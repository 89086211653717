import { Component, OnInit } from '@angular/core';
import { Chart } from 'chart.js';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';
import { DashboardService } from 'src/app/core/dashboard.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Globals } from 'src/app/core/globals.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  colors = {
    lightBlue: '#2f99cb',
    lightGreen: '#1ebc38cb',
    darkGreen: '#023009',
    maroon: '#7a0000',
    darkYellow: '#ffd05b',
    darkBlue: '#15607a',
    lightBlue2: '#18a1cd',
    blue2: '#320064'
  }

  posColors = [this.colors.darkBlue, this.colors.blue2, this.colors.maroon, this.colors.lightBlue2, this.colors.darkGreen];

  hotelId: string = '';
  dashData: any = {}

  arrivalChart: any;
  checkoutChart: any;
  roomStatusChart: any;
  revenueTodayChart: any;
  occuAdrChart: any;
  revenueYearlyChart: any;

  chartOption: any = {
    responsive: true,
    legend: {
      align: 'center',
      position: 'bottom',
      labels: {
        boxWidth: 10,
        boxHeight: 10,
      }
    },
    plugins: {
      title: {
        display: true,
        text: '',
      }
    }
  }
  currencyCode: string = ''

  constructor(
    private dashboardService: DashboardService,
    private route: ActivatedRoute,
    private globals: Globals
  ) { }

  ngOnInit(): void {
    this.hotelId = this.route.snapshot.params?.hotelId;
    this.getDashboardData();
    this.globals.getCurrencyCode(this.hotelId).subscribe(
      data => {
        this.currencyCode = data;
      }
    );
  }

  getDashboardData() {
    this.dashboardService.getDashboardData(this.hotelId).subscribe(res => {
      if (res?.success) {
        this.dashData = res.data;
        this.initCharts();
      }
    })
  }

  initCharts() {
    this.initArrivalChart();
    this.initCheckoutChart();
    this.initRoomStatusChart();
    this.initRevenueTodayChart();
    this.initOccuAdrChart();
    this.initRevenueYearly();
  }

  initArrivalChart() {
    this.arrivalChart = new Chart('arrivalCanvas', {
      type: 'doughnut',
      data: {
        labels: ['Pending', 'Arrived'],
        datasets: [
          { 
            data: [this.dashData.arrivalPending, this.dashData.arrived],
            backgroundColor: [this.colors.lightBlue, this.colors.lightGreen],
            datalabels: {
              color: '#fff',
            }
          },
        ]
      },
      plugins: [DataLabelsPlugin],
      options: this.chartOption
    });
  }

  initCheckoutChart() {
    this.checkoutChart =  new Chart('checkoutCanvas', {
      type: 'doughnut',
      data: {
        labels: ['Pending', 'Checked Out'],
        datasets: [
          { 
            data: [this.dashData.checkoutPending, this.dashData.checkedOut],
            backgroundColor: [this.colors.lightBlue, this.colors.darkGreen],
            datalabels: {
              color: '#fff'
            }
          },
        ]
      },
      plugins: [DataLabelsPlugin],
      options: this.chartOption
    });
  }

  initRoomStatusChart() {
    const roomData = this.dashData.totalRoomSummary;
    this.roomStatusChart = new Chart('roomStatusCanvas', {
      type: 'doughnut',
      data: {
        labels: ['Available', 'Out of Order', 'Occupied', 'Complimentary'],
        datasets: [
          { 
            data: [roomData.available, roomData.outoforder, roomData.occupied, roomData.complementary],
            backgroundColor: [this.colors.lightGreen, 'grey', this.colors.maroon, 'blue'],
            datalabels: {
              color: '#fff'
            }
          },
        ]
      },
      plugins: [DataLabelsPlugin],
      options: this.chartOption
    });
  }

  initRevenueTodayChart() {
    this.revenueTodayChart = new Chart('revenueTodayCanvas', {
      type: 'doughnut',
      data: {
        labels: this.dashData.revenueData.map(e => e.posName),
        datasets: [
          { 
            data: this.dashData.revenueData.map((e) => e.value),
            backgroundColor: this.posColors.slice(0, this.dashData.revenueData.length),
            datalabels: {
              color: '#fff'
            }
          },
        ]
      },
      plugins: [DataLabelsPlugin],
      options: this.chartOption
    });


  }

  initRevenueYearly() {
    const posYearlyData = this.dashData.posYearlyData
    const labels = posYearlyData.yearlyDates.map(e => this.getMonthLabel(e));
    const datasets = posYearlyData.revenue.map((e, i) => {
      return {
        label: e.posName,
        data: e.data,
        backgroundColor: this.posColors[i]
      }
    })

    this.revenueYearlyChart = new Chart('revenueYearlyCanvas', {
      type: 'bar',
      data: {
        labels,
        datasets
      },
      options: this.chartOption
    });
  }

  
  initOccuAdrChart() {
    const posYearlyData = this.dashData.posYearlyData
    const labels = posYearlyData.yearlyDates.map(e => this.getMonthLabel(e));

    this.occuAdrChart = new Chart('occuAdrCanvas', {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [{
          label: 'ADR',
          type: 'line',
          borderColor: 'rgb(75, 192, 192)',
          borderWidth: 2,
          fill: false,
          data: posYearlyData.adr,
          yAxisID: 'y-axis-1'
        }, {
          label: 'Occupancy',
          type: 'bar',
          backgroundColor: 'rgb(255, 99, 132)',
          data: posYearlyData.occupancy,
          yAxisID: 'y-axis-2'
        }]
      },
      options: {
        scales: {
          yAxes: [{
            id: 'y-axis-1',
            position: 'left',
            ticks: {
              beginAtZero: true
            }
          }, {
            id: 'y-axis-2',
            position: 'right',
            ticks: {
              beginAtZero: true
            }
          }]
        }
      }
    });
  }

  getMonthLabel(dateString) {
    const [year, month] = dateString.split("-");
    const dateObject = new Date(`${year}-${month}-01`);
    const monthName = dateObject.toLocaleString('en-US', { month: 'short' });
    return monthName + ' ' + year;
  }
}
