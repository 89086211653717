import { DatePipe, Location } from '@angular/common';
import { Component, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
// import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { PosService } from 'src/app/core/pos.service';

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.scss']
})
export class LogsComponent implements OnInit {

  logs: any;
  ELEMENT_DATA: LogsInfo[];
  fromDate: string;
  toDate: string;
  showPage = false;
  posLogs: any;
  hotelRoute: any;
  hotelId: any;
  currentUser: any;
  posNames = [];
  posName = null;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any, 
    @Optional() private dialogRef: MatDialogRef<LogsComponent>, 
    private datePipe: DatePipe,
    public location: Location,
    private router: Router,
    private route: ActivatedRoute,
    private posService: PosService
    ) { if (data){
      this.logs = this.data.logs;
    } }

  ngOnInit(): void {
    this.posLogs = this.location.getState();
    if(this.posLogs.posLogs == true){
      this.showPage = true;
      this.posNames = this.posLogs.posNames;
    }else{
      this.showPage = false;
    }
    this.hotelRoute = this.route.params.subscribe((params) => {
      this.hotelId = params.hotelId;
    });
    this.hotelRoute = this.route.queryParams.subscribe((queryParams) => {
      this.currentUser = queryParams.user;
    });
    
    this.dateFilter();
    
    
  }
  closeDialog() {
    this.dialogRef.close();
  }
  getLogs(){
    this.posService.fetchPosLogs(this.hotelId, this.posName, this.fromDate, this.toDate).subscribe(success=>{
      this.dataSource.data = success;
      this.dataSource.paginator = this.paginator;
    })
  }
  displayedColumns: string[] = [
    'datetime',
    'log',
    'changed_by'
  ];
  dataSource = new MatTableDataSource<LogsInfo>();

  // @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  dateFilter(){
    var date = new Date();
    const datecopy = new Date(Number(date));
    datecopy.setDate(date.getDate() - 6);
    this.fromDate = this.datePipe.transform(datecopy, 'yyyy-MM-dd');
    this.toDate = this.datePipe.transform(date, 'yyyy-MM-dd');
  }
  ngOnDestroy() {
    this.hotelRoute.unsubscribe();
  }

  backToPosPage(){
    this.router.navigate(['/pos/'+this.hotelId],{queryParams :{user: this.currentUser }});
  }
}

export interface LogsInfo {
  datetime: string;
  log: string;
  changed_by: string;
}
