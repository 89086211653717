<ng-container>
    <div>
        <nav class="navbar" role="navigation" aria-label="main navigation">
        <div class="navbar-brand" *ngIf="delete == false">
            <h2>{{ "stayview.reservation.bulk_out_of_order" | translate }}</h2>
        </div>
        <div class="navbar-brand" *ngIf="delete == true">
            <h2>{{"stayview.reservation.delete_bulk_out_of_order" | translate}}</h2>
        </div>
        <div class="navbar-end">
            <div class="navbar-item">
            <button class="btn" type="button" style="display: inline-block" (click)="cleardata()">
                {{ "stayview.reservation.clear" | translate }}
            </button>
            <button style="display: inline-block" class="btn" (click)="location.back()">
                {{ "stayview.reservation.back" | translate }}
            </button>
            </div>
        </div>
        </nav>
    </div>
    
    <hr />

    <div class="example-container">
        <mat-form-field appearance="outline">
            <mat-label>Enter a date range</mat-label>
            <mat-date-range-input [rangePicker]="picker" [min]="minCheckinDate" >
                <input matStartDate placeholder="Start date" [(ngModel)]="checkinDate" #dateRangeStart onkeydown="return false">
                <input matEndDate placeholder="End date" [(ngModel)]="checkoutDate" #dateRangeEnd (dateChange)="changedates(dateRangeStart, dateRangeEnd)" onkeydown="return false">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Room Type</mat-label>
            <mat-select [(ngModel)]="roomType" name="roomtype" >
                <mat-option *ngFor="let roomtype of roomtypes" [value]="roomtype.name" (click)="changeRoomtype(roomtype)" >
                {{roomtype.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>Room Numbers</mat-label>
            <mat-select [formControl]="roomNumbers" multiple >
                <mat-option *ngFor="let room of roomNumInfo" [value]="room">{{room.roomNum}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" class="increase-width">
            <mat-label>Comments</mat-label>
            <input matInput placeholder="Ex. Repair" [(ngModel)]="comments" name="comments">
        </mat-form-field>
    </div>
    <div class="row action-buttons" style="margin-bottom: 0; margin-right: 0%; justify-content: flex-end" >
        <button *ngIf = "submitting == false" class="btn mr-1" type="submit" mat-raised-button (click)="submit()" [disabled]="!roomNumbers.value">
        Submit
        </button>
        <button *ngIf = "submitting == true" class="btn mr-1" type="submit" mat-raised-button (click)="submit()" [disabled]="true">
            Submitting...
            </button>
    </div>
</ng-container>
