<div class="primary_modal_wrapper">
    <div class="modal_heading">
        <h1>{{ "accounts.mark_city_ledger" | translate }}</h1>

        <select *ngIf="ledgerData?.configLevel === 'chain'"
            class="w-300 form-control form-control-sm"
            [(ngModel)]="filterByHotel" (ngModelChange)="hotelChange()">
            <option *ngFor="let hotel of hotels" 
                [value]="hotel.id">
                {{hotel.name}}
            </option>
            <option value="all">{{ "accounts.all" | translate }}</option>
        </select>

        <svg [mat-dialog-close]="false" role="button"
            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
          <path
            d="M315.3 411.3c-6.253 6.253-16.37 6.253-22.63 0L160 278.6l-132.7 132.7c-6.253 6.253-16.37 6.253-22.63 0c-6.253-6.253-6.253-16.37 0-22.63L137.4 256L4.69 123.3c-6.253-6.253-6.253-16.37 0-22.63c6.253-6.253 16.37-6.253 22.63 0L160 233.4l132.7-132.7c6.253-6.253 16.37-6.253 22.63 0c6.253 6.253 6.253 16.37 0 22.63L182.6 256l132.7 132.7C321.6 394.9 321.6 405.1 315.3 411.3z" />
        </svg>
    </div>

    <mat-divider></mat-divider>

    <div class="modal_body">
        
        <table class="table" *ngIf="ledgerData?.data?.length">
            <thead class="thead-dark">
                <tr>
                    <th>{{ "accounts.guest" | translate }}</th>
                    <th>{{ "accounts.checkin" | translate }}</th>
                    <th>{{ "accounts.checkout" | translate }}</th>
                    <th>{{ "accounts.invoice_no" | translate }}.</th>
                    <th>{{ "accounts.amount" | translate }}</th>
                    <th></th>
                </tr>
            </thead>

            <tbody>
                <tr *ngFor="let p of ledgerData?.data">
                    <td>{{p.guest_name}}</td>
                    <td>{{p.checkin | date : 'mediumDate'}}</td>
                    <td>{{p.checkout | date : 'mediumDate'}}</td>
                    <td>{{p.invoice_num}}</td>
                    <td>{{p.amount | number : '1.2-2'}}</td>
                    <td>
                        <input type="checkbox" [(ngModel)]="selectedPaymentIds[p.payment_id]">
                    </td>
                </tr>
            </tbody>
        </table>
        
    </div>

    <div class="modal_footer">
        <div>
            <button mat-raised-button (click)="changePage('prev')">{{ "accounts.prev" | translate }}</button>
            <span class="primary_btn">{{currentPage}}</span>
            <button mat-raised-button (click)="changePage('next')">{{ "accounts.next" | translate }}</button>
        </div>
        <div>
            <button mat-raised-button mat-dialog-close>
                {{ "accounts.cancel" | translate }}
            </button>

            <button mat-raised-button
                class="primary_btn"
                (click)="submitClick()">
                {{ "accounts.add" | translate }}
            </button>

        </div>
    </div>

    <div class="loader_wrapper" *ngIf="loading">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
         style="position: relative;top: 50%;left: 50%;transform: translate(-50%, -50%);" width="100px" height="100px" viewBox="0 0 100 50" preserveAspectRatio="xMidYMid">
            <rect x="14.5" y="10" width="8" height="20" fill="#b2b2b2">
              <animate attributeName="opacity" dur="0.8620689655172413s" repeatCount="indefinite" calcMode="spline" keyTimes="0;0.5;1" keySplines="0.5 0 0.5 1;0.5 0 0.5 1" values="1;0.2;1" begin="-0.5172413793103448"></animate>
            </rect><rect x="34.5" y="10" width="8" height="20" fill="#b2b2b2">
              <animate attributeName="opacity" dur="0.8620689655172413s" repeatCount="indefinite" calcMode="spline" keyTimes="0;0.5;1" keySplines="0.5 0 0.5 1;0.5 0 0.5 1" values="1;0.2;1" begin="-0.3448275862068965"></animate>
            </rect><rect x="54.5" y="10" width="8" height="20" fill="#b2b2b2">
              <animate attributeName="opacity" dur="0.8620689655172413s" repeatCount="indefinite" calcMode="spline" keyTimes="0;0.5;1" keySplines="0.5 0 0.5 1;0.5 0 0.5 1" values="1;0.2;1" begin="-0.17241379310344826"></animate>
            </rect><rect x="74.5" y="10" width="8" height="20" fill="#b2b2b2">
              <animate attributeName="opacity" dur="0.8620689655172413s" repeatCount="indefinite" calcMode="spline" keyTimes="0;0.5;1" keySplines="0.5 0 0.5 1;0.5 0 0.5 1" values="1;0.2;1" begin="-0.8620689655172413"></animate>
            </rect>
        </svg>
    </div>

</div>
