<div class="dialog-box-popup">
    <div class="row mb-1">
      <h1>
        Transfer Bill to Room No
      </h1>
      <hr />
    </div>
  
    <div class="popup-form">
      <div class="form-row">
        <div class="form-group col-md-12 mb-3">
          <label for="transferToGuest">Select Room</label>
          <select
              class="form-control"
              [(ngModel)]="transferToGuest"
              id="transferToGuest"
              name="transferToGuest"
            >
              <option *ngFor="let option of guestList" [value]="option['room_allocation_id']">
                {{option["room_num"]}} - {{ option["guest_name"] }}
              </option>
            </select>
        </div>
        
      </div>
      <div class="row action-buttons mt-4">
        <button type="button" mat-raised-button (click)="closeDialog()">
          {{ "stayview.reservation.edit_booking.cancel" | translate }}
        </button>
        <button
          *ngIf="submitting == false"
          type="submit"
          mat-raised-button
          (click)="submit(transferToGuest)"
          class="confirm-button"
          [disabled]="!transferToGuest"
        >
          {{ "stayview.reservation.edit_booking.submit" | translate }}
        </button>
        <button
          *ngIf="submitting == true"
          type="submit"
          mat-raised-button
          disabled
          class="confirm-button"
        >
          {{ "stayview.reservation.edit_booking.submitting" | translate }}
        </button>
      </div>
    </div>
  </div>
  