import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Subject, forkJoin } from 'rxjs';
import { debounceTime, finalize } from 'rxjs/operators';
import { CommunicationService } from 'src/app/core/communication.service';
import { Globals } from 'src/app/core/globals.service';
import { StoreService } from 'src/app/core/store.service';
import { StoreCommonService } from 'src/app/views/store/store-common.service';
import { saveAs } from 'file-saver-es';
import { SendPoEmailComponent } from '../../../views/store/modals/send-po-email/send-po-email.component';
import { UserService } from 'src/app/core/user.service';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class OrderComponent implements OnInit {
  private readonly MAX_FILE_SIZE_MB = 5
  
  masterItems: Array<any>;
  vendors: Array<any>;
  selectedItem: any;
  selectedVendor = '';
  orderItems: Array<any>;
  orderTotal: any = {quantity: 0, pre_tax_amount: 0, tax: {total: 0, break_up: []}, total_amount: 0};
  lastTaxDetails = {pre_tax_amount: 0, tax_details: {total: 0, break_up: []}, total_amount: 0};
  rateChange$ = new Subject();
  printDisabled = false;
  today = new Date().toISOString().split('T')[0];
  emailSentString = 'Email to Vendor';
  quantityChangeTime: any;
  loading = false;
  selectedFiles: File[];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public storeService: StoreService,
              public dialogRef: MatDialogRef<any>,
              public globals: Globals,
              public commS: CommunicationService,
              public storeCommon: StoreCommonService,
              private userService: UserService,
              public dialog: MatDialog) { }

  ngOnInit(): void {
    this.selectedItem = {id: '', name: '', quantity: 0, rate: null, pre_tax_amount: null, tax: {total: null}, total_amount: null};
    this.masterItems = this.data.masterItems;
    if (this.data.mode === 'edit' || this.data.mode === 'view') {
      this.orderItems = JSON.parse(JSON.stringify(this.data.orderItems));
      for (const item of this.orderItems) {
        this.orderTotal = this.storeCommon.calculateTotal('+', item, this.orderTotal);
      }
      this.selectedVendor = this.data.vendor.id;
    }
    this.rateChange$.pipe(
      debounceTime(500)
    ).subscribe(() => this.getTaxDetails(true));
  }

  selectItem(id: string) {
    if (!id) {
      this.selectedItem = {id: '', name: '', quantity: 0, rate: null, pre_tax_amount: null, tax: {total: null}, total_amount: null};
      return;
    }
    for (const item of this.masterItems) {
      if (item.id === id) {
        this.selectedItem = JSON.parse(JSON.stringify(item));
        this.selectedItem.rate = item.rate === null ? 0 : item.rate;
        this.selectedItem.quantity = 1;
        this.getTaxDetails();
        break;
      }
    }
  }

  getTaxDetails(rateChange = false) {
    this.loading = true;
    const body = {tax_group: this.selectedItem.tax_group, tax_inclusive: this.selectedItem.tax_inclusive, amount: this.selectedItem.rate};
    this.storeService.calculateTax(this.data.hotelId, body)
    .pipe(finalize(() => this.loading = false))
    .subscribe(data => {
      this.lastTaxDetails = JSON.parse(JSON.stringify(data));
      const newData = this.storeCommon.changeTaxFeilds(this.selectedItem, this.lastTaxDetails, rateChange);
      this.selectedItem = this.storeCommon.updateSelectedItem(this.selectedItem, newData);
    });
  }

  onRateChange(e) {
    this.rateChange$.next(e);
  }

  deleteUnwantedProperties(properties) {
    for (const prop of properties) {
      if (this.selectedItem.hasOwnProperty(prop)) {
        delete this.selectedItem[prop];
      }
    }
  }

  pushOrderItem(item) {
    if (!this.orderItems) {
      this.orderItems = [];
    }
    if (!this.storeCommon.isQuantityValid(item.quantity)) {
      return;
    }
    for (const inItem of this.orderItems) {
      if (item.id === inItem.id) {
        return;
      }
    }
    this.orderItems.push(JSON.parse(JSON.stringify(item)));
    this.orderTotal = this.storeCommon.calculateTotal('+', item, this.orderTotal);
  }

  removeOrderItem(item) {
    for (const cItem of this.orderItems) {
      if (cItem.id === item.id) {
        this.orderItems.splice(this.orderItems.indexOf(item), 1);
        break;
      }
    }
    this.orderTotal = this.storeCommon.calculateTotal('-', item, this.orderTotal);
  }

  onQuantityChange() {
    if (!this.selectedItem.quantity) {
      return;
    }
    clearTimeout(this.quantityChangeTime);
    this.quantityChangeTime = setTimeout(() => {
      this.loading = true;
      const amount = this.selectedItem.rate * this.selectedItem.quantity;
      const body = {tax_group: this.selectedItem.tax_group, tax_inclusive: this.selectedItem.tax_inclusive, amount};
      this.storeService.calculateTax(this.data.hotelId, body)
      .pipe(finalize(() => this.loading = false))
      .subscribe(data => {
        this.selectedItem.pre_tax_amount = data.pre_tax_amount;
        this.selectedItem.total_amount = data.total_amount;
        this.selectedItem.tax.total = data.tax_details.total;
        this.selectedItem.tax.break_up = data.tax_details.break_up;
      });
    }, 800);
  }

  comparer(otherArray){
    return (current) => {
      return otherArray.filter((other) => {
        return other.id === current.id && other.quantity === current.quantity && other.total_amount === current.total_amount;
      }).length === 0;
    };
  }

  submitClick() {
    if (this.selectedFiles) {
      this.uploadAndSubmit();
    } else {
      this.submitPO();
    }
  }

  submitPO(selectedFilesUrl = null) {
    const body: any = {order_info: this.orderTotal};
    body.order_info.vendor_id = this.selectedVendor;
    if (this.data.selectedFiles?.length && selectedFilesUrl?.length) {
      body.order_info.selectedFilesUrl = [...selectedFilesUrl, ...this.data.selectedFiles];
    } else {
      body.order_info.selectedFilesUrl = selectedFilesUrl
    }
  
    this.loading = true;
  
    if (this.data.mode === 'edit' || this.data.mode === 'view') {
      if (this.data.mode === 'edit') {
        body.new_items = this.orderItems.filter(this.comparer(this.data.orderItems));
        body.deleted_items = this.data.orderItems.filter(this.comparer(this.orderItems));
      }
      body.order_id = this.data.orderId;
      body.order_info.invoice_num = this.data.invoiceNum;
      body.order_info.invoice_date = this.data.invoiceDate;

      this.storeService.editPurchaseOrder(body, this.data.hotelId)
      .pipe(finalize(() => this.loading = false))
      .subscribe(data => {
        this.commS.emitPurchaseOrderReload();
        this.globals.snackBarFunc(`Order edited successfully !`);
      }).add(() => this.closeDialog());
      return;
    } 
    else {
      body.items = this.orderItems;
      this.storeService.addPurchaseOrder(body, this.data.hotelId)
      .pipe(finalize(() => this.loading = false))
      .subscribe(data => {
        this.commS.emitPurchaseOrderReload();
        this.globals.snackBarFunc(`Order created successfully !`);
      }, err => {

      }).add(() => this.closeDialog());
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  getOrderPdf() {
    this.printDisabled = true;
    this.storeService.getPdf(this.data.hotelId, 'Purchase Order', this.data.orderId)
    .subscribe(data => {
      const blob = new Blob([data], {type: 'text/pdf; charset=utf-8'});
      const fileName = `${this.data.orderId}.pdf`;
      saveAs(blob, fileName);
    });

    setTimeout(() => {
      this.printDisabled = false;
    }, 5000);
  }

  sendEmailToVendor() {
    const dialogBody = {
      vendorEmail: this.data.vendor.email,
      hotelId: this.data.hotelId,
      orderId: this.data.orderId,
      orderNum: this.data.orderNum,
      totalAmount: this.orderTotal.total_amount
    };
    this.dialog.open(SendPoEmailComponent, {
      data: dialogBody,
      height: '500px',
      width: '500px',
    });
  }

  uploadAndSubmit() {
    this.loading = true;
    const ts = new Date().getTime();
    const fileUploadObservables = this.selectedFiles.map(f => 
      this.userService.uploadFile(f, `${this.data.hotelId}/${ts}-${f.name}`)
    );

    forkJoin(fileUploadObservables).subscribe(res => {
      this.submitPO(res);
    })

  }

  fileChange(e) {
    if (e?.files?.length > 5) {
      alert('Max 5 files allowed');
      e.value = '';
      return;
    }

    for (const f of e.files) {
      if (f.size > this.MAX_FILE_SIZE_MB * 1024 * 1024) {
        alert('Max file size 5MB');
        e.value = '';
        return;
      }
    }
    
    this.selectedFiles = [...e.files];
  }

  setVendor(e) {
    this.selectedVendor = e;
  }

  openTab(url) {
    window.open(url, '_blank')
  }
}
