<div>
    <div class="aio_heading">
        <h2>{{ "banquets.title" | translate }}</h2>
    
        <button mat-raised-button class="primary_btn"
            routerLink="/banquet/{{hotelId}}">
          {{ "banquets.back_btn" | translate }}
        </button>
    </div>

    <mat-divider></mat-divider>

    <div class="container-fluid common_content">
        <form class="create_form shadow-sm" [formGroup]="banquetForm" *ngIf="banquetForm">
            <div>
                
                <h3>{{ "banquets.reservation" | translate }}</h3>
                <div class="input_group">

                    <div class="datepicker-parent">
                        <label>{{ "banquets.date" | translate }} <span class="text-danger">*</span></label>
                        <div class="form-control form-control-sm" (click)="toggleDatePicker(); $event.stopPropagation()">
                            {{ "banquets.select_date" | translate }}
                        </div>

                        <div class="datepicker" *ngIf="showDatePicker" appClickOutside (clickOutside)="closeDatepicker()">
                            <div class="top-header">
                                <mat-icon (click)="changeMonth(false)">keyboard_arrow_left</mat-icon>
                                <span>{{generatedDates[currentMonth].month}}</span>
                                <mat-icon (click)="changeMonth(true)">keyboard_arrow_right</mat-icon>
                            </div>

                            <div class="dates">
                                <div *ngFor="let d of daysOfWeek">{{d}}</div>
                                
                                <div *ngFor="let dummy of [].constructor(generatedDates[currentMonth].dates[0].dayOfWeek)"></div>

                                <div *ngFor="let item of generatedDates[currentMonth].dates" 
                                    (click)="selectDate(item.date)"
                                    [ngClass]="{'active': selectedDates[item.date]?.selected}">
                                    {{item.date | date: 'dd'}}
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div>
                        <label>Pax </label>
                        <input type="number" class="form-control form-control-sm" formControlName="pax" max="1000">
                    </div>
    
                    
                    <div>
                        <label>{{ "banquets.budget" | translate }}</label>
                        <input type="number" inputmode="decimal" class="form-control form-control-sm" formControlName="budget">
                    </div>
    
                    
                    <div>
                        <label>{{ "banquets.pricing_type" | translate }} </label>
                        <select class="form-control form-control-sm" formControlName="pricingType">
                            <option *ngFor="let p of pricingTypes" [value]="p">{{p}}</option>
                        </select>
                    </div>

                    <div>
                        <label>{{ "banquets.status" | translate }} <span class="text-danger">*</span></label>
                        <select class="form-control form-control-sm" formControlName="status">
                            <option *ngFor="let s of statuses" [value]="s.value">{{s.name}}</option>
                        </select>
                    </div>
                        
                    <div>
                        <label>{{ "banquets.amount" | translate }}</label>
                        <input type="number" inputmode="decimal" class="form-control form-control-sm" formControlName="amount">
                    </div>

                    <div *ngIf="banquetForm.controls.status.value === 'tentative'">
                        <label>{{ "banquets.expiry_date" | translate }}</label>
                        <input type="date" class="form-control form-control-sm" formControlName="expiryDate">
                    </div>
                    
                    <div>
                        <label>{{ "banquets.comments" | translate }} </label>
                        <textarea type="text" class="form-control form-control-sm" formControlName="comments" maxlength="64"></textarea>
                    </div>

                    <div class="sessions-list">
                        <table>
                            <thead>
                                <tr>
                                    <th class="date">{{ "banquets.date" | translate }}</th>
                                    <th *ngFor="let item of sessions">{{item.shortName}}</th>
                                    <th>{{ "banquets.venues" | translate }}</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            
                            <tbody>
                                <ng-container *ngFor="let date of selectedDates | keyvalue">
                                    <tr *ngIf="date.value?.selected">
                                        <td class="date">{{date.key | date: 'd MMM'}}</td>
                                        <td *ngFor="let s of sessions; let si=index">
                                            <input type="checkbox" 
                                                [checked]="selectedDates[date.key]?.session ? selectedDates[date.key]?.session[si] : false"
                                                (change)="selectSession(date.key, si, $event.target.checked)">
                                        </td>
                                        <td>
                                            <app-multi-select-dropdown
                                                [data]="halls"
                                                [selectedKeys]="selectedDates[date.key]?.halls"
                                                name="name"
                                                placeholder="Select halls"
                                                (onRowClick)="hallSelect($event, date.key)">
                                            </app-multi-select-dropdown>
                                        </td>
                                        <td><mat-icon class="text-danger" (click)="removeDate(date.key)">delete</mat-icon></td>
                                        <td>
                                            <ng-container *ngFor="let err of errors | keyvalue">
                                                <span *ngIf="errors[err.key][date.key]" class="text-danger">
                                                    {{err.value[date.key]}}
                                                </span>
                                            </ng-container>
                                        </td>
                                    </tr>
                                </ng-container>
                            </tbody>

                        </table>
                    </div>
                </div>

                
                <h3>{{ "banquets.guest" | translate }}</h3>
                <div class="input_group">

                    <div>
                        <label>{{ "banquets.name" | translate }} <span class="text-danger">*</span></label>
                        <input type="text" class="form-control form-control-sm" formControlName="name">
                    </div>
    
                    <div>
                      <label>{{ "banquets.email" | translate }}</label>
                      <input type="text" class="form-control form-control-sm" formControlName="email" maxlength="64">
                    </div>
    
                    <div>
                        <label>{{ "banquets.phone" | translate }}</label>
                        <input type="text" class="form-control form-control-sm" formControlName="phone" maxlength="64">
                    </div>
                </div>

                
                <h3>{{ "banquets.details" | translate }}</h3>
                <div class="input_group">
                    
                    <div>
                        <label>{{ "banquets.type_of_party" | translate }} </label>
                        <select class="form-control form-control-sm" formControlName="typeOfParty">
                            <option *ngFor="let p of typeOfParties" [value]="p">{{p}}</option>
                        </select>
                    </div>
    
                    <div>
                        <label>{{ "banquets.menu" | translate }} </label>
                        <textarea type="text" class="form-control form-control-sm" formControlName="menu" maxlength="64"></textarea>
                    </div>
    
                    <div>
                        <label>{{ "banquets.arrangements" | translate }} </label>
                        <textarea type="text" class="form-control form-control-sm" formControlName="arrangements" maxlength="64"></textarea>
                    </div>

                    <div>
                        <label>{{ "banquets.attachment" | translate }}</label>
                        <input 
                            type="file"
                            accept="image/*, .pdf"
                            (change)="fileChange($event.target)"
                            multiple>
                    </div>

                    <div *ngIf="banquetForm.controls.attachment.value?.length">
                        <ng-container *ngFor="let f of banquetForm.controls.attachment.value">
                            <ng-container *ngIf="f.url.split('.').pop() === 'pdf';else imageView">
                                <img class="cursor-pointer" matTooltip="Open" src="assets/img/pdf_icon.png" width="20" (click)="openTab(f.url)"/>
                            </ng-container>
                        
                            <ng-template #imageView>
                                <img class="cursor-pointer" matTooltip="Open" [src]="f.url" width="50" (click)="openTab(f.url)" />
                            </ng-template>
                        </ng-container>
                    </div>

                </div>
                
            </div>
            
            <div class="action_btns">
              <button mat-raised-button class="primary_btn"
                (click)="submitClick()" >
                {{ "banquets.submit" | translate }}
              </button>
            </div>
      
      
          </form>

    </div>

</div>

