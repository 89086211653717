import { DatePipe } from '@angular/common';
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { DialogComponent } from 'src/app/_shared/dialog/dialog.component';
import { MaintenanceIssueService } from 'src/app/core/maintenance-issue.service';
import { ReservationService } from 'src/app/core/reservation.service';

@Component({
  selector: 'app-lost-and-found-entry',
  templateUrl: './lost-and-found-entry.component.html',
  styleUrls: ['./lost-and-found-entry.component.scss']
})
export class LostAndFoundEntryComponent implements OnInit {

  mode = 'new';
  date : string;
  itemDetails: string ;
  status  = 'Lost';
  comments : string;
  itemStatus = 'Open';
  hotelId: any;
  currentDate: Date;
  itemId: any;
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private guestService: ReservationService,
    private datePipe: DatePipe,
    private maintainanceService: MaintenanceIssueService) { }

  ngOnInit(): void {
    this.hotelId = this.data.hotelId;
    this.mode = this.data.mode;
    if (this.mode == 'edit'){
      this.date = this.data.itemData.date;
      this.itemId = this.data.itemData.id;
      this.itemDetails = this.data.itemData.itemDetails;
      this.status = this.data.itemData.status;
      this.itemStatus = this.data.itemData.itemStatus;
      this.comments = this.data.itemData.comments;
    }else{
      this.getCurrentDate();
    }
    
  }

  close(){
    window.location.reload();
  }

  getCurrentDate(){
    this.guestService.getCurrentDatetTime(this.hotelId).subscribe(
      (data) => {
        this.currentDate = new Date(data.currentdatetime);
        this.date = this.datePipe.transform(this.currentDate, 'yyyy-MM-dd');
      })
    }

  submit(){
    const dialogBody =
        { title: 'Create Lost/Found Entry',
          body: `Do You Want To Submit?`,
          confirmBtn: 'Submit',
          cancelBtn: 'Cancel'
        };
    const dialogRef = this.dialog.open(DialogComponent, {data: dialogBody});

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'true'){
        var data = {
          date: this.date,
          item_details : this.itemDetails,
          status: this.status,
          item_status: this.itemStatus,
          comments: this.comments
        }
        if (this.mode == 'edit'){
          data['id'] = this.itemId;
        }
        this.maintainanceService.createLostAndFoundEntry(this.hotelId, data).subscribe(success=>{
          window.location.reload();
        });
      }
    })
  }
}
