import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { StoreService } from 'src/app/core/store.service';

@Component({
  selector: 'app-vendors-drop-down',
  templateUrl: './vendors-drop-down.component.html',
  styleUrls: ['./vendors-drop-down.component.scss']
})
export class VendorsDropDownComponent implements OnInit, OnDestroy {
  
  @Input() hotelId = '';
  @Input() selectedVendor;
  @Output() vendorSelected = new EventEmitter<null>();
  
  subscriptions: Subscription[] = [];
  vendors = [];

  constructor(
    private storeService: StoreService,
  ) { }

  ngOnInit(): void {
    this.getData();
  }

  getData() {
    this.subscriptions.push(
      this.storeService.getVendors(this.hotelId, 'name')
      .subscribe(res => {
        this.vendors = res?.vendors;
      })
    )
  }

  selectItem(e) {
    this.vendorSelected.emit(e);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
