import { DatePipe, Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CommunicationService } from 'src/app/core/communication.service';
import { Globals } from 'src/app/core/globals.service';
import { UserService } from 'src/app/core/user.service';
import { DialogComponent } from 'src/app/_shared/dialog/dialog.component';
import { ExcelServicesService } from 'src/app/_shared/services/excel-file.service';
import { PaymentsDetailsComponent } from '../stayview/payment-details/payment-details.component';
import { StoreService } from 'src/app/core/store.service';
import { GetPaymentFileComponent } from './get-payment-file/get-payment-file.component';
import { AccountsService } from 'src/app/core/accounts.service';

@Component({
  selector: 'app-expenses',
  templateUrl: './expenses.component.html',
  styleUrls: ['./expenses.component.scss']
})
export class ExpensesComponent implements OnInit {

  startDate: any;
  endDate: any;
  paymentType = 'Cash';
  config: any;
  payments: any;
  paymentModes = ['Bill to Company', 'Cash', 'Credit Card', 'Wallet'];
  userFeatures: any;
  selectedPayments = [];
  storeConfig: any = {isEnabled: false}
  accountsConfig: any = {};
  expenseType: 'chain' | 'hotel' = 'hotel';
  selectedHotel: string = '';
  showPayments = []

  constructor(public userService: UserService,
              public globals: Globals,
              public datePipe: DatePipe,
              public dialog: MatDialog,
              public commS: CommunicationService,
              public location: Location,
              private excelService: ExcelServicesService,
              private storeService: StoreService,
              private accountsService: AccountsService
            ) { }

  ngOnInit(): void {
    this.globals.config$.subscribe(data => {
      this.config = data;
      this.paymentModes = this.config?.paymentModes?.length ? this.config?.paymentModes : this.paymentModes;
      this.endDateCalc(this.config.currentDate);
      this.initView();
      this.getStoreConfig();
      this.getAccountsConfig();
    });

    this.commS.expenseReload.subscribe(event => {
      if (this.config.hotelId) {
        this.initView();
      }
    });

    this.userService.userFeatures$.subscribe(res => {
      this.userFeatures = res?.live;
    })
  }

  initView() {
    if (this.expenseType === 'hotel') {
      this.getHotelExpense();
    } else {
      this.getGroupExpense();
    }
  }

  getHotelExpense() {
    this.expenseType = 'hotel';
    this.selectedPayments = [];

    this.userService.getExpenses(this.config.hotelId, this.startDate, this.endDate, this.paymentType).
    subscribe(data => {
      if (data && data.hasOwnProperty('payments')) {
        this.payments = data;
        this.showPayments = this.payments?.payments;
      }
    });
  }

  getAccountsConfig() {
    this.accountsService.getAccountsConfig(this.config.hotelId).subscribe(res => {
      this.accountsConfig = res;
    });
  }

  getGroupExpense() {
    this.expenseType = 'chain';
    this.selectedPayments = [];

    this.userService.getExpenses(this.config.hotelId, this.startDate, this.endDate, this.paymentType, this.expenseType, this.accountsConfig?.conf?.chainId).
    subscribe(data => {
      if (data && data.hasOwnProperty('payments')) {
        this.payments = data;
        this.showPayments = this.payments?.payments;
        this.changeHotel();
      }
    });
  }

  endDateCalc(endDate) {
    this.endDate = endDate;
    this.startDate = new Date(endDate);
    this.startDate.setDate(this.startDate.getDate() - 7);
    this.startDate = this.datePipe.transform((new Date(this.startDate)), 'y-MM-dd');
  }

  goToAddPayment(paidIn: boolean, heading = null) {
    this.dialog.open(PaymentsDetailsComponent, {
      disableClose: true,
      data :
        {mode: 'newEntry', paidIn, expense: true, heading}
      });
  }

  deletePayment(paymentId, amount, paidIn: boolean) {
    if (!this.userFeatures?.deleteExpenses) {
      this.deleteNotAllowed();
      return;
    }

    const paymentName = paidIn ? 'Deposit' : 'Expense';
    const dialogBody = {title: 'Delete Payment', body: `Are you sure you want to delete this ${paymentName} ?`,
    confirmBtn: 'Yes', cancelBtn: 'Cancel'};
    const dialogRef = this.dialog.open(DialogComponent, {data: dialogBody});
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'true') {
        let body: any;
        body = {amount, user: localStorage.getItem('user'), };
        this.userService.deletePayment(paymentId, this.config.hotelId, body).subscribe(
          success => {
            this.initView();
          }, err => {

          });
      }
    });
  }

  deleteNotAllowed() {
    const dialogBody = {title: 'No permission', body: `You are not allowed to delete expense`,
    cancelBtn: 'Dismiss'};
    this.dialog.open(DialogComponent, {data: dialogBody});
  }

  downloadExcel() {
    const headerRow = ['Date', 'POS', 'Guest', 'Room no.', 'Type', 'Category', 'Expense Date', 'Invoice#', 'Comments', 'Paid in', 'Paid out'];
    const downloadData = [headerRow];
    this.payments.payments?.forEach(p => {
      p.data?.forEach(r => {
        const row = [r.date, r.pos_name, r.guest_name, r.room_num, r.type_of_payment, r.expense_category, r.expense_date, 
          r.expense_invoice_num, r.comments, (r.paid_in ? r.amount : ''), (r.paid_in ? '' : r.amount)
        ];
        downloadData.push(row);
      });
    });
    this.excelService.exportAsExcelFile(downloadData, 'expenses');
  }

  getStoreConfig() {
    this.storeService.getStoreConfig(this.config?.hotelId).subscribe(res => {
      this.storeConfig = res;
    })
  }

  selectPayment(checked, payment) {
    if (checked) {
      this.selectedPayments.push(payment)
    } else {
      this.selectedPayments = this.selectedPayments.filter(e => {
        return e.id !== payment.id;
      });
    }
  }

  getBankPaymentFile() {
    if (!this.selectedPayments?.length) {
      const dialogBody = {
        title: 'Download Payment File', 
        body: `Select expense to download payment file`, 
        confirmBtn: 'Ok'
      };
      this.dialog.open(DialogComponent, {data: dialogBody});
      return;
    }

    this.dialog.open(GetPaymentFileComponent, {
      width: '700px',
      data: {
        payments: JSON.parse(JSON.stringify(this.selectedPayments)),
        paymentType: this.paymentType,
        hotelId: this.config.hotelId,
        tdsForExpenses: this.config?.tdsForExpenses
      }
    })
  }

  goBack() { 
    if (this.expenseType === 'chain') {
      this.getHotelExpense(); 
    } else {
      this.location.back()
    }
  }

  changeHotel() {
    if (this.selectedHotel) {
      this.showPayments = this.payments?.payments.map(item => ({
        ...item,
        data: item.data.filter(entry => entry.hotel === this.selectedHotel)
      }));
    } else {
      this.showPayments = this.payments?.payments;
    }
  }
}
