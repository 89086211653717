<div class="primary_modal_wrapper">
    <div class="modal_heading">
        <h1>Send Payment Link</h1>
    </div>

    <mat-divider></mat-divider>

    <div class="modal_body">

        <div class="input_group">
            <p>Please specify details to send the Payment Link</p>

            <div>
                <label>Email</label>
                <input type="text" class="form-control form-control-sm" placeholder="Enter Email" [(ngModel)]="emails" 
                (ngModelChange)="validateEmail()">

                <p *ngIf="error.error" class="p-2 text-danger small">Invalid Email</p>
            </div>

            <div>
                <div>
                    <label>Phone No.</label>
                    <input type="text" class="form-control form-control-sm" placeholder="Enter Phone" [(ngModel)]="phoneNos" 
                        (keypress)="phoneNumValidation($event)">
                </div>
            
                <div>
                    <label>Amount</label>
                    <input type="text" class="form-control form-control-sm" placeholder="Enter Amount" [(ngModel)]="amountToPay"
                    (keypress)="numberOnly($event)">
                </div>
            </div>

        </div>
        
    </div>

    <div class="modal_footer">
        <mat-dialog-actions align="end">
            <button mat-raised-button 
                mat-dialog-close 
                [ngClass]="{disable_button: submitting}">
                Cancel
            </button>
            <button mat-raised-button
                class="primary_btn"
                [ngClass]="{disable_button: submitting}"
                (click)="submit()">
                <ng-container *ngIf="!submitting; else submittingBlock">
                    Send Link
                </ng-container>
                <ng-template #submittingBlock>
                    Sending...
                </ng-template>
            </button>
        </mat-dialog-actions>
    </div>

</div>