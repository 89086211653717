import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CompaniesService } from 'src/app/core/companies.service';

@Component({
  selector: 'app-mark-city-ledger',
  templateUrl: './mark-city-ledger.component.html',
  styleUrls: ['./mark-city-ledger.component.scss']
})
export class MarkCityLedgerComponent implements OnInit {
  currentPage = 1;
  startDate: any;
  endDate: any;
  ledgerData: any = [];
  selectedPaymentIds = {};
  filterByHotel = 'all';
  loading = false;
  hotels = []

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private matDialogRef: MatDialogRef<MarkCityLedgerComponent>,
    private companiesService: CompaniesService,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.filterByHotel = this.data.hotelId;
    this.getData();
  }


  getData() {
    this.loading = true;
    this.companiesService.getCityLedgerExtraInfo(this.data.hotelId, this.data?.company?.name, this.currentPage, this.filterByHotel)
    .subscribe(res => {
      this.loading = false;
      this.ledgerData = res?.data;
      if (!this.hotels?.length) {
        this.hotels = this.ledgerData.hotels;
      }
    });
  }

  hotelChange() {
    this.currentPage = 1;
    this.getData();
  }

  changePage(change: 'prev' | 'next') {
    if (change === 'prev' && this.currentPage > 1) {
      this.currentPage--;
      this.getData();
    } else if (change === 'next') {
      this.currentPage++;
      this.getData();
    }
  }

  submitClick() {
    const selectedPaymentIds = []
    for (const pId in this.selectedPaymentIds) {
      if (this.selectedPaymentIds[pId]) {
        selectedPaymentIds.push(pId)
      }
    }
    this.matDialogRef.close({selectedPaymentIds});
  }
}
