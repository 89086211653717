<div class="primary_modal_wrapper">
    <div class="modal_heading">
        <h1>Mark As Paid</h1>
    </div>

    <mat-divider></mat-divider>

    <div class="modal_body">
        <form [formGroup]="paymentsForm">

            <div class="flex">
                <label>Type of Payment<span class="text-danger">*</span></label>
                <select class="form-control form-control-sm" formControlName="type_of_payment">
                    <option value="" disabled>Select</option>
                    <option *ngFor="let item of paymentModes" [value]="item">{{item}}</option>
                </select>
            </div>

            <div class="flex">
                <label>Amount</label>
                <input type="text" class="form-control form-control-sm" [value]="data.amount" disabled>
            </div>

            <div class="flex">
                <label>Comments</label>
                <textarea class="form-control form-control-sm" formControlName="comments"></textarea>
            </div>
            
            <div class="flex">
                <label>Upload</label>
                <input 
                    type="file"
                    accept="image/*,.pdf"
                    (change)="fileChange($event.target)"
                    >
            </div>

            <div class="checkbox_div">
                <input type="checkbox" formControlName="create_payment" name="create_payment">
                <label>Create Payment Transaction</label>
            </div>
        </form>
        
    </div>

    <div class="modal_footer">
        <mat-dialog-actions align="end">
            <button mat-raised-button mat-dialog-close>Cancel</button>
            <button mat-raised-button
                [ngClass]="{'disable_button': paymentsForm.invalid}"
                class="primary_btn"
                (click)="submit()">
                Submit
            </button>
        </mat-dialog-actions>
    </div>

</div>