import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AccountsService } from 'src/app/core/accounts.service';
import { UserService } from 'src/app/core/user.service';

@Component({
  selector: 'app-reconcilation',
  templateUrl: './reconcilation.component.html',
  styleUrls: ['./reconcilation.component.scss']
})
export class ReconcilationComponent implements OnInit {

  startDate: any;
  endDate: any;
  hotelId: string;
  reconcilationData: any;
  paymentModes: any[] = [];
  selectedMode: string = 'Cash';
  currentPage = 1;
  selectedHotel = '';
  hotels = []

  constructor(
    private datePipe: DatePipe,
    private accountsService: AccountsService,
    private route: ActivatedRoute,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.hotelId = this.route.snapshot.params.hotelId;
    this.getConfig();
    this.getAccountsConfig();
  }

  getConfig() {
    this.userService.getProductConfig(this.hotelId).subscribe(res => {
      this.paymentModes = res?.conf?.paymentModes;
    });
  }

  startDateCalc(startDate?) {
    this.endDate = this.datePipe.transform((new Date()), 'y-MM-dd');
    this.startDate = new Date(startDate ? startDate : this.endDate);
    this.startDate = this.datePipe.transform((new Date(this.startDate)), 'y-MM-dd');
  }

  getData() {
    this.accountsService.getReconcilation(this.hotelId, this.startDate, this.endDate, this.selectedMode, this.currentPage, this.selectedHotel)
    .subscribe(res => {
      if (res?.success) {
        this.reconcilationData = res?.data;
        if (!this.hotels.length) {
          this.hotels = this.reconcilationData?.hotels;
        }
      }
    })
  }

  showMoreDetails(row) {
    this.accountsService.reconcilationMoreInfo(this.hotelId, row.paymentId)
    .subscribe(res => {
      if (res?.success) {
        row.invoiceNum = res?.data?.invoiceNum;
        row.vendorName = res?.data?.vendorName;
        row.companyName = res?.data?.companyName;
      }
    })
  }

  getAccountsConfig() {
    this.accountsService.getAccountsConfig(this.hotelId).subscribe(res => {
      this.startDateCalc(res?.conf?.financialStart);
      this.getData();
    })
  }

  
  previousPage() {
    if (this.currentPage === 1) {
      return;
    }
    this.currentPage--;
    this.getData();
  }

  nextPage() {
    if (this.reconcilationData?.payments?.length < 50) {
      return;
    }
    this.currentPage++;
    this.getData();
  }
}
