<div class="mat-dialog"
  [ngStyle]="{'width': data.width, 'height': data.height}">
  <div class="title">
    <h2 mat-dialog-title>{{data.title}}</h2>
  </div>
  
  <div *ngIf="data.innerHTML" [innerHTML]="data.innerHTML" class="body"></div>
  
  <div *ngIf="data.body" class="body" mat-dialog-content>{{data.body}}</div>
  
  <div class="actions" mat-dialog-actions>
  
    <button 
      class="confirmBtn" [ngStyle]="{'background-color': data.confirmBtnColor}"
      mat-button mat-dialog-close mat-dialog-close="true">
      {{data.confirmBtn}}
    </button>

    <button *ngIf="data.secondBtn"
      class="confirmBtn"
      mat-button mat-dialog-close mat-dialog-close="secondBtn">
      {{data.secondBtn}}
    </button>
  
    <button  *ngIf="data.cancelBtn"
      class="bg-secondary"
      mat-button mat-dialog-close 
      mat-dialog-close="false">
      {{data.cancelBtn}}
    </button>
  
  </div>
</div>