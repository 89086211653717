import { Component, OnInit } from '@angular/core';
import { DatePipe, Location } from '@angular/common';
import { FormControl, NgForm } from '@angular/forms';
import { ReservationService } from 'src/app/core/reservation.service';
import { ActivatedRoute } from '@angular/router';
import { DialogComponent } from 'src/app/_shared/dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-bulk-out-of-order',
  templateUrl: './bulk-out-of-order.component.html',
  styleUrls: ['./bulk-out-of-order.component.scss']
})

export class BulkOutOfOrderComponent implements OnInit {

  checkinDate: Date;
  checkoutDate: Date;
  roomType: String;
  comments: String;
  hotelId: any;
  roomtypes: any = [];
  hotelRoute: any;
  currentUser: any;
  currentDate: Date;
  timezone: any;
  nextdaydate: Date;
  roomNumInfo = [];
  roomNumbers = new FormControl();
  minCheckinDate: Date;
  submitting: boolean = false;
  selectedRoomId: any;
  delete = false;

  constructor( public location: Location, private reservService: ReservationService, 
    private route: ActivatedRoute, private datePipe: DatePipe, public matdialog: MatDialog,) { }

  ngOnInit(): void {
    this.delete = false;
    const deleteState = this.location.getState();
    if (deleteState && deleteState['delete']){
      this.delete = deleteState['delete'];
    }
    this.hotelRoute = this.route.params.subscribe((params) => {
      this.hotelId = params.hotelId;
    });
    this.hotelRoute = this.route.queryParams.subscribe((queryParams) => {
      this.currentUser = queryParams.user;
    });
    this.fetchRoomTypes();
    this.getTimeZone();
  }

  fetchRoomTypes(){
    this.reservService.getRoomType(this.hotelId).subscribe(result=>{
      this.roomtypes = result;
      this.roomType = result[0]['name'];
    })
  }

  cleardata() {
    this.ngOnInit();
  }

  getTimeZone(): void {
    this.reservService.getTimeZone(this.hotelId).subscribe((success) => {
      this.timezone = success['timezone'];
      this.getCurrentDate();
    });
  }

  getCurrentDate() {
    this.reservService.getCurrentDatetTime(this.hotelId).subscribe(
      (data) => {
        this.currentDate = new Date(data.currentdatetime);
        this.minCheckinDate = new Date(this.currentDate);
        let today = new Date(this.currentDate);
        let nextday = new Date(this.currentDate);
        nextday.setDate(nextday.getDate() + 1);
        if (this.datePipe.transform(new Date(this.currentDate), 'yyyy-MM-dd') == this.datePipe.transform( new Date().toLocaleString('en-US', { timeZone: this.timezone }), 'yyyy-MM-dd')) {
            this.minCheckinDate = today;
            this.checkinDate = new Date();
            this.checkoutDate = nextday;
        } else {
            this.checkinDate = today;
            this.checkoutDate = nextday;
        }
        this.days();
      },
      (err) => {}
    );
  }

  addDays(date, days) {
    const copy = new Date(Number(date));
    copy.setDate(date.getDate() + days);
    return copy;
  }
  
  days() {
    var checkinValue = new Date(this.checkinDate);
    var checkoutValue = new Date(this.checkoutDate);
    
    if (this.checkinDate > this.checkoutDate) {
      this.nextdaydate = this.addDays(checkinValue, 1);
      this.checkoutDate = this.nextdaydate;
      checkoutValue = this.nextdaydate;
    }
    this.selectedRoomId = this.roomtypes[0]['room_id'];
    if(this.delete == true){
      this.reservService.fetchBulkOutofOrders(this.hotelId, this.datePipe.transform(this.checkinDate, 'yyyy-MM-dd'), this.datePipe.transform(this.checkoutDate, 'yyyy-MM-dd'), this.selectedRoomId).subscribe(data=>{
        this.roomNumInfo = data;
        this.roomNumbers.setValue(data.map(room => room));
      })
    }else{
      this.reservService.fetchAvailableRoomNumbers(this.hotelId, this.datePipe.transform(this.checkinDate, 'yyyy-MM-dd'), this.datePipe.transform(this.checkoutDate, 'yyyy-MM-dd'), this.roomtypes[0]['room_id']).subscribe(result=>{
        this.roomNumInfo = result.availableRoomNumbers;
        if (this.roomNumInfo.length === 0){
          var WarningDialog = {title: 'Warning !', body: `Room numbers are not available for selected room type for selected dates, Kindly select different dates or different room type.`, cancelBtn: 'Dismiss', height: '200px'};
          const dialogRef = this.matdialog.open(DialogComponent, {data: WarningDialog});
          dialogRef.disableClose = true;
        }
      })
    }
  }

  isYearMoreThanFourDigits(dateString: string): boolean {
    // Parse the date
    const date = new Date(dateString);
    // Get the year component
    const year = date.getFullYear();
    // Check if the year has more than 4 digits
    return year.toString().length > 4;
  }


  changedates(dateRangeStart: HTMLInputElement = null, dateRangeEnd: HTMLInputElement = null){
    this.roomType = this.roomtypes[0]['name']
    if (this.isYearMoreThanFourDigits(this.datePipe.transform(dateRangeStart.value, 'yyyy-MM-dd'))) {
      alert('Start date year must be 4 digits.');
      this.checkinDate = null;
    }else if (this.isYearMoreThanFourDigits(this.datePipe.transform(dateRangeEnd.value, 'yyyy-MM-dd'))) {
      alert('End date year must be 4 digits.');
      this.checkoutDate = null;
    }else{
      if (dateRangeStart.value!='' && dateRangeEnd.value!=''){
        this.selectedRoomId = this.roomtypes[0]['room_id']
        if(this.delete == true){
          this.reservService.fetchBulkOutofOrders(this.hotelId, this.datePipe.transform(this.checkinDate, 'yyyy-MM-dd'), this.datePipe.transform(this.checkoutDate, 'yyyy-MM-dd'), this.selectedRoomId).subscribe(data=>{
            this.roomNumInfo = data;
            this.roomNumbers.setValue(data.map(room => room));
          })
        }else{
        this.reservService.fetchAvailableRoomNumbers(this.hotelId, this.datePipe.transform(dateRangeStart.value, 'yyyy-MM-dd'), this.datePipe.transform(dateRangeEnd.value, 'yyyy-MM-dd'), this.roomtypes[0]['room_id']).subscribe(result=>{
          this.roomNumInfo = result.availableRoomNumbers;
          if (this.roomNumInfo.length === 0){
            var WarningDialog = {title: 'Warning !', body: `Room numbers are not available for selected room type for selected dates, Kindly select different dates or different room type.`, cancelBtn: 'Dismiss', height: '200px'};
            const dialogRef = this.matdialog.open(DialogComponent, {data: WarningDialog});
            dialogRef.disableClose = true;
          }
        })
      }
    }
    }
    
  }
  
  changeRoomtype(roomtypeData){
    this.selectedRoomId = roomtypeData['room_id'];
    if(this.delete == true){
      this.reservService.fetchBulkOutofOrders(this.hotelId, this.datePipe.transform(this.checkinDate, 'yyyy-MM-dd'), this.datePipe.transform(this.checkoutDate, 'yyyy-MM-dd'), this.selectedRoomId).subscribe(data=>{
        this.roomNumInfo = data;
        this.roomNumbers.setValue(data.map(room => room));
      })
    }else{
    this.reservService.fetchAvailableRoomNumbers(this.hotelId, this.datePipe.transform(this.checkinDate, 'yyyy-MM-dd'), this.datePipe.transform(this.checkoutDate, 'yyyy-MM-dd'), roomtypeData['room_id']).subscribe(result=>{
      this.roomNumInfo = result.availableRoomNumbers;
      if (this.roomNumInfo.length === 0){
        var WarningDialog = {title: 'Warning !', body: `Room numbers are not available for selected room type for selected dates, Kindly select different dates or different room type.`, cancelBtn: 'Dismiss', height: '200px'};
        const dialogRef = this.matdialog.open(DialogComponent, {data: WarningDialog});
        dialogRef.disableClose = true;
      }
    })
  }
  }

  submit() {
    const dialogBody = {
      title: 'Bulk Out Of Order',
      body: 'Are you sure you want to Submit ?',
      confirmBtn: 'Submit',
      cancelBtn: 'Cancel',
    };
    const dialogRef = this.matdialog.open(DialogComponent, {
      data: dialogBody,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'true') {
        this.submitting = true;
        var data = {
          checkinDate : this.datePipe.transform(this.checkinDate, 'yyyy-MM-dd'),
          checkoutDate: this.datePipe.transform(this.checkoutDate, 'yyyy-MM-dd'),
          roomId: this.selectedRoomId,
          roomNumbersInfo: this.roomNumbers.value,
          comments: this.comments,
          user: this.currentUser
        }
        if(this.delete == true){
          this.reservService.bulkCancelOutofOrderBlock(this.hotelId, data).subscribe(response=>{
            this.submitting = false;
            var WarningDialog = {};
            if (response['error']) {
              WarningDialog = {title: 'Error !', body: response['error'], cancelBtn: 'Dismiss', height: '200px',};
            } else {
              WarningDialog = {title: 'Done !', body: `You Have successfully Submitted the data`, cancelBtn: 'Dismiss'};
            }
            const dialogRef = this.matdialog.open(DialogComponent, {data: WarningDialog});
            dialogRef.disableClose = true;
            dialogRef.afterClosed().subscribe((result) => {
              if (result === 'false') {
                this.matdialog.closeAll();
                window.location.reload();
              }
            });
          })
        }else{
          this.reservService.bulkOutOfOrder(data, this.hotelId).subscribe(response=>{
            this.submitting = false;
            var WarningDialog = {};
            if (response['error']) {
              WarningDialog = {title: 'Error !', body: response['error'], cancelBtn: 'Dismiss', height: '200px',};
            } else {
              WarningDialog = {title: 'Done !', body: `You Have successfully Submitted the data`, cancelBtn: 'Dismiss'};
            }
            const dialogRef = this.matdialog.open(DialogComponent, {data: WarningDialog});
            dialogRef.disableClose = true;
            dialogRef.afterClosed().subscribe((result) => {
              if (result === 'false') {
                this.matdialog.closeAll();
                window.location.reload();
              }
            });
          })
        }
        
      }
    })
  }
}
