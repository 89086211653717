import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountsService } from 'src/app/core/accounts.service';
import { finalize } from 'rxjs/operators'
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-accounts-ledger',
  templateUrl: './accounts-ledger.component.html',
  styleUrls: ['./accounts-ledger.component.scss']
})
export class AccountsLedgerComponent implements OnInit {

  params: any = {};
  loading = true;
  payments: any;
  startDate: any;
  endDate: any;
  headerName = '';
  qParams: any = {}
  currentPage = 1;
  totalColSpan = 4;
  selectedHotel: string = '';
  hotels = []

  constructor(
    private accountsService: AccountsService,
    private route: ActivatedRoute,
    private router: Router,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.params = this.route.snapshot.params;
    this.qParams = this.route.snapshot.queryParams;
    this.getAccountsConfig();
  }
  
  getAccountsConfig() {
    this.accountsService.getAccountsConfig(this.params.hotelId).subscribe(res => {
      this.startDateCalc(res?.conf?.financialStart);
      this.getLedger();
    })
  }

  getLedger() {
    if (this.qParams?.company) {
      this.headerName = JSON.parse(this.qParams?.company)?.name;
      this.totalColSpan = 5;
      this.getCompaniesLedger();
    } else {
      this.headerName = JSON.parse(this.qParams?.vendor)?.name;
      this.getVendorsLedger();
    }
  }

  getCompaniesLedger() {
    this.accountsService.getLedger(this.params.hotelId, this.params.id, this.startDate, this.endDate, this.currentPage, this.selectedHotel)
    .pipe(finalize(() => this.loading = false))
    .subscribe(res => {
      if (res?.success) {
        this.payments = res?.data;
        if (!this.hotels.length) {
          this.hotels = this.payments?.hotels;
        }
        this.currentPage = parseInt(res?.data?.currentPage);
        if (this.payments?.configLevel === 'chain') {
          this.totalColSpan = 6;
        }
      }
    });
  }

  getVendorsLedger() {
    this.accountsService.getVendorLedger(this.params.hotelId, this.params.id, this.startDate, this.endDate, this.currentPage)
    .pipe(finalize(() => this.loading = false))
    .subscribe(res => {
      if (res?.success) {
        this.payments = res?.data;
      }
    });
  }

  startDateCalc(startDate?) {
    this.endDate = this.datePipe.transform((new Date()), 'y-MM-dd');
    this.startDate = new Date(startDate ? startDate : this.endDate);
    this.startDate = this.datePipe.transform((new Date(this.startDate)), 'y-MM-dd');
  }

  goBack() {
    let tabIndex = localStorage.getItem('accountsCurrentTab');
    this.router.navigate([`accounts/${this.params.hotelId}`], {queryParams: {tabIndex}});
  }

  previousPage() {
    if (this.currentPage === 1) {
      return;
    }
    this.currentPage--;
    this.getLedger();
  }

  nextPage() {
    if (!this.payments?.rowsPerView || this.payments?.payments?.length < this.payments?.rowsPerView) {
      return;
    }
    this.currentPage++;
    this.getLedger();
  }

  dateChange() {
    this.currentPage = 1;
    this.getLedger();
  }

  changeHotel() {
    this.getLedger();
  }
}
