import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LeadsListComponent } from './leads-list/leads-list.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { CreateLeadComponent } from './create-lead/create-lead.component';
import { RouterModule } from '@angular/router';
import { CreateGroupBookingModule } from '../group-booking/create-group-booking/create-group-booking.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  declarations: [
    LeadsListComponent,
    CreateLeadComponent
  ],
  imports: [
    CommonModule,
    MatDividerModule,
    ReactiveFormsModule,
    FormsModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatTooltipModule,
    NgSelectModule,
    RouterModule,
    CreateGroupBookingModule,
    TranslateModule
  ]
})
export class LeadsModule { }
