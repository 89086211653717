import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BookingDetailsRoutingModule } from './booking-details-routing.module';
import { BookingDetailsComponent } from './booking-details.component';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';


@NgModule({
  declarations: [BookingDetailsComponent],
  imports: [
    CommonModule,
    BookingDetailsRoutingModule, MatIconModule, MatTabsModule
  ]
})
export class BookingDetailsModule { }
