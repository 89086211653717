import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountsService } from 'src/app/core/accounts.service';

@Component({
  selector: 'app-hsn-summary',
  templateUrl: './hsn-summary.component.html',
  styleUrls: ['./hsn-summary.component.scss']
})
export class HsnSummaryComponent implements OnInit {

  hsnSummary: any = {};
  hotelId = '';
  tableData = []

  constructor(
    private accountsService: AccountsService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.hotelId = params.hotelId;
      try {
        this.hsnSummary = JSON.parse(params?.data);
        this.createTable();
      }
      catch {

      }
    });
  }

  createTable() {
    const arr = [];
    for (const key in this.hsnSummary) {
      arr.push(this.hsnSummary[key]);
    }
    this.tableData = arr;
  }

  back() {
    this.accountsService.currentTab = 'gstr1';
    this.router.navigate([`accounts/${this.hotelId}`], {queryParams: {tabName: 'gstr1', fromBack: true}});
  }

}
