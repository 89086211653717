<div class="container">

    <button class="d-flex ml-auto btn-sm pr-border"
        (click)="getBankPaymentFile()">
        Get Bank Payment File
    </button>

    <div class="row">
        <div class="col-sm-2">
            <label for="">{{'stores.start_date' | translate}} :</label>
            <input type="date" class="form-control form-control-sm" [(ngModel)]="startDate"
            (change)="getOrders()" max="{{endDate}}">
        </div>
        <div class="col-sm-2">
            <label for="">{{'stores.end_date' | translate}} :</label>
            <input type="date" class="form-control form-control-sm" [(ngModel)]="endDate"
            (change)="getOrders()" min="{{startDate}}">
        </div>
        <div class="col-sm-2" [ngClass]="{'invisible': tab === 'invoice'}">
            <label for="">{{'stores.status' | translate}} :</label>
            <select class="form-control form-control-sm"  [(ngModel)]="selectedStatus" (change)="getOrders()">
                <ng-container *ngFor="let status of statuses | keyvalue">
                    <option *ngIf="status.key !== 'invoiced' " [value]="status.value">
                        {{status.value}}
                    </option>
                </ng-container>
            </select>
        </div>

        <div class="offset-sm-2 col-sm-4">
            <label for="" class="invisible">create</label>
            <div class="d-flex justify-content-end">
                <button *ngIf="tab === 'purchaseOrder' && features.purchaseOrder.create" mat-stroked-button 
                    class="btn-sm pr-border mr-2" (click)="openOrder('new')">{{'stores.create_btn' | translate}}
                </button>
                <input type="search" id="search" class="form-control form-control-sm" placeholder="{{'stores.search' | translate}} . . ."
                (input)="filterOrders($event.target.value)">
            </div>
        </div>
    </div>

    <table class="mt-4 table table-striped">
        <thead class="thead-dark">
            <tr>
                <th *ngIf="tab === 'invoice'"></th>
                <th>{{'stores.order' | translate}} #</th>
                <th *ngIf="tab === 'purchaseOrder'">{{'stores.date' | translate}}</th>
                <th *ngIf="tab === 'invoice'">{{'stores.invoice_date' | translate}}</th>
                <th class="text-right">{{'stores.pre_tax' | translate}}</th>
                <th class="text-right">{{'stores.tax' | translate}}</th>
                <th class="text-right">{{'stores.total' | translate}}</th>
                <th class="text-center">{{'stores.status' | translate}}</th>
                <th *ngIf="tab === 'invoice'">{{'stores.invoice' | translate}}#</th>
                <th>{{'stores.vendor' | translate}}</th>
                <th *ngIf="tab === 'invoice'">{{'stores.goods_received' | translate}}</th>
                <th *ngIf="tab === 'purchaseOrder' && features.purchaseOrder.approve" [ngStyle]="{'width': '190px'}"></th>
                <th [ngStyle]="{'width': '70px'}"></th>
            </tr>
        </thead>

        <tbody>
            <tr *ngFor="let order of $orders">
                <td *ngIf="tab === 'invoice'">
                    <div class="d-flex gap-1">
                            <span *ngIf="order.paid else checkBlock">Paid</span>
                            <ng-template #checkBlock>
                                <input type="checkbox" (change)="selectInvoices($event.target.checked, order.id)">
                            </ng-template>
                    </div>
                </td>
                <td>{{order.num}}</td>
                <td *ngIf="tab === 'purchaseOrder'">{{order.date | date: 'dd MMM hh:mm a'}}</td>
                <td *ngIf="tab === 'invoice'">{{order.invoice_date | date: 'dd MMM'}}</td>
                <td class="text-right">{{order.pre_tax_amount | number: '1.2-2'}}</td>
                <td class="text-right">{{order.tax.total | number: '1.2-2'}}</td>
                <td class="text-right">{{order.total_amount | number: '1.2-2'}}</td>
                <td class="text-center">{{order.status}}</td>
                <td *ngIf="tab === 'invoice'">{{order.invoice_num}}</td>
                <td>{{order.vendor?.name}}</td>
                <td *ngIf="tab === 'invoice'">
                    <button mat-stroked-button *ngIf="!order.goods_received" class="btn-sm border-danger fs-12" [ngStyle]="{'width': '60px'}"
                        data-toggle="tooltip" data-placement="top" title="Change to Yes"
                        (click)="goodsReceived(order.id)">
                        {{'stores.no_btn' | translate}}
                    </button>
                    <button mat-stroked-button *ngIf="order.goods_received" 
                        class="btn-sm border-success text-success fs-12" 
                        [ngStyle]="{'width': '60px', 'pointer-events': 'none'}">
                        {{'stores.yes_btn' | translate}}
                    </button>
                </td>
                <td *ngIf="tab === 'purchaseOrder' && features.purchaseOrder.approve" 
                    class="d-flex justify-content-between align-items-center">

                    <button mat-stroked-button class="btn-sm border-warning fs-12"
                        (click)="changeOrderStatus(statuses.approved, order.id, order.vendor?.id)"
                        [disabled]="order.status === 'Approved'">
                        {{'stores.approve_btn' | translate}}
                    </button>
                    <button mat-stroked-button class="btn-sm border-secondary fs-12"
                        (click)="changeOrderStatus(statuses.invoiced, order.id, order.vendor?.id)"
                        [disabled]="order.status === 'Applied'">
                        {{'stores.invoice_btn' | translate}}
                    </button>
                </td>
                <td>
                    <div *ngIf="tab === 'purchaseOrder'" class="d-flex justify-content-between">
                        <mat-icon class="text-primary" 
                            (click)="viewOrder(order.id, order.num, order.vendor, features.purchaseOrder.edit, order.invoice_num, null, order.selectedFiles)">
                            {{features.purchaseOrder.edit ? 'edit': 'visibility'}}
                        </mat-icon>
                        <mat-icon *ngIf="features.purchaseOrder.edit" class="text-danger"
                            (click)="deleteOrder(order.id, order.num)">
                            delete
                        </mat-icon>
                    </div>

                    <div *ngIf="tab === 'invoice'" class="d-flex justify-content-between">
                        <mat-icon *ngIf="order.goods_received" class="text-primary" 
                            (click)="viewOrder(order.id, order.num, order.vendor, false, order.invoice_num, order.invoice_date, order.selectedFiles)">
                            visibility
                        </mat-icon>
                        <mat-icon *ngIf="!order.goods_received" class="text-primary" 
                            (click)="viewOrder(order.id, order.num, order.vendor, features.purchaseOrder.edit, order.invoice_num, order.invoice_date, order.selectedFiles)">
                            edit
                        </mat-icon>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</div>