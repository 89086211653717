<div>
    <div class="aio_heading">
        <h2>Document Summary</h2>
    
        <button mat-raised-button class="primary_btn" (click)="back()">
          Back
        </button>
    </div>

    <mat-divider></mat-divider>

    <div class="container-fluid common_content">
        
        <table class="table">
            <thead class="thead-dark">
                <tr>
                    <th>Hotel</th>
                    <th>POS</th>
                    <th>From</th>
                    <th>To</th>
                    <th>Total No.</th>
                    <th>Cancelled</th>
                    <th>Net Issued</th>
                </tr>
            </thead>

            <tbody>
                <tr *ngFor="let item of tableData">
                    <td>{{item.hotelName}}</td>
                    <td>{{item.posName}}</td>
                    <td>{{item.from}}</td>
                    <td>{{item.to}}</td>
                    <td>{{item.totnum}}</td>
                    <td>{{item.cancel}}</td>
                    <td>{{item.net_issue}}</td>
                </tr>
            </tbody>

        </table>

    </div>

</div>
