import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountsService } from 'src/app/core/accounts.service';
import { saveAs } from 'file-saver-es';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from 'src/app/_shared/dialog/dialog.component';
import { ExcelServicesService } from 'src/app/_shared/services/excel-file.service';
import Decimal from 'decimal.js';

@Component({
  selector: 'app-gstr1',
  templateUrl: './gstr1.component.html',
  styleUrls: ['./gstr1.component.scss']
})
export class Gstr1Component implements OnInit {

  startDate: any;
  endDate: any;
  hotelId: string;
  state: string = '';
  gstr1Data: any = {};
  tableData = []

  constructor(
    private accountsService: AccountsService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private excelService: ExcelServicesService
  ) { }

  ngOnInit(): void {
    const routeSnap = this.route.snapshot
    this.hotelId = routeSnap.params.hotelId;
    this.getParams();
    this.setDates();
    
    if (routeSnap.queryParams?.fromBack) {
      const gstr1Data = sessionStorage.getItem('gstr1TempStore');
      try {
        this.gstr1Data = JSON.parse(gstr1Data);
        this.createTableData();
      } catch {
        this.getGstr1Data();
      }
    } else {
      this.getGstr1Data();
    }
  }

  setDates() {
    const today = new Date();
    const firstDayLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    const lastDayLastMonth = new Date(today.getFullYear(), today.getMonth(), 0);

    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    }

    this.startDate = formatDate(firstDayLastMonth);
    this.endDate = formatDate(lastDayLastMonth);
  }

  getGstr1Data() {
    this.storeParams();
    this.accountsService.getGstr1(this.hotelId, this.startDate, this.endDate, this.state).subscribe(res => {
      if (res?.success) {
        this.gstr1Data = res.data;
        const strData = JSON.stringify(this.gstr1Data);
        sessionStorage.setItem('gstr1TempStore', strData);
        this.createTableData();
      }
    });
  }

  createTableData() {
    const data = this.gstr1Data;
    this.tableData = [
      {
          'particular': 'B2B Invoices - 4A, 4B, 4C, 6B, 6C', 
          'voucherCount': data.largeInvData['voucherCount'], 
          'taxableAmt': data.largeInvData['preTaxAmt'],
          'igst': data.largeInvData['igst'],
          'cgst': data.largeInvData['cgst'],
          'sgst': data.largeInvData['sgst'],
          'cess': 0,
          'totalTax': data.largeInvData['totalTax'],
          'totalInvoiceAmt': data.largeInvData['totalInvoiceAmt'],
      },
      {'particular': 'B2C(Large) Invoices - 5A, 5B', 'voucherCount': ''},
      {
          'particular': 'B2C(Small) Invoices - 7', 
          'voucherCount': data.smallInvData['voucherCount'], 
          'taxableAmt': data.smallInvData['preTaxAmt'],
          'igst': data.smallInvData['igst'],
          'cgst': data.smallInvData['cgst'],
          'sgst': data.smallInvData['sgst'],
          'cess': 0,
          'totalTax': data.smallInvData['totalTax'],
          'totalInvoiceAmt': data.smallInvData['totalInvoiceAmt']      
      },
      {'particular': 'Credit/Debit Notes(Registered) - 9B', 'voucherCount': ''},
      {'particular': 'Credit/Debit Notes(Unregistered) - 9B', 'voucherCount': ''},
      {'particular': 'Export Invoices - 6A', 'voucherCount': ''},
      {'particular': 'Tax Liability(Advances Received) - 11A(1) 11A(2)', 'voucherCount': ''},
      {'particular': 'Adjustment of Advances: 11B(1) 11B(2)', 'voucherCount': ''},
      {
        'particular': 'Nill Rated Invoices - 8A, 8B, 8C, 8D', 
        'voucherCount': data.nilInvData['voucherCount'], 
        'taxableAmt': data.nilInvData['preTaxAmt']
      }
    ]
  }

  getSummary(i) {
    if (i === 0) {
      this.getB2BSummary();
    } else if (i === 2) {
      this.getB2CSummary();
    } else if (i === 8) {
      this.getNilInvoiceDetails()
    }
  }

  // summary routes start
  getHsnSummary() {
    this.router.navigate(['accounts/taxes/hsn-summary', 
    {data: JSON.stringify(this.gstr1Data?.hsnData?.data), hotelId: this.hotelId, startDate: this.startDate, endDate: this.endDate, state: this.state}])
  }

  getB2BSummary() {
    this.router.navigate(['accounts/taxes/b2b-summary', 
        {hotelId: this.hotelId, startDate: this.startDate, endDate: this.endDate, state: this.state}])
  }

  getDocSummary() {
    this.router.navigate(['accounts/taxes/doc-summary', 
    {data: JSON.stringify(this.gstr1Data?.docSummary?.data), hotelId: this.hotelId, startDate: this.startDate, endDate: this.endDate, state: this.state}])
  }

  getB2CSummary() {
    this.router.navigate(['accounts/taxes/b2c-summary', 
    {data: JSON.stringify(this.gstr1Data?.groupStateTax), hotelId: this.hotelId, startDate: this.startDate, endDate: this.endDate, state: this.state}])
  }

  getNilInvoiceDetails() {
    this.accountsService.getInvoiceDetails(this.hotelId, this.gstr1Data?.nilInvData?.invoiceIds).subscribe(res => {
      this.router.navigate(['accounts/taxes/nil-invoice-details', 
      {data: JSON.stringify(res?.data), hotelId: this.hotelId}])
    })
  }

  // summary routes end

  storeParams() {
    sessionStorage.setItem('gstr1StartDate', this.startDate);
    sessionStorage.setItem('gstr1EndDate', this.endDate);
    sessionStorage.setItem('gstr1state', this.state);
  }

  getParams() {
    this.startDate = sessionStorage.getItem('gstr1StartDate') ? sessionStorage.getItem('gstr1StartDate') : this.startDate;
    this.endDate = sessionStorage.getItem('gstr1EndDate') ? sessionStorage.getItem('gstr1EndDate') : this.endDate;
    this.state = sessionStorage.getItem('gstr1state') ? sessionStorage.getItem('gstr1state') : this.state;
  }

  goBack() {
    this.accountsService.currentTab = 'taxes';
  }


  // Download JSON

  downloadJsonFile() {
    if (this.gstr1Data?.configLevel === 'chain' && !this.state) {
      this.dialog.open(DialogComponent, {data: {title: 'Error', body: `Please select a state`, confirmBtn: 'Ok'}});
      return;
    }

    const confirmDialog = this.dialog.open(DialogComponent, {
      data: {title: 'Download', 
        innerHTML: `Include B2B invoices in JSON.<br> <small>(This can be avoided if you are using an automatic E-invoicing system)</small>`, 
        confirmBtn: 'Yes', cancelBtn: 'No'
      },
      height: '170px'
    });

    confirmDialog.afterClosed().subscribe(res => {
        if (!this.jsonValidation()) {
          return;
        }
        const jsonObj = this.createJson(res);
        const text = JSON.stringify(jsonObj);
        const blob = new Blob([text], { type: 'application/json' });
        saveAs(blob, 'gst_json_file.json');
    })
  }
  
  jsonValidation(): boolean {
    const errorDialog = (msg) => {
      this.dialog.open(DialogComponent, {
        data: {
          title: 'Error',
          body: msg,
          cancelBtn: 'Ok'
        }
      })
    }

    if (!this.gstr1Data?.gstin || this.gstr1Data?.gstin?.length !== 15) {
      errorDialog('Invalid GST No.');
      return false;
    }


    // doc summary
    const docData = this.gstr1Data.docSummary.data;
    for (const key in docData) {
      const docObj = docData[key];
      if (docObj.to.includes(':') || docObj.from.includes(':')) {
        errorDialog(`Special characters detected for ${docObj.to} and ${docObj.from}`);
        return false;
      }
      else if (docObj.to?.length > 16 || docObj.from.length > 16) {
        errorDialog(`Length greater than 16 for ${docObj.to} and ${docObj.from}`);
        return false;
      }
    }

    return true;
  }

  createJson(includeB2b) {
    const gstr1Data = JSON.parse(JSON.stringify(this.gstr1Data));
    const jsonObj: any = {
      gstin: gstr1Data?.gstin || '',
      fp: this.getFp(),
      b2b: [],
      b2cs: [],
      nil: {inv: []},
      hsn: {data: []},
      doc_issue: {doc_det: []}
    }
    
    // b2b
    if (includeB2b === 'true') {
      const b2bData = gstr1Data.gstBillTo;
      for  (const key in b2bData) {
        const invData = [];
        this.reCalcTaxB2b(b2bData[key].invoiceIds);
        for (const inv in b2bData[key].invoiceIds) {
          const invObj = b2bData[key].invoiceIds[inv];
          if (!invObj?.itms?.length) {
            continue;
          }
          delete invObj['extras']
          invData.push(invObj)
        }

        if (invData.length) {
          jsonObj.b2b.push({
            ctin: b2bData[key].ctin,
            inv: invData
          });
        }
      }
    } else {
      delete jsonObj['b2b'];
    }

    // b2c
    const b2cData = gstr1Data.b2cs;
    for (const key in b2cData) {
      this.reCalcTaxB2c(b2cData[key])
      jsonObj.b2cs.push(b2cData[key]);
    }

    // nil
    const nilData = gstr1Data.nilInvData;
    
    if (nilData.invoices.length) {
      jsonObj.nil.inv = nilData.invoices;
    } else {
      delete jsonObj['nil'];
    }

    // hsn
    const hsnData = gstr1Data.hsnData.data;
    let index = 1;
    for (const key in hsnData) {
      const hsnObj = hsnData[key];
      const delKeys = ['serviceIds', 'totalAmount', 'preTaxAmount'];
      for (const k of delKeys) {
        delete hsnObj[k]
      }
      hsnObj.num = index;
      jsonObj.hsn.data.push(hsnObj);
      index++;
    }

    // doc issue
    const docData = gstr1Data.docSummary.data;
    const docsArray = [];
    let di = 0;
    for (const key in docData) {
      di++;
      const docObj = docData[key];
      const delKeys = ['hotelId', 'hotelName', 'posName'];
      for (const k of delKeys) {
        delete docObj[k]
      }
      docObj.num = di;
      docsArray.push(docObj)
    }
  
    jsonObj.doc_issue.doc_det.push({
      doc_num: 1,
      docs: docsArray
    })
    return jsonObj;
  }

  getFp(): string {
    let currentDate = new Date(this.startDate);
    let month: number | string = currentDate.getMonth() + 1;
    let year = currentDate.getFullYear();

    if (month < 10) {
      month = '0' + month;
    }

    let formattedDate = month.toString() + year.toString();
    return formattedDate; 
  }

  downloadExcel() {
    const roomAllocations = this.gstr1Data?.roomAllocations;
    const headerRow = ['Type', 'State', 'GSTIN/NUM', 'Guest Name', 'Checkin', 'Checkout', 'Source', 'Guest GST', 'Company', 'Billed Date', 
    'Invoice no.', 'Folio no.', 'Taxable Amount', 'Integrated Tax Amount', 'Central Tax Amount', 'State Tax Amount', 'Cess Amount', 'Tax Amount', 
    'Invoice Amount', 'E-Invoice Link'];

    const invoices = [];
    const invoicesDone = {}
    // B2B invoices
    for (const gstN in this.gstr1Data?.gstBillTo) {
      const comp = this.gstr1Data?.gstBillTo[gstN]
      for (const invId in comp.invoiceIds) {
        const inv = comp.invoiceIds[invId]?.extras;
        const roomInfo = roomAllocations?.[inv.roomAllocationId];
        if (invoicesDone[invId]) {
          continue;
        }
        invoices.push([
          'B2B', this.state, this.gstr1Data?.gstin, roomInfo?.guestName, roomInfo?.checkinDate, roomInfo?.checkoutDate, roomInfo?.source, comp.ctin, comp.billTo, 
          inv.billedDate, comp.invoiceIds[invId].inum, roomInfo.folioNum, inv.preTaxAmt, inv.igst, inv.cgst, inv.sgst, '', inv.totalTax, inv.totalAmt, inv.eInvoiceLink]);
        invoicesDone[invId] = true;
      }
    };

    // B2C Invoices
    for (const st in this.gstr1Data?.groupStateTax) {
      const gs = this.gstr1Data?.groupStateTax[st];

      for (const invId in gs.invoiceDetails) {
        const inv = gs.invoiceDetails[invId];
        const roomInfo = roomAllocations?.[inv.roomAllocationId];
        if (invoicesDone[invId]) {
          continue;
        }
        invoices.push(['B2C', this.state, this.gstr1Data?.gstin, roomInfo?.guestName, roomInfo?.checkinDate, roomInfo?.checkoutDate, roomInfo?.source, inv.gstNum, '',
        inv.billedDate, inv.invoiceNum, roomInfo?.folioNum, inv.preTaxAmt, inv.igst, inv.cgst, inv.sgst, '', inv.totalTax, inv.total, inv.eInvoiceLink]);
        invoicesDone[invId] = true;
      }
    }

    invoices.sort((a, b) => {
      const dateA = new Date(a[18]).getTime();
      const dateB = new Date(b[18]).getTime();
      return dateA - dateB
    });

    const colWidths = [10, 25, 25, 30, 20, 20, 20, 30, 30, 20, 20, 20, 10, 10, 10, 10, 10, 10, 15, 40] 
    const downloadData = [headerRow, ...invoices];
    this.excelService.exportAsExcelFile(downloadData, 'Invoices', colWidths);
  }

  reCalcTaxB2b(invoices) {
    for (const inv in invoices) {
      let items = invoices[inv].itms;
      
      // aggregate based on tax rate
      if (items?.length > 1) {
        items = this.aggregateB2BItems(items);
        invoices[inv].itms = items;
      }
      for (const it of items) {
        it.itm_det.txval = this.roundToTwo(it.itm_det.txval);
        const preTax = it.itm_det.txval;
        const taxAmt = (preTax * it.itm_det.rt) / 100;
        const taxbreak = this.roundToTwo(taxAmt/2);
        it.itm_det.camt = taxbreak;
        it.itm_det.samt = taxbreak;
      } 
    }
  }

  aggregateB2BItems(items) {
    const aggregatedData = {};
    items.forEach(item => {
      const { rt, txval, camt, csamt, iamt, samt } = item.itm_det;
      if (!aggregatedData[rt]) {
        aggregatedData[rt] = {
          txval: 0,
          camt: 0,
          csamt: 0,
          iamt: 0,
          samt: 0
        };
      }

      aggregatedData[rt].txval += txval;
      aggregatedData[rt].camt += camt;
      aggregatedData[rt].csamt += csamt;
      aggregatedData[rt].iamt += iamt;
      aggregatedData[rt].samt += samt;
    });

    const res = []; let index = 0;
    for (const rt in aggregatedData) {
      const itm_det = {rt: parseFloat(rt), ...aggregatedData[rt]};
      res.push({itm_det, num: ++index})
    }
    
    return res;
  }

  reCalcTaxB2c(obj) {
    const taxAmt = (obj.txval * obj.rt) / 100;
    const taxbreak = this.roundToTwo(taxAmt/2);
    obj.camt = taxbreak;
    obj.samt = taxbreak;
  }

  roundToTwo(num) {
    num = new Decimal(num);
    return parseFloat(num.toDecimalPlaces(2, Decimal.ROUND_HALF_UP));
  }

}
