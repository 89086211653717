import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };
  @Injectable({
    providedIn: 'root',
  })

  export class PMSOpsService {
    constructor(private http: HttpClient) {}

    fetchFieldsData(hotelId): Observable<any> {
        return this.http.get<any>(`${environment.apiUrl}/fetch_pms_ops_config/${hotelId}`);
      }

    addPMSOpsInfo(hotelId, date, dailydata, weeklydata, monthlydata) {
      const data = new FormData();
      data.append('date', JSON.stringify(date['date']))
      data.append('dailydata', JSON.stringify(dailydata['textdata']))
      data.append('weeklydata', JSON.stringify(weeklydata['textdata']))
      data.append('monthlydata', JSON.stringify(monthlydata['textdata']))
      for (var file in dailydata['filedata']){
        data.append(file, dailydata['filedata'][file]);
      }
      for (var file in weeklydata['filedata']){
        data.append(file, weeklydata['filedata'][file]);
      }
      for (var file in monthlydata['filedata']){
        data.append(file, monthlydata['filedata'][file]);
      }
      
      return this.http.post<any>(`${environment.apiUrl}/add_pms_ops_data/${hotelId}`, data);
    }

    fetchPmsOpsData(hotelId, fromDate, toDate): Observable<any> {
      return this.http.get<any>(`${environment.apiUrl}/fetch_pms_ops_data/${hotelId}`, {params: {'fromDate': fromDate, 'toDate': toDate}});
    }
  }