import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { DialogComponent } from 'src/app/_shared/dialog/dialog.component';
import { MaintenanceIssueService } from 'src/app/core/maintenance-issue.service';
import { ReservationService } from 'src/app/core/reservation.service';
import { saveAs } from 'file-saver-es';

@Component({
  selector: 'app-create-laundry',
  templateUrl: './create-laundry.component.html',
  styleUrls: ['./create-laundry.component.scss']
})
export class CreateLaundryComponent implements OnInit {
  mode = 'new';
  sentDate : string;
  receivedDate: string;
  quantity: number = 1;
  status  = 'Sent';
  item: string;
  comments : string;
  submitting = false;
  hotelId: any;
  items = [];
  laundryItems = [];
  timezone: any;
  currentDate: Date;
  laundyId = '';
  listOfItems = [];
  selectedDate : string;
  downloadDisabled: boolean = false;
  laundryStockInfo = [];

  constructor(  @Inject(MAT_DIALOG_DATA) public data: any,
              private dialog: MatDialog,
              private guestService: ReservationService,
              private datePipe: DatePipe,
              private maintService: MaintenanceIssueService,) { }

  ngOnInit(): void {
    this.mode = this.data.mode;
    this.hotelId = this.data.hotelId;
    this.getConfig();
    if(this.mode == 'edit'){
      this.getConfig();
      this.laundyId = this.data.laundryData.id;
      this.sentDate = this.datePipe.transform(this.data.laundryData.sentDate, 'yyyy-MM-dd');
      this.receivedDate = this.datePipe.transform(this.data.laundryData.receivedDate, 'yyyy-MM-dd');
      this.status = this.data.laundryData.status;
      this.quantity = this.data.laundryData.quantity;
      this.item = this.data.laundryData.item;
      this.comments = this.data.laundryData.comments;
    }
    if(this.mode == 'stock'){
      this.laundryStockInfo = this.data.laundryStockData;
    }
    this.getTimeZone();
  }

  getConfig() {
    this.maintService.getMaintenanceConfig(this.hotelId).subscribe((data) => {
      if (data?.isEnabled) {
        this.laundryItems = data.conf.laundryItems;
      }
    })
  }
  
  getTimeZone(): void {
    this.guestService.getTimeZone(this.hotelId).subscribe((success) => {
      this.timezone = success['timezone'];
      this.getCurrentDate();
    });
  }

  getCurrentDate(){
    this.guestService.getCurrentDatetTime(this.hotelId).subscribe(
      (data) => {
        this.currentDate = new Date(data.currentdatetime);
        if(this.mode != 'summary' && this.mode != 'stock'){
          const datecopy = new Date(Number(this.currentDate));
          datecopy.setDate(this.currentDate.getDate() - 1);
          (<HTMLInputElement>document.getElementById('sentDate')).min =
            this.datePipe.transform(datecopy, 'yyyy-MM-dd');
          let today = new Date(this.currentDate);
          if (this.mode == 'new'){
            if (
              this.datePipe.transform(new Date(this.currentDate), 'yyyy-MM-dd') ==
              this.datePipe.transform(
                new Date().toLocaleString('en-US', { timeZone: this.timezone }),
                'yyyy-MM-dd'
              )
            ) {
                this.sentDate = this.datePipe.transform(
                  new Date().toLocaleString('en-US', { timeZone: this.timezone }),
                  'yyyy-MM-dd'
                );
                this.receivedDate = this.datePipe.transform(today, 'yyyy-MM-dd');
            } else {
                this.sentDate = this.datePipe.transform(today, 'yyyy-MM-dd');
                this.receivedDate = this.datePipe.transform(today, 'yyyy-MM-dd');
              }
          }else{
            if (!this.data.laundryData.receivedDate){
              this.receivedDate = this.datePipe.transform(today, 'yyyy-MM-dd');
            }
          }
        }else{
          this.selectedDate = this.datePipe.transform(this.currentDate, 'yyyy-MM-dd');
        }
        
        
        })
      }

  close(){
    window.location.reload();
  }

  addListOfItems(){
    var data = {
      sentDate: this.sentDate,
      item : this.item,
      status: this.status,
      quantity: this.quantity,
      comments: this.comments
    }
    if(this.status == 'Sent'){
      data['receivedDate'] = '';
    }else{
      data['receivedDate'] = this.receivedDate;
    }if(this.status == 'Received'){
      data['sentDate'] = '';
    }else{
      data['sentDate'] = this.sentDate;
    }
    this.listOfItems.push(data)
  }

  removeItemFromList(index){
    this.listOfItems.splice(index, 1);
  }

  downloadSummary() {
    this.downloadDisabled = true;
    this.maintService.downloadLaundrySummary(this.hotelId, this.selectedDate).subscribe((data) => {
      let blob: any = new Blob([data], { type: 'text/pdf; charset=utf-8' });
      let fileName = `laundrySummary-`+this.selectedDate+`.pdf`;
      saveAs(blob, fileName);
    });

    setTimeout(() => {
      this.downloadDisabled = false;
    }, 5000);
  }

  submit(){
    if (this.status == 'Received' && (this.receivedDate==null)){
      const WarningDialog = {
        title: 'Error',
        body: `Kindly provide correct Received Date and submit`,
        cancelBtn: 'Dismiss',
      };
      this.dialog.open(DialogComponent, { data: WarningDialog });
    }else if(this.item == null || this.item == ''){
      const WarningDialog = {
        title: 'Error',
        body: `Kindly select item and submit`,
        cancelBtn: 'Dismiss',
      };
      this.dialog.open(DialogComponent, { data: WarningDialog });
    }
    else{
        const dialogBody =
        { title: 'Add Laundry',
          body: `Do You Want To Submit?`,
          confirmBtn: 'Submit',
          cancelBtn: 'Cancel'
        };
        const dialogRef = this.dialog.open(DialogComponent, {data: dialogBody});

        dialogRef.afterClosed().subscribe(result => {
          if (result === 'true'){
            var data = {
              'sentDate': this.sentDate,
              'receivedDate': this.receivedDate,
              'status': this.status,
              'item': this.item,
              'quantity': this.quantity,
              'comments': this.comments
            }
            if (this.mode != 'new'){
              data['laundryId'] = this.laundyId;
              this.submitting = true;
              this.maintService.updateLaundry(this.hotelId, data).subscribe(success=>{
                this.submitting = false;
                window.location.reload();
              })
            }else{
              this.submitting = true;
              this.maintService.addLaundry(this.hotelId, this.listOfItems).subscribe(success=>{
                this.submitting = false;
                window.location.reload();
              })
            }
           
          }
        })
      }
    
  }
}

