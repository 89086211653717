<div>
    <div class="aio_heading">
        <h2>HSN/SAC View</h2>
    
        <button mat-raised-button class="primary_btn" (click)="back()">
          Back
        </button>
    </div>

    <mat-divider></mat-divider>

    <div class="container-fluid common_content">
        
        <table class="table">
            <thead class="thead-dark">
                <tr>
                    <th>HSN/ SAC</th>
                    <th>Description</th>
                    <th>Type of Supply</th>
                    <th>UQC</th>
                    <th>Total Quantity</th>
                    <th>Total Value</th>
                    <th>Tax Rate</th>
                    <th>Taxable Amount</th>
                </tr>
            </thead>

            <tbody>
                <tr *ngFor="let item of tableData">
                    <td>{{item.hsn_sc}}</td>
                    <td>{{item.desc}}</td>
                    <td>Services</td>
                    <td>NA</td>
                    <td>0</td>
                    <td>{{item.totalAmount | number: '1.2-2'}}</td>
                    <td>{{item.rt}} %</td>
                    <td>{{item.preTaxAmount | number: '1.2-2'}}</td>
                </tr>
            </tbody>

        </table>

    </div>

</div>
