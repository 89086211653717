import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserService } from 'src/app/core/user.service';

@Component({
  selector: 'app-mark-paid',
  templateUrl: './mark-paid.component.html',
  styleUrls: ['./mark-paid.component.scss']
})
export class MarkPaidComponent implements OnInit {

  paymentsForm: FormGroup;
  paymentModes: string[] = ['Cash', 'Credit Card', 'Wallet'];
  uploadedFile: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<MarkPaidComponent>,
    private fb: FormBuilder,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.getConfig();
    this.initializeForm();
  }

  getConfig() {
    this.userService.getProductConfig(this.data.hotelId).subscribe((data) => {
      if (data?.conf?.paymentModes?.length) {
        this.paymentModes = data?.conf?.paymentModes?.filter((m) => m !== 'Bill to Company');
      }
    });
  }

  initializeForm() {
    this.paymentsForm = this.fb.group({
      company_id: [this.data.companyId],
      paid_in: ['true'],
      type_of_payment: ['', [Validators.required]],
      amount: [this.data.amount],
      comments: [''],
      hotel_id: [this.data.hotelId],
      user: [localStorage.getItem('user')],
      create_payment: [true]
    });
  }

  fileChange(e) {
    this.uploadedFile = e.files[0];
  }

  submit() {
    if (this.paymentsForm.invalid) {
      return;
    }

    this.dialogRef.close({submit: true, formData: this.paymentsForm.value, file: this.uploadedFile})

  }

}
