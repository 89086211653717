<div>
    <div class="aio_heading">
        <h2>Nil Invoices</h2>
    
        <button mat-raised-button class="primary_btn" (click)="back()">
          Back
        </button>
    </div>

    <mat-divider></mat-divider>

    <div class="container-fluid common_content">
        <table class="table">
            <thead class="thead-dark">
                <tr>
                    <th>Guest Name</th>
                    <th>Source</th>
                    <th>Checkin</th>
                    <th>Checkout</th>
                    <th>Amount</th>
                    <th>Invoice num</th>
                </tr>
            </thead>

            <tbody>
                <tr *ngFor="let item of invoiceData">
                    <td>{{item.guestName}}</td>
                    <td>{{item.source}}</td>
                    <td>{{item.checkin}}</td>
                    <td>{{item.checkout}}</td>
                    <td>{{item.amount | number: '1.2-2'}}</td>
                    <td>{{item.invoiceNum}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>