<div class="mat-dialog">
  <div class="flex flex-column">
    <div class="title">
      <h2 mat-dialog-title>{{'stores.send_email' | translate}}</h2>
      <mat-divider></mat-divider>
    </div>

    <div class="body">
      <div class="d-flex flex-column mt-2">
        <label>{{'stores.vendor_email_id' | translate}}:</label>
        <input type="text" class="form-control form-control-sm" [(ngModel)]="data.vendorEmail">
      </div>

      <div class="d-flex flex-column mt-3">
        <label>{{'stores.additional_email_id_cc' | translate}}:</label>
        <input type="text" class="form-control form-control-sm" 
          [(ngModel)]="ccEmail"
          (input)="addEmail()"
          placeholder="Enter comma seperated emails. Ex: xyz@mail.com, abc@mail.com">
      </div>
      <div class="mt-3">
        <ng-container *ngFor="let email of emailList">
          <span 
            class="email-block"
            [ngClass]="{
              'alert alert-success': isValidEmail(email),
              'alert alert-danger': !isValidEmail(email)}">
            {{email}}
          </span>
        </ng-container>
      </div>

      <div class="d-flex flex-column mt-3">
        <label for="message">{{'stores.message' | translate}}:</label>
        <textarea row="5"
          id="message"
          class="form-control form-control-sm" 
          [(ngModel)]="message">
        </textarea>
      </div>

      <div class="d-flex flex-column mt-3">
        <label for="signature">{{'stores.signature' | translate}}:</label>
        <textarea row="3"
          id="signature"
          class="form-control form-control-sm" 
          [(ngModel)]="signature">
        </textarea>
      </div>
    </div>
  </div>

  <div class="actions">
    <button class="pr-border" mat-button mat-dialog-close [mat-dialog-close]="true" (click)="send()"
      [disabled]="!data.vendorEmail?.length">
      {{'stores.send' | translate}}
    </button>

    <button class="border border-danger" mat-button mat-dialog-close [mat-dialog-close]="false">
      {{'stores.cancel' | translate}}
    </button>

  </div>
</div>
