<div class="tab-container">
    
    <div class="container">

        <div class="row pt-1 pb-2 bg-white">
            <div class="col-sm-2">
                <label for="From Date">{{ "accounts.from_date" | translate }}:</label>
                <input type="date" class="form-control" id="from-date" max="{{endDate}}" [(ngModel)]="startDate">
            </div>
            <div class="col-sm-2">
                <label for="To Date">{{ "accounts.to_date" | translate }}:</label>
                <input type="date" class="form-control" id="to-date" min="{{startDate}}" [(ngModel)]="endDate">
            </div>
            <div class="col-sm-2">
                <label for="To Date">{{ "accounts.payment_mode" | translate }}</label>
                <select class="form-control" [(ngModel)]="selectedMode">
                    <option *ngFor="let item of paymentModes" [value]="item">{{item}}</option>
                </select>
            </div>

            <div *ngIf="reconcilationData?.configLevel === 'chain'"
                class="col-sm-2">
                <label for="To Date">{{ "accounts.hotel" | translate }}:</label>
                <select class="form-control"
                    [(ngModel)]="selectedHotel">
                    <option value="">{{ "accounts.all" | translate }}</option>
                    <option *ngFor="let hotel of hotels" [value]="hotel.id">
                        {{hotel.name}}
                    </option>
                </select>
            </div>

            <div class="col-sm-2 submit-btn">
                <button mat-raised-button class="primary_btn" (click)="getData()">{{ "accounts.submit" | translate }}</button>
            </div>
        </div>

        <table class="table mt-3">
            <thead class="thead-dark">
                <tr>
                    <th>{{ "accounts.date" | translate }}</th>
                    <th>{{ "accounts.pos_name" | translate }}</th>
                    <th *ngIf="reconcilationData?.configLevel === 'chain'">{{ "accounts.hotel" | translate }}</th>
                    <th>{{ "accounts.invoice_no" | translate }}.</th>
                    <th>{{ "accounts.vendor_or_company" | translate }}</th>
                    <th>{{ "accounts.comments" | translate }}</th>
                    <th>{{ "accounts.image" | translate }}Image</th>
                    <th>{{ "accounts.user" | translate }}</th>
                    <th class="text-right">{{ "accounts.paid_in" | translate }}</th>
                    <th class="text-right">{{ "accounts.paid_out" | translate }}</th>
                </tr>
            </thead>
    
            <tbody>
                <tr class="dark-row">
                    <td>{{ "accounts.total" | translate }}</td>
                    <td [attr.colspan]="reconcilationData?.configLevel === 'chain' ? 8 : 7" class="text-right">
                        {{reconcilationData?.totalPaidIn | number: '1.2-2'}}
                    </td>
                    <td class="text-right">{{reconcilationData?.totalPaidOut | number: '1.2-2'}}</td>
                </tr>

                <tr *ngFor="let item of reconcilationData?.payments">
                    <td>{{item.date | date: 'dd MMM YY'}}</td>
                    <td>{{item.posName}}</td>
                    <td *ngIf="reconcilationData?.configLevel === 'chain'">{{item.hotelName}}</td>
                    <td>{{item.invoiceNum ? item.invoiceNum : item.folioNum}}</td>
                    <td>{{item.vendorName}} {{item.companyName}}</td>
                    <td>{{item.comments}}</td>
                    <td>
                        <a [href]="item.imgUrl" target="_blank">
                            <img *ngIf="item.imgUrl" [src]="item.imgUrl" width="30" height="30">
                        </a>
                    </td>
                    <td>{{item.user}}</td>
                    <td class="text-right">
                        <ng-container *ngIf="item.paidIn">
                            {{item.amount | number: '1.2-2'}}
                        </ng-container>
                    </td>
                    <td class="text-right">{{item.paidIn ? '' : item.amount | number: '1.2-2'}}</td>
                </tr>
          
            </tbody>
        </table>

    </div>

    <div class="d-flex justify-content-end">
        <nav>
            <ul class="pagination">
                <li class="page-item">
                    <a class="page-link" (click)="previousPage()">{{ "accounts.previous" | translate }}</a>
                </li>
                <li class="page-item">
                    <a class="page-link active-page">
                        {{currentPage}}
                    </a>
                </li>
                <li class="page-item">
                    <a class="page-link" (click)="nextPage()">{{ "accounts.next" | translate }}</a>
                </li>
            </ul>
          </nav>
    </div>

</div>
