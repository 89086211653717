import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { CompaniesService } from 'src/app/core/companies.service';
import { Globals } from 'src/app/core/globals.service';
import { UserService } from 'src/app/core/user.service';
import { DialogComponent } from 'src/app/_shared/dialog/dialog.component';
import { expandCollapse, slide } from 'src/app/animations';
import { saveAs } from 'file-saver-es';
import { MarkPaidComponent } from './mark-paid/mark-paid.component';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-city-ledger',
  templateUrl: './city-ledger.component.html',
  styleUrls: ['./city-ledger.component.scss'],
  animations: [expandCollapse, slide]
})
export class CityLedgerComponent implements OnInit {

  hotelId: string;
  cityLedgerData: any[];
  $cityLedgerData: any[];
  currentDate: string;
  paymentFiles: any = {};
  startDate: any;
  endDate: any;

  constructor(private route: ActivatedRoute,
              public globals: Globals,
              public companiesService: CompaniesService,
              public userService: UserService,
              public dialog: MatDialog,
              private datePipe: DatePipe
            ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.hotelId = params.hotelId;
      this.globals.changeHotelID(this.hotelId);
    });
    this.getProductConfig();
    this.getCityLedger();
  }
  
  dateCalc() {
    this.startDate = new Date();
    this.startDate.setDate(new Date().getDate() - 30);
    this.startDate = this.datePipe.transform((new Date(this.startDate)), 'y-MM-dd');
    this.endDate = this.datePipe.transform((new Date()), 'y-MM-dd');
  }

  getProductConfig() {
    this.userService.getProductConfig(this.hotelId).subscribe(data => {
      this.currentDate = data.conf.currentDate;
    });
  }

  getCityLedger() {
    this.companiesService.getCityLedger(this.hotelId).subscribe(data => {
      data?.city_ledger.forEach(e => {
        e.hidden = true;
        e.data.forEach(p => p.btcPaymentDate = this.currentDate);
      });
      this.cityLedgerData = data.city_ledger;
      this.$cityLedgerData = data.city_ledger;
    });
  }

  toggleState(i: number) {
    const company = this.$cityLedgerData[i];
    if (company.hidden && !company.infoFetched) {
      this.companiesService.getCityLedgerExtraInfo(this.hotelId, company.company_name, company.page).subscribe(res => {
        company.data = res?.data?.data;
        company.infoFetched = true;
        company.hidden = false;
      })
    } 
    else {
      company.hidden = !company.hidden;
    }
  }

  changePage(i, change) {
    const company = this.$cityLedgerData[i];
    if (!company.page) {
      company.page = 1;
    }
    if (change === 'prev' && company.page > 1) {
      company.page--;
    } else {
      company.page++;
    }
    this.companiesService.getCityLedgerExtraInfo(this.hotelId, company.company_name, company.page)
    .subscribe(res => {
      company.data = res?.data?.data;
    })
  }

  markPaid(payment: any, index: number, company) {
    const ref = this.dialog.open(MarkPaidComponent, {
      width: '600px',
      height: 'auto',
      data: {
        ...payment, hotelId: this.hotelId, companyId: company.company_id
      }
    });

    ref.afterClosed().subscribe(res => {
      if (!res?.submit) {return;}
      if (res?.file) {
        this.paymentFiles[payment.payment_id] = res.file;
      }
      const body = {...payment, formData: res.formData, companyName: company.company_name };
            
      if (this.paymentFiles[payment.payment_id]) {
        this.uploadToS3andMarkPaid(this.paymentFiles[payment.payment_id], body, index);
      } else {
        this.markBTCPaymentMade(body, index);
      }
    })

  }

  uploadToS3andMarkPaid(file, payment, index) {
    const key = `btc-payment/${payment.payment_id}`;
    this.userService.uploadFile(file, key).subscribe(data => {
      this.markBTCPaymentMade(payment, index, data.url);
    });
  }

  markBTCPaymentMade(payment, index, fileUrl?) {
    const body = {
      reference_id: payment.referenceId, 
      payment_id: payment.payment_id, 
      date: payment.btcPaymentDate, 
      url: fileUrl,
      company_name: payment.companyName,
      invoice_num: payment.invoice_num,
      formData: payment.formData
    };
    this.companiesService.markBtcPaymentMade(this.hotelId, body).subscribe(data => {
      this.removeMarkedPayment(payment.payment_id, index, data);
    });
  }

  fileUpload(paymentId, event) {
    const file = event.files[0];
    this.paymentFiles[paymentId] = file;
  }

  removeMarkedPayment(paymentId, index, data) {
    this.$cityLedgerData[index].total_outstanding = data.total_outstanding;
    for (const e of this.$cityLedgerData[index].data) {
      if (e.payment_id === paymentId) {
        this.$cityLedgerData[index].data = this.$cityLedgerData[index].data.filter(e => e.payment_id !== paymentId);
        break;
      }
    }
  }

  searchCompany(name: string) {
    name = name.toLowerCase();
    this.$cityLedgerData = this.cityLedgerData.filter(e => e.company_name?.toLowerCase().indexOf(name) > -1);
  }

  downloadExcel(company: any) {
    this.companiesService.downloadCityLedger(company.company_id, this.hotelId)
    .subscribe(data => {
      const blob: any = new Blob([data], {type: 'text/pdf; charset=utf-8'});
      saveAs(blob, `${company.company_name}.xlsx`);
    });
  }
}
