import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-bill',
  templateUrl: './bill.component.html',
  styleUrls: ['./bill.component.css']
})
export class BillComponent implements OnInit {
  invoiceHeading: string[];
  invoiceAddress: string[];
  order: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private dialogRef: MatDialogRef<BillComponent>) { }

  ngOnInit(): void {
    this.invoiceHeading = this.data.config.invoiceHeading.split('<break>');
    this.invoiceAddress = this.data.config.invoiceAddress.split('<break>');
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
