import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-show-payment-links',
  templateUrl: './show-payment-links.component.html',
  styleUrls: ['./show-payment-links.component.scss']
})
export class ShowPaymentLinksComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit(): void {
    
  }

}
