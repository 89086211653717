import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { SignaturePad } from 'angular2-signaturepad';
import { ReservationService } from 'src/app/core/reservation.service';
import { DialogComponent } from 'src/app/_shared/dialog/dialog.component';

export interface guestDetails {
  name: string;
  email: string;
  phone: string;
  inputAddress: string;
  country: string;
  special_request: string;
  purposeOfVisit: string;
  comingFrom: string;
  goingTo: string;
};

@Component({
  selector: 'app-contactless-checkin',
  templateUrl: './contactless-checkin.component.html',
  styleUrls: ['./contactless-checkin.component.css']
})

export class ContactlessCheckinComponent implements OnInit {
  disablefield: boolean;
  showTravelData: boolean;

  constructor(private guestService: ReservationService, private route: ActivatedRoute, public matdialog:MatDialog) { }

  hotelRoute : any;
  hotelID : string;
  bookingId : string;
  signatureImg: boolean = false;
  termsAndConditions: any;
  notreadTerms: boolean = true;
  guestDetails: guestDetails = {name: '', email: '', phone: '', inputAddress: '', country: '', special_request: '', purposeOfVisit: '', comingFrom: '', goingTo: ''};
  countriesList = ['Afghanistan', 'Albania', 'Algeria', 'American Samoa', 'Andorra', 'Angola', 'Anguilla', 'Antigua and Barbuda',
                  'Argentina', 'Armenia', 'Aruba', 'Australia', 'Austria', 'Azerbaijan', 'Bahamas', 'Bahrain', 'Bangladesh',
                  'Barbados', 'Belarus', 'Belgium', 'Belize', 'Benin', 'Bermuda', 'Bhutan', 'Bolivia', 'Bosnia and Herzegovina',
                  'Botswana', 'Brazil', 'British Virgin Islands', 'Brunei', 'Bulgaria', 'Burkina Faso', 'Burundi', 'Cambodia',
                  'Cameroon', 'Canada', 'Cape Verde', 'Cayman Islands', 'Central African Republic', 'Chad', 'Chile', 'China',
                  'Colombia', 'Comoros', 'Cook Islands', 'Costa Rica', 'Croatia', 'Cuba', 'Curacao', 'Cyprus', 'Czech Republic',
                  'Denmark', 'Djibouti', 'Dominica', 'Dominican Republic', 'DR Congo', 'Ecuador', 'Egypt', 'El Salvador',
                  'Equatorial Guinea', 'Eritrea', 'Estonia', 'Eswatini', 'Ethiopia', 'Falkland Islands', 'Faroe Islands',
                  'Fiji', 'Finland', 'France', 'French Guiana', 'French Polynesia', 'Gabon', 'Gambia', 'Georgia', 'Germany',
                  'Ghana', 'Gibraltar', 'Greece', 'Greenland', 'Grenada', 'Guadeloupe', 'Guam', 'Guatemala', 'Guinea',
                  'Guinea-Bissau', 'Guyana', 'Haiti', 'Honduras', 'Hong Kong', 'Hungary', 'Iceland', 'India', 'Indonesia',
                  'Iran', 'Iraq', 'Ireland', 'Isle of Man', 'Israel', 'Italy', 'Ivory Coast', 'Jamaica', 'Japan', 'Jordan',
                  'Kazakhstan', 'Kenya', 'Kiribati', 'Kuwait', 'Kyrgyzstan', 'Laos', 'Latvia', 'Lebanon', 'Lesotho', 'Liberia',
                  'Libya', 'Liechtenstein', 'Lithuania', 'Luxembourg', 'Macau', 'Madagascar', 'Malawi', 'Malaysia', 'Maldives',
                  'Mali', 'Malta', 'Marshall Islands', 'Martinique', 'Mauritania', 'Mauritius', 'Mayotte', 'Mexico', 'Micronesia',
                  'Moldova', 'Monaco', 'Mongolia', 'Montenegro', 'Montserrat', 'Morocco', 'Mozambique', 'Myanmar', 'Namibia',
                  'Nauru', 'Nepal', 'Netherlands', 'New Caledonia', 'New Zealand', 'Nicaragua', 'Niger', 'Nigeria', 'Niue',
                  'North Korea', 'North Macedonia', 'Northern Mariana Islands', 'Norway', 'Oman', 'Pakistan', 'Palau', 'Palestine',
                  'Panama', 'Papua New Guinea', 'Paraguay', 'Peru', 'Philippines', 'Poland', 'Portugal', 'Puerto Rico', 'Qatar',
                  'Republic of the Congo', 'Reunion', 'Romania', 'Russia', 'Rwanda', 'Saint Barthelemy', 'Saint Kitts and Nevis',
                  'Saint Lucia', 'Saint Martin', 'Saint Pierre and Miquelon', 'Saint Vincent and the Grenadines', 'Samoa',
                  'San Marino', 'Sao Tome and Principe', 'Saudi Arabia', 'Senegal', 'Serbia', 'Seychelles', 'Sierra Leone',
                  'Singapore', 'Sint Maarten', 'Slovakia', 'Slovenia', 'Solomon Islands', 'Somalia', 'South Africa', 'South Korea',
                  'South Sudan', 'Spain', 'Sri Lanka', 'Sudan', 'Suriname', 'Sweden', 'Switzerland', 'Syria', 'Taiwan', 'Tajikistan',
                  'Tanzania', 'Thailand', 'Timor-Leste', 'Togo', 'Tokelau', 'Tonga', 'Trinidad and Tobago', 'Tunisia', 'Turkey',
                  'Turkmenistan', 'Turks and Caicos Islands', 'Tuvalu', 'Uganda', 'Ukraine', 'United Arab Emirates', 'United Kingdom',
                  'United States', 'United States Virgin Islands', 'Uruguay', 'Uzbekistan', 'Vanuatu', 'Vatican City', 'Venezuela',
                  'Vietnam', 'Wallis and Futuna', 'Western Sahara', 'Yemen', 'Zambia', 'Zimbabwe']

  ngOnInit(): void {
    this.hotelRoute = this.route.params.subscribe(params => {
      this.hotelID = params.hotelId;
      this.bookingId = params.bookingId;
    });
    this.guestService.fetchallbookingData(this.bookingId,this.hotelID).subscribe(data=>{
      this.termsAndConditions = data.termsAndConditions;
      if (data.termsAndConditions){
        this.notreadTerms = true;
      }else{
        this.notreadTerms = false;
      }
      if (data.error){
        const WarningDialog = {title: 'Warning!', body:data.error, cancelBtn: 'Dismiss', width: '250px', height: '150px'};
        this.matdialog.open(DialogComponent, {data: WarningDialog});
        this.disablefield = true;
      }else{
        var allocationIDs = [];
        this.guestDetails.name = data.guestdata.name;
        this.guestDetails.email = data.guestdata.email;
        this.guestDetails.phone = data.guestdata.phone;
        this.guestDetails.country = data.guestdata.address.country;
        this.fileSelected = data.guestdata.photo_id;
        this.guestDetails.inputAddress = data.guestdata.address.address;
        this.guestDetails['guestID'] = data.allocation[0]['guest_id'];
        for (var allocationId in data.allocation){
          allocationIDs.push(data.allocation[allocationId]['room_allocation_id'])
        }
        this.guestDetails.special_request = data.allocation[0]['request'];
        this.guestDetails['allocationId_list'] = allocationIDs;
        this.guestDetails['amount'] = data.bookingdata.payload.amount.amountBeforeTax;
        this.guestDetails['tax'] = data.bookingdata.payload.amount.tax;
        this.guestDetails['bookingId'] = this.bookingId;
        this.showTravelData = data.guestdata.show_travel_data;
        if (data.guestdata.travel_data){
          this.guestDetails['purposeOfVisit'] = data.guestdata.travel_data.purpose;
          this.guestDetails['comingFrom'] = data.guestdata.travel_data.comingFrom;
          this.guestDetails['goingTo'] = data.guestdata.travel_data.goingTo;
        }
        
      }
    });
  };
  
  @ViewChild(SignaturePad) signaturePad: SignaturePad;
  idImgs = {};
  fileSelected = [];

  signaturePadOptions: Object = { 
    'minWidth': 2,
    'canvasWidth': 300,
    'canvasHeight': 100
  };

  ngAfterViewInit() {
    // this.signaturePad is now available
    this.signaturePad.set('minWidth', 1); 
    this.signaturePad.clear(); 
  }

  drawComplete() {
    if (!this.signaturePad.isEmpty()){
      this.signatureImg = true;
    }
  }

  drawStart() {
    console.log('begin drawing');
  }

  clearSignature() {
    this.signaturePad.clear();
    this.signatureImg = false;
  }

  handleInput(event){
    var filelength= Object.keys(event.files).length;
    for (var i =0; i<filelength;i++){
       this.guestService.uploadID(this.hotelID, event.files[i], null, this.bookingId).subscribe(
         success => {
          if(success.error){
            const WarningDialog = {title: 'Warning!', body:success.error, cancelBtn: 'Dismiss', width: '250px', height: '150px'};
            this.matdialog.open(DialogComponent, {data: WarningDialog});
          }else{
            this.fileSelected.push(success);
          }
         }
       );
     }
  }
   
  removeImage(url) {
    this.fileSelected.splice(this.fileSelected.findIndex(function(i){
      return i === url;
    }), 1);
  }

  base64toBlob(b64Data) {
    const byteCharacters = atob(b64Data.split(",")[1]);
    const byteArrays = [];
    let sliceSize = 512;
    const contentType = b64Data.split(',')[0].split(';')[0].split(':')[1];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
  
    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }

  termsRead(){
    const cb = (<HTMLInputElement>document.getElementById('accept'));
    this.notreadTerms = !cb.checked;
  }

  clearform(){
    this.guestDetails.country = null;
  }

  ngOnDestroy(){
    this.hotelRoute.unsubscribe();
  }

  onSubmit(formData:NgForm): void{
    if (!this.guestDetails.name.trim()) {
      const WarningDialog = {title: 'Warning!', body:`Kindly provide your Name.`, cancelBtn: 'Dismiss', width: '250px', height: '150px'};
      this.matdialog.open(DialogComponent, {data: WarningDialog});
    }
    if (!this.guestDetails.email.trim()) {
      const WarningDialog = {title: 'Warning!', body:`Kindly provide your Email.`, cancelBtn: 'Dismiss', width: '250px', height: '150px'};
      this.matdialog.open(DialogComponent, {data: WarningDialog});
    }
    if (!this.guestDetails.phone.trim()) {
      const WarningDialog = {title: 'Warning!', body:`Kindly provide your Phone number.`, cancelBtn: 'Dismiss', width: '250px', height: '150px'};
      this.matdialog.open(DialogComponent, {data: WarningDialog});
    }
    if (!this.guestDetails.inputAddress.trim()){
      const WarningDialog = {title: 'Warning!', body:`Address field should not be empty.`, cancelBtn: 'Dismiss', width: '250px', height: '150px'};
      this.matdialog.open(DialogComponent, {data: WarningDialog});
    }
    if (!this.guestDetails.country.trim()) {
      const WarningDialog = {title: 'Warning!', body:`Kindly specify your country.`, cancelBtn: 'Dismiss', width: '250px', height: '150px'};
      this.matdialog.open(DialogComponent, {data: WarningDialog});
    }
    if(!this.signaturePad.isEmpty() && this.guestDetails.email.trim() && this.guestDetails.name.trim() &&
      this.guestDetails.phone.trim() && this.guestDetails.inputAddress.trim() && this.guestDetails.country.trim()){
        const dialogBody = {title: 'Submit', body: 'Are you sure you want to Submit ?',
            confirmBtn: 'Submit', cancelBtn: 'Cancel', width: '250px', height: '150px'
            };
        const dialogRef = this.matdialog.open(DialogComponent, {data: dialogBody});
        dialogRef.afterClosed().subscribe(result => {
          if(result === 'true'){
            const blob = this.base64toBlob(this.signaturePad.toDataURL());
            this.guestService.uploadID(this.hotelID, blob, 'signature-'+this.bookingId, this.bookingId).subscribe(
              data => {
                if(data.error){
                  const WarningDialog = {title: 'Warning!', body:data.error, cancelBtn: 'Dismiss', width: '250px', height: '150px'};
                  this.matdialog.open(DialogComponent, {data: WarningDialog});
                }else{
                  this.fileSelected.push({'signature': data.url});
                  this.guestDetails['files'] = this.fileSelected;
                  this.guestService.contactlessCheckin(this.hotelID,this.guestDetails).subscribe(success=>{
                    if (success.error){
                      const WarningDialog = {title: 'Warning!', body:success.error, cancelBtn: 'Dismiss', width: '250px', height: '150px'};
                      this.matdialog.open(DialogComponent, {data: WarningDialog});
                    }else{
                      const WarningDialog = {title: 'Success !', body:`Your data submitted successfully!`, cancelBtn: 'Dismiss', width: '250px', height: '150px'};
                      this.matdialog.open(DialogComponent, {data: WarningDialog});
                    }
                  });
                }
              }, err => {
                const WarningDialog = {title: 'Error !', body:`Something went wrong while submitting, kindly try again.`, cancelBtn: 'Dismiss', width: '250px', height: '150px'};
                this.matdialog.open(DialogComponent, {data: WarningDialog});
              }
            );
            
          }
        });
      }
  }
}
