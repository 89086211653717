import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountsService } from 'src/app/core/accounts.service';
import { B2binvoiceDetailsComponent } from './b2binvoice-details/b2binvoice-details.component';
import { ExcelServicesService } from 'src/app/_shared/services/excel-file.service';

@Component({
  selector: 'app-b2binvoice-summary',
  templateUrl: './b2binvoice-summary.component.html',
  styleUrls: ['./b2binvoice-summary.component.scss']
})
export class B2binvoiceSummaryComponent implements OnInit {
  hotelId = '';
  b2bData: any = {};
  tableData = [];
  totalData: any = {};
  roomAllocations = {}

  constructor(
    private accountsService: AccountsService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private excelService: ExcelServicesService
  ) { }

  ngOnInit(): void {
    let gstr1Data: any = sessionStorage.getItem('gstr1TempStore');
    this.route.params.subscribe(params => {
      this.hotelId = params.hotelId;
      try {
        gstr1Data = JSON.parse(gstr1Data);
        this.b2bData = gstr1Data?.gstBillTo;
        this.roomAllocations = gstr1Data?.roomAllocations;
        this.createTableData();
      }
      catch { }
    });
  }

  createTableData() {
    const tKeys = ['voucherCount', 'eInvoiceCount', 'preTaxAmt', 'totalInvoiceAmt', 'totalTax', 'igst', 'cgst', 'sgst'];
    const totalData: any = {}
    tKeys.forEach(e => totalData[e] = 0);

    const arr = [];
    for (const key in this.b2bData) {
      let obj = this.b2bData[key];
      obj.voucherCount = Object.keys(obj.invoiceIds)?.length;
      arr.push(obj);

      for (const k in obj) {
        if (tKeys.includes(k)) {
          totalData[k] += obj[k]
        }
      }
    }

    arr.sort((a, b) => b.totalInvoiceAmt - a.totalInvoiceAmt);
    this.tableData = arr;
    this.totalData = totalData;
  }

  openInvoiceDetails(summary) {
    this.dialog.open(B2binvoiceDetailsComponent, {
      width: '900px',
      data: {
        summary, 
        hotelId: this.hotelId,
      }
    })
  }

  downloadExcel() {
    const headerRow = ['Guest Name', 'Checkin', 'Checkout', 'Source', 'Particular', 'GSTIN/NUM', 'Taxable Amount', 'Integrated Tax Amount', 
    'Central Tax Amount', 'State Tax Amount', 'Cess Amount', 'Tax Amount', 'Invoice Amount'];
    const dataRows = [];

    const sortedArr = this.tableData.slice().sort((a, b) => a.billTo.toLowerCase().localeCompare(b.billTo.toLowerCase()));

    sortedArr.forEach(r => {
      for (const invId in r.invoiceIds) {
        const inv = r.invoiceIds[invId]?.extras;
        const roomInfo = this.roomAllocations?.[inv.roomAllocationId];
        dataRows.push([roomInfo?.guestName, roomInfo?.checkinDate, roomInfo?.checkoutDate, roomInfo?.source, r.billTo, r.ctin, inv.preTaxAmt, inv.igst, 
          inv.cgst, inv.sgst, '', inv.totalTax, inv.totalAmt]
        );
      }
    });
  
    const downloadData = [headerRow, ...dataRows]
    this.excelService.exportAsExcelFile(downloadData, 'Details of B2B Invoices - 4A, 4B, 4C, 6B, 6C');
  }

  back() {
    this.accountsService.currentTab = 'gstr1';
    this.router.navigate([`accounts/${this.hotelId}`], {queryParams: {tabName: 'gstr1', fromBack: true}});
  }
}
