<div *ngIf = "showPage != true">
  <span>{{ "maintenance.not_access_msg" | translate }}</span>
</div>

<div *ngIf = "showPage == true">
  <div class="mat-elevation-z8">
    <div class="form-row">
        <div class="form-group col-md-2 col-sm-2">
          <label for="fromDate">{{ "maintenance.from_date" | translate }}:</label>
          <input type="date" class="form-control form-control-sm" onkeydown="return false" id="fromDate" [(ngModel)]='fromDate' (change)="getData()" name="fromDate" >
        </div>
        <div class="form-group col-md-2 col-sm-2">
          <label for="toDate">{{ "maintenance.to_date" | translate }}:</label>
          <input type="date" class="form-control form-control-sm" onkeydown="return false" id="toDate" [(ngModel)]='toDate' (change)="getData()" name="toDate" >
        </div>
        <div class="form-group col-md-2 col-sm-2">
          <label for="status">{{ "maintenance.status" | translate }}:</label>
          <select class="form-control form-control-sm" id="status" [(ngModel)]="status" (change)="getData()" name="status">
            <option value="Sent">{{ "maintenance.sent" | translate }}</option>
            <option value="Received">{{ "maintenance.received" | translate }}</option>
          </select>
        </div>
        <div class="col-lg-4 ml-auto" id="create">
          <button type="button" class="a-button form-control-sm stockButton" (click)="getLaundryStock()">{{ "maintenance.laundry_items_status" | translate }}</button>
          <button type="button" class="a-button form-control-sm printButton" (click)="printSummary()">{{ "maintenance.print_summary" | translate }}</button>
          <button type="button" class="a-button form-control-sm createButton" (click)="createNewLaundry()">{{ "maintenance.create" | translate }}</button>
        </div>
    </div>

    <table mat-table [dataSource]="dataSource" matSort class="table_wrapper">
  
      <ng-container matColumnDef="item">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ "maintenance.item" | translate }} </th>
        <td mat-cell *matCellDef="let element"> {{element.item}} </td>
      </ng-container>

      <ng-container matColumnDef="quantity">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "maintenance.quantity" | translate }} </th>
        <td mat-cell *matCellDef="let element"> {{element.quantity}} </td>
      </ng-container>

      <ng-container matColumnDef="sentDate" sticky>
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "maintenance.sent_date" | translate }}  </th>
        <td mat-cell *matCellDef="let element"> <span *ngIf="element.sentDate!='None'">{{element.sentDate | date:'MMM d, yyyy'}}</span></td>
      </ng-container>

      <ng-container matColumnDef="receivedDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "maintenance.received_date" | translate }} </th>
        <td mat-cell *matCellDef="let element"> {{element.receivedDate | date:'MMM d, yyyy'}} </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef  mat-sort-header> {{ "maintenance.status" | translate }} </th>
        <td mat-cell *matCellDef="let element"> {{element.status}} </td>
      </ng-container>

      <ng-container matColumnDef="comments">
        <th mat-header-cell *matHeaderCellDef> {{ "maintenance.comments" | translate }} </th>
        <td mat-cell *matCellDef="let element"> {{element.comments}} </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef> {{ "maintenance.action" | translate }} </th>
        <td mat-cell class="tkaction" *matCellDef="let element">
          <div class="example-button-row" >
            <span title="Edit"><button type = "button" mat-icon-button (click)="editItem(element)">
              <mat-icon >edit</mat-icon>
            </button></span>
            <span title="Delete"><button type = "button" mat-icon-button (click)="deleteItem(element.id)">
              <mat-icon>delete</mat-icon>
            </button></span>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    
    <mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons></mat-paginator>
  </div>
</div>
