<div class="primary_modal_wrapper">
    <div class="modal_heading">
        <h1>Add Room to Group ({{data?.guest?.name}})</h1>
    </div>

    <mat-divider></mat-divider>

    <div class="modal_body">

        <div class="d-flex justify-content-between">
            <div class="d-flex flex-column">
                <label>Select Room:</label>
                <div class="custom_dropdown">
                    <input class="input_box form-control-sm" (input)="filterGuestToAdd($event.target.value)">
    
                    <div class="drop_container">
                        <div class="item" *ngFor="let item of guestsToAdd">
                            <input type="checkbox" id="{{item.roomNum}}" (change)="selectItem($event.target.checked, item)"
                                [checked]="item.checked">
                            <label class="ellip" for="{{item.roomNum}}">
                                {{item.roomNum}} - {{item.guestName}}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            
            <div>
                {{addedGuests?.length}} rooms selected
            </div>
        </div>
    </div>

    <div class="modal_footer">
        <mat-dialog-actions align="end">
            <button mat-raised-button 
                mat-dialog-close 
                [ngClass]="{disable_button: submitting}">
                Cancel
            </button>
            <button mat-raised-button
                class="primary_btn "
                [ngClass]="{disable_button: submitting}"
                (click)="submit()">
                <ng-container *ngIf="!submitting; else submittingBlock">
                    Add
                </ng-container>
                <ng-template #submittingBlock>
                    Adding...
                </ng-template>
            </button>
        </mat-dialog-actions>
    </div>

</div>