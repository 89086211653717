import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BookingService {

  constructor(private http: HttpClient) { }

  getBookingDetails(bookingId, hotelId): Observable <any> {
    return this.http.get(`${environment.apiUrl}/booking-details/${hotelId}/${bookingId}`);
  }

  getGuestsToAddInGroup(hotelId, bookingId, groupId): Observable <any> {
    const httpParams = new HttpParams({
      fromObject: {bookingId, groupId}
    });
    return this.http.get(`${environment.apiUrl}/pms/add-guest-to-group/${hotelId}`, {params: httpParams});
  }

  addGuestToGroup(hotelId, roomAllocationIds, groupId): Observable <any> {
    return this.http.put(`${environment.apiUrl}/pms/add-guest-to-group/${hotelId}`, {roomAllocationIds, groupId});
  }

  removeGuestFromGroup(hotelId, roomAllocationId): Observable <any> {
    const httpParams = new HttpParams({
      fromObject: {roomAllocationId}
    });
    return this.http.delete(`${environment.apiUrl}/pms/remove-guest-from-group/${hotelId}`, {params: httpParams});
  }

  updateMasterRoom(groupId, hotelId, allocId): Observable <any> {
    return this.http.put(`${environment.apiUrl}/makeMasterRoom/${hotelId}`, {groupId: groupId, allocId: allocId});
  }
}
