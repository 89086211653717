import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HRModuleSevice {

  constructor(private http: HttpClient) { }

  getProductConfig(hotelId): Observable <any> {
    return this.http.get(`${environment.apiUrl}/hotels/${hotelId}/products/HRModule`);
  }

  fetchAllInfoOfEmployees(hotelId, employeeId = null, filter = 'All'): Observable<any> {
    let params = new HttpParams();
    params = params.append('employeeId', employeeId);
    params = params.append('filter' , filter);
    return this.http.get<any>(`${environment.apiUrl}/fetch_employees_info/${hotelId}`, {params: params});
  }

  uploadIDFiles(hotelId, file){
    const httpParams = new HttpParams({
      fromObject: {bucket: 'aiosell.pms', key: 'employeeIDs/'+ hotelId + '/' + file.name}
    });
    const data = new FormData();
    data.append('file', file); 
    return this.http.post<any>(`${environment.apiUrl}/upload/`, data, {params: httpParams});
  }

  fetchInfoForCreateEmployee(hotelId): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/fetch_create_employee_info/${hotelId}`);
  }

  addEmployee(employeeData, hotelId, chainId) {
    let params = new HttpParams();
    params = params.append('chainId', chainId);
    return this.http.post(`${environment.apiUrl}/create_employee/${hotelId}`, employeeData, {params: params});
  }

  updateEmployee(employeeData, hotelId, employeeId, user) {
    let params = new HttpParams();
    params = params.append('employeeId', employeeId);
    params = params.append('user', user);
    return this.http.put(`${environment.apiUrl}/update_employee/${hotelId}`, employeeData, {params: params});
  }

  removeEmployee(employeeId, hotelId){
    let params = new HttpParams();
    params = params.append('employeeId', employeeId);
    return this.http.delete(`${environment.apiUrl}/remove_employee/${hotelId}`, {params: params});
  }

  fetchAttendance(hotelId, month, year, employeeStatus): Observable<any>{
    let params = new HttpParams();
    params = params.append('month', month);
    params = params.append('year', year);
    params = params.append('status', employeeStatus)
    return this.http.get<any>(`${environment.apiUrl}/fetch_attendance/${hotelId}`, {params: params});
  }

  updateAttendance(hotelId, data, user){
    let params = new HttpParams();
    params = params.append('user', user);
    return this.http.put(`${environment.apiUrl}/update_attendance/${hotelId}`, data, {params: params});
  }

  downloadAttendance(hotelId, month, year, department, groupId = null): Observable<any> {
    let params = new HttpParams();
    params = params.append('month', month);
    params = params.append('year', year);
    params = params.append('department', department);
    params = params.append('groupId', groupId);
    return this.http.get(`${environment.apiUrl}/download_attendance/${hotelId}`, {responseType: 'blob', params: params});
  }

  fetchSalaryAndAttendance(hotelId, month, year): Observable<any> {
    let params = new HttpParams();
    params = params.append('month', month);
    params = params.append('year', year);
    return this.http.get(`${environment.apiUrl}/fetch_salary_info/${hotelId}`, {params: params});
  }

  downloadSalary(hotelId, month, year, user, groupId = null, process = 'false'): Observable<any> {
    let params = new HttpParams();
    params = params.append('month', month);
    params = params.append('year', year);
    params = params.append('groupId', groupId);
    params = params.append('process', process)
    params = params.append('user', user)
    return this.http.get(`${environment.apiUrl}/download_salary/${hotelId}`, {responseType: 'blob', params: params});
  }

  downloadEmployeesData(hotelId, groupId = null, showSalary = 'true'): Observable<any> {
    let params = new HttpParams();
    params = params.append('groupId', groupId);
    params = params.append('salaryAccess', showSalary)
    return this.http.get(`${environment.apiUrl}/download_employeesdata/${hotelId}`, {responseType: 'blob', params: params});
  }

  getBankFile(hotelId: string, month, year, groupId): Observable<any> {
    const httpParams = new HttpParams({fromObject: {month, year, groupId}});
    return this.http.get(`${environment.apiUrl}/generate-file-for-bank/${hotelId}`, {params: httpParams, responseType: 'blob'});
  }
}