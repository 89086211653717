import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { DialogComponent } from 'src/app/_shared/dialog/dialog.component';
import { GuestsService } from 'src/app/core/guests.service';

@Component({
  selector: 'app-guest-search-table',
  templateUrl: './guest-search-table.component.html',
  styleUrls: ['./guest-search-table.component.scss']
})
export class GuestSearchTableComponent implements OnInit {

  constructor( private guestsService: GuestsService, @Inject(MAT_DIALOG_DATA) public data: any, public fb: FormBuilder,public dialogRef: MatDialogRef<DialogComponent>) { }

  ELEMENT_DATA: GuestDataElement[];
  search: FormGroup;

  ngOnInit(): void {
    this.getData();
    this.search = this.fb.group({
      searchInput: [this.data.keyword, Validators.required],
    });
  }
  

  displayedColumns: string[] = ['select', 'position', 'name', 'email', 'phone', 'dob', 'country', 'idDetail', 'photoId'];
  dataSource = new MatTableDataSource<GuestDataElement>();
  selection = new SelectionModel<GuestDataElement>(true, []);


  selectedRow(data){
    this.dialogRef.close({ data: data }); // pass data back to main page
  }

  getData(){
    this.guestsService.getGuestsData(this.data.hotelId, 100, this.data.keyword).subscribe(guestData =>{
      this.dataSource.data = guestData.success.data;
    })
  }


  onSearch(key) {
      this.guestsService.getGuestsData(this.data.hotelId, 100, key.value.searchInput).subscribe(guestData =>{
        this.dataSource.data = guestData.success.data;
      })
  }

  back(){
    this.dialogRef.close();
  }
  
}

export interface GuestDataElement {
  position: number;
  name: string;
  email: string;
  phone: string;
  dob: string;
  country: string;
  idDetail: string;
  photoId: string;
}
