import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserService } from 'src/app/core/user.service';
import { Globals } from 'src/app/core/globals.service';
import { AccountsService } from 'src/app/core/accounts.service';
import { DialogComponent } from 'src/app/_shared/dialog/dialog.component';
import { finalize } from 'rxjs/operators'
import { MarkCityLedgerComponent } from '../mark-city-ledger/mark-city-ledger.component';

@Component({
  selector: 'app-acc-receive-payment',
  templateUrl: './acc-receive-payment.component.html',
  styleUrls: ['./acc-receive-payment.component.scss']
})
export class AccReceivePaymentComponent implements OnInit {

  receiveForm: FormGroup;
  paymentModes: string[] = [];
  title: string = 'Receive Payment';
  imgfile: File;
  loading = false;
  selectedPayments = []

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userService: UserService,
    private fb: FormBuilder,
    public globals: Globals,
    private accountsService: AccountsService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<AccReceivePaymentComponent>,
  ) { }

  ngOnInit(): void {
    this.getConfig();
    this.initForm();
    if (this.data?.title) {
      this.title = this.data.title;
    }
  }

  initForm() {
    this.receiveForm = this.fb.group({
      typeOfPayment: ['', Validators.required],
      amount: ['', Validators.required],
      comments: ['', Validators.required],
      imgUrl: ['']
    })
  }

  getConfig() {
    this.userService.getProductConfig(this.data.hotelId).subscribe(res => {
      this.paymentModes = res?.conf?.paymentModes?.filter((m) => m !== 'Bill to Company');
    })
  }

  fileChange(event) {
    this.imgfile = event.files[0];
  }

  submitClick() {
    const dialogBody = {
      title: this.title, body: `Are you sure you want to ${this.data?.vendor ? 'make' : 'receive'} payment ?`,
      confirmBtn: 'Yes', cancelBtn: 'No'
    };
    const dialogRef = this.dialog.open(DialogComponent, {data: dialogBody});

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'true') {
       if (this.imgfile) {
        this.uploadAndSubmit()
       } else {
        this.submit();
       }
      }
    });
  }

  uploadAndSubmit() {
    this.loading = true;
    const ts = new Date().getTime();
    this.userService
      .uploadFile(this.imgfile, `${this.data.hotelId}/${ts}-${this.imgfile.name}`)
      .pipe(finalize(() => this.loading = false))
      .subscribe(
        (img) => {
          this.receiveForm.controls.imgUrl.setValue(img.url);
          this.submit();
        },
        (err) => {}
      );
  }

  submit() {
    this.loading = true;
    if (this.data?.company?.id) {
      this.receivePayment();
    } else {
      this.makePayment();
    }
  }

  receivePayment() {
    const body = {...this.receiveForm.value, companyId: this.data?.company?.id, paymentIds: this.selectedPayments};
    this.accountsService.receivePayment(this.data.hotelId, body)
    .pipe(finalize(() => this.loading = false))
    .subscribe(res => {
      this.dialogRef.close(res);
    })
  }

  makePayment() {
    const body = {...this.receiveForm.value, vendorId: this.data?.vendor?.id};
    this.accountsService.makePayment(this.data.hotelId, body)
    .pipe(finalize(() => this.loading = false))
    .subscribe(res => {
      this.dialogRef.close(res);
    })
  }

  openMarkCityLedger() {
    const dref = this.dialog.open(MarkCityLedgerComponent, {
      width: '1000px',
      data: {hotelId: this.data.hotelId, company: this.data.company}
    })

    dref.afterClosed().subscribe(res => {
      this.selectedPayments = res?.selectedPaymentIds;
    });

  }



}
