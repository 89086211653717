import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommunicationService {

  private balance = new BehaviorSubject<string>('null');
  balanceChange = this.balance.asObservable();

  private company = new BehaviorSubject<string>('');
  companyChange = this.company.asObservable();

  private posOrder = new BehaviorSubject<string>('');
  posOrderChange = this.posOrder.asObservable();

  private menuOpened = new BehaviorSubject<any>(null);
  menuOpened$ = this.menuOpened.asObservable();

  stayViewReload: EventEmitter<any> = new EventEmitter();
  expenseReload: EventEmitter<any> = new EventEmitter();
  purchaseOrderReload: EventEmitter<any> = new EventEmitter();
  indentReload: EventEmitter<any> = new EventEmitter();
  consumptionReload: EventEmitter<any> = new EventEmitter();

  constructor() { }

  emitStayViewReload() {
    this.stayViewReload.emit();
  }

  emitBalanceChange(change) {
    this.balance.next(change);
  }

  emitCompanyChange(change) {
    this.company.next(change);
  }

  emitPosOrderChange(change) {
    this.posOrder.next(change);
  }

  emitExpensesReload() {
    this.expenseReload.emit();
  }

  emitPurchaseOrderReload() {
    this.purchaseOrderReload.emit();
  }

  emitIndentReload() {
    this.indentReload.emit();
  }

  emitconsumptionReload() {
    this.consumptionReload.emit();
  }

  allocationMenuOpened(data) {
    this.menuOpened.next(data);
  }
}
