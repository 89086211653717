<h4 class="text-success">{{data}}</h4>

<!-- <div class="row">
    <div class="col-sm-9">
        <h4 class="text-warning">{{data}}</h4>
    </div>
    <div class="col-sm-3">
        <button mat-raised-button>Dismiss</button>
    </div>    
</div> -->

