import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Globals } from 'src/app/core/globals.service';
import { StoreService } from 'src/app/core/store.service';
import { UserService } from 'src/app/core/user.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  tabItems = [
    {key: 'purchaseOrder', name: 'Purchase Orders'},
    {key: 'invoice', name: 'Invoices'},
    {key: 'indent', name: 'Indent'},
    {key: 'stock', name: 'Stock'},
    {key: 'consumption', name: 'Consumption'},
    {key: 'reports', name: 'Reports'},
    {key: 'settings', name: 'Settings'}
  ];

  currentTab: string;

  storeConfig: any;
  storeFeatures: any;
  hotelId: string;

  loaded = false;

  constructor(public storeService: StoreService,
              private route: ActivatedRoute,
              public globals: Globals,
              private userService: UserService
              ) { }

  ngOnInit(): void {
    Object.freeze(this.tabItems);
    this.route.params.subscribe(params => {
      this.hotelId = params.hotelId;
      this.globals.changeHotelID(this.hotelId);
    });
    this.getFeatures();
    this.getStoreConfig();

    const lastIndex = sessionStorage.getItem('storesCurrentTab') ? sessionStorage.getItem('storesCurrentTab') : 0;
    this.changeCurrentTab(lastIndex);
  }

  changeCurrentTab(index) {
    this.currentTab = this.tabItems[index].key;
    sessionStorage.setItem('storesCurrentTab', index);
  }

  getFeatures() {
    this.userService.userFeatures$.subscribe(res => {
      if (res) {
        this.storeFeatures = res.live?.stores;
        if (this.storeFeatures) {
          this.storeFeatures.role = res.userRole;
        }
      }
    })
  }

  getStoreConfig() {
    this.storeService.getStoreConfig(this.hotelId).subscribe(data => {
      this.storeConfig = data;
      this.loaded = true;
    });
  }

}
