import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DialogComponent } from 'src/app/_shared/dialog/dialog.component';
import { PosService } from 'src/app/core/pos.service';
import { BillComponent } from '../bill/bill.component';
import { PaymentsDetailsComponent } from '../../stayview/payment-details/payment-details.component';
import { UserService } from 'src/app/core/user.service';

@Component({
  selector: 'app-pos-order-view',
  templateUrl: './pos-order-view.component.html',
  styleUrls: ['./pos-order-view.component.scss']
})
export class PosOrderViewComponent implements OnInit {
  private subscriptions: Subscription[] = [];

  hotelId: string;
  currency = localStorage.getItem('currencyCode');
  posName: string;
  orders = [];
  posConfig = {};

  constructor(
    private posService: PosService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private router: Router,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    const snapshot = this.route.snapshot;
    this.hotelId = snapshot.params?.hotelId;
    this.posName = snapshot.queryParams?.posName;
    const ordersStr = snapshot.queryParams?.orders;
    this.posConfig = this.posService.currentPosConfig;
    if (ordersStr) {
      const {billed, unbilled} = JSON.parse(ordersStr);
      const filteredbilled = billed.filter(obj => obj.balance > 0 && !obj.order_billed_to_room);
      this.orders = this.setOrders([...unbilled, ...filteredbilled]);
      this.updateTimesElapsed();
    } else {
      this.getOrders();
    }
  }

  getOrders() {
    this.userService.getCurrentDate(this.hotelId).subscribe(res => {
      this.posService.getPosOrders(this.hotelId, this.posName, res?.currentDate, res?.currentDate, res?.currentDate)
      .subscribe((data) => {
        const filteredbilled = data.billed.filter(obj => obj.balance > 0 && !obj.order_billed_to_room);
        this.orders = this.setOrders([...data.unbilled, ...filteredbilled]);
        this.updateTimesElapsed();
      });
    });
  }

  setOrders(orders) {
    const sortedArray = orders.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
    return sortedArray;
  }

  orderReadyToggle(order) {
    const newStatus = order.status === 'ready' ? 'Unbilled': 'ready';

    const dialogBody = {
      title: 'Change Status',
      body: `Are you sure you want to ${newStatus === 'ready' ? 'Mark ready ?': 'Mark not ready ?'}`,
      confirmBtn: 'Yes',
      cancelBtn: 'Cancel',
    };
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '400px',
      data: dialogBody,
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res === 'true') {
        this.posService.orderReadyStatusToggle(order.invoice_id, newStatus, this.hotelId).subscribe(res => {
          if (res?.success) {
            order.status = newStatus;
          }
        })
      }
    })
  }

  viewBill(order) {
    order.services_used.data = order.services_used;
    const data = { order, config: this.posConfig };
    this.dialog.open(BillComponent, { data });
  }

  openOrder(orderInfo?, table?, billOrder?) {
    this.router.navigate([`pos-create-order/${this.hotelId}`], {
      queryParams: {
        posName: this.posName,
        orderInfo: orderInfo ? JSON.stringify(orderInfo) : '',
        table: table ? JSON.stringify(table) : '',
        billOrder,
        backRoute: 'pos-order-view'
      }
    });
  }

  print(order) {
    this.getCurrentOrder(order.invoice_id).subscribe(res => {
      this.posService.escPosPrint(order.status !== 'Billed' ? 'edit' : 'view', res, this.posConfig);
    })
  }

  goToAddPayment(orderInfo) {
    this.dialog.open(PaymentsDetailsComponent, {
      disableClose: true,
      data: {
        allocId: null,
        invoiceId: orderInfo?.invoice_id,
        mode: 'newEntry',
        balance: orderInfo?.balance,
        pos: true,
        billed: orderInfo?.status === 'Billed' ? true : false,
        posName: this.posName,
      },
    });
  }
  
  getCurrentOrder(invoiceId) {
    return this.userService.getInvoiceById(this.hotelId, invoiceId, 'True');
  }

  deleteOrder(order, i) {
    const dialogBody = {
      title: 'DELETE ORDER',
      body: `Are you sure you want to delete this order?`,
      confirmBtn: 'Yes',
      cancelBtn: 'Cancel',
    };
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '400px',
      data: dialogBody,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'true') {
        this.posService
          .deletePosOrder(this.hotelId, this.posName, order.invoice_id, localStorage.getItem('user'), order.order_id)
          .subscribe((data) => {
            if (data && 'error' in data) {
              this.dialog.open(DialogComponent, {
                data: { title: 'ERROR', body: data.error, cancelBtn: 'Ok' },
              });
            } else {
              this.orders.splice(i, 1);
            }
          });
      }
    });
  }

  private updateTimesElapsed() {
    const now = new Date();
    this.orders.forEach(obj => {
      const targetDate = new Date(obj.date);
      const timeDiff = now.getTime() - targetDate.getTime();
      obj.minutesElapsed = Math.floor(timeDiff / (1000 * 60));
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe())
  }
}
