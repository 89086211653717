import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ReservationService } from 'src/app/core/reservation.service';
import { DialogComponent } from 'src/app/_shared/dialog/dialog.component';

@Component({
  selector: 'app-edit-booking-id',
  templateUrl: './edit-booking-id.component.html',
  styleUrls: ['./edit-booking-id.component.scss'],
})
export class EditBookingIdComponent implements OnInit {
  newBookingId = '';
  submitting: boolean = false;
  currentBookingId = '';
  currentUser = '';
  newChannel = undefined;
  currentChannel = undefined;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private guestService: ReservationService,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<EditBookingIdComponent>
  ) {}

  ngOnInit(): void {
    this.currentUser = this.data.user;
    this.currentBookingId = this.data.oldId;
    this.currentChannel = this.data.currentChannel;
  }

  closeDialog() {
    this.dialogRef.close();
  }
  submit(newId) {
    if ((newId && this.data.oldId) || this.data.currentChannel) {
      const dialogBody = {
        title: 'Change Booking Info',
        body: 'Are you sure you want to Submit ?',
        confirmBtn: 'Submit',
        cancelBtn: 'Cancel',
      };
      const dialogRef = this.dialog.open(DialogComponent, { data: dialogBody });
      dialogRef.afterClosed().subscribe((result) => {
        if (result === 'true') {
          this.submitting = true;
          this.guestService
            .changeBookingId(
              this.data.oldId,
              newId,
              this.data.hotelId,
              this.currentUser,
              this.currentChannel,
              this.newChannel
            )
            .subscribe((success) => {
              this.submitting = false;
              success = success;
              var WarningDialog = {};
              if (success.error) {
                WarningDialog = {
                  title: 'Error !',
                  body: success['error'],
                  cancelBtn: 'Dismiss',
                };
                const dialogRef = this.dialog.open(DialogComponent, {
                  data: WarningDialog,
                });
                dialogRef.disableClose = true;
                dialogRef.afterClosed().subscribe((result) => {
                  if (result === 'false') {
                    this.dialog.closeAll();
                    window.location.reload();
                  }
                });
              } else {
                window.location.reload();
              }
            });
        }
      });
    }
  }
}
