<div *ngIf="type === 'group'">
  <div class="heading flex-space-bw">
    <h2>{{ "maintenance.grouptitle" | translate }}</h2>
  </div>
  <mat-divider></mat-divider>
</div>

<div *ngIf = "type != 'group' && showPage != true">
  <span>You Dont Have Access to view this Page!!!</span>
</div>

<div *ngIf = "(type == 'group') || (type != 'group' && showPage == true)">
  <div class="container-fluid common_content">
    <div class="filters-wrapper">
      <div *ngIf="type === 'group' && groupId === ''">
        <span style="color: red">Not Configured at Group Level!!</span>
      </div>
      <form [formGroup]="filtersForm">
        <form formGroupName="date" class="d-content">
          <div class="d-flex flex-column gap-2">
            <label>{{ "maintenance.start_date" | translate }}</label>
            <input
              type="date"
              class="form-control form-control-sm"
              formControlName="startDate"
            />
          </div>
  
          <div class="d-flex flex-column gap-2">
            <label>{{ "maintenance.end_date" | translate }}</label>
            <input
              type="date"
              class="form-control form-control-sm"
              formControlName="endDate"
            />
          </div>
        </form>
  
        <form formGroupName="localFilter" class="d-content">
          <div class="d-flex flex-column gap-2" *ngIf="type !== 'group'">
            <label>{{ "maintenance.area" | translate }}</label>
            <select
              name="Area"
              class="form-control form-control-sm"
              formControlName="area"
            >
              <option value="" selected>All</option>
              <option *ngFor="let area of config?.areas" [value]="area">
                {{ area }}
              </option>
            </select>
          </div>
  
          <div class="d-flex flex-column gap-2" *ngIf="type !== 'group'">
            <label>{{ "maintenance.type" | translate }}</label>
            <select
              name="Type"
              class="form-control form-control-sm"
              formControlName="typeOfOperation"
            >
              <option value="">All</option>
              <option
                *ngFor="let type of config?.typeOfOperations"
                [value]="type"
              >
                {{ type }}
              </option>
            </select>
          </div>
  
          <div class="d-flex flex-column gap-2" *ngIf="type === 'group'">
            <label>{{ "maintenance.hotel" | translate }}</label>
            <select
              name="hotel"
              class="form-control form-control-sm"
              formControlName="hotelName"
            >
              <option value="">All</option>
              <option *ngFor="let hotel of hotels" [value]="hotel.name">
                {{ hotel.name }}
              </option>
            </select>
          </div>
  
          <div class="d-flex flex-column gap-2">
            <label>{{ "maintenance.status" | translate }}</label>
            <select
              name="Status"
              class="form-control form-control-sm"
              formControlName="status"
            >
              <option value="">All</option>
              <option *ngFor="let status of config?.statuses" [value]="status">
                {{ status }}
              </option>
            </select>
          </div>
  
          <div class="d-flex flex-column gap-2">
            <label>{{ "maintenance.assigned_to" | translate }}</label>
            <input
              type="text"
              class="form-control form-control-sm"
              maxlength="64"
              formControlName="assignedTo"
            />
          </div>
  
          <div class="d-flex flex-column gap-2">
            <label>{{ "maintenance.priority" | translate }}</label>
            <select
              class="form-control form-control-sm"
              name="Priority"
              formControlName="priority"
            >
              <option value="">{{ "maintenance.all" | translate }}</option>
              <option value="low">{{ "maintenance.low" | translate }}</option>
              <option value="medium">
                {{ "maintenance.medium" | translate }}
              </option>
              <option value="high">{{ "maintenance.high" | translate }}</option>
            </select>
          </div>
        </form>
      </form>
    </div>
  
    <div class="d-flex justify-content-end mb-3" >

      <input type="text" class="form-control form-control-sm mr-1" style="width: 200px;" placeholder="Ticket No." #ticketSearch>

      <button mat-stroked-button 
        class="px-3 btn-sm pr-border"
        (click)="filterByTicketNum(ticketSearch.value)">
        Search
      </button>

      <button *ngIf="type !== 'group'"
        (click)="openCreateIssue()"
        mat-stroked-button
        class="px-3 btn-sm pr-border ml-1">
        {{ "maintenance.create" | translate }}
      </button>
    </div>
  
    <div class="table-container">
      <table class="table table-bordered">
        <thead class="thead-dark">
          <tr>
            <th>{{ "maintenance.creation_date" | translate }}</th>
            <th *ngIf="type === 'group'">
              {{ "maintenance.hotelname" | translate }}
            </th>
            <th>{{ "maintenance.heading" | translate }}</th>
            <th>Ticket No.</th>
            <th>{{ "maintenance.area" | translate }}</th>
            <th>{{ "maintenance.operation_type" | translate }}</th>
            <th>{{ "maintenance.room_number" | translate }}</th>
            <th>{{ "maintenance.common_area" | translate }}</th>
            <th>{{ "maintenance.status" | translate }}</th>
            <th>{{ "maintenance.priority" | translate }}</th>
            <th>{{ "maintenance.assigned_to" | translate }}</th>
            <th>{{ "maintenance.last_updated" | translate }}</th>
            <th>{{ "maintenance.description" | translate }}</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
  
        <tbody>
          <ng-container *ngFor="let issue of mainIssuesFiltered; let i = index">
            <tr>
              <td>{{ issue.creationDate | date : "dd/MM/yy hh:mm a" }}</td>
              <td *ngIf="type === 'group'">{{ issue.hotelName }}</td>
              <td>{{ issue.heading }}</td>
              <td>{{ issue.ticketNum }}</td>
              <td>{{ issue.area }}</td>
              <td>{{ issue.typeOfOperation }}</td>
              <td>{{ issue.roomNum }}</td>
              <td>{{ issue.commonArea }}</td>
              <td>{{ issue.status }}</td>
              <td>{{ issue.priority }}</td>
              <td>{{ issue.assignedTo }}</td>
              <td>{{ issue.lastUpdated | date : "dd/MM/yy hh:mm a" }}</td>
              <td>{{ issue.description }}</td>
              <td>
                <button mat-button [matMenuTriggerFor]="menu">
                  {{ "maintenance.actions" | translate }}
                </button>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item (click)="editIssue(issue)">
                    {{ "maintenance.edit" | translate }}
                  </button>
                  <button
                    mat-menu-item
                    [hidden]="type === 'group'"
                    (click)="updateIssue(issue)"
                  >
                    {{ "maintenance.update" | translate }}
                  </button>
                  <button
                    mat-menu-item
                    [hidden]="type === 'group'"
                    (click)="deleteIssue(issue)"
                  >
                    {{ "maintenance.delete" | translate }}
                  </button>
                </mat-menu>
              </td>
              <td (click)="showIssueUpdates(issue)">
                <mat-icon>keyboard_arrow_{{ !!!issue.shown ? "down" : "up" }}</mat-icon>
              </td>
            </tr>
  
            <tr [hidden]="!issue.shown">
              <td class="alt-row" colspan="13">
                <div [@expandCollapse]="!issue.shown ? 'collapsed' : 'expanded'">
                  <table
                    *ngIf="issue?.updates?.length"
                    class="table nested-table"
                  >
                    <thead>
                      <tr class="bg-lightgrey">
                        <th>{{ "maintenance.date" | translate }}</th>
                        <th>{{ "maintenance.status_changed" | translate }}</th>
                        <th>
                          {{ "maintenance.status_after_update" | translate }}
                        </th>
                        <th>
                          {{ "maintenance.assigned_to_after_update" | translate }}
                        </th>
                        <th>{{ "maintenance.comment" | translate }}</th>
                        <th>{{ "maintenance.comment_by" | translate }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="sub-row" *ngFor="let update of issue?.updates">
                        <td>{{ update.date | date : "dd/MM/yy hh:mm a" }}</td>
                        <td>{{ update.statusChanged ? "Yes" : "No" }}</td>
                        <td>{{ update.statusAfterUpdate }}</td>
                        <td>{{ update.assignedToAfterUpdate }}</td>
                        <td>{{ update.comment }}</td>
                        <td>{{ update.commentBy }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div
                  *ngIf="!issue?.updates?.length"
                  class="d-flex justify-content-center alert alert-warning"
                >
                  {{ "maintenance.no_updates" | translate }}
                </div>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
</div>