<div class="container">
    <div class="row heading">
        <h1>{{data.mode | titlecase}} Company</h1>
    </div>
    <mat-divider></mat-divider>

    <div class="form-container">
        <form [formGroup]="companyForm" (ngSubmit)="onSubmit(companyForm)">
            <div class="row">
                <div class="col-sm-3">
                    <label for="company-name">{{'companies.company_name' | translate}}</label>
                    <input type="text" class="form-control" id="company-name"
                    formControlName="company_name">
                </div>
                <div class="col-sm-3">
                    <label for="contact-person">{{'companies.contact_person' | translate}}</label>
                    <input type="text" class="form-control" id="contact-person"
                    formControlName="contact_person">
                </div>
                <div class="col-sm-3">
                    <label for="email">{{'companies.email' | translate}}</label>
                    <input type="email" class="form-control" id="email"
                    formControlName="email">
                </div>
                <div class="col-sm-3">
                    <label for="phone">{{'companies.phone' | translate}}</label>
                    <input type="phone" class="form-control" id="phone"
                    formControlName="phone_num">
                </div>
                
            </div>
            <div class="row mt-4">
                <div class="col-sm-9">
                    <label for="address">{{'companies.address' | translate}}</label>
                    <input type="text" class="form-control" id="address"
                    formControlName="address">
                </div>
                <div class="col-sm-3">
                    <label for="tax-num">{{'companies.gst/vat' | translate}}</label>
                    <input type="text" class="form-control" id="tax-num"
                    formControlName="tax_num">
                </div>
            </div>
            <div class="row mt-4">
                <h4>{{'companies.contracted_rate' | translate}} :</h4>
            </div>
            <div class="row">
                
                <!-- <div class="col-sm-3">
                    <label for="chain">Applicable to chain</label>
                    <input type="text" class="form-control" disabled=true>
                </div> -->
                <div formArrayName="contracted_rate" 
                *ngFor="let room of companyForm.get('contracted_rate')['controls']; let i=index">
                    <div [formGroupName]="i" class="col-sm-11">
                        <label for="">{{room['controls']['room_id'].value | titlecase }}</label>
                        <label for="">, {{ room['controls']['occupancy'].value | titlecase }}</label>
                        <label for="">, {{ room['controls']['mealplan'].value }}&ensp;</label>
                        <input type="number" class = "form-control" formControlName="rate">
                    </div>
                </div>
            </div>
            <div class="row mt-4 action-buttons">
                <button type="button" mat-raised-button (click)="closeDialog()">
                    {{'companies.cancel_btn' | translate}}
                </button>
                <button type="submit" mat-raised-button (click)=" closeDialog()"
                    [disabled]="!companyForm.valid" class="active">
                    {{'companies.submit_btn' | translate}}
                </button>
            </div>
        </form>
    </div>
</div>