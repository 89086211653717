<div class="tab-container">
    
    <div class="container">

        <div class="header_row pt-1 pb-2 bg-white">

            <div>
                <div>
                    <label for="From Date">From Date:</label>
                    <input type="date" class="form-control" id="from-date" max="{{endDate}}" [(ngModel)]="startDate">
                </div>
                <div>
                    <label for="To Date">To Date:</label>
                    <input type="date" class="form-control" id="to-date" min="{{startDate}}" [(ngModel)]="endDate">
                </div>
                <div>
                    <label for="To Date">Type</label>
                    <select class="form-control" [(ngModel)]="selectedMode">
                        <option value="sales">Sales</option>
                        <option value="purchases">Purchases</option>
                    </select>
                </div>
                
                <button class="btn pr-border" (click)="getData()">
                    Generate
                </button>
            </div>

            <div>
                <button class="btn pr-border" (click)="openGstr()">
                    GSTR1
                </button>
            </div>

        </div>

        <table class="table mt-3">
            <thead class="thead-dark">
                <tr>
                    <th>Tax</th>
                    <th class="text-right">Total</th>
                    <th [ngStyle]="{'width': '200px'}"></th>
                </tr>
            </thead>
    
            <tbody>
                <ng-container *ngFor="let item of taxesData; let i = index">
                    <tr (click)="item.hidden = !!!item.hidden" class="main-row">
                        <td>{{item.taxGroup}}</td>
                        <td class="text-right">{{item.total}}</td>
                        <td class="d-flex justify-content-end">
                            <button class="btn-sm pr-border mr-2" (click)="viewLedger(item.taxGroup); $event.stopPropagation()">
                                View Ledger
                            </button>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="3" class="alt-row">
                            <div [@expandCollapse]="item.hidden ? 'expanded' : 'collapsed'">
                                <table class="table nested-table">
                                    <tbody>
                                        <tr @slide
                                            class="sub-row" 
                                            *ngFor="let t of item.taxes">

                                            <td class="text-center">{{t.name}}</td>
                                            <td class="text-right">{{t.amount}}</td>
                                            <td [ngStyle]="{'width': '200px'}"></td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>

    </div>

</div>