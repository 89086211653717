import { DatePipe } from '@angular/common';
import { OnInit, Component, Input, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { HRModuleSevice } from 'src/app/core/hr-module.service';
import { saveAs } from 'file-saver-es';

@Component({
  selector: 'app-salary',
  templateUrl: './salary.component.html',
  styleUrls: ['./salary.component.scss']
})
export class SalaryComponent implements OnInit {
  @Input() hotelId: string;
  @Input() user: string;
  @Input() groupId: string;
  @Input() showSalary: boolean;
  level: string;
  constructor(public dialog: MatDialog, private hrService: HRModuleSevice, private datePipe: DatePipe) { }

  displayedColumns: string[] ;
  dataSource = new MatTableDataSource();

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  ELEMENT_DATA: any[];
  month = this.datePipe.transform(new Date(), 'yyyy-MM');
  ngOnInit(): void {
    this.fetchSalaryAndAttendance();
    if (!this.groupId){
      this.level = "hotel";
    }
  }

  selectedMonth(){
    this.fetchSalaryAndAttendance();
  }

  fetchSalaryAndAttendance(){
    var date = new Date(this.month);
    this.hrService.fetchSalaryAndAttendance(this.hotelId, date.getMonth()+1, date.getFullYear()).subscribe(success=>{
      this.dataSource.data = success.details;
      this.displayedColumns = success.headings;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    })
  }

  downloadSalary(group = null){
    var date = new Date(this.month);
    this.hrService.downloadSalary(this.hotelId, date.getMonth()+1, date.getFullYear(), this.user ,group).subscribe(
      data => {
        let blob:any = new Blob([data], { type: 'text/xlsx; charset=utf-8' });
        let fileName = this.month+'_Employees_Salary.xlsx'
        saveAs(blob, fileName);
      });
  }

  generateBankTransFile(group = null){
    var date = new Date(this.month);
    this.hrService.getBankFile(this.hotelId, date.getMonth()+1, date.getFullYear(), group).subscribe(
      data => {
        let blob:any = new Blob([data], { type: 'text/csv; charset=utf-8' });
        let fileName = this.month+'_bank_file.csv'
        saveAs(blob, fileName);
      });
  }

}
