import { Component, OnInit, HostListener, AfterViewInit } from '@angular/core';
import { Globals } from './core/globals.service';
import { ActivatedRoute, Router, NavigationEnd} from '@angular/router';
import {delay} from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from './core/user.service';
import { Title } from '@angular/platform-browser'; 
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'PMS';

  opened = true;
  modeVar = 'side';
  isLoading: boolean;
  constructor(
    private globals: Globals, 
    private activatedRoute: ActivatedRoute,
    private translateService: TranslateService,
    private userService: UserService,
    private titleService: Title,
    private router: Router
  ) {
      translateService.setDefaultLang('en');
      var lang = localStorage.getItem('lang');
      if (lang) {
        this.translateService.use(lang);
      }
  }

  changeMode() {
    if (this.globals.innerWidth < 850) {
      this.opened = false;
    }
    if (this.globals.innerWidth <= 480){
      this.modeVar = 'over';
    } else if (this.globals.innerWidth > 480){
      this.modeVar = 'side';
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.changeMode();
  }

  ngOnInit(){
    this.changeMode();
    this.globals.isLoading$.
    pipe(delay(0)).
    subscribe(data => this.isLoading = data);
    this.getUserFeatures();

    this.router.events.pipe(  
      filter(event => event instanceof NavigationEnd),  
    ).subscribe(() => {  
      const rt = this.getChild(this.activatedRoute);  
      rt.data.subscribe(data => {  
        this.titleService.setTitle(data.title)});  
    });
  };

  getChild(activatedRoute: ActivatedRoute) {  
    if (activatedRoute.firstChild) {  
      return this.getChild(activatedRoute.firstChild);  
    } else {  
      return activatedRoute;  
    }  
  };

  sideBarToggler(){
    this.opened = !this.opened;
  }

  changeOfRoutes() {
    this.activatedRoute.queryParams.subscribe(queryParams => {
      if (!queryParams.token) {
        return;
      }
      this.globals.setUser(queryParams.user);
      localStorage.setItem('user', queryParams.user);
      localStorage.setItem('token', queryParams.token);

      if (queryParams.lang) {
        localStorage.setItem('lang', queryParams.lang);
        this.translateService.use(queryParams.lang);
      }
    });
  }

  getUserFeatures() {
    const body = {username: localStorage.getItem('user')};
    this.userService.getUserData(body).subscribe(response => {
      this.userService.userFeatures = {...response.features, userRole: response.role};
    });
  }
}
