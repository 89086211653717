
<nav class="navbar" role="navigation" aria-label="main navigation">
    <div class="navbar-brand" *ngIf = "!showBackbutton">
      {{ "operations.enter_pms_operations_data" | translate }}
    </div>
    <div class="navbar-brand" *ngIf = "showBackbutton">
        {{ "operations.modify_pms_operations_data" | translate }}
    </div>
    <div class="navbar-end" *ngIf = "showBackbutton">
        <div class="navbar-item">
            <button class="buttonStyle" (click)="back()">
                {{ "operations.back_btn" | translate }}
            </button>
        </div>
    </div>
</nav>
<hr>
<div class="mat-elevation-z8">
    <div id="row0">

    </div>
    <h2>{{ "operations.daily" | translate }} </h2>
    <div id="row1">
        
    </div>
    <!-- <br> -->
    <h2>{{ "operations.weekly" | translate }} </h2>
    <div id="row2">

    </div>
    <br>
    <h2>{{ "operations.monthly" | translate }} </h2>
    <div id="row3">

    </div>
    <br>
    <br>
    <button (click)="submit()" mat-raised-button class="submitButton">{{ "operations.submit_btn" | translate }}</button>
</div>



