import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { StoreService } from 'src/app/core/store.service';
import { StoreCommonService } from '../../store-common.service';
import { Globals } from 'src/app/core/globals.service';
import { CommunicationService } from 'src/app/core/communication.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { saveAs } from 'file-saver-es';
import { CopyInvoiceComponent } from '../../modals/copy-invoice/copy-invoice.component';

@Component({
  selector: 'app-indent-items',
  templateUrl: './indent-items.component.html',
  styleUrls: ['./indent-items.component.scss']
})
export class IndentItemsComponent implements OnInit, OnDestroy {

  componentDestroyed$ = new Subject();
  indentItems: Array<any>;
  selectedItem: any;
  indentTotal: any = {quantity: 0, pre_tax_amount: 0, tax: {total: 0, break_up: []}, total_amount: 0};
  lastTaxDetails = {pre_tax_amount: 0, tax_details: {total: 0, break_up: []}, total_amount: 0};
  userDepartments: Array<any>;
  masterItems: any[];
  requesterDept = '';
  categories: any[];
  subCategories: any[];
  category = '';
  subCategory = '';
  printDisabled = false;
  indentDate: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<any>,
              public storeService: StoreService,
              public storeCommon: StoreCommonService,
              public globals: Globals,
              public commS: CommunicationService,
              private dialog: MatDialog
              
              ) { }

  ngOnInit(): void {
    this.selectedItem = {id: '', name: '', quantity: 0, rate: null, pre_tax_amount: null, tax: {total: null}, total_amount: null, comments: null};
    this.getDepts();
    if (this.data.mode !== 'view') {
      this.masterItems = JSON.parse(JSON.stringify(this.data.masterItems));
      this.getCategories();
      this.setIndentDate();
    }
    if (this.data?.indentItems?.length) {
      this.indentItems = JSON.parse(JSON.stringify(this.data.indentItems));
      for (const item of this.indentItems) {
        this.indentTotal = this.storeCommon.calculateTotal('+', item, this.indentTotal);
      }
    }
    if (this.data.mode === 'edit' || this.data.mode === 'view') {
      this.requesterDept = this.data.department?.id;
    }
  }

  getDepts() {
    this.storeService.getDeptByUser(this.data.hotelId)
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe(data => {
      this.userDepartments = data?.departments;
    });
  }

  getCategories() {
    this.storeService.getCategories(this.data.hotelId)
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe(data => {
      if (data?.categories) {
        this.categories = data.categories;
      }
    });
  }

  selectItem(id: string) {
    if (!id) {
      this.selectedItem = {id: '', name: '', quantity: 0, rate: null, pre_tax_amount: null, tax: {total: null}, total_amount: null, comments: null};
      return;
    }
    for (const item of this.data.masterItems) {
      if (item.id === id) {
        this.selectedItem = JSON.parse(JSON.stringify(item));
        this.selectedItem.rate = item.rate === null ? 0 : item.rate;
        this.selectedItem.quantity = 1;
        this.getAvgRate();
        break;
      }
    }
  }

  getAvgRate() {
    this.storeService.getAverageRate(this.data.hotelId, this.selectedItem.id)
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe(rate => {
      this.getTaxDetails(rate.average_rate);
    });
  }

  getTaxDetails(avgRate) {
    const body = {tax_group: this.selectedItem.tax_group, tax_inclusive: this.selectedItem.tax_inclusive, amount: avgRate};
    this.storeService.calculateTax(this.data.hotelId, body).subscribe(data => {
      this.lastTaxDetails = JSON.parse(JSON.stringify(data));
      const newData = this.storeCommon.changeTaxFeilds(this.selectedItem, this.lastTaxDetails);
      this.selectedItem = this.storeCommon.updateSelectedItem(this.selectedItem, newData);
    });
  }

  pushItem(item) {
    if (!this.indentItems) {
      this.indentItems = [];
    }
    if (!(item.name.length >= 1 && this.storeCommon.isQuantityValid(item.quantity))) {
      return;
    }
    for (const inItem of this.indentItems) {
      if (item.id === inItem.id) {
        return;
      }
    }
    const unWantedProperties = ['category', 'sub_category', 'vendor_ids', 'tax_group', 'tax_inclusive', 'is_inventoried', 'is_capital_item', 'stock'];
    this.deleteUnwantedProperties(unWantedProperties);
    this.indentItems.push(JSON.parse(JSON.stringify(item)));
    this.indentTotal = this.storeCommon.calculateTotal('+', item, this.indentTotal);
  }

  deleteUnwantedProperties(properties) {
    for (const prop of properties) {
      if (this.selectedItem.hasOwnProperty(prop)) {
        delete this.selectedItem[prop];
      }
    }
  }

  onQuantityChange() {
    if (this.selectedItem.id?.length > 0) {
      this.selectedItem = this.storeCommon.onQuantityChange(this.selectedItem, this.lastTaxDetails);
    }
  }

  onItemQuantityChange(item, newQty) {
    if (!this.storeCommon.isQuantityValid(newQty)) {
      return;
    }
    item = this.storeCommon.onItemQuantityChange(item, newQty);
    this.indentTotal = {quantity: 0, pre_tax_amount: 0, tax: {break_up: [], total: 0}, total_amount: 0};
    for (const eitem of this.indentItems) {
      this.indentTotal = this.storeCommon.calculateTotal('+', eitem, this.indentTotal);
    }
  }

  removeItem(item) {
    for (const cItem of this.indentItems) {
      if (cItem.id === item.id) {
        this.indentItems.splice(this.indentItems.indexOf(item), 1);
        break;
      }
    }
    this.indentTotal = this.storeCommon.calculateTotal('-', item, this.indentTotal);
  }

  submitIndent() {
    for (const item of this.indentItems) {
      if (!this.storeCommon.isQuantityValid(item.quantity)) {
        return;
      }
    }
    this.indentTotal.dept_id = this.requesterDept;
    const body: any = {items: this.indentItems, indent_info: this.indentTotal, indent_date: this.indentDate};

    if (this.data.mode === 'new') {
      this.storeService.createIndent(this.data.hotelId, body).subscribe(data => {
        this.afterSubmit('created');
      });
    } else if (this.data.mode === 'edit') {
      const deletedItems = this.data.indentItems.filter(this.comparer(this.indentItems));
      body.indent_info.id = this.data.indentId;
      body.deleted_items = deletedItems;
      this.storeService.editIndent(this.data.hotelId, body).subscribe(data => {
        this.afterSubmit('edited');
      });
    }
  }

  afterSubmit(action) {
    this.closeDialog();
    this.globals.snackBarFunc(`Indent ${action} successfully !`);
    this.commS.emitIndentReload();
  }

  closeDialog() {
    this.dialogRef.close();
  }

  comparer(otherArray){
    return (current) => {
      return otherArray.filter((other) => {
        return other.id === current.id && other.quantity === current.quantity && other.total_amount === current.total_amount;
      }).length === 0;
    };
  }

  filterSubCategories(category) {
    this.subCategory = '';
    if (category === '') {
      this.subCategories = [];
      return;
    }
    this.subCategories = this.categories.filter(data => {
      return data?.name === category;
    });
    if (this.subCategories.length > 0) {
      this.subCategories = this.subCategories[0].sub_categories;
    }
  }

  filterMasterItems() {
    const category = this.category.toLowerCase();
    const subCategory = this.subCategory.toLowerCase();

    this.masterItems = this.data.masterItems.filter(data => {
      return data.category?.toLowerCase().indexOf(category) > -1 && data.sub_category?.toLowerCase().indexOf(subCategory) > -1;
    });
  }

  getIndentPdf() {
    this.printDisabled = true;
    this.storeService.getPdf(this.data.hotelId, 'Indent', this.data.indentId)
    .subscribe(data => {
      const blob = new Blob([data], {type: 'text/pdf; charset=utf-8'});
      const fileName = `${this.data.indentId}.pdf`;
      saveAs(blob, fileName);
    });

    setTimeout(() => {
      this.printDisabled = false;
    }, 5000);
  }

  copyInvoice() {
    this.dialogRef.close();
  
    const ref = this.dialog.open(CopyInvoiceComponent, {
      width: '780px',
      height: '600px',
      data: {hotelId: this.data.hotelId, masterItems: this.masterItems}
    })
  }

  setIndentDate() {
    const date = new Date()
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // Adding 1 because getMonth() returns zero-based index
    const day = ('0' + date.getDate()).slice(-2);
    this.indentDate = year + '-' + month + '-' + day;
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }
}
