<div class="primary_modal_wrapper">
    <div class="modal_heading">
        <h1>Add New Service</h1>
    </div>

    <mat-divider></mat-divider>

    <div class="modal_body">
        <form [formGroup]="serviceForm">
            <div class="flex">
                <label >Service Name <span class="text-danger">*</span></label>
                <input type="text" class="form-control form-control-sm" maxlength="64" formControlName="serviceName">
            </div>

            <div class="flex">
                <label >Amount <span class="text-danger">*</span></label>
                <input type="number" class="form-control form-control-sm" 
                    (input)="globals.amountOnly($event)" maxlength="12" formControlName="amount">
            </div>

            <div class="flex">
                <label >Tax Group <span class="text-danger">*</span></label>
                <select  class="form-control form-control-sm" formControlName="taxGroup">
                    <option *ngFor="let tax of taxGroups" [value]="tax">{{tax}}</option>
                </select>
            </div>

            <div class="flex">
                <label >HSN</label>
                <input type="text" class="form-control form-control-sm" maxlength="20" formControlName="hsn">
            </div>

            <div class="flex">
                <label >Tax Inclusive <span class="text-danger">*</span></label>
                <select class="form-control form-control-sm" formControlName="taxInclusive">
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>
            </div>

            <div class="flex">
                <label >Amount Editable</label>
                <select  class="form-control form-control-sm" formControlName="amountEditable">
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                </select>
            </div>

            <div class="flex">
                <label>Category <span class="text-danger">*</span></label>
                <ng-select class="form-control form-control-sm serivce-ng" formControlName="category">
                    <ng-option 
                      *ngFor="let item of categories" 
                      [value]="item.category">
                      {{item.category}}
                    </ng-option>
                 </ng-select>
            </div>

            <!-- <div class="flex">
                <label>Photo</label>
                <div class="common_browse_file">
                    <input type="hidden" class="" value="" name="" maxlength="30">
                    <input type="file" class="custom-file-input" name="busi-for-doc"
                      accept="image/*" (change)="uploadEvent($event.target.files)">
                    <label class="doc-name">
                      <span>{{file.name}}</span></label>
                </div>
            </div> -->

            <div class="flex">
                <label >Comments</label>
                <textarea class="form-control form-control-sm" formControlName="comments"></textarea>
            </div>
        </form>
        
    </div>

    <div class="modal_footer">
        <mat-dialog-actions align="end">
            <button mat-raised-button mat-dialog-close>Cancel</button>
            <button mat-raised-button
                [ngClass]="{'disable_button': serviceForm.invalid}"
                class="primary_btn"
                (click)="submit()">
                Submit
            </button>
        </mat-dialog-actions>
    </div>

</div>