<ng-container *ngIf="mode === 'showServices'">
  <div class="row" style="justify-content: space-between">
    <p>{{ "stayview.allocation_service.services" | translate }}</p>
    <div>
      <button
        mat-button
        class="button1"
        (click)="changeMode('newEntry', 'new')"
      >
        {{ "stayview.allocation_service.add_service" | translate }}
      </button>
      <button mat-button mat-dialog-close class="button1">
        <mat-icon>keyboard_backspace</mat-icon>
        {{ "stayview.allocation_service.back_btn" | translate }}
      </button>
    </div>
  </div>
  <div class="scrollTable">
    <table class="table table-striped">
      <thead class="thead-dark">
        <tr class="text-center">
          <th>{{ "stayview.allocation_service.date" | translate }}</th>
          <th>{{ "stayview.allocation_service.name" | translate }}</th>
          <th>{{ "stayview.allocation_service.qty" | translate }}</th>
          <th>
            {{ "stayview.allocation_service.pre_tax_amount" | translate }}
          </th>
          <th>{{ "stayview.allocation_service.tax" | translate }}</th>
          <th>{{ "stayview.allocation_service.total_amount" | translate }}</th>
          <th>{{ "stayview.allocation_service.action" | translate }}</th>
        </tr>
      </thead>
      <tbody *ngIf="servicedata">
        <tr
          *ngFor="let service of servicedata['servicesInfo']"
          class="text-center"
        >
          <td>{{ service.date | date : "MMM d" }}</td>
          <td>{{ service.serviceName }}</td>
          <td>{{ service.quantity }}</td>
          <td>
            {{ service.pretaxAmount | currency : currency : "code" : ".2-3" }}
          </td>
          <td>{{ service.tax | currency : currency : "code" : ".2-3" }}</td>
          <td>{{ service.total | currency : currency : "code" : ".2-2" }}</td>
          <td class="actions">
            <mat-icon
              (click)="deleteService(service)"
              [hidden]="service.serviceName.includes('Room Charges')"
            >
              delete
            </mat-icon>
          </td>
        </tr>
        <tr>
          <td colspan="3" class="text-right font-weight-bold">
            {{ "stayview.allocation_service.total_amount" | translate }}:
          </td>
          <td class="text-center font-weight-bold">
            {{
              servicedata.totalPriceInfo.totalPretaxAmount
                | currency : currency : "code" : ".2-3"
            }}
          </td>
          <td class="text-center font-weight-bold">
            {{
              servicedata.totalPriceInfo.totalTax
                | currency : currency : "code" : ".2-3"
            }}
          </td>
          <td class="text-center font-weight-bold">
            {{ servicedata.totalPriceInfo.totalAmount | currency : currency }}
          </td>
          <td class="text-center font-weight-bold">&nbsp;</td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-container>

<ng-container *ngIf="mode === 'addService'">
  <div class="add-service">
    <div class="row">
      <h1>{{ "stayview.allocation_service.add_service" | translate }}</h1>
      <hr />
    </div>
    <div class="add-service-form">
      <form [formGroup]="addServiceForm" (ngSubmit)="submit(addServiceForm)">
        <div class="row" style="margin-top: 0">
          <div class="col-sm-3">
            <label for="category">{{
              "stayview.allocation_service.service_category" | translate
            }}</label>
            <select
              class="form-control"
              formControlName="category"
              id="service_category"
              name="service_category"
              (input)="filterServices($event.target.value)"
            >
              <option value="" selected hidden>
                {{ "stayview.allocation_service.select_category" | translate }}
              </option>
              <option
                *ngFor="let service of serviceCategories"
                [value]="service.category"
              >
                {{ service.category }}
              </option>
            </select>
          </div>
          <div class="col-sm-3">
            <label for="service">{{
              "stayview.allocation_service.service" | translate
            }}</label>
            <select
              class="form-control"
              formControlName="service"
              id="service"
              name="service"
              (input)="changeService($event.target.value)"
            >
              <option value="" selected hidden>
                {{ "stayview.allocation_service.select_service" | translate }}
              </option>
              <option
                *ngFor="let service of servicesInfo"
                [value]="service.serviceName"
              >
                {{ service.serviceName }}
              </option>
            </select>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-sm-3">
            <label for="pretax">{{
              "stayview.allocation_service.pretax_rate" | translate
            }}</label>
            <input
              type="number"
              class="form-control"
              id="pretax"
              [(ngModel)]="preTaxRate"
              (input)="getServiceTaxdata(preTaxRate)"
              formControlName="pretaxAmount"
              [readonly]="
                taxInclusive == true ||
                amountEditable == false ||
                servicesInfo?.length == 0
              "
            />
          </div>
          <div class="col-sm-3">
            <label for="tax">{{
              "stayview.allocation_service.tax" | translate
            }}</label>
            <input
              type="number"
              class="form-control"
              [(ngModel)]="tax"
              formControlName="totalTax"
              readonly
            />
          </div>
          <div class="col-sm-3">
            <label for="totalAmount">{{
              "stayview.allocation_service.total_amount" | translate
            }}</label>
            <input
              type="number"
              class="form-control"
              [(ngModel)]="totalAmount"
              (input)="getServiceTaxdata(totalAmount)"
              formControlName="totalAmount"
              [readonly]="
                taxInclusive == false ||
                amountEditable == false ||
                servicesInfo?.length == 0
              "
            />
          </div>
          <div class="col-sm-2">
            <label for="quantity">{{
              "stayview.allocation_service.qty" | translate
            }}</label>
            <input
              type="number"
              min="1"
              class="form-control"
              id="quantity"
              [(ngModel)]="quantity"
              (input)="changeQuantity($event.target.value)"
              formControlName="quant"
            />
          </div>
        </div>
        <div
          class="row action-buttons mt-4"
          style="margin-bottom: 0; justify-content: flex-end"
        >
          <button
            class="mr-1"
            (click)="changeMode('editStay')"
            mat-raised-button
          >
            {{ "stayview.allocation_service.cancel_btn" | translate }}
          </button>
          <button
            class="button1 mr-1"
            type="submit"
            mat-raised-button
            mat-dialog-close
            [disabled]="addServiceForm.value.service == ''"
          >
            {{ "stayview.allocation_service.submit_btn" | translate }}
          </button>
        </div>
        <br />
      </form>
    </div>
  </div>
</ng-container>
