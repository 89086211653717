import { Component, Inject, OnInit } from '@angular/core';
import { BookingService } from 'src/app/core/booking.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { UserService } from 'src/app/core/user.service';
import { Globals } from 'src/app/core/globals.service';
import { EditStayComponent } from '../stayview/edit/edit-stay/edit-stay.component';

@Component({
  selector: 'app-booking-details',
  templateUrl: './booking-details.component.html',
  styleUrls: ['./booking-details.component.scss']
})
export class BookingDetailsComponent implements OnInit {
  bookingDetails: any;
  isLoaded = false;
  currentUser: string;
  hotelId: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private bookingService: BookingService,
              public datePipe: DatePipe, private userService: UserService, private dialogRef: MatDialogRef<BookingDetailsComponent>,  public dialog: MatDialog, public globals: Globals) { }

  ngOnInit(): void {
    this.globals.currentUser.subscribe(user => {this.currentUser = user});
    this.hotelId = this.data.hotelId;
    this.bookingService.getBookingDetails(this.data.bookingId, this.data.hotelId).subscribe(
      data => {
        this.bookingDetails = data;
        this.isLoaded = true;
      }
    );
  }

  openId(url) {
    window.open(url, '_blank');
  }

  getInvoice(roomAllocationId) {
    this.userService.getInvoice(this.data.hotelId, roomAllocationId).subscribe(
      data => {
        data.invoices.forEach(ele => {
          if ('error' in ele){
            return;
          }
          window.open(ele.url, '_blank');
        });
    }, err => {
    });
  }


  openEditStay(allocId, status, bookingId, source){
    const hotelId = this.data.hotelId;
    var invoiceId;
    this.dialog.open(EditStayComponent, {data : {allocId, hotelId, status, invoiceId, bookingId, source, queryParams:{ user: this.currentUser} }});
  }
  closeDialog() {
    this.dialogRef.close();
  }

}
