import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

interface CardDetails {
  cardNo: string;
  cvv: string;
  expiryMonth: number;
  expiryYear: number;
}

interface Address {
  firstName: string;
  lastName: string;
  line1: string;
  city: string;
  state: string;
  country: string;
  zip: string;
}

interface ChargeCardBody {
  address: Address;
  cardDetail: CardDetails;
  paymentDetail: {
    gateway: string;
    amount: number
  };
}

interface CaptureBody {
  gateway: string;
  transactionId: string;
  amount: number;
}

interface AuthorizeEmv {
  gateway: string;
  emvId: string;
  amount: number;
}

interface ChargeEmv {
  gateway: string;
  deviceId: string;
  unit: number;
  amount: number;
}

@Injectable({
  providedIn: 'root'
})
export class PaymentsService {

  constructor(private http: HttpClient) { }

  chargeCard(hotelId: string, body: ChargeCardBody): Observable <any> {
    return this.http.post(`${environment.apiUrl}/charge-card/${hotelId}`, body);
  }

  authorizeCard(hotelId: string, body: ChargeCardBody): Observable <any> {
    return this.http.post(`${environment.apiUrl}/authorize-card/${hotelId}`, body);
  }

  capturePayment(hotelId: string, body: CaptureBody): Observable <any> {
    return this.http.post(`${environment.apiUrl}/capture-payment/${hotelId}`, body);
  }

  updateCapture(hotelId: string, body): Observable <any> {
    return this.http.put(`${environment.apiUrl}/cc-payments/${hotelId}`, body);
  }

  authorizeEmv(hotelId: string, body: AuthorizeEmv): Observable <any> {
    return this.http.post(`${environment.apiUrl}/authorize-emv/${hotelId}`, body);
  }

  chargeEmv(hotelId: string, body: ChargeEmv): Observable <any> {
    return this.http.post(`${environment.apiUrl}/charge-emv/${hotelId}`, body);
  }

  initiateOnEDC(body, hotelId): Observable<any>{
    return this.http.post(`${environment.apiUrl}/initiate-payment/${hotelId}`, body);
  }

  getStatusOfTransaction(body, hotelId): Observable<any>{
    const httpParams = new HttpParams({
      fromObject: body,
    });
    return this.http.get(`${environment.apiUrl}/get-payment-status/${hotelId}`, {params: httpParams});
  }

  cancelOnEDC(hotelId: string, body): Observable <any> {
    return this.http.put(`${environment.apiUrl}/cancel-transaction/${hotelId}`, body);
  }

  getBalanceNonCheckedinAlloc(hotelId, allocId): Observable <any> {
    return this.http.get(`${environment.apiUrl}/pms/getbalance/${hotelId}/${allocId}`)
  }

  createPaytmOrder(hotelId, terminalId, amount, currency, allocationInfo, posName): Observable <any> {
    
    const payload = {
      property_id: hotelId,
      terminal_id: terminalId,
      invoice_id: allocationInfo?.invoiceId,
      folio_number: allocationInfo?.folioNum,
      first_name: allocationInfo?.guestName,
      last_name: "",
      email: allocationInfo?.guestContact?.email,
      phone: allocationInfo?.guestContact?.phone,
      amount,
      currency,
      extra_data: {
        bookingID: allocationInfo?.bookingId, 
        pos: posName,
        invoiceNumber: null,
        folioNumber: allocationInfo?.folioNum, 
        guestName: allocationInfo?.guestName,
        room: allocationInfo?.roomId, 
        rateplan: allocationInfo?.room?.rateplanId,
        pax: allocationInfo.pax
      }
    }
    for (const key in ['email', 'phone']) {
      if (!payload[key]) {
        delete payload[key]
      }
    }
    return this.http.post(`${environment.apiUrl}/order/paytm`, payload)
  }

  getPaytmOrderStatus(intentId): Observable <any> {
    return this.http.get(`${environment.apiUrl}/order/paytm/status/${intentId}`)
  }

  sendPaymentLink(body, hotelId): Observable<any> {
    return this.http.post(`${environment.apiUrl}/send-paymentlink/${hotelId}`, body);
  }

  getPaymentLinks(bookingId, hotelId): Observable<any> {
    return this.http.get(`${environment.apiUrl}/${bookingId}/${hotelId}/payment-from-links`);
  }

  getCardDetails(bookingId, hotelId): Observable<any> {
    return this.http.get(`${environment.apiUrl}/bookings/${hotelId}/card-details/${bookingId}`)
  }
}
