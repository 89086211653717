import { DatePipe, Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogComponent } from 'src/app/_shared/dialog/dialog.component';
import { PMSOpsService } from 'src/app/core/pms-ops.service';
import { ReservationService } from 'src/app/core/reservation.service';

@Component({
  selector: 'app-pms-ops',
  templateUrl: './pms-ops.component.html',
  styleUrls: ['./pms-ops.component.scss']
})
export class PmsOpsComponent implements OnInit {
  hotelId: any;
  dailyItemFieldsInfo = {};
  hotelRoute: any;
  weeklyItemFieldsInfo = {};
  monthlyItemFieldsInfo = {};
  dynamicForm: FormGroup;
  date : string;
  typeConversion = {
    'Text Field': 'text',
    'Photo Upload' : 'file',
    'Video Upload' : 'file',
    'Number' : 'number'
  }
  dailyItemsInfo = {};
  weeklyItemsInfo = {};
  monthlyItemsInfo = {};
  fileselected: any;
  showBackbutton = false;
  state: any;
  data: any;
  currentUser: any = '';

  
  constructor( private pmsOpsService:PMSOpsService, private route: ActivatedRoute, 
    private guestService: ReservationService, private datePipe: DatePipe, public location: Location,
    public matdialog: MatDialog, private router: Router) {}

  ngOnInit(): void {
    this.hotelRoute = this.route.params.subscribe(params => {
      this.hotelId = params.hotelId;
    });
    this.route.queryParams.subscribe(queryParams => {
      this.currentUser = queryParams.user;
    });
    this.getCurrentDate();
    this.state = this.location.getState();
    this.showBackbutton = this.state['showbackButton'];
    this.data = this.state['data'];
  }


  getCurrentDate() {
    this.guestService.getCurrentDatetTime(this.hotelId).subscribe(
      (data) => {
        if(this.data){
          this.date = this.data.Date;
          this.fetchfieldData(this.data);
        }else{
          this.date = this.datePipe.transform(new Date(data.currentdatetime), 'yyyy-MM-dd');
          this.fetchfieldData();
        }
        
      },
      (err) => {}
    );
  }
  

  fetchfieldData(detail = null){
    this.pmsOpsService.fetchFieldsData(this.hotelId).subscribe(data=>{
      this.dailyItemFieldsInfo = data.dailyItemsFieldsInfo;
      this.weeklyItemFieldsInfo = data.weeklyItemsFieldsInfo;
      this.monthlyItemFieldsInfo = data.monthlyItemsFieldsInfo;
      if (detail != null){
        this.addAndUpdateElementsToForm(detail)
      }else{
        this.addElementsToForm();
      }
      
    })
  }

  addElementsToForm(){
    var table = document.createElement('table');
    var row = document.createElement('tr');
    var tablecol1 = document.createElement('td');
    var tablecol2 = document.createElement('td');
    tablecol1.appendChild(document.createTextNode('Date :  '));
    tablecol1.setAttribute('style', 'width: 50px;')
    var input = document.createElement('input');
    input.setAttribute('class', 'form-control form-control-sm');
    input.setAttribute('style', 'border:1px solid grey;');
    input.setAttribute("type", "date")
    input.setAttribute("id", "date")
    input.setAttribute("value", this.date)
    input.setAttribute("min", this.date)
    tablecol2.appendChild(input);
    row.appendChild(tablecol1);
    row.appendChild(tablecol2);
    table.appendChild(row);
    document.getElementById('row0').appendChild(table);
    table.appendChild(document.createElement('br'))
    for (var item in this.dailyItemFieldsInfo){
      var table = document.createElement('table');
      var row = document.createElement('tr');
      var tablecol1 = document.createElement('td');
      var tablecol2 = document.createElement('td');
      tablecol1.setAttribute('style', 'width: 200px;')
      tablecol1.appendChild(document.createElement("label").appendChild(document.createTextNode(this.dailyItemFieldsInfo[item]['fieldName'] + ' : ')));
      var input = document.createElement('input');
      if (this.typeConversion[this.dailyItemFieldsInfo[item]['fieldType']] == 'text' || this.typeConversion[this.dailyItemFieldsInfo[item]['fieldType']] == 'number'){
        input.setAttribute('class', 'form-control form-control-sm');
        // input.setAttribute('style', 'border:1px solid grey;');
      }else if (this.dailyItemFieldsInfo[item]['fieldType'] == 'Photo Upload'){
        input.setAttribute("accept", ".jpg, .png, .jpeg")
      }else if (this.dailyItemFieldsInfo[item]['fieldType'] == 'Video Upload'){
        input.setAttribute("accept", ".mp4")
      }
      input.setAttribute("type", this.typeConversion[this.dailyItemFieldsInfo[item]['fieldType']])
      input.setAttribute("id", this.dailyItemFieldsInfo[item]['fieldName'])
      tablecol2.appendChild(input)
      row.appendChild(tablecol1);
      row.appendChild(tablecol2);
      table.appendChild(row);
      document.getElementById('row1').appendChild(table);
      table.appendChild(document.createElement('br'))
    }
    for (var item in this.weeklyItemFieldsInfo){
      var table = document.createElement('table');
      var row = document.createElement('tr');
      var tablecol1 = document.createElement('td');
      var tablecol2 = document.createElement('td');
      tablecol1.setAttribute('style', 'width: 200px;')
      tablecol1.appendChild(document.createElement("label").appendChild(document.createTextNode(this.weeklyItemFieldsInfo[item]['fieldName'] + ' : ')));
      var input = document.createElement('input');
      if (this.typeConversion[this.weeklyItemFieldsInfo[item]['fieldType']] == 'text' || this.typeConversion[this.weeklyItemFieldsInfo[item]['fieldType']] == 'number'){
        input.setAttribute('class', 'form-control form-control-sm');
      }else if (this.weeklyItemFieldsInfo[item]['fieldType'] == 'Photo Upload'){
        input.setAttribute("accept", ".jpg, .png, .jpeg")
      }else if (this.weeklyItemFieldsInfo[item]['fieldType'] == 'Video Upload'){
        input.setAttribute("accept", "video/mp4")
        input.setAttribute("max-file-size", "1024")
      }
      input.setAttribute("id", this.weeklyItemFieldsInfo[item]['fieldName'])
      input.setAttribute("type", this.typeConversion[this.weeklyItemFieldsInfo[item]['fieldType']])
      tablecol2.appendChild(input)
      row.appendChild(tablecol1);
      row.appendChild(tablecol2);
      table.appendChild(row);
      table.appendChild(document.createElement('br'))
      document.getElementById('row2').appendChild(table);
    }
    for (var item in this.monthlyItemFieldsInfo){
      var table = document.createElement('table');
      var row = document.createElement('tr');
      var tablecol1 = document.createElement('td');
      var tablecol2 = document.createElement('td');
      tablecol1.setAttribute('style', 'width: 200px;')
      tablecol1.appendChild(document.createElement("label").appendChild(document.createTextNode(this.monthlyItemFieldsInfo[item]['fieldName'] + ' : ')));
      var input = document.createElement('input');
      if (this.typeConversion[this.monthlyItemFieldsInfo[item]['fieldType']] == 'text' || this.typeConversion[this.monthlyItemFieldsInfo[item]['fieldType']] == 'number'){
        input.setAttribute('class', 'form-control form-control-sm');
      }else if (this.monthlyItemFieldsInfo[item]['fieldType'] == 'Photo Upload'){
        input.setAttribute("accept", ".jpg, .png, .jpeg")
      }else if (this.monthlyItemFieldsInfo[item]['fieldType'] == 'Video Upload'){
        input.setAttribute("accept", "video/mp4")
        input.setAttribute("max-file-size", "1024")
      }
      input.setAttribute("id", this.monthlyItemFieldsInfo[item]['fieldName'])
      input.setAttribute("type", this.typeConversion[this.monthlyItemFieldsInfo[item]['fieldType']])
      tablecol2.appendChild(input)
      row.appendChild(tablecol1);
      row.appendChild(tablecol2);
      table.appendChild(row);
      document.getElementById('row3').appendChild(table);
    }
  }

  addAndUpdateElementsToForm(data){
    var table = document.createElement('table');
    var row = document.createElement('tr');
    var tablecol1 = document.createElement('td');
    var tablecol2 = document.createElement('td');
    tablecol1.appendChild(document.createTextNode('Date :  '));
    tablecol1.setAttribute('style', 'width: 50px;')
    var input = document.createElement('input');
    input.setAttribute('class', 'form-control form-control-sm');
    input.setAttribute('style', 'border:1px solid grey;');
    input.setAttribute("type", "date")
    input.setAttribute("id", "date")
    input.setAttribute("value", this.date)
    input.setAttribute("min", this.date)
    tablecol2.appendChild(input);
    row.appendChild(tablecol1);
    row.appendChild(tablecol2);
    table.appendChild(row);
    document.getElementById('row0').appendChild(table);
    table.appendChild(document.createElement('br'))
    for (var item in this.dailyItemFieldsInfo){
      var table = document.createElement('table');
      var row = document.createElement('tr');
      var tablecol1 = document.createElement('td');
      var tablecol2 = document.createElement('td');
      var tablecol3 = document.createElement('td');
      tablecol1.setAttribute('style', 'width: 200px;')
      tablecol1.appendChild(document.createElement("label").appendChild(document.createTextNode(this.dailyItemFieldsInfo[item]['fieldName'] + ' : ')));
      var input = document.createElement('input');
      var link = document.createElement('a');
      if (this.typeConversion[this.dailyItemFieldsInfo[item]['fieldType']] == 'text' || this.typeConversion[this.dailyItemFieldsInfo[item]['fieldType']] == 'number'){
        input.setAttribute('class', 'form-control form-control-sm');
        // input.setAttribute('style', 'border:1px solid grey;');
        if(data.Daily_Data){
          if(data.Electricity_Units && 'Electricity Units' == this.dailyItemFieldsInfo[item]['fieldName']){
            input.setAttribute("value", data.Electricity_Units)
          }
          for(var detail of data.Daily_Data){
            if (detail.fieldName == this.dailyItemFieldsInfo[item]['fieldName']){
              input.setAttribute('value', detail.fieldValue);
            }
          }
        }
        
      }else if (this.dailyItemFieldsInfo[item]['fieldType'] == 'Photo Upload'){
        input.setAttribute("accept", ".jpg, .png, .jpeg")
        if(data.Daily_Briefing_photo && 'Daily Briefing Photo' == this.dailyItemFieldsInfo[item]['fieldName']){
          link.setAttribute('href', data['Daily_Briefing_photo']);
          link.setAttribute('target', "_blank");
          // Set text content
          link.textContent = 'Link';
        }
        if(data.Daily_Data){
          for(var detail of data.Daily_Data){
            if (detail.fieldName == this.dailyItemFieldsInfo[item]['fieldName']){
              // Set href attribute
              link.setAttribute('href', detail.fieldValue);
              link.setAttribute('target', "_blank");
              // Set text content
              link.textContent = 'Link';
            }
          }
        }
        
      }else if (this.dailyItemFieldsInfo[item]['fieldType'] == 'Video Upload'){
        input.setAttribute("accept", ".mp4")
        if(data.Daily_Data){
          for(var detail of data.Daily_Data){
            if (detail.fieldName == this.dailyItemFieldsInfo[item]['fieldName']){
              // Set href attribute
              link.setAttribute('href', detail.fieldValue);
              link.setAttribute('target', "_blank");
              // Set text content
              link.textContent = 'Link';
            }
          }
        }
        
      }
      input.setAttribute("type", this.typeConversion[this.dailyItemFieldsInfo[item]['fieldType']])
      input.setAttribute("id", this.dailyItemFieldsInfo[item]['fieldName'])
      tablecol2.appendChild(input);
      tablecol3.appendChild(link);
      row.appendChild(tablecol1);
      row.appendChild(tablecol2);
      row.appendChild(tablecol3);
      table.appendChild(row);
      document.getElementById('row1').appendChild(table);
      table.appendChild(document.createElement('br'))
    }
    for (var item in this.weeklyItemFieldsInfo){
      var table = document.createElement('table');
      var row = document.createElement('tr');
      var tablecol1 = document.createElement('td');
      var tablecol2 = document.createElement('td');
      var tablecol3 = document.createElement('td');
      tablecol1.setAttribute('style', 'width: 200px;')
      tablecol1.appendChild(document.createElement("label").appendChild(document.createTextNode(this.weeklyItemFieldsInfo[item]['fieldName'] + ' : ')));
      var input = document.createElement('input');
      var link = document.createElement('a');
      if (this.typeConversion[this.weeklyItemFieldsInfo[item]['fieldType']] == 'text' || this.typeConversion[this.weeklyItemFieldsInfo[item]['fieldType']] == 'number'){
        input.setAttribute('class', 'form-control form-control-sm');
        if (data.Weekly_Data){
          for(var detail of data.Weekly_Data){
            if (detail.fieldName == this.weeklyItemFieldsInfo[item]['fieldName']){
              input.setAttribute('value', detail.fieldValue);
            }
          }
        }
        
      }else if (this.weeklyItemFieldsInfo[item]['fieldType'] == 'Photo Upload'){
        input.setAttribute("accept", "image/*");
        if (data.Weekly_Data){
          for(var detail of data.Weekly_Data){
            if (detail.fieldName == this.weeklyItemFieldsInfo[item]['fieldName']){
              // Set href attribute
              link.setAttribute('href', detail.fieldValue);
              link.setAttribute('target', "_blank");

              // Set text content
              link.textContent = 'Link';
            }
          }
        }
      }else if (this.weeklyItemFieldsInfo[item]['fieldType'] == 'Video Upload'){
        input.setAttribute("accept", "video/mp4,video/x-m4v,video/*")
        input.setAttribute("max-file-size", "1024");
        if (data.Weekly_Data){
          for(var detail of data.Weekly_Data){
            if (detail.fieldName == this.weeklyItemFieldsInfo[item]['fieldName']){
              // Set href attribute
              link.setAttribute('href', detail.fieldValue);
              link.setAttribute('target', "_blank");

              // Set text content
              link.textContent = 'Link';
            }
          }
        }
      }
      input.setAttribute("id", this.weeklyItemFieldsInfo[item]['fieldName'])
      input.setAttribute("type", this.typeConversion[this.weeklyItemFieldsInfo[item]['fieldType']])
      tablecol2.appendChild(input)
      tablecol3.appendChild(link);
      row.appendChild(tablecol1);
      row.appendChild(tablecol2);
      row.appendChild(tablecol3);
      table.appendChild(row);
      table.appendChild(document.createElement('br'))
      document.getElementById('row2').appendChild(table);
    }
    for (var item in this.monthlyItemFieldsInfo){
      var table = document.createElement('table');
      var row = document.createElement('tr');
      var tablecol1 = document.createElement('td');
      var tablecol2 = document.createElement('td');
      var tablecol3 = document.createElement('td');
      tablecol1.setAttribute('style', 'width: 200px;')
      tablecol1.appendChild(document.createElement("label").appendChild(document.createTextNode(this.monthlyItemFieldsInfo[item]['fieldName'] + ' : ')));
      var input = document.createElement('input');
      var link = document.createElement('a');
      if (this.typeConversion[this.monthlyItemFieldsInfo[item]['fieldType']] == 'text' || this.typeConversion[this.monthlyItemFieldsInfo[item]['fieldType']] == 'number'){
        input.setAttribute('class', 'form-control form-control-sm');
        if (data.Monthly_Data){
          for(var detail of data.Monthly_Data){
            if (detail.fieldName == this.monthlyItemFieldsInfo[item]['fieldName']){
              input.setAttribute('value', detail.fieldValue);
            }
          }
        }
      }else if (this.monthlyItemFieldsInfo[item]['fieldType'] == 'Photo Upload'){
        input.setAttribute("accept", "image/*");
        if (data.Monthly_Data){
          for(var detail of data.Monthly_Data){
            if (detail.fieldName == this.monthlyItemFieldsInfo[item]['fieldName']){
              // Set href attribute
              link.setAttribute('href', detail.fieldValue);
              link.setAttribute('target', "_blank");

              // Set text content
              link.textContent = 'Link';
            }
          }
        }
      }else if (this.monthlyItemFieldsInfo[item]['fieldType'] == 'Video Upload'){
        input.setAttribute("accept", "video/mp4,video/x-m4v,video/*");
        input.setAttribute("max-file-size", "1024");
        if (data.Monthly_Data){
          for(var detail of data.Monthly_Data){
            if (detail.fieldName == this.monthlyItemFieldsInfo[item]['fieldName']){
              // Set href attribute
              link.setAttribute('href', detail.fieldValue);
              link.setAttribute('target', "_blank");

              // Set text content
              link.textContent = 'Link';
            }
          }
        }
      }
      input.setAttribute("id", this.monthlyItemFieldsInfo[item]['fieldName'])
      input.setAttribute("type", this.typeConversion[this.monthlyItemFieldsInfo[item]['fieldType']])
      tablecol2.appendChild(input)
      tablecol3.appendChild(link)
      row.appendChild(tablecol1);
      row.appendChild(tablecol2);
      row.appendChild(tablecol3);
      table.appendChild(row);
      document.getElementById('row3').appendChild(table);
    }
  }
  

  submit(){
    const dialogBody = {
      title: 'Add Entry',
      body: 'Are you sure you want to Submit ?',
      confirmBtn: 'Submit',
      cancelBtn: 'Cancel',
    };
    const dialogRef = this.matdialog.open(DialogComponent, {
      data: dialogBody,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'true') {
        var dontSubmit = false;
        var bytes = 1024; // Change to 1000 if required
        var megaBytes = bytes * bytes;
        var dateinfo = {
          date : (<HTMLInputElement>(
            document.getElementById('date')
          )).value,
           }
        var dailytextdata = {}; var dailyfiledata = {};
        var weeklytextdata = {}; var weeklyfiledata = {}; var monthlytextdata = {}; var monthlyfiledata = {}
        for (var item in this.dailyItemFieldsInfo){
          if (this.typeConversion[this.dailyItemFieldsInfo[item]['fieldType']] == 'text' || this.typeConversion[this.dailyItemFieldsInfo[item]['fieldType']] == 'number'){
            dailytextdata[this.dailyItemFieldsInfo[item]['fieldName']] = (<HTMLInputElement>(
              document.getElementById(this.dailyItemFieldsInfo[item]['fieldName'])
            )).value
          }else{
            if ((<HTMLInputElement>(
              document.getElementById(this.dailyItemFieldsInfo[item]['fieldName'])
            )).files && ((<HTMLInputElement>(
              document.getElementById(this.dailyItemFieldsInfo[item]['fieldName'])
            )).files).length>0){
              dailyfiledata[this.dailyItemFieldsInfo[item]['fieldName']] = (<HTMLInputElement>(
                document.getElementById(this.dailyItemFieldsInfo[item]['fieldName'])
              )).files[0];
              var filesize = (<HTMLInputElement>(
                document.getElementById(this.dailyItemFieldsInfo[item]['fieldName'])
              )).files[0].size;
  
              if (filesize/megaBytes > 100){
                dontSubmit = true;
              }
            }
            
          }
        }
        this.dailyItemsInfo['textdata'] = dailytextdata;
        this.dailyItemsInfo['filedata'] = dailyfiledata;
        for (var item in this.weeklyItemFieldsInfo){
          if (this.typeConversion[this.weeklyItemFieldsInfo[item]['fieldType']] == 'text' || this.typeConversion[this.weeklyItemFieldsInfo[item]['fieldType']] == 'number'){
            weeklytextdata[this.weeklyItemFieldsInfo[item]['fieldName']] = (<HTMLInputElement>(
              document.getElementById(this.weeklyItemFieldsInfo[item]['fieldName'])
            )).value
          }else{
            weeklyfiledata[this.weeklyItemFieldsInfo[item]['fieldName']] = (<HTMLInputElement>(
              document.getElementById(this.weeklyItemFieldsInfo[item]['fieldName'])
            )).files[0];
            if ((<HTMLInputElement>(
              document.getElementById(this.weeklyItemFieldsInfo[item]['fieldName'])
            )).files[0]){
              var filesize = (<HTMLInputElement>(
                document.getElementById(this.weeklyItemFieldsInfo[item]['fieldName'])
              )).files[0].size;
  
              if (filesize/megaBytes > 100){
                dontSubmit = true;
              }
            }
            
          }
        }
        this.weeklyItemsInfo['textdata'] = weeklytextdata;
        this.weeklyItemsInfo['filedata'] = weeklyfiledata;
        for (var item in this.monthlyItemFieldsInfo){
          if (this.typeConversion[this.monthlyItemFieldsInfo[item]['fieldType']] == 'text' || this.typeConversion[this.monthlyItemFieldsInfo[item]['fieldType']] == 'number'){
            monthlytextdata[this.monthlyItemFieldsInfo[item]['fieldName']] = (<HTMLInputElement>(
              document.getElementById(this.monthlyItemFieldsInfo[item]['fieldName'])
            )).value
          }else{
            monthlyfiledata[this.monthlyItemFieldsInfo[item]['fieldName']] = (<HTMLInputElement>(
              document.getElementById(this.monthlyItemFieldsInfo[item]['fieldName'])
            )).files[0];
            if ((<HTMLInputElement>(
              document.getElementById(this.monthlyItemFieldsInfo[item]['fieldName'])
            )).files[0]){
              var filesize = (<HTMLInputElement>(
                document.getElementById(this.monthlyItemFieldsInfo[item]['fieldName'])
              )).files[0].size;
  
              if (filesize/megaBytes > 100){
                dontSubmit = true;
              }
            }
          }
        }
        this.monthlyItemsInfo['textdata'] = monthlytextdata;
        this.monthlyItemsInfo['filedata'] = monthlyfiledata;
        if (dontSubmit == false){
          this.pmsOpsService.addPMSOpsInfo(this.hotelId, dateinfo, this.dailyItemsInfo, this.weeklyItemsInfo, this.monthlyItemsInfo).subscribe(
            success=>{
              var WarningDialog = {}
              if (success['error']) {
                WarningDialog = {
                  title: 'ERROR !',
                  body: 'Something went wrong!! Your data might be submitted, kindly check in reservations list',
                  cancelBtn: 'Dismiss',
                };
              }else{
                WarningDialog = {
                  title: 'Done !',
                  body: `You Have successfully submitted the data`,
                  cancelBtn: 'Dismiss',
                };
                if(this.showBackbutton == true){
                  this.location.back();
                }
              }
              this.matdialog.open(DialogComponent, {
                data: WarningDialog,
              });
            }, err=>{
                    WarningDialog = {
                      title: 'Error !',
                      body: `Sorry the data submission failed, if you are uploading multiple videos, kindly submit one at a time.`,
                      cancelBtn: 'Dismiss',
                    };
                    this.matdialog.open(DialogComponent, {
                      data: WarningDialog,
                    });
            }
          )
        }
        else{
          var WarningDialog = {
            title: 'Error !',
            body: `File size should be within 100MB`,
            cancelBtn: 'Dismiss',
          };
          this.matdialog.open(DialogComponent, {
            data: WarningDialog,
          });
        }
        
      }
    })
  }
  
  back(){
    this.router.navigate(['/pms-operations-data/'+this.hotelId], {queryParams: {user: this.currentUser }});
  }

}
