import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountsService } from 'src/app/core/accounts.service';

@Component({
  selector: 'app-doc-summary',
  templateUrl: './doc-summary.component.html',
  styleUrls: ['./doc-summary.component.scss']
})
export class DocSummaryComponent implements OnInit {
  hotelId = '';
  docSummary = {};
  tableData = []

  constructor(
    private accountsService: AccountsService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.hotelId = params.hotelId;
      try {
        this.docSummary = JSON.parse(params?.data);
        this.createTable();
      }
      catch { }
    });
  }

  createTable() {
    const arr = [];
    for (const key in this.docSummary) {
     arr.push(this.docSummary[key]); 
    }
    this.tableData = arr;
  }

  back() {
    this.accountsService.currentTab = 'gstr1';
    this.router.navigate([`accounts/${this.hotelId}`], {queryParams: {tabName: 'gstr1', fromBack: true}});
  }

}
