<div class="primary_modal_wrapper">
    <div class="modal_heading">
        <h1>{{title}}</h1>

        <svg [mat-dialog-close]="false" role="button"
            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
          <path
            d="M315.3 411.3c-6.253 6.253-16.37 6.253-22.63 0L160 278.6l-132.7 132.7c-6.253 6.253-16.37 6.253-22.63 0c-6.253-6.253-6.253-16.37 0-22.63L137.4 256L4.69 123.3c-6.253-6.253-6.253-16.37 0-22.63c6.253-6.253 16.37-6.253 22.63 0L160 233.4l132.7-132.7c6.253-6.253 16.37-6.253 22.63 0c6.253 6.253 6.253 16.37 0 22.63L182.6 256l132.7 132.7C321.6 394.9 321.6 405.1 315.3 411.3z" />
        </svg>
    </div>

    <mat-divider></mat-divider>

    <div class="modal_body">
        <form [formGroup]="receiveForm">
            <div>
                <label> {{ "accounts.type_of_payment" | translate }} <span class="text-danger">*</span></label>
                <select class="form-control form-control-sm" formControlName="typeOfPayment">
                    <option *ngFor="let mode of paymentModes" 
                        [value]="mode">{{mode}}
                    </option>
                </select>
            </div>

            <div>
                <label> {{ "accounts.amount_to_be_paid" | translate }} <span class="text-danger">*</span></label>
                <input 
                    type="text"
                    class="form-control form-control-sm"
                    maxlength="12"
                    formControlName="amount"
                    (keypress)="globals.numberOnly($event)">
            </div>

            <div>
                <label> {{ "accounts.comments" | translate }} <span class="text-danger">*</span></label>
                <textarea class="form-control form-control-sm" maxlength="64" formControlName="comments"></textarea>
            </div>

            <div>
                <label> {{ "accounts.image" | translate }} </label>
                <input 
                    type="file"
                    accept="image/*"
                    class="form-control form-control"
                    (change)="fileChange($event.target)">
            </div>
        </form>
        
    </div>

    <div class="modal_footer">
        <div>
            <button *ngIf="data?.company?.id"
                [matBadge]="selectedPayments?.length"
                mat-raised-button
                class="primary_btn"
                (click)="openMarkCityLedger()">
                {{ "accounts.mark_city_ledger" | translate }}
            </button>
        </div>            

        <div>
            <button mat-raised-button mat-dialog-close>{{ "accounts.cancel" | translate }}</button>
            <button mat-raised-button
                [ngClass]="{'disable_button': receiveForm.invalid}"
                class="primary_btn"
                (click)="submitClick()">
                {{ "accounts.submit" | translate }}
            </button>
        </div>
    </div>

    <div class="loader_wrapper" *ngIf="loading">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
         style="position: relative;top: 50%;left: 50%;transform: translate(-50%, -50%);" width="100px" height="100px" viewBox="0 0 100 50" preserveAspectRatio="xMidYMid">
            <rect x="14.5" y="10" width="8" height="20" fill="#b2b2b2">
              <animate attributeName="opacity" dur="0.8620689655172413s" repeatCount="indefinite" calcMode="spline" keyTimes="0;0.5;1" keySplines="0.5 0 0.5 1;0.5 0 0.5 1" values="1;0.2;1" begin="-0.5172413793103448"></animate>
            </rect><rect x="34.5" y="10" width="8" height="20" fill="#b2b2b2">
              <animate attributeName="opacity" dur="0.8620689655172413s" repeatCount="indefinite" calcMode="spline" keyTimes="0;0.5;1" keySplines="0.5 0 0.5 1;0.5 0 0.5 1" values="1;0.2;1" begin="-0.3448275862068965"></animate>
            </rect><rect x="54.5" y="10" width="8" height="20" fill="#b2b2b2">
              <animate attributeName="opacity" dur="0.8620689655172413s" repeatCount="indefinite" calcMode="spline" keyTimes="0;0.5;1" keySplines="0.5 0 0.5 1;0.5 0 0.5 1" values="1;0.2;1" begin="-0.17241379310344826"></animate>
            </rect><rect x="74.5" y="10" width="8" height="20" fill="#b2b2b2">
              <animate attributeName="opacity" dur="0.8620689655172413s" repeatCount="indefinite" calcMode="spline" keyTimes="0;0.5;1" keySplines="0.5 0 0.5 1;0.5 0 0.5 1" values="1;0.2;1" begin="-0.8620689655172413"></animate>
            </rect>
        </svg>
    </div>
</div>
