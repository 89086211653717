<div *ngIf="enabled; else elseBlock">
  <div class="heading flex-space-bw">
    <h2>{{ "maintenance.title" | translate }}</h2>
  </div>
  <mat-divider></mat-divider>

  <div class="container">
    <div class="tabs">
      <ul>
        <ng-container *ngFor="let tab of tabItems; let i = index">
          <li
            (click)="changeCurrentTab(i)"
            [ngClass]="{ 'active-tab': currentTab === tab.key }">
            {{ tab.name }}
          </li>
        </ng-container>
      </ul>
    </div>

    <ng-container>
      <div class="content">
        <ng-container *ngIf="currentTab === 'maintenance'">
          <app-maintenance-index [hotelId]="hotelId" [showPage]="maintenance"></app-maintenance-index>
        </ng-container>

        <ng-container *ngIf="currentTab === 'housekeeping'">
          <app-housekeeping [hotelId]="hotelId" [user]="currentUser" [showPage]="housekeeping"></app-housekeeping>
        </ng-container>
        
        <ng-container *ngIf="currentTab === 'laundry'">
          <app-laundry [hotelId]="hotelId" [showPage]="laundry"></app-laundry>
        </ng-container>

        <ng-container *ngIf="currentTab === 'lost&found'">
          <app-lost-and-found [hotelId]="hotelId" [showPage]="lostAndFound"></app-lost-and-found>
        </ng-container>

        <!-- <ng-container *ngIf="currentTab === 'tasks'">
          <app-tasks [hotelId]="hotelId"></app-tasks>
        </ng-container> -->
      </div>
    </ng-container>
  </div>
</div>
<ng-template #elseBlock>
  <h1>This feature Is Not Enabled For This Property!</h1>
</ng-template>
