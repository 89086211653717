import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MatTabsModule } from '@angular/material/tabs';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule, MatTabsModule, TranslateModule
  ]
})
export class EditModule { }
