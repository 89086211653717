<div class="pos-container">
    <div class="heading">
        <h1>
            {{data.reportName}} - 
            <ng-container *ngIf="data.startDate === data.endDate; else elseBlock">
                <span [ngStyle]="{'font-size': '1.2rem'}">
                    {{data.startDate | date: 'dd MMM'}}
                </span>
            </ng-container>
            <ng-template #elseBlock>
                <span [ngStyle]="{'font-size': '1.2rem'}">
                    {{data.startDate | date: 'dd MMM'}} to {{data.endDate | date: 'dd MMM'}}
                </span>
            </ng-template>
        </h1>
        <button mat-stroked-button class="active small-btn"
        (click)="closeDialog()">
            {{'pos.close' | translate}}
        </button>
    </div>
    <mat-divider></mat-divider>

    <!-- Item consumption -->
    <div *ngIf="data.reportName === 'Item Consumption'"
        class="table-container">
        <table class="table table-bordered table-striped">
            <thead class="thead-dark">
                <tr>
                    <th>{{'pos.service_name' | translate}}</th>
                    <th class="text-center">{{'pos.qty' | translate}}</th>
                    <th class="text-right">{{'pos.amount' | translate}}</th>
                    <th class="text-right">{{'pos.tax' | translate}}</th>
                    <th class="text-right">{{'pos.total' | translate}}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let service of data.reportData">
                    <td>{{service.service_name}}</td>
                    <td class="text-center">{{service.quantity}}</td>
                    <td class="text-right">{{service.service_amount | number: '1.2-2'}}</td>
                    <td class="text-right">{{service.tax | number: '1.2-2'}}</td>
                    <td class="text-right">{{service.total_amount | number: '1.2-2'}}</td>
                </tr>
                <tr [ngStyle]="{'font-size': '0.85rem'}">
                    <td colspan="2" class="text-right">{{'pos.total' | translate}}</td>
                    <td class="text-right">{{serviceTotal.amount | number: '1.2-2'}}</td>
                    <td class="text-right">{{serviceTotal.tax | number: '1.2-2'}}</td>
                    <td class="text-right">{{serviceTotal.total | number: '1.2-2'}}</td>
                </tr>
            </tbody>
        </table>
    </div>
    
    <!-- Payments -->
    <div *ngIf="data.reportName === 'Payments'"
        class="table-container">
        <table class="table table-bordered table-striped">
            <thead class="thead-dark">
                <tr>
                    <th>{{'pos.type_of_payment' | translate}}</th>
                    <th class="text-right">
                        {{'pos.amount' | translate}}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let payment of data.reportData">
                    <td>{{payment.type_of_payment}}</td>
                    <td class="text-right">{{payment.amount | number: '1.2-2'}}</td>
                </tr>
                <tr [ngStyle]="{'font-size': '0.85rem'}">
                    <td class="text-right">{{'pos.total' | translate}}</td>
                    <td class="text-right">{{paymentsTotal.amount | number: '1.2-2'}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>