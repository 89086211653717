import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { StoreService } from 'src/app/core/store.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Globals } from 'src/app/core/globals.service';
import { DialogComponent } from '../../../_shared/dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit, OnDestroy {
  @Input() hotelId: string;
  componentDestroyed$ = new Subject();

  settings = ['Master Items', 'Item Category', 'Department', 'Vendor'];
  currentSetting: string;

  overlay = false;
  isVisible = {addMasterItemForm: false, addDepartmentForm: false, addVendorForm: false};
  formAdd: boolean;
  showConfigureUsers = false;
  userDepts: any;
  newUserDept = {id: null, name: null};

  /* Category */
  categoryName: string;
  subCategoryName: string;
  categories: Array<any>;
  $categories: Array<any>;
  filteredSubCategory: Array<any>;
  editCategory = {id: '', name: ''};

  /* Master item */
  masterItems: Array<any>;
  $masterItems: Array<any>;
  addMasterItemForm: FormGroup;
  taxGroups: Array<string>;
  units = ['Bag', 'Bottle', 'Box', 'Bulk', 'Bunch', 'Can', 'Crate', 'Feet', 'gms' , 'kgs', 'Ltr', 'ml', 'mts', 'Nos', 'Pkts', 'Roll', 'Set', 'Tin', 'Tray'];
  fCategory = '';
  fsubCategory = '';
  fsubCategories: any[];
  showMultiSelect = false;
  totalValue = 0;

  /* Department */
  departments: Array<any>;
  $departments: Array<any>;
  addDepartmentForm: FormGroup;

  /* Vendor */
  vendors: Array<any>;
  $vendors: Array<any>;
  addVendorForm: FormGroup;

  constructor(public storeService: StoreService,
              public fb: FormBuilder,
              public globals: Globals,
              public dialog: MatDialog) { }

  ngOnInit(): void {
    this.changeSetting(this.settings[0]);
    this.getUserDepts();
    this.getCategories();
  }

  changeSetting(setting) {
    if (this.currentSetting === setting) {
      return;
    }
    this.currentSetting = setting;
    if (setting === 'Item Category') {
      if (!this.categories) { this.getCategories(); }
    } else if (setting === 'Master Items') {
      if (!this.masterItems) { this.getItems(); }
    } else if (setting === 'Department') {
      if (!this.departments) { this.getDepartments(); }
    } else if (setting === 'Vendor') {
      if (!this.vendors) { this.getVendors(); }
    }
  }

  /* Category */
  addCategory(name: string) {
    if (name) {
      const body = {name: this.removeWhiteSpace(name)};
      this.storeService.addCategory(body, this.hotelId)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(data => {
        if (data?.error) {
          this.globals.snackBarFunc(`${data.error}`);
        } else {
          this.globals.snackBarFunc(`${name} added successfully !`);
          this.getCategories();
          this.categoryName = undefined;
        }
      });
    }
  }
  addSubCategory(name: string, categoryId: string, subCategories) {
    if (name) {
      name = this.removeWhiteSpace(name);
      for (const sub of subCategories) {
        if (sub.name === name) {return; }
      }
      const body = {name, category_id: categoryId};
      this.storeService.addSubCategory(body, this.hotelId)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(data => {
        this.pushSubCategory(this.$categories, categoryId, name);
        this.subCategoryName = undefined;
        this.globals.snackBarFunc(`${name} added successfully !`);
      });
    }
  }

  pushSubCategory(array, categoryId, name) {
    for (const category of array) {
      if (category.id === categoryId) {
        category.sub_categories.push({id: '', name});
        break;
      }
    }
  }

  getCategories() {
    this.storeService.getCategories(this.hotelId)
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe(data => {
      if (data?.categories) {
        this.categories = data.categories;
        this.$categories = data.categories;
      }
    });
  }

  filterSubCategory(category: string) {
    if (this.categories && category) {
      for (const item of this.categories) {
        if (item?.name === category) {
          this.filteredSubCategory =  item.sub_categories;
          return;
        }
      }
    }
  }

  filter$categories(key) {
    key = key.toLowerCase();
    this.$categories = this.categories.filter(data => {
      return data.name?.toLowerCase().indexOf(key) > -1;
    });
  }

  editCategoryFun(id: string, name: string) {
    this.editCategory = {id, name};
  }

  saveEditCategory(prevName: string) {
    const reqBody = {id: this.editCategory.id, edited_name: this.editCategory.name, name: prevName};
    const dialogBody = {title: 'RENAME CATEGORY', body: `Are you sure you want to rename ${prevName} to ${this.editCategory.name} ?`,
    confirmBtn: 'Save', cancelBtn: 'Cancel'};
    const dialogRef = this.dialog.open(DialogComponent, {data: dialogBody});
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'true') {
          this.storeService.editCategory(reqBody, this.hotelId)
          .pipe(takeUntil(this.componentDestroyed$))
          .subscribe(data => {
            this.getCategories();
            this.globals.snackBarFunc(`Category edited successfully !`);
            this.cancelEdit();
          });
      }
    });
  }

  cancelEdit() {
    this.editCategory = {id: '', name: ''};
  }

  deleteCategory(name: string, categoryId: string) {
    const dialogBody = {title: 'DELETE CATEGORY', body: `Are you sure you want to delete ${name}?
    Deleting category will also delete all its sub category`,
    confirmBtn: 'Delete', cancelBtn: 'Cancel', confirmBtnColor: '#b90f0f', height: '170px'};
    const dialogRef = this.dialog.open(DialogComponent, {data: dialogBody});
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'true') {
        // const confirmDelete = confirm(`WARNING : DELETING CATEGORY WILL ALSO DELETE ALSO IT'S SUB CATEGORIES. Click OK to proceed`);
        this.storeService.deleteCategory(categoryId, this.hotelId)
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(data => {
          this.getCategories();
          this.globals.snackBarFunc(`${name} deleted successfully !`);
        });
        return;
      }
    });
  }

  deleteSubCategory(name: string, categoryId: string) {
    const dialogBody = {title: 'DELETE SUB CATEGORY', body: `Are you sure you want to delete ${name}?`,
    confirmBtn: 'Delete', cancelBtn: 'Cancel', confirmBtnColor: '#b90f0f'};
    const dialogRef = this.dialog.open(DialogComponent, {data: dialogBody});
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'true'){
        this.storeService.deleteSubCategory(name, categoryId, this.hotelId)
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(data => {
          for (const category of this.$categories) {
            if (category.id === categoryId) {
              category.sub_categories = category.sub_categories.filter(ele => {
                return ele.name !== name;
              });
            }
          }
        });
      }
    });
  }


  /* Master Items */
  initMasterItemForm(body) {
    if (body.category?.length > 0) {
      this.filterSubCategory(body.category);
    }
    this.addMasterItemForm = this.fb.group({
      id: [body.id],
      name: [body.name, [Validators.required]],
      category: [body.category, [Validators.required]],
      sub_category: [body.sub_category, [Validators.required]],
      vendor_ids: [body.vendor_ids],
      unit: [body.unit, [Validators.required]],
      rate: [body.rate, [Validators.max(999999999)]],
      tax_group: [body.tax_group, [Validators.required]],
      tax_inclusive: [body.tax_inclusive, [Validators.required]],
      is_inventoried: [body.is_inventoried, [Validators.required]],
      is_capital_item: [body.is_capital_item, [Validators.required]],
      stock: [body.stock, [Validators.min(0), Validators.max(999999999)]]
    });
  }

  addItem() {
    this.formAdd = true;
    this.openForm('addMasterItemForm');
    const body = {name: '', category: '', sub_category: '', vendor_ids: null, unit: '', rate: null, tax_group: '', tax_inclusive: '1',
                  is_inventoried: '1', is_capital_item: '1', stock: 0};
    this.initMasterItemForm(body);
    if (!this.categories) {
      this.getCategories();
    }
    if (!this.vendors) {
      this.getVendors();
    } else {
      this.$vendors.forEach(ele => {
        ele.selected = false;
      });
    }
    if (!this.taxGroups) {
      this.getTaxGroups();
    }
  }

  editItem(body) {
    this.formAdd = false;
    this.openForm('addMasterItemForm');
    this.initMasterItemForm(body);
    if (!this.categories) {
      this.getCategories();
    }
    if (!this.vendors) {
      this.getVendors();
    } else {
      this.awaitVendors(body);
    }
    if (!this.taxGroups) {
      this.getTaxGroups();
    }

  }

  awaitVendors(body) {
    if (this.vendors) { this.checkSortVendors(body); }
    else { setTimeout(this.awaitVendors, 250); }
  }

  checkSortVendors(body) {
    this.$vendors = this.vendors.map(a => ({...a}));
    this.$vendors.forEach(ele => {
      ele.selected = body.vendor_ids.includes(ele.id) ? true : false;
    });
    this.$vendors.sort((x, y) => y.selected - x.selected);
  }

  getTaxGroups() {
    this.storeService.getTaxGroups(this.hotelId)
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe(data => {
      this.taxGroups = data?.tax_groups;
    });
  }

  submitMasterForm(form) {
    form.controls.name.setValue(this.removeWhiteSpace(form.controls.name.value));
    form.controls.tax_inclusive.setValue(Number(form.controls.tax_inclusive.value));
    form.controls.is_inventoried.setValue(Number(form.controls.is_inventoried.value));
    form.controls.is_capital_item.setValue(Number(form.controls.is_capital_item.value));
    const vendorIds = this.vendorsSelected();
    form.controls.vendor_ids.setValue(vendorIds);

    if (form.value.id) {
      this.storeService.editMasterItem(form.value, this.hotelId)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(data => {
        this.getItems();
        this.globals.snackBarFunc(`Edited successfully !`);
      }).add(() => {
        this.closeForm('addMasterItemForm');
      });
    } else {
      this.storeService.addMasterItem(form.value, this.hotelId)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(data => {
        if (data?.error) {
          this.globals.snackBarFunc(`${data.error}`);
        } else {
          this.globals.snackBarFunc(`Item added successfully !`);
          this.getItems();
        }
      }).add(() => {
        this.closeForm('addMasterItemForm');
      });
    }
  }

  deleteItem(name, itemId: string) {
    const dialogBody = {title: 'DELETE MASTER ITEM', body: `Are you sure you want to delete ${name}?`,
    confirmBtn: 'Delete', cancelBtn: 'Cancel', confirmBtnColor: '#b90f0f'};
    const dialogRef = this.dialog.open(DialogComponent, {data: dialogBody});
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'true'){
        this.storeService.deleteItem(itemId, this.hotelId).subscribe(data => {
          this.globals.snackBarFunc(`${name} deleted successfully !`);
          this.getItems();
        });
      }
    });
  }

  getItems() {
    this.storeService.getMasterItems(this.hotelId)
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe(data => {
      if (data?.master_items) {
        this.masterItems = data.master_items;
        this.$masterItems = data.master_items;
        this.setMasterTotalValue(this.$masterItems);
      }
    });
  }

  filter$masterItems(key: string) {
    key = key.toLowerCase();
    this.$masterItems = this.masterItems.filter(data => {
      return data.name?.toLowerCase().indexOf(key) > -1 &&
      data.category?.toLowerCase().indexOf(this.fCategory.toLowerCase()) > -1 &&
      data.sub_category?.toLowerCase().indexOf(this.fsubCategory.toLowerCase()) > -1;
    });
    this.setMasterTotalValue(this.$masterItems);
  }

  filterMasterItemBy() {
    if (this.fCategory === '') {
      this.$masterItems = JSON.parse(JSON.stringify(this.masterItems));
      return;
    }
    this.$masterItems = this.masterItems.filter(data => {
      return data.category?.toLowerCase().indexOf(this.fCategory.toLowerCase()) > -1 &&
      data.sub_category?.toLowerCase().indexOf(this.fsubCategory.toLowerCase()) > -1;
    });

    this.setMasterTotalValue(this.$masterItems);
  }

  filterFsubCategories(category) {
    this.fsubCategory = '';
    if (category === '') {
      this.fsubCategories = [];
      return;
    }
    this.fsubCategories = this.$categories.filter(data => {
      return data.name === category;
    });
    if (this.fsubCategories.length > 0) {
      this.fsubCategories = this.fsubCategories[0].sub_categories;
    }
  }

  setMasterTotalValue(items: any[]) {
    this.totalValue = items?.reduce((a, b) => {
      return a + b?.value;
    }, 0);
  }

  showMultiSelectToggle(bool, event = null) {
    const nodeName: string = event?.composedPath()[0]?.nodeName;
    if (bool) {
      this.showMultiSelect = !this.showMultiSelect;
      return;
    }
    else if (this.showMultiSelect && nodeName !== 'INPUT' && nodeName !== 'LABEL') {
      this.showMultiSelect = false;
    }
  }

  vendorsSelected() {
    const vendorIds = [];
    this.$vendors.forEach(ele => {
      if (ele.selected) {
        vendorIds.push(ele.id);
      }
    });
    return vendorIds;
  }

  /* Department */

  initDepartmentForm(body) {
    this.addDepartmentForm = this.fb.group({
      id: [body.id],
      name: [body.name, [Validators.required]],
      contact_person: [body.contact_person],
      amount_issued: [body.amount_issued, [Validators.max(9999999999)]]
    });
  }

  addDepartment() {
    this.formAdd = true;
    const body = {name: null, contact_person: null, amount_issued: null};
    this.openForm('addDepartmentForm');
    this.initDepartmentForm(body);
  }

  editDepartment(body) {
    this.formAdd = false;
    this.openForm('addDepartmentForm');
    this.initDepartmentForm(body);
  }

  getDepartments() {
    this.storeService.getDepartments(this.hotelId)
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe(data => {
      if (data?.departments) {
        this.departments = data.departments;
        this.$departments = data.departments;
      }
    });
  }

  submitDepartmentForm(form) {
    form.controls.name.setValue(this.removeWhiteSpace(form.controls.name.value));
    if (form.value.id) {
      this.storeService.editDept(form.value, this.hotelId)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(data => {
        this.getDepartments();
        this.globals.snackBarFunc(`Department edited successfully !`);
      }).add(() => {
        this.closeForm('addDepartmentForm');
      });
    } else {
      this.storeService.addDeparment(form.value, this.hotelId)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(data => {
        this.getDepartments();
        this.globals.snackBarFunc(`Department added successfully !`);
      }).add(() => {
        this.closeForm('addDepartmentForm');
      });
    }
  }

  filter$departments(key) {
    key = key.toLowerCase();
    this.$departments = this.departments.filter(data => {
      return data.name?.toLowerCase().indexOf(key) > -1;
    });
  }

  deleteDepartment(deptId: string, name: string) {
    const dialogBody = {title: 'DELETE DEPARTMENT', body: `Are you sure you want to delete ${name}?`,
    confirmBtn: 'Delete', cancelBtn: 'Cancel', confirmBtnColor: '#b90f0f'};
    const dialogRef = this.dialog.open(DialogComponent, {data: dialogBody});
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'true'){
        this.storeService.deleteDept(deptId, this.hotelId)
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(data => {
          this.getDepartments();
          this.globals.snackBarFunc(`${name} deleted successfully !`);
        });
      }
    });
  }

  /* Vendor */

  initVendorForm(body) {
    this.addVendorForm = this.fb.group({
      id: [body.id],
      name: [body.name, [Validators.required]],
      gst_num: [body.gst_num],
      address: [body.address],
      contact_person: [body.contact_person],
      phone: [body.phone],
      email_id: [body.email_id],
      state: [body?.state],
      account_name: [body?.account_name],
      bank_name: [body?.bank_name],
      ifsc: [body?.ifsc],
      account_number: [body?.account_number]
    });
  }

  addVendor() {
    this.formAdd = true;
    const body = {name: null, gst_num: null, address: null, contact_person: null, phone: null, email: null, state: null};
    this.openForm('addVendorForm');
    this.initVendorForm(body);
  }

  editVendor(body) {
    this.formAdd = false;
    this.openForm('addVendorForm');
    this.initVendorForm(body);
  }

  submitVendorForm(form) {
    form.controls.name.setValue(this.removeWhiteSpace(form.controls.name.value));
    if (form.value.id) {
      this.storeService.editVendor(form.value, this.hotelId)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(data => {
        this.getVendors();
        this.globals.snackBarFunc(`Edited successfully !`);
      }).add(() => {
        this.closeForm('addVendorForm');
      });
    } else {
      this.storeService.addVendor(form.value, this.hotelId)
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(data => {
        this.getVendors();
        this.globals.snackBarFunc(`Added successfully !`);
      }).add(() => {
        this.closeForm('addVendorForm');
      });
    }
  }

  getVendors() {
    this.storeService.getVendors(this.hotelId)
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe(data => {
      if (data?.vendors) {
        data.vendors.forEach(ele => {
          ele.selected = false;
        });
        this.vendors = data.vendors;
        this.$vendors = data.vendors;
      }
    });
  }

  vendorsFilter(key: string) {
    key = key.toLowerCase();
    this.$vendors = this.vendors.filter(data => {
      return data.name?.toLowerCase().indexOf(key) > -1;
    });
  }

  deleteVendor(vendorId: string, name: string) {
    const dialogBody = {title: 'DELETE DEPARTMENT', body: `Are you sure you want to delete ${name}?`,
    confirmBtn: 'Delete', cancelBtn: 'Cancel', confirmBtnColor: '#b90f0f'};
    const dialogRef = this.dialog.open(DialogComponent, {data: dialogBody});
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'true'){
        this.storeService.deleteVendor(vendorId, this.hotelId)
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(data => {
          this.getVendors();
          this.globals.snackBarFunc(`${name} deleted successfully !`);
        });
      }
    });
  }

  openForm(formName) {
    this.overlay = true;
    this.isVisible[formName] = true;
  }

  closeForm(formName) {
    this.overlay = false;
    this.isVisible[formName] = false;
    if (this.showMultiSelect) {
      this.showMultiSelect = false;
    }
  }

  /* Configure Department User */

  getUserDepts() {
    this.storeService.getUserDept(this.hotelId)
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe(data => {
      this.userDepts = data?.user_departments;
    });
  }

  openConfigureUsers() {
    this.overlay = true;
    this.showConfigureUsers = true;
    if (!this.departments) {
      this.getDepartments();
    }
  }

  closeConfigureUsers() {
    this.overlay = false;
    this.showConfigureUsers = false;
  }

  onDeptSelectList(deptName: string) {
    let newDept = false;
    for (const dept of this.departments) {
      if (dept.name.replace(/\s/g, '') === deptName.replace(/\s/g, '')) {
        newDept = true;
        this.newUserDept = {id: dept.id, name: dept.name};
        break;
      }
    }
    if (!newDept) {
      this.newUserDept = {id: null, name: null};
    }
  }

  addUserDept(username: string) {
    if (!(this.newUserDept.id && this.newUserDept.name)) {
      return;
    }
    for (const user of this.userDepts) {
      if (user.username?.trim() === username?.trim()) {
        for (const dept of user.departments) {
          if (dept.id === this.newUserDept.id) {
            return;
          }
        }
        const body = {username, dept_id: this.newUserDept.id};
        this.storeService.addUserDept(this.hotelId, body).subscribe(data => {
          user.departments.push(this.newUserDept);
        });
        break;
      }
    }
  }

  makeMasterUser(username) {
    const dialogBody = {title: 'User Access', body: `Are you sure you want to make ${username} as a Master User ?`,
    confirmBtn: 'Yes', cancelBtn: 'No', height: '160px'};
    const dialogRef = this.dialog.open(DialogComponent, {data: dialogBody});
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'true') {
        const body = {username, dept_id: '*'};
        this.storeService.addUserDept(this.hotelId, body).subscribe(data => {
          for (const user of this.userDepts) {
            if (user.username === username) {
              user.is_master_user = true;
              break;
            }
          }
        });
      }
    });
  }

  deleteUserDept(username, deptId) {
    for (const user of this.userDepts) {
      if (user.username === username) {
        this.storeService.deleteUserDept(this.hotelId, username, deptId).subscribe(data => {
          if (deptId === '*') {
            user.is_master_user = false;
          } else {
            user.departments = user.departments.filter(dept => dept.id !== deptId);
          }
        });
        break;
      }
    }
  }

  removeMasterUser(username) {
    const dialogBody = {title: 'User Access', body: `Are you sure you want to remove ${username} as a Master User ?`,
    confirmBtn: 'Yes', cancelBtn: 'No', height: '160px'};
    const dialogRef = this.dialog.open(DialogComponent, {data: dialogBody});
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'true') {
        this.deleteUserDept(username, '*');
      }
    });
  }

  removeWhiteSpace(str: string) {
    return str.replace(/\s+/g, ' ').trim();
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }
}
