import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { expandCollapse, rotate, slide, staggerEffect } from 'src/app/animations';
import { BanquetService } from 'src/app/core/banquet.service';

@Component({
  selector: 'app-banquet',
  templateUrl: './banquet.component.html',
  styleUrls: ['./banquet.component.scss'],
  animations: [expandCollapse, slide, rotate, staggerEffect]
})
export class BanquetComponent implements OnInit {

  sessions = [
    {name: 'Breakfast', shortName: 'B'},
    {name: 'Lunch', shortName: 'L'},
    {name: 'Dinner', shortName: 'D'}
  ];

  sessionMap = {
    '0': 'B', '1': 'L', '2': 'D'
  }

  statuses = [
    {name: 'Tentative', value: 'tentative', color: '#f1acac'},
    {name: 'Confirmed', value: 'confirmed', color: '#1ebc38cb'},
    {name: 'Completed', value: 'completed', color: '#023009'},
  ];

  hotelId: string = '';
  headerDates = [];
  hallsData = [];
  startDate: any;
  endDate: any;
  offset = ' 00:12:00';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private banquetService: BanquetService,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.hotelId = this.route.snapshot.params?.hotelId;
    this.getProductConfig();
    this.getViewData()
  }

  getViewData() {
    this.banquetService.getBanquetView(this.hotelId, this.startDate, this.endDate).subscribe(res => {
      if (res?.success) {
        this.startDate = res?.data?.startDate;
        this.headerDates = res?.data?.dates;
        this.hallsData = res?.data?.hallsData;
      }
    })
  }

  getProductConfig() {
    this.banquetService.getProductConfig(this.hotelId).subscribe(res => {
      if (res?.conf?.banquetSessions?.length === 3) {
        this.sessions = res.conf.banquetSessions;
        let si = -1; 
        for (const s of this.sessions) {
          si++;
          this.sessionMap[si.toString()] = s.shortName;
        }
      }
    })
  }

  endDateCalc(){
    this.endDate = new Date(this.startDate + this.offset);
    this.endDate.setDate(this.endDate.getDate() + 7);
    this.endDate = this.datePipe.transform((new Date(this.endDate)), 'y-MM-dd');
  }
  
  changeDay(move){
    this.startDate = new Date(this.startDate + this.offset);
    if (move === 'next'){
      this.startDate.setDate(this.startDate.getDate() + 7);
    }
    else if (move === 'prev'){
      this.startDate.setDate(this.startDate.getDate() - 7);
    }
    this.startDate = this.datePipe.transform((new Date(this.startDate)), 'y-MM-dd');
    this.endDateCalc();
    this.getViewData();
  }

  datePicked(datePickerValue): void {
    this.startDate = new Date(datePickerValue);
    this.startDate = this.datePipe.transform((new Date(this.startDate)), 'y-MM-d');
    this.endDateCalc();
    this.getViewData();
  }

  changeStatus(newStatus: 'confirmed' | 'completed', data) {
    this.banquetService.changeStatus(this.hotelId, {newStatus, ...data}).subscribe(res => {
      if (res?.success) {
        this.getViewData();
      }
    })
  }

  editReservation(reservationId) {
    this.router.navigate([`/banquet/${this.hotelId}/create`], {queryParams: {reservationId}});
  }

  createReservation() {
    this.router.navigate([`/banquet/${this.hotelId}/create`]);
  }
}
