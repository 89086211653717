enum Status {
    Blocked = 'blocked',
    Tentative = 'tentative',
    Confirmed = 'confirmed',
    Cancelled = 'cancelled'
}

export const Statuses = [
    {name: 'Tentative', value: 'tentative'},
    {name: 'Blocked', value: 'blocked'},
    {name: 'Confirmed', value: 'confirmed'},
    {name: 'Cancelled', value: 'cancelled'},
];
