import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import moment from 'moment';
import { UserService } from 'src/app/core/user.service';
import { DialogComponent } from 'src/app/_shared/dialog/dialog.component';


@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.css']
})
export class LineChartComponent implements OnInit {
  startDate = '';
  endDate = '';
  endTime = '';
  startTime = '';
  hotelRoute: any;
  InvalidInput = false;
  hotelName = '';
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private datePipe: DatePipe, private userService: UserService, public matdialog:MatDialog) { }

  datesToShow = [];

  ngOnInit(): void {
    this.convertStringToDatetime(this.data.deviceDetails.datetimelist, 'no')
    this.startDate = this.data.deviceDetails.fromDate;
    this.startTime = '12:00'
    this.endDate = this.data.deviceDetails.toDate;
    this.endTime = '12:00'
    this.hotelName = this.data.deviceDetails.hotelName;
  }

  convertStringToDatetime(datetimeData, flag){
    for (var day = 0; day<Object.keys(datetimeData).length; day++){
      this.datesToShow.push(moment(datetimeData[day]).format('LT'))
    }
    if (flag == 'yes'){
      this.randomize();
    }
  }

  public lineChartOptions = {
    scaleShowVerticalLines: false,
    responsive: false,
    scales: {
      yAxes: [{
        ticks: {
          max: 1,
          min: 0,
          stepSize: 1,
          callback: function(value, index, values) {
            if (value == 1){
              return 'Occupied';
            }else{
              return 'Available'
            }
            
        }
      }
      }],
      xAxis: [{
        type : 'time',
        unit : 'minute' 
      }]
    }
  }
  public lineChartLabels = this.datesToShow;
  public lineChartType = 'line';
  public lineChartLegend = true;
  public lineChartData = [
    {
      data: this.data.deviceDetails.roomStatus, 
      label: 'Room Status', 
      backgroundColor: "transparent",
      pointBackgroundColor:"#ff80aa", 
      pointBorderColor : "#ffcce6"
    }
  ];

  public lineChartOptions1 = {
    scaleShowVerticalLines: false,
    responsive: false,
    interaction: {
      mode: 'index'
    },
    scales: {
      yAxes: [{
        ticks: {
          max: 1,
          min: 0,
          stepSize: 1,
          callback: function(value, index, values) {
            if (value == 1){
              return 'On';
            }else{
              return 'Off'
            }
        }
      }
      }],
      xAxis: [{
        type: 'time',
        unit: 'minute',
        time: {
        tooltipFormat: 'LT'
        },
        showLine : true
      }]
  },
  }
  public lineChartLabels1 = this.datesToShow;
  public lineChartType1 = 'line';
  public lineChartLegend1 = true;
  public lineChartData1 = [
    {
      data: this.data.deviceDetails.deviceStatus,
      label: 'Device Status',
      backgroundColor: "transparent",
      borderColor: "#08d5e0", 
      pointBackgroundColor:"#08d5e0",
      pointBorderColor : "#b3ecff"
    }
  ];

  checkDateDiff(startDate, endDate){
    var date1 = new Date(startDate); 
	  var date2 = new Date(endDate);
    var Time = date2.getTime() - date1.getTime(); 
    var Days = Time / (1000 * 3600 * 24); //Diference in Days
    if(Days > 2){
      this.InvalidInput = true;
    }
    else{
      this.InvalidInput = false;
    }
  }

  public randomize():void {
    let _lineChartData:Array<any> = new Array(this.lineChartData);
    for (let i = 0; i < this.lineChartData.length; i++) {
      _lineChartData[i]={
        data: this.data.deviceDetails.roomStatus, 
        label: 'Room Status', 
        backgroundColor: "transparent",
        pointBackgroundColor:"#ff80aa", 
        pointBorderColor : "#ffcce6"
      }
    }
    this.lineChartLabels.length = 0;
    this.lineChartLabels = this.datesToShow;
    this.lineChartData = _lineChartData;
    let _lineChartData1:Array<any> = new Array(this.lineChartData1);

    for (let i = 0; i < this.lineChartData1.length; i++) {
      _lineChartData1[i]={
        data: this.data.deviceDetails.deviceStatus, 
        label: 'Device Status',
        backgroundColor: "transparent",
        borderColor: "#08d5e0", 
        pointBackgroundColor:"#08d5e0",
        pointBorderColor : "#b3ecff"
      }
    }
    this.lineChartLabels1.length = 0;
    this.lineChartLabels1 = this.datesToShow;
    this.lineChartData1 = _lineChartData1;
  }


  submit(startDate,startTime,endDate,endTime){
    this.userService.getDeviceStatusForRoomNum(this.data.hotelID, this.data.roomNumId, startDate, endDate, startTime+':00', endTime+':00').subscribe(
      devicedata => {
        this.data.deviceDetails = devicedata;
        this.datesToShow = []
        if( devicedata.error){
          const WarningDialog = {title: 'Error !', body: devicedata.error, cancelBtn: 'Dismiss'};
          const dialogRef = this.matdialog.open(DialogComponent, {data: WarningDialog});
        }
        this.convertStringToDatetime(devicedata.datetimelist, 'yes')
      });
  }
  

  
}
