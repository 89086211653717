<ng-container id="additional-guest">
  <div class="add-guest">
    <div class="row">
      <h1>Additional Guest Details</h1>
      <button class="form-inline ml-auto mt-3 action-buttons" id="backButton" mat-raised-button mat-dialog-close>
        Back
      </button>
    </div>
    <hr />
    <div class="add-guest-form">
      <form #guestform="ngForm" name="ngForm" (ngSubmit)="submit(guestform)">
        <div class="row row1 mb-2">
          <div class="col-md-3 padding-0">
            <label for="name">Name</label>
            <input type="text" class="form-control form-control-sm" id="name" [(ngModel)]="guestName" name="guestname" required/>
          </div>
          <div class="col-md-4 padding-0">
            <label for="email">Email</label>
            <input type="text" class="form-control form-control-sm" id="email" [(ngModel)]="email" name="email"/>
          </div>
          <div class="col-md-2 padding-0">
            <label for="phone">Phone No</label>
            <input type="text" class="form-control form-control-sm" id="phone" [(ngModel)]="phone" name="phone"/>
          </div>
          <div class="col-md-2 padding-0">
            <label for="gender">Gender</label>
            <select class="form-control form-control-sm" [(ngModel)]="gender" id="gender" name="gender">
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Other">Other</option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3 padding-0">
            <label for="addressLine1">Address Line1</label>
            <input type="text" class="form-control form-control-sm" id="addressLine1" [(ngModel)]="addressLine1" name="addressLine1"/>
          </div>
          <div class="col-md-2 padding-0">
            <label for="city">City</label>
            <input type="text" class="form-control form-control-sm" id="city" [(ngModel)]="city" name="city"/>
          </div>
          <div class="col-md-2 padding-0">
            <label for="state">State</label>
            <input type="text" class="form-control form-control-sm" id="state" [(ngModel)]="state" name="state"/>
          </div>
          <div class="col-md-2 padding-0">
            <label for="zip">Zip</label>
            <input type="text" class="form-control form-control-sm" id="zip" [(ngModel)]="zip" name="zip"/>
          </div>
          <div class="col-md-2 padding-0 mb-2">
            <label for="country">Country</label>
            <input type="text" class="form-control form-control-sm" id="country" [(ngModel)]="country" name="country"/>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2 padding-0">
            <label for="idproof">Identity</label>
            <select class="form-control form-control-sm" [(ngModel)]="idproof" id="idproof" name="idproof">
              <option value=""></option>
              <option *ngFor="let option of identityList" [value]="option">
                {{ option }}
              </option>
            </select>
          </div>
          <div class="col-md-2 padding-0">
            <label for="idDetail">ID Detail</label>
            <input type="text" class="form-control form-control-sm" [(ngModel)]="idDetails" name="iddetail"/>
          </div>
          <div class="form-group col-md-2 mb-3 padding-0">
            <label for="fileup">Photo ID</label><br />
            <div class="upload-btn-wrapper">
              <button id="fileup" type="button" class="btn1 form-control form-control-sm">
                Upload
              </button>
              <input type="file" name="documents" ngModel accept=".pdf, image/jpg, image/jpeg, image/png" multiple (change)="handleInput($event.target)"/>
            </div>
          </div>
          <div class="form-group col-md-2 mb-3 padding-0 images" *ngIf="fileselected?.length > 0">
            <label for="files"></label>
            <ul class="images">
              <li id="files" *ngFor="let i of fileselected">
                <div class="cancel" (click)="removeImage(i)">+</div>
                <a *ngIf="i['url']" href="{{ i['url'] }}" target="_blank">
                  <img src="{{ i['url'] }}" width="70px" height="50px" alt=""/>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="row action-buttons mt-4 actionstyle">
          <button class="button1 mr-1" type="submit" mat-raised-button [disabled]="!guestform.valid">
            Submit
          </button>
        </div>
        <br />
      </form>
    </div>
    <div *ngIf="guestDetails && guestDetails.length > 0">
      <table class="table table-striped">
        <thead class="thead-dark">
          <tr class="text-center">
            <th>Sr. No</th>
            <th>Name</th>
            <th>Address</th>
            <th>Gender</th>
            <th>ID Proof</th>
            <th>ID Details</th>
            <th>Photo Ids</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of guestDetails; let indexOfelement = index" class="text-center">
            <td>{{ indexOfelement + 1 }}</td>
            <td class="alignTextCenter">{{ data.name }} <mat-icon matTooltip = "Email - {{data.email}}, Phone - {{data.phone}}">info</mat-icon></td>
            <td>{{ data.addressLine1 }}&nbsp;{{ data.city }}&nbsp;{{ data.state }}&nbsp;{{ data.zip }}&nbsp;{{ data.country }}</td>
            <td>{{ data.gender }}</td>
            <td>{{ data.idproof }}</td>
            <td>{{ data.idDetails }}</td>
            <td style="padding-bottom: 0px">
              <ul class="images">
                <li id="files" *ngFor="let i of data.photoId">
                  <a *ngIf="i['url']" href="{{ i['url'] }}" target="_blank">
                    <img src="{{ i['url'] }}" width="70px" height="50px" alt=""/>
                  </a>
                </li>
              </ul>
            </td>
            <td class="actions">
              <mat-icon (click)="deleteguest(indexOfelement)">
                delete
              </mat-icon>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</ng-container>
