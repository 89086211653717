<div class="tab-container">
    
    <div class="d-flex justify-content-end">
        <button class="btn-sm pr-border mr-2" (click)="createPo()">
            {{ "accounts.create_purchase_order" | translate }}
        </button>

        <button class="btn-sm pr-border mr-2" (click)="addVendor()">
            {{ "accounts.add_vendor" | translate }}
        </button>

        <input type="search" id="search" 
            class="form-control form-control-sm" placeholder="Search..."  (input)="vendorsFilter($event.target.value)">
    </div>

    <table class="table m-3" *ngIf="vendors.length">
        <thead class="thead-dark">
            <tr>
                <th>{{ "accounts.name" | translate }}</th>
                <th>{{ "accounts.gst/vat" | translate }}</th>
                <th>{{ "accounts.phone" | translate }}</th>
                <th>{{ "accounts.email" | translate }}</th>
                <th class="text-right">{{ "accounts.total_outstanding" | translate }}</th>
                <th class="text-center">{{ "accounts.action" | translate }}</th>
            </tr>
        </thead>

        <tbody>
            <tr *ngFor="let item of $vendors">
                <td>{{item.name}}</td>
                <td>{{item.gst_num}}</td>
                <td>{{item.phone}}</td>
                <td>{{item.email}}</td>
                <td>
                    <div class="d-flex justify-content-around">
                        <span>{{item.total_outstanding | number: '1.2-2'}}</span>
                        <mat-icon matTooltip="Refresh Total Outstanding" (click)="refreshOutstanding(item)">refresh</mat-icon>
                    </div>
                </td>
                <td class="d-flex justify-content-end">
                    <button class="btn-sm pr-border mr-2" (click)="makePayment(item)">
                        {{ "accounts.make_payment" | translate }}
                    </button>
                    <button class="btn-sm pr-border mr-2"
                        (click)="viewLedger(item)">
                        {{ "accounts.view_ledger" | translate }}
                    </button>
                    <mat-icon class="text-primary mr-1" (click)="editvendor(item)">edit</mat-icon>
                    <mat-icon class="text-danger" (click)="deleteVendor(item)">delete</mat-icon>
                </td>
            </tr>
        </tbody>
    </table>

</div>
