import { Component, Input, AfterViewInit, ViewChild, OnInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CreateLaundryComponent } from './create-laundry/create-laundry.component';
import { MatDialog } from '@angular/material/dialog';
import { MaintenanceIssueService } from 'src/app/core/maintenance-issue.service';
import { DatePipe } from '@angular/common';
import { DialogComponent } from 'src/app/_shared/dialog/dialog.component';

@Component({
  selector: 'app-laundry',
  templateUrl: './laundry.component.html',
  styleUrls: ['./laundry.component.scss'],
})
export class LaundryComponent implements OnInit {
  @Input() hotelId: string;
  @Input() showPage: boolean;
  ELEMENT_DATA: LaundryInfo[];
  fromDate : string = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
  laundryStockDetails = [];
  toDate : string = '';
  status: string;
  constructor(private dialog: MatDialog, private maintainanceService: MaintenanceIssueService, private datePipe: DatePipe) {}

  displayedColumns: string[] = [
    'item',
    'quantity',
    'sentDate',
    'receivedDate',
    'status',
    'comments',
    'action',
  ];
  dataSource = new MatTableDataSource<LaundryInfo>();

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngOnInit(): void {
    this.dateFilter();
    // this.dataSource.paginator = this.paginator;
    // this.dataSource.sort = this.sort;
    
  }

  dateFilter(){
    var date = new Date();
    const datecopy = new Date(Number(date));
    datecopy.setDate(date.getDate() - 6);
    this.fromDate = this.datePipe.transform(datecopy, 'yyyy-MM-dd');
    this.toDate = this.datePipe.transform(date, 'yyyy-MM-dd');
    this.status = 'Sent';
    this.getData();
  }

  createNewLaundry() {
    const data = { hotelId: this.hotelId, mode: 'new' };
    this.dialog.open(CreateLaundryComponent, {
      width: '95%',
      maxWidth: '95%',
      minHeight: '50%',
      height: '70%',
      maxHeight: '80%',
      data,
    });
  }

  printSummary() {
    const data = { hotelId: this.hotelId, mode: 'summary' };
    this.dialog.open(CreateLaundryComponent, {
      width: '30%',
      maxWidth: '30%',
      minHeight: '40%',
      height: '40%',
      maxHeight: '50%',
      data,
    });
  }

  editItem(laundryData){
    const data = { hotelId: this.hotelId, mode: 'edit', laundryData: laundryData  };
    this.dialog.open(CreateLaundryComponent, {
      width: '95%',
      maxWidth: '95%',
      minHeight: '30%',
      height: '30%',
      data,
    });
  }

  deleteItem(id){
    const dialogBody =
        { title: 'Delete Laundry',
          body: `Do You Want To Delete?`,
          confirmBtn: 'Yes',
          cancelBtn: 'Cancel'
        };
        const dialogRef = this.dialog.open(DialogComponent, {data: dialogBody});

        dialogRef.afterClosed().subscribe(result => {
          if (result === 'true'){
            this.maintainanceService.deleteLaundry(this.hotelId, id).subscribe(success=>{
              this.ngOnInit();
            })
          }})
    
  }

  getData(){
    this.maintainanceService.getLaundryInfo(this.hotelId, this.fromDate, this.toDate, this.status).subscribe(success=>{
      this.dataSource.data = success;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    })
  }

  getLaundryStock(){
    this.maintainanceService.fetchLaundryStockDetails(this.hotelId).subscribe(success=>{
      this.laundryStockDetails = success.success;
      this.showLaundryStock();
    })
  }

  showLaundryStock(){
    const data = { hotelId: this.hotelId, mode: 'stock', laundryStockData: this.laundryStockDetails  };
    this.dialog.open(CreateLaundryComponent, {
      width: '85%',
      maxWidth: '85%',
      minHeight: '60%',
      height: '60%',
      data,
    });
  }
}

export interface LaundryInfo {
  sentDate: string;
  item: string;
  quantity: number;
  receivedDate: string;
  status: string;
  comments: string;
  action: string;
}