import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountsService } from 'src/app/core/accounts.service';
import { finalize } from 'rxjs/operators'
import { DialogComponent } from 'src/app/_shared/dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { CompaniesService } from 'src/app/core/companies.service';
import { AccReceivePaymentComponent } from '../modals/acc-receive-payment/acc-receive-payment.component';
import { Globals } from 'src/app/core/globals.service';
import { AddCompanyComponent } from '../modals/add-company/add-company.component';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-receivables',
  templateUrl: './receivables.component.html',
  styleUrls: ['./receivables.component.scss']
})
export class ReceivablesComponent implements OnInit {
  @Input() accountsConfig: any = {};

  hotelId: string;
  companies: any = {};
  currentPage = 0;
  loading = true;
  paginateArray = [];
  searchInput = new FormControl();

  constructor(
    private route: ActivatedRoute,
    private accountsService: AccountsService,
    private companiesService: CompaniesService,
    private dialog: MatDialog,
    private router: Router,
    private globals: Globals
  ) { }

  ngOnInit(): void {
    this.hotelId = this.route.snapshot.params.hotelId;
    this.getReceivables();
    this.searchCompany();
  }

  getReceivables(page=1, reload=false) {
    if (this.currentPage === page && !reload) {
      return;
    }
  
    this.accountsService.getReceivables(this.hotelId, page)
    .pipe(finalize(() => this.loading = false))
    .subscribe(res => {
      if (res?.success) {
        this.companies = res?.data;
        this.currentPage = page;
        this.pagination()
      }
    });
  }

  receivePayment(company) {
    const ref = this.dialog.open(AccReceivePaymentComponent, {
      width: '500px',
      data: {company, hotelId: this.hotelId}
    });

    ref.afterClosed().subscribe(res => {
      if (res?.success) {
        this.getReceivables(this.currentPage, true)
      }
    })
  }

  pagination() {
    const max = this.companies?.total_pages >= 10 ? 10 : this.companies?.total_pages;
    const arr = [];
    let p = this.currentPage;
    while(arr.length <= max) {
      arr.push(p);
      p++;
    }
    this.paginateArray = arr;
  }

  editCompany(company) {

  }


  updateGroupCompanies() {
    const dialogBody = {title: 'Update Group Companies', body: `Are you sure you want to update group companies ?`, confirmBtn: 'Yes', cancelBtn: 'No'};
    const dialogRef = this.dialog.open(DialogComponent, {data: dialogBody});
  
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'true') {
        this.accountsService.updateGroupCompanies(this.hotelId).subscribe(res => {
          if (res?.success) {
            this.getReceivables(1, true);
            this.globals.snackBarFunc(`${res.data?.noOfCompaniesAdded} companies added`);
          }
        })
      }
    })
  }

  deleteCompany(company) {
    const dialogBody = {title: 'Delete Company', body: `Are you sure you want to delete ${company.name} ?`, confirmBtn: 'Yes', cancelBtn: 'No'};
    const dialogRef = this.dialog.open(DialogComponent, {data: dialogBody});
  
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'true') {
        if (this.accountsConfig?.conf?.accountsConfigLevel === 'chain') {
          this.deleteGroupCompany(company.id);
        } else {
          this.deleteHotelCompany(company);
        }
      }
    })

  }

  deleteGroupCompany(id: string) {
    this.accountsService.deleteGroupCompanies(this.hotelId, id).subscribe(res => {
      if (res?.success) {
        this.getReceivables(this.currentPage, true);
      }
    })
  }

  deleteHotelCompany(company) {
    this.companiesService.deleteCompany(this.hotelId, company.id, company.name, localStorage.getItem('user'))
    .subscribe(res => {
      this.getReceivables(this.currentPage, true);
    });
  }

  viewLedger(company) {
    this.router.navigate([`accounts/ledger/${this.hotelId}/${company.id}`], {queryParams: {company: JSON.stringify(company)}})
  }

  addCompany() {
    const ref = this.dialog.open(AddCompanyComponent, {
      width: '600px',
      data: {
        hotelId: this.hotelId,
        configLevel: this.accountsConfig?.conf?.accountsConfigLevel
      }
    })
  }

  nextPage() {
    if (this.currentPage >= this.companies?.total_pages) {
      return;
    }
  
    this.getReceivables(this.currentPage + 1);
  }

  previousPage() {
    if (this.currentPage === 1) {
      return;
    }
  
    this.getReceivables(this.currentPage - 1);
  }

  searchCompany() {
    this.searchInput.valueChanges
    .pipe(debounceTime(500))
    .subscribe(val => {
      this.accountsService.getReceivables(this.hotelId, 1, val).subscribe(res => {
        this.companies = res?.data;
        this.currentPage = 1;
        this.pagination()
      })
    })
    
  }

  refreshOutstanding(company) {
    const body: any = {
      updateType: 'company'
    }
    if (this.accountsConfig?.conf?.accountsConfigLevel === 'chain') {
      body.groupCompanyId = company.id
    } else {
      body.companyId = company.id
    }
    this.accountsService.updateOutstanding(this.hotelId, body).subscribe(res => {
      if (res?.success) {
        this.globals.snackBarFunc(`Outstanding updated !`);
        company.totalOutstanding = res.data?.newOutstanding;
      }
    })
  }
}
