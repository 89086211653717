import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { UserService } from '../../../core/user.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators} from '@angular/forms';
import { DialogComponent } from 'src/app/_shared/dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Globals } from '../../../core/globals.service';
import { CustomValidator } from '../validators/customValidator';
import { CommunicationService } from 'src/app/core/communication.service';
import { ReservationService } from 'src/app/core/reservation.service';
import { saveAs } from 'file-saver-es';
import { debounceTime, finalize } from 'rxjs/operators'
import { PaymentsService } from 'src/app/core/payments.service';
import { DiscountLogComponent } from '../../../_shared/common_modals/discount-log/discount-log.component';
 
@Component({
  selector: 'app-service-details',
  templateUrl: './service-details.component.html',
  styleUrls: ['./service-details.component.css']
})
export class ServiceDetailsComponent implements OnInit {
  @Input() allocId: string;
  @Input() mode: string;
  @Input() status: string;
  @Input() hoteliD:string;

  @Output() invoiceCount = new EventEmitter<number>();
  @Output() invoiceIds = new EventEmitter<any>();
  currentUser: string;

  servicesUsedData: any;
  quantitySum = 0;
  amountSum = 0;
  taxSum = 0;
  totalAmountSum = 0;
  balanceAmount: number;
  serviceNames: any;
  serviceCategories: any;
  serviceId: string;
  amount = 0;
  newAmount = 0;
  allTax: any;
  taxAmount = 0;
  newTaxAmount = 0;
  totalAmount = 0;
  description: '';
  newEntryplusView = false;
  quantity = 1;
  hotelId: string;
  invoiceId: string;
  invoiceData: any[];
  currency: string;
  addServiceUsedForm: FormGroup;
  currentInvoiceIndex = 0;
  checkedInGuests = [];
  taxInclusive: string;
  taxList = [];
  percTax = 0;
  flatTax = 0;
  isEditable: string;
  selectedGuest: any;
  supportedCurrencies: string[];
  selectedCurrency: string;
  discount = 0;
  discountType = '';
  discountApplicable = true;
  taxDetails: any;
  features: any;
  serviceConfig: any = {};
  loading = false;
  amountChangeTimer: any;
  discountAppliedOn: 'preTax' | 'postTax' = 'preTax';
  bulkAddServices = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private userService: UserService,
              public fb: FormBuilder, public dialog: MatDialog, private globals: Globals,
              private commS: CommunicationService, private allocservice: ReservationService, private paymentService: PaymentsService,) { }

  ngOnInit(): void {
    this.globals.currentUser.subscribe(user => this.currentUser = user);
    this.globals.currentHotelID.subscribe(hotelId => this.hotelId = hotelId);
    if (this.hotelId == 'none'){
      this.hotelId = this.hoteliD;
    }
    if (this.data.mode === 'newEntry'){
      this.mode = 'newEntry';
      this.newEntry();
    }else if (this.mode === 'editStay'){
      if(this.status === 'Assigned' || this.status === 'Unassigned'){
        this.getBalance();
      }else{
        this.balanceUpdate();
      }
      
      this.getServiceDetails();
      this.globals.getCurrencyCode(this.hotelId).subscribe(
        data => {
          this.currency = data;
        }, err => {

        });
    }

    this.commS.balanceChange.subscribe(ev => {
      if (ev !== 'null'){
        if (ev === this.allocId) {
          this.balanceUpdate();
        }
      }
    });

    this.userService.getCheckedInGuests(this.hotelId, this.allocId).subscribe(
      data => {
        this.checkedInGuests = data.checked_in_guests;
      });

    this.supportedCurrencies = this.userService.supportedCurrencies;

    this.userService.userFeatures$.subscribe(data => {
      this.features = data;
    });
  }

  balanceUpdate() {
    this.userService.getBalance(this.allocId).subscribe(
      data => {
        this.balanceAmount = data.balance;
      }
    );
  }

  getBalance(){
    this.paymentService.getBalanceNonCheckedinAlloc(this.hotelId, this.allocId).subscribe(data=>{
      this.balanceAmount = data.balance;
    })
  }

  changeMode(mode){
    this.mode = mode;
    this.newEntryplusView = !this.newEntryplusView;
    this.newEntry();
  }

  // NEW ENTRY
  newEntry(){
    this.allocId = this.data.allocId;
    if (!this.invoiceId && this.data?.invoiceId){
      this.invoiceId = this.data.invoiceId;
    }
    this.getServiceCategories();
    this.initializeForm();
    this.onChangeActions();
  }

  onChangeActions() {
    this.addServiceUsedForm.controls.quantity.valueChanges
      .pipe(debounceTime(500))
      .subscribe(v => {
        this.quantityFun(v)
    })
  }

  initializeForm(){
    this.addServiceUsedForm = this.fb.group({
      category: ['', [Validators.required]],
      service_id: [[Validators.required]],
      comments: [''],
      service_amount: [0, [Validators.required]],
      tax: [0, [Validators.required]],
      total_amount: [0, [Validators.required]],
      roomAllocationId: [this.allocId, [Validators.required]],
      quantity: [1, [Validators.required, CustomValidator.cannotBeLessThanOne]],
      hotelId: [this.hotelId],
      invoiceId: [this.invoiceId],
      user: [this.currentUser],
      service_name: ['']
    });
  }

  getServiceCategories() {
    this.userService.getServiceCategories(this.hotelId).subscribe(
      data => {
        this.serviceCategories = data.categories;
      }, err => {
        console.log(err);
      });
  }

  filterServices(category) {
    for (const ele of this.serviceCategories) {
      if (ele.category === category) {
        this.serviceNames = ele.service_names;
        break;
      }
    }
  }

  initAmounts(data, qtyChange = false) {
    if (!qtyChange) {
      this.amount = data.amount;
    }
    this.newAmount = data.amount;
    this.taxAmount = data.tax.total;
    this.newTaxAmount = this.taxAmount;
    this.totalAmount = data.total_amount;
    this.taxDetails = {...data.tax};
    this.allTax = {...this.taxDetails};
  }

  getAmount(name) {
    this.userService.getServiceConfig(this.hotelId, name).subscribe(
      data => {
        this.serviceConfig = data;
        this.initAmounts(data);
        this.serviceId = data.service_id;
        this.description = data.comments;
        this.taxInclusive = data.tax_inclusive;
        this.percTax = data.perc_total_tax;
        this.flatTax = data.flat_total_tax;
        this.taxList = data.tax_list;
        this.isEditable = data.editable;
      }, err => {

      });
  }

  amountChange(amount) {
    this.serviceConfig.amount = amount / this.quantity;
    clearTimeout(this.amountChangeTimer);

    this.amountChangeTimer = setTimeout(() => {
      this.calculateAmount(amount)
    }, 800);
  }

  calculateAmount(amount, qtyChange = false) {
    if (this.serviceId && amount && amount) {
      const body = {amount, taxInclusive: this.taxInclusive, taxList: this.taxList, percTotalTax: this.percTax,
        flatTotalTax: this.flatTax };
      this.loading = true;
      this.userService.calculateTax(body)
      .pipe(finalize(() => this.loading = false))
      .subscribe(
      data => {
        this.initAmounts(data, qtyChange);
      });
    }
  }

  quantityFun(qty){
    this.quantity = qty;
    let amount = this.taxInclusive === 'No' ? this.serviceConfig?.amount : this.serviceConfig?.total_amount;
    amount = amount * this.quantity;
    this.calculateAmount(amount, true);
  }

  submit(form){
    form.controls.service_id.setValue(this.serviceId);
    form.controls.invoiceId.setValue(this.invoiceId);

    form.controls.tax.setValue(this.allTax);
    
    this.userService.bulkAddService(this.bulkAddServices, this.allocId, this.invoiceId, this.hotelId).subscribe(
      success =>
      {
        const dialogBody = {title: 'Success', body: 'Service added', cancelBtn: 'Ok'};
        this.dialog.open(DialogComponent, {data: dialogBody});
        this.commS.emitBalanceChange(this.allocId);
      }, err => {
          const dialogBody = {title: err, cancelBtn: 'Dismiss'};
          this.dialog.open(DialogComponent, {data: dialogBody});
      });
  }
  // ******************************************************//
  // VIEW SERVICES USED
  getServiceDetails() {
    let body: any;
    body = {};
    if (this.allocId) {
      body.roomAllocationId = this.allocId;
    }
    this.userService.getServicesUsed(body, this.hotelId).subscribe(
      data => {
        this.invoiceData = data.services_used;
        if (this.invoiceData.length > 0) {
          this.invoiceId = this.invoiceData[0].invoice_id;
          const invoiceIds = this.invoiceData.map(e => e.invoice_id);
          this.invoiceIds.emit(invoiceIds);
          this.invoiceCount.emit(this.invoiceData.length);
          this.changeInvoice();
          this.checkBoxToggleAll();
        }
    }, err => {
      console.log(err);
    });
  }

  changeInvoice(i = 0) {
    this.servicesUsedData = this.invoiceData[i].data;
    this.quantitySum = this.invoiceData[i].quantity_sum;
    this.taxSum = this.invoiceData[i].tax_sum;
    this.totalAmountSum = this.invoiceData[i].total_amount_sum;
    this.amountSum = this.invoiceData[i].amount_sum;
    this.invoiceId = this.invoiceData[i].invoice_id;
    this.servicesUsedData.forEach(obj => {
      obj.checked = false;
    });
    this.currentInvoiceIndex = i;
  }

  deleteServiceUsed(serviceUsedId, amount, serviceName, service) {
    const dialogBody = {title: 'Delete Service', body: `Are you sure you want to delete this service ?`,
    confirmBtn: 'Yes', cancelBtn: 'Cancel'};
    const dialogRef = this.dialog.open(DialogComponent, {data: dialogBody});
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'true') {
        const body = {roomAllocationId: this.allocId, invoiceId: this.invoiceId, amount, user: this.currentUser,
          serviceName};
        this.userService.deleteServicesUsed(serviceUsedId, this.hotelId, body)
        .subscribe(
          success => {
            this.getServiceDetails();
            this.commS.emitBalanceChange(this.allocId);
          }, err => {

          });
        if (service.service_category === 'Extra Services'){
          this.deleteServiceFromAllocation(service);
        }
      }
    });
  }

  checkBoxToggleAll(){
    if (this.servicesUsedData.every(val => val.checked === true)){
      this.servicesUsedData.forEach(val => {
        val.checked = false;
      });
    }else{
      this.servicesUsedData.forEach(val => {
        val.checked = true;
      });
    }
  }

  checkedServices(){
    const checkedServices = this.servicesUsedData.filter(serv => {
      return serv.checked === true;
    });
    return checkedServices;
  }

  selectCheckedInGuest(selectedGuest, fn: string) {
    this.selectedGuest = selectedGuest;
    
    // Transfer to same room, different invoice
    if (fn === 'transferToInvoice') {
      if (this.selectedGuest?.newInvoice) {
        this.transferToInvoice();
      }
      else if (this.selectedGuest.hasOwnProperty('invoiceTransferIndex')) {
        const invoiceIndex = this.selectedGuest.invoiceTransferIndex;
        this.transferToInvoice(this.invoiceData[invoiceIndex].invoice_id);
      }
      else if (this.selectedGuest?.invoice_id && this.selectedGuest?.room_allocation_id) {
        this.transferToInvoice(this.selectedGuest.invoice_id, this.selectedGuest.room_allocation_id, selectedGuest);
      }
    } 
    else if (fn === 'transferInvoiceToRoom') {
      this.transferInvoiceToRoom(this.selectedGuest.room_allocation_id, this.selectedGuest.room_num);
    }
    this.selectedGuest = undefined;
  }

  removeWhiteSpace(str: string): string {
    return str.replace(/\s+/g, ' ').trim();
  }

  // Transfer Items
  transferToInvoice(id = 0, newRoomAllocationId = null, selectedGuest = null) {
    const services = this.checkedServices();
    if (services.length > 0) {
      const dialogBody = {title: 'Transfer Service', body: `Are you sure you want to transfer this service ?`,
      confirmBtn: 'Yes', cancelBtn: 'Cancel'};
      const dialogRef = this.dialog.open(DialogComponent, {data: dialogBody});
      dialogRef.afterClosed().subscribe(result => {
        if (result === 'true') {
          const serviceUsedIds = [];
          const serviceUsedNames = [];
          let totalAmount = 0;
          services.forEach(element => {
            serviceUsedIds.push(element.service_used_id);
            serviceUsedNames.push(element.service_name);
            totalAmount += element.total_amount;
          });
          totalAmount = parseFloat((totalAmount).toFixed(2));

          const body = 
          {serviceUsedIds, roomAllocationId: this.allocId, totalAmount, prevInvoiceId: this.invoiceId,
            user: this.currentUser, newRoomAllocationId, serviceUsedNames, 
            prevRoom: {roomNum: this.data?.roomNum, folioNo: this.data?.folioNo},
            newRoom: {roomNum: selectedGuest?.room_num, folioNo: selectedGuest?.folio_num}
          };
          this.userService.transferInvoice(body, id, this.hotelId).subscribe(
            data => {
              this.dialog.closeAll()
              this.commS.emitStayViewReload();
              this.globals.snackBarFunc('Items transferred');
            }, err => {

            });
        }
      });
    } else {
      const dialogBody = {title: 'Transfer Invoice', body: 'No services selected. Please select a service to transfer',
      cancelBtn: 'Dismiss'};
      this.dialog.open(DialogComponent, {data: dialogBody});
    }
  }

  transferInvoiceToRoom(newRoomAllocationId, newRoomNum) {
    const invoiceId = this.invoiceData[this.currentInvoiceIndex].invoice_id;
    const body = {invoiceId, newRoomAllocationId, prevRoomAllocationId: this.allocId};
    let invoiceName = this.invoiceData[this.currentInvoiceIndex].invoice_num;
    invoiceName = invoiceName ? invoiceName : `Invoice ${this.currentInvoiceIndex + 1}`;
    const dialogBody = {title: `Transfer Invoice`, body: `Transfer ${invoiceName} to Room - ${newRoomNum} ?`,
    confirmBtn: 'Yes', cancelBtn: 'Cancel'};
    const dialogRef = this.dialog.open(DialogComponent, {data: dialogBody});
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'true') {
        this.userService.transferInvoiceToRoom(body, this.hotelId).subscribe(data => {
          this.dialog.closeAll()
          this.commS.emitStayViewReload();
          this.globals.snackBarFunc('Invoice transferred');
        });
      }
    });
  }

  downloadInvoice() {
    if (!(this.supportedCurrencies.includes(this.selectedCurrency))) {
      return;
    }

    this.userService.downloadInvoice(this.hotelId, this.invoiceId, this.selectedCurrency)
    .subscribe(data => {
      const blob: any = new Blob([data], {type: 'text/pdf; charset=utf-8' });
      const fileName = `${this.selectedCurrency}-${this.invoiceId}.pdf`;
      saveAs(blob, fileName);
    });
  }

  deleteServiceFromAllocation(service){
    var serviceData = {serviceName: service.service_name, quantity: service.quantity, date: service.service_date,
                        pretax_amount: service.service_amount, tax:service.tax}
    this.allocservice.updateServiceToAllocation(this.data.hotelId, this.data.allocId, this.data.queryParams.user, serviceData, 'remove').subscribe(success =>{
      this.dialog.closeAll();
    })
  }

  applyDiscount() {  
    const checkedServices = this.servicesUsedData.filter(e => e.checked);
    if (this.discount && checkedServices?.length) {

      const dialogBody = {title: 'Apply Discount', 
      body: `Are you sure you want to apply a discount of ${this.discountType === '%' ? this.discount + '%' : 'flat ' + this.discount}
        on ${this.discountAppliedOn === 'postTax' ? 'Post Tax Amount' : 'Pre Tax Amount'} ?`,
      confirmBtn: 'Yes', cancelBtn: 'Cancel',
      height: '170px'
      };
      const dialogRef = this.dialog.open(DialogComponent, {data: dialogBody});

      dialogRef.afterClosed().subscribe(res => {
        if (res === 'true') {
          const body = {
            discount: this.discount, 
            discountType: this.discountType, 
            servicesUsed: checkedServices,
            invoiceId: this.invoiceId,
            roomAllocationId: this.allocId,
            appliedOn: this.discountAppliedOn
          };
          
          this.userService.applyDiscount(this.data.hotelId, body).subscribe(
            data => {
              this.globals.snackBarFunc('Discount Applied');
              this.commS.emitBalanceChange(this.allocId);
              this.getServiceDetails();
            });
        }
      });

    }
  }

  getDiscountLog() {
    this.userService.getDiscountLog(this.hotelId, this.allocId).subscribe(res => {
      if (res?.success && res.data?.length) {
        const dref =this.dialog.open(DiscountLogComponent, {
          width: '1000px',
          data: {
            discountData: res.data,
            hotelId: this.hotelId,
            roomAllocationId: this.allocId
          }
        });

        dref.afterClosed().subscribe(res => {
          if (res?.refresh) {
            this.getServiceDetails();
            this.commS.emitBalanceChange(this.allocId);
          }
        })
      } else if (res?.success && !res.data?.length) {
        this.globals.snackBarFunc('No Discounts Applied');
      }
    })
  }

  bulkAdd() {
    const data = this.addServiceUsedForm.value;
    data.service_id = this.serviceId;
    data.tax = this.allTax;
    
    if (this.addServiceUsedForm.valid && this.serviceId) {
      this.bulkAddServices.push(data);
      
      const resetArr = ['service_id', 'description', 'service_amount', 'tax', 'total_amount', 'service_name', 'comments'];
      for (const fc of resetArr) {
        this.addServiceUsedForm.get(fc)?.setValue(null);
      }
      this.addServiceUsedForm.get('quantity')?.setValue(1);
      this.serviceId = null;
    }
  }

  removeService(index) {
    this.bulkAddServices.splice(index, 1);
  }

  doNotGenInvoiceNum(value: boolean) {
    this.userService.updateInvoiceNumGen(this.hotelId, this.invoiceId, value).subscribe(res => {
      if (res?.success) {
        this.globals.snackBarFunc(`Invoice no. will ${value ? 'not' : ''} be generated`);
      }
    })
  } 
}
