import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from 'src/app/_shared/snackbar/snackbar.component';

@Injectable({
  providedIn: 'root'
})
export class Globals {

  innerWidth: any;
  loadingMap: Map<string, boolean> = new Map<string, boolean>();

  private hotelID = new BehaviorSubject<string>('none');
  private user = new BehaviorSubject<string>('none');
  public isLoading = new BehaviorSubject<boolean>(false);
  public config = new BehaviorSubject<any>({});

  currentHotelID = this.hotelID.asObservable();
  currentUser = this.user.asObservable();
  config$ = this.config.asObservable();
  isLoading$ = this.isLoading.asObservable();

  constructor(private http: HttpClient,
              public snackBar: MatSnackBar) {
    this.innerWidth = window.innerWidth;

    window.addEventListener('resize', (event) => {
      this.innerWidth = window.innerWidth;
    });
  }

  setConfig(config) {
    this.config.next(config);
  }

  changeHotelID(hotelID: string) {
    this.hotelID.next(hotelID);
  }

  getToken(): string {
    return localStorage.getItem('token');
  }

  getCurrencyCode(hotelId): Observable<any>{
    return this.http.get<any>(`${environment.apiUrl}/fetchcurrency/${hotelId}`);
  }

  setUser(user: string) {
    this.user.next(user);
  }

  setisLoading(loading: boolean, url: string): void {
    if (!url) {
      throw new Error('The request URL must be provided to the setisLoading function');
    }
    if (loading === true) {
      this.loadingMap.set(url, loading);
      this.isLoading.next(true);
    } else if (loading === false && this.loadingMap.has(url)) {
      this.loadingMap.delete(url);
    }
    if (this.loadingMap.size === 0) {
      this.isLoading.next(false);
    }
  }

  snackBarFunc(message) {
    this.snackBar.openFromComponent(SnackbarComponent, {
      panelClass: 'my-custom-snackbar',
      data: message,
      duration: 4000,
    });
  }

  // input type text
  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode !== 45 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  // input type = number
  amountOnly(event: any) {
    const arr = event.target.value.split('.');
    if (arr?.length > 1 && arr[1].length > 2) {
      arr[1] = '.' + arr[1].split('').slice(0, 2).join('');
      event.target.value = arr.join('');
    }
  }

  numberWithDecimal(event: any) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (
      // Only allow one decimal point
      (charCode === 46 && (event.target as HTMLInputElement).value.includes('.')) || 
      // Disallow non-numeric characters
      (charCode !== 46 && charCode > 31 && (charCode < 48 || charCode > 57))
    ) {
      event.preventDefault();
    }
  }
}
