import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AllocationMenu2Component } from './allocation-menu2/allocation-menu2.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  declarations: [
    AllocationMenu2Component
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    TranslateModule,
  ],
  exports: [
    AllocationMenu2Component
  ]
})
export class AllocationMenu2Module { }
