<div>
    <div class="aio_heading">
        <h2>B2C (Small) Invoices - 7</h2>
    
        <button mat-raised-button class="primary_btn" (click)="back()">
          Back
        </button>
    </div>

    <mat-divider></mat-divider>

    <div class="container-fluid common_content">
        
        <table class="table">
            <thead class="thead-dark">
                <tr>
                    <th>Particular</th>
                    <th class="text-right">Taxable Amount</th>
                    <th class="text-right">IGST</th>
                    <th class="text-right">CGST</th>
                    <th class="text-right">SGST</th>
                    <th class="text-right">CESS</th>
                    <th class="text-right">Tax Amount</th>
                    <th class="text-right">Total Amount</th>
                </tr>
            </thead>

            <tbody>
                <tr *ngFor="let item of tableData">
                    <td>
                        <span class="links" 
                            (click)="openInvoiceDetails(item.invoiceDetails)">
                            {{item.state + ' ' + item.taxRate}}
                        </span>
                    </td>
                    <td class="text-right">{{item.preTaxAmount | number: '1.2-2'}}</td>
                    <td class="text-right">{{item.igst | number: '1.2-2'}}</td>
                    <td class="text-right">{{item.cgst | number: '1.2-2'}}</td>
                    <td class="text-right">{{item.sgst | number: '1.2-2'}}</td>
                    <td></td>
                    <td class="text-right">{{item.totalTax | number: '1.2-2'}}</td>
                    <td class="text-right">{{item.totalAmount | number: '1.2-2'}}</td>
                </tr>
            </tbody>

        </table>

    </div>

</div>
