import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }

  supportedCurrencies = [
    'AED', 'AFN', 'ALL', 'AMD', 'ANG', 'AOA', 'ARS', 'AUD', 'AWG', 'AZN', 'BAM', 'BBD', 'BDT', 'BGN', 'BHD', 'BIF', 'BMD', 'BND', 'BOB', 'BRL',
    'BSD', 'BTC', 'BTN', 'BWP', 'BYN', 'BYR', 'BZD', 'CAD', 'CDF', 'CHF', 'CLF', 'CLP', 'CNY', 'COP', 'CRC', 'CUC', 'CUP', 'CVE', 'CZK', 'DJF',
    'DKK', 'DOP', 'DZD', 'EGP', 'ERN', 'ETB', 'EUR', 'FJD', 'FKP', 'GBP', 'GEL', 'GGP', 'GHS', 'GIP', 'GMD', 'GNF', 'GTQ', 'GYD', 'HKD', 'HNL',
    'HRK', 'HTG', 'HUF', 'IDR', 'ILS', 'IMP', 'INR', 'IQD', 'IRR', 'ISK', 'JEP', 'JMD', 'JOD', 'JPY', 'KES', 'KGS', 'KHR', 'KMF', 'KPW', 'KRW',
    'KWD', 'KYD', 'KZT', 'LAK', 'LBP', 'LKR', 'LRD', 'LSL', 'LTL', 'LVL', 'LYD', 'MAD', 'MDL', 'MGA', 'MKD', 'MMK', 'MNT', 'MOP', 'MRO', 'MUR',
    'MVR', 'MWK', 'MXN', 'MYR', 'MZN', 'NAD', 'NGN', 'NIO', 'NOK', 'NPR', 'NZD', 'OMR', 'PAB', 'PEN', 'PGK', 'PHP', 'PKR', 'PLN', 'PYG', 'QAR',
    'RON', 'RSD', 'RUB', 'RWF', 'SAR', 'SBD', 'SCR', 'SDG', 'SEK', 'SGD', 'SHP', 'SLL', 'SOS', 'SRD', 'STD', 'SVC', 'SYP', 'SZL', 'THB', 'TJS',
    'TMT', 'TND', 'TOP', 'TRY', 'TTD', 'TWD', 'TZS', 'UAH', 'UGX', 'USD', 'UYU', 'UZS', 'VEF', 'VND', 'VUV', 'WST', 'XAF', 'XAG', 'XAU', 'XCD',
    'XDR', 'XOF', 'XPF', 'YER', 'ZAR', 'ZMK', 'ZMW', 'ZWL'
  ];

  private _userFeatures = new BehaviorSubject<any>(null);
  userFeatures$ = this._userFeatures.asObservable();

  set userFeatures(data) {
    this._userFeatures.next(data);
  }
  

  getProductConfig(hotelId): Observable <any> {
    return this.http.get(`${environment.apiUrl}/hotels/${hotelId}/products/pms`);
  }

  getCurrentDate(hotelId): Observable <any> {
    return this.http.get(`${environment.apiUrl}/pms/current-date/${hotelId}`);
  }

  getCurrentRoomStatus(startDate, endDate, hotelId): Observable<any> {
    const httpParams = new HttpParams({
      fromObject: {startDate, endDate}
    });
    return this.http.get(`${environment.apiUrl}/current-room-status/${hotelId}`, { params: httpParams });
  }

  getStayview2(startDate, endDate, hotelId): Observable<any> {
    const httpParams = new HttpParams({
      fromObject: {startDate, endDate}
    });
    return this.http.get(`${environment.apiUrl}/pms2/current-room-status/${hotelId}`, { params: httpParams });
  }

  changeRoomDirty(body, hotelId): Observable<any> {
    return this.http.put(`${environment.apiUrl}/roomdirty/${hotelId}`, body);
  }

  checkIn(body, hotelId): Observable<any> {
    return this.http.put(`${environment.apiUrl}/checkin/${hotelId}`, body);
  }

  undoCheckIn(roomAllocationId, hotelId, body): Observable<any> {
    return this.http.put(`${environment.apiUrl}/undo-checkin/${roomAllocationId}/${hotelId}`, body);
  }

  undoCheckOut(roomAllocationId, hotelId, body): Observable<any> {
    return this.http.put(`${environment.apiUrl}/undo-checkout/${roomAllocationId}/${hotelId}`, body);
  }

  checkOut(data, hotelId): Observable<any> {
    return this.http.put(`${environment.apiUrl}/checkout/${hotelId}`, data);
  }

  unassignRoom(body, hotelId): Observable<any> {
    return this.http.put(`${environment.apiUrl}/unassign/${hotelId}`, body);
  }

  allocateRoom(body, hotelId): Observable<any> {
    return this.http.put(`${environment.apiUrl}/allocate-room/${hotelId}`, body);
  }

  dragDrop(body, hotelId): Observable<any> {
    return this.http.put(`${environment.apiUrl}/drag-drop/${hotelId}`, body);
  }

  deleteRoomAllocation(roomAllocationId, hotelId, body): Observable<any> {
    return this.http.put(`${environment.apiUrl}/room-allocation/${roomAllocationId}/${hotelId}`, body);
  }

  addServiceToFolio(body, hotelId): Observable<any> {
    return this.http.post(`${environment.apiUrl}/services-used/${hotelId}`, body);
  }

  bulkAddService(services, roomAllocationId, invoiceId, hotelId): Observable<any> {
    return this.http.post(`${environment.apiUrl}/pms/${hotelId}/add-bulk-services`, {services, roomAllocationId, invoiceId});
  }

  getServicesUsed(body, hotelId): Observable<any> {
    const httpParams = new HttpParams({
      fromObject: body
    });
    return this.http.get(`${environment.apiUrl}/services-used/${hotelId}`, { params: httpParams });
  }

  deleteServicesUsed(serviceId, hotelId, body): Observable<any> {
    const httpParams = new HttpParams({
      fromObject: body
    });
    return this.http.delete(`${environment.apiUrl}/services-used/${serviceId}/${hotelId}`, { params: httpParams });
  }

  getServiceCategories(hotelId): Observable<any> {
    return this.http.get(`${environment.apiUrl}/services-category/${hotelId}`);
  }

  getServiceConfig(hotelId, name, roundTo='2'): Observable<any> {
    const httpParams = new HttpParams({fromObject: {name, roundTo} });
    return this.http.get(`${environment.apiUrl}/services-config/${hotelId}`, { params: httpParams });
  }

  addServicesConfig(hotelId, body): Observable<any> {
    return this.http.post(`${environment.apiUrl}/services-config/${hotelId}`, body);
  }

  getServiceNames(hotelId, category): Observable<any> {
    const httpParams = new HttpParams({fromObject: {category} });
    return this.http.get(`${environment.apiUrl}/service-names/${hotelId}`, { params: httpParams });
  }

  getPayments(allocationId, hotelId): Observable<any> {
    const httpParams = new HttpParams({
      fromObject: {roomAllocationId: allocationId}
    });
    return this.http.get(`${environment.apiUrl}/payments/${hotelId}`, { params: httpParams });
  }

  getExpenses(hotelId, startDate, endDate, paymentType, expenseType: 'chain' | 'hotel' = 'hotel' , chainId = null): Observable<any> {
    const httpParams = new HttpParams({
      fromObject: {startDate, endDate, paymentType, expenseType, chainId}
    });
    return this.http.get(`${environment.apiUrl}/payments/${hotelId}/expenses`, { params: httpParams });
  }

  getExpenseCategories(hotelId): Observable<any> {
    return this.http.get(`${environment.apiUrl}/expense_categories/${hotelId}`);
  }

  getRoomAllocationData(allocationId, hotelId, user): Observable<any> {
    const httpParams = new HttpParams({fromObject: {user}});
    return this.http.get(`${environment.apiUrl}/room-allocation/${allocationId}/${hotelId}`, {params: httpParams});
  }

  addPaymentToFolio(body, hotelId): Observable<any> {
    return this.http.post(`${environment.apiUrl}/payments/${hotelId}`, body);
  }

  deletePayment(paymentId, hotelId, body): Observable<any> {
    const httpParams = new HttpParams({
      fromObject: body
    });
    return this.http.delete(`${environment.apiUrl}/payments/${hotelId}/${paymentId}`, {params: httpParams});
  }

  uploadFile(file, key): Observable<any> {
    const httpParams = new HttpParams({
      fromObject: {bucket: 'aiosell.pms', key}
    });
    const data = new FormData();
    data.append('file', file);
    return this.http.post(`${environment.apiUrl}/upload/`, data, {params: httpParams});
  }

  getComapanyNames(hotelId): Observable<any> {
    return this.http.get(`${environment.apiUrl}/companies/${hotelId}/names`);
  }

  transferInvoice(body, id, hotelId): Observable<any> {
    if (!id){
      return this.http.put(`${environment.apiUrl}/services-used/transfer/${hotelId}`, body);
    }
    return this.http.put(`${environment.apiUrl}/services-used/transfer/${id}/${hotelId}`, body);
  }

  transferInvoiceToRoom(body, hotelId): Observable<any> {
    return this.http.put(`${environment.apiUrl}/transfer-invoice/${hotelId}`, body);
  }

  getInvoice(hotelId, roomAllocationId): Observable<any> {
    const httpParams = new HttpParams({fromObject: {roomAllocationId}});
    return this.http.get(`${environment.apiUrl}/invoice/${hotelId}`, {params: httpParams});
  }

  getGroupInvoice(hotelId, roomAllocationId): Observable<any> {
    const httpParams = new HttpParams({fromObject: {roomAllocationId}});
    return this.http.get(`${environment.apiUrl}/group-invoice/${hotelId}`, {params: httpParams});
  }

  getPaymentReceipt(hotelId, roomAllocationId): Observable<any> {
    const httpParams = new HttpParams({fromObject: {roomAllocationId}});
    return this.http.get(`${environment.apiUrl}/payment-receipt/${hotelId}`, {params: httpParams});
  }

  getInvoiceById(hotelId, invoiceId, pos = 'False'): Observable<any> {
    const httpParams = new HttpParams({fromObject: {invoiceId, pos}});
    return this.http.get(`${environment.apiUrl}/invoice/${hotelId}`, {params: httpParams});
  }

  getInvoiceDetails(roomAllocationId, hotelId): Observable<any> {
    const httpParams = new HttpParams({fromObject: {roomAllocationId}});
    return this.http.get(`${environment.apiUrl}/invoice-details/${hotelId}`, {params: httpParams});
  }

  editInvoiceDetails(body, roomAllocationId, hotelId): Observable<any> {
    const httpParams = new HttpParams({fromObject: {roomAllocationId}});
    return this.http.put(`${environment.apiUrl}/invoice/${hotelId}`, body, {params: httpParams});
  }

  getBalance(roomAllocationId): Observable<any> {
    return this.http.get(`${environment.apiUrl}/balance/${roomAllocationId}`);
  }

  getNumRooms(hotelId, bookingId): Observable<any> {
    const httpParams = new HttpParams({fromObject: {bookingId}});
    return this.http.get(`${environment.apiUrl}/num-rooms/${hotelId}`, {params: httpParams});
  }

  getCheckedInGuests(hotelId, roomAllocationId): Observable<any> {
    return this.http.get(`${environment.apiUrl}/checkedin-guests/${roomAllocationId}/${hotelId}`);
  }

  getTaxGroups(hotelId: string): Observable <any> {
    return this.http.get(`${environment.apiUrl}/tax-group/${hotelId}`);
  }

  calculateTax(body): Observable<any> {
    return this.http.put(`${environment.apiUrl}/tax/calculate-tax`, body);
  }

  getRoomView(hotelId, date, floorView): Observable<any> {
    const httpParams = new HttpParams({fromObject: {date, floorView}});
    return this.http.get(`${environment.apiUrl}/pms2/rooms-view/${hotelId}`, {params: httpParams});
  }

  getGrc(hotelId, roomAllocationId): Observable<any> {
    const httpParams = new HttpParams({fromObject: {roomAllocationId}});
    return this.http.get(`${environment.apiUrl}/pms/guest-registration-card/${hotelId}`, {params: httpParams, responseType: 'blob'});
  }

  downloadInvoice(hotelId, invoiceId, currency: string): Observable<any> {
    const httpParams = new HttpParams({fromObject: {invoiceId}});
    return this.http.get(`${environment.apiUrl}/invoice/${hotelId}/${currency}`, {params: httpParams, responseType: 'blob'});
  }

  editInvoiceDetailsforAllocation(body, roomAllocationId, hotelId): Observable<any> {
    const httpParams = new HttpParams({fromObject: {roomAllocationId}});
    return this.http.put(`${environment.apiUrl}/edit-invoice/${hotelId}`, body, {params: httpParams});
  }

  getPaymentAddedBy(hotelId, paymentId): Observable<any> {
    return this.http.get(`${environment.apiUrl}/pms/payment-user/${hotelId}/${paymentId}`);
  }

  applyDiscount(hotelId: string, body): Observable<any> {
    return this.http.put(`${environment.apiUrl}/services-used/${hotelId}/apply-discount`, body);
  }

  getDeviceStatusForRoomNum(hotelId, roomNumId, startDate = null, endDate = null, startTime = null, endTime = null): Observable<any> {
    const httpParams = new HttpParams({fromObject: {startDate, endDate, startTime, endTime}});
    return this.http.get(`${environment.apiUrl}/iot/device_status_details_daywise/${roomNumId}/${hotelId}`, {params: httpParams});
  }

  getUserData(body): Observable <any> {
    return this.http.post(`${environment.apiUrl}/user/get_details`, body);
  }

  getPrevDayCheckIns(hotelId: string): Observable <any> {
    return this.http.get(`${environment.apiUrl}/pms/previous-day-checkins/${hotelId}`);
  }

  getAvailableRooms(hotelId: string, startDate, endDate, roomId): Observable <any> {
    const httpParams = new HttpParams({fromObject: {startDate, endDate, roomId}});
    return this.http.get(`${environment.apiUrl}/pms/available-rooms/${hotelId}`, {params: httpParams});
  }

  syncRmsInventory(hotelId: string, startDate, endDate): Observable <any> {
    const httpParams = new HttpParams({fromObject: {startDate, endDate}});
    return this.http.get(`${environment.apiUrl}/pms/sync-rms-inventory/${hotelId}`, {params: httpParams});
  }

  transferGroupInvoiceToRoom(body, hotelId): Observable<any> {
    return this.http.put(`${environment.apiUrl}/transfer-invoice-group/${hotelId}`, body);
  }

  cryptoDecrypter({cardNumber, cvv, expiry}) {
    return this.http.post(`${environment.apiUrl}/crypto/decrypter`, {cardNumber, cvv, expiry});
  }

  getDiscountLog(hotelId, roomAllocationId, serviceUsedIds = []): Observable<any> {
    return this.http.put(`${environment.apiUrl}/pms/${hotelId}/discount-log`, {roomAllocationId, serviceUsedIds});
  }

  undoDiscount(hotelId, roomAllocationId, discountId): Observable<any> {
    return this.http.put(`${environment.apiUrl}/pms/${hotelId}/undo-discount`, {roomAllocationId, discountId});
  }

  getTaxNames(hotelId): Observable<any> {
    return this.http.get(`${environment.apiUrl}/pms/${hotelId}/tax-names`);
  }

  transferGroupInvoiceBulkToRoom(dataList, newAllocId, hotelId): Observable<any> {
    return this.http.put(`${environment.apiUrl}/transfer-invoice-group-bulk/${hotelId}`, {dataList: dataList, newAllocId: newAllocId});
  }
  
  getRoomAllocation(roomAllocationId, hotelId): Observable<any> {
    return this.http.get(`${environment.apiUrl}/pms/room-allocation/${hotelId}/${roomAllocationId}`);
  }

  getBalances(roomAllocationIds, hotelId): Observable<any> {
    return this.http.post(`${environment.apiUrl}/pms/balances/${hotelId}`, {roomAllocationIds});
  }

  updateInvoiceNumGen(hotelId, invoiceId, doNotGenInvoiceNum): Observable<any> {
    return this.http.put(`${environment.apiUrl}/pms/${hotelId}/${invoiceId}/invoice-num-gen`, {doNotGenInvoiceNum});
  }
}
