import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { StoreService } from 'src/app/core/store.service';
import { OrderComponent } from 'src/app/_shared/common_modals/order/order.component';
import { DatePipe } from '@angular/common';
import { CommunicationService } from 'src/app/core/communication.service';
import { Globals } from 'src/app/core/globals.service';
import { DialogComponent } from 'src/app/_shared/dialog/dialog.component';
import { saveAs } from 'file-saver-es';

@Component({
  selector: 'app-purchase-order',
  templateUrl: './purchase-order.component.html',
  styleUrls: ['./purchase-order.component.scss']
})

export class PurchaseOrderComponent implements OnInit {

  @Input() hotelId: string;
  @Input() features: any;
  @Input() tab: 'invoice' | 'purchaseOrder';

  masterItems: Array<any>;
  orderMode: string;
  orders: Array<any>;
  $orders: Array<any>;
  statuses = {all: 'All', applied: 'Applied', approved: 'Approved', invoiced: 'Invoiced'};
  selectedStatus = this.statuses.all;
  orderItems: Array<any>;

  endDate = new Date().toISOString().slice(0, 10);
  startDate: any;
  selectedInvoices = []

  constructor(public dialog: MatDialog,
              public storeService: StoreService,
              public datePipe: DatePipe,
              public commS: CommunicationService,
              public globals: Globals
            ) { }

  ngOnInit(): void {
    if (this.tab === 'invoice') {
      this.selectedStatus = this.statuses.invoiced;
    }
    this.startDateCalc();
    this.getOrders();
    this.commS.purchaseOrderReload.subscribe(event => {
      this.getOrders();
    });
  }

  startDateCalc() {
    this.startDate = new Date(this.endDate);
    this.startDate.setDate(this.startDate.getDate() - 7);
    this.startDate = this.datePipe.transform((new Date(this.startDate)), 'y-MM-dd');
  }

  openOrder(mode: string, vendor = null, orderId = null, orderNum = null, invoiceNum = null, invoiceDate = null, selectedFiles = null) {
    this.orderMode = mode;
    if (this.masterItems) {
      this.openOrderComponent(orderId, orderNum, vendor, invoiceNum, invoiceDate, selectedFiles);
    } else {
      this.storeService.getMasterItems(this.hotelId).subscribe(data => {
        this.masterItems = data?.master_items;
        this.openOrderComponent(orderId, orderNum, vendor, invoiceNum, invoiceDate, selectedFiles);
      });
    }
  }

  viewOrder(orderId: string, orderNum: string, vendor, edit = false, invoiceNum = null, invoiceDate = null, selectedFiles = null) {
    const mode = edit ? 'edit' : 'view';
    this.storeService.getOrderItems(orderId).subscribe(data => {
      this.orderItems = data.order_items;
      this.openOrder(mode, vendor, orderId, orderNum, invoiceNum, invoiceDate, selectedFiles);
    });
  }

  getOrders() {
    if (this.selectedStatus === 'All') {
      this.storeService.getPurchaseOrders(this.hotelId, this.startDate, this.endDate).subscribe(data => {
        this.orders = data.purchase_orders;
        this.$orders = JSON.parse(JSON.stringify(this.orders));
      });
    } else {
      this.storeService.getPurchaseOrders(this.hotelId, this.startDate, this.endDate, this.selectedStatus).subscribe(data => {
        this.orders = data.purchase_orders;
        this.$orders = JSON.parse(JSON.stringify(this.orders));
      });
    }
  }

  openOrderComponent(orderId, orderNum = null, vendor, invoiceNum, invoiceDate, selectedFiles) {
    let heading: string;
    switch (this.orderMode) {
      case 'new':
        heading = 'Create PO';
        break;
      case 'edit':
        heading = 'Edit PO';
        break;
      case 'view':
        heading = `Order # ${orderNum}`;
    }
    const data = {hotelId: this.hotelId, masterItems: this.masterItems, orderItems: this.orderItems,
      mode: this.orderMode, vendor, heading, orderId, tab: this.tab, invoiceNum, invoiceDate, orderNum, selectedFiles};
    this.dialog.open(OrderComponent, {
      width: '900px',
      maxWidth: '95%',
      minHeight: '600px',
      data
    });
  }

  filterOrders(key: string) {
    key = key.toLowerCase();
    this.$orders = this.orders.filter(data => {
      return data.num?.toLowerCase().indexOf(key) > -1 || data.invoice_num?.toLowerCase().indexOf(key) > -1
      || data.vendor?.name?.toLowerCase().indexOf(key) > -1;
    });
  }

  changeOrderStatus(status: string, orderId: string, vendorId) {
    const dialogStatus = status === 'Approved' ? 'Approve' : 'Invoice';
    let dialogBody: any;
    if (status === this.statuses.invoiced && (!vendorId || vendorId?.length === 0)) {
      dialogBody = {title: 'Warning', body: `Please select a vendor before invoicing the order`, cancelBtn: 'Ok'};
    } else {
      dialogBody = {title: 'Change PO status', body: `Are you sure you want to ${dialogStatus} PO ?`,
      confirmBtn: 'Yes', cancelBtn: 'No'};
    }

    const dialogRef = this.dialog.open(DialogComponent, {data: dialogBody});
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'true') {
        const body = {status, order_id: orderId};
        this.storeService.changeOrderStatus(body, this.hotelId).subscribe(data => {
          this.globals.snackBarFunc(`Order ${status} successfully !`);
        }).add(() => this.getOrders());
      }
    });
  }

  goodsReceived(orderId) {
    const dialogBody = {title: 'Goods Received', body: `Are you sure you have received the goods ?`, confirmBtn: 'Yes', cancelBtn: 'No'};
    const dialogRef = this.dialog.open(DialogComponent, {data: dialogBody});
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'true') {
        const body = {order_id: orderId};
        this.storeService.goodsReceived(this.hotelId, body).subscribe(data => {
          this.getOrders();
        });
      }
    });
  }

  deleteOrder(orderId, orderNum) {
    const dialogBody = {title: 'Delete Order', body: `Are you sure you want to delete ${orderNum} ?`, confirmBtn: 'Yes', cancelBtn: 'No'};
    const dialogRef = this.dialog.open(DialogComponent, {data: dialogBody});
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'true') {
        this.storeService.deletePurchaseOrder(this.hotelId, orderId).subscribe(data => {

        }).add(() => this.getOrders());
      }
    });
  }

  selectInvoices(checked, poId) {
    if (checked) {
      this.selectedInvoices.push(poId)
    } else {
      this.selectedInvoices = this.selectedInvoices.filter(e => {
        return e !== poId;
      });
    }
  }

  getBankPaymentFile() {
    if (!this.selectedInvoices?.length) {
      return;
    }
  
    const dialogBody = {
      title: 'Confirm', 
      body: `Do you want to mark these invoices as paid ?`, 
      confirmBtn: 'Yes', secondBtn: 'No', cancelBtn: 'Cancel'
    };
    const dialogRef = this.dialog.open(DialogComponent, {data: dialogBody});
    dialogRef.afterClosed().subscribe(res => {
      const markPaid = res === 'true' ? true : false;
      if (res === 'true' || res == 'secondBtn') {
        const body = {orderIds: this.selectedInvoices, markPaid}; 
        this.storeService.getBankPaymentFile(this.hotelId, body)
        .subscribe(res => {
          const blob: any = new Blob([res], {type: 'text/pdf; charset=utf-8'});
          saveAs(blob, `bank_payment_file_${new Date().toISOString()}.csv`);
          if (markPaid) {
            this.getOrders();
          }
        })
      }
    })
    
  }
}
