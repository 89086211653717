import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountsService } from 'src/app/core/accounts.service';
import { expandCollapse, slide } from 'src/app/animations';

@Component({
  selector: 'app-taxes',
  templateUrl: './taxes.component.html',
  styleUrls: ['./taxes.component.scss'],
  animations: [expandCollapse, slide]
})
export class TaxesComponent implements OnInit {
  startDate: any;
  endDate: any;
  selectedMode: 'sales' | 'purchases' = 'sales';
  hotelId: string;
  taxesData = [];

  constructor(
    private datePipe: DatePipe,
    private accountsService: AccountsService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.hotelId = this.route.snapshot.params.hotelId;
    this.startDateCalc();
    this.getData();
  }

  startDateCalc() {
    this.endDate = this.datePipe.transform((new Date()), 'y-MM-dd');
    this.startDate = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    this.startDate = this.datePipe.transform((this.startDate), 'y-MM-dd');
  }

  getData() {
    this.accountsService.getTaxes(this.hotelId, this.startDate, this.endDate, this.selectedMode)
    .subscribe(res => {
      this.taxesData = res?.data;
    })
  }

  viewLedger(taxName) {
    this.router.navigate([`accounts/taxes/ledger/${this.hotelId}`], {queryParams: {taxName, startDate: this.startDate, endDate: this.endDate, taxType: this.selectedMode}})
  }

  openGstr() {
    this.accountsService.currentTab = 'gstr1'
  }

}
