import { Component, OnInit, Input, AfterViewInit, OnDestroy } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { StoreService } from 'src/app/core/store.service';
import { MatDialog } from '@angular/material/dialog';
import { CommunicationService } from 'src/app/core/communication.service';
import { ConsumeComponent } from './consume/consume.component';

@Component({
  selector: 'app-consumption',
  templateUrl: './consumption.component.html',
  styleUrls: ['./consumption.component.scss']
})
export class ConsumptionComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() hotelId: string;
  @Input() features: any;

  masterItems: any[];
  itemsConsumed: any[];
  $itemsConsumed: any[];
  categories: any[];
  userDepartments: any[];
  endDate = new Date().toISOString().slice(0, 10);
  startDate: any;
  department = '';

  componentDestroyed$ = new Subject();

  constructor(public dialog: MatDialog,
              public storeService: StoreService,
              public datePipe: DatePipe,
              public commS: CommunicationService) { }

  ngOnInit(): void {
    this.startDateCalc();
    this.getItemsConsumed();
    this.commS.consumptionReload.subscribe(ev => this.getItemsConsumed());
  }

  ngAfterViewInit(): void {
    this.getMasterItems();
    this.getDepts();
    this.getCategories();
  }

  startDateCalc() {
    this.startDate = new Date(this.endDate);
    this.startDate.setDate(this.startDate.getDate() - 7);
    this.startDate = this.datePipe.transform((new Date(this.startDate)), 'y-MM-dd');
  }

  getMasterItems() {
    this.storeService.getMasterItems(this.hotelId)
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe(data => {
      this.masterItems = data?.master_items;
    });
  }

  getCategories() {
    this.storeService.getCategories(this.hotelId)
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe(data => {
      if (data?.categories) {
        this.categories = data.categories;
      }
    });
  }

  getItemsConsumed() {
    this.storeService.getItemsConsumed(this.hotelId, this.startDate, this.endDate)
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe(data => {
      this.itemsConsumed = data?.items_consumed;
      this.$itemsConsumed = JSON.parse(JSON.stringify(this.itemsConsumed));
    });
  }

  getDepts() {
    this.storeService.getDeptByUser(this.hotelId)
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe(data => {
      this.userDepartments = data?.departments;
    });
  }

  filterItemsConsumed(key: string) {
    key = key?.toLowerCase();
    const department = this.department?.toLowerCase();
    this.$itemsConsumed = this.itemsConsumed.filter(data => {
      return data.department?.toLowerCase().indexOf(department) > -1 &&
        (data.name?.toLowerCase().indexOf(key) > -1 || data.comments?.toLowerCase().indexOf(key) > -1);
    });
  }

  filterBy() {
    if (this.department === 'All') {
      this.department = '';
    }
    const department = this.department?.toLowerCase();
    this.$itemsConsumed = this.itemsConsumed.filter(data => {
      return data.department?.toLowerCase().indexOf(department) > -1;
    });
  }

  consumeItem() {
    const data = {hotelId: this.hotelId, masterItems: this.masterItems, categories: this.categories, departments: this.userDepartments};
    this.dialog.open(ConsumeComponent, {data});
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }
}
