<div class="primary_modal_wrapper">
    <div class="modal_heading">
        <h1>Charge Card</h1>
    </div>

    <mat-divider></mat-divider>

    <div class="modal_body">

        <div class="input_group">
            <mat-form-field appearance="outline">
                <mat-label>Name on card</mat-label>
                <input matInput maxlength="50" [(ngModel)]="cardName">
            </mat-form-field>
    
            <mat-form-field appearance="outline">
                <mat-label>Card No.</mat-label>
                <input matInput 
                    placeholder="1234 1234 1234 1234" 
                    [(ngModel)]="cardNumber" 
                    (ngModelChange)="cardNumChange()"
                    (keypress)="globals.numberOnly($event)"
                    maxlength="19"
                    autocomplete="off"
                >
                <mat-icon matSuffix>credit_card</mat-icon>
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Expiry</mat-label>
                <input matInput
                    placeholder="MM/YY"
                    [(ngModel)]="cardExpiry"
                    (ngModelChange)="cardExpiryChange()"
                    (keypress)="globals.numberOnly($event)"
                    maxlength="5"
                    autocomplete="off"
                    (paste)="return($event)"
                >
            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>CVV</mat-label>
                <input matInput 
                    [type]="'password'"
                    maxlength="3" 
                    [(ngModel)]="cardCvv"
                    (keypress)="globals.numberOnly($event)"
                    autocomplete="off"
                    (paste)="return($event)"
                >
            </mat-form-field>

        </div>
        
    </div>

    <div class="modal_footer">
        <mat-dialog-actions align="end">
            <button mat-raised-button 
                mat-dialog-close 
                [ngClass]="{disable_button: submitting}">
                Cancel
            </button>
            <button mat-raised-button
                class="success_btn"
                [ngClass]="{disable_button: submitting}"
                (click)="submit()">
                <ng-container *ngIf="!submitting; else submittingBlock">
                    {{submitText}} {{data?.amount | number: '1.2-2'}}
                </ng-container>
                <ng-template #submittingBlock>
                    Submitting...
                </ng-template>
            </button>
        </mat-dialog-actions>
    </div>

</div>