import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { DialogComponent } from 'src/app/_shared/dialog/dialog.component';
import { AddEditFormComponent } from '../../companies/add-edit-form/add-edit-form.component';
import { CompaniesService } from 'src/app/core/companies.service';

@Component({
  selector: 'app-company-search-table',
  templateUrl: './company-search-table.component.html',
  styleUrls: ['./company-search-table.component.scss']
})
export class CompanySearchTableComponent implements OnInit {

  ELEMENT_DATA: CompanyDataElement[];
  search: FormGroup;
  hotelID: any;
  currentUser: any;
  companyData = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private companiesService: CompaniesService,
  public fb: FormBuilder, public dialogRef: MatDialogRef<DialogComponent>,
  public dialog: MatDialog) { }

  ngOnInit(): void {
    this.getData();
    this.search = this.fb.group({
      searchInput: [this.data.keyword, Validators.required],
    });
    this.hotelID = this.data.hotelId;
    this.currentUser = this.data.user;
  }
  displayedColumns: string[] = ['select', 'name', 'email', 'tax_num', 'contracted_rate'];
  dataSource = new MatTableDataSource<CompanyDataElement>();
  selection = new SelectionModel<CompanyDataElement>(true, []);

  selectedRow(data){
    this.dialogRef.close({ data: data }); // pass data back to main page
  }

  getData(){
    this.companiesService.getAllCompaniesData(this.data.hotelId).subscribe(companyData =>{
      this.dataSource.data = companyData.success.data;
      this.companyData = companyData.success.data
      this.onSearch(this.data.keyword);
    })
  }

  editCompany(data) {
    this.dialog.open(AddEditFormComponent, {data: {data, hotelId: this.hotelID, mode: 'edit'},autoFocus: false,
    maxHeight: '90vh'});
  }

  clear(){
    this.search.patchValue({
      searchInput: ''
    });
    this.dataSource.data = this.companyData;
  }

  showResultBasedOnSearch(event){
    const filterValue = (event.target as HTMLInputElement).value;
    if (this.dataSource){
      this.onSearch(event.target.value);
    }else{
      this.dataSource.filter = filterValue.trim().toLowerCase();
    }
  }

  onSearch(key) {
    if (key) {
      let filteredCompanies = [];
      
      for (var company in this.companyData) {
        if ((this.companyData[company]['name'].toLowerCase()).includes(key.toLowerCase())) {
          filteredCompanies.push(this.companyData[company]);
        }
      }
    
      // Update data source with the filtered companies
      this.dataSource.data = filteredCompanies.length > 0 ? filteredCompanies : [];
    } else {
      // Reset to original data if there's no search input
      this.dataSource.data = this.companyData;
    }
    
  }

  back(){
    this.dialogRef.close();
  }
}

export interface CompanyDataElement {
  companyName: string;
  email: string;
  GSTNum: string;
  contractedRate: string;
}