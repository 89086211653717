<nav class="navbar" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
      {{ "operations.title" | translate }}
    </div>
    <div class="navbar-end">
        <div class="navbar-item">
            <button class="buttonStyle">
              <!-- <a class="pmsopslink" href="http://localhost:4200/create-pms-operations-entry/{{hotelId}}" target="_blank">Add Entry</a> -->
              <a class="pmsopslink" href="https://pms.aiosell.com/create-pms-operations-entry/{{hotelId}}" target="_blank">{{ "operations.add_entry" | translate }}</a>
            </button>
        </div>
    </div>
</nav>
<hr>
<div class=" mat-elevation-z8">
    <div class="form-row">
        <div class="form-group col-md-2 col-sm-2">
          <label for="fromDate">{{ "operations.from_date" | translate }}:</label>
          <input type="date" class="form-control form-control-sm" onkeydown="return false" id="fromDate" [(ngModel)]='FromDate' (change)= "getData()" name="FromDate" >
        </div>
        <div class="form-group col-md-2 col-sm-2">
          <label for="toDate">{{ "operations.to_date" | translate }}:</label>
          <input type="date" class="form-control form-control-sm" onkeydown="return false" id="toDate" [(ngModel)]='ToDate' (change)= "getData()" name="ToDate" >
        </div>
    </div>

    <div class="example-container table-responsive">
        <table mat-table [dataSource]="dataSource" matSort >
          <!-- Operation Date Column -->
          <ng-container matColumnDef="Date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ "operations.date" | translate }} </th>
            <td mat-cell class="left" *matCellDef="let element">{{element.Date | date:'d, MMM, yyyy'}}</td>
          </ng-container>
      
          <!-- Electricity Usage Column -->
          <ng-container matColumnDef="Electricity_Units">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ "operations.electricity_units" | translate }} </th>
            <td mat-cell class="left" *matCellDef="let element"> {{element.Electricity_Units}} </td>
          </ng-container>
      
          <!-- Daily Brief Photo Column -->
          <ng-container matColumnDef="Daily_Briefing_photo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ "operations.daily_briefing_photo" | translate }}</th>
            <td mat-cell class="left" *matCellDef="let element"><a href={{element.Daily_Briefing_photo}} target="_blank" *ngIf="element.Daily_Briefing_photo"><img src="{{element.Daily_Briefing_photo}}" alt="daily_brief_photo"></a></td>
          </ng-container>

          <!-- Daily Info Column -->
          <ng-container matColumnDef="Daily_Data">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ "operations.daily_data" | translate }}</th>
            <td mat-cell class="left" *matCellDef="let element">
              <ng-container *ngFor="let item of element.Daily_Data">
                <p *ngIf="item.fieldType != 'text' && item.fieldType != 'number'">{{ item.fieldName}} - <a href="{{item.fieldValue}}" target="_blank">Link </a></p>
                <p *ngIf="(item.fieldType == 'text' || item.fieldType == 'number') && item.fieldValue?.length >0">{{ item.fieldName}} - {{item.fieldValue}}</p>
              </ng-container>
            </td>
          </ng-container>

          <!-- Weekly Info Column -->
          <ng-container matColumnDef="Weekly_Data">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ "operations.weekly_data" | translate }}</th>
            <td mat-cell class="left" *matCellDef="let element">
              <ng-container *ngFor="let item of element.Weekly_Data">
                <p *ngIf="item.fieldType != 'text' && item.fieldType != 'number'">{{ item.fieldName}} - <a href="{{item.fieldValue}}" target="_blank">Link </a></p>
                <p *ngIf="(item.fieldType == 'text' || item.fieldType == 'number') && item.fieldValue?.length >0">{{ item.fieldName}} - {{item.fieldValue}}</p>
              </ng-container>
            </td>
          </ng-container>

          <!-- Monthly Info Column -->
          <ng-container matColumnDef="Monthly_Data">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ "operations.monthly_data" | translate }}</th>
            <td mat-cell class="left" *matCellDef="let element">
              <ng-container *ngFor="let item of element.Monthly_Data">
                <p *ngIf="item.fieldType != 'text' && item.fieldType != 'number'">{{ item.fieldName}} - <a href="{{item.fieldValue}}" target="_blank">Link </a></p>
                <p *ngIf="(item.fieldType == 'text' || item.fieldType == 'number') && item.fieldValue?.length >0">{{ item.fieldName}} - {{item.fieldValue}}</p><br>
              </ng-container>
            </td>
          </ng-container>
    
          <!-- action Column -->
          <ng-container matColumnDef="take_action" >
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ "operations.action" | translate }} </th>
            <td mat-cell class="tkaction" *matCellDef="let element">
              <div class="example-button-row" >
                <span title="Edit"><button mat-icon-button aria-label="Example icon button with a edit icon"  class="buttonsize" (click)="editROw(element)" [disabled]="features?.role != 'admin'">
                  <mat-icon>edit</mat-icon>
                </button></span>
              </div>
            </td>
          </ng-container>
    
          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[30, 40]" showFirstLastButtons></mat-paginator>
      
      </div>
</div>
