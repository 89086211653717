<div id="overlay" *ngIf="overlay"></div>
<div class="container">
    <div class="d-flex head-setting">
        <ng-container *ngFor="let head of settings">
            <button mat-stroked-button
                (click)="changeSetting(head)"
                [ngClass]="{'active-setting': currentSetting === head}">
                {{head}}
            </button>
        </ng-container>

        <button mat-stroked-button class="ml-auto border-warning"
            (click)="openConfigureUsers()">
            {{'stores.configure_user' | translate}}
        </button>
    </div>

    <div class="content">
        <!-- ITEM CATEGORY -->

        <ng-container *ngIf="currentSetting === 'Item Category'">
            <div class="category">
                <div class="mb-4 d-flex justify-content-end">
                    <div class="d-flex mr-1">
                        <input type="text" class="form-control" name="category" id="category"
                            [(ngModel)]="categoryName" placeholder="{{'stores.category_name' | translate}}" maxlength="64">
                        <button mat-stroked-button class="ml-1 pr-border"
                            (click)="addCategory(categoryName)">
                            {{'stores.add' | translate}}
                        </button>
                    </div>
                    <input type="search" class="col-sm-3 form-control" placeholder="{{'stores.search_category' | translate}} . . ."
                        (input)="filter$categories($event.target.value)">
                </div>

                <mat-accordion>
                    <mat-expansion-panel *ngFor="let category of $categories" class="item">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                <div class="category-heading">
                                    <span [ngClass]="{'d-none': editCategory?.id === category.id}">
                                        {{category.name}}
                                    </span>
                                    <div class="d-none align-items-center" (click)="$event.stopPropagation();"
                                        [ngClass]="{'d-flex': editCategory?.id === category.id}">
                                        <input type="text" class="form-control form-control-sm"
                                            (keydown.Space)="$event.stopPropagation();" [(ngModel)]="editCategory.name">
                                        <button mat-stroked-button class="small-btn border-success"
                                            (click)="saveEditCategory(category.name)">
                                            {{'stores.save' | translate}}
                                        </button>
                                        <button mat-stroked-button class="d-flex justify-content-center small-btn border-danger"
                                            (click)="cancelEdit()">
                                            {{'stores.cancel' | translate}}
                                        </button>
                                    </div>
                                    <div class="action-icons" (click)="$event.stopPropagation();">
                                        <mat-icon class="text-primary" (click)="editCategoryFun(category.id, category.name)">edit</mat-icon>
                                        <mat-icon class="text-danger" (click)="deleteCategory(category.name, category.id)">delete</mat-icon>
                                    </div>
                                </div>
                            </mat-panel-title>
                        </mat-expansion-panel-header>

                        <div class="expansion-content">
                            <div *ngFor="let sub of category.sub_categories" class="sub-item">
                                <span class="pr-4">{{sub?.name}}</span>
                                <span class="close-icon" (click)="deleteSubCategory(sub.name, category.id)">+</span>
                            </div>
                        </div>
                        <div class="row mt-2 expansion-content">
                            <span class="add-sub-category">
                                <input type="text" class="form-control form-control-sm"
                                    [(ngModel)]="subCategoryName" placeholder="Sub category name">
                                <button mat-stroked-button class="ml-1 pr-border small-btn"
                                    (click)="addSubCategory(subCategoryName, category.id, category.sub_categories)">
                                    <mat-icon>add</mat-icon> {{'stores.sub_category' | translate}}
                                </button>
                            </span>
                        </div>

                    </mat-expansion-panel>
                </mat-accordion>

            </div>
        </ng-container>
        <!-- ITEM CATEGORY END-->

        <!-- MASTER ITEM LIST -->
        <ng-container *ngIf="currentSetting === 'Master Items'">
            <div class="master-items">
                <div class="mb-4 row">
                    <div class="col-sm-3">
                        <label for="Category">{{'stores.category' | translate}}</label>
                        <select class="form-control" [(ngModel)]="fCategory" (change)="filterFsubCategories(fCategory); filterMasterItemBy();">
                            <option value="">All</option>
                            <option *ngFor="let cat of categories">
                                {{cat.name}}
                            </option>
                        </select>
                    </div>
                    <div class="col-sm-3">
                        <label for="Category">{{'stores.sub_category' | translate}}</label>
                        <select class="form-control" [(ngModel)]="fsubCategory" (change)="filterMasterItemBy();">
                            <option value="">All</option>
                            <option *ngFor="let cat of fsubCategories">
                                {{cat.name}}
                            </option>
                        </select>
                    </div>
                    <div class="col-sm-5 ml-auto">
                        <label class="invisible">search</label>
                        <div class="d-flex justify-content-between">
                            <button mat-stroked-button class="pr-border"
                                (click)="addItem()">{{'stores.add_item' | translate}}
                            </button>
                            <input type="search" class="form-control" placeholder="{{'stores.search_item' | translate}} . . ."
                                (input)="filter$masterItems($event.target.value)" [ngStyle]="{'width': '80%'}">
                        </div>
                    </div>
                </div>


                <div *ngIf="isVisible.addMasterItemForm" class="pop-up-form" (click)="showMultiSelectToggle(null, $event)">

                    <form [formGroup]="addMasterItemForm" (ngSubmit)="submitMasterForm(addMasterItemForm)">
                        <div class="form-content">
                            <span class="close-form" (click)="closeForm('addMasterItemForm')">+</span>
                            <div class="row h5 mb-1">
                                {{formAdd ? 'Add Item' : 'Edit Item'}}
                            </div>
                            <mat-divider></mat-divider>
                            <div class="row mt-2">
                                <div class="col-sm-6 form-group">
                                    <label for="">{{'stores.item_name' | translate}}</label>
                                    <input type="text" class="form-control form-control-sm" formControlName="name" maxlength="64">
                                </div>
                                <div class="col-sm-6 form-group">
                                    <label for="">{{'stores.category' | translate}}</label>
                                    <select class="form-control form-control-sm" (change)="filterSubCategory($event.target.value)"
                                        formControlName="category">
                                        <option *ngFor="let category of categories" [value]="category.name">
                                            {{category.name}}
                                        </option>
                                    </select>
                                    <!-- <input list="categoryList" class="form-control form-control-sm" (click)="$event.target.value = ''"
                                    (change)="filterSubCategory($event.target.value)" formControlName="category">
                                    <datalist id="categoryList">
                                        <option *ngFor="let category of categories">{{category.name}}</option>
                                    </datalist> -->
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6 form-group">
                                    <label for="">{{'stores.vendor' | translate}}</label>
                                    <div class="multi-select">
                                        <input type="text" class="form-control form-control-sm" (click)="showMultiSelectToggle(true); $event.target.value=''"
                                            (input)="vendorsFilter($event.target.value)">
                                        <div class="drop-down" *ngIf="showMultiSelect">
                                            <div class="options" *ngFor="let vendor of $vendors">
                                                <label>
                                                    <input type="checkbox" [(ngModel)]="vendor.selected" [ngModelOptions]="{standalone: true}">
                                                    {{vendor.name}}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-6 form-group">
                                    <label for="">{{'stores.sub_category' | translate}}</label>
                                    <select class="form-control form-control-sm" formControlName="sub_category">
                                        <option hidden>Select</option>
                                        <option *ngFor="let category of filteredSubCategory" [value]="category.name">
                                            {{category.name}}
                                        </option>
                                    </select>
                                    <!-- <input list="subcategoryList" class="form-control form-control-sm" (click)="$event.target.value = ''"
                                    formControlName="sub_category">
                                    <datalist id="subcategoryList">
                                        <option *ngFor="let category of filteredSubCategory">{{category.name}}</option>
                                    </datalist> -->
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6 form-group">
                                    <label for="">{{'stores.unit' | translate}}</label>
                                    <select class="form-control form-control-sm" formControlName="unit">
                                        <option hidden>Select</option>
                                        <option *ngFor="let unit of units" [value]="unit">
                                            {{unit}}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-sm-6 form-group">
                                    <label for="">{{'stores.rate' | translate}}</label>
                                    <input type="number" class="form-control form-control-sm" formControlName="rate">
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6 form-group">
                                    <label for="">{{'stores.tax_group' | translate}}</label>
                                    <select class="form-control form-control-sm" formControlName="tax_group">
                                        <option hidden>Select</option>
                                        <option [value]="tax" *ngFor="let tax of taxGroups">{{tax}}</option>
                                    </select>
                                </div>
                                <div class="col-sm-6 form-group">
                                    <label for="">{{'stores.tax_inclusive' | translate}}</label>
                                    <select class="form-control form-control-sm" formControlName="tax_inclusive">
                                        <option [value]="1" selected>Yes</option>
                                        <option [value]="0">No</option>
                                    </select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6 form-group">
                                    <label for="">Inventoried</label>
                                    <select class="form-control form-control-sm" formControlName="is_inventoried">
                                        <option [value]="1" selected>Yes</option>
                                        <option [value]="0">No</option>
                                    </select>
                                </div>
                                <div class="col-sm-6 form-group">
                                    <label for="">{{'stores.capital_item' | translate}}</label>
                                    <select class="form-control form-control-sm" formControlName="is_capital_item">
                                        <option [value]="1" selected>Yes</option>
                                        <option [value]="0">No</option>
                                    </select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6 form-group">
                                    <label for="">{{'stores.stock' | translate}}</label>
                                    <input type="number" class="form-control form-control-sm" formControlName="stock">
                                </div>
                            </div>

                            <div class="row">
                                <button type="submit" class="ml-auto pr-border" mat-stroked-button
                                    [disabled]="!addMasterItemForm.valid">
                                    {{'stores.submit' | translate}}
                                </button>
                            </div>
                        </div>
                    </form>

                </div>

                <div class="d-flex justify-content-end total-value-text">
                    {{'stores.total_value' | translate}}: <span>{{totalValue | number: '1.2-2'}}</span>
                </div>
                
                <table class="table table-striped">
                    <thead class="thead-dark">
                        <tr>
                            <th>{{'stores.name' | translate}}</th>
                            <th>{{'stores.category' | translate}}</th>
                            <th>{{'stores.sub_category' | translate}}</th>
                            <th class="text-right">{{'stores.rate' | translate}}</th>
                            <th class="text-center">{{'stores.tax_group' | translate}}</th>
                            <th class="text-center">{{'stores.tax_inclusive' | translate}}</th>
                            <th class="text-center">{{'stores.inventoried' | translate}}</th>
                            <th class="text-center">{{'stores.capital_item' | translate}}</th>
                            <th class="text-right">{{'stores.stock' | translate}}</th>
                            <th>{{'stores.unit' | translate}}</th>
                            <th class="text-right">{{'stores.value' | translate}}</th>
                            <th [ngStyle]="{'width': '100px', 'max-width': '100px'}"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of $masterItems">
                            <td>{{item.name}}</td>
                            <td>{{item.category}}</td>
                            <td>{{item.sub_category}}</td>
                            <td class="text-right">{{item.rate | number: '1.2-2'}}</td>
                            <td class="text-center">{{item.tax_group}}</td>
                            <td class="text-center">{{item.tax_inclusive ? 'Yes' : 'No'}}</td>
                            <td class="text-center">{{item.is_inventoried ? 'Yes' : 'No'}}</td>
                            <td class="text-center">{{item.is_capital_item ? 'Yes' : 'No'}}</td>
                            <td class="text-right font-weight-bold">{{item.stock}}</td>
                            <td>{{item.unit}}</td>
                            <td class="text-right">{{item.value | number: '1.2-2'}}</td>
                            <td>
                                <div class="d-flex justify-content-between ml-3">
                                    <mat-icon class="text-primary" (click)="editItem(item)">edit</mat-icon>
                                    <mat-icon class="text-danger" (click)="deleteItem(item.name, item.id)">delete</mat-icon>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </ng-container>
        <!-- MASTER ITEM LIST END-->

        <!-- DEPARTMENTS -->
        <ng-container *ngIf="currentSetting === 'Department'">
            <div class="department">
                <div class="mb-4 d-flex justify-content-end">
                    <div class="d-flex mr-1">
                        <button mat-stroked-button class="ml-1 pr-border"
                            (click)="addDepartment()">
                            {{'stores.add_department' | translate}}
                        </button>
                    </div>
                    <input type="search" class="col-sm-3 form-control" placeholder="{{'stores.search_department' | translate}} . . ."
                        (input)="filter$departments($event.target.value)">
                </div>


                <div *ngIf="isVisible.addDepartmentForm" class="pop-up-form">

                    <form [formGroup]="addDepartmentForm" (ngSubmit)="submitDepartmentForm(addDepartmentForm)">
                        <div class="form-content">
                            <span class="close-form" (click)="closeForm('addDepartmentForm')">+</span>
                            <div class="row h5 mb-1">
                                {{formAdd ? 'Add Department' : 'Edit Department'}}
                            </div>
                            <mat-divider></mat-divider>
                            <div class="row mt-2">
                                <div class="col-sm-6 form-group">
                                    <label for="">{{'stores.department_name' | translate}}</label>
                                    <input type="text" class="form-control form-control-sm" formControlName="name" maxlength="64">
                                </div>
                                <div class="col-sm-6 form-group">
                                    <label for="">{{'stores.contact_person' | translate}}</label>
                                    <input type="text" class="form-control form-control-sm" formControlName="contact_person" maxlength="64">
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6 form-group">
                                    <label for="">{{'stores.amount_issued' | translate}}</label>
                                    <input type="number" class="form-control form-control-sm" formControlName="amount_issued" min="1">
                                </div>
                            </div>

                            <div class="row">
                                <button type="submit" class="ml-auto pr-border" mat-stroked-button
                                    [disabled]="!addDepartmentForm.valid">
                                    {{'stores.submit' | translate}}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>

                <table class="table table-striped">
                    <thead class="thead-dark">
                        <tr>
                            <th>{{'stores.name' | translate}}</th>
                            <th>{{'stores.contact_person' | translate}}</th>
                            <th>{{'stores.amount_issued' | translate}}</th>
                            <th [ngStyle]="{'width': '85px', 'max-width': '85px'}"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of $departments">
                            <td>{{item.name}}</td>
                            <td>{{item.contact_person}}</td>
                            <td>{{item.amount_issued}}</td>
                            <td>
                                <div class="d-flex justify-content-between">
                                    <mat-icon class="text-primary" (click)="editDepartment(item)">edit</mat-icon>
                                    <mat-icon class="text-danger" (click)="deleteDepartment(item.id, item.name)">delete</mat-icon>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </ng-container>
        <!-- DEPARTMENTS END-->

        <!-- VENDORS -->
        <ng-container *ngIf="currentSetting === 'Vendor'">
            <div class="vendor">
                <div class="mb-4 d-flex justify-content-end">
                    <div class="d-flex mr-1">
                        <button mat-stroked-button class="ml-1 pr-border"
                            (click)="addVendor()">
                            {{'stores.add_vendor' | translate}}
                        </button>
                    </div>
                    <input type="search" class="col-sm-3 form-control" placeholder="{{'stores.search_vendor' | translate}} . . ."
                        (input)="vendorsFilter($event.target.value)">
                </div>


                <div *ngIf="isVisible.addVendorForm" class="pop-up-form">

                    <form [formGroup]="addVendorForm" (ngSubmit)="submitVendorForm(addVendorForm)">
                        <div class="form-content">
                            <span class="close-form" (click)="closeForm('addVendorForm')">+</span>
                            <div class="row h5 mb-1">
                                {{formAdd ? 'Add Vendor' : 'Edit Vendor'}}
                            </div>
                            <mat-divider></mat-divider>
                            <div class="row mt-2">
                                <div class="col-sm-6 form-group">
                                    <label for="">{{'stores.vendor_name' | translate}}</label>
                                    <input type="text" class="form-control form-control-sm" formControlName="name" maxlength="64">
                                </div>
                                <div class="col-sm-6 form-group">
                                    <label for="">{{'stores.contact_person' | translate}}</label>
                                    <input type="text" class="form-control form-control-sm" formControlName="contact_person" maxlength="64">
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6 form-group">
                                    <label for="">{{'stores.gst_num' | translate}}</label>
                                    <input type="text" class="form-control form-control-sm" formControlName="gst_num" maxlength="64">
                                </div>
                                <div class="col-sm-6 form-group">
                                    <label for="">{{'stores.phone' | translate}}</label>
                                    <input type="text" class="form-control form-control-sm" formControlName="phone" maxlength="20">
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6 form-group">
                                    <label for="">{{'stores.address' | translate}}</label>
                                    <textarea type="text" class="form-control form-control-sm" formControlName="address"></textarea>
                                </div>
                                <div class="col-sm-6 form-group">
                                    <label for="">State</label>
                                    <input type="text" class="form-control form-control-sm" formControlName="state" maxlength="100">
                                </div>
                            </div>
            
                            <div class="row">
                                <div class="col-sm-6 form-group">
                                    <label for="">{{'stores.email' | translate}}</label>
                                    <input type="text" class="form-control form-control-sm" formControlName="email_id" maxlength="40">
                                </div>

                                <div class="col-sm-6 form-group">
                                    <label for="">Account No.</label>
                                    <input type="text" class="form-control form-control-sm" formControlName="account_number" maxlength="50">
                                </div>
                            </div>
                            
                            <div class="row">
                                <div class="col-sm-6 form-group">
                                    <label for="">Account Name</label>
                                    <input type="text" class="form-control form-control-sm" formControlName="account_name" maxlength="50">
                                </div>

                                <div class="col-sm-6 form-group">
                                    <label for="">Bank Name</label>
                                    <input type="text" class="form-control form-control-sm" formControlName="bank_name" maxlength="50">
                                </div>
                            </div>  

                            <div class="row">
                                <div class="col-sm-6 form-group">
                                    <label for="">IFSC Code</label>
                                    <input type="text" class="form-control form-control-sm" formControlName="ifsc" maxlength="50">
                                </div>
                            </div>  

                            <div class="row">
                                <button type="submit" class="ml-auto pr-border" mat-stroked-button
                                    [disabled]="!addVendorForm.valid">
                                    {{'stores.submit' | translate}}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>

                <table class="table table-striped">
                    <thead class="thead-dark">
                        <tr>
                            <th>{{'stores.name' | translate}}</th>
                            <th>{{'stores.contact_person' | translate}}</th>
                            <th>{{'stores.gst' | translate}}</th>
                            <th>{{'stores.address' | translate}}</th>
                            <th>{{'stores.phone' | translate}}</th>
                            <th>{{'stores.email' | translate}}</th>
                            <th [ngStyle]="{'width': '85px', 'max-width': '85px'}"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of $vendors">
                            <td>{{item.name}}</td>
                            <td>{{item.contact_person}}</td>
                            <td>{{item.gst_num}}</td>
                            <td class="text-truncate" [ngStyle]="{'max-width': '15ch'}"
                                data-toggle="tooltip" data-placement="top" title="{{item.address}}">
                                {{item.address}}
                            </td>
                            <td>{{item.phone}}</td>
                            <td>{{item.email}}</td>
                            <td>
                                <div class="d-flex justify-content-between">
                                    <mat-icon class="text-primary" (click)="editVendor(item)">edit</mat-icon>
                                    <mat-icon class="text-danger" (click)="deleteVendor(item.id, item.name)">delete</mat-icon>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </ng-container>
        <!-- VENDORS END -->
    </div>

    <div *ngIf="showConfigureUsers" class="pop-up-form">
        <div class="form-content">
            <span class="close-form" (click)="closeConfigureUsers()">+</span>
            <div class="h5 mb-1">
                {{'stores.user_departments' | translate}}
            </div>
            <mat-divider></mat-divider>

           <div class="user-info mt-2">
                <mat-accordion>
                    <mat-expansion-panel *ngFor="let user of userDepts" class="item">
                        <mat-expansion-panel-header #panelUD (click)="user.is_master_user ? panelUD._toggle() : true">
                            <mat-panel-title>
                                {{user.username}}
                            </mat-panel-title>
                            <mat-icon *ngIf="user.is_master_user; else personBlock" (click)="removeMasterUser(user.username); $event.stopPropagation()"
                                class="text-warning mr-3" data-toggle="tooltip" data-placement="top" title="Master User">
                                admin_panel_settings
                            </mat-icon>
                            <ng-template #personBlock>
                                <mat-icon [ngStyle]="{'color': '#aaaaaa'}" (click)="makeMasterUser(user.username); $event.stopPropagation()"
                                    class="mr-3" data-toggle="tooltip" data-placement="top" title="Regular User">
                                    person
                                </mat-icon>
                            </ng-template>
                        </mat-expansion-panel-header>
                        <div class="expansion-content">
                            <div *ngFor="let dept of user.departments" class="sub-item">
                                <span class="pr-4">{{dept.name}}</span>
                                <span class="close-icon" (click)="deleteUserDept(user.username, dept.id)">+</span>
                            </div>
                        </div>
                        <div class="d-flex justify-content-end mt-2 mb-3 expansion-content">
                            <div>
                                <input list="deptList" class="form-control form-control-sm" (click)="$event.target.value = ''"
                                    (change)="onDeptSelectList($event.target.value)" placeholder="{{'stores.select_department' | translate}}">
                                <datalist id="deptList">
                                    <option *ngFor="let dept of departments">{{dept.name}}</option>
                                </datalist>
                            </div>
                            <button mat-stroked-button class="btn-sm border-success ml-1" 
                                (click)="addUserDept(user.username)">
                                {{'stores.add' | translate}}
                            </button>
                        </div>

                    </mat-expansion-panel>
                </mat-accordion>
           </div>

        </div>
    </div>
</div>