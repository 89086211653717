<div>
    <div class="heading d-flex justify-content-between align-items-center">
        <div class="d-flex align-items-center">
            <h1>{{'stayview.title' | translate}}</h1>
        </div>

        <div *ngIf="extraInfo && roomsSummary?.length"
            class="info d-flex justify-content-between align-items-center">
            <div>
                <span>{{'stayview.occupied' | translate}} : </span>
                <span class="cell_info bg_primary">
                    {{roomsSummary[1]?.data[0]}}
                </span>
            </div>

            <div>
                <span>{{'stayview.available' | translate}} : </span>
                <span class="cell_info bg_primary">
                    {{roomsSummary[0]?.data[0]}}
                </span>
            </div>
            <div>
                <span>{{'stayview.complimentary' | translate}} : </span>
                <span class="cell_info bg_primary">
                    {{extraInfo[0]?.complementary}}
                </span>
            </div>
            <div>
                <span>{{'stayview.maintenance' | translate}} : </span>
                <span class="cell_info bg_primary">
                    {{extraInfo[0]?.out_of_order}}
                </span>
            </div>
        </div>

    </div>

    <mat-divider></mat-divider>

    <div class="stayview_wrapper">
        
        <ul class="list_table">

            <li>
                <div class="main_header">
                    <div class="status_colors">
                        <div>
                            <div class="color-code" style="background-color:#f1acac"></div>{{'stayview.assigned' | translate}}
                        </div>
                        <div>
                            <div class="color-code" style="background-color:#1ebc38cb"></div>{{'stayview.checked_in' | translate}}
                        </div>
                        <div>
                            <div class="color-code" style="background-color:#e70000d8"></div>{{'stayview.checking_out' | translate}}
                        </div>
                        <div>
                            <div class="color-code" style="background-color:#023009"></div>{{'stayview.checked_out' | translate}}
                        </div>
                        <div>
                            <div class="color-code" style="background-color:#6d6d6b"></div>{{'stayview.maintenance' | translate}}
                        </div>
                        <div>
                            <div class="color-code" style="background-color:rgb(213 221 63 / 80%)"></div>Complimentary
                        </div>
                    </div>

                    <div class="ml-auto d-flex align-items-center">

                        <div class="mr-2">
                            <button class="view-btn" mat-stroked-button (click)="viewSizeToggle(15)"
                                [ngClass]="{'view-btn-active': viewSize === 15}">
                                |||||
                            </button>
                            <button class="view-btn" mat-stroked-button (click)="viewSizeToggle(7)"
                                [ngStyle]="{'font-weight': 700, 'letter-spacing': '3px'}"
                                [ngClass]="{'view-btn-active': viewSize === 7}">
                                |||
                            </button>
                        </div>

                        <div id="refresh_btn" class="cirlce-btn" matTooltip="Refresh view">
                            <svg role="button" (click)="initializeView()"
                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                              <defs>
                                <style>
                                  .fa-secondary {opacity: .4}
                                </style>
                              </defs>
                              <path class="fa-primary"
                                d="M464 224H320c-17.69 0-32-14.31-32-32s14.31-32 32-32h112V48c0-17.69 14.31-32 32-32s32 14.31 32 32V192C496 209.7 481.7 224 464 224z" />
                              <path class="fa-secondary"
                                d="M396.5 390.2c10.59 14.16 7.75 34.22-6.375 44.81c-39.03 29.28-85.36 44.86-134.2 44.86C132.5 479.9 32 379.4 32 256s100.5-223.9 223.9-223.9c69.15 0 134 32.47 176.1 86.12V160h-48.61c-29.97-39.7-77.25-63.78-127.6-63.78C167.7 96.22 96 167.9 96 256s71.69 159.8 159.8 159.8c34.88 0 68.03-11.03 95.88-31.94C365.9 373.3 385.9 376.1 396.5 390.2z" />
                            </svg>
                        </div>

                        <div class="cirlce-btn" matTooltip="Sync Inventory"
                            [ngStyle]="(!features?.live?.syncInventory || syncing) && {'pointer-events': 'none', 'opacity': '0.6'}">
                            <svg role="button" (click)="syncRmsInventory()"
                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                              <defs>
                                <style>
                                  .fa-secondary {
                                    opacity: .4
                                  }
                                </style>
                              </defs>
                              <path class="fa-primary"
                                d="M449.9 39.96l-48.5 48.53C362.5 53.19 311.4 32 256 32C161.5 32 78.59 92.34 49.58 182.2c-5.438 16.81 3.797 34.88 20.61 40.28c16.97 5.5 34.86-3.812 40.3-20.59C130.9 138.5 189.4 96 256 96c37.96 0 73 14.18 100.2 37.8L311.1 178C295.1 194.8 306.8 223.4 330.4 224h146.9C487.7 223.7 496 215.3 496 204.9V59.04C496 34.99 466.9 22.95 449.9 39.96z" />
                              <path class="fa-secondary"
                                d="M462.4 329.8C433.4 419.7 350.4 480 255.1 480c-55.41 0-106.5-21.19-145.4-56.49l-48.5 48.53C45.07 489 16 477 16 452.1V307.1C16 296.7 24.32 288.3 34.66 288h146.9c23.57 .5781 35.26 29.15 18.43 46l-44.18 44.2C183 401.8 218 416 256 416c66.58 0 125.1-42.53 145.5-105.8c5.422-16.78 23.36-26.03 40.3-20.59C458.6 294.1 467.9 313 462.4 329.8z" />
                            </svg>
                        </div>
                        <div class="cirlce-btn" matTooltip="Previous checkins">
                            <svg (click)="openPrevDayCheckins()"
                                [ngStyle]="!features?.live?.previousCheckin && {'pointer-events': 'none', 'opacity': '0.6'}"
                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" role="button">
                                <path
                                    d="M272 288H448C483.3 288 512 316.7 512 352V416C512 451.3 483.3 480 448 480H64C28.65 480 0 451.3 0 416V352C0 316.7 28.65 288 64 288H240V182.6L203.3 219.3C197.1 225.6 186.9 225.6 180.7 219.3C174.4 213.1 174.4 202.9 180.7 196.7L244.7 132.7C250.9 126.4 261.1 126.4 267.3 132.7L331.3 196.7C337.6 202.9 337.6 213.1 331.3 219.3C325.1 225.6 314.9 225.6 308.7 219.3L272 182.6L272 288zM448 320H64C46.33 320 32 334.3 32 352V416C32 433.7 46.33 448 64 448H448C465.7 448 480 433.7 480 416V352C480 334.3 465.7 320 448 320zM448 32C483.3 32 512 60.65 512 96V160C512 195.3 483.3 224 448 224H400C391.2 224 384 216.8 384 208C384 199.2 391.2 192 400 192H448C465.7 192 480 177.7 480 160V96C480 78.33 465.7 64 448 64H64C46.33 64 32 78.33 32 96V160C32 177.7 46.33 192 64 192H112C120.8 192 128 199.2 128 208C128 216.8 120.8 224 112 224H64C28.65 224 0 195.3 0 160V96C0 60.65 28.65 32 64 32H448z" />
                            </svg>
                        </div>

                        <div id="global_search" class="cirlce-btn">
                            <svg role="button" (click)="searchActive = !searchActive"
                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" matTooltip="Search">
                                <defs>
                                  <style>
                                    .fa-secondary {opacity: .4}
                                  </style>
                                </defs>
                                <path class="fa-primary"
                                  d="M500.3 443.7l-119.7-119.7c-15.03 22.3-34.26 41.54-56.57 56.57l119.7 119.7c15.62 15.62 40.95 15.62 56.57 0C515.9 484.7 515.9 459.3 500.3 443.7z" />
                                <path class="fa-secondary"
                                  d="M207.1 0C93.12 0-.0002 93.13-.0002 208S93.12 416 207.1 416s208-93.13 208-208S322.9 0 207.1 0zM207.1 336c-70.58 0-128-57.42-128-128c0-70.58 57.42-128 128-128s128 57.42 128 128C335.1 278.6 278.6 336 207.1 336z" />
                            </svg>

                            <input #guestSearch
                                type="search" 
                                placeholder="Search..." 
                                [ngStyle]="searchActive && {'display': 'block'}"
                                (input)="searchGuest(guestSearch.value)"
                            />

                            <!-- SEARCH RESULTS START -->
                            <div id="search_results" [ngStyle]="(!searchActive || !filteredGuests?.length) && {'display': 'none'}">
                                <div class="item status_bg" *ngFor="let item of filteredGuests">
                                    
                                    <div class="d-flex flex-column justify-content-between">
                                        <span class="ellipsis" [matTooltip]="item.guestName">
                                            {{item.guestName}}
                                        </span>
   
                                        <small class="ellipsis">
                                            {{item.roomTypeName}}
                                        </small>
                                        
                                        <small class="text-secondary">{{item.source}}</small>
                                    </div>

                                    <div class="d-flex flex-column align-items-end">
                                        <small>{{item.checkIn | date: 'dd MMM'}}  -  {{item.checkOut | date: 'dd MMM'}}</small>

                                        <div>
                                            <button class="btn btn-info btn-sm"
                                            (click)="goToEdit(item.roomAllocationId, item.status, item.bookingId, item.source)">
                                                {{'stayview.edit' | translate}}
                                            </button>

                                            <button *ngIf="!item.status" class="btn btn-info btn-sm"
                                                (click)="assignRoom(item)">
                                                {{'stayview.assign' | translate}}
                                            </button>
                                        </div>

                                        <div *ngIf="item.status"
                                            class="d-flex align-items-center">
                                            <small>{{item.status}}</small>
                                            <small class="ml-1">{{item.roomNum}}</small>
                                        </div>
                          
                                    </div>

                                </div>
                            </div>
                            <!-- SEARCH RESULTS END -->

                        </div>

                        <div class="nav_btns">
                            <svg role="button" (click)="goToReservation()" [ngStyle] ="!features?.live?.createReservation && {'pointer-events': 'none', 'opacity': '0.6'}"
                                matTooltip="Create Reservation"
                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                              <path
                                d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM256 368C269.3 368 280 357.3 280 344V280H344C357.3 280 368 269.3 368 256C368 242.7 357.3 232 344 232H280V168C280 154.7 269.3 144 256 144C242.7 144 232 154.7 232 168V232H168C154.7 232 144 242.7 144 256C144 269.3 154.7 280 168 280H232V344C232 357.3 242.7 368 256 368z" />
                            </svg>

                            <svg role="button" (click)="goToExpense()"
                                [ngStyle]="!features?.live?.expense && {'pointer-events': 'none', 'opacity': '0.6'}"
                                matTooltip="Expenses"
                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                              <path
                                d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM276.8 133.6C276.8 121.7 267.1 112 255.2 112C243.3 112 233.6 121.7 233.6 133.6V149.7C227.5 151.1 221.6 152.1 216.1 155.4C198.1 163.1 181.1 177.6 177.1 199.4C172.9 222.5 182.9 243.3 202.4 255.8C216.6 264.9 235.4 270.2 250.5 274.5C252.7 275.1 254.1 275.7 257.3 276.4C269.2 279.6 281.7 282.1 291.8 289.9C303.5 297.9 297.9 312.1 285.9 316.1C277 320.6 263.4 322.1 246.4 319.6C234.8 317.8 223.2 313.8 211.9 310C209.4 309.2 206.9 308.3 204.4 307.5C193.1 303.7 180.8 309.9 177.1 321.2C173.3 332.5 179.5 344.8 190.8 348.5C204.9 353.1 219.1 357.8 233.6 361L233.6 378.4C233.6 390.3 243.3 400 255.2 400C267.1 400 276.8 390.3 276.8 378.4L276.8 363.2C285.7 362.2 294.2 360.2 302 357C320.8 349.5 336.3 334.8 340.5 312.4C344.7 289.2 335.6 267.5 316.2 254.2C301.3 244 281.4 238.4 265.8 233.9C263.5 233.3 261.1 232.6 258.8 231.1C247.4 228.9 235.5 225.7 225.6 219.4C213.1 211.4 222.2 199.8 233.2 195C245.6 189.7 260.9 188.5 274.1 191C281.2 192.4 288.2 194.4 295.2 196.3C296.7 196.8 298.3 197.2 299.8 197.6C311.3 200.8 323.2 194.1 326.4 182.6C329.6 171.1 322.8 159.2 311.3 155.1C309.5 155.5 307.6 154.9 305.8 154.4C296.2 151.7 286.5 149 276.8 147.8L276.8 133.6z" />
                            </svg>
                        </div>

                        <div class="d-flex justify-content-between align-items-center">
                            <svg (click)="changeDay('prev')" role="button" matTooltip="Previous"
                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                              <path
                                d="M224 480c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25l192-192c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l169.4 169.4c12.5 12.5 12.5 32.75 0 45.25C240.4 476.9 232.2 480 224 480z" />
                            </svg>

                            <input 
                                type="date" 
                                name="staydate" 
                                class="form-control form-control-sm ml-2 mr-2"
                                [ngModel]="startDate"
                                (change)="datePicked($event.target.value)">

                            <svg (click)="changeDay('next')" role="button" matTooltip="Next"
                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                              <path
                                d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z" />
                            </svg>

                        </div>
                    </div>
                </div>
            </li>

            <li class="header_date_row">
                <span class="first_cell">{{'stayview.rooms' | translate}}</span>
                <div class="main_cells_wrapper">
                    <span class="cell" *ngFor="let date of headerDates">
                        {{date | date: ' dd MMM'}} <br>
                        {{date | date: ' EEE '}}
                    </span>
                </div>
            </li>

            <li class="data_row_container">
                <div class="room_type_container" *ngFor="let roomType of roomsData">
                    <div class="room_type_row room_row">
                        <div class="first_cell" (click)="roomType.hidden = !roomType.hidden">
                            <span [matTooltip]="roomType.roomTypeName">{{roomType.roomTypeName}}</span>

                            <svg [@rotate]="roomType.hidden ? 'rotated': 'initial'"
                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                              <path
                                d="M352 352c-8.188 0-16.38-3.125-22.62-9.375L192 205.3l-137.4 137.4c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25C368.4 348.9 360.2 352 352 352z" />
                            </svg>
                        </div>
                        <div class="main_cells_wrapper">
                            <span role="button" 
                                class="cell" 
                                [matMenuTriggerFor]="unassignedGuestsDrop" 
                                *ngFor="let unGuests of roomType.unassignedGuests">
                                <span class="cell_info"
                                    [ngClass]="{'bg_primary': unGuests.guests?.length > 0}">
                                    {{unGuests.guests?.length}}
                                </span>

                                <!-- UNASSIGNED GUESTS MENU START -->

                                <mat-menu #unassignedGuestsDrop="matMenu"> 
                                    <div class="menu-container" *ngFor="let unGuest of unGuests.guests; let ug = index;" 
                                      [ngStyle]="{'background-color': ug%2 == 0 ? '#f2f2f2': '#fff'}">  
                                      <div class="row" style="margin: auto;">
                                        <div class="col-sm-7">
                                          <h4>{{unGuest.guestName}}</h4>
                                        </div>
              
                                        <div class="col-sm-5" style="padding: 0;">
                                          <small>{{unGuest.checkIn | date: 'dd MMM'}}  -  {{unGuest.checkOut | date: 'dd MMM'}}</small>
                                        </div>
                
                                      </div>  
                                      <div class="row" style="margin: auto;">  
                                        <div class="col-sm-4">
                                          <small class="text-secondary">{{unGuest.source}}</small>
                                        </div>
                                        <div class="col-sm-3" style="padding: 0;">
                                          <small style="font-weight: 450;">{{unGuest.total_price | currency: currencyCode}}</small>
                                        </div>
                                        <div class="col-sm-5">
                                          <div class="row">
                                            <div class="col-sm-6" style="padding: 0;">
                                              <button class="btn btn-info btn-sm"
                                              (click)="goToEdit(unGuest.roomAllocationId, 'Unassigned', unGuest.bookingId, unGuest.source)">
                                                {{'stayview.edit' | translate}}
                                              </button>
                                            </div>
                                            <div class="col-sm-6" style="padding: 0;">
                                              <button class="btn btn-info btn-sm"
                                                (click)="assignRoom(unGuest)">
                                                {{'stayview.assign' | translate}}
                                              </button>
                                            </div>
                                          </div>
                                        </div> 
                                      </div>
                                    </div> 
                                </mat-menu>

                                <!-- UNASSIGNED GUESTS MENU START -->
                            </span>

                             
                        </div>
                    </div>
    
                    <div class="rooms_expandable_wrapper" 
                        [@expandCollapse]="roomType.hidden ? 'collapsed': 'expanded'" [@staggerEffect]>

                        <div class="rooms_row room_row" *ngFor="let room of roomType.rooms; let ri = index">
                            <div class="first_cell">
                                <span>{{room.roomNum}}</span>

                                <div class="d-flex justify-content-between align-items-center">
                                    <ng-container *ngIf="room.iotStatus">
                                        <mat-icon *ngIf="room.iotStatus !== 'na'" 
                                            class="d-flex justify-content-center mr-2" 
                                            [ngClass]="{
                                                'iot-status-on': room.iotStatus === 'on',
                                                'iot-status-off': room.iotStatus === 'off',
                                                'iot-status-disconnected': room.iotStatus === 'disconnected'
                                            }"
                                            [ngStyle]="{'font-size': '22px'}"
                                            [matTooltip]="room?.iotStatus"
                                            (click)="showData(room.roomNumId, room.roomNum)"
                                            >bolt
                                        </mat-icon>
                    
                                        <mat-icon *ngIf="room.iotStatus === 'na'" 
                                            class="text-secondary d-flex justify-content-center align-items-center mr-2" 
                                            [ngStyle]="{'font-size': '15px'}"
                                            [matTooltip]="'Not configured'">
                                            close
                                        </mat-icon>
                                    </ng-container>

                                    <div class="optional-icons">
                                        <ng-container *ngFor="let icon of room.icons">
                                            <ng-container *ngIf="icon.isSelected">
                                                <img *ngIf="icon.name === 'Twin'" src="../../../../assets/img/twin-beds.png" alt="">
                                                <img *ngIf="icon.name === 'King'" src="../../../../assets/img/bed.png" alt="">
                                                <img *ngIf="icon.name === 'Smoking'" src="../../../../assets/img/cigarette.png" alt="">
                                                <img *ngIf="icon.name === 'View'" src="../../../../assets/img/photo.png" alt="">
                                            </ng-container>
                                        </ng-container>

                                    </div>
    
                                    <span role="button" *ngIf="room.housekeeping === 'dirty'" (click)="cleanDirtyToggle(room, 'clean')"
                                        matTooltip="Mark as clean">
                                        <img src="../../../../assets/svg/Broom.svg" alt="" width="15px">
                                    </span>
    
                                    <span role="button" *ngIf="room.housekeeping === 'clean'" (click)="cleanDirtyToggle(room, 'dirty')"
                                        matTooltip="Mark as dirty">
                                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" xml:space="preserve">
                                            <g><g transform="translate(0.000000,511.000000) scale(0.100000,-0.100000)"><path d="M4084.7,4956.6c-11.9-31.6-51.4-193.8-89-361.9C3792,3675.1,3493.4,3156.9,2910,2721.8c-278.9-209.6-597.3-350-1202.4-530c-253.1-75.2-255.1-136.5-5.9-207.7c528-152.3,749.5-237.3,1000.7-389.6c445-270.9,767.3-623,988.8-1083.8c136.5-282.8,207.7-500.4,306.5-939.4c77.1-336.2,94.9-395.5,130.5-395.5c29.7,0,51.4,73.2,128.5,417.3c195.8,886,508.3,1429.9,1058.1,1843.2c312.5,235.4,585.4,361.9,1093.7,506.3c397.5,114.7,425.2,164.2,136.4,249.2c-626.9,187.9-810.8,265-1111.4,464.7c-334.2,225.5-623,536-808.9,874.1c-164.2,296.7-267,595.3-379.7,1103.5c-63.3,284.8-93,375.8-120.6,375.8C4114.4,5010,4096.6,4986.3,4084.7,4956.6z"/><path d="M7547.7,655.1c-9.9-25.7-41.5-152.3-69.2-282.8C7340-264.5,7142.2-669.9,6811.9-998.2c-334.2-330.3-597.3-470.7-1232.1-654.6c-180-53.4-203.7-69.2-176-114.7c13.8-21.8,114.7-61.3,278.8-108.8c496.4-140.4,826.7-322.4,1125.3-619c338.2-334.2,518.2-704,672.4-1380.4c37.6-164.1,75.2-306.5,85.1-316.4c29.7-29.7,57.3,47.4,122.6,336.2c146.4,644.7,330.3,1026.4,656.6,1352.7c322.4,322.4,619,478.6,1273.6,668.5c243.3,71.2,219.5,110.7-128.6,209.6c-537.9,152.3-878.1,346.1-1180.7,664.5c-294.7,314.5-486.5,712-611.1,1273.6c-75.1,332.2-89,385.6-112.7,385.6C7575.3,698.7,7557.5,678.9,7547.7,655.1z"/><path d="M1853.9-1130.7c-11.9-43.5-43.5-176-73.2-296.7c-187.9-781.2-599.2-1208.4-1366.6-1416c-170.1-45.5-221.5-77.1-180-110.8c13.8-11.9,124.6-49.4,243.2-83.1c721.9-209.6,1123.3-638.8,1303.3-1396.2c91-375.8,91-379.7,120.6-340.2c13.9,17.8,39.6,110.8,59.3,207.7c106.8,537.9,304.6,905.8,634.8,1178.7c203.7,168.1,397.5,261.1,785.1,377.7c217.5,63.3,223.5,92.9,29.7,148.3c-361.9,104.8-563.6,195.8-761.4,344.1c-249.2,187.9-421.2,415.3-541.9,713.9c-59.3,148.3-176,575.5-176,644.7c0,15.8-11.9,47.5-27.7,67.3C1877.6-1055.5,1873.7-1057.5,1853.9-1130.7z"/></g></g>
                                        </svg>
                                    </span>
                                </div>
                            </div>

                            <div class="main_cells_wrapper"
                                (dragover)="dragOver($event)" (drop)="drop($event, room)">

                                <span class="cell" *ngFor="let d of roomType.unassignedGuests; let ci=index"
                                    (mousedown)="selectCells($event, 'down', ci, ri, room, roomType.roomTypeId, roomType.roomTypeName)"
                                    (mouseover)="selectCells($event, 'over', ci)"
                                    (mouseup)="selectCells($event, 'up', ci)"
                                    [ngStyle]="selectingRoom && {'cursor': 'crosshair'}"
                                    matTooltip="{{selectingRoom ? room.roomNum : ''}}"
                                    [ngClass]="{
                                        'selected-cell': mouseDown && 
                                        selectedCell.cellIndex[ci] && 
                                        selectedCell.selectedRow === ri &&
                                        selectedCell.room.roomTypeId === roomType.roomTypeId
                                    }"
                                    (click)="selectingRoom && selectRoomToAssign(room.roomNum, room.roomNumId, roomType.roomTypeId)"
                                    >
                                    <!-- &nbsp; -->
                                </span>

                                <ng-container *ngFor="let guest of room.allocations; let g = index">
                                    <div
                                        [matMenuTriggerFor]="allocationMenu"
                                        (menuOpened)="allocationMenuOpened(guest)"
                                        class="guest_bar"
                                        draggable="true"
                                        (dragstart)="dragStart($event, guest, room)"
                                        [ngStyle]="guest | guestBarPosition: startDate : viewSize : currentDate"
                                        [ngClass]="{
                                                'red_border': (balances.hasOwnProperty(guest.roomAllocationId) && balances[guest.roomAllocationId] > 0)
                                                || (guest.status === 'Assigned' && guest.paymentMode === 'Pay at Hotel')
                                            }"
                                        >
                                        {{guest.guestName}}

                                        <!-- SOURCE IMAGE -->
                                        <img *ngIf="guest.checkIn !== guest.checkOut && 
                                            sourceIconMapping.hasOwnProperty(guest.source)"
                                            [src]="iconPath + sourceIconMapping[guest.source]" class="source_img">
                                    </div>

                                    <mat-menu #allocationMenu="matMenu">
                                        <app-allocation-menu2
                                            [guestInfo]="guest"
                                            [currentData]="{startDate: startDate, currentDate: currentDate, room: room}"
                                            (refreshView)="initializeView()"
                                            >
                                        </app-allocation-menu2>
                                    </mat-menu>

                                </ng-container>
                            </div>
                        </div>

                    </div>
                </div>

            </li>

            <li class="bottom_section" *ngIf="roomsSummary.length" (mouseenter)="showBottomTable = true"
                (mouseleave)="showBottomTable = false">
                <div class="info_row even" [ngStyle]="showBottomTable && {'visibility': 'hidden'}">
                    <span class="first_cell">{{'stayview.available_rooms' | translate}}</span>
                    <div class="main_cells_wrapper">
                        <span class="cell" *ngFor="let data of roomsSummary[0].data">
                            {{data}}
                        </span>
                    </div>
                </div>
                <div class="expandable_rows" [ngStyle]="showBottomTable && {'height': '100%'}">
                    <div class="info_row" *ngFor="let row of roomsSummary; odd as isOdd; even as isEven"
                        [ngClass]="{'odd': isOdd, 'even': isEven}">
                        <span class="first_cell">{{row.name}}</span>
                        <div class="main_cells_wrapper">
                            <span class="cell" *ngFor="let data of row.data">
                                {{data}}
                            </span>
                        </div>
                    </div>
                </div>
            </li>

        </ul>
    </div>
</div>