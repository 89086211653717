<div class="dialog-box-popup">
    <div class="row mb-1" style="justify-content:space-between;">
        <div><h1>{{'stayview.send_email.voucher_email' | translate}}</h1></div>
        <div><button mat-raised-button class="a-button mr-1" *ngIf = "!groups"
            (click)="viewVoucher('room')">
            {{'stayview.send_email.room_voucher' | translate}}
        </button>
        <button mat-raised-button class="a-button mr-1"
            (click)="viewVoucher('booking')">
            {{'stayview.edit_stay.booking_voucher' | translate}}
        </button></div>
        <hr>
    </div>

    <div class="popup-form">
        <div class="form-row">
            <div class="form-group col-md-12 mb-3">
                <label for="emailIds">{{'stayview.send_email.email_ids' | translate}} <span> ({{'stayview.send_email.emailId1' | translate}} , {{'stayview.send_email.emailId2' | translate}})</span></label>
                <input type="text" id="emailIds" class="form-control form-control-sm" [(ngModel)]="emailIds" name='emailIds'>
            </div>
        </div>
        <div class="form-row" *ngIf = "!groups">
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="voucher" id="bookingVoucher" checked value="bookingVoucher">
                <label class="form-check-label" for="bookingVoucher">
                    {{'stayview.edit_stay.booking_voucher' | translate}}
                </label>
            </div>
            <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="voucher" id="roomVoucher" value="roomVoucher">
                <label class="form-check-label" for="roomVoucher">
                    {{'stayview.send_email.room_voucher' | translate}}
                </label>
            </div>
        </div>
        <div class="row action-buttons mt-4">
            <button type="button" mat-raised-button (click)="closeDialog()">
                {{'stayview.send_email.cancel' | translate}}
            </button>
            <button *ngIf="(submitting == false)" type="submit" mat-raised-button (click)="submit(emailIds)" [disabled]="!emailIds"
                class="confirm-button">
                {{'stayview.send_email.send' | translate}}
            </button>
            <button *ngIf="(submitting == true)" type="submit" mat-raised-button disabled
                class="confirm-button">
                {{'stayview.send_email.sending' | translate}}
            </button>
        </div>
    </div>
</div>

