import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BanquetService {

  constructor(private http: HttpClient) { }

  getProductConfig(hotelId): Observable <any> {
    return this.http.get(`${environment.apiUrl}/hotels/${hotelId}/products/banquets`);
  }

  createReservation(hotelId: string, body): Observable <any> {
    return this.http.post(`${environment.apiUrl}/${hotelId}/banquets/reservation`, body);
  }

  editReservation(hotelId: string, body, reservationId): Observable <any> {
    return this.http.put(`${environment.apiUrl}/${hotelId}/banquets/reservation/${reservationId}`, body);
  }

  getReservation(hotelId, reservationId): Observable <any> {
    return this.http.get(`${environment.apiUrl}/${hotelId}/banquets/reservation/${reservationId}`);
  }

  getBanquetView(hotelId, startDate=null, endDate=null): Observable <any> {
    const httpParams = new HttpParams({fromObject: {startDate, endDate}});
    return this.http.get(`${environment.apiUrl}/${hotelId}/banquets/view`, {params: httpParams});
  }

  getBanquetHalls(hotelId: string): Observable <any> {
    return this.http.get(`${environment.apiUrl}/${hotelId}/banquets/hall-category`);
  }

  changeStatus(hotelId, body): Observable <any> {
    return this.http.put(`${environment.apiUrl}/${hotelId}/banquets/change-status`, body);
  }

}