<div *ngIf="loaded && leadConfig.isEnabled">
    <div class="aio_heading">
      <h2>{{'leads.title' | translate}}</h2>
    </div>
    <mat-divider></mat-divider>
  
    <div class="container-fluid common_content">
      <div class="d-flex justify-content-end">
        <button (click)="createLeads()"
          mat-raised-button
          class="primary_btn">
          {{'leads.create_btn' | translate}}
        </button>
      </div>

      <div class="filters-wrapper">
        <form>
  
          <form class="d-content" [formGroup]="filterForm">
            <div class="d-flex flex-column gap-2">
              <label>{{'leads.type' | translate}}</label>
              <select class="form-control form-control-sm" formControlName="type">
                <option *ngFor="let type of types"
                  [value]="type">
                  {{type}}
                </option>
              </select>
            </div>
            
            <div class="d-flex flex-column gap-2">
              <label>{{'leads.status' | translate}}</label>
              <select class="form-control form-control-sm" formControlName="status">
                <option value="">All</option>
                <option *ngFor="let status of statuses"
                  [value]="status.value">
                  {{status.name}}
                </option>
              </select>
            </div>

            <div class="d-flex flex-column gap-2">
              <label>{{'leads.start_date' | translate}}</label>
              <input type="date" class="form-control form-control-sm" formControlName="startDate">
            </div>
    
            <div class="d-flex flex-column gap-2">
              <label>{{'leads.end_date' | translate}}</label>
              <input type="date" class="form-control form-control-sm" formControlName="endDate">
            </div>

          </form>
          
        </form>
      </div>
  
      <div class="d-flex justify-content-end mb-3">
        <input type="search" id="search" class="form-control form-control-sm" placeholder="Search . . .">
      </div>
  
      <div class="table-container">
        <table class="table table-bordered">
          <thead class="thead-dark">
            <tr>
              <th>{{'leads.creation_date' | translate}}</th>
              <th>{{'leads.type' | translate}}</th>
              <th>{{'leads.start_date' | translate}}</th>
              <th>{{'leads.end_date' | translate}}</th>
              <th>{{'leads.expiration' | translate}}</th>
              <th>{{'leads.status' | translate}}</th>
              <th class="text-right">{{'leads.total' | translate}}</th>
              <th class="text-right">{{'leads.advance' | translate}}</th>
              <th>{{'leads.pax' | translate}}</th>
              <th>{{'leads.guest' | translate}}</th>
              <th>{{'leads.phone' | translate}}</th>
              <th>{{'leads.email' | translate}}</th>
              <th>{{'leads.created_by' | translate}}</th>
              <th>{{'leads.actions' | translate}}</th>
            </tr>
          </thead>
    
          <tbody>
            <tr *ngFor="let lead of filteredLeads">
              <td>{{lead.createdOn | date: 'dd/MM/yy hh:mm a'}}</td>
              <td>{{lead.type}}</td>
              <td>{{lead.startDate | date: 'dd/MM/yy'}}</td>
              <td>{{lead.endDate | date: 'dd/MM/yy'}}</td>
              <td>{{lead.expirationDate | date: 'dd/MM/yy hh:mm a'}}</td>
              <td>
                <div class="lead_status" 
                  [matMenuTriggerFor]="statusMenu"
                  role="button"
                  [ngClass]="{
                    'blocked': lead.status === 'blocked',
                    'confirmed': lead.status === 'confirmed',
                    'cancelled': lead.status === 'cancelled'
                  }"
                  [ngStyle]="{'pointer-events': lead.status === 'confirmed' ? 'none': ''}">
                  {{lead.status}}
                </div>

                <mat-menu #statusMenu="matMenu">
                  
                  <ng-container *ngIf="!(lead.type === 'Rooms' && lead.status === 'cancelled')">
                    <button mat-menu-item *ngIf="lead.status !== 'blocked'" (click)="changeStatus(lead, 'blocked')">
                      {{'leads.block' | translate}}
                    </button>
                    <button mat-menu-item *ngIf="lead.status !== 'confirmed'" (click)="changeStatus(lead, 'confirmed')">
                      {{'leads.confirm' | translate}}
                    </button>
                  </ng-container>

                  <button mat-menu-item *ngIf="lead.status !== 'cancelled'" (click)="changeStatus(lead, 'cancelled')">
                    {{'leads.cancel' | translate}}
                  </button>
                </mat-menu>
              </td>
              <td class="text-right">{{lead.totalAmount | number: '1.2-2'}}</td>
              <td class="text-right">{{lead.advancePayment | number: '1.2-2'}}</td>
              <td>{{lead.pax}}</td>
              <td>{{lead.guestName}}</td>
              <td>{{lead.phone}}</td>
              <td>{{lead.email}}</td>
              <td>{{lead.createdBy}}</td>
              <td>
                <div class="d-flex justify-content-between">
                  <mat-icon
                    *ngIf="lead.status !== 'confirmed'" 
                    (click)="editLead(lead)"
                    class="text-info" 
                    >
                    edit
                  </mat-icon>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
  
    </div>
  </div>
  
<ng-container *ngIf="!leadConfig?.isEnabled && loaded">
  <div class="text-danger mt-3 h4">
    {{'leads.disable_text' | translate}}
  </div>
</ng-container>