import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountsIndexComponent } from './accounts-index/accounts-index.component';
import { MatDividerModule } from '@angular/material/divider';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ReceivablesComponent } from './receivables/receivables.component';
import { PayablesComponent } from './payables/payables.component';
import { TaxesComponent } from './taxes/taxes.component';
import { ReconcilationComponent } from './reconcilation/reconcilation.component';
import { MatIconModule } from '@angular/material/icon';
import { AccReceivePaymentComponent } from './modals/acc-receive-payment/acc-receive-payment.component';
import { AccAddCompanyComponent } from './modals/acc-add-company/acc-add-company.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { AccountsLedgerComponent } from './accounts-ledger/accounts-ledger.component';
import { AddCompanyComponent } from './modals/add-company/add-company.component';
import { VendorComponent } from './modals/vendor/vendor.component';
import { TranslateModule } from '@ngx-translate/core';
import { TaxLedgerComponent } from './taxes/tax-ledger/tax-ledger.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Gstr1Component } from './taxes/gstr1/gstr1.component';
import { HsnSummaryComponent } from './taxes/hsn-summary/hsn-summary.component';
import { B2binvoiceSummaryComponent } from './taxes/b2binvoice-summary/b2binvoice-summary.component';
import { DocSummaryComponent } from './taxes/doc-summary/doc-summary.component';
import { B2cSummaryComponent } from './taxes/b2c-summary/b2c-summary.component';
import { B2binvoiceDetailsComponent } from './taxes/b2binvoice-summary/b2binvoice-details/b2binvoice-details.component';
import { MarkCityLedgerComponent } from './modals/mark-city-ledger/mark-city-ledger.component';
import { MatBadgeModule } from '@angular/material/badge';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { NilInvoiceDetailsComponent } from './taxes/nil-invoice-details/nil-invoice-details.component';


@NgModule({
  declarations: [
    AccountsIndexComponent, 
    ReceivablesComponent, 
    PayablesComponent, 
    TaxesComponent, 
    ReconcilationComponent, 
    AccReceivePaymentComponent,
    AccAddCompanyComponent,
    AccountsLedgerComponent,
    AddCompanyComponent,
    VendorComponent,
    TaxLedgerComponent,
    Gstr1Component,
    HsnSummaryComponent,
    B2binvoiceSummaryComponent,
    DocSummaryComponent,
    B2cSummaryComponent,
    B2binvoiceDetailsComponent,
    MarkCityLedgerComponent,
    NilInvoiceDetailsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatDividerModule,
    MatIconModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatButtonModule,
    TranslateModule,
    MatTooltipModule,
    MatBadgeModule,
    MatFormFieldModule,
    MatSelectModule
  ]
})
export class AccountsModule { }
