import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { HRModuleSevice } from 'src/app/core/hr-module.service';
import { DialogComponent } from 'src/app/_shared/dialog/dialog.component';

@Component({
  selector: 'app-create-employee',
  templateUrl: './create-employee.component.html',
  styleUrls: ['./create-employee.component.scss'],
  animations: [
    trigger('rotate', [
      state('initial', style({ transform: 'rotate(180deg)' })),
      transition('initial <=> final', animate(200)),
    ]),
  ],
})
export class CreateEmployeeComponent implements OnInit {
  fileselected = [];
  AnimationStates = {};
  departments: any;
  idTypes: any;
  paymentModes: any;
  user: any;
  chainId: any;
  hotels = [];
  splitSalaryConfig = [];
  salaryDeductionConfig = [];
  additionalSalaryConfig = [];
  showHotels = false;
  employeeForm: FormGroup;
  isLinear = false;
  items: FormArray;
  mode = 'new';
  showSalary = true;
  employeeSplitSalaryConfig = [];
  employeeAdditionalSalaryConfig = [];
  employeeSalaryDeductionConfig = [];
  splitHeading = '';
  splitCalcType = 'Fixed';
  splitValue = 0;
  additionalHeading = '';
  additionalCalcType = 'Fixed';
  additionalValue = 0;
  deductHeading = '';
  deductCalcType = 'Fixed';
  deductValue = 0;
  deductionNotAttendanceBased = false;

  constructor(
    public dialogRef: MatDialogRef<any>,
    public fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private hrModuleService: HRModuleSevice,
    public matdialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.initializeForm();
    this.AnimationStates['Employee'] = 'initial';
    this.AnimationStates['Salary'] = 'final';
    this.AnimationStates['Leave'] = 'final';
    this.AnimationStates['Regulatory'] = 'final';
    this.AnimationStates['Identity'] = 'final';

    this.user = this.data.user;
    if (this.mode == 'new') {
      this.showSalary = true;
    } else {
      this.showSalary = this.data.showSalary;
    }
  }

  initializeForm() {
    this.employeeForm = this.fb.group({
      name: ['', [Validators.required]],
      department: ['', [Validators.required]],
      reportingManager: [''],
      position: ['', [Validators.required]],
      salary: ['', [Validators.required]],
      joinDate: [''],
      dob: [''],
      accountNum: [''],
      ifscCode: [''],
      bankName: [''],
      accountName: [''],
      nominee: [''],
      paidLeave: [0],
      casualLeave: [0],
      advance: [''],
      advanceInstallment: [''],
      paymentMode: [''],
      procesSalary: [''],
      phone: [''],
      emailId: [''],
      gender: [''],
      addressLine1: [''],
      city: [''],
      state: [''],
      zipCode: [''],
      country: [''],
      employeeStatus: ['Active'],
      exitDate: [''],
      pfNum: [''],
      pfUANNum: [''],
      pfPassword: [''],
      ESINumber: [''],
      comments: [''],
      hotel: [''],
      splitConfig: [],
      additionalConfig: [],
      deductionConfig: [],
      items: this.fb.array([this.createItem()]),
      noSalaryDeduction: [0],
      noSalaryAddition: [0],
    });
    this.fetchRequiredFormInfo();
  }

  // CHANGE ANIMATION STATE
  changeState(val) {
    this.AnimationStates[val] =
      this.AnimationStates[val] === 'initial' ? 'final' : 'initial';
  }

  fetchRequiredFormInfo() {
    this.mode = this.data.mode;
    this.hrModuleService
      .fetchInfoForCreateEmployee(this.data.hotelId)
      .subscribe((success) => {
        this.departments = success.departments;
        this.idTypes = success.identityTypes;
        this.paymentModes = success.paymentsModes;
        this.additionalSalaryConfig = success.additionalSalaryConfig;
        this.splitSalaryConfig = success.splitSalaryConfig;
        this.salaryDeductionConfig = success.salaryDeductionConfig;
        this.hotels = success.hotels;

        for (var id in this.hotels) {
          if (this.hotels[id]['hotelId'] == this.data.hotelId) {
            this.employeeForm.patchValue({
              hotel: this.hotels[id]['name'],
            });
          }
        }
        if (this.data.employeeId) {
          this.hrModuleService
            .fetchAllInfoOfEmployees(this.data.hotelId, this.data.employeeId)
            .subscribe((employeedata) => {
              var processSalary = 'False';
              this.showHotels = true;
              if (employeedata[0].processedSalary == 'Yes') {
                processSalary = 'True';
              } else {
                processSalary = 'False';
              }
              this.employeeSplitSalaryConfig =
                employeedata[0].employeeSplitSalaryConfig;
              this.employeeAdditionalSalaryConfig =
                employeedata[0].employeeAdditionalSalaryConfig;
              this.employeeSalaryDeductionConfig =
                employeedata[0].employeeSalaryDeductionConfig;
              this.employeeForm.patchValue({
                name: employeedata[0].name,
                department: employeedata[0].department,
                reportingManager: employeedata[0].reportingManager,
                position: employeedata[0].position,
                salary: employeedata[0].salary,
                joinDate: employeedata[0].joinDate,
                dob: employeedata[0].dob,
                accountNum: employeedata[0].accountNum,
                ifscCode: employeedata[0].ifscCode,
                bankName: employeedata[0].bankName,
                accountName: employeedata[0].accountName,
                nominee: employeedata[0].nominee,
                paidLeave: employeedata[0].paidLeave,
                casualLeave: employeedata[0].casualLeave,
                advance: employeedata[0].advanceBalance,
                advanceInstallment: employeedata[0].advanceInstallment,
                paymentMode: employeedata[0].paymentMode,
                procesSalary: processSalary,
                phone: employeedata[0].phone,
                emailId: employeedata[0].emailId,
                gender: employeedata[0].gender,
                addressLine1: employeedata[0].address.line1,
                city: employeedata[0].address.city,
                state: employeedata[0].address.state,
                zipCode: employeedata[0].address.zipCode,
                country: employeedata[0].address.country,
                employeeStatus: employeedata[0].employeeStatus,
                exitDate: employeedata[0].exitDate,
                pfNum: employeedata[0].pfNum,
                pfUANNum: employeedata[0].pfUANNum,
                pfPassword: employeedata[0].pfPassword,
                ESINumber: employeedata[0].esiNum,
                comments: employeedata[0].comments,
                splitConfig: employeedata[0].employeeSplitSalaryConfig,
                additionalConfig:
                  employeedata[0].employeeAdditionalSalaryConfig,
                deductionConfig: employeedata[0].employeeSalaryDeductionConfig,
                noSalaryDeduction: employeedata[0].noSalaryDeduction,
                noSalaryAddition: employeedata[0].noSalaryAddition,
              });

              this.fileselected = employeedata[0].photoIds;
              this.items = this.employeeForm.get('items') as FormArray;
              this.items.removeAt(0);
              employeedata[0].idDetails.forEach((x) => {
                this.items.push(
                  this.fb.group({
                    idName: x.idName,
                    file: x.file,
                    idDetail: x.idDetail,
                  })
                );
              });
            });
        }

        if (success.chainId) {
          this.chainId = success.chainId;
        } else {
          this.chainId = '';
        }
      });
  }

  handleInput(event, row) {
    var filelength = Object.keys(event.files).length;
    var tempdict = {};
    for (var i = 0; i < filelength; i++) {
      this.hrModuleService
        .uploadIDFiles(this.data.hotelId, event.files[i])
        .subscribe(
          (success) => {
            tempdict[row] = success;

            this.items = this.employeeForm.get('items') as FormArray;
            this.items.value[row]['file'] = success.url;
            this.fileselected.push(success);
          },
          (err) => {
            console.log(err);
          }
        );
    }
  }

  removeImage(url, row) {
    this.items = this.employeeForm.get('items') as FormArray;
    this.items.value[row]['file'] = '';
    this.fileselected.splice(
      this.fileselected.findIndex(function (i) {
        return i === url;
      }),
      1
    );
  }

  createItem(): FormGroup {
    return this.fb.group({
      idName: '',
      file: '',
      idDetail: '',
    });
  }

  addItem(): void {
    this.items = this.employeeForm.get('items') as FormArray;
    this.items.push(this.createItem());
  }

  removeItem(i): void {
    this.items = this.employeeForm.get('items') as FormArray;
    this.items.removeAt(i);
  }

  addSplit(): void {
    this.employeeSplitSalaryConfig.push({
      heading: this.splitHeading,
      calculationType: this.splitCalcType,
      value: this.splitValue,
    });
  }

  removeSplit(index): void {
    this.employeeSplitSalaryConfig.splice(index, 1);
  }

  addAdditionalConfig(): void {
    this.employeeAdditionalSalaryConfig.push({
      heading: this.additionalHeading,
      calculationType: this.additionalCalcType,
      value: this.additionalValue,
    });
  }

  removeAdditionalConfig(index): void {
    this.employeeAdditionalSalaryConfig.splice(index, 1);
  }

  addDeductionConfig(notAttendanceBased): void {
    this.employeeSalaryDeductionConfig.push({
      heading: this.deductHeading,
      calculationType: this.deductCalcType,
      deductionNotAttendanceBased: notAttendanceBased,
      value: this.deductValue,
    });
  }

  removeDeductionConfig(index): void {
    this.employeeSalaryDeductionConfig.splice(index, 1);
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onSubmit(form) {
    const dialogBody = {
      title: 'Submission',
      body: 'Are you sure you want to Submit ?',
      confirmBtn: 'Submit',
      cancelBtn: 'Cancel',
    };
    const dialogRef = this.matdialog.open(DialogComponent, {
      data: dialogBody,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'true') {
        this.employeeForm.patchValue({
          splitConfig: this.employeeSplitSalaryConfig,
        });
        this.employeeForm.patchValue({
          additionalConfig: this.employeeAdditionalSalaryConfig,
        });
        this.employeeForm.patchValue({
          deductionConfig: this.employeeSalaryDeductionConfig,
        });

        if (this.mode === 'modify') {
          for (var id in this.hotels) {
            if (this.hotels[id]['name'] == form.value.hotel) {
              this.employeeForm.patchValue({
                hotel: this.hotels[id]['hotelId'],
              });
            }
          }

          this.hrModuleService
            .updateEmployee(
              form.value,
              this.data.hotelId,
              this.data.employeeId,
              this.data.user
            )
            .subscribe(
              (success) => {
                var WarningDialog = {
                  title: 'Done !',
                  body: `You Have successfully modified the data`,
                  cancelBtn: 'Dismiss',
                };
                const dialogRef = this.matdialog.open(DialogComponent, {
                  data: WarningDialog,
                });
                dialogRef.disableClose = true;
                dialogRef.afterClosed().subscribe((result) => {
                  if (result === 'false') {
                    this.matdialog.closeAll();
                    window.location.reload();
                  }
                });
              },
              (err) => {
                const WarningDialog = {
                  title: 'ERROR !',
                  body: 'Something went wrong!!',
                  cancelBtn: 'Dismiss',
                };
                const dialogRef = this.matdialog.open(DialogComponent, {
                  data: WarningDialog,
                });
                dialogRef.disableClose = true;
                dialogRef.afterClosed().subscribe((result) => {
                  if (result === 'false') {
                    this.matdialog.closeAll();
                    window.location.reload();
                  }
                });
              }
            );
        } else {
          this.hrModuleService
            .addEmployee(form.value, this.data.hotelId, this.chainId)
            .subscribe(
              (success) => {
                var WarningDialog = {
                  title: 'Done !',
                  body: `You Have successfully modified the data`,
                  cancelBtn: 'Dismiss',
                };
                const dialogRef = this.matdialog.open(DialogComponent, {
                  data: WarningDialog,
                });
                dialogRef.disableClose = true;
                dialogRef.afterClosed().subscribe((result) => {
                  if (result === 'false') {
                    this.matdialog.closeAll();
                    window.location.reload();
                  }
                });
              },
              (err) => {
                const WarningDialog = {
                  title: 'ERROR !',
                  body: 'Something went wrong!!',
                  cancelBtn: 'Dismiss',
                };
                const dialogRef = this.matdialog.open(DialogComponent, {
                  data: WarningDialog,
                });
                dialogRef.disableClose = true;
                dialogRef.afterClosed().subscribe((result) => {
                  if (result === 'false') {
                    this.matdialog.closeAll();
                    window.location.reload();
                  }
                });
              }
            );
        }
      }
    });
  }
}
