<div class="container">
  <div class="heading">
    <h1 *ngIf="!(data.mode === 'view'); else viewBlock">
      {{ data.mode | titlecase }} {{ "pos.order" | translate }}
      <span *ngIf="data.complimentary" id="complimentary_text"
        >(Complimentary)</span
      >
    </h1>
    <ng-template #viewBlock>
      <h1>{{ "pos.invoice" | translate }} - {{ data.invoiceNum }}</h1>
    </ng-template>

    <div class="right-child">
      <div *ngIf="data.mode !== 'new'" class="d-flex align-items-center">
        <span >GST : </span>
        <input type="text" class="form-control" placeholder="GST No." [(ngModel)]="gstNum">
        <mat-icon matTooltip="Save" (click)="editGst()">check_circle</mat-icon>
      </div>

      <button mat-stroked-button (click)="openAddService()">
        {{ "pos.add_new_service" | translate }}
      </button>
    </div>
  
  </div>

  <mat-divider></mat-divider>

  <div class="form-container">
    <form [formGroup]="newOrderForm" (ngSubmit)="onSubmit(newOrderForm)">
      <div class="row">

        <div class="col-sm-4">
          <label>{{ "pos.table" | translate }}</label>

          <ng-select class="form-control" formControlName="tableId" (change)="setTable($event)">
            <ng-option 
              *ngFor="let table of tables"
              [value]="table.id">
              {{table.tableNum}}
            </ng-option>
         </ng-select>
  
        </div>

        <div class="col-sm-4">
          <label>{{ "pos.staff" | translate }}</label>
          <input
            type="text"
            class="form-control"
            [readonly]="data.mode === 'view' || data.mode === 'bill'"
            formControlName="staff"
          />
        </div>

        <div class="col-sm-4">
          <label for="Details">{{ "pos.details" | translate }}</label>
          <input
            type="text"
            class="form-control"
            id="details"
            [readonly]="data.mode === 'view' || data.mode === 'bill'"
            formControlName="details"
          />
        </div>

      </div>
      <div *ngIf="data.mode === 'new' || data.mode === 'edit'" class="row mt-4">
        <div class="col-sm-3">
          <label for="services">{{ "pos.service_category" | translate }}</label>
          <select
            class="form-control"
            id="category"
            name="servicecategory"
            (change)="filterServices($event.target.value); serviceName = ''"
            [(ngModel)]="category"
            [ngModelOptions]="{ standalone: true }"
          >
            <option *ngFor="let category of serviceCategories">
              {{ category }}
            </option>
          </select>
        </div>
        <div class="col-sm-3">
          <label for="services">{{ "pos.service" | translate }}</label>
          <input
            list="servicelist"
            class="form-control"
            (click)="serviceName = ''"
            (change)="getServiceConfig($event.target.value); quantity = 1"
            [(ngModel)]="serviceName"
            [ngModelOptions]="{ standalone: true }"
          />
          <datalist id="servicelist">
            <option *ngFor="let name of serviceNames" [value]="name">
              {{ name }}
            </option>
          </datalist>
        </div>
        <div class="col-sm-1">
          <label for="services">{{ "pos.qty" | translate }}</label>
          <input
            type="number"
            min="1"
            class="form-control"
            id="Qty"
            (change)="quantityFun(quantity)"
            [(ngModel)]="quantity"
            [ngModelOptions]="{ standalone: true }"
          />
        </div>

        <ng-container *ngIf="!data?.complimentary">
          <div
            class="col-sm-1"
            [ngStyle]="{ flex: '0 0 9.666667%', 'max-width': '9.666667%' }"
          >
            <label for="services">{{ "pos.amount" | translate }}</label>
            <input
              type="number"
              class="form-control"
              id="serviceamount"
              [(ngModel)]="serviceAmount"
              [ngModelOptions]="{ standalone: true }"
              [readonly]="taxInclusive === 'Yes' || isEditable === 'No'"
              (input)="amountChange(serviceAmount)"
            />
          </div>
          <div
            class="col-sm-1"
            [ngStyle]="{ flex: '0 0 9.666667%', 'max-width': '9.666667%' }"
          >
            <label for="services">{{ "pos.tax" | translate }}</label>
            <input
              type="number"
              class="form-control"
              id="tax"
              [(ngModel)]="allTax.total"
              [ngModelOptions]="{ standalone: true }"
              readonly
            />
          </div>
          <div
            class="col-sm-2"
            [ngStyle]="{ flex: '0 0 12.666667%', 'max-width': '12.666667%' }"
          >
            <label for="services">{{ "pos.total" | translate }}</label>
            <input
              type="number"
              class="form-control"
              id="totalmount"
              [(ngModel)]="totalAmount"
              [ngModelOptions]="{ standalone: true }"
              [readonly]="taxInclusive === 'No' || isEditable === 'No'"
              (input)="calculateAmount(totalAmount)"
            />
          </div>
        </ng-container>

        <div class="col-sm-1">
          <span id="add" (click)="addNewService()">+</span>
        </div>
      </div>

      <div
        *ngIf="data.mode === 'edit' && !data?.complimentary"
        id="selected_actions"
        class="mt-3"
      >
        <button
          (click)="transferServicesClick()"
          mat-raised-button
          matTooltip="Transfer to an existing order"
          type="button"
        >
          {{ "pos.transfer" | translate }}
        </button>
        <button
          (click)="transferServicesClick(true)"
          mat-raised-button
          matTooltip="Transfer to new order"
          type="button"
        >
          {{ "pos.transfer_to_new_order" | translate }}
        </button>
      </div>

      <!-- LIST OF SERVICES USED -->
      <div class="table-box mt-4">
        <div class="scroll-table-container mt-2">
          <table class="table">
            <thead class="thead-dark">
              <tr>
                <th *ngIf="data.mode === 'edit' || data.mode === 'bill'">
                  <input
                    type="checkbox"
                    class="form-control form-control-sm"
                    (click)="selectAll($event.target.checked)"
                  />
                </th>
                <th>{{ "pos.service_name" | translate }}</th>
                <th class="text-right">{{ "pos.rate" | translate }}</th>
                <th class="text-center">{{ "pos.qty" | translate }}</th>
                <th class="text-right">{{ "pos.amt" | translate }}</th>
                <th class="text-right" *ngIf="discountApplied">
                  {{ "pos.disc_amt" | translate }}
                </th>
                <th class="text-right">{{ "pos.tax" | translate }}</th>
                <th class="text-right">{{ "pos.total" | translate }}</th>
                <th *ngIf="data.mode === 'edit' || data.mode === 'new'"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let service of servicesUsed; let i = index">
                <td *ngIf="data.mode === 'edit' || data.mode === 'bill'">
                  <input
                    type="checkbox"
                    class="form-control form-control-sm"
                    [(ngModel)]="service.checked"
                    [ngModelOptions]="{ standalone: true }"
                    [attr.disabled]="!service.service_used_id ? '' : null"
                  />
                </td>
                <td class="ellip">
                  {{ service.service_name }}
                </td>
                <td class="text-right">
                  {{service.service_amount / service.quantity | currency : data.currencyCode}}
                </td>
                <td class="text-center">{{ service.quantity }}</td>
                <td class="text-right">
                  {{ service.service_amount | currency : data.currencyCode }}
                </td>
                <td class="text-right" *ngIf="discountApplied">
                  {{ service.discounted_amount | currency : data.currencyCode }}
                </td>
                <td class="text-right">
                  {{ service.tax.total | currency : data.currencyCode }}
                </td>
                <td class="text-right">
                  {{ service.total_amount | currency : data.currencyCode }}
                </td>
                <td
                  *ngIf="data.mode === 'edit' || data.mode === 'new'"
                  class="action"
                >
                  <mat-icon
                    [hidden]="data.showDeleteServiceOption == false"
                    (click)="deleteService(i, service.service_used_id, service.total_amount, service.service_name)"
                    class="text-danger"
                  >
                    delete
                  </mat-icon>
                </td>
              </tr>
              <tr [ngStyle]="{ 'background-color': '#ececec' }">
                <td *ngIf="data.mode === 'edit' || data.mode === 'bill'"></td>
                <td>{{ "pos.total" | translate }} :</td>
                <td></td>
                <td class="text-center">
                  {{ totalObj.quantity }}
                </td>
                <td class="text-right">
                  {{ totalObj.amount | currency : data.currencyCode }}
                </td>
                <td class="text-right" *ngIf="discountApplied">
                  {{ totalObj.discountAmt | currency : data.currencyCode }}
                </td>
                <td class="text-right">
                  {{ totalObj.tax | currency : data.currencyCode }}
                </td>
                <td class="text-right">
                  {{ totalObj.totalAmount | currency : data.currencyCode }}
                </td>
                <td></td>
              </tr>

            </tbody>
          </table>
        </div>

        <table
          class="table mt-2"
          *ngIf="payments?.length > 0"
          [ngStyle]="{ width: '40%' }"
        >
          <thead class="thead-dark">
            <tr>
              <th>{{ "pos.type_of_payment" | translate }}</th>
              <th>{{ "pos.comments" | translate }}</th>
              <th>{{ "pos.amount" | translate }}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            
            <tr *ngFor="let pay of payments">
              <td>{{ pay.type_of_payment }}</td>
              <td>{{ pay.comments }}</td>
              <td>{{ pay.amount }}</td>
              <td class="action">
                <mat-icon *ngIf="pay.type_of_payment !== 'Bill to Room'"
                  class="text-danger"
                  matTooltip="Delete payment"
                  (click)="deletePayment(pay)">
                  delete
                </mat-icon>
              </td>
            </tr>
      
          </tbody>
        </table>
      </div>
      <div class="row mt-3" [ngStyle]="{ 'align-items': 'center' }">
        <ng-container *ngIf="(data?.mode === 'edit' || data.mode === 'bill') && !data?.complimentary">
          <div class="col-sm-2">
            <input
              type="number"
              class="form-control"
              min="1"
              [(ngModel)]="discount"
              [ngModelOptions]="{ standalone: true }"
              placeholder="Enter discount"
            />
          </div>
          <div class="col-sm-2">
            <select
              name="discount-type"
              class="form-control"
              [(ngModel)]="discountType"
              [ngModelOptions]="{ standalone: true }"
            >
              <option value="%">{{ "pos.percentage" | translate }}</option>
              <option value="flat">{{ "pos.flat" | translate }}</option>
            </select>

          </div>

          <div class="col-sm-2">
            <select class="form-control" 
              [(ngModel)]="discountAppliedOn" 
              [ngModelOptions]="{ standalone: true }">
              <option value="preTax">Pre Tax Amount</option>
              <option value="postTax">Total Amount</option>
            </select>
          </div>
          <button
            mat-raised-button
            type="button"
            class="active small-btn"
            (click)="applyDiscount()"
          >
            {{ "pos.apply" | translate }}
          </button>

          <button
            class="active small-btn"
            [ngClass]="{'disabled-btn': features?.live?.hasOwnProperty('undoDiscounts') && !features?.live?.undoDiscounts}"
            mat-raised-button 
            (click)="getDiscountLog()">
            Undo
          </button>
        </ng-container>
          
      </div>
      <div class="row mt-2" *ngIf="data.mode === 'view' || data.mode === 'edit'">
        <div class="col-sm-3">
          <select
            class="form-control"
            name="printer"
            id="printer"
            [(ngModel)]="selectedPrinter"
            [ngModelOptions]="{ standalone: true }"
            (change)="changePrinter(selectedPrinter)"
          >
            <option disabled [ngValue]="undefined">
              {{ "pos.select_printer" | translate }}
            </option>
            <option *ngFor="let printer of printers" [ngValue]="printer">
              {{ printer }}
            </option>
          </select>
        </div>
      </div>
      <div class="row mt-4 action-buttons">
        <button type="button" mat-raised-button (click)="closeDialog()">
          <ng-container *ngIf="!(data.mode === 'view'); else closeBlock">{{
            "pos.cancel" | translate
          }}</ng-container>
          <ng-template #closeBlock>{{ "pos.close" | translate }}</ng-template>
        </button>
        <button
          *ngIf="data.mode === 'view' || data.mode === 'edit'"
          type="button"
          mat-raised-button
          (click)="printOrder()"
          class="active"
          [disabled]="!selectedPrinter"
        >
          {{ "pos.print" | translate }}
        </button>
        <button
          *ngIf="data.mode === 'new' || data.mode === 'edit'"
          type="submit"
          mat-raised-button
          (click)="closeDialog()"
          class="active"
        >
          {{ "pos.submit" | translate }}
        </button>
        <button
          *ngIf="data.mode === 'bill'"
          type="button"
          mat-raised-button
          (click)="billOrder()"
          class="active"
        >
          {{ "pos.bill" | translate }}
        </button>
        <button
          *ngIf="data.mode === 'view'"
          type="button"
          mat-raised-button
          (click)="emitViewBill()"
          class="active"
        >
          {{ "pos.view_bill" | translate }}
        </button>

        <button *ngIf="data?.currentOrder?.invoice?.e_invoice"
          type="button" mat-raised-button
          class="active"
          (click)="openLink(data.currentOrder.invoice.e_invoice)">
          {{ "pos.e_invoice" | translate }}
        </button>
      </div>
    </form>
  </div>

  <div class="loading_overlay" *ngIf="transferring">
    <div class="d-flex justify-content-center align-items-center">
      {{ "pos.transfering" | translate }}...
    </div>
  </div>
</div>

