import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PosComponent } from './pos.component';
import { MatDividerModule } from '@angular/material/divider';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { OrderComponent } from './order/order.component';
import { MatIconModule } from '@angular/material/icon';
import { BillComponent } from './bill/bill.component';
import { PosReportComponent } from './pos-report/pos-report.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TransferOrderComponent } from './transfer-order/transfer-order.component';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { PosTableViewComponent } from './pos-table-view/pos-table-view.component';
import { RouterModule } from '@angular/router';
import { MatMenuModule } from '@angular/material/menu';
import { PosCreateOrderComponent } from './pos-create-order/pos-create-order.component';
import { PipesModule } from 'src/app/_shared/pipes/pipes.module';
import { PosOrderViewComponent } from './pos-order-view/pos-order-view.component';



@NgModule({
  declarations: [PosComponent, OrderComponent, BillComponent, PosReportComponent, TransferOrderComponent, PosTableViewComponent, PosCreateOrderComponent, PosOrderViewComponent],
  imports: [
    CommonModule, MatDividerModule, ReactiveFormsModule, MatButtonModule, MatIconModule, FormsModule,
    MatTooltipModule, MatDialogModule, TranslateModule, NgSelectModule, RouterModule, MatMenuModule, PipesModule
  ]
})
export class PosModule { }
