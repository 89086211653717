import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserService } from 'src/app/core/user.service';
import { DialogComponent } from 'src/app/_shared/dialog/dialog.component';
import { finalize } from 'rxjs/operators';
import { EditStayComponent } from '../../stayview/edit/edit-stay/edit-stay.component';

@Component({
  selector: 'app-previous-day-checkins',
  templateUrl: './previous-day-checkins.component.html',
  styleUrls: ['./previous-day-checkins.component.scss']
})
export class PreviousDayCheckinsComponent implements OnInit {

  allocations: any = [];
  loaded = false;
  availableRooms: {roomNumId: string, roomNum: string}[] = [];
  availableRoomsCache: any = {};
  roomsLoading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userService: UserService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<PreviousDayCheckinsComponent>
  ) { }

  ngOnInit(): void {
    this.getCheckins();
  }

  getCheckins() {
    this.userService.getPrevDayCheckIns(this.data?.hotelId)
    .pipe(finalize(() => this.loaded = true))
    .subscribe(res => {
      this.allocations = res?.data;
    });
  }

  checkIn(allocId, bookingId, guestName: string) {
    const body = {
      roomAllocationId: allocId, 
      hotelId: this.data?.hotelId, 
      bookingId, 
      user: localStorage.getItem('user'),
      previousCheckin: {previousCheckin: true},
      allocData: {
        guest_name: guestName,
      }
    };
    this.userService.checkIn(body, this.data.hotelId).subscribe( success => {
      if (success && 'error' in success) {
        const WarningDialog = { title: 'ERROR !', body: `${success.error}`, cancelBtn: 'Dismiss'};
        this.dialog.open(DialogComponent, {data: WarningDialog});
      }
      this.dialogRef.close({refresh: true});
    },
      err => {});
  }

  assignGuest(room, guestInfo) {
    const body = {
      title: 'Confirm',
      body: `Are you sure you want to checkin guest to room - ${room.roomNum} ?`,
      confirmBtn: 'Yes',
      cancelBtn: 'Cancel'
    };
    const dialogRef = this.dialog.open(DialogComponent, {data: body});
    dialogRef.afterClosed().subscribe(res => {
      if (res === 'true') {
        this.dialogRef.close({assign: {room, guestInfo}});
      }
    });
  }

  getAvailableRooms(startDate, endDate, roomId) {
    this.availableRooms = [];
    const key = JSON.stringify({startDate, endDate, roomId});
    if (this.availableRoomsCache[key]) {
      this.availableRooms = this.availableRoomsCache[key];
      return;
    }

    this.roomsLoading = true;
    this.userService.getAvailableRooms(this.data?.hotelId, startDate, endDate, roomId)
    .pipe(finalize(() => this.roomsLoading = false))
    .subscribe(res => {
      this.availableRoomsCache[key] = res?.data;
      this.availableRooms = res?.data;
    });
  }

  openEditStay(allocId, status, bookingId, source){
    const hotelId = this.data?.hotelId;
    var invoiceId;
    this.dialog.open(EditStayComponent, {data : {allocId, hotelId, status, invoiceId, bookingId, source, queryParams:{ user: localStorage.getItem('user')} }});
  }
}
