<div class="container">
    <div class="heading">
        <h1>{{'stores.consume_item' | translate}}</h1>
        <mat-divider></mat-divider>
    </div>

    <div class="content mt-3">
        <form [formGroup]="consumeItemForm" (ngSubmit)="onSubmit(consumeItemForm)">
            <div class="row mb-3">
                <div class="col-sm-3">
                    <label for="Department">{{'stores.department' | translate}}</label>
                    <select class="form-control form-control-sm" formControlName="dept_id">
                        <option value="" hidden>{{'stores.select_department' | translate}}</option>
                        <option *ngFor="let dept of data.departments" 
                            [value]="dept.id"> {{dept.name}}
                        </option>
                    </select>
                </div>
                <div class="col-sm-3">
                    <label for="category">{{'stores.category' | translate}}</label>
                    <select class="form-control form-control-sm" [(ngModel)]="category" (change)="filterSubCategories(category); filterMasterItems();"
                        [ngModelOptions]="{standalone: true}">
                        <option value="">All</option>
                        <option *ngFor="let cat of data.categories">
                            {{cat.name}}
                        </option>
                    </select>
                </div>
                <div class="col-sm-3">
                    <label for="subcategory">{{'stores.sub_category' | translate}}</label>
                    <select class="form-control form-control-sm" [(ngModel)]="subCategory" (change)="filterMasterItems();"
                        [ngModelOptions]="{standalone: true}">
                        <option value="">All</option>
                        <option *ngFor="let cat of subCategories">
                            {{cat.name}}
                        </option>
                    </select>
                </div>
            </div>
            <div *ngIf="data.mode !== 'view'" class="row form-group mb-3">
                <div class="col-sm-3">
                    <label for="Item Name">{{'stores.select_item' | translate}}</label>
                    <ng-select (change)="selectItem($event)" class="form-control form-control-sm">
                        <ng-option *ngFor="let item of masterItems" [value]="item">{{item.name}}</ng-option>
                     </ng-select>
                </div>
                <div class="col-sm-2">
                    <label for="Quantity">{{'stores.quantity' | translate}}</label>
                    <input type="number" class="form-control form-control-sm" formControlName="quantity">
                </div>
                <div class="col-sm-1">
                    <label for="unit">{{'stores.unit' | translate}}</label>
                    <span>{{selectedItem?.unit}}</span>
                </div>
                <div class="col-sm-4">
                    <label for="Comments">{{'stores.comments' | translate}}</label>
                    <input type="text" class="form-control form-control-sm" formControlName="comments">
                </div>
            </div>
    
            <div class="bottom-row">
                <div class="d-flex align-items-end mr-2">
                    <button  mat-stroked-button type="submit" class="pr-border" [disabled]="!consumeItemForm.valid">
                        {{'stores.submit' | translate}}
                    </button>
                </div>
                <div class="d-flex align-items-end">
                    <button type="button"
                        mat-stroked-button class="border-danger" (click)="closeDialog()">
                        {{'stores.cancel' | translate}}
                    </button>
                </div>
            </div>
        </form>
        
    </div>
</div>