<!-- <app-header (toggleSideBarValue)="sideBarToggler()"></app-header> -->

<mat-sidenav-container>
    <!-- <mat-sidenav class="text-center" mode="{{modeVar}}" [(opened)]="opened">
        <app-sidebar></app-sidebar>
    </mat-sidenav> -->
    <mat-sidenav-content>     
        <router-outlet (activate)="changeOfRoutes()"></router-outlet>
        <app-spinner *ngIf="isLoading"></app-spinner>
    </mat-sidenav-content>
</mat-sidenav-container>