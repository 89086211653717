<div *ngIf = "enabled; else elseBlock;">
    <div class="heading flex-space-bw">
        <h2>{{ "hr.hr_module" | translate }}</h2>
    </div>
    <mat-divider></mat-divider>
    
    <div class="container" >
        <div class="tabs">
            <ul>
                <ng-container *ngFor="let tab of tabItems; let i = index">
                    <li
                        (click)="changeCurrentTab(i);"
                        [ngClass]="{'active-tab': currentTab === tab.key}">
                        {{tab.name}}
                    </li>
                </ng-container>
            </ul>
        </div>

        <ng-container>
            <div class="content">
                <ng-container *ngIf="currentTab === 'employees'">
                    <app-employees [hotelId]="hotelId" [user]="currentUser" [groupId]="groupId" [showSalary]="showSalary" [showEmployees]="showEmployees"></app-employees>
                </ng-container>

                <ng-container *ngIf="currentTab === 'attendance'">
                    <app-attendance [hotelId]="hotelId" [user]="currentUser" [showAttendance]="showAttendance" [allowSalaryProcessing]="allowSalaryProcessing"></app-attendance>
                </ng-container>

                <ng-container *ngIf="currentTab === 'salary'">
                    <app-salary [hotelId]="hotelId" [user]="currentUser" [groupId]="groupId" [showSalary]="showSalary"></app-salary>
                </ng-container>

                
            </div>
        </ng-container>
    </div>
</div>
<ng-template #elseBlock>
    <h1>{{ "hr.hr_text" | translate }}</h1>
</ng-template>
