<div class="primary_modal_wrapper">
    <div class="modal_heading">
        <h1>Payment Links</h1>
    </div>

    <mat-divider></mat-divider>

    <div class="modal_body">

        <ng-container *ngIf="data.links?.length; else noPaymentLinks">
            <table class="table table-sm table-striped">
                <thead class="thead-dark">
                    <tr>
                        <th>Payment Link</th>
                        <th>Amount</th>
                        <th>Mode</th>
                        <th>Status</th>
                    </tr>
                </thead>
    
                <tbody>
                    <tr *ngFor="let paym of data.links">
                        <td>{{paym.paymentLink}}</td>
                        <td>{{paym.amount}}</td>
                        <td>{{paym.paymentMode}}</td>
                        <td>{{paym.status | titlecase}}</td>
                    </tr>
                </tbody>
            </table>
        </ng-container>
        
        <ng-template #noPaymentLinks>
            <p>No link related payments made</p>
        </ng-template>
    </div>

    <div class="modal_footer">
        <mat-dialog-actions align="end">
            <button mat-raised-button 
                mat-dialog-close>
                Close
            </button>
        </mat-dialog-actions>
    </div>

</div>