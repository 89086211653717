import { Injectable } from '@angular/core';
import { HttpParams, HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ReservationElement } from '../views/reservationdata/reservationdata/reservationdata.component';
import { environment } from 'src/environments/environment';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class ReservationdataService {


  constructor(private http: HttpClient) { }
   /** GET users from the server */
   getData1(filter_Options, hotelId): Observable<ReservationElement[]> {
    let params = new HttpParams();
    params = params.append('fromDate', filter_Options[0]);
    params = params.append('toDate', filter_Options[1]);
    params = params.append('filterOn', filter_Options[2]);
    return this.http.get<ReservationElement[]>(`${environment.apiUrl}/bookingsdata/${hotelId}`,{params: params});
  }
  
  cancelReservation(payloadlist):Observable<any>{
    let params = new HttpParams();
    params = params.append('recompute', 'true');
    return this.http.post(`${environment.apiUrl}/pms/common/push`,payloadlist, {params:params});
  }

  cancelGroupBooking(payload, groupId): Observable<any> {
    const httpParams = new HttpParams({fromObject: {groupId, recompute: 'true'}});
    return this.http.post(`${environment.apiUrl}/pms/common/push`, payload, {params: httpParams});
  }

  fetchpayload(bookingdata,hotelId):Observable<any>{
    return this.http.put(`${environment.apiUrl}/fetchpayload/${hotelId}`, bookingdata, httpOptions);
  }

  
  fetchLogsOfAllocId(allocId, hotelId): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/fetchLogsOfAllocId/${hotelId}/${allocId}`);
  }

  markNoShow(bookingId, hotelId, user): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}/markNoShow/${hotelId}`, {bookingId: bookingId, user: user});
  }

  uploadFile(hotelId, file, user):Observable<any>{
    const data = new FormData();
    data.append('file', file);
    let params = new HttpParams();
    params = params.append('user', user);
    return this.http.put(`${environment.apiUrl}/convertExcelToJson/${hotelId}`, data, {params:params});
  }
  
  downloadFile(data, filename='data') {
    let csvData = this.ConvertToCSV(data, ['Booking_ID','Customer_Name', 'Booking_Date', 'Checkin', 'Checkout',
    'Source', 'Guests', 'Rooms', 'Room_Nights', 'Amount', 'Payment_Mode',
    'MealPlan', 'Roomstatus', 'state']);
    
    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
        dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
}

ConvertToCSV(objArray, headerList) {
     let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
     let str = '';
     let row = 'S.No,';

     for (let index in headerList) {
         row += headerList[index] + ',';
     }
     row = row.slice(0, -1);
     str += row + '\r\n';
     for (let i = 0; i < array.length; i++) {
         let line = (i+1)+'';
         for (let index in headerList) {
            let head = headerList[index];

             line += ',' + array[i][head];
         }
         str += line + '\r\n';
     }
     return str;
 }
  
}
