<div id="overlay" *ngIf="overlay"></div>
<div>
  <div class="d-flex justify-content-between align-items-center">
    <h2>{{ "guest.title" | translate }}</h2>
    <div class="d-flex justify-content-between align-items-center">
      <button
        *ngIf="searchMode"
        (click)="getGuests(); searchMode = false"
        mat-stroked-button
        class="active small-btn"
      >
        Back
      </button>
      <div>
        <button
          mat-stroked-button
          class="active small-btn"
          (click)="removeDuplicateEntries()"
        >
          {{ "guest.dedupe_btn" | translate }}
        </button>
      </div>
      <div>
        <div class="upload-btn-wrapper">
          <button
            mat-stroked-button
            class="active small-btn"
            (click)="uploadFile($event.target)"
          >
            {{ "guest.upload_btn" | translate }}<mat-icon>upload</mat-icon>
          </button>
          <input
            type="file"
            name="documents"
            accept=".xls"
            (change)="uploadFile($event.target)"
          />
        </div>
      </div>
      <div>
        <button
          mat-stroked-button
          class="active small-btn"
          (click)="downloadFile()"
        >
          {{ "guest.download_btn" | translate }}<mat-icon>download</mat-icon>
        </button>
      </div>
      <div class="search">
        <form [formGroup]="search" (ngSubmit)="onSearch(search)">
          <input
            id="search-bar"
            type="text"
            placeholder="{{ 'guest.search' | translate }}. . ."
            formControlName="searchInput"
          />
          <button type="submit" mat-icon-button>
            <mat-icon>search</mat-icon>
          </button>
        </form>
      </div>
    </div>
  </div>
  <mat-divider></mat-divider>
  <div class="table-container">
    <table class="table table-bordered">
      <thead class="thead-dark">
        <tr>
          <th style="width: 80px">{{ "guest.photo_id" | translate }}</th>
          <th>{{ "guest.name" | translate }}</th>
          <th>{{ "guest.phone" | translate }}</th>
          <th>{{ "guest.email" | translate }}</th>
          <th>{{ "guest.total_value" | translate }}</th>
          <th>{{ "guest.previous_stays" | translate }}</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let guest of guestData">
          <td>
            <ng-container *ngIf="guest.photo_id">
              <div (click)="openPhotoId(guest.photo_id)">
                <ng-container
                  *ngIf="
                    guest.photo_id[0].url.split('.').pop() === 'pdf';
                    else imageView
                  "
                >
                  <img src="assets/img/pdf_icon.png" alt="photo_id" />
                </ng-container>
                <ng-template #imageView>
                  <!-- <div class="stack">
                                        <ng-container *ngFor="let photo of guest.photo_id; let i = index">
                                            <img class="stack-element" [src]=photo.url alt="photo_id"
                                            [ngStyle]="{'margin-left.px': i*8, 'margin-top.px': i*2}">
                                        </ng-container>
                                    </div> -->
                  <img [src]="guest.photo_id[0].url" alt="photo_id" />
                </ng-template>
              </div>
            </ng-container>
          </td>
          <td>{{ guest.name }}</td>
          <td>{{ guest.phone }}</td>
          <td>{{ guest.email }}</td>
          <td>{{ guest.total_value | currency : currencyCode }}</td>
          <td
            [matMenuTriggerFor]="menu"
            (menuOpened)="getPrevBookings(guest)"
            class="color-primary"
          >
            {{ "guest.view_btn" | translate }}
          </td>
          <td>
            <mat-icon class="color-primary" (click)="editFormToggle(guest)"
              >edit</mat-icon
            >
          </td>
          <mat-menu #menu="matMenu">
            <div
              *ngFor="let prev of guest['previous_stays']; let i = index"
              class="menu-container"
              [ngStyle]="{
                'background-color': i % 2 == 0 ? '#f2f2f2' : '#fff'
              }"
            >
              <div class="row">
                <small class="col-sm-8">
                  {{ prev.checkin | date : "dd MMM YY" }} -
                  {{ prev.checkout | date : "dd MMM YY" }}
                </small>
                <small class="col-sm-4 text-right" style="font-weight: 450">
                  {{ prev.amount | currency : currencyCode }}
                </small>
              </div>
              <div class="row">
                <small class="col-sm-6 text-secondary">{{ prev.source }}</small>
                <div class="text-right col-sm-6">
                  <button
                    class="btn btn-info btn-sm"
                    (click)="openBookingDetails(prev.booking_id)"
                  >
                    {{ "guest.view_btn" | translate }}
                  </button>
                </div>
              </div>
            </div>
          </mat-menu>
        </tr>
        <tr class="bottom-row">
          <td colspan="6">
            <div class="bottom-td">
              <div *ngIf="guestData?.length > 0; else notFoundBlock">
                {{ "guest.showing" | translate }} {{ currentPage * 50 + 1 }}
                {{ "guest.to" | translate }} {{ currentPage * 50 + entries }}
                {{ "guest.of" | translate }} {{ totalEntries }}
                <span *ngIf="searchMode; else entriesBlock"> results </span>
                <ng-template #entriesBlock>
                  <span>{{ "guest.entries" | translate }}</span>
                </ng-template>
              </div>
              <div>
                <ng-template #notFoundBlock>
                  <!-- No results found -->
                </ng-template>
              </div>

              <div class="pagination">
                <button
                  mat-stroked-button
                  (click)="getGuests(currentPage - 1)"
                  [disabled]="currentPage - 1 < 0"
                >
                  {{ "guest.previous_btn" | translate }}
                </button>
                <button
                  *ngFor="let page of paginator(currentPage, totalPages)"
                  class="btn btn-small page-num"
                  (click)="getGuests(page)"
                  [ngClass]="{ active: currentPage === page }"
                >
                  {{ page + 1 }}
                </button>
                <button
                  mat-stroked-button
                  (click)="getGuests(currentPage + 1)"
                  [disabled]="currentPage + 1 > totalPages"
                >
                  {{ "guest.next_btn" | translate }}
                </button>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div id="edit-guest" *ngIf="showEditForm">
  <div class="container">
    <div class="row heading">
      <h1>{{ "guest.edit_guest" | translate }}</h1>
    </div>
    <mat-divider></mat-divider>

    <div class="form-container">
      <form [formGroup]="editGuestForm">
        <div class="row">
          <div class="col-sm-3">
            <label>{{ "guest.name" | translate }}</label>
            <input
              type="text"
              class="form-control form-control-sm"
              formControlName="name"
              maxlength="64"
              readonly
            />
          </div>
          <div class="col-sm-3">
            <label>{{ "guest.email" | translate }}</label>
            <input
              type="text"
              class="form-control form-control-sm"
              formControlName="email"
              maxlength="64"
            />
          </div>
          <div class="col-sm-2">
            <label>{{ "guest.phone" | translate }}</label>
            <input
              type="text"
              class="form-control form-control-sm"
              formControlName="phone"
              maxlength="64"
            />
          </div>
          <div class="col-sm-2">
            <label>{{ "guest.dob" | translate }}</label>
            <input
              type="date"
              class="form-control form-control-sm"
              formControlName="date_of_birth"
            />
          </div>
          <div class="col-sm-2">
            <label>{{ "guest.gender" | translate }}</label>
            <select
              class="form-control form-control-sm"
              formControlName="gender"
            >
              <option *ngFor="let gender of genders" [value]="gender">
                {{ gender }}
              </option>
            </select>
          </div>
        </div>

        <div formGroupName="address" class="row mt-3">
          <div class="col-sm-4">
            <label>{{ "guest.address" | translate }}</label>
            <input
              type="text"
              class="form-control form-control-sm"
              formControlName="address"
            />
          </div>

          <div class="col-sm-2">
            <label>{{ "guest.city" | translate }}</label>
            <input
              type="text"
              class="form-control form-control-sm"
              formControlName="city"
            />
          </div>

          <div class="col-sm-2">
            <label>{{ "guest.state" | translate }}</label>
            <input
              type="text"
              class="form-control form-control-sm"
              formControlName="state"
            />
          </div>

          <div class="col-sm-2">
            <label>{{ "guest.zip" | translate }}</label>
            <input
              type="text"
              class="form-control form-control-sm"
              formControlName="zip"
            />
          </div>

          <div class="col-sm-2">
            <label>{{ "guest.country" | translate }}</label>
            <input
              type="text"
              class="form-control form-control-sm"
              formControlName="country"
            />
          </div>
        </div>

        <div class="row mt-3">
          <div class="col-sm-3">
            <label>{{ "guest.identity" | translate }}</label>
            <select
              class="form-control form-control-sm"
              formControlName="id_proof"
              [attr.disabled]="idEditable ? null : true">
              <option *ngFor="let type of identityTypes" [value]="type">
                {{ type }}
              </option>
            </select>
          </div>

          <div class="col-sm-3">
            <label>{{ "guest.id_detail" | translate }}</label>
            <input
              type="text"
              class="form-control form-control-sm"
              formControlName="id_detail"
              [readonly]="idEditable ? null : true"
            />
          </div>

          <div class="col-sm-4">
            <label>{{ "guest.photo_id" | translate }}</label>
            <div class="d-flex">
              <div
                *ngFor="let photo of editGuestForm.get('photo_id')['controls']"
                class="photo-id mr-1"
              >
                <a [href]="photo.value.url" target="_blank">
                  <img [src]="photo.value.url" />
                </a>
              </div>
              <div *ngFor="let photo of localFilesSrc" class="photo-id mr-1">
                <img [src]="photo.src" />
              </div>
              <label
                *ngIf="
                  editGuestForm.get('photo_id')['controls'].length +
                    localFilesSrc.length <
                  5
                "
                class="custom-file-upload"
              >
                <input
                  type="file"
                  (change)="fileUpload($event.target)"
                  accept="image/*"
                  class="file-upload"
                />
                <mat-icon>add</mat-icon>
              </label>
            </div>
          </div>
        </div>

        <div class="row mt-4 f-flex flex-row-reverse">
          <button type="button" mat-raised-button (click)="editFormToggle()">
            {{ "guest.cancel_btn" | translate }}
          </button>
          <button
            type="button"
            mat-raised-button
            (click)="onSubmit(); editFormToggle()"
            class="active"
          >
            {{ "guest.submit_btn" | translate }}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
