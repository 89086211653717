<div>
    <div class="heading d-flex justify-content-between align-items-center mb-2">
        <h2>{{'companies.city_ledger' | translate}}</h2>

        <div class="header-row">
           <div>
            <button mat-stroked-button class="active" routerLink="/companies/{{hotelId}}">
                {{'companies.companies_btn' | translate}}
            </button>
           </div>

            <input #searchString type="text" class="form-control form-control-sm" placeholder="{{'companies.search_company' | translate}}" 
                (input)="searchCompany(searchString.value)">
        </div>
    </div>
    <mat-divider></mat-divider>

    <div class="table-container">
        <table class="table table-bordered">
            <thead class="thead-dark">
                <tr>
                    <th>{{'companies.company_name' | translate}}</th>
                    <th>{{'companies.gst/vat' | translate}}</th>
                    <th>{{'companies.total_outstanding' | translate}}</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let company of $cityLedgerData; let i = index">
                    <tr [ngClass]="{'bg-grey': !company.hidden}">
                        <td>{{company.company_name}}</td>
                        <td>{{company.tax_num}}</td>
                        <td class="text-right">{{company.total_outstanding | number : '1.2-2'}}</td>
                        <td class="text-right">
                            <div class="d-flex justify-content-around align-items-center">
                                <mat-icon (click)="downloadExcel(company)"
                                    title="Download in excel format">
                                    download
                                </mat-icon>
                                <mat-icon (click)="toggleState(i)"
                                    title="{{company.hidden ? 'Show': 'Hide'}}">
                                    keyboard_arrow_{{company.hidden ? 'down': 'up'}}
                                </mat-icon>
                            </div>
                        </td>
                    </tr>

                    <ng-container *ngIf="company.infoFetched && !company.data?.length; else paymentsBlock">
                        <div class="d-flex justify-content-center m-4">
                            No City Ledger entries
                        </div>
                    </ng-container>
                    
                    <ng-template #paymentsBlock>
                        <tr [hidden]="company.hidden">
                            <td class="alt-row" colspan="4">
                                <div [@expandCollapse]="company.hidden ? 'collapsed': 'expanded'">

                                    <table class="table nested-table">
                                        <thead>
                                            <tr class="bg-lightgrey">
                                                <th>{{'companies.guest_name' | translate}}</th>
                                                <th>{{'companies.checkin' | translate}}</th>
                                                <th>{{'companies.checkout' | translate}}</th>
                                                <th>{{'companies.invoice_no' | translate}}.</th>
                                                <th class="text-center">{{'companies.amount' | translate}}</th>
                                                <th class="width-50">{{'companies.payment_date' | translate}}</th>
                                                <th>{{'companies.reference_id' | translate}}</th>
                                                <th>{{'companies.file' | translate}}</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr class="sub-row" 
                                                *ngFor="let p of company.data">
                                                <td>{{p.guest_name}}</td>
                                                <td>{{p.checkin | date : 'mediumDate'}}</td>
                                                <td>{{p.checkout | date : 'mediumDate'}}</td>
                                                <td>
                                                    <a [href]="p.url" target="_blank">{{p.invoice_num}}</a>
                                                </td>
                                                <td class="text-right">{{p.amount | number : '1.2-2'}}</td>
                                                <td>
                                                    <input type="date" class="form-control form-control-sm" [(ngModel)]="p.btcPaymentDate">
                                                </td>
                                                <td>
                                                    <input type="text" class="form-control form-control-sm" maxlength="50" [(ngModel)]="p.referenceId">
                                                </td>
                                                <td>
                                                    <label class="custom-file-upload">
                                                        <input type="file" (change)="fileUpload(p.payment_id, $event.target)" accept="image/*, application/pdf"/>
                                                        <mat-icon matBadge="1" [matBadgeHidden]="!paymentFiles[p.payment_id]" matBadgeColor="warn">
                                                            file_upload
                                                        </mat-icon>
                                                    </label>
                                                </td>
                                                <td class="text-right width-50">
                                                    <button mat-stroked-button class="active small-btn" (click)="markPaid(p, i, company)">
                                                        {{'companies.mark_paid_btn' | translate}}
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <div class="sub-page">
                                        <button mat-raised-button (click)="changePage(i, 'prev')">Prev</button>
                                        <div>{{company.page || 1}}</div>
                                        <button mat-raised-button (click)="changePage(i, 'next')">Next</button>
                                    </div>

                                </div>
                            </td>
                        </tr>
                    </ng-template>

                </ng-container>
            </tbody>
        </table>
    </div>
</div>