import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AccountsService } from 'src/app/core/accounts.service';

@Component({
  selector: 'app-b2binvoice-details',
  templateUrl: './b2binvoice-details.component.html',
  styleUrls: ['./b2binvoice-details.component.scss']
})
export class B2binvoiceDetailsComponent implements OnInit {

  invoiceData = []

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private accountsService: AccountsService
  ) { }

  ngOnInit(): void {
    this.getData()
  }

  getData() {
    this.accountsService.getInvoiceDetails(this.data.hotelId, this.data?.summary.invoiceIds).subscribe(res => {
      if (res?.success) {
        this.invoiceData = res.data;
      }
    })
  }

  generateEInvoice(item) {
    this.accountsService.generateEInvoice(item.hotelId, item.invoiceId, item.bookingId).subscribe(res => {
      if (res?.success) {
        item.eInvoiceLink = res.data;
      }
    }, err => {
      item.failed = true;
    })
  }

}
