import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { StoreService } from 'src/app/core/store.service';
import { ReturnStockItemComponent } from '../modals/return-stock-item/return-stock-item.component';

@Component({
  selector: 'app-dept-stock',
  templateUrl: './dept-stock.component.html',
  styleUrls: ['./dept-stock.component.scss']
})
export class DeptStockComponent implements OnInit {
  @Input() hotelId: string;
  @Input() features: any;

  deptStock: Array<any>;
  $deptStock: Array<any>;
  userDepartments: Array<any>;
  categories: Array<any>;
  subCategories: Array<any>;
  department = '';
  category = '';
  subCategory = '';
  totalValue = 0;

  constructor(
    public storeService: StoreService,
    private dialog: MatDialog  
  ) { }

  ngOnInit(): void {
    this.getStock();
    this.getDepts();
    this.getCategories();
  }

  getStock() {
    this.storeService.getDeptStock(this.hotelId).subscribe(data => {
      this.deptStock = data?.dept_stock;
      this.$deptStock = JSON.parse(JSON.stringify(this.deptStock));
      this.countTotalValue();
    });
  }

  getDepts() {
    this.storeService.getDeptByUser(this.hotelId).subscribe(data => {
      this.userDepartments = data?.departments;
    });
  }

  getCategories() {
    this.storeService.getCategories(this.hotelId).subscribe(data => {
      this.categories = data?.categories;
    });
  }

  filterSubCategories(category) {
    this.subCategory = '';
    if (category === '') {
      this.subCategories = [];
      return;
    }
    this.subCategories = this.categories.filter(data => {
      return data?.name === category;
    });
    if (this.subCategories.length > 0) {
      this.subCategories = this.subCategories[0].sub_categories;
    }

    this.countTotalValue();
  }

  searchFun(key: string) {
    key = key?.toLowerCase();
    const department = this.department?.toLowerCase();
    const category = this.category?.toLowerCase();
    const subCategory = this.subCategory?.toLowerCase();

    this.$deptStock = this.deptStock.filter(data => {
      return data.dept_name?.toLowerCase().indexOf(department) > -1 &&
            data.item_category?.toLowerCase().indexOf(category) > -1 &&
            data.item_sub_category?.toLowerCase().indexOf(subCategory) > -1 &&
            data.item_name?.toLowerCase().indexOf(key) > -1;
    });
    this.countTotalValue();
  }

  filterBy() {
    if (this.department === 'All') {
      this.department = '';
    }
    const department = this.department?.toLowerCase();
    const category = this.category?.toLowerCase();
    const subCategory = this.subCategory?.toLowerCase();

    this.$deptStock = this.deptStock.filter(data => {
      return data.dept_name?.toLowerCase().indexOf(department) > -1 &&
            data.item_category?.toLowerCase().indexOf(category) > -1 &&
            data.item_sub_category?.toLowerCase().indexOf(subCategory) > -1;
    });
    this.countTotalValue();
  }

  countTotalValue() {
    this.totalValue = this.$deptStock.reduce((p, c) => p + (c.value * 1), 0);
  }

  returnStock() {
    const dRef = this.dialog.open(ReturnStockItemComponent, {
      width: '700px',
      maxWidth: '95%',
      height: 'auto',
      data: {
        hotelId: this.hotelId,
        stock: this.deptStock,
        departments: this.userDepartments
      }
    });
  
    dRef.afterClosed().subscribe(data => {
      if (data?.success) {
        this.getStock();
      }
    });
  }
}
