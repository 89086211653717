import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { Stayview2Component } from './stayview2/stayview2.component';
import { GuestBarPosPipe } from './guest-bar-pos.pipe';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AllocationMenu2Module } from 'src/app/_shared/modules/allocation-menu-2/allocation-menu-2.module';
import { PreviousDayCheckinsComponent } from './previous-day-checkins/previous-day-checkins.component';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { QuickReservationComponent } from './quick-reservation/quick-reservation.component';



@NgModule({
  declarations: [
    Stayview2Component,
    GuestBarPosPipe,
    PreviousDayCheckinsComponent,
    QuickReservationComponent,
  ],
  imports: [
    CommonModule,
    MatDividerModule,
    MatIconModule, 
    MatListModule,
    MatFormFieldModule,
    MatInputModule,
    MatMenuModule,
    MatButtonModule,
    MatTooltipModule,
    MatDatepickerModule,
    FormsModule,
    AllocationMenu2Module,
    MatDialogModule,
    TranslateModule,
    ReactiveFormsModule
  ],
  exports: [
    GuestBarPosPipe
  ],
  providers: [
    GuestBarPosPipe
  ]
})
export class Stayview2Module { }
