import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Globals } from 'src/app/core/globals.service';
import { ReservationService } from 'src/app/core/reservation.service';
import { EditStayComponent } from '../stayview/edit/edit-stay/edit-stay.component';
import { Location } from '@angular/common';
import { ReservationdataService } from 'src/app/core/reservationdata.service';
import { DialogComponent } from 'src/app/_shared/dialog/dialog.component';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AddRoomToGroupComponent } from './add-room-to-group/add-room-to-group.component';
import { BookingService } from 'src/app/core/booking.service';
import { UserService } from 'src/app/core/user.service';
import { TransferFolioComponent } from './transfer-folio/transfer-folio.component';
import { SendVoucherEmailComponent } from '../stayview/send-voucher-email/send-voucher-email.component';

@Component({
  selector: 'app-group-booking',
  templateUrl: './group-booking.component.html',
  styleUrls: ['./group-booking.component.scss']
})
export class GroupBookingComponent implements OnInit {

  hotelId: string;
  startDate: any;
  endDate: any;
  showBookings = true;
  groupBookings: any[];
  mode = 'new';
  payload: any;
  currentUser: any;
  filteron: string = "booking_date";
  titles:Array<Object> = [
    {name: "Booking Date", value: "booking_date"},
    {name: "Check In", value:"checkin_date"},
    {name: "Check Out", value:"checkout_date"},
    {name: "Stay Date", value:"stay_date"}, 
];
  constructor(private route: ActivatedRoute,
              public globals: Globals,
              public reserv: ReservationService,
              public reservData: ReservationdataService,
              public datePipe: DatePipe,
              public dialog: MatDialog,
              public location: Location,
              private bookingService: BookingService,
              private userService: UserService
              ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.hotelId = params.hotelId;
      this.globals.changeHotelID(this.hotelId);
    });
    this.route.queryParams.subscribe(queryParams => {
      this.currentUser = queryParams.user;
    });
    this.initDates();
    this.getData();
  }

  getData(){
    this.reserv.getGroupBooking(this.hotelId, this.startDate, this.endDate, this.filteron).subscribe(data => {
      this.groupBookings = data.group_bookings;
      this.groupBookings.forEach(e => {
        e.hidden = true;
      });
    });
  }
  initDates() {
    this.endDate = new Date().toISOString().slice(0, 10);
    this.startDate = new Date(this.endDate);
    this.startDate.setDate(this.startDate.getDate() - 7);
    this.startDate = this.datePipe.transform((new Date(this.startDate)), 'y-MM-dd');
  }


  showB(value) {
    this.showBookings = true;
    this.getData();
  }

  toggleState(i: number) {
    this.groupBookings[i].hidden = !this.groupBookings[i].hidden;
  }

  goToEdit(allocId, status, bookingId, source){
    const user = localStorage.getItem('user');
    this.dialog.open(EditStayComponent, {data : {allocId, hotelId: this.hotelId, status, bookingId, source, queryParams: {user}}});
  }

  goBack() {
    if (!this.showBookings) {
      this.showBookings = true;
      return;
    }
    this.location.back();
  }

  openGroupBooking(mode = 'new', group = null) {
    this.mode = mode;
    if (mode === 'modify') {
      const bookingInfo = {bookingid: group.rooms[0].booking_id, checkin: group.group_checkin.split(' ')[0], checkout: group.group_checkout.split(' ')[0]};
      this.reservData.fetchpayload(bookingInfo, this.hotelId).subscribe(data => {
        this.payload = data?.payload;
        this.payload.action = 'modify';
        this.payload.groupId = group.id;
        this.payload.groupName = group.name;
        this.showBookings = false;
      });
    } else {
      this.showBookings = false;
    }
  }

  cancelBooking(rooms, groupId) {
    const uniqueBookings = new Set();
    rooms?.forEach(room => {
      const obj = JSON.stringify({
        bookingid: room.booking_id, 
        checkin: room.checkin?.split(' ')[0], 
        checkout: room.checkout?.split(' ')[0]
      });
      uniqueBookings.add(obj);
    });

    const bookingInfo: any[] = []
    for (const value of uniqueBookings.values()) {
      bookingInfo.push(JSON.parse(value as string));
    }
    
    this.confirmCancel().subscribe(res => {
      if (res) {
        this.fetchPayload(bookingInfo).subscribe(payloads => {
          const cancelBody = [];

          payloads?.forEach(e => {
            if (e?.hasOwnProperty('error')) {
              this.openDialog('Error', e.error, 'Ok', null);
              return;
            }
            else if (e?.hasOwnProperty('payload')) {
              cancelBody.push(e.payload);
            }
          });

          this.reservData.cancelGroupBooking(cancelBody, groupId).subscribe(() => {
            this.openDialog('Success', 'Booking cancelled', 'Ok', null);
            this.getData();
          });
        });

      }
    });

  }

  confirmCancel(): Observable<boolean> {
    const dialogBody = {title: 'Cancel Booking', body: `Are you sure you want to cancel this booking ?`,
    confirmBtn: 'Yes', cancelBtn: 'Cancel'};
    const dialogRef = this.dialog.open(DialogComponent, {data: dialogBody});
    return dialogRef.afterClosed()
      .pipe(map(result => {
        if (result === 'true') {
          return true;
        }
        else {
          return false;
        }
    }));
  }

  fetchPayload(bookingInfo: any[]): Observable<any> {
    const requests = [];
    bookingInfo.forEach(info => {
      requests.push(this.reservData.fetchpayload(info, this.hotelId));
    });
    
    return forkJoin(requests);
  }

  openDialog(title: string, body: string, confirmBtn: string, cancelBtn: string) {
    const dialogBody = {title, body, confirmBtn, cancelBtn};
    this.dialog.open(DialogComponent, {data: dialogBody});
  }

  addGuestToGroup(guest) {
    const ref = this.dialog.open(AddRoomToGroupComponent, {
      width: '600px',
      height: 'auto',
      data: {
        hotelId: this.hotelId,
        guest
      }
    });

    ref.afterClosed().subscribe(res => {
      if (res?.success) {
        this.getData();
      }
    })
  }

  removeGuestFromGroup(roomAllocationId) {
    const ref = this.dialog.open(DialogComponent, {data: {
      title: 'Confirm',
      body: 'Are you sure you want to remove this room from group ?',
      confirmBtn: 'Yes', cancelBtn: 'Cancel'
    }});

    ref.afterClosed().subscribe(res => {
      if (res === 'true') {
        this.bookingService.removeGuestFromGroup(this.hotelId, roomAllocationId)
        .subscribe(res => {
          this.getData();
        })
      }
    })
  }

  checkin(allocId, status, bookingId, source, guestname, guestContact){
    if(status !== 'Assigned'){
      const WarningDialog =
        {title: 'ERROR !', body: 'Kindly Assign the room before checking in', cancelBtn: 'Dismiss'};
        this.dialog.open(DialogComponent, {data: WarningDialog});
        return;
    }
    const dialogBody =
      {title: 'Check in ?', body: 'Are you sure you want to check in ?',
        confirmBtn: 'Check in', cancelBtn: 'Cancel'
      };
    const dialogRef = this.dialog.open(DialogComponent, {data: dialogBody});
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'true'){
        const body = {
          roomAllocationId: allocId, 
          hotelId: this.hotelId, 
          bookingId, 
          user: localStorage.getItem('user'),
          allocData: {
            guest_name: guestname,
            ...guestContact
          }
        };
        this.userService.checkIn(body, this.hotelId)
        .subscribe(success => {
          this.getData();
        },
          err => {
        });
      }
    });
  }

  checkOut(roomAllocationId, bookingId, source, guestName, guestContact) {
    const dialogBody =
      {title: 'Check out ?', body: 'Are you sure you want to check out ?',
       confirmBtn: 'Check out', cancelBtn: 'Cancel'
      };
    const dialogRef = this.dialog.open(DialogComponent, {data: dialogBody});
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'true'){
        const body = {
          roomAllocationId, 
          bookingId, 
          user: localStorage.getItem('user'),
          allocData: {
            guest_name: guestName,
            ...guestContact
          }
        };
        this.userService.checkOut(body, this.hotelId)
          .subscribe(
          res => {
            if (res && 'error' in res) {
              const WarningDialog =
              {title: 'ERROR !', body: `${res.error}`, cancelBtn: 'Dismiss'};
              this.dialog.open(DialogComponent, {data: WarningDialog});
              return;
            }
            this.getData();
          },
          err => {
            const WarningDialog =
              {title: 'ERROR !', body: `${err.error}`, cancelBtn: 'Dismiss'};
            this.dialog.open(DialogComponent, {data: WarningDialog});
          });
      }
    });
  }

  transferToFolio(rooms, allocId){
    const checkedInAllocDetails = rooms.filter(e => e.status == 'Checked in' && e.room_allocation_id != allocId);
    var hotelId = this.hotelId
    this.dialog.open(TransferFolioComponent, {
      width: '30%',
      autoFocus: false,
      maxHeight: '250vh',
      data: {
        hotelId,
        currentAllocId: [{room_allocation_id: allocId}],
        checkedInAllocDetails: checkedInAllocDetails,
        queryParams: { user: localStorage.getItem('user') },
      },
    });
  }


  selectOrUnselectAll(data){
    if (data.every(val => val.checked === true)){
      data.forEach(val => {
        val.checked = false;
      });
    }else {
      data.forEach(val => {
        val.checked = true;
      });
    }
  }

  groupCheckIn(data){
    const allocDetails = data.filter(e => e.checked);
    if(allocDetails.length == 0){
      const WarningDialog =
              {title: 'ERROR !', body: 'Kindly choose the rooms with help of checkbox', cancelBtn: 'Dismiss'};
            this.dialog.open(DialogComponent, {data: WarningDialog});
    }else{
      const dialogBody =
      {title: 'Check in ?', body: 'Are you sure you want to do Group check in ?',
        confirmBtn: 'Check in', cancelBtn: 'Cancel'
      };
    const dialogRef = this.dialog.open(DialogComponent, {data: dialogBody});
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'true'){
        for (var allocdata in allocDetails){
          if (allocDetails[allocdata]['status'] === 'Assigned'){
            const body = {
              roomAllocationId: allocDetails[allocdata]['room_allocation_id'], 
              hotelId: this.hotelId, 
              bookingId: allocDetails[allocdata]['booking_id'], 
              user: localStorage.getItem('user'),
              allocData: {
                guest_name: allocDetails[allocdata]['guest_name'],
                ...allocDetails[allocdata]['guest_contact']
              }
            };
            this.userService.checkIn(body, this.hotelId)
            .subscribe();
          }
        }
        this.getData();
      }
    })
    }
    
    
  }

  groupCheckOut(data){
    const allocDetails = data.filter(e => e.checked);
    if(allocDetails.length == 0){
      const WarningDialog =
              {title: 'ERROR !', body: 'Kindly choose the rooms with help of checkbox', cancelBtn: 'Dismiss'};
            this.dialog.open(DialogComponent, {data: WarningDialog});
    }else{
      const dialogBody =
        {title: 'Check Out ?', body: 'Are you sure you want to do Group check out ?',
          confirmBtn: 'Check out', cancelBtn: 'Cancel'
        };
      const dialogRef = this.dialog.open(DialogComponent, {data: dialogBody});
      dialogRef.afterClosed().subscribe(result => {
        if (result === 'true'){
          for (var allocdata in allocDetails){
            if (allocDetails[allocdata]['status'] === 'Checked in'){
              const body = {
                roomAllocationId: allocDetails[allocdata]['room_allocation_id'], 
                hotelId: this.hotelId, 
                bookingId: allocDetails[allocdata]['booking_id'], 
                user: localStorage.getItem('user'),
                allocData: {
                  guest_name: allocDetails[allocdata]['guest_name'],
                  ...allocDetails[allocdata]['guest_contact']
                }
              };
              this.userService.checkOut(body, this.hotelId)
              .subscribe();
            }
          }
          this.getData();
        }
      })
    }
  }

  groupTransferFolio(rooms){
    const allocDetails = rooms.filter(e => e.checked && e.status == 'Checked in');
    if(allocDetails.length == 0){
      const WarningDialog =
              {title: 'ERROR !', body: 'Kindly choose the rooms with help of checkbox', cancelBtn: 'Dismiss'};
            this.dialog.open(DialogComponent, {data: WarningDialog});
    }else{
      const checkedInAllocDetails = rooms.filter(e => e.status == 'Checked in');
      var hotelId = this.hotelId
      this.dialog.open(TransferFolioComponent, {
        width: '30%',
        autoFocus: false,
        maxHeight: '250vh',
        data: {
          hotelId,
          currentAllocId: allocDetails,
          checkedInAllocDetails: checkedInAllocDetails,
          queryParams: { user: localStorage.getItem('user') },
        },
      });
    }
  }

  changeMasterRoom(groupId, allocId){
    const dialogBody =
        {title: 'Change Master', body: 'Are you sure you want to do change master Room ?',
          confirmBtn: 'Yes', cancelBtn: 'Cancel'
        };
      const dialogRef = this.dialog.open(DialogComponent, {data: dialogBody});
      dialogRef.afterClosed().subscribe(result => {
        if (result === 'true'){
          this.bookingService.updateMasterRoom(groupId, this.hotelId, allocId).subscribe(data=>{
            this.getData();
          })
        }
      })
  }

  sendVoucher(bookingId) {
    this.dialog.open(SendVoucherEmailComponent, {
      data: {
        roomAllocationId: '',
        emailId: '',
        user: localStorage.getItem('user'),
        hotelId: this.hotelId,
        bookingId: bookingId,
        source: 'PMS',
        groups: true
      },
      width: '600px',
      height: '250px',
    });
  }


}
