import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { debounceTime } from 'rxjs/operators';
import { MaintenanceIssueService } from 'src/app/core/maintenance-issue.service';
import { CreateIssueComponent } from '../create-issue/create-issue.component';
import { expandCollapse, slide } from 'src/app/animations';
import { DialogComponent } from 'src/app/_shared/dialog/dialog.component';
import { Globals } from 'src/app/core/globals.service';

@Component({
  selector: 'app-maintenance-index',
  templateUrl: './maintenance-index.component.html',
  styleUrls: ['./maintenance-index.component.scss'],
  animations: [expandCollapse, slide],
})
export class MaintenanceIndexComponent implements OnInit {
  @Input() hotelId: string;
  @Input() showPage : boolean;
  config: any = {};
  roomNumbers: any[] = [];

  mainIssues = [];
  mainIssuesFiltered = [];
  filtersForm: FormGroup;
  type: any;
  groupId: any;
  hotels: any;

  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private maintService: MaintenanceIssueService,
    public fb: FormBuilder,
    public global: Globals
  ) {}

  ngOnInit(): void {
    if (!this.hotelId) {
      this.route.params.subscribe((params) => {
        this.hotelId = params.hotelId;
        this.global.changeHotelID(this.hotelId);
      });
    }
    this.route.queryParams.subscribe((queryParams) => {
      this.type = queryParams.type;
    });

    this.getConfig();
    this.getRoomNumbers();
    const today = new Date();
    var d = new Date();
    if (this.type === 'group') {
      var firstDay = new Date(new Date().setMonth(d.getMonth() - 3));
    } else {
      var firstDay = new Date(new Date().setMonth(d.getMonth() - 6));
    }
    this.initFilterForm(today, firstDay);
    // this.getIssues();

    this.filtersForm.controls.date.valueChanges
      .pipe(debounceTime(800))
      .subscribe((e) => this.getIssues());

    (this.filtersForm.controls.localFilter as FormGroup).valueChanges
      .pipe(debounceTime(1000))
      .subscribe((e) => this.filterIssue());
  }

  initFilterForm(today: Date, firstDay: Date) {
    this.filtersForm = this.fb.group({
      date: this.fb.group({
        startDate: [firstDay.toISOString().substring(0, 10)],
        endDate: [today.toISOString().substring(0, 10)],
      }),

      localFilter: this.fb.group({
        area: [''],
        typeOfOperation: [''],
        status: [''],
        assignedTo: [''],
        priority: [''],
        hotelName: [''],
      }),
    });
  }

  openCreateIssue(mode = 'new', issueData?: any) {
    const dialogRef = this.dialog.open(CreateIssueComponent, {
      data: {
        hotelId: this.hotelId,
        config: this.config,
        mode,
        issueData,
        roomNumbers: this.roomNumbers,
      },
      height: 'auto',
      width: '750px',
      maxWidth: '93%',
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res?.success) {
        this.getIssues();
      }
    });
  }

  getConfig() {
    this.maintService.getMaintenanceConfig(this.hotelId).subscribe((data) => {
      if (data?.isEnabled) {
        this.config = data.conf;
        this.groupId = data.groupId;
        this.hotels = data.hotels;
        this.getIssues();
        // this.setDefaultStatus(this.config.statuses);
      }
    });
  }

  setDefaultStatus(statuses) {
    for (const status of statuses) {
      if (status === 'New') {
        this.filtersForm.controls.localFilter['controls'].status.setValue(
          'New'
        );
        break;
      }
    }
  }

  getRoomNumbers() {
    this.maintService.getRoomNumbers(this.hotelId).subscribe((data) => {
      if (data?.success) {
        this.roomNumbers = data.data;
      }
    });
  }

  getIssues() {
    const startDate = (this.filtersForm.controls.date as FormGroup).controls
      .startDate.value;
    const endDate = (this.filtersForm.controls.date as FormGroup).controls
      .endDate.value;

    const filters = {
      startDate,
      endDate,
    };
    // Object.keys(this.filtersForm.controls).forEach(key => {
    //   if (this.filtersForm.controls[key].value && !(['startDate', 'endDate'].includes(key))) {
    //     filters[key] = this.filtersForm.controls[key].value;
    //   }
    // });
    if (this.type == 'group' && this.groupId) {
      this.maintService
        .getIssues(this.hotelId, filters, this.groupId)
        .subscribe((data) => {
          if (data?.success) {
            this.mainIssues = data.data;
            this.mainIssuesFiltered = this.mainIssues;
            this.setDefaultStatus(this.config.statuses);
          }
        });
    } else {
      this.maintService.getIssues(this.hotelId, filters).subscribe((data) => {
        if (data?.success) {
          this.mainIssues = data.data;
          this.mainIssuesFiltered = this.mainIssues;
          this.setDefaultStatus(this.config.statuses);
        }
      });
    }
  }

  filterIssue() {
    const control = (this.filtersForm.controls.localFilter as FormGroup)
      .controls;
    this.mainIssuesFiltered = this.mainIssues.filter((e) => {
      return (
        e.area?.toLowerCase().indexOf(control.area.value?.toLowerCase()) > -1 &&
        e.typeOfOperation
          ?.toLowerCase()
          .indexOf(control.typeOfOperation.value?.toLowerCase()) > -1 &&
        e.status?.toLowerCase().indexOf(control.status.value?.toLowerCase()) >
          -1 &&
        e.assignedTo
          ?.toLowerCase()
          .indexOf(control.assignedTo.value?.toLowerCase()) > -1 &&
        e.priority
          ?.toLowerCase()
          .indexOf(control.priority.value?.toLowerCase()) > -1 &&
        e.hotelName
          ?.toLowerCase()
          .indexOf(control.hotelName.value?.toLowerCase()) > -1
      );
    });
  }

  showIssueUpdates(issue: any) {
    if (issue.shown) {
      issue.shown = false;
      return;
    }

    if (issue.updates?.length) {
      issue.shown = true;
      return;
    }
    this.maintService
      .getIssueUpdate(issue.hotelId, issue.id)
      .subscribe((data) => {
        if (data?.success) {
          issue.updates = data.data;
          issue.shown = true;
        }
      });
  }

  editIssue(issue: any) {
    this.openCreateIssue('edit', issue);
  }

  updateIssue(issue: any) {
    this.openCreateIssue('issue-update', issue);
  }

  deleteIssue(issue: any) {
    const dialogBody = {
      title: 'DELETE ISSUE',
      body: `Are you sure you want to delete ?`,
      confirmBtn: 'Yes',
      cancelBtn: 'Cancel',
    };
    const dialogRef = this.dialog.open(DialogComponent, { data: dialogBody });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'true') {
        this.maintService
          .deleteIssue(issue.hotelId, issue.id)
          .subscribe((data) => {
            if (data?.success) {
              this.global.snackBarFunc('Deleted');
              this.getIssues();
            }
          });
      }
    });
  }

  filterByTicketNum(ticketNum) {
    const chainId = this.type === 'group' ? this.groupId : null;
    this.maintService.filterIssue(this.hotelId, ticketNum, chainId).subscribe(res => {
      this.mainIssuesFiltered = res.data;
    })
  }
}
