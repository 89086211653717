<div class="main-wrapper">

    <div class="main-content">
        <div class="heading">
            <h1>{{data.heading}}</h1>
            <mat-divider></mat-divider>
        </div>

        <div class="d-flex ml-auto mt-1">
            <div *ngIf="data.mode !== 'view'" class="vendor-drop mr-3">
                <label>{{'stores.vendor' | translate}}</label>
                <app-vendors-drop-down 
                    [hotelId]="data.hotelId"
                    [selectedVendor]="selectedVendor"
                    (vendorSelected)="setVendor($event)">
                </app-vendors-drop-down>
            </div>
            <div *ngIf="data.mode === 'view'" class="mr-3">
                <label for="Vendor">{{'stores.vendor' | translate}}</label>
                <input  type="text" class="form-control form-control-sm" [value]="data.vendor.name" readonly>
            </div>

            <div *ngIf="data.tab === 'invoice'" class="mr-3">
                <label for="invoice number">{{'stores.invoice_no' | translate}}.</label>
                <input type="text" class="form-control form-control-sm" [(ngModel)]="data.invoiceNum">
            </div>

            <div *ngIf="data.tab === 'invoice'" class="mr-3">
                <label for="invoice date">{{'stores.invoice_date' | translate}}</label>
                <input type="date" class="form-control form-control-sm" [(ngModel)]="data.invoiceDate" max="{{today}}">
            </div>

            <div class="d-flex flex-column">
                <label for="image">Image</label>
                <div class="d-flex align-items-center">
                    <input 
                        type="file"
                        accept="image/*,.pdf"
                        (change)="fileChange($event.target)"
                        multiple >
                    <ng-container *ngFor="let f of data.selectedFiles">
                        <ng-container *ngIf="f.url.split('.').pop() === 'pdf';else imageView">
                            <img class="cursor-pointer" matTooltip="Open" src="assets/img/pdf_icon.png" width="20" (click)="openTab(f.url)"/>
                        </ng-container>
                    
                        <ng-template #imageView>
                            <img class="cursor-pointer" matTooltip="Open" [src]="f.url" width="50" (click)="openTab(f.url)" />
                        </ng-template>
                    </ng-container>
                </div>
            </div>
        </div>

        <div *ngIf="data.mode !== 'view'" class="row form-group mt-3">
            <div class="col-sm-3">
                <label for="Item Name">{{'stores.select_item' | translate}}</label>
                <ng-select (change)="selectItem($event)" class="form-control form-control-sm">
                    <ng-option *ngFor="let item of masterItems" [value]="item.id">{{item.name}}</ng-option>
                 </ng-select>
            </div>
            <div class="col-sm-1" [ngStyle]="{ 'flex': '0 0 10%', 'max-width': '10%'}">
                <label for="Quantity">{{'stores.quantity' | translate}}</label>
                <input type="number" class="form-control form-control-sm" [ngClass]="{'invalid': selectedItem.quantity > 99999999.9}"
                [(ngModel)]="selectedItem.quantity" (input)="onQuantityChange()" min="1" max="99999999.99">
            </div>
            <div class="col-sm-2" [ngStyle]="{ 'flex': '0 0 12.666667%', 'max-width': '12.666667%'}">
                <label for="Rate">{{'stores.rate' | translate}}</label>
                <input type="number" class="form-control form-control-sm" [(ngModel)]="selectedItem.rate" (input)="onRateChange(selectedItem.rate)"
                >
            </div>
            <div class="col-sm-2" [ngStyle]="{ 'flex': '0 0 12.666667%', 'max-width': '12.666667%'}">
                <label for="Pre Tax">{{'stores.pre_tax' | translate}}</label>
                <input type="number" class="form-control form-control-sm" [ngModel]="selectedItem.pre_tax_amount" readonly>
            </div>
            <div class="col-sm-2" [ngStyle]="{ 'flex': '0 0 12.666667%', 'max-width': '12.666667%'}">
                <label for="Tax">{{'stores.tax' | translate}}</label>
                <input type="number" class="form-control form-control-sm" [ngModel]="selectedItem.tax?.total" readonly>
            </div>
            <div class="col-sm-2" [ngStyle]="{ 'flex': '0 0 12.666667%', 'max-width': '12.666667%'}">
                <label for="Total Amount">{{'stores.total' | translate}}</label>
                <input type="number" class="form-control form-control-sm" [ngModel]="selectedItem.total_amount" readonly>
            </div>
            <div class="col-sm-1">
                <label for="Add button" class="invisible">Add</label>
                <button mat-stroked-button class="btn-sm border-primary" 
                    (click)="pushOrderItem(selectedItem)">{{'stores.add_item_btn' | translate}}
                </button>
            </div>
        </div>

        <!-- List of PO items -->
        <div class="table-container mt-3">
            <table class="table">
                <thead class="thead-dark">
                    <tr>
                        <th>{{'stores.name' | translate}}</th>
                        <th>{{'stores.qty' | translate}}</th>
                        <th></th>
                        <th class="text-right">{{'stores.rate' | translate}}</th>
                        <th class="text-right">{{'stores.pre_tax' | translate}}</th>
                        <th class="text-right">{{'stores.tax' | translate}}</th>
                        <th class="text-right">{{'stores.total' | translate}}</th>
                        <th *ngIf="data.mode !== 'view'"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of orderItems">
                        <td>{{item.name}}</td>
                        <td>{{item.quantity}}</td>
                        <td>{{item?.unit}}</td>
                        <td class="text-right">{{item.rate | number: '1.2-2'}}</td>
                        <td class="text-right">{{item.pre_tax_amount | number: '1.2-2'}}</td>
                        <td class="text-right">{{item.tax?.total | number: '1.2-2'}}</td>
                        <td class="text-right">{{item.total_amount | number: '1.2-2'}}</td>
                        <td *ngIf="data.mode !== 'view'" class="text-right">
                            <div class="d-flex justify-content-end">
                                <mat-icon class="text-danger" (click)="removeOrderItem(item)">delete</mat-icon>
                            </div>
                        </td>
                    </tr>
                    <tr class="bg-light font-weight-bold">
                        <td>{{'stores.total' | translate}}</td>
                        <td>{{orderTotal.quantity}}</td>
                        <td></td>
                        <td></td>
                        <td class="text-right">{{orderTotal.pre_tax_amount | number: '1.2-2'}}</td>
                        <td class="text-right">{{orderTotal.tax.total | number: '1.2-2'}}</td>
                        <td class="text-right">{{orderTotal.total_amount | number: '1.2-2'}}</td>
                        <td *ngIf="data.mode !== 'view'"></td>
                    </tr>
                </tbody>
            </table>
        </div>

    </div>

    <div class="bottom-row">
        <div *ngIf="data.mode !== 'new'" class="d-flex align-items-end mr-2">
            <button mat-stroked-button class="border-primary" (click)="getOrderPdf()"
                [disabled]="printDisabled">
                {{'stores.print' | translate}}
            </button>
        </div>
        <div *ngIf="data.mode !== 'new'" class="d-flex align-items-end mr-2">
            <button mat-stroked-button class="border-primary" (click)="sendEmailToVendor()">
                {{'stores.send_email' | translate}}
            </button>
        </div>
        <div class="d-flex align-items-end mr-2">
            <button *ngIf="(data.tab === 'purchaseOrder' && data.mode !== 'view') || (data.tab === 'invoice') "
                mat-stroked-button type="submit" class="pr-border"
                (click)="submitClick()" [disabled]="!orderItems || orderItems?.length === 0">
                {{'stores.submit' | translate}}
            </button>
        </div>
        <div class="d-flex align-items-end">
            <button mat-stroked-button class="border-danger" (click)="closeDialog()">
                {{'stores.cancel' | translate}}
            </button>
        </div>
    </div>

    <div class="loader-wait" *ngIf="loading">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
            <rect x="15" y="30" width="10" height="40" fill="#6a6a6a">
              <animate attributeName="opacity" dur="1s" repeatCount="indefinite" calcMode="spline" keyTimes="0;0.5;1" keySplines="0.5 0 0.5 1;0.5 0 0.5 1" values="1;0.2;1" begin="-0.6"></animate>
            </rect><rect x="35" y="30" width="10" height="40" fill="#979797">
              <animate attributeName="opacity" dur="1s" repeatCount="indefinite" calcMode="spline" keyTimes="0;0.5;1" keySplines="0.5 0 0.5 1;0.5 0 0.5 1" values="1;0.2;1" begin="-0.4"></animate>
            </rect><rect x="55" y="30" width="10" height="40" fill="#bdbdbd">
              <animate attributeName="opacity" dur="1s" repeatCount="indefinite" calcMode="spline" keyTimes="0;0.5;1" keySplines="0.5 0 0.5 1;0.5 0 0.5 1" values="1;0.2;1" begin="-0.2"></animate>
            </rect><rect x="75" y="30" width="10" height="40" fill="#e2e2e2">
              <animate attributeName="opacity" dur="1s" repeatCount="indefinite" calcMode="spline" keyTimes="0;0.5;1" keySplines="0.5 0 0.5 1;0.5 0 0.5 1" values="1;0.2;1" begin="-1"></animate>
            </rect>
        </svg>
    </div>
</div>