import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'banquetBookingPos'
})
export class BanquetBookingPosPipe implements PipeTransform {

  statusStyle = {
    tentative: {
      'background-color': '#f1acacbd',
      color: 'rgba(20, 20, 20, 0.897)',
      'text-shadow': 'none',
      '--box-shadow-color': '#f1acacbd',
    },
    confirmed: {
      'background-color': '#1ebc38cb',
      '--box-shadow-color': '#1ebc38cb'
    },
    completed: {
      'background-color': '#023009e1',
      '--box-shadow-color': '#023009e1'
    },
  }

  transform(value: any, ...args: unknown[]): unknown {
    let statusStyle = {}
    if (this.statusStyle.hasOwnProperty(value?.status)) {
      statusStyle = this.statusStyle[value?.status];
    }
    return {
      ...this.statusStyle[value?.status]
    };
  }

}
