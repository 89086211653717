import { Component, Inject, OnInit } from '@angular/core';
import { Globals } from 'src/app/core/globals.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PaymentsService } from 'src/app/core/payments.service';
import { DialogComponent } from '../../dialog/dialog.component';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-charge-card',
  templateUrl: './charge-card.component.html',
  styleUrls: ['./charge-card.component.scss']
})
export class ChargeCardComponent implements OnInit {

  cardName = '';
  cardNumber = '';
  cardExpiry = '';
  cardCvv = '';

  submitText: string;
  submitting = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public globals: Globals,
    private paymentService: PaymentsService,
    private dialogRef: MatDialogRef<ChargeCardComponent>,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.submitText = this.data.mode === 'charge' ? 'Charge' : 'Authorize for';
  }

  submit() {  
    this.submitting = true;
    const body = this.getBody();
    if (this.data.mode === 'charge') {
      this.chargeCard(body);
    } else if (this.data.mode === 'authorize') {
      this.authorizeCard(body);
    }
  }

  chargeCard(body) {
    this.paymentService.chargeCard(this.data.hotelId, body)
    .pipe(
      finalize(() => this.submitting = false)
    )
    .subscribe(data => {
      if (data?.success) {
        data.response.transactionStatus = 'charged';
        data.response.gateway = this.data.provider;
        this.dialogRef.close(data);
      }
      else {
        const dialogBody = {
          title: 'Error', body: `Something went wrong`, confirmBtn: 'Ok'
        };
        this.dialog.open(DialogComponent, {data: dialogBody});
      }
    });
  }

  authorizeCard(body) {
    this.paymentService.authorizeCard(this.data.hotelId, body)
    .pipe(
      finalize(() => this.submitting = false)
    )
    .subscribe(data => {
      if (data?.success) {
        data.response.transactionStatus = 'authorized';
        data.response.gateway = this.data.provider;
        this.dialogRef.close(data);
      }
      else {
        const dialogBody = {
          title: 'Error', body: `Something went wrong`, confirmBtn: 'Ok'
        };
        this.dialog.open(DialogComponent, {data: dialogBody});
      }
    });
  }

  getBody() {
    const cardNum = this.cardNumber.replace(/\s/g, '');
    const [month, year] = this.cardExpiry.split('/');
    const [firstName, lastName] = this.cardName.split(' ');
    const body = {
      address: {
        firstName,
        lastName,
        line1: '',
        city: '',
        state: '',
        country: '',
        zip: ''
      },
      cardDetail: {
        cardNo: cardNum,
        cvv: this.cardCvv,
        expiryMonth: Number(month),
        expiryYear: Number(`20${year}`)
      },
      paymentDetail: {
        gateway: this.data.provider,
        amount: this.data?.amount
      }
    };

    return body;
  }

  cardNumChange() {
    this.cardNumber = this.ccFormat(this.cardNumber);
  }

  ccFormat(value: string): string {
    const v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
    const matches = v.match(/\d{4,16}/g);
    const match = matches && matches[0] || '';
    const parts = [];

    for (let i = 0, len = match.length; i < len; i += 4) {
      parts.push(match.substring(i, i + 4));
    }

    if (parts.length) {
      return parts.join(' ');
    } else {
      return value;
    }
  }

  cardExpiryChange() {
    this.cardExpiry = this.ccExpiresFormat(this.cardExpiry);
  }

  ccExpiresFormat(value: string) {
    return value.replace(
      /[^0-9]/g, '' // To allow only numbers
    ).replace(
      /^([2-9])$/g, '0$1' // To handle 3 > 03
    ).replace(
      /^(1{1})([3-9]{1})$/g, '0$1/$2' // 13 > 01/3
    ).replace(
      /^0{1,}/g, '0' // To handle 00 > 0
    ).replace(
      /^([0-1]{1}[0-9]{1})([0-9]{1,2}).*/g, '$1/$2' // To handle 113 > 11/3
    );
  }

  return(e: any) {
    return false;
  }
}
