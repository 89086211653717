import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Globals } from 'src/app/core/globals.service';
import { MaintenanceIssueService } from 'src/app/core/maintenance-issue.service';
import { UserService } from 'src/app/core/user.service';

@Component({
  selector: 'app-maintenance-home',
  templateUrl: './maintenance-home.component.html',
  styleUrls: ['./maintenance-home.component.scss'],
})
export class MaintenanceHomeComponent implements OnInit {
  type = '';
  tabItems: { key: string; name: string }[];
  userFeatures: any;
  housekeeping = true;
  laundry = true;
  lostAndFound = true;
  maintenance = true;
  constructor(
    private route: ActivatedRoute,
    public globals: Globals,
    private maintService: MaintenanceIssueService,
    private userService: UserService
  ) {}

  currentTab: string;
  hotelId: any;
  currentUser = '';
  enabled = false;
  ngOnInit(): void {
    Object.freeze(this.tabItems);
    this.route.params.subscribe((params) => {
      this.hotelId = params.hotelId;
      this.globals.changeHotelID(this.hotelId);
    });
    this.route.queryParams.subscribe((queryParams) => {
      this.currentUser = queryParams.user;
      this.type = queryParams.type;
    });
    this.getUserFeatures();
    this.checktype();
    const lastIndex = sessionStorage.getItem('maintenanceCurrentTab')
      ? sessionStorage.getItem('maintenanceCurrentTab')
      : 0;
    this.changeCurrentTab(lastIndex);
    this.maintService.getMaintenanceConfig(this.hotelId).subscribe((data) => {
      this.enabled = data.isEnabled;
      // this.groupId = data.conf.chainId;
    });
  }
  changeCurrentTab(index) {
    this.currentTab = this.tabItems[index].key;
    sessionStorage.setItem('maintenanceCurrentTab', index);
  }
  checktype() {
    this.tabItems = [
      { key: 'maintenance', name: 'Maintenance' },
      { key: 'housekeeping', name: 'Housekeeping' },
      { key: 'laundry', name: 'Laundry' },
      { key: 'lost&found', name: 'Lost & Found' },
      // { key: 'tasks', name: 'Tasks' },
    ];
  }

  getUserFeatures() {
    // const body = {username: this.currentUser};
    // this.userService.getUserData(body).subscribe(data => {
    //   this.userFeatures = data?.features.live;
      // if (this.userFeatures.hasOwnProperty('housekeeping')) {
      //   this.housekeeping = this.userFeatures.housekeeping;
      // }if(this.userFeatures.hasOwnProperty('laundry')){
      //   this.laundry = this.userFeatures.laundry;
      // }if(this.userFeatures.hasOwnProperty('lostAndFound')){
      //   this.lostAndFound = this.userFeatures.lostAndFound;
      // }if(this.userFeatures.hasOwnProperty('maintenance')){
      //   this.maintenance = this.userFeatures.maintenance;
      // }
    // });
    this.userService.userFeatures$.subscribe(res => {
      this.userFeatures = res?.live;
      if (this.userFeatures?.hasOwnProperty('housekeeping')) {
        this.housekeeping = this.userFeatures.housekeeping;
      }if(this.userFeatures?.hasOwnProperty('laundry')){
        this.laundry = this.userFeatures.laundry;
      }if(this.userFeatures?.hasOwnProperty('lostAndFound')){
        this.lostAndFound = this.userFeatures.lostAndFound;
      }if(this.userFeatures?.hasOwnProperty('maintenance')){
        this.maintenance = this.userFeatures.maintenance;
      }
    })
  }
}
