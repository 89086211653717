<div class="container">
  <div class="heading">
    <br />
    <h1 *ngIf="mode === 'new'">{{ "hr.modify_employee" | translate }}</h1>
    <h1 *ngIf="mode === 'modify'">{{ "hr.create_employee" | translate }}</h1>
    <mat-divider></mat-divider>
  </div>

  <div class="content mt-3">
    <form [formGroup]="employeeForm" (ngSubmit)="onSubmit(employeeForm)">
      <div class="accordion" id="accordionExample">
        <div class="card">
          <div class="card-header" id="headingOne">
            <h2 class="mb-0">
              <button
                class="btn btn-block text-left"
                type="button"
                data-toggle="collapse"
                data-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
                (click)="changeState('Employee')"
              >
                {{ "hr.employee" | translate }}
                <span
                  [@rotate]="AnimationStates['Employee']"
                  class="material-icons float-right"
                >
                  keyboard_arrow_up
                </span>
              </button>
            </h2>
          </div>

          <div
            id="collapseOne"
            class="collapse show"
            aria-labelledby="headingOne"
            data-parent="#accordionExample"
          >
            <div class="card-body">
              <div class="row mb-2">
                <div class="col-sm-4">
                  <label for="name"
                    >{{ "hr.name" | translate }} <span style="color: red">*</span></label
                  >
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    formControlName="name"
                    required
                  />
                </div>
                <div class="col-sm-3">
                  <label for="department"
                    >{{ "hr.department" | translate }} <span style="color: red">*</span></label
                  >
                  <select
                    class="form-control form-control-sm"
                    formControlName="department"
                  >
                    <option value=""></option>
                    <option *ngFor="let option of departments" [value]="option">
                      {{ option }}
                    </option>
                  </select>
                </div>
                <div class="col-sm-3">
                  <label for="position"
                    >{{ "hr.position" | translate }} <span style="color: red">*</span></label
                  >
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    formControlName="position"
                  />
                </div>
                <div class="col-sm-2">
                  <label for="gender">{{ "hr.gender" | translate }}</label>
                  <select
                    class="form-control form-control-sm"
                    formControlName="gender"
                  >
                    <option value="Male">{{ "hr.male" | translate }}</option>
                    <option value="Female">{{ "hr.femmale" | translate }}</option>
                    <option value="Other">{{ "hr.other" | translate }}</option>
                  </select>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-sm-2">
                  <label for="joinDate">{{ "hr.date_of_joining" | translate }}:</label>
                  <input
                    type="date"
                    class="form-control form-control-sm"
                    formControlName="joinDate"
                    onkeydown="return false"
                  />
                </div>
                <div class="col-sm-3">
                  <label for="repMngr">{{ "hr.reporting_manager" | translate }}</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    formControlName="reportingManager"
                  />
                </div>
                
                <div class="col-sm-3">
                  <label for="phone">{{ "hr.phone_number" | translate }}</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    formControlName="phone"
                  />
                </div>
                <div class="col-sm-4">
                  <label for="emailId">{{ "hr.email_id" | translate }}</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    formControlName="emailId"
                  />
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-sm-2">
                  <label for="exitDate">{{ "hr.date_of_exit" | translate }}:</label>
                  <input
                    type="date"
                    class="form-control form-control-sm"
                    formControlName="exitDate"
                    onkeydown="return false"
                  />
                </div>
                <div class="col-sm-2">
                  <label for="dob">{{ "hr.date_of_birth" | translate }}:</label>
                  <input
                    type="date"
                    class="form-control form-control-sm"
                    formControlName="dob"
                    onkeydown="return false"
                  />
                </div>
                <div class="col-sm-5">
                  <label for="addressLine1">{{ "hr.address" | translate }}</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    formControlName="addressLine1"
                  />
                </div>
                <div class="col-sm-3">
                  <label for="city">{{ "hr.city" | translate }}</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    formControlName="city"
                  />
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-sm-2">
                  <label for="state">{{ "hr.state" | translate }}</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    formControlName="state"
                  />
                </div>
                <div class="col-sm-2">
                  <label for="zipCode">{{ "hr.zip_code" | translate }}</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    formControlName="zipCode"
                  />
                </div>
                <div class="col-sm-2">
                  <label for="country">{{ "hr.country" | translate }}</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    formControlName="country"
                  />
                </div>
                <div class="col-sm-2">
                  <label for="employeeStatus">{{ "hr.employee_status" | translate }}</label>
                  <select
                    class="form-control form-control-sm"
                    formControlName="employeeStatus"
                  >
                    <option value="Active">{{ "hr.active" | translate }}</option>
                    <option value="Resigned">{{ "hr.resigned" | translate }}</option>
                  </select>
                </div>
                <div
                  class="col-sm-4"
                  *ngIf="chainId != '' && showHotels"
                >
                  <label for="hotel">{{ "hr.hotel" | translate }}</label>
                  <select
                    class="form-control form-control-sm"
                    formControlName="hotel"
                  >
                    <option
                      *ngFor="let option of hotels"
                      [value]="option['name']"
                    >
                      {{ option["name"] }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-sm-12">
                  <label for="comments">{{ "hr.comments" | translate }}</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    formControlName="comments"
                  />
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-sm-3">
                  <label for="accountNum">{{ "hr.account_number" | translate }}</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    formControlName="accountNum"
                  />
                </div>
                <div class="col-sm-2">
                  <label for="ifscCode">{{ "hr.ifsc_code" | translate }}</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    formControlName="ifscCode"
                  />
                </div>
                <div class="col-sm-3">
                  <label for="accountName">{{ "hr.account_name" | translate }}</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    formControlName="accountName"
                  />
                </div>
                <div class="col-sm-3">
                  <label for="bankName">{{ "hr.bank_name" | translate }}</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    formControlName="bankName"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" id="headingTwo">
            <h2 class="mb-0">
              <button
                class="btn btn-block text-left collapsed"
                type="button"
                [disabled]="!showSalary"
                data-toggle="collapse"
                data-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
                (click)="changeState('Salary')"
              >
                {{ "hr.salary" | translate }}
                <span
                  [@rotate]="AnimationStates['Salary']"
                  class="material-icons float-right"
                >
                  keyboard_arrow_up
                </span>
              </button>
            </h2>
          </div>
          <div
            id="collapseTwo"
            class="collapse"
            aria-labelledby="headingTwo"
            data-parent="#accordionExample"
          >
            <div class="card-body">
              <div class="row mb-3">
                <div class="col-sm-2">
                  <label for="salary"
                    >{{ "hr.gross_salary" | translate }} <span style="color: red">*</span></label
                  >
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    formControlName="salary"
                  />
                </div>
                <div class="col-sm-2">
                  <label for="procesSalary">{{ "hr.process_salary" | translate }}</label>
                  <select
                    class="form-control form-control-sm"
                    formControlName="procesSalary"
                  >
                    <option value="False">{{ "hr.false" | translate }}</option>
                    <option value="True">{{ "hr.true" | translate }}</option>
                  </select>
                </div>
                <div class="col-sm-3">
                  <label for="paymentMode">{{ "hr.payment_mode" | translate }}</label>
                  <select
                    class="form-control form-control-sm"
                    formControlName="paymentMode"
                  >
                    <option value=""></option>
                    <option
                      *ngFor="let option of paymentModes"
                      [value]="option"
                    >
                      {{ option }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-sm-3">
                  <input
                    type="checkbox"
                    formControlName="noSalaryDeduction"
                  /><span> {{ "hr.no_salary_deduction" | translate }}</span>
                </div>
                <div class="col-sm-3">
                  <input
                    type="checkbox"
                    formControlName="noSalaryAddition"
                  /><span> {{ "hr.no_salary_addition" | translate }}</span>
                </div>
              </div>
              <!-- <div class="row mb-3"> -->
              <h3>{{ "hr.split_salary_config_override" | translate }}</h3>
              <!-- </div> -->
              <div class="row mb-3">
                <div class="col-lg-3">
                  <label for="splitSalaryHeading">{{ "hr.salary_heading" | translate }}</label>
                  <select
                    class="form-control form-control-sm"
                    id="splitSalaryHeading"
                    [(ngModel)]="splitHeading"
                    [ngModelOptions]="{ standalone: true }"
                  >
                    <option value=""></option>
                    <option
                      *ngFor="let option of splitSalaryConfig"
                      [value]="option['heading']"
                    >
                      {{ option["heading"] }}
                    </option>
                  </select>
                </div>
                <div class="col-lg-2">
                  <label for="splitSalaryCalculationType"
                    >{{ "hr.calculation_type" | translate }}</label
                  >
                  <select
                    class="form-control form-control-sm"
                    id="splitSalaryCalculationType"
                    [(ngModel)]="splitCalcType"
                    [ngModelOptions]="{ standalone: true }"
                  >
                    <option value="Fixed">{{ "hr.fixed" | translate }}</option>
                    <option value="Variable(%)">{{ "hr.variable" | translate }}(%)</option>
                    <option value="Remaining">{{ "hr.remaining" | translate }}</option>
                  </select>
                </div>
                <div class="col-lg-2">
                  <label for="splitSalaryValue">{{ "hr.value" | translate }}</label>
                  <input
                    class="form-control form-control-sm"
                    type="text"
                    id="splitSalaryValue"
                    placeholder="0"
                    [(ngModel)]="splitValue"
                    [ngModelOptions]="{ standalone: true }"
                  />
                </div>
                <div class="col-lg-2" class="buttonSpace">
                  <button
                    mat-stroked-button
                    type="button"
                    class="btn pr-border mr-2"
                    style="margin-top: 4px"
                    (click)="addSplit()"
                    [disabled] = "!splitHeading"
                  >
                    {{ "hr.add" | translate }}
                  </button>
                </div>
              </div>
              <div class="row form-group">
                <div class="col-lg-10">
                  <table
                    class="table table-bordered"
                    [hidden]="employeeSplitSalaryConfig.length == 0"
                  >
                    <thead class="row-highlight">
                      <th width="5%">{{ "hr.salary_heading" | translate }}</th>
                      <th width="5%">{{ "hr.calculation_type" | translate }}</th>
                      <th width="5%">{{ "hr.value" | translate }}</th>
                      <th width="3%">{{ "hr.action" | translate }}</th>
                    </thead>
                    <tbody>
                      <tr
                        *ngFor="
                          let data of employeeSplitSalaryConfig;
                          let dataindex = index
                        "
                      >
                        <td>{{ data["heading"] }}</td>
                        <td>{{ data["calculationType"] }}</td>
                        <td>{{ data["value"] }}</td>
                        <td>
                          <span title="Delete"
                            ><button
                              type="button"
                              class="btn"
                              mat-icon-button
                              (click)="removeSplit(dataindex)"
                            >
                              <mat-icon>delete</mat-icon>
                            </button></span
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <h3>{{ "hr.additional_salary_config_override" | translate }}</h3>
              <div class="row mb-3">
                <div class="col-lg-3">
                  <label for="additionalSalaryHeading">{{ "hr.salary_heading" | translate }}</label>
                  <select
                    class="form-control form-control-sm"
                    id="additionalSalaryHeading"
                    [(ngModel)]="additionalHeading"
                    [ngModelOptions]="{ standalone: true }"
                  >
                    <option value=""></option>
                    <option
                      *ngFor="let option of additionalSalaryConfig"
                      [value]="option['heading']"
                    >
                      {{ option["heading"] }}
                    </option>
                  </select>
                </div>
                <div class="col-lg-2">
                  <label for="additionalSalaryCalculationType"
                    >{{ "hr.calculation_type" | translate }}</label
                  >
                  <select
                    class="form-control form-control-sm"
                    id="additionalSalaryCalculationType"
                    [(ngModel)]="additionalCalcType"
                    [ngModelOptions]="{ standalone: true }"
                  >
                    <option value="Fixed">{{ "hr.fixed" | translate }}</option>
                    <option value="Variable(%)">{{ "hr.variable" | translate }}(%)</option>
                  </select>
                </div>
                <div class="col-lg-2">
                  <label for="additionalSalaryValue">{{ "hr.value" | translate }}</label>
                  <input
                    class="form-control form-control-sm"
                    type="text"
                    id="additionalSalaryValue"
                    placeholder="0"
                    [(ngModel)]="additionalValue"
                    [ngModelOptions]="{ standalone: true }"
                  />
                </div>
                <div class="col-lg-2" class="buttonSpace">
                  <button
                    mat-stroked-button
                    type="button"
                    class="btn pr-border mr-2"
                    style="margin-top: 4px"
                    (click)="addAdditionalConfig()"
                    [disabled] = "!additionalHeading"
                  >
                    {{ "hr.add" | translate }}
                  </button>
                </div>
              </div>
              <div class="row form-group">
                <div class="col-lg-10">
                  <table
                    class="table table-bordered"
                    [hidden]="employeeAdditionalSalaryConfig.length == 0"
                  >
                    <thead class="row-highlight">
                      <th width="5%">{{ "hr.salary_heading" | translate }}</th>
                      <th width="5%">{{ "hr.calculation_type" | translate }}</th>
                      <th width="5%">{{ "hr.value" | translate }}</th>
                      <th width="3%">{{ "hr.action" | translate }}</th>
                    </thead>
                    <tbody>
                      <tr
                        *ngFor="
                          let data of employeeAdditionalSalaryConfig;
                          let dataindex = index
                        "
                      >
                        <td>{{ data.heading }}</td>
                        <td>{{ data.calculationType }}</td>
                        <td>{{ data.value }}</td>
                        <td>
                          <span title="Delete"
                            ><button
                              type="button"
                              class="btn"
                              mat-icon-button
                              (click)="removeAdditionalConfig(dataindex)"
                            >
                              <mat-icon>delete</mat-icon>
                            </button></span
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <!-- <div class="row mb-3"> -->
              <h3>{{ "hr.salary_deduction_config_override" | translate }}</h3>
              <!-- </div> -->
              <div class="row mb-3">
                <div class="col-lg-3">
                  <label for="salaryDeductionHeading">{{ "hr.salary_heading" | translate }}</label>
                  <select
                    class="form-control form-control-sm"
                    id="salaryDeductionHeading"
                    [(ngModel)]="deductHeading"
                    [ngModelOptions]="{ standalone: true }"
                  >
                    <option value=""></option>
                    <option
                      *ngFor="let option of salaryDeductionConfig"
                      [value]="option['heading']"
                    >
                      {{ option["heading"] }}
                    </option>
                  </select>
                </div>
                <div class="col-lg-2">
                  <label for="salaryDeductionCalculationType"
                    >{{ "hr.calculation_type" | translate }}</label
                  >
                  <select
                    class="form-control form-control-sm"
                    id="salaryDeductionCalculationType"
                    [(ngModel)]="deductCalcType"
                    [ngModelOptions]="{ standalone: true }"
                  >
                    <option value="Fixed">{{ "hr.fixed" | translate }}</option>
                    <option value="Variable(%)">{{ "hr.variable" | translate }}(%)</option>
                  </select>
                </div>
                <div class="col-lg-2">
                  <label for="deductionSalaryValue">{{ "hr.value" | translate }}</label>
                  <input
                    class="form-control form-control-sm"
                    type="text"
                    id="deductionSalaryValue"
                    placeholder="0"
                    [(ngModel)]="deductValue"
                    [ngModelOptions]="{ standalone: true }"
                  />
                </div>
                <div class="col-lg-3">
                  <br>
                  <label><input type="checkbox" id="checkbox" [(ngModel)]="deductionNotAttendanceBased" [ngModelOptions]="{ standalone: true }"> {{ "hr.not_attendance_based" | translate }}</label>
                </div>
                <div class="col-lg-2" class="buttonSpace">
                  <button
                    mat-stroked-button
                    type="button"
                    class="btn pr-border mr-2"
                    style="margin-top: 4px"
                    (click)="addDeductionConfig(deductionNotAttendanceBased)"
                    [disabled] = "!deductHeading"
                  >
                    {{ "hr.add" | translate }}
                  </button>
                </div>
              </div>
              <div class="row form-group">
                <div class="col-lg-10">
                  <table
                    class="table table-bordered"
                    [hidden]="employeeSalaryDeductionConfig.length == 0"
                  >
                    <thead class="row-highlight">
                      <th width="5%">{{ "hr.salary_heading" | translate }}</th>
                      <th width="5%">{{ "hr.calculation_type" | translate }}</th>
                      <th width="5%">{{ "hr.value" | translate }}</th>
                      <th width="5%">{{ "hr.not_attendance_based" | translate }</th>
                      <th width="3%">{{ "hr.action" | translate }}</th>
                    </thead>
                    <tbody>
                      <tr
                        *ngFor="
                          let data of employeeSalaryDeductionConfig;
                          let dataindex = index
                        "
                      >
                        <td>{{ data.heading }}</td>
                        <td>{{ data.calculationType }}</td>
                        <td>{{ data.value }}</td>
                        <td>{{ data.deductionNotAttendanceBased }}</td>
                        <td>
                          <span title="Delete"
                            ><button
                              type="button"
                              class="btn"
                              mat-icon-button
                              (click)="removeDeductionConfig(dataindex)"
                            >
                              <mat-icon>delete</mat-icon>
                            </button></span
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" id="headingThree">
            <h2 class="mb-0">
              <button
                class="btn btn-block text-left collapsed"
                type="button"
                data-toggle="collapse"
                [disabled]="!showSalary"
                data-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
                (click)="changeState('Leave')"
              >
                {{ "hr.leave" | translate }}
                <span
                  [@rotate]="AnimationStates['Leave']"
                  class="material-icons float-right"
                >
                  keyboard_arrow_up
                </span>
              </button>
            </h2>
          </div>
          <div
            id="collapseThree"
            class="collapse"
            aria-labelledby="headingThree"
            data-parent="#accordionExample"
          >
            <div class="card-body">
              <div class="row mb-3">
                <div class="col-sm-2">
                  <label for="paidLeave">{{ "hr.paid_leave" | translate }}</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    formControlName="paidLeave"
                  />
                </div>
                <div class="col-sm-2">
                  <label for="casualLeave">{{ "hr.casual_leave" | translate }}</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    formControlName="casualLeave"
                  />
                </div>
                <div class="col-sm-3">
                  <label for="advance">{{ "hr.advance_balance" | translate }}</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    formControlName="advance"
                  />
                </div>
                <div class="col-sm-3">
                  <label for="advanceInstallment">{{ "hr.advance_installment" | translate }}</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    formControlName="advanceInstallment"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" id="headingFour">
            <h2 class="mb-0">
              <button
                class="btn btn-block text-left collapsed"
                type="button"
                data-toggle="collapse"
                data-target="#collapseFour"
                aria-expanded="false"
                aria-controls="collapseFour"
                (click)="changeState('Regulatory')"
              >
                {{ "hr.regulatory" | translate }}
                <span
                  [@rotate]="AnimationStates['Regulatory']"
                  class="material-icons float-right"
                >
                  keyboard_arrow_up
                </span>
              </button>
            </h2>
          </div>
          <div
            id="collapseFour"
            class="collapse"
            aria-labelledby="headingFour"
            data-parent="#accordionExample"
          >
            <div class="card-body">
              <div class="row mb-3">
                <div class="col-sm-2">
                  <label for="pfNum">{{ "hr.pf_number" | translate }}</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    formControlName="pfNum"
                  />
                </div>
                <div class="col-sm-3">
                  <label for="pfUANNum">{{ "hr.pf_uan_number" | translate }}</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    formControlName="pfUANNum"
                  />
                </div>
                <div class="col-sm-2">
                  <label for="pfPassword">{{ "hr.pf_password" | translate }}</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    formControlName="pfPassword"
                  />
                </div>
                <div class="col-sm-3">
                  <label for="ESINumber">{{ "hr.esi_number" | translate }}</label>
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    formControlName="ESINumber"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header" id="headingFive">
            <h2 class="mb-0">
              <button
                class="btn btn-block text-left collapsed"
                type="button"
                data-toggle="collapse"
                data-target="#collapseFive"
                aria-expanded="false"
                aria-controls="collapseFive"
                (click)="changeState('Identity')"
              >
                {{ "hr.identity" | translate }}
                <span
                  [@rotate]="AnimationStates['Identity']"
                  class="material-icons float-right"
                >
                  keyboard_arrow_up
                </span>
              </button>
            </h2>
          </div>
          <div
            id="collapseFive"
            class="collapse"
            aria-labelledby="headingFive"
            data-parent="#accordionExample"
          >
            <div class="card-body">
              <div
                formArrayName="items"
                *ngFor="
                  let item of employeeForm.get('items')['controls'];
                  let i = index
                "
              >
                <!-- <div [formGroupName]="i">
                                    <input formControlName="name" placeholder="Item name">
                                    <input formControlName="description" placeholder="Item description">
                                    <input formControlName="price" placeholder="Item price">
                                </div> -->

                <div class="row form-group mb-3" [formGroupName]="i">
                  <div
                    class="col-sm-2"
                    style="
                      padding-top: 20px;
                      margin: top 10px;
                      padding-right: 0%;
                      margin-right: 0%;
                    "
                  >
                    <button
                      mat-icon-button
                      type="button"
                      class="pr-border form-control-sm"
                      (click)="addItem()"
                    >
                      +
                    </button>
                    <button
                      mat-icon-button
                      type="button"
                      class="pr-border form-control-sm"
                      [hidden]="i == 0"
                      (click)="removeItem(i)"
                    >
                      -
                    </button>
                  </div>
                  <div
                    class="col-sm-2"
                    style="margin-left: 0%; padding-left: 0%"
                  >
                    <label for="idName">{{ "hr.id_name" | translate }}</label>
                    <select
                      class="form-control form-control-sm"
                      formControlName="idName"
                    >
                      <option value=""></option>
                      <option *ngFor="let option of idTypes" [value]="option">
                        {{ option }}
                      </option>
                    </select>
                  </div>
                  <div class="col-sm-3">
                    <label for="idDetail">{{ "hr.id_detail" | translate }}</label>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      formControlName="idDetail"
                    />
                  </div>
                  <div class="form-group col-md-2 mb-3">
                    <label for="fileup">{{ "hr.photo_id" | translate }}</label><br />
                    <div class="upload-btn-wrapper">
                      <button
                        id="fileup"
                        type="button"
                        class="btn1 form-control form-control-sm"
                      >
                        {{ "hr.upload" | translate }}
                      </button>
                      <input
                        type="file"
                        name="documents"
                        accept=".pdf, image/jpg, image/jpeg, image/png"
                        (change)="handleInput($event.target, i)"
                      />
                    </div>
                  </div>
                  <div
                    class="form-group col-md-2 mb-3"
                    *ngIf="fileselected?.length > 0"
                    style="display: flex"
                  >
                    <label for="files"></label>
                    <ul style="display: flex">
                      <!-- <li id="files" style='display: inline; padding-right: 5px; padding-top: 5px; font-size: 12px;' 
                                            *ngIf="item.length > 0">
                                                <div class="cancel" (click)="removeImage(item.value.file, i)">+</div>
                                                <a href="{{item.value.file}}" target="_blank">
                                                    <img src="{{item.value.file}}" width="70px" height="50px" alt="">
                                                </a>
                                            </li> -->
                      <li
                        id="files"
                        style="
                          display: inline;
                          padding-right: 5px;
                          padding-top: 5px;
                          font-size: 12px;
                        "
                        *ngFor="let j of fileselected; let k = index"
                      >
                        <div
                          *ngIf="k == i"
                          class="cancel"
                          (click)="removeImage(j, i)"
                        >
                          +
                        </div>
                        <a *ngIf="k == i" href="{{ j['url'] }}" target="_blank">
                          <img
                            src="{{ j['url'] }}"
                            width="70px"
                            height="50px"
                            alt=""
                          />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div class="bottom-row">
        <div class="d-flex align-items-end mr-2">
          <button
            mat-stroked-button
            type="submit"
            class="pr-border"
            [disabled]="!employeeForm.valid"
          >
            {{ "hr.submit" | translate }}
          </button>
        </div>
        <div class="d-flex align-items-end">
          <button
            type="button"
            mat-stroked-button
            class="border-danger"
            (click)="closeDialog()"
          >
            {{ "hr.cancel" | translate }}
          </button>
        </div>
      </div>
    </form>
  </div>
</div>

