import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-add-gst',
  templateUrl: './add-gst.component.html',
  styleUrls: ['./add-gst.component.scss']
})
export class AddGstComponent implements OnInit {

  addGstForm: FormGroup;
  totalAmount = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private matDialogRef: MatDialogRef<AddGstComponent>,
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.initForm()
    if (this.data?.expensesGst) {
      try {
        const expensesGst = JSON.parse(this.data.expensesGst);
        this.addGstForm.controls.preTaxAmount.setValue(expensesGst.preTaxAmount);
        if (expensesGst.taxes?.length) {
          expensesGst.taxes.forEach(e => {
            this.addItem(e)
          })
        }
      } catch {

      }
    } 
    this.addItem();
  }

  initForm() {
    this.addGstForm = this.fb.group({
      preTaxAmount: [0, Validators.min(0)],
      taxes: this.fb.array([])
    });

    this.preTaxChange();
  }

  preTaxChange() {
    this.addGstForm.get('preTaxAmount').valueChanges.subscribe(value => {
      this.totalAmount = this.getTotal();
    });
  }

  taxNameChange(index) {
    const cTax = this.itemsFormArray.at(index)
    const taxName = cTax.get('taxName').value;
    const taxAmount = this.getTaxAmount(taxName);
    cTax.get('amount').patchValue(taxAmount);
  
    this.totalAmount = this.getTotal();
  }

  getTaxAmount(taxName) {
    let taxVal = 0;
    this.data.taxNames?.forEach(t => {
      if (t.name === taxName) {
        taxVal = t.value;
      }
    });
  
    const preTaxAmount = this.addGstForm.controls.preTaxAmount.value;
    return (preTaxAmount * taxVal) / 100;
  }

  getTotal() {
    let total = parseFloat(this.addGstForm.get('preTaxAmount').value || 0);
    this.itemsFormArray.controls.forEach((control) => {
      total += parseFloat(control.get('amount').value || 0)
    });
    return total;
  }

  get itemsFormArray() {
    return this.addGstForm.get('taxes') as FormArray;
  }

  addItem(data?) {
    const newItem = this.fb.group({
      taxName: [data?.taxName || '', Validators.required],
      amount: [data?.amount || 0, Validators.min(0)]
    });

    this.itemsFormArray.push(newItem);
  }

  removeItem(index: number) {
    this.itemsFormArray.removeAt(index);
    this.totalAmount = this.getTotal();
  }

  submit() {
    this.matDialogRef.close({
      gstData: this.addGstForm.value, 
      totalAmount: this.totalAmount
    })
  }

  numberOnly(event: any) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (
      // Only allow one decimal point
      (charCode === 46 && (event.target as HTMLInputElement).value.includes('.')) || 
      // Disallow non-numeric characters
      (charCode !== 46 && charCode > 31 && (charCode < 48 || charCode > 57))
    ) {
      event.preventDefault();
    }
  }
}
