<div class="primary_modal_wrapper">
    <div class="modal_heading">
        <h1></h1>

        <svg [mat-dialog-close]="false" role="button"
            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
          <path
            d="M315.3 411.3c-6.253 6.253-16.37 6.253-22.63 0L160 278.6l-132.7 132.7c-6.253 6.253-16.37 6.253-22.63 0c-6.253-6.253-6.253-16.37 0-22.63L137.4 256L4.69 123.3c-6.253-6.253-6.253-16.37 0-22.63c6.253-6.253 16.37-6.253 22.63 0L160 233.4l132.7-132.7c6.253-6.253 16.37-6.253 22.63 0c6.253 6.253 6.253 16.37 0 22.63L182.6 256l132.7 132.7C321.6 394.9 321.6 405.1 315.3 411.3z" />
        </svg>
    </div>

    <mat-divider></mat-divider>

    <div class="modal_body">
        <table class="table">
            <thead class="thead-dark">
                <tr>
                    <th>Guest Name</th>
                    <th>Source</th>
                    <th>Checkin</th>
                    <th>Checkout</th>
                    <th>Amount</th>
                    <th>Invoice num</th>
                    <th>E-Invoice Link</th>
                </tr>
            </thead>

            <tbody>
                <tr *ngFor="let item of invoiceData">
                    <td>{{item.guestName}}</td>
                    <td>{{item.source}}</td>
                    <td>{{item.checkin}}</td>
                    <td>{{item.checkout}}</td>
                    <td>{{item.amount}}</td>
                    <td>{{item.invoiceNum}}</td>
                    <td>
                        <a *ngIf="item.eInvoiceLink"
                            [href]="item.eInvoiceLink" target="_blank" rel="noopener noreferrer">
                            Open
                        </a>

                        <button *ngIf="!item.eInvoiceLink && !item.failed"
                            mat-raised-button
                            (click)="generateEInvoice(item)">
                            Generate
                        </button>

                        <span *ngIf="item.failed" class="text-danger">Failed</span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>