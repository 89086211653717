<div>
    <div class="heading flex-space-bw">
        <h2>{{'stores.title' | translate}}</h2>
    </div>
    <mat-divider></mat-divider>
    
    <div class="container" *ngIf="storeConfig?.isEnabled">
        <div class="tabs" *ngIf="storeFeatures?.stores">
            <ul>
                <ng-container *ngFor="let tab of tabItems; let i = index">
                    <li *ngIf="storeFeatures[tab.key] && storeFeatures[tab.key][tab.key]"
                        (click)="changeCurrentTab(i);"
                        [ngClass]="{'active-tab': currentTab === tab.key}">
                        {{tab.name}}
                    </li>
                </ng-container>
            </ul>
        </div>

        <ng-container *ngIf="storeFeatures?.stores">
            <div class="content">
                <ng-container *ngIf="currentTab === 'settings'">
                    <app-settings [hotelId]="hotelId"></app-settings>
                </ng-container>

                <ng-container *ngIf="currentTab === 'purchaseOrder'">
                    <app-purchase-order [hotelId]="hotelId" [features]="storeFeatures" [tab]="currentTab"></app-purchase-order>
                </ng-container>

                <ng-container *ngIf="currentTab === 'invoice'">
                    <app-purchase-order [hotelId]="hotelId" [features]="storeFeatures" [tab]="currentTab"></app-purchase-order>
                </ng-container>

                <ng-container *ngIf="currentTab === 'indent'">
                    <app-indent [hotelId]="hotelId" [features]="storeFeatures"></app-indent>
                </ng-container>

                <ng-container *ngIf="currentTab === 'stock'">
                    <app-dept-stock [hotelId]="hotelId" [features]="storeFeatures"></app-dept-stock>
                </ng-container>
                
                <ng-container *ngIf="currentTab === 'consumption'">
                    <app-consumption [hotelId]="hotelId" [features]="storeFeatures"></app-consumption>
                </ng-container>

                <ng-container *ngIf="currentTab === 'reports'">
                    <app-store-reports [hotelId]="hotelId" [features]="storeFeatures"></app-store-reports>
                </ng-container>
            </div>
        </ng-container>
    </div>
    <ng-container *ngIf="!storeConfig?.isEnabled && loaded">
        <div class="text-danger mt-3 h4">
            {{'stores.disable_text' | translate}}
        </div>
    </ng-container>
</div>