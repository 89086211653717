import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { DialogComponent } from '../_shared/dialog/dialog.component';
import { Globals } from './globals.service';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor{

  ignoreUrls = [
    {method: 'GET', url: `${environment.apiUrl}/invoice-details`},
    {method: 'POST', url: `${environment.apiUrl}/pms/balances`}
  ];

  constructor(
    private globals: Globals,
    private dialog: MatDialog
  ) { }
  
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let showLoader = true;
    this.ignoreUrls.forEach(e => {
      if (e.method === req.method && 
        req.url.indexOf(e.url) > -1) {
          showLoader = false;
          return;
        }
    });
    
    if (showLoader) {
      this.globals.setisLoading(true, req.url);
    }


    return next.handle(req)
      .pipe(
        finalize(() => this.globals.setisLoading(false, req.url)),
        tap((event: any) => {},
        (error: any) => {
          if (error.status === 500) {
            this.showErrorMsg();
          }
          else if (error.status === 422) {
            this.showErrorMsg(error.error.error);
          }
        }
        )
      );
  }

  showErrorMsg(msg?: string) {
    const WarningDialog = {
      title: 'ERROR !', 
      body: msg ? msg : `Something went wrong. Please try again`, 
      cancelBtn: 'Dismiss'
    };
    this.dialog.open(DialogComponent, {
      data: WarningDialog, height:'20%'
    });
  }
}
