import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { interval, Subscription } from 'rxjs';
import { PosService } from 'src/app/core/pos.service';
import { BillComponent } from '../bill/bill.component';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from 'src/app/_shared/dialog/dialog.component';
import { UserService } from 'src/app/core/user.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { PaymentsDetailsComponent } from '../../stayview/payment-details/payment-details.component';

@Component({
  selector: 'app-pos-table-view',
  templateUrl: './pos-table-view.component.html',
  styleUrls: ['./pos-table-view.component.scss']
})
export class PosTableViewComponent implements OnInit {
  @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;

  private subscriptions: Subscription[] = [];

  posName: string;
  hotelId: string;
  tables = [];
  orders = {billed: [], unbilled: []};
  currency = localStorage.getItem('currencyCode');
  posConfig: any = {};
  unsortedTables = []

  constructor(
    private posService: PosService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private userService: UserService,
    private router: Router
  ) { }

  ngOnInit(): void {
    const snapshot = this.route.snapshot;
    this.hotelId = snapshot.params?.hotelId;
    this.posName = snapshot.queryParams?.posName;
    this.unsortedTables = JSON.parse(snapshot.queryParams?.tables);
    const ordersStr = snapshot.queryParams?.orders;
    this.posConfig = this.posService.currentPosConfig;
    if (ordersStr) {
      this.orders = JSON.parse(ordersStr);
      const tableOrders = this.setTableOrder(this.orders);
      this.categorizeTables(this.unsortedTables, tableOrders);
      this.updateTimesElapsed();
    } else {
      this.getOrders(this.unsortedTables);
    }
    this.subscriptions.push(
      interval(60000).subscribe(() => this.updateTimesElapsed())
    );
  }

  getOrders(tables) {
    this.userService.getCurrentDate(this.hotelId).subscribe(res => {
      this.posService.getPosOrders(this.hotelId, this.posName, res?.currentDate, res?.currentDate, res?.currentDate)
      .subscribe((data) => {
        this.orders = {billed: data.billed, unbilled: data.unbilled};
        const tableOrders = this.setTableOrder(this.orders);
        this.categorizeTables(tables, tableOrders);
        this.updateTimesElapsed();
      });
    });
  }

  categorizeTables(tables, tableOrders) {
    const categoryBased = {};
    const pushTable = (t) => {
      if (categoryBased.hasOwnProperty(t.category)) {
        categoryBased[t.category].push(t);
      } else {
        categoryBased[t.category] = [t]
      }
    }
    
    tables.forEach(t => {
      if (tableOrders.hasOwnProperty(t.id)) {
        t.orderInfo = tableOrders[t.id];
        tableOrders[t.id].forEach(ord => {
          const dupTable = JSON.parse(JSON.stringify(t))
          dupTable.orderInfo = ord;
          pushTable(dupTable)
        })
      } else {
        pushTable(t)
      }
    });

    const sortedKeys = Object.keys(categoryBased).sort((a, b) => a.localeCompare(b));
    const sortedArray = sortedKeys.map(key => ({ category: key, tables: categoryBased[key] }));
    this.tables = sortedArray;
  }

  setTableOrder(orders) {
    const tableOrders = {}
    orders?.billed?.forEach(o => {
      if (o.posExtraData?.tableId && o.balance > 0 && !o.order_billed_to_room) {
        if (tableOrders.hasOwnProperty(o.posExtraData?.tableId)) {
          tableOrders[o.posExtraData?.tableId].push(o)
        } else {
          tableOrders[o.posExtraData?.tableId] = [o];
        }
      }
    });

    orders?.unbilled?.forEach(o => {
      if (o.posExtraData?.tableId) {
        if (tableOrders.hasOwnProperty(o.posExtraData?.tableId)) {
          tableOrders[o.posExtraData?.tableId].push(o)
        } else {
          tableOrders[o.posExtraData?.tableId] = [o];
        }
      }
    });

    return tableOrders;
  }

  private updateTimesElapsed() {
    const now = new Date();
    this.tables.forEach(obj => {
      obj.tables.forEach(table => {
        if (table?.orderInfo) {
          const targetDate = new Date(table.orderInfo?.date);
          const timeDiff = now.getTime() - targetDate.getTime();
          table.orderInfo.minutesElapsed = Math.floor(timeDiff / (1000 * 60));
        }
      })
    });
  }

  orderReadyToggle(order) {
    const newStatus = order.status === 'ready' ? 'Unbilled': 'ready';

    const dialogBody = {
      title: 'Change Status',
      body: `Are you sure you want to ${newStatus === 'ready' ? 'Mark ready ?': 'Mark not ready ?'}`,
      confirmBtn: 'Yes',
      cancelBtn: 'Cancel',
    };
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '400px',
      data: dialogBody,
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res === 'true') {
        this.posService.orderReadyStatusToggle(order.invoice_id, newStatus, this.hotelId).subscribe(res => {
          if (res?.success) {
            order.status = newStatus;
          }
        })
      }
    })
  }

  viewBill(order) {
    order.services_used.data = order.services_used;
    const data = { order, config: this.posConfig };
    this.dialog.open(BillComponent, { data });
  }

  openOrder(orderInfo?, table?, billOrder?) {
    this.router.navigate([`pos-create-order/${this.hotelId}`], {
      queryParams: {
        posName: this.posName,
        orderInfo: orderInfo ? JSON.stringify(orderInfo) : '',
        table: table ? JSON.stringify(table) : '',
        billOrder,
        backRoute: 'pos-table-view'
      }
    });
  }

  print(order) {
    this.getCurrentOrder(order.invoice_id).subscribe(res => {
      if (order.status !== 'Billed') {
        this.posService.escPosPrint('edit', res, this.posConfig);
      } else {
        this.posService.escPosPrint('view', res, this.posConfig);
      }
    })

  }

  goToAddPayment(orderInfo) {
    this.dialog.open(PaymentsDetailsComponent, {
      disableClose: true,
      data: {
        allocId: null,
        invoiceId: orderInfo?.invoice_id,
        mode: 'newEntry',
        balance: orderInfo?.balance,
        pos: true,
        billed: orderInfo?.status === 'Billed' ? true : false,
        posName: this.posName,
      },
    });
  }
  
  getCurrentOrder(invoiceId) {
    return this.userService.getInvoiceById(this.hotelId, invoiceId, 'True');
  }

  deleteOrder(table) {
    const dialogBody = {
      title: 'DELETE ORDER',
      body: `Are you sure you want to delete this order?`,
      confirmBtn: 'Yes',
      cancelBtn: 'Cancel',
    };
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '400px',
      data: dialogBody,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'true') {
        this.posService
          .deletePosOrder(this.hotelId, this.posName, table.orderInfo.invoice_id, localStorage.getItem('user'), table.orderInfo.order_id)
          .subscribe((data) => {
            if (data && 'error' in data) {
              this.dialog.open(DialogComponent, {
                data: { title: 'ERROR', body: data.error, cancelBtn: 'Ok' },
              });
            } else {
              table.orderInfo = null;
            }
          });
      }
    });
  }

  tableClick(table) {
    if (!table.orderInfo) {
      this.openOrder(null, table);
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe())
  }
}
