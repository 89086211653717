import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RoomviewComponent } from './roomview.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { AllocationMenuModule } from '../stayview/allocation-menu/allocation-menu.module';
import { MatBadgeModule } from '@angular/material/badge';
import { RouterModule } from '@angular/router';
import { AllocationMenu2Module } from 'src/app/_shared/modules/allocation-menu-2/allocation-menu-2.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [RoomviewComponent],
  imports: [
    CommonModule, MatDividerModule, MatIconModule, MatDatepickerModule, MatButtonModule, MatListModule,
    MatFormFieldModule, MatInputModule, MatMenuModule, AllocationMenuModule, MatBadgeModule, RouterModule,
    AllocationMenu2Module, TranslateModule
  ]
})
export class RoomviewModule { }
