import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import {ReservationdataService} from '../../../core/reservationdata.service';
import { DatePipe } from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import { ReservationService } from 'src/app/core/reservation.service';
import { MatDialog } from '@angular/material/dialog';
import { BookingDetailsComponent } from '../../booking-details/booking-details.component';
import { DialogComponent } from '../../../_shared/dialog/dialog.component';
import { UserService } from 'src/app/core/user.service';
import { SendPaymentLinkComponent } from 'src/app/_shared/common_modals/send-payment-link/send-payment-link.component';
import { ShowPaymentLinksComponent } from 'src/app/_shared/common_modals/show-payment-links/show-payment-links.component';
import { PaymentsService } from 'src/app/core/payments.service';

export interface ReservationElement {
  Booking_ID : String;
  Customer_Name : String;
  Booking_Date : String;
  Checkin : String;
  Checkout : String;
  Source : String;
  Guests : Number;
  Rooms : Number;
  Room_Nights : Number;
  Amount : Number;
  Payment_Mode : String;
  MealPlan : String;
  Roomstatus : any;
 
}

@Component({
  selector: 'app-reservationdata',
  templateUrl: './reservationdata.component.html',
  styleUrls: ['./reservationdata.component.css']
})


export class ReservationdataComponent implements OnInit, OnDestroy  {
  ELEMENT_DATA : ReservationElement[];
  notPMSElement = false;
  hotelRoute: any;
  hotelID: any;
  allocationdata: ReservationElement[];
  paymenttype: string;
  payloadlist = [];
  currentUser: any;
  cancelled_bookings = false;
  secretKey = 'b3ff91752d14402d8db6cacf32e0397c';
  userFeatures: any;
  showCreateReservation = true;
  showDeleteReservation = true;
  showMarkNoShow = true;
  allowToCreateComplimentary = true;
  allowToCreateOutofOrder = true;
  allowToCreateGroupReservations = true;
  
  constructor(private appService:ReservationdataService, private getcurrencyService:ReservationService, private datePipe: DatePipe, private router: Router, private route: ActivatedRoute,
              public dialog: MatDialog,
              private userService: UserService,
              private paymentsService: PaymentsService) { 
    this.filteron = this.titles[0]['name'];
   }
  displayedColumns: string[] = ['Booking_ID','Customer_Name', 'Booking_Date', 'Checkin', 'Checkout',
                                'Source', 'Guests', 'Rooms', 'Room_Nights', 'Amount', 'Payment_Mode',
                                'MealPlan','Roomstatus','take_action'];
  
  dataSource = new MatTableDataSource<ReservationElement>();

  download(){
    this.appService.downloadFile(this.dataSource.filteredData, 'jsontocsv');
  }
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }
  data11=[];
  
  
  ngOnInit() { 
    this.hotelRoute = this.route.params.subscribe(params => {
      this.hotelID = params.hotelId;
      this.secretKey += this.hotelID;
    });
    this.hotelRoute = this.route.queryParams.subscribe(queryParams => {
      this.currentUser = queryParams.user;
    });
    this.getUserFeatures();
    this.lastdayFilter();
    this.getData1();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  FromDate;
  ToDate;
  filter_Options=[];
  BookedOn;
  Checkin;
  Checkout;
  filteron:string;
  booking_data = {};
  allocation_data = {};
  amountBreak = {};
  pax = [];
  titles:Array<Object> = [
      {name: "Booking Date"},
      {name: "Check In"},
      {name: "Check Out"},
      {name: "Stay Date"}, 
  ];
  lastdayFilter(){
    var date = new Date();
    const datecopy = new Date(Number(date));
    datecopy.setDate(date.getDate() - 6);
    this.FromDate = this.datePipe.transform(datecopy, 'yyyy-MM-dd');
    this.ToDate=this.datePipe.transform(date, 'yyyy-MM-dd');
  }
currencycode:string;
  getData1():void{
    this.filter_Options =[];
    this.filter_Options.push(this.FromDate)
    this.filter_Options.push(this.ToDate)
    if (this.filteron=="Booking Date"){
      this.filter_Options.push('booking_date')
    }else if(this.filteron=="Check In"){
      this.filter_Options.push('checkin_date')
    }else if(this.filteron=="Check Out"){
      this.filter_Options.push('checkout_date')
    }else if(this.filteron=="Stay Date"){
      this.filter_Options.push('Stay Date')
    }
    this.getcurrencyService.getCurrencycode(this.hotelID).subscribe(currency => this.currencycode = currency);
    let resp = this.appService.getData1(this.filter_Options, this.hotelID)
    resp.subscribe(report => {this.dataSource.data = report as ReservationElement[]
    });
  }
  markNoShow(bookingId){
    const dialogBody = {title: 'Mark No Show', body: 'Are you sure you want to mark this reservation No Show?',
    confirmBtn: 'Yes', cancelBtn: 'No'
    };
    const dialogRef = this.dialog.open(DialogComponent, {data: dialogBody});
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'true'){
        this.appService.markNoShow(bookingId, this.hotelID, this.currentUser).subscribe(
          success=>{
            const WarningDialog = {title: 'Success !', body: `You Have successfully marked reservation as No Show`, cancelBtn: 'Dismiss'};
                                                                      this.dialog.open(DialogComponent, {data: WarningDialog});
                                                                      this.getData1();
                                                                      }, err => {
                                                                        const WarningDialog = {title: 'ERROR !', body: err, cancelBtn: 'Dismiss'};
                                                                        this.dialog.open(DialogComponent, {data: WarningDialog});
                                                                        }
        );
      }
    })
  }
  editROw(value1){
    this.router.navigate(['/reservation/'+this.hotelID],{state:{modify:'true',Booking_ID:value1},queryParams :{user: this.currentUser }});
  }
  
  bookingdetails(bookingId){
    this.dialog.open(BookingDetailsComponent, {data: {bookingId, hotelId: this.hotelID, currencyCode: this.currencycode}});
  }

  resp;
  provided_id={};
  cancelreservation(bookingId,checkin,checkout,amount):void{
    const dialogBody = {title: 'Cancel Reservation', body: 'Are you sure you want to Cancel this reservation ?',
    confirmBtn: 'Yes', cancelBtn: 'No'
    };
    const dialogRef = this.dialog.open(DialogComponent, {data: dialogBody});
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'true'){
        this.provided_id['bookingid']=bookingId
        this.provided_id['checkin']=checkin
        this.provided_id['checkout']=checkout
        this.provided_id['amount']=amount
        this.appService.fetchpayload(this.provided_id,this.hotelID).subscribe(response=> {this.resp = response
          this.resp['payload']['user'] = this.currentUser;
          this.payloadlist.push(this.resp['payload']);
          this.appService.cancelReservation(this.payloadlist).subscribe(success=>{
            const WarningDialog = {title: 'Cancelled !', body: `You Have successfully cancelled reservation`, cancelBtn: 'Dismiss'};
                                                                      this.dialog.open(DialogComponent, {data: WarningDialog});
                                                                      this.getData1();
                                                                      }, err => {
                                                                        const WarningDialog = {title: 'ERROR !', body: err, cancelBtn: 'Dismiss'};
                                                                        this.dialog.open(DialogComponent, {data: WarningDialog});
                                                                        });
        
        }); 
      }
    });
    
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  handleInput(event){
    var filelength= Object.keys(event.files).length;
   
    for (var i =0; i<filelength;i++){
       this.appService.uploadFile(this.hotelID, event.files[i], this.currentUser).subscribe(
         success => {
          const WarningDialog = {title: 'Success !', body: `You Have successfully addedd bookings`, cancelBtn: 'Dismiss'};
          this.dialog.open(DialogComponent, {data: WarningDialog});
         }, err => {
          const WarningDialog = {title: 'ERROR !', body: 'Something went wrong', cancelBtn: 'Dismiss'};
          this.dialog.open(DialogComponent, {data: WarningDialog});
         }
       );
     }
   }

  getUserFeatures() {
    this.userService.userFeatures$.subscribe(res => {
      this.userFeatures = res?.live;
      if (this.userFeatures?.hasOwnProperty('createReservation')) {
        this.showCreateReservation = this.userFeatures.createReservation;
      }if (this.userFeatures?.hasOwnProperty('deleteReservation')) {
        this.showDeleteReservation = this.userFeatures.deleteReservation;
      }if (this.userFeatures?.hasOwnProperty('markNoShow')) {
        this.showMarkNoShow = this.userFeatures.markNoShow;
      }if (this.userFeatures?.hasOwnProperty('complimentary')) {
        this.allowToCreateComplimentary = this.userFeatures.complimentary;
      }if (this.userFeatures?.hasOwnProperty('outofOrder')) {
        this.allowToCreateOutofOrder = this.userFeatures.outofOrder;
      }if (this.userFeatures?.hasOwnProperty('groupReservations')) {
        this.allowToCreateGroupReservations = this.userFeatures.groupReservations;
      }
    })
  }

  decryptCcDetails(card) {
    if (card?.decrypted) {
      return;
    }

    const { cardNumber, cvv, expiry } = card;
    this.userService.cryptoDecrypter({ cardNumber, cvv, expiry }).subscribe(res => {
      card.decrypted = res;
    })
  }

  getCardDetails(booking) {
    if (!booking.cardDetailsFetched) {
      this.paymentsService.getCardDetails(booking.Booking_ID, this.hotelID).subscribe(res => {
        booking.creditCard = {fetched: res}
      })
    }
  }

  sendPaymentLink(booking) {
    this.dialog.open(SendPaymentLinkComponent, {
      width: '500px',
      data: {
        email: booking.email,
        phone: booking.phone,
        bookingId: booking.Booking_ID, 
        hotelId: this.hotelID, 
        balance: booking.balance
      },
      disableClose: true
    })
  }

  showPaymentLinks(bookingId) {
    this.paymentsService.getPaymentLinks(bookingId, this.hotelID).subscribe(res => {
      this.dialog.open(ShowPaymentLinksComponent, {
        width: '700px',
        data: {
          links: res.data
        },
        disableClose: true
      })
    })
  } 

  ngOnDestroy(){
    this.hotelRoute.unsubscribe();
  }
}

