<div class="container">
    <div class="top_header">
        <div>
            <label for="">{{'stores.start_date' | translate}} :</label>
            <input type="date" class="form-control form-control-sm" [(ngModel)]="startDate"
            (change)="getReports()" max="{{endDate}}">
        </div>
        <div >
            <label for="">{{'stores.end_date' | translate}} :</label>
            <input type="date" class="form-control form-control-sm" [(ngModel)]="endDate"
            (change)="getReports()" min="{{startDate}}">
        </div>
        <div >
            <label for="report">{{'stores.report_type' | translate}}:</label>
            <select class="form-control form-control-sm" (change)="reportChange($event.target.value)">
                <option *ngFor="let report of reportNames" [value]="report.name">{{report.name}}</option>
            </select>
        </div>
        <div >
            <label for="sort by">{{'stores.sort_by' | translate}}:</label>
            <select class="form-control form-control-sm" [(ngModel)]="type" (change)="typeChange($event.target.value)">
                <option *ngFor="let type of types" [value]="type">
                    {{type}}
                </option>
            </select>
        </div>
        
        <div>
            <button mat-raised-button class="primary_btn"
                (click)="downloadReport()">
                Download
            </button>
        </div>
    </div>

    <div class="offset-sm-8 col-sm-4 text-right h5 mt-3">
        {{'stores.total_value' | translate}}:
        <ng-container *ngIf="reportName === 'Indent' || reportName === 'Consumption'; else totalBlock">
            {{$report?.report_sum.pre_tax_amount | number: '1.2-2'}}
        </ng-container>
        <ng-template #totalBlock>
            {{$report?.report_sum.total_amount | number: '1.2-2'}}
        </ng-template>
    </div>

    <table class="mt-4 table">
        <thead class="thead-dark">
            <ng-container *ngIf="$report?.type === 'Category Wise'">
                <tr>
                    <th>{{'stores.category' | translate}}</th>
                    <th>{{'stores.sub_category' | translate}}</th>
                    <th class="text-right">{{'stores.quantity' | translate}}</th>
                    <th></th>
                    <th class="text-right">{{'stores.value' | translate}}</th>
                    <th></th>
                </tr>
            </ng-container>

            <ng-container *ngIf="$report?.type === 'Sub-category Wise'">
                <tr>
                    <th>{{'stores.sub_category' | translate}}</th>
                    <th class="text-right">{{'stores.quantity' | translate}}</th>
                    <th></th>
                    <th class="text-right">{{'stores.value' | translate}}</th>
                    <th></th>
                </tr>
            </ng-container>
            
            <tr *ngIf="['Item Wise', 'Department Wise', 'Vendor Wise'].includes($report?.type)">
                <th>{{'stores.name' | translate}}</th>
                <th>{{'stores.category' | translate}}</th>
                <th>{{'stores.sub_category' | translate}}</th>
                <th class="text-right">{{'stores.quantity' | translate}}</th>
                <th></th>
                <th class="text-right">{{'stores.value' | translate}}</th>
                <th></th>
            </tr>
        </thead>

        <tbody>
            <ng-container *ngFor="let item of $report?.data; let i = index">

                <!-- CATEGORY WISE -->
                <ng-container *ngIf="$report?.type === 'Category Wise'">
                    <tr class="header-row">
                        <td>{{item.data[0].category}}</td>
                        <td></td>
                        <td class="text-right">{{item.sum_quantity}}</td>
                        <td class="text-left">{{item.data[0].unit}}</td>

                        <td class="text-right">
                            <ng-container *ngIf="reportName === 'Indent' || reportName === 'Consumption'; else totalBlock">
                                {{item.sum_pre_tax | number: '1.2-2'}}
                            </ng-container>
                            <ng-template #totalBlock>
                                {{item.sum_total | number: '1.2-2'}}
                            </ng-template>
                        </td>
    
                        <td class="text-right" (click)="toggleState(i)">
                            <mat-icon>
                                keyboard_arrow_{{item.hidden ? 'down': 'up'}}
                            </mat-icon>
                        </td>
    
                    </tr>
                    <tr *ngFor="let sub of item?.data" [ngClass]="{'d-none': item.hidden}">
                        <td>{{sub.sub_header}}</td>
                        <td>{{sub.sub_category}}</td>
                        <td class="text-right">{{sub.quantity}}</td>
                        <td class="text-left">{{sub.unit}}</td>
                        <td class="text-right">
                            <ng-container *ngIf="reportName === 'Indent' || reportName === 'Consumption'; else totalBlock">
                                {{sub.pre_tax_amount | number: '1.2-2'}}
                            </ng-container>
                            <ng-template #totalBlock>
                                {{sub.total_amount | number: '1.2-2'}}
                            </ng-template>
                        </td>
                        <td></td>
                    </tr>
                </ng-container>
                
                <!-- SUB CATEGORY WISE -->
                <ng-container *ngIf="$report?.type === 'Sub-category Wise'">
                    <tr class="header-row">
                        <td>
                            <span>{{item.header}}</span>
                            <span> - ({{item.data[0].category}})</span>
                        </td>
                        <td class="text-right">{{item.sum_quantity}}</td>
                        <td class="text-left">{{item.data[0].unit}}</td>

                        <td class="text-right">
                            <ng-container *ngIf="reportName === 'Indent' || reportName === 'Consumption'; else totalBlock">
                                {{item.sum_pre_tax | number: '1.2-2'}}
                            </ng-container>
                            <ng-template #totalBlock>
                                {{item.sum_total | number: '1.2-2'}}
                            </ng-template>
                        </td>
    
                        <td class="text-right" (click)="toggleState(i)">
                            <mat-icon>
                                keyboard_arrow_{{item.hidden ? 'down': 'up'}}
                            </mat-icon>
                        </td>
    
                    </tr>
                    <tr *ngFor="let sub of item?.data" [ngClass]="{'d-none': item.hidden}">
                        <td>{{sub.sub_header}}</td>
                        <td class="text-right">{{sub.quantity}}</td>
                        <td class="text-left">{{sub.unit}}</td>
                        <td class="text-right">
                            <ng-container *ngIf="reportName === 'Indent' || reportName === 'Consumption'; else totalBlock">
                                {{sub.pre_tax_amount | number: '1.2-2'}}
                            </ng-container>
                            <ng-template #totalBlock>
                                {{sub.total_amount | number: '1.2-2'}}
                            </ng-template>
                        </td>
                        <td></td>
                    </tr>
                </ng-container>

                <!-- OTHER -->
                <ng-container *ngIf="['Item Wise', 'Department Wise', 'Vendor Wise'].includes($report?.type)">
                    <tr class="header-row">
                        <td class="header">{{item.header}}</td>
                        <ng-container *ngIf="$report.type === 'Item Wise'">
                            <td>{{item.data[0].category}}</td>
                            <td>{{item.data[0].sub_category}}</td>
                            <td class="text-right">{{item.sum_quantity}}</td>
                            <td class="text-left">{{item.data[0].unit}}</td>
                        </ng-container>
    
                        <ng-container *ngIf="$report.type !== 'Item Wise'">
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </ng-container>
    
                        <td class="text-right">
                            <ng-container *ngIf="reportName === 'Indent' || reportName === 'Consumption'; else totalBlock">
                                {{item.sum_pre_tax | number: '1.2-2'}}
                            </ng-container>
                            <ng-template #totalBlock>
                                {{item.sum_total | number: '1.2-2'}}
                            </ng-template>
                        </td>
    
                        <td class="text-right" (click)="toggleState(i)">
                            <mat-icon>
                                keyboard_arrow_{{item.hidden ? 'down': 'up'}}
                            </mat-icon>
                        </td>
    
                    </tr>
                    <tr *ngFor="let sub of item?.data" [ngClass]="{'d-none': item.hidden}">
                        <td>{{sub.sub_header}}</td>
                        <ng-container *ngIf="$report.type !== 'Item Wise'">
                            <td>{{sub.category}}</td>
                            <td>{{sub.sub_category}}</td>
                        </ng-container>
                        
                        <ng-container *ngIf="$report.type === 'Item Wise'">
                            <td></td>
                            <td></td>
                        </ng-container>
                        <td class="text-right">{{sub.quantity}}</td>
                        <td class="text-left">{{sub.unit}}</td>
                        <td class="text-right">
                            <ng-container *ngIf="reportName === 'Indent' || reportName === 'Consumption'; else totalBlock">
                                {{sub.pre_tax_amount | number: '1.2-2'}}
                            </ng-container>
                            <ng-template #totalBlock>
                                {{sub.total_amount | number: '1.2-2'}}
                            </ng-template>
                        </td>
                        <td></td>
                    </tr>
                </ng-container>

            </ng-container>
        </tbody>
    </table>
</div>