import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { StoreService } from 'src/app/core/store.service';
import { MatDialog } from '@angular/material/dialog';
import { IndentItemsComponent } from './indent-items/indent-items.component';
import { CommunicationService } from 'src/app/core/communication.service';
import { DialogComponent } from 'src/app/_shared/dialog/dialog.component';
import { Globals } from 'src/app/core/globals.service';

@Component({
  selector: 'app-indent',
  templateUrl: './indent.component.html',
  styleUrls: ['./indent.component.scss']
})
export class IndentComponent implements OnInit, AfterViewInit {

  @Input() hotelId: string;
  @Input() features: any;

  masterItems: Array<any>;
  orderMode: string;
  indents: Array<any>;
  $indents: Array<any>;
  statuses = {all: 'All', applied: 'Applied', approved: 'Approved', received: 'Received'};
  selectedStatus = this.statuses.all;
  endDate = new Date().toISOString().slice(0, 10);
  startDate: any;

  constructor(public dialog: MatDialog,
              public storeService: StoreService,
              public datePipe: DatePipe,
              public commS: CommunicationService,
              public globals: Globals
  ) { }

  ngOnInit(): void {
    Object.freeze(this.statuses);
    this.startDateCalc();
    this.getIndents();
    this.commS.indentReload.subscribe(ev => this.getIndents());
  }

  ngAfterViewInit(): void {
    this.getMasterItems();
  }

  startDateCalc() {
    this.startDate = new Date(this.endDate);
    this.startDate.setDate(this.startDate.getDate() - 7);
    this.startDate = this.datePipe.transform((new Date(this.startDate)), 'y-MM-dd');
  }

  getIndents() {
    this.storeService.getIndents(this.hotelId, this.startDate, this.endDate, this.selectedStatus).subscribe(data => {
      this.indents = data?.indents;
      this.$indents = JSON.parse(JSON.stringify(this.indents));
    });
  }

  filterIndents(key: string) {
    key = key.toLowerCase();
    this.$indents = this.indents.filter(data => {
      return data.num?.toLowerCase().indexOf(key) > -1 || data.department.name?.toLowerCase().indexOf(key) > -1;
    });
  }

  createIndent() {
    const heading = 'Create Indent';
    const data = {masterItems: this.masterItems, heading, hotelId: this.hotelId, mode: 'new'};
    this.openIndentDialog(data);
  }

  getMasterItems() {
    this.storeService.getMasterItems(this.hotelId).subscribe(data => {
      this.masterItems = data?.master_items;
    });
  }

  openIndent(indent, edit: boolean) {
    const mode = edit ? 'edit' : 'view';
    const heading = `Indent# -  ${indent.num}`;
    this.storeService.getIndentItems(this.hotelId, indent.id).subscribe(res => {
      const indentItems = res?.indent_items;
      const data = {masterItems: this.masterItems, heading, hotelId: this.hotelId, mode, indentItems, department: indent.department,
                    indentId: indent.id};
      this.openIndentDialog(data);
    });
  }

  openIndentDialog(data) {
    this.dialog.open(IndentItemsComponent, {
      width: '900px',
      maxWidth: '95%',
      minHeight: '600px',
      data
    });
  }

  deleteIndent(id, num) {
    const dialogBody = {title: 'Delete Indent', body: `Are you sure you want to delete ${num} ?`, confirmBtn: 'Yes', cancelBtn: 'No'};
    const dialogRef = this.dialog.open(DialogComponent, {data: dialogBody});
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'true') {
        this.storeService.deleteIndent(this.hotelId, id).subscribe(data => {
          this.globals.snackBarFunc(`Indent deleted successfully !`);
        }).add(() => this.getIndents());
      }
    });
  }

  changeStatus(id, status) {
    const dialogStatus = status === 'Approved' ? 'you want to approve Indent' : 'you have received the items';
    const dialogBody = {title: 'Change Indent status', body: `Are you sure ${dialogStatus} ?`,
    confirmBtn: 'Yes', cancelBtn: 'No', height: '140px'};

    const dialogRef = this.dialog.open(DialogComponent, {data: dialogBody});
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'true') {
        const body = {status, indent_id: id};
        this.storeService.changeIndentStatus(this.hotelId, body).subscribe(data => {
          if (data?.error) {
            const dialogBody2 = {title: 'Error', body: `${data.error}`, cancelBtn: 'I understand'};
            this.dialog.open(DialogComponent, {data: dialogBody2});
            return;
          }
          this.globals.snackBarFunc(`Indent ${status} successfully !`);
        }).add(() => this.getIndents());
      }
    });
  }

}
