import { HttpClient, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AccountsService {

  url = `${environment.apiUrl}/accounts`;

  constructor(private http: HttpClient) { }

  _currentTab = new EventEmitter()

  set currentTab(tab: string) {
    this._currentTab.emit(tab);
  }

  getAccountsConfig(hotelId: string): Observable <any> {
    return this.http.get(`${environment.apiUrl}/accounts/${hotelId}/config`);
  }

  getReceivables(hotelId: string, page=1, term=null): Observable <any> {
    const params: any = {page};
    if (term) {
      params.term = term;
    }
    const httpParams = new HttpParams({fromObject: params});
    return this.http.get(`${this.url}/receivables/${hotelId}`, {params: httpParams});
  }

  updateGroupCompanies(hotelId: string): Observable <any> {
    return this.http.put(`${this.url}/update-group-companies/${hotelId}`, {});
  }

  addGroupCompanies(hotelId: string, body): Observable <any> {
    return this.http.post(`${this.url}/group-company/${hotelId}`, body);
  }

  deleteGroupCompanies(hotelId: string, id: string): Observable <any> {
    return this.http.delete(`${this.url}/group-company/${hotelId}/${id}`);
  }
  
  receivePayment(hotelId, data): Observable <any> {
    return this.http.post(`${this.url}/receive-payment/${hotelId}`, data);
  }

  makePayment(hotelId, data): Observable <any> {
    return this.http.post(`${this.url}/make-payment/${hotelId}`, data);
  }

  getLedger(hotelId, companyId, startDate, endDate, page, filterHotel): Observable <any> {
    const httpParams = new HttpParams(
      {fromObject: {startDate, endDate, page, filterHotel}}
    );
    return this.http.get(`${this.url}/ledger/${hotelId}/${companyId}`, {params: httpParams});
  }

  getVendorLedger(hotelId, vendorId, startDate, endDate, page): Observable <any> {
    const httpParams = new HttpParams(
      {fromObject: {startDate, endDate, page}}
    );
    return this.http.get(`${this.url}/vendor-ledger/${hotelId}/${vendorId}`, {params: httpParams});
  }

  getReconcilation(hotelId, startDate, endDate, paymentType, page, filterHotel): Observable <any> {
    const httpParams = new HttpParams(
      {fromObject: {startDate, endDate, paymentType, page, filterHotel}}
    );
    return this.http.get(`${this.url}/reconcilation/${hotelId}`, {params: httpParams});
  }
  
  reconcilationMoreInfo(hotelId, paymentId): Observable <any> {
    const httpParams = new HttpParams(
      {fromObject: {paymentId}}
    );
    return this.http.get(`${this.url}/reconcilation/more-info/${hotelId}`, {params: httpParams});
  }

  getTaxes(hotelId, startDate, endDate, taxType): Observable <any> {
    const httpParams = new HttpParams(
      {fromObject: {startDate, endDate, taxType}}
    );
    return this.http.get(`${this.url}/taxes/${hotelId}`, {params: httpParams});
  }

  getTaxesLedger(hotelId, startDate, endDate, taxType, taxName): Observable <any> {
    const httpParams = new HttpParams(
      {fromObject: {startDate, endDate, taxType, taxName}}
    );
    return this.http.get(`${this.url}/taxes/ledger/${hotelId}`, {params: httpParams});
  }

  updateOutstanding(hotelId, body): Observable <any> {
    return this.http.put(`${this.url}/${hotelId}/update-outstanding`, body);
  }

  getGstr1(hotelId, startDate, endDate, state): Observable <any> {
    const httpParams = new HttpParams(
      {fromObject: {startDate, endDate, state}}
    );
    return this.http.get(`${this.url}/taxes/${hotelId}/gstr1`, {params: httpParams});
  }

  getInvoiceDetails(hotelId, invoiceIds): Observable <any> {
    return this.http.post(`${this.url}/taxes/${hotelId}/gstr1/b2bsummary/invoice-details`, {invoiceIds});
  }

  downloadB2bExcel(hotelId, invoiceIds, serviceUsedIds): Observable <any> {
    return this.http.post(`${this.url}/taxes/${hotelId}/gstr1/b2bsummary/download-excel`, {invoiceIds, serviceUsedIds}, {responseType: 'blob'});
  }

  generateEInvoice(hotelId, invoiceId, bookingId): Observable <any> {
    const httpParams = new HttpParams(
      {fromObject: {invoiceId, bookingId}}
    );
    return this.http.get(`${environment.apiUrl}/pms/${hotelId}/generate-einvoice`, {params: httpParams});
  }

}
