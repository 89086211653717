import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { LostAndFoundEntryComponent } from './lost-and-found-entry/lost-and-found-entry.component';
import { MatDialog } from '@angular/material/dialog';
import { MaintenanceIssueService } from 'src/app/core/maintenance-issue.service';
import { DialogComponent } from 'src/app/_shared/dialog/dialog.component';

@Component({
  selector: 'app-lost-and-found',
  templateUrl: './lost-and-found.component.html',
  styleUrls: ['./lost-and-found.component.scss'],
})
export class LostAndFoundComponent implements OnInit {
  @Input() hotelId: string;
  @Input() showPage: boolean;

  ELEMENT_DATA: LostAndFoundInfo[];
  fromDate : string = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
  laundryStockDetails = [];
  toDate : string = '';
  status: string;

  constructor(private datePipe: DatePipe, private dialog: MatDialog,private maintainanceService: MaintenanceIssueService) {}

  displayedColumns: string[] = [
    'itemDetails',
    'date',
    'status',
    'itemStatus',
    'comments',
    'action',
  ];
  dataSource = new MatTableDataSource<LostAndFoundInfo>();

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngOnInit(): void {
    this.dateFilter();
  }

  dateFilter(){
    var date = new Date();
    const datecopy = new Date(Number(date));
    datecopy.setDate(date.getDate() - 6);
    this.fromDate = this.datePipe.transform(datecopy, 'yyyy-MM-dd');
    this.toDate = this.datePipe.transform(date, 'yyyy-MM-dd');
    this.status = 'Lost';
    this.getData();
  }

  createNewEntry(){
    const data = { hotelId: this.hotelId, mode: 'new' };
    this.dialog.open(LostAndFoundEntryComponent, {
      width: '85%',
      maxWidth: '85%',
      minHeight: '50%',
      height: '50%',
      maxHeight: '50%',
      data,
    });
  }

  getData(){
    this.maintainanceService.fetchLostAndFoundItems(this.hotelId, this.fromDate, this.toDate, this.status).subscribe(success=>{
      this.dataSource.data = success.success;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    })
  }

  editItem(itemData){
    const data = { hotelId: this.hotelId, mode: 'edit', itemData: itemData  };
    this.dialog.open(LostAndFoundEntryComponent, {
      width: '85%',
      maxWidth: '85%',
      minHeight: '50%',
      height: '50%',
      maxHeight: '50%',
      data,
    });
  }

  deleteItem(id){
    const dialogBody =
        { title: 'Delete Entry',
          body: `Do You Want To Delete?`,
          confirmBtn: 'Yes',
          cancelBtn: 'Cancel'
        };
    const dialogRef = this.dialog.open(DialogComponent, {data: dialogBody});

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'true'){
        this.maintainanceService.deleteLostAndFoundEntry(this.hotelId, id).subscribe(success=>{
          this.ngOnInit();
        })
      }
    })
  }

}

export interface LostAndFoundInfo {
  date: string;
  itemDetails: number;
  status: string;
  itemStatus: string;
  comments: string;
  action: string;
}