<div class="container">
    <div class="row">
        <div class="col-sm-2">
            <label for="">{{'stores.start_date' | translate}} :</label>
            <input type="date" class="form-control form-control-sm" [(ngModel)]="startDate"
            (change)="getIndents()" max="{{endDate}}">
        </div>
        <div class="col-sm-2">
            <label for="">{{'stores.end_date' | translate}} :</label>
            <input type="date" class="form-control form-control-sm" [(ngModel)]="endDate"
            (change)="getIndents()" min="{{startDate}}">
        </div>
        <div class="col-sm-2">
            <label for="">{{'stores.status' | translate}} :</label>
            <select class="form-control form-control-sm"  [(ngModel)]="selectedStatus" (change)="getIndents()">
                <option *ngFor="let status of statuses | keyvalue" [value]="status.value">
                    {{status.value}}
                </option>
            </select>
        </div>

        <div class="offset-sm-2 col-sm-4">
            <label for="" class="invisible">create</label>
            <div class="d-flex justify-content-end">
                <button *ngIf="features.indent.create" mat-stroked-button 
                    class="btn-sm pr-border mr-2" (click)="createIndent()">{{'stores.create_btn' | translate}}
                </button>
                <input type="search" id="search" class="form-control form-control-sm" placeholder="{{'stores.search' | translate}} . . ."
                (input)="filterIndents($event.target.value)">
            </div>
        </div>
    </div>

    <table class="mt-4 table table-striped">
        <thead class="thead-dark">
            <tr>
                <th>{{'stores.indent' | translate}} #</th>
                <th>{{'stores.date' | translate}}</th>
                <th class="text-right">{{'stores.pre_tax' | translate}}</th>
                <th class="text-center">{{'stores.status' | translate}}</th>
                <th>{{'stores.department' | translate}}</th>
                <th [ngStyle]="{'width': '140px'}"></th>
                <th [ngStyle]="{'width': '70px'}"></th>
            </tr>
        </thead>

        <tbody>
            <tr *ngFor="let indent of $indents">
                <td>{{indent.num}}</td>
                <td>{{indent.date | date: 'dd MMM hh:mm a'}}</td>
                <td class="text-right">{{indent.pre_tax_amount | number: '1.2-2'}}</td>
                <td class="text-center">{{indent.status}}</td>
                <td>{{indent.department.name}}</td>
                <td class="d-flex justify-content-between align-items-center action-btn">

                    <button mat-stroked-button class="btn-sm border-warning fs-12"
                        *ngIf="indent.status === 'Applied'"
                        (click)="changeStatus(indent.id, statuses.approved)"
                        [disabled]="!features.indent.approve">
                        {{'stores.approve_btn' | translate}}
                    </button>
                    <button *ngIf="indent.status === statuses.approved || indent.status === statuses.received"
                        mat-stroked-button class="btn-sm fs-12"
                        [ngClass]="{'border-success': indent.status === statuses.received,
                                    'border-secondary': indent.status === statuses.approved}"
                        (click)="changeStatus(indent.id, statuses.received)"
                        [disabled]="indent.status === statuses.received">
                        {{'stores.received_btn' | translate}}
                    </button>
                </td>
                <td>
                    <div class="d-flex justify-content-between">
                        <mat-icon *ngIf="indent.status !== 'Received'" class="text-primary" 
                            (click)="openIndent(indent, features.indent.edit)">
                            {{features.indent.edit ? 'edit': 'visibility'}}
                        </mat-icon>
                        <mat-icon *ngIf="indent.status === 'Received'" class="text-primary" 
                            (click)="openIndent(indent, false)">
                            visibility
                        </mat-icon>
                        <mat-icon *ngIf="features.indent.edit && indent.status !== 'Received' " class="text-danger"
                            (click)="deleteIndent(indent.id, indent.num)">
                            delete
                        </mat-icon>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</div>