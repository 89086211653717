import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'guestBarPosition'
})
export class GuestBarPosPipe implements PipeTransform {

  statusStyle = {
    Assigned: {
      'background-color': '#f1acacbd',
      color: 'rgba(20, 20, 20, 0.897)',
      'text-shadow': 'none',
      '--box-shadow-color': '#f1acacbd',
    },
    'Checked in': {
      'background-color': '#1ebc38cb',
      '--box-shadow-color': '#1ebc38cb'
    },
    'Checked out': {
      'background-color': '#023009e1',
      '--box-shadow-color': '#023009e1'
    },
    'Out of Order': {
      'background-color': '#6d6d6bd8',
      '--box-shadow-color': '#6d6d6bd8'
    },
    checkingOut: {
      'background-color': '#e70000d8',
      '--box-shadow-color': '#e70000d8'
    },
    compli: {
      'background-color': 'rgb(213 221 63 / 80%)',
      '--box-shadow-color': '#1ebc38cb'
    }
  };
  oneDayCheckoutSize = 12; // px

  transform(value: any, startDate: string, viewSize: number, currentDate: string): unknown {
    const cellSize = 100 / viewSize;
    const checkoutMinusStart = ((new Date(value.checkOut).valueOf() - new Date(startDate).valueOf()) / (1000 * 3600 * 24));
    const checkinMinusStart = ((new Date(value.checkIn).valueOf() - new Date(startDate).valueOf()) / (1000 * 3600 * 24));
    const width = this.widthCalc(value, cellSize, checkoutMinusStart, startDate);

    if (value.hidden) {
      return {display: 'none'};
    }
    return {
      left: this.leftPos(value, cellSize, checkinMinusStart, startDate),
      width: `calc(${width} - 2px)`,
      'max-width': width,
      ...this.getColor(value.status, currentDate, value.checkOut, value.businessSegment),
      ...this.getBorder(checkinMinusStart)
    };
  }

  widthCalc(value, cellSize: number, checkoutMinusStart, startDate: string): string {
    if (value.checkIn === value.checkOut) {
      return '0';
    }
    if (checkoutMinusStart === 0 ) {
      return `${cellSize / 2}%`;
    }
  
    let width = cellSize * checkoutMinusStart;

    if ((new Date(value.checkIn) < new Date(startDate)) && 
        (new Date(value.checkOut) > new Date(startDate))) {
        width = width + (cellSize / 2);
    } 
    else if (new Date(value.checkIn) > new Date(startDate)) {
      width = cellSize * ((new Date(value.checkOut).valueOf() - new Date(value.checkIn).valueOf()) / (1000 * 3600 * 24));
    }

    if (value?.leftOffset) {
      return `calc(${width}% - ${this.oneDayCheckoutSize * value.leftOffset}px)`;
    }

    return `${width}%`;
  }

  leftPos(value, cellSize, checkinMinusStart, startDate: string): string {
    if (new Date(value.checkIn) < new Date(startDate)) {
      return '0';
    }
    const leftPos = `${(cellSize * checkinMinusStart) + (cellSize / 2)}%`;
    if (value?.leftOffset) {      
      return `calc(${leftPos} + ${this.oneDayCheckoutSize * value.leftOffset}px)`;
    }
  
    return leftPos;
  }

  getColor(status: string, currentDate: string, checkOut: string, businessSegment: string) {
    if (this.statusStyle.hasOwnProperty(status)) {
      if (status === 'Checked in' && currentDate === checkOut) {
        return this.statusStyle.checkingOut;
      }
      if (status === 'Checked in' && businessSegment === 'Management Block') {
        return this.statusStyle.compli;
      }
      return this.statusStyle[status];
    }
    return {};
  }

  getBorder(checkinMinusStart: number) {
    if (checkinMinusStart < 0) {
      const res = {
        'border-top-right-radius': '30px',
      };
      return res;
    }

    return {
      'border-top-right-radius': '30px',
      'border-bottom-left-radius': '30px',
    };
  }
  
}
