import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

@Component({
  selector: 'app-pos-report',
  templateUrl: './pos-report.component.html',
  styleUrls: ['./pos-report.component.scss']
})
export class PosReportComponent implements OnInit {
  serviceTotal = {amount: 0, tax: 0, total: 0};
  paymentsTotal = {amount: 0};

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<PosReportComponent>) { }

  ngOnInit(): void {
    const matDialogConfig = new MatDialogConfig();
    matDialogConfig.position = {top: '8%'};
    this.dialogRef.updatePosition(matDialogConfig.position);

    if (this.data.reportName === 'Item Consumption') {
      this.data.reportData.sort((a, b) => {
        return b.total_amount - a.total_amount;
      });
      this.serviceTotal = this.getServiceTotal(this.data?.reportData);
    } else if (this.data.reportName === 'Payments') {
      this.data.reportData.sort((a, b) => {
        return b.amount - a.amount;
      });
      this.paymentsTotal = this.getPaymentsTotal(this.data?.reportData);
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  getServiceTotal(data) {
    const totalObj = {amount: 0, tax: 0, total: 0};
    data.forEach(ele => {
      totalObj.amount += ele.service_amount;
      totalObj.tax += ele.tax;
      totalObj.total += ele.total_amount;
    });
    return totalObj;
  }

  getPaymentsTotal(data) {
    const totalObj = {amount: 0};
    data.forEach(ele => {
      totalObj.amount += ele.amount;
    });
    return totalObj;
  }
}
