import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BookingService } from 'src/app/core/booking.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-add-room-to-group',
  templateUrl: './add-room-to-group.component.html',
  styleUrls: ['./add-room-to-group.component.scss']
})
export class AddRoomToGroupComponent implements OnInit {
  submitting = false;
  guestsToAdd: any[];
  guestsToAdd$: any[];
  addedGuests = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private bookingsService: BookingService,
    private dialogRef: MatDialogRef<AddRoomToGroupComponent>
  ) { }

  ngOnInit(): void {
    this.getData();
  }

  getData() {
    this.bookingsService.getGuestsToAddInGroup(this.data.hotelId, this.data?.guest?.group_booking_id, this.data.guest?.id)
    .subscribe(res => {
      this.guestsToAdd = res?.data?.allocations;
      this.guestsToAdd$ = JSON.parse(JSON.stringify(this.guestsToAdd));
    })
  }

  selectItem(checked, data) {
    if (checked) {
      this.addedGuests.push(data.roomAllocationId);
    } else {
      this.addedGuests = this.addedGuests.filter(e => {
        return e !== data.roomAllocationId;
      });
    }
  }

  filterGuestToAdd(key: string) {
    key = key?.toLowerCase();
    this.guestsToAdd = this.guestsToAdd$?.filter(e => {
      return e.roomNum.toLowerCase().indexOf(key) > -1 || e.guestName.toLowerCase().indexOf(key) > -1
    });
    
    this.guestsToAdd.forEach(e => {
      if (this.addedGuests.indexOf(e.roomAllocationId) > -1) {
        e.checked = true;
      }
    })
  }

  submit() {
    this.submitting = true;
    this.bookingsService.addGuestToGroup(this.data.hotelId, this.addedGuests, this.data.guest?.id)
    .pipe(finalize(() => this.submitting = false))
    .subscribe(res => {
      this.dialogRef.close({success: true});
    })
  }
}
