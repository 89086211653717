import { Component, OnInit, HostListener } from '@angular/core';
import { OnboardService } from 'src/app/core/onboard.service';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../../_shared/dialog/dialog.component';
import { OnboardData } from './onboarddata';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-onboard',
  templateUrl: './onboard.component.html',
  styleUrls: ['./onboard.component.scss']
})
export class OnboardComponent implements OnInit {
  hotels: any;
  hotelDetails : any;
  countries :any;
  country : string;
  timezones: any;
  selectCountry: any;
  countryName: string; 
  currencies: any;
  roomNames: any;
  hotelLive: boolean;
  selectTimeZone:any;

  
  bookingEngineDetails : any;
  reviews : any;
  dynamicPricing: any;

  onBoardForm: FormGroup;
  rooms : any;
  ratePlans: FormArray;
  users: any;
  numberOfMeals = [0,1,2,3,4,5,6,7,8,9,10];
  userTypes : any;

  ratePlans1 : any;

  onboardPayload: OnboardData;
  onboardPmsPayload : any;
  hotelId:any;

  editOnboardPayload:any;

  constructor( public onboardService:OnboardService,  public fb: FormBuilder, public matdialog:MatDialog, private route: ActivatedRoute ) { }

  @HostListener("window:beforeunload", ["$event"]) unloadHandler(event: Event) {
    let result = confirm("Changes you made may not be saved.");
    if (result) {
      // Do more processing...
    }
    event.returnValue = false; // stay on same page
  }

  ngOnInit(): void {
    this.route.queryParams
    .subscribe(params => {
      this.hotelId = params.hotelId;
    }
  );
 

  this.hotelDetails = {
    'hotelId': '',
    'hotelName': '',
    'address':{
      "coords":{}
    },
    'policies':{
      'offline_payment':{'active':true}
    },
    'contact':{},
    'white_label':{
      "text": "Aiosell Live",
      "icon": {
          "url": "https://s3.ap-south-1.amazonaws.com/buzz.hotel-images/octave-hotel-marathahalli/Aiosell.jpeg?_cache=1631077095",
          "name": "aiosell.jpeg"
      }
      }
  };
  this.bookingEngineDetails = {
    'status': true,
    'multiplier': 0.9,
    'ea': 500,
    'ec':0,
  }; 
  this.reviews = {
    'status': true,
    'mails': '',
  };
  this.dynamicPricing = {
    'status': true,
    'modal':'octave-brookefield-suites'
  };

  this.onboardPmsPayload = {
    'status':true,
    'indianTaxSlab':true,
    'invoicePrefix':'2024-25-',
    'nightAuditTime':'05:00',
    'reportTime':'11:00',
    'hotelId':'',
  }  

  this.getHotels();
  this.getOnboardConfigs();
  this.initializeOnboardForm();
  };

  initializeOnboardForm(){
    this.rooms = [{rank : 0,name : 'Super Deluxe', displayName : '', description : 'Free Wi-Fi', totalCount: '10', minocc : '1', maxocc : '2',type : 'primary',roomNumbers : '' },
                  {rank : 1, name : 'Premium', displayName : '', description : 'Free Wi-Fi', totalCount: '10', minocc : '1', maxocc : '2',type : 'primary',roomNumbers : '' }]
    this.hotelLive = true;
    this.users = [{role : 'customer', username : '', password : ''}]
    this.roomNames = ['Super Deluxe', 'Premium'];
    this.ratePlans1 = [{ name:'Super Deluxe', occupancy:'S', mealplan:'EP', numberOfMeals:0, increment:0, rate:0, ratio:1 }, { name:'Super Deluxe', occupancy:'D', mealplan:'EP', numberOfMeals:0, increment:0, rate:0, ratio:1  },
                      { name:'Premium', occupancy:'S', mealplan:'EP', numberOfMeals:0, increment:0, rate:0, ratio:1  }, { name:'Premium', occupancy:'D', mealplan:'EP', numberOfMeals:0, increment:0, rate:0, ratio:1  }]

  };



  prefillRatePlans(){
    this.roomNames  = Object.keys(this.rooms).map(k => this.rooms[k].name);
    this.ratePlans1 = [];
    for(var i=0; i<this.roomNames.length; i++){
      this.ratePlans1.push({ name:this.roomNames[i], occupancy:'S', mealplan:'EP', numberOfMeals:0, increment:0, rate:0, ratio:1 }, { name:this.roomNames[i], occupancy:'D', mealplan:'EP', numberOfMeals:0, increment:0, rate:0, ratio:1 })
    };
  };


  getHotels(){
    this.onboardService.getHotels().subscribe(
      data => {
        this.hotels = data.hotels;
      });
  };


  getOnboardConfigs(){
    this.onboardService.getConfig("onboard").subscribe(
      data => {
        for(var i=0; i<data.length; i++){
          if(data[i].id == "countries"){
            this.countries = data[i].conf;
            this.hotelDetails.address.country_code = this.countries[0].code;
          }
          if(data[i].id == "currencies"){
            this.currencies = data[i].conf;
            this.hotelDetails.address.currency = 'INR';
          }
          if(data[i].id == "timezones"){
            this.timezones = data[i].conf;   
            this.hotelDetails.address.timezone = this.timezones[0];       
          }
        }
        if(this.hotelId){
          this.onboardService.getEditOnboardData(this.hotelId).subscribe(
            data => {
              this.editOnboardPayload = data;
              this.hotelDetails = this.editOnboardPayload.hotelDetails;
              this.dynamicPricing = data.dynamicPricing;
              this.rooms = this.editOnboardPayload.rooms;
              this.ratePlans1 = this.editOnboardPayload.ratePlans;
              this.roomNames = this.editOnboardPayload.roomNames;
              this.users = this.editOnboardPayload.users ? this.editOnboardPayload.users: this.users; 
              this.onboardPmsPayload = this.editOnboardPayload.onboardPmsPayload;
              this.reviews = this.editOnboardPayload.reviews;
            })
        } 
    })

    this.onboardService.getRoles('roleType').subscribe(
      data => {
        this.userTypes = data;
      })
  };

  add(typo): void {
    if (typo == "rooms"){
      let idx = 0;
      if(this.rooms){
        idx = this.rooms.length;
      };
      this.rooms.push({rank : idx ,name : 'Super Deluxe', displayName : '', description : 'Free Wi-Fi', totalCount: '10', minocc : '1', maxocc : '2',type : 'primary',roomNumbers : ''});
    } 
    if (typo == "ratePlans1"){
      this.ratePlans1.push({ name:'Super Deluxe', occupancy:'S', mealplan:'EP', numberOfMeals:0, increment:0, rate:0 });
    };
    if (typo == "users"){
      this.users.push({role   : 'customer', username   : '', password   : '', 'exist':false});
    }
  };

  remove(typo, i): void {
    if (typo == "rooms"){
      this.rooms.splice(i);
    } 
    if (typo == "ratePlans1"){
      this.ratePlans1.splice(i, 1)
    };
    if (typo == "users"){
      this.users.splice(i);
    }
  };



  saveOnboardData(){
    this.onboardPayload = {
      'hotelDetails': this.hotelDetails,
      'rooms': this.rooms,
      'ratePlans': this.ratePlans1 ,
      'users':  this.users,
      'bookingEngineDetails': this.bookingEngineDetails,
      'dynamicPricing': this.dynamicPricing,
      'reviews': this.reviews,
      'hotelLive': this.hotelLive,
      'onboardPmsPayload': this.onboardPmsPayload
    };
    if(!this.hotelDetails.hotelName || !this.ratePlans1[0].rate){
      alert("Please fill all details");
    }
    else{
      this.onboardService.onboardRms( this.onboardPayload, "onBoard").subscribe(res => {
        if(res.status){
          const dialogBody = {title: 'Success ', body: 'Completed Quick Onboarding!', cancelBtn: 'Close', confirmBtn: 'Go to Admin', width: '400px', height: '170px'};
          const dialogRef = this.matdialog.open(DialogComponent, {data: dialogBody});
          dialogRef.afterClosed().subscribe(result => {
            if(result === 'true'){
              window.open('https://admin.aiosell.com', "_blank");
            }else{
              window.location.reload();
            };
          });
        } else {
          const dialogBody = {title: 'Hotel Id Already Exist ! ', body: 'Do you want to continue ?',
          cancelBtn: 'No', confirmBtn: 'Yes' , width: '400px', height: '170px'};
          const dialogRef = this.matdialog.open(DialogComponent, {data: dialogBody});
          dialogRef.afterClosed().subscribe(result => {
            if(result === 'true'){
              this.onboardService.onboardRms( this.onboardPayload, 'overRide').subscribe(res => {
                this.onboardPmsPayload['hotelId'] = res;
              })
            };
          });
  
        }
      });
    }

  };
  
};