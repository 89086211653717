import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogComponent } from 'src/app/_shared/dialog/dialog.component';
import { BanquetService } from 'src/app/core/banquet.service';
import { UserService } from 'src/app/core/user.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-create-banquet-reservation',
  templateUrl: './create-banquet-reservation.component.html',
  styleUrls: ['./create-banquet-reservation.component.scss']
})
export class CreateBanquetReservationComponent implements OnInit {
  MAX_FILE_SIZE_MB = 5;

  mode: 'create' | 'edit' = 'create'
  hotelId: string;
  banquetForm: FormGroup;
  reservationId: string;

  typeOfParties = [];
  statuses = [
    {name: 'Tentative', value: 'tentative'},
    {name: 'Confirmed', value: 'confirmed'},
    {name: 'Completed', value: 'completed'},
  ];
  pricingTypes = [];
  sessions = [
    {name: 'Breakfast', value: 0, shortName: 'B'},
    {name: 'Lunch', value: 1, shortName: 'L'},
    {name: 'Dinner', value: 2, shortName: 'D'}
  ];

  attachments: File[];
  generatedDates = [];
  currentMonth: number = 0;
  selectedDates = {};
  halls = [];

  showDatePicker: boolean = false;
  showMultiSelect: boolean = false;
  errors = {halls: {}, session: {}};

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private banquetService: BanquetService,
    private dialog: MatDialog,
    private router: Router,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.hotelId = this.route.snapshot.params?.hotelId;
    if (this.route.snapshot.queryParams?.reservationId) {
      this.mode = 'edit';
      this.reservationId = this.route.snapshot.queryParams.reservationId;
      this.getReservation();
    }
    else {
      this.initForm();
    }
    this.generateDates(120);
    this.getBanquetHalls();
    this.getProductConfig();
  }

  getProductConfig() {
    this.banquetService.getProductConfig(this.hotelId).subscribe(res => {
      this.pricingTypes = res?.conf?.pricingTypes;
      this.typeOfParties = res?.conf?.typesOfParty;
      if (res?.conf?.banquetSessions?.length === 3) {
        this.sessions = res.conf.banquetSessions;
      }
    })
  }

  getBanquetHalls() {
    this.banquetService.getBanquetHalls(this.hotelId).subscribe(res => {
      this.halls = res?.data?.halls;
    })
  }

  initForm(data=null) {
    this.banquetForm = this.fb.group({
      name: [data?.name, Validators.required],
      dates: [data?.dates, Validators.required],
      email: [data?.email],
      phone: [data?.phone],
      typeOfParty: [data?.typeOfParty],
      pax: [data?.pax],
      status: [data?.status, Validators.required],
      budget: [data?.budget],
      amount: [data?.amount],
      pricingType: [data?.pricingType],
      menu: [data?.menu],
      arrangements: [data?.arrangements],
      attachment: [data?.attachment],
      guestId: [data?.guestId],
      comments: [data?.comments],
      expiryDate: [data?.expiryDate],
      leadId: [data?.leadId]
    })
  }

  validation(): boolean {
    const dates = [];
    let atleastOneSession = false;
    this.errors = {halls: {}, session: {}};

    for (const item in this.selectedDates) {
      const cDate = this.selectedDates[item];
      if (cDate?.selected) {
        const sessions = [];
        for (const s in cDate?.session) {
          if (cDate.session[s]) {
            sessions.push(s);
          }
        }

        const halls = []
        for (const h in cDate?.halls) {
          if (cDate.halls[h]) {
            halls.push(h);
          }
        }

        if (sessions.length) {
          atleastOneSession = true;
          dates.push({date: item, sessions, halls});
        }
        if (!halls.length) {
          this.errors.halls[item] = 'Select atleast 1 hall';
        }
        if (!sessions.length) {
          this.errors.session[item] = 'Select atleast 1 session'
        }
      }
    }

    if (!dates.length || !atleastOneSession) {
      const dialogBody = {
        title: 'Error',
        body: `Please enter atleast 1 date with a session`,
        confirmBtn: 'Ok',
      };
      this.dialog.open(DialogComponent, {data: dialogBody});
      return false;
    }

    for (const k in this.errors) {
      if (Object.keys(this.errors[k]).length) {
        return false;
      }
    }

    this.banquetForm.controls.dates.setValue(dates);

    if (this.banquetForm.invalid) {
      const dialogBody = {
        title: 'Error',
        body: `Please enter all required feilds`,
        confirmBtn: 'Ok',
      };
      this.dialog.open(DialogComponent, {data: dialogBody});
      return false;
    }

    return true;
  }

  submitClick() {
    if (!this.validation()) {
      return;
    }
  
    if (this.attachments?.length) {
      this.uploadAndSubmit();
    } else {
      this.submit();
    }
  }

  submit() {
    const body = {...this.banquetForm.value};
    if (this.mode === 'create') {
      this.createReservation(body);
    } else {
      this.editReservation(body);
    }
  }

  uploadAndSubmit() {
    const ts = new Date().getTime();
    const fileUploadObservables = this.attachments.map(f => 
      this.userService.uploadFile(f, `${this.hotelId}/${ts}-${f.name}`)
    );

    forkJoin(fileUploadObservables).subscribe(res => {
      let attachments = this.banquetForm.controls.attachment.value;
      attachments = attachments ? [...attachments, ...res] : res;
      this.banquetForm.controls.attachment.setValue(attachments);
      this.submit();
    })

  }

  createReservation(body) {
    this.banquetService.createReservation(this.hotelId, body).subscribe(res => {
      if (res?.success) {
        const dialogBody = {
          title: 'Success',
          body: res?.msg ? res.msg : `New reservation created`,
          confirmBtn: 'Ok',
        };
        const dref = this.dialog.open(DialogComponent, {data: dialogBody});
        dref.afterClosed().subscribe(res => {
          this.router.navigate([`/banquet/${this.hotelId}`])
        })
      }
    });
  }

  editReservation(body) {
    this.banquetService.editReservation(this.hotelId, body, this.reservationId).subscribe(res => {
      if (res?.success) {
        const dialogBody = {
          title: 'Success',
          body: res?.msg ? res.msg : `Reservation modified`,
          confirmBtn: 'Ok',
        };
        const dref = this.dialog.open(DialogComponent, {data: dialogBody});
        dref.afterClosed().subscribe(res => {
          this.router.navigate([`/banquet/${this.hotelId}`])
        })
      }
    });
  }

  getReservation() {
    this.banquetService.getReservation(this.hotelId, this.reservationId).subscribe(res => {
      if (res?.success) {
        this.initForm(res.data);
        this.setSelectedDate();
      }
    })
  }

  // set dates after getting reservation data on edit
  setSelectedDate() {
    const dates = this.banquetForm.controls.dates.value;
    dates.forEach(e => {
      this.selectDate(e.date);
      e.session.forEach(s => {
        this.selectSession(e.date, parseInt(s), true)
      });
      e.halls.forEach(h => {
        this.hallSelect({checked: true, item: {id: h}}, e.date)
      })
    })
  }

  resetForm() {
    this.banquetForm.reset();
    this.attachments = [];
    this.selectedDates = {};
  }

  fileChange(e) {
    if (e?.files?.length > 5) {
      alert('Max 5 files allowed');
      e.value = '';
      return;
    }

    for (const f of e.files) {
      if (f.size > this.MAX_FILE_SIZE_MB * 1024 * 1024) {
        alert('Max file size 5MB');
        e.value = '';
        return;
      }
    }
    
    this.attachments = [...e.files];
  }

  changeMonth(value) {
    const newVal = value ? this.currentMonth + 1 : this.currentMonth - 1;
    if (newVal < this.generatedDates.length && newVal > -1) {
      this.currentMonth = newVal;
    }
  }

  selectDate(date) {
    if (this.selectedDates[date]?.selected) {
      this.selectedDates[date].selected = false;
    } else {
      this.selectedDates[date] = {selected: true};
    }
  }

  removeDate(date) {
    this.selectedDates[date].selected = false;
  }

  selectSession(date, session, checked) {
    if (this.selectedDates[date].session) {
      this.selectedDates[date].session[session] = checked;
    } else {
      this.selectedDates[date].session = {[session]: checked};
    }
  }

  hallSelect(event, date) {
    const {checked, item} = event;
    if (this.selectedDates[date].halls) {
      this.selectedDates[date].halls[item.id] = checked;
    } else {
      this.selectedDates[date].halls = {[item.id]: checked};
    }
  }


  // Custom date picker

  closeDatepicker() {
    this.showDatePicker = false;
  }

  
  toggleDatePicker() {
    this.showDatePicker = !this.showDatePicker;
  }
  
  generateDates(numDays: number) {
    const data = [];
    const today = new Date();

    const months = [
      'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
    ];
    const daysOfWeek = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

    let currentMonth = -1;
    let currentMonthObj = null;
    
    for (let i = 0; i < numDays; i++) {
      const date = new Date(today);
      date.setDate(today.getDate() + i);

      if (date.getMonth() !== currentMonth) {
        currentMonthObj = { month: months[date.getMonth()], dates: [] };
        currentMonth = date.getMonth();
        data.push(currentMonthObj)
      }

      currentMonthObj.dates.push({date: this.formatDate(date), dayOfWeek: date.getDay()});
    }

    this.generatedDates = data;
  }
  daysOfWeek = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

  formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  openTab(url) {
    window.open(url, '_blank')
  }

}
