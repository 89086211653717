<div>
  <div class="heading flex-space-bw">
    <h2>{{ "group_booking.title" | translate }}</h2>
    <button mat-raised-button class="active" (click)="goBack()">
      {{ "group_booking.back" | translate }}
    </button>
  </div>
  <mat-divider></mat-divider>

  <div class="container-fluid" *ngIf="showBookings">
    <div class="row pt-1 pb-2 bg-white">
      <div class="col-sm-2">
        <label for="From Date">{{ "group_booking.from_date" | translate }}:</label>
        <input
          type="date"
          class="form-control"
          id="from-date"
          max="{{ endDate }}"
          [(ngModel)]="startDate"
        />
      </div>
      <div class="col-sm-2">
        <label for="To Date">{{ "group_booking.to_date" | translate }}:</label>
        <input
          type="date"
          class="form-control"
          id="to-date"
          min="{{ startDate }}"
          [(ngModel)]="endDate"
        />
      </div>
      <div class="col-sm-2">
        <label for="filters">{{'stayview.reservation.reservation_data.filter_options' | translate}}:</label>
        <select class="form-control" id="filters" [(ngModel)]="filteron" name="filteron">
          <option *ngFor="let title of titles" [ngValue]="title.value">{{title.name}}</option>
        </select>
      </div>
      <div class="col-sm-2" id="submit" >
        <br>
        <button mat-raised-button class="active" (click)="getData()">{{'stayview.reservation.submit' | translate}}</button>
      </div>
      <div class="col-sm-1 ml-auto">
        <!-- <label class="invisible">{{
          "group_booking.create_group_booking" | translate
        }}</label> -->
        <br>
        <button mat-raised-button class="active adjustSpace" (click)="openGroupBooking()">
          {{ "group_booking.create" | translate }}
        </button>
      </div>
    </div>

    <div class="content">
      <table class="table table-bordered">
        <thead class="thead-dark">
          <tr>
            <th></th>
            <th class="text-left">
              {{ "group_booking.group_name" | translate }}
            </th>
            <th class="text-left">
              {{ "group_booking.booking_id" | translate }}
            </th>
            <th class="text-left">
              {{ "group_booking.check_in" | translate }}
            </th>
            <th class="text-left">
              {{ "group_booking.check_out" | translate }}
            </th>
            <th class="text-center">{{ "group_booking.rooms" | translate }}</th>
            <th class="text-center">
              {{ "group_booking.guests" | translate }}
            </th>
            <th class="text-center">
              {{ "group_booking.amount" | translate }}
            </th>
            <th class="text-center">
              {{ "group_booking.payment_made" | translate }}
            </th>
            <th class="text-center">
              {{ "group_booking.booked_on" | translate }}
            </th>
            <th [ngStyle]="{ width: '60px' }"></th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let g of groupBookings; let i = index">
            <tr class="header-row" [ngClass]="{ 'bg-grey': !g.hidden }">
              <td><mat-icon matTooltip="Select All" (click)="selectOrUnselectAll(g.rooms)">select_all</mat-icon></td>
              <td>{{ g.name }}</td>
              <td>
                <div *ngFor="let bid of g.booking_ids">
                  {{ bid }}
                </div>
              </td>
              <td>{{ g.group_checkin | date : "dd MMM h:mm a" }}</td>
              <td>{{ g.group_checkout | date : "dd MMM h:mm a" }}</td>
              <td class="text-center">{{ g.total_rooms }}</td>
              <td class="text-center">{{ g.total_pax }}</td>
              <td class="text-right">{{ g.total_price | number : "1.2-2" }}</td>
              <td class="text-right">{{ g.total_payment | number : "1.2-2" }}</td>
              <td class="text-right">
                <div class="d-flex justify-content-between">
                  {{ g.booked_on | date : "dd MMM h:mm a" }}
                </div>
              </td>
              <td>
               
                <div class="d-flex justify-content-between">
                  <mat-icon [matMenuTriggerFor]="moreOptions">more_vert</mat-icon>

                  <mat-menu #moreOptions="matMenu">
                    <button mat-menu-item
                      (click)="openGroupBooking('modify', g)"
                      [ngClass]="{ disabled: !g.editable }">Edit
                    </button>

                    <button mat-menu-item
                      (click)="groupCheckIn(g.rooms)"
                      >Group Check-In
                    </button>

                    <button mat-menu-item
                    (click)="groupCheckOut(g.rooms)"
                      >Group Check-Out
                    </button>

                    <button mat-menu-item
                    (click)="groupTransferFolio(g.rooms)"
                      >Group Transfer Bill to Room No
                    </button>

                    <button mat-menu-item
                      (click)="cancelBooking(g.rooms, g.id)"
                      [ngClass]="{ disabled: !g.deleteable }">
                      Cancel
                    </button>
                    
                    <button mat-menu-item
                      (click)="addGuestToGroup(g)">
                      Add Room to group
                    </button>

                    <button mat-menu-item
                      (click)="sendVoucher(g.group_booking_id)">
                      Send Email Confirmation
                    </button>
                  </mat-menu>
      
                  <mat-icon
                    (click)="toggleState(i)"
                  >
                    keyboard_arrow_{{ g.hidden ? "down" : "up" }}
                  </mat-icon>
                </div>
              </td>
            </tr>

            <tr class="bg-lightgrey" [hidden]="g.hidden">
              <td class="alt-row" colspan="11">
                <div>
                  <table class="table nested-table">
                    <thead>
                      <tr>
                        <th></th>
                        <th>{{ "group_booking.room_no" | translate }}</th>
                        <th>{{ "group_booking.guest_name" | translate }}</th>
                        <th>{{ "group_booking.room_type" | translate }}</th>
                        <th>{{ "group_booking.checkin" | translate }}</th>
                        <th>{{ "group_booking.checkout" | translate }}</th>
                        <th class="text-center">
                          {{ "group_booking.guests" | translate }}
                        </th>
                        <th class="text-center">
                          {{ "group_booking.balance" | translate }}
                        </th>
                        <th class="text-center">
                          {{ "group_booking.payment_made" | translate }}
                        </th>
                        <th>{{ "group_booking.status" | translate }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="sub-row" *ngFor="let r of g.rooms">
                        <td><input type="checkbox" [(ngModel)]="r.checked"/></td>
                        <td>{{ r.room_num }}</td>
                        <td> <span>{{ r.guest_name }}</span>
                          <span class = "leftspace" [hidden]="r.master_room == false">Master</span>
                          <span><button mat-icon-button class="iconcolor">
                            <mat-icon matTooltip="Make master" [hidden]="r.master_room == true" (click)="changeMasterRoom(g.id, r.room_allocation_id)">swap_vert</mat-icon>
                          </button></span></td>
                        <td>{{ r.room_type | titlecase }}</td>
                        <td>{{ r.checkin | date : "dd MMM h:mm a" }}</td>
                        <td>{{ r.checkout | date : "dd MMM h:mm a" }}</td>
                        <td class="text-center">{{ r.pax }}</td>
                        <td class="text-right">
                          {{ r.balance | number : "1.2-2" }}
                        </td>
                        <td class="text-right">
                          {{ r.payment | number : "1.2-2" }}
                        </td>
                        <td class="d-flex align-items-center">
            
                          <span>{{ r.status }}</span>
                          
                          <span>
                            <button mat-icon-button matTooltip="Edit" class="iconcolor" (click)="goToEdit(r.room_allocation_id, r.status, r.booking_id, r.source)">
                              <mat-icon>edit</mat-icon>
                            </button>

                            <button [disabled]="r.status === 'Checked in' || r.status === 'Checked out' || r.status === 'Unassigned' || r.status === 'No Show'" mat-icon-button matTooltip="Check-In" class="iconcolor" (click)="checkin(r.room_allocation_id, r.status, r.booking_id, r.source, r.guest_name, r.guest_contact)">
                              <mat-icon>how_to_reg</mat-icon>
                            </button>

                            <button [disabled]="r.status !== 'Checked in' || r.status === 'Checked out'" mat-icon-button matTooltip="Check-Out" class="iconcolor" (click)="checkOut(r.room_allocation_id, r.booking_id, r.source, r.guest_name, r.guest_contact)">
                              <mat-icon>exit_to_app</mat-icon>
                            </button>

                            <button [disabled]="r.status !== 'Checked in' || r.status === 'Checked out'" mat-icon-button matTooltip="Transfer Bill to Room No" class="iconcolor" (click)="transferToFolio(g.rooms, r.room_allocation_id)">
                              <mat-icon>call_made</mat-icon>
                            </button>

                            <button [disabled]="r.status === 'Checked in' || r.status === 'Checked out'" mat-icon-button matTooltip="Remove from group" class="iconcolor" (click)="removeGuestFromGroup(r.room_allocation_id)">
                              <mat-icon>delete</mat-icon>
                            </button>
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>

  <div class="content" *ngIf="!showBookings">
    <app-create-group-booking
      [hotelId]="hotelId"
      [mode]="mode"
      [payload]="payload"
      [user]="currentUser"
      (showBookings)="showB($event)"
    >
    </app-create-group-booking>
  </div>
</div>
