import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StoreService } from 'src/app/core/store.service';
import { Globals } from '../../../../core/globals.service';

@Component({
  selector: 'app-send-po-email',
  templateUrl: './send-po-email.component.html',
  styleUrls: ['./send-po-email.component.scss']
})
export class SendPoEmailComponent implements OnInit {

  ccEmail: string;
  emailList = [];
  message = 'Please contact the hotel for any questions';
  signature: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public storeService: StoreService,
    public globals: Globals
    ) { }

  ngOnInit(): void {
    this.storeService.getHotel(this.data.hotelId).subscribe(data => {
      this.signature = `Regards,\n${data?.globals?.name}`;
    });
  }

  addEmail() {
    if (!this.ccEmail?.length) {
      this.emailList = [];
      return;
    }

    this.emailList = this.ccEmail.split(',');
    console.log();
  }

  isValidEmail(email: string) {
    const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (email?.trim()?.match(validRegex)) {
      return true;
    }
    return false;
  }

  send() {
    this.emailList = this.emailList.filter(ele => {
      return this.isValidEmail(ele);
    });

    const body = {
      vendorEmail: this.data.vendorEmail,
      ccList: this.emailList,
      orderId: this.data.orderId,
      message: this.message,
      signature: this.signature,
      orderNum: this.data.orderNum,
      totalAmount: this.data.totalAmount?.toString()
    };

    this.storeService.sendEmail(this.data.hotelId, body)
    .subscribe(data => {
      this.globals.snackBarFunc(data?.message);
    });
  }


}
