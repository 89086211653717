import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { finalize } from 'rxjs/operators';
import { StoreService } from 'src/app/core/store.service';
import { DialogComponent } from 'src/app/_shared/dialog/dialog.component';

@Component({
  selector: 'app-return-stock-item',
  templateUrl: './return-stock-item.component.html',
  styleUrls: ['./return-stock-item.component.scss']
})
export class ReturnStockItemComponent implements OnInit {

  selectedItem: any = {};
  returnItems: any[] = [];
  filteredItems: any[] = [];
  quantityError: string;
  submitting = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private storeService: StoreService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<ReturnStockItemComponent>
  ) { }

  ngOnInit(): void {
    this.filteredItems = this.data.stock;
  }

  selectItem(item) {
    this.selectedItem = item;
  }

  deptChange(deptId: string) {
    this.filteredItems = this.data?.stock.filter(item => item.dept_id === deptId);
  }

  quantityChange() {
    if (this.quantityError) {
      this.quantityError = null;
    }
  }

  add() {
    if (!this.selectedItem.quantity || this.selectedItem.quantity < 0) {
      this.quantityError = 'Valid quantity is required';
      return;
    }
    if (this.selectedItem.quantity > this.selectedItem.stock) {
      this.quantityError = 'Quantity cannot be more than available';
      return;
    }
    
    for (const item of this.returnItems) {
      if (item.item_id === this.selectedItem.item_id) {
        return;
      }
    }
    
    this.returnItems.push({
      dept_id: this.selectedItem.dept_id,
      dept_name: this.selectedItem.dept_name,
      item_id: this.selectedItem.item_id,
      item_name: this.selectedItem.item_name,
      quantity: this.selectedItem.quantity,
      unit: this.selectedItem.unit
    });
  }

  removeItem(itemId: string) {
    this.returnItems = this.returnItems.filter(item => item.item_id !== itemId);
  }

  submit() {
    this.submitting = true;
    this.storeService.returnStock(this.returnItems, this.data.hotelId)
    .pipe(finalize(() => this.submitting = false))
    .subscribe(data => {
      if (data?.success) {
        this.dialog.open(DialogComponent, {
          data: {
            title: 'SUCCESS',
            body: 'Items returned to master stock successfully !',
            confirmBtn: 'Ok'
          }
        });
        this.dialogRef.close({success: true});
      }
    });
  }

  submitConfirmation() {
    if (!this.returnItems.length) {
      this.dialog.open(DialogComponent, {
        data: {
          title: 'WARNING',
          body: 'Please enter atleast 1 item',
          confirmBtn: 'Ok'
        }
      });
      return false;
    }

    const dialogRef = this.dialog.open(DialogComponent, {
      data: {
        title: 'Confirm',
        body: 'Are you sure to return these items to master stock ?',
        confirmBtn: 'Yes',
        cancelBtn: 'Cancel'
      }
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.submit();
      }
    });
  }
}
