import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { DialogComponent } from 'src/app/_shared/dialog/dialog.component';
import { MaintenanceIssueService } from 'src/app/core/maintenance-issue.service';
import { UserService } from 'src/app/core/user.service';
import { expandCollapse, slide } from 'src/app/animations';
import { ReservationService } from 'src/app/core/reservation.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-housekeeping',
  templateUrl: './housekeeping.component.html',
  styleUrls: ['./housekeeping.component.scss'],
  animations: [expandCollapse, slide],
})
export class HousekeepingComponent implements AfterViewInit {
  @Input() hotelId: string;
  @Input() user:string;
  @Input() data;
  @Input() showPage: boolean;
  ELEMENT_DATA: HousekeepingInfoElement[];
  mode:string = "housekeeping";
  nights = '';
  submitting = false;
  roomNum = '';
  comment = '';
  roomTypes: any;
  overallHousekeepingInfo: any;
  timezone: any;
  currentDate: Date;
  checkinDate: string;
  checkoutDate: string;
  nextdaydate: Date;
  OutOfOrderInfo: any;
  roomId: any;

  constructor(private maintService: MaintenanceIssueService,
              private dialog: MatDialog, 
              private userService: UserService,
              private guestService: ReservationService,
              private route: ActivatedRoute,
              private datePipe: DatePipe,) {}

  displayedColumns: string[] = [
    'roomNum',
    'roomAvailabilityStatus',
    'guestName',
    'roomCleanessStatus',
    'markOutOfOrder'
  ];
  dataSource = new MatTableDataSource<HousekeepingInfoElement>();

  // @ViewChild(MatSort, {static: false})
  // set sort(value: MatSort) {
  //   if (this.dataSource){
  //     this.dataSource.sort = value;
  //   }
  // }
  // @ViewChild(MatPaginator, {static: false})
  // set paginator(value: MatPaginator) {
  //   if (this.dataSource){
  //     this.dataSource.paginator = value;
  //   }
  // }
  
  ngAfterViewInit(): void {
    if (this.data){
      this.mode = this.data.mode;
      this.user = this.data.user;
      this.hotelId = this.data.hotelId;
    }
    else{
      this.fetchHousekeepingInfo();
      // this.dataSource.paginator = this.paginator;
      // this.dataSource.sort = this.sort;
    
    }
    
    this.route.queryParams.subscribe((queryParams) => {
      this.user = queryParams.user;
    });
  }

  fetchHousekeepingInfo() {
    this.maintService
      .getHousekeepingInfo(this.hotelId)
      .subscribe((success) => {
        this.roomTypes = success.roomTypeInfo;
        this.overallHousekeepingInfo = success.housekeepingInfo;
        for(var roomtype = 0; roomtype<success.housekeepingInfo.length; roomtype++){
          this.showHousekeepingInfo(success.housekeepingInfo[roomtype])
        }
        // this.showHousekeepingInfo(success.housekeepingInfo[0])
        // this.dataSource.data = success.housekeepingInfo as HousekeepingInfoElement[];
      });
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  cleanDirtyToggle(roomNum, originalstatus, roomNumId): void {
    var status = 'dirty';
    if (originalstatus == 'dirty'){
      status = 'clean';
    }
    const dialogBody =
      {title: 'HOUSEKEEPING',
       body: `Change room status to ${status.toUpperCase()}?`,
       confirmBtn: 'Change',
       cancelBtn: 'Cancel'
      };
    const dialogRef = this.dialog.open(DialogComponent, {data: dialogBody});

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'true'){
        const body = {roomNumID: roomNumId, status, user: this.user, roomNum: roomNum};
        this.userService.changeRoomDirty(body, this.hotelId).subscribe(
          data => {
            this.fetchHousekeepingInfo()
          }, err => {
            this.errorMsg(err);
        });
      }
    });
  }

  errorMsg(msg) {
    const errorDialog =
      {title: 'Oops !',
      body: msg.message,
      cancelBtn: 'Dismiss'
      };
    this.dialog.open(DialogComponent, {data: errorDialog});
  }
  
  getTimeZone(): void {
    this.guestService.getTimeZone(this.hotelId).subscribe((success) => {
      this.timezone = success['timezone'];
      this.getCurrentDate();
    });
  }

  getCurrentDate(){
    this.guestService.getCurrentDatetTime(this.hotelId).subscribe(
      (data) => {
        this.currentDate = new Date(data.currentdatetime);
        (<HTMLInputElement>document.getElementById('checkinDate')).min =
          this.datePipe.transform(new Date(this.currentDate), 'yyyy-MM-dd');
        let today = new Date(this.currentDate);
        let nextday = new Date(this.currentDate);
        nextday.setDate(nextday.getDate() + 1);
        if (
          this.datePipe.transform(new Date(this.currentDate), 'yyyy-MM-dd') ==
          this.datePipe.transform(
            new Date().toLocaleString('en-US', { timeZone: this.timezone }),
            'yyyy-MM-dd'
          )
        ) {
          
            (<HTMLInputElement>document.getElementById('checkoutDate')).min =
              nextday.toISOString().split('T')[0];
            this.checkinDate = this.datePipe.transform(
              new Date().toLocaleString('en-US', { timeZone: this.timezone }),
              'yyyy-MM-dd'
            );
            this.checkoutDate = this.datePipe.transform(
              nextday,
              'yyyy-MM-dd'
            );
        } else {
            (<HTMLInputElement>document.getElementById('checkoutDate')).min =
              nextday.toISOString().split('T')[0];
            this.checkinDate = this.datePipe.transform(today, 'yyyy-MM-dd');
            this.checkoutDate = this.datePipe.transform(
              nextday,
              'yyyy-MM-dd'
            );
          }
          this.days();
        }   
    );
  }

  addDays(date, days) {
    const copy = new Date(Number(date));
    copy.setDate(date.getDate() + days);
    return copy;
  }

  days(){
    var checkinValue = new Date(this.checkinDate);
    var checkoutValue = new Date(this.checkoutDate);
    if (this.mode != 'editStay') {
      if (this.checkinDate >= this.checkoutDate) {
        this.nextdaydate = this.addDays(checkinValue, 1);
        this.checkoutDate = this.nextdaydate.toISOString().split('T')[0];
        checkoutValue = this.nextdaydate;
      }
      var checknextdate = this.addDays(checkinValue, 1);
      (<HTMLInputElement>document.getElementById('checkoutDate')).min =
        checknextdate.toISOString().split('T')[0];
    }
    const date1: any = new Date(this.checkinDate);
    const date2: any = new Date(this.checkoutDate);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    this.nights = diffDays.toString();
  }

  close(){
    this.mode='housekeeping';
    window.location.reload();
  }

  changeMode(mode) {
    this.mode = mode;
  }

  markOutOfOrder(data, roomTypeinfo) {
    this.roomId = roomTypeinfo.roomId;
    this.OutOfOrderInfo = data;
    this.mode='outoforder';
    this.getTimeZone();
    this.roomNum = data.roomNum;
  }

  showHousekeepingInfo(info: any) {
    if (info.shown) {
      info.shown = false;
      return;
    }

    if (info?.length) {
      info.shown = true;
      return;
    }
    if (info['info']){
      info.shown = true;
    }
    this.dataSource.data = info['info'] as HousekeepingInfoElement[];
  }

  submit(){
    const dialogBody =
      {title: 'Mark Out Of Order',
       body: `Do You Want To Submit?`,
       confirmBtn: 'Submit',
       cancelBtn: 'Cancel'
      };
    const dialogRef = this.dialog.open(DialogComponent, {data: dialogBody});

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'true'){
        var body: any;
        body = {room_num_id: this.OutOfOrderInfo.roomNumId, special_request: this.comment, nights:this.nights, roomidselected: this.roomId, room_num: this.OutOfOrderInfo.roomNum, user: this.user, checkin:this.checkinDate, checkout:this.checkoutDate};
        this.guestService.outoforderBlock(this.hotelId, body).subscribe(success=>{
          var WarningDialog = {}
          if (success['error']){
            WarningDialog = {
              title: 'ERROR !',
              body: success['error'],
              cancelBtn: 'Dismiss',
              width: '400px',
              height: '170px',
            };
          }else{
            WarningDialog = {
              title: 'Success !',
              body: 'You have Successfully Submitted',
              cancelBtn: 'Dismiss',
            };
          }
          this.dialog.open(DialogComponent, { data: WarningDialog });
          
        })
      }
    })

    
  }
}

export interface HousekeepingInfoElement {
  roomNum: string;
  roomAvailabilityStatus: string;
  guestName: string;
  roomCleanessStatus: string;
}