<div class="tab-container">
    
    <div class="container">

        <div class="header_row pt-1 pb-2 bg-white">
            <div>
                <div>
                    <label for="From Date">Start Date:</label>
                    <input type="date" class="form-control form-control-sm" [(ngModel)]="startDate" (ngModelChange)="getGstr1Data()">
                </div>
                <div>
                    <label for="From Date">End Date:</label>
                    <input type="date" class="form-control form-control-sm" [(ngModel)]="endDate" (ngModelChange)="getGstr1Data()">
                </div>
                
                <div *ngIf="gstr1Data?.configLevel === 'chain'">
                    <label for="From Date">State:</label>
                    <select name="states" class="form-control form-control-sm" [(ngModel)]="state" (ngModelChange)="getGstr1Data()">
                        <option value="">All</option>
                        <option *ngFor="let state of gstr1Data?.states"
                            [value]="state">{{state}}
                        </option>
                    </select>
                </div>
            </div>

            <div>

                <button class="btn pr-border" 
                    (click)="downloadExcel()">Download Excel
                </button>

                <button class="btn pr-border" 
                    (click)="downloadJsonFile()">Download JSON
                </button>
                
                <button class="btn pr-border" (click)="goBack()">Back</button>
            </div>
        </div>

        <table class="table mt-3">
            <thead class="thead-dark">
                <tr>
                    <th>Sl.</th>
                    <th>Particular</th>
                    <th class="text-right">Voucher Count</th>
                    <th class="text-right">Taxable Amount</th>
                    <th class="text-right">Integrated Tax Amount</th>
                    <th class="text-right">Central Tax Amount</th>
                    <th class="text-right">State Tax Amount</th>
                    <th class="text-right">Cess Amount</th>
                    <th class="text-right">Tax Amount</th>
                    <th class="text-right">Invoice Amount</th>
                </tr>
            </thead>

            <tbody>
                <tr *ngFor="let item of tableData; let i=index">
                    <td>{{i+1}}</td>
                    <td (click)="getSummary(i)"
                        [ngClass]="{'links': i === 0 || i === 2 || i === 8}">
                        {{item.particular}}
                    </td>
                    <td class="text-right">{{item.voucherCount}}</td>
                    <td class="text-right">{{item.taxableAmt | number: '1.2-2'}}</td>
                    <td class="text-right">{{item.igst | number: '1.2-2'}}</td>
                    <td class="text-right">{{item.cgst | number: '1.2-2'}}</td>
                    <td class="text-right">{{item.sgst | number: '1.2-2'}}</td>
                    <td class="text-right">{{item.cess}}</td>
                    <td class="text-right">{{item.totalTax | number: '1.2-2'}}</td>
                    <td class="text-right">{{item.totalInvoiceAmt | number: '1.2-2'}}</td>
                </tr>
                <tr class="total-row dark-row">
                    <td></td>
                    <td>Total</td>
                    <td class="text-right">{{gstr1Data?.totalInvData?.voucherCount}}</td>
                    <td class="text-right">{{gstr1Data?.totalInvData?.preTaxAmt | number: '1.2-2'}}</td>
                    <td class="text-right">{{gstr1Data?.totalInvData?.igst | number: '1.2-2'}}</td>
                    <td class="text-right">{{gstr1Data?.totalInvData?.cgst | number: '1.2-2'}}</td>
                    <td class="text-right">{{gstr1Data?.totalInvData?.sgst | number: '1.2-2'}}</td>
                    <td class="text-right">{{gstr1Data?.totalInvData?.cess}}</td>
                    <td class="text-right">{{gstr1Data?.totalInvData?.totalTax | number: '1.2-2'}}</td>
                    <td class="text-right">{{gstr1Data?.totalInvData?.totalInvoiceAmt | number: '1.2-2'}}</td>
                </tr>
                <tr>
                    <td></td>
                    <td class="links" (click)="getHsnSummary()">HSN/SAC Summary - {{gstr1Data?.hsnData?.length}}</td>
                </tr>
                <tr>
                    <td></td>
                    <td class="links" (click)="getDocSummary()">Document Summary - {{gstr1Data?.docSummary?.length}}</td>
                </tr>
            </tbody>
        </table>
    </div>

</div>