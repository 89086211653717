import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CompaniesService } from 'src/app/core/companies.service';
import { UserService } from 'src/app/core/user.service';
import { Globals } from 'src/app/core/globals.service';
import { AddEditFormComponent } from './add-edit-form/add-edit-form.component';
import { MatDialog } from '@angular/material/dialog';
import { CommunicationService } from 'src/app/core/communication.service';
import { DialogComponent } from '../../_shared/dialog/dialog.component';
import { Location } from '@angular/common';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss']
})
export class CompaniesComponent implements OnInit {

  currentUser: string;
  hotelId: string;
  companiesData: any[];
  currencyCode: string;
  currentPage = 0;
  totalPages = 0;
  entries = 0;
  totalEntries = 0;
  search: FormGroup;
  searchMode = false;

  constructor(private companiesService: CompaniesService, private route: ActivatedRoute, public globals: Globals,
              public fb: FormBuilder, private userService: UserService, public dialog: MatDialog,
              private commS: CommunicationService, public location: Location) { }

  ngOnInit(): void {
    this.globals.currentUser.subscribe(user => this.currentUser = user);
    this.route.params.subscribe(params => {
      this.hotelId = params.hotelId;
      this.globals.changeHotelID(this.hotelId);
    });
    this.globals.getCurrencyCode(this.hotelId).subscribe(
      data => {
        this.currencyCode = data;
      }
    );

    this.getCompanies();

    this.search = this.fb.group({
      searchInput: ['', Validators.required]
    });

    this.commS.companyChange.subscribe(
      data => {
        if (data === 'change'){
          this.getCompanies();
        }
      }
    );
    var state = this.location.getState();
  }

  getCompanies(page = 0) {
    this.companiesService.getCompanies(this.hotelId, page).subscribe(
      data => {
        this.companiesData = data.data;
        this.entries = data.data.length;
        this.currentPage = data.page;
        this.totalPages = data.total_pages;
        this.totalEntries = data.total_entries;
      }
    );
  }

  onSearch(key){
    if (this.search.valid){
      this.companiesService.companySearch(this.hotelId, key.value.searchInput).subscribe(
        data => {
          this.companiesData = data.data;
          this.entries = data.data.length;
          this.currentPage = data.page;
          this.totalPages = data.total_pages;
          this.totalEntries = data.total_entries;
          this.searchMode = true;
        });
    }
  }

  getInvoices(company) {
    if (company.invoices) {
      return;
    }
    this.companiesService.getInvoices(company.id, this.hotelId).subscribe(data => {
      company.invoices = data;
    });
  }

  getInvoice(invoiceId) {
    this.userService.getInvoiceById(this.hotelId, invoiceId).subscribe(
      data => {
        data.invoices.forEach(ele => {
          if ('error' in ele){
            return;
          }
          window.open(ele.url, '_blank');
        });
    }, err => {
    });
  }

  addCompany() {
    this.dialog.open(AddEditFormComponent, {data: {hotelId: this.hotelId, mode: 'add'},autoFocus: false,
      maxHeight: '90vh'});
  }

  editCompany(data) {
    this.dialog.open(AddEditFormComponent, {data: {data, hotelId: this.hotelId, mode: 'edit'},autoFocus: false,
    maxHeight: '90vh'});
  }

  deleteCompany(id, name) {
    const dialogBody =
      {title: 'DELETE COMPANY', body: `Are you sure you want to delete ${name}?`,
      confirmBtn: 'Yes', cancelBtn: 'Cancel'
      };
    const dialogRef = this.dialog.open(DialogComponent, {data: dialogBody});

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'true'){
        this.companiesService.deleteCompany(this.hotelId, id, name, this.currentUser).subscribe(
          data => {
            this.commS.emitCompanyChange('change');
          }
        );
      } else {
        return;
      }
    });
  }
}
