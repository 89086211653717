import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Globals } from 'src/app/core/globals.service';
import { ReservationService } from 'src/app/core/reservation.service';
import { DialogComponent } from 'src/app/_shared/dialog/dialog.component';
import { CustomValidator } from '../validators/customValidator';
import { UserService } from 'src/app/core/user.service';

@Component({
  selector: 'app-allocation-services',
  templateUrl: './allocation-services.component.html',
  styleUrls: ['./allocation-services.component.css']
})
export class AllocationServicesComponent implements OnInit {
  @Input() mode: string;

  servicedata: any;
  currency: any;
  serviceName: any;
  addServiceForm: FormGroup;
  preTaxRate : number;
  tax : number;
  totalAmount : number;
  quantity : number;
  state = null;
  serviceId: any;
  taxGroup: any;
  taxInclusive : boolean;
  amountEditable : boolean;
  taxData: any;
  preTax: any;
  newTax: any;
  total: any;
  taxDetails: any;
  serviceCategories: any;
  servicesInfo: any;
  serviceCategory: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public allocservice: ReservationService, 
              private globals: Globals,public dialog: MatDialog, public form: FormBuilder, public userService: UserService) { }

  ngOnInit(): void {
    this.globals.getCurrencyCode(this.data.hotelId).subscribe(
      currencydata => {
        this.currency = currencydata;
      }, err => {

      });
    if(this.state){
      this.mode = 'addService'
    }else{
      this.mode = 'showServices'
    }
    if(this.mode == 'showServices'){
      this.getServicesOfAllocation();
    }else{
      this.getServiceCategories();
    }
  }

  getServiceCategories() {
    this.allocservice.getServicesAndCategoris(this.data.hotelId).subscribe(
      data => {
        this.serviceCategories = data;
      }, err => {
        console.log(err);
      });
  }

  filterServices(category) {
    this.serviceCategory = category;
    for (const ele of this.serviceCategories) {
      if (ele.category === category) {
        this.servicesInfo = ele.servicesInfo;
        break;
      }
    }
  }

  getServicesOfAllocation(){
    this.allocservice.getAllocRelServices(this.data.hotelId, this.data.allocId).subscribe(servicedata=>{
      this.servicedata = servicedata;
    })
  }
  
  changeMode(mode, state = null){
    this.mode = mode;
    this.state = state;
    this.initializeForm();
    this.ngOnInit()
  }

  initializeForm(){
    this.addServiceForm = this.form.group({
      category: ['', [Validators.required]],
      serviceId: [[Validators.required]],
      service: ['', [Validators.required]],
      taxGroup: [[Validators.required]],
      totalTax: [0, [Validators.required]],
      taxDetails: [[Validators.required]],
      totalAmount: [0, [Validators.required, CustomValidator.cannotBeLessThanOne]],
      pretaxAmount: [0, [Validators.required]],
      roomAllocationId: [this.data.allocId, [Validators.required]],
      quant: [1, [Validators.required, CustomValidator.cannotBeLessThanOne]],
      hotelId: [this.data.hotelId],
      user: [this.data.queryParams.user]
    });
  }

  changeService(serviceName){
    for (var service in this.servicesInfo){
      if (this.servicesInfo[service]['serviceName'] == serviceName ){
        this.serviceName = this.servicesInfo[service].serviceName; this.quantity = 1;
        this.initialiseAmounts(this.servicesInfo[service]['perServicePretaxRate'], this.servicesInfo[service]['perServiceTax']['total'], this.servicesInfo[service]['perServiceTax'])
        this.serviceId = this.servicesInfo[service]['serviceId'];
        this.taxGroup = this.servicesInfo[service]['taxGroup'];
        this.taxInclusive = this.servicesInfo[service]['taxInclusive'];
        this.amountEditable = this.servicesInfo[service]['amountEditable'];
      }
    }
  }

  changeQuantity(quantity){
    var taxdata = {...this.taxData};
    if(Number(quantity)!=0){
      for (var taxinfo in taxdata){
        this.taxData[taxinfo] = taxdata[taxinfo] * Number(quantity);
      }
    }
    this.preTaxRate = parseFloat((this.preTax * Number(quantity)).toFixed(2));
    this.tax = parseFloat((this.newTax * Number(quantity)).toFixed(2));
    this.totalAmount = parseFloat(((this.preTax + this.newTax) * Number(quantity)).toFixed(2));
  }

  initialiseAmounts(pretax, tax, taxdetails){
    this.preTax = pretax;
    this.preTaxRate = parseFloat(this.preTax.toFixed(2));
    this.newTax = tax;
    this.tax = parseFloat(this.newTax.toFixed(2));
    this.total = this.preTax + this.newTax;
    this.totalAmount = parseFloat(this.total.toFixed(2));
    this.taxDetails = {...taxdetails};
    this.taxData = {...this.taxDetails};
  }

  getServiceTaxdata(amount){
    this.allocservice.getServiceTax(this.data.hotelId, amount, this.serviceName, this.serviceCategory).subscribe(taxInfo=>{
      this.initialiseAmounts(taxInfo['pretax_amount'], taxInfo['di']['total'], taxInfo['di']);
    })
  }
  
  deleteService(servicedata){
    const dialogBody = {title: 'Delete Service', body: 'Are you sure you want to Delete ?',
                        confirmBtn: 'Submit', cancelBtn: 'Cancel'
                        };
    const dialogRef = this.dialog.open(DialogComponent, {data: dialogBody});
    dialogRef.afterClosed().subscribe(result => {
        if(result === 'true'){
          this.allocservice.updateServiceToAllocation(this.data.hotelId, this.data.allocId, this.data.queryParams.user, servicedata, 'remove').subscribe(success =>{
            this.dialog.closeAll();
          })
        }
      });
  }

  submit(form){
    form.controls.serviceId.setValue(this.serviceId)
    form.controls.taxDetails.setValue(this.taxData)
    form.controls.taxGroup.setValue(this.taxGroup)
    const dialogBody = {title: 'Add Service', body: 'Are you sure you want to Submit ?',
            confirmBtn: 'Submit', cancelBtn: 'Cancel'
            };
    const dialogRef = this.dialog.open(DialogComponent, {data: dialogBody});
    dialogRef.afterClosed().subscribe(result => {
        if(result === 'true'){
          this.allocservice.updateServiceToAllocation(this.data.hotelId, this.data.allocId, this.data.queryParams.user, form.value, 'add').subscribe(success =>{
            this.dialog.closeAll();
          })
        }
    });
  }
}
