import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Globals } from 'src/app/core/globals.service';
import { UserService } from 'src/app/core/user.service';

@Component({
  selector: 'app-add-service',
  templateUrl: './add-service.component.html',
  styleUrls: ['./add-service.component.scss']
})
export class AddServiceComponent implements OnInit {
  serviceForm: FormGroup;
  taxGroups: string[];
  categories: any[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public globals: Globals,
    public fb: FormBuilder,
    private userService: UserService,
    private dialogRef: MatDialogRef<AddServiceComponent>
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.getTaxGroup();
    this.getCategory();
  }

  initForm() {
    this.serviceForm = this.fb.group({
      serviceName: [null, [Validators.required]],
      amount: [null, [Validators.required, Validators.min(0.1)]],
      taxGroup: [null, [Validators.required]],
      hsn: [''],
      taxInclusive: ['1'],
      amountEditable: ['0'],
      comments: [null],
      category: [null, [Validators.required]]
    });
  }

  getTaxGroup() {
    this.userService.getTaxGroups(this.data.hotelId)
    .subscribe(data => {
      this.taxGroups = data?.tax_groups;
    });
  }

  getCategory() {
    this.userService.getServiceCategories(this.data.hotelId)
    .subscribe(data => {
      this.categories = data?.categories;
    });
  }

  getBody() {
    const body = this.serviceForm.value;
    body.taxInclusive = body.taxInclusive === '1' ? true : false;
    body.amountEditable = body.amountEditable === '1' ? true : false;
    return body;
  }

  submit() {
    if (this.serviceForm.invalid) {
      return;
    }
    const body = this.getBody();
    this.userService.addServicesConfig(this.data.hotelId, body)
    .subscribe(data => {
      this.globals.snackBarFunc(data.message);
      this.dialogRef.close({data: this.serviceForm.value});
    });
  }

  amountOnly(event: any) {
    const arr = event.target.value.split('.');
    if (arr?.length > 1 && arr[1].length > 2) {
      arr[1] = '.' + arr[1].split('').slice(0, 2).join('');
      event.target.value = arr.join('');
    }
  }

}
