<div class="primary_modal_wrapper">
    <div class="modal_heading">
        <h1>Discount Logs</h1>
    </div>

    <mat-divider></mat-divider>

    <div class="modal_body">

        <table class="table table-striped">
            <colgroup>
                <col span="1">
            </colgroup>
            <colgroup>
                <col span="3" style="background-color: #e4fff9;">
            </colgroup>
            <colgroup>
                <col span="3" style="background-color: aliceblue;">
            </colgroup>
            <colgroup>
                <col span="3">
            </colgroup>
            <thead class="thead-dark">
                <tr>
                    <th>Name</th>
                    <th>Pre Disc Amt</th>
                    <th>Pre Disc Tax</th>
                    <th>Pre Disc Total</th>
                    <th>Post Disc Amt</th>
                    <th>Post Disc Tax</th>
                    <th>Post Disc Total</th>
                    <th>Discount</th>
                    <th>Applied On</th>
                    <th>Changed On</th>
                    <th></th>
                </tr>
            </thead>

            <tbody>
                <tr *ngFor="let item of data.discountData">
                    <td>{{item.serviceName}}</td>
                    <td>{{item.preDiscAmount}}</td>
                    <td>{{item.preDiscTaxAmount?.total}}</td>
                    <td>{{item.preDiscTotalAmount}}</td>
                    <td>{{item.postDiscAmount}}</td>
                    <td>{{item.postDiscTaxAmount?.total}}</td>
                    <td>{{item.postDiscTotalAmount}}</td>
                    <td>{{item.discount}} {{item.discountType}}</td>
                    <td>{{item.appliedOn}}</td>
                    <td>{{item.changedOn | date: 'dd MMM h:mm a'}}</td>
                    <td>
                        <button mat-raised-button 
                            class="text-danger"
                            (click)="undoDiscount(item.id)">
                            Undo
                        </button>
                    </td>
                </tr>
            </tbody>
        </table>
        
    </div>

    
    <div class="modal_footer">
        <mat-dialog-actions align="end">

            <button mat-raised-button 
                mat-dialog-close
                class="primary_btn">
                Close
            </button>

        </mat-dialog-actions>
    </div>
</div>