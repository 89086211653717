import { Injectable } from '@angular/core';  
import { saveAs } from 'file-saver-es';  
import * as XLSX from 'xlsx';  
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';  
const EXCEL_EXTENSION = '.xlsx';  

@Injectable({  
  providedIn: 'root'  
})  
export class ExcelServicesService {
    
  constructor() { }  

  public exportAsExcelFile(array: any[], excelFileName: string, colWidth?: number[] | number): void {  
    const worksheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(array);
    if (colWidth) {
      worksheet['!cols'] = this.createWidthCol(colWidth, array[0]?.length);
    }
    const workbook: XLSX.WorkBook = { Sheets: { data: worksheet }, SheetNames: ['data'] };  
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });  
    this.saveAsExcelFile(excelBuffer, excelFileName);  
  }  

  private saveAsExcelFile(buffer: any, fileName: string): void {  
    const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});  
    saveAs(data, fileName + '_export_' + new  Date().getTime() + EXCEL_EXTENSION);  
  }  

  createWidthCol(colWidth: number | number[], noOfColumns: number) {
    const arr = [];
    if (typeof colWidth === 'number') {
      for (let i=0; i<=noOfColumns; i++) {
        arr.push({ wch: colWidth })
      }
    } else {
      colWidth.forEach(e => {
        arr.push({ wch: e })
      });
    }
    return arr;
  }

} 
