import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountsService } from 'src/app/core/accounts.service';

@Component({
  selector: 'app-nil-invoice-details',
  templateUrl: './nil-invoice-details.component.html',
  styleUrls: ['./nil-invoice-details.component.scss']
})
export class NilInvoiceDetailsComponent implements OnInit {
  hotelId: any;
  invoiceData: any;

  constructor(
    private accountsService: AccountsService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.hotelId = params.hotelId;
      try {
        this.invoiceData = JSON.parse(params?.data);
      }
      catch { }
    });
  }

  back() {
    this.accountsService.currentTab = 'gstr1';
    this.router.navigate([`accounts/${this.hotelId}`], {queryParams: {tabName: 'gstr1', fromBack: true}});
  }

}
