import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogComponent } from 'src/app/_shared/dialog/dialog.component';
import { StoreService } from 'src/app/core/store.service';
import { saveAs } from 'file-saver-es';
import { Globals } from 'src/app/core/globals.service';


@Component({
  selector: 'app-get-payment-file',
  templateUrl: './get-payment-file.component.html',
  styleUrls: ['./get-payment-file.component.scss']
})
export class GetPaymentFileComponent implements OnInit {

  paymentType: string = '';
  downloading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private storeService: StoreService,
    private dialogRef: MatDialogRef<GetPaymentFileComponent>,
    public globals: Globals
  ) { }

  ngOnInit(): void {
    this.paymentType = this.data.paymentType;
    this.data.payments.forEach(p => {
      if (this.data?.tdsForExpenses && p.tds_details?.amountPaid) {
        p.inputVal = p.tds_details.amountPaid;
      } else {
        p.inputVal = p.amount;
      }
    });
  }

  downloadClick() {
    const paymentBody = {};
    const paymentIds = [];

    this.data.payments.forEach(p => {
      paymentIds.push(p.id);
      paymentBody[p.id] = {amount: p.inputVal, vendorName: p.vendor_name}
    });

    this.downloadFile(paymentIds, paymentBody);
  }

  downloadFile(paymentIds, paymentBody) {
    const dialogBody = {
      title: 'Confirm', 
      body: `Do you want to download the file ?`, 
      confirmBtn: 'Yes', cancelBtn: 'Cancel'
    };
  
    const dialogRef = this.dialog.open(DialogComponent, {data: dialogBody});
    dialogRef.afterClosed().subscribe(res => {
      if (res === 'true') {
        this.downloading = true;
        const body = {paymentIds, paymentBody}; 
        this.storeService.getBankPaymentFileExpenses(this.data.hotelId, body)
        .subscribe(res => {
          const blob: any = new Blob([res], {type: 'text/pdf; charset=utf-8'});
          saveAs(blob, `bank_payment_file_${new Date().toISOString()}.csv`);
          this.dialogRef.close();
        })
      }
    })
  }
}
