<div class="primary_modal_wrapper">
    <div class="modal_heading">
        <h1>{{title}}</h1>

        <svg [mat-dialog-close]="false" role="button"
            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
          <path
            d="M315.3 411.3c-6.253 6.253-16.37 6.253-22.63 0L160 278.6l-132.7 132.7c-6.253 6.253-16.37 6.253-22.63 0c-6.253-6.253-6.253-16.37 0-22.63L137.4 256L4.69 123.3c-6.253-6.253-6.253-16.37 0-22.63c6.253-6.253 16.37-6.253 22.63 0L160 233.4l132.7-132.7c6.253-6.253 16.37-6.253 22.63 0c6.253 6.253 6.253 16.37 0 22.63L182.6 256l132.7 132.7C321.6 394.9 321.6 405.1 315.3 411.3z" />
        </svg>
    </div>

    <mat-divider></mat-divider>

    <div class="modal_body">
        <form [formGroup]="vendorForm">
            <div class="form-content">
                <div class="row mt-2">
                    <div class="col-sm-6 form-group">
                        <label for="">{{'stores.vendor_name' | translate}}</label>
                        <input type="text" class="form-control form-control-sm" formControlName="name" maxlength="64">
                    </div>
                    <div class="col-sm-6 form-group">
                        <label for="">{{'stores.contact_person' | translate}}</label>
                        <input type="text" class="form-control form-control-sm" formControlName="contact_person" maxlength="64">
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6 form-group">
                        <label for="">{{'stores.gst_num' | translate}}</label>
                        <input type="text" class="form-control form-control-sm" formControlName="gst_num" maxlength="64">
                    </div>
                    <div class="col-sm-6 form-group">
                        <label for="">{{'stores.phone' | translate}}</label>
                        <input type="text" class="form-control form-control-sm" formControlName="phone" maxlength="20">
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6 form-group">
                        <label for="">{{'stores.address' | translate}}</label>
                        <textarea type="text" class="form-control form-control-sm" formControlName="address"></textarea>
                    </div>
                    <div class="col-sm-6 form-group">
                        <label for="">State</label>
                        <input type="text" class="form-control form-control-sm" formControlName="state" maxlength="100">
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-6 form-group">
                        <label for="">{{'stores.email' | translate}}</label>
                        <input type="text" class="form-control form-control-sm" formControlName="email_id" maxlength="40">
                    </div>
                </div>              
            </div>
        </form>
    </div>

    <div class="modal_footer">
        <mat-dialog-actions align="end">
            <button mat-raised-button mat-dialog-close>Cancel</button>
            <button mat-raised-button
                [ngClass]="{'disable_button': vendorForm.invalid}"
                class="primary_btn"
                (click)="submit()">
                Submit
            </button>
        </mat-dialog-actions>
    </div>

</div>


