import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { finalize } from 'rxjs/operators';
import { StoreService } from 'src/app/core/store.service';
import { IndentItemsComponent } from '../../indent/indent-items/indent-items.component';

@Component({
  selector: 'app-copy-invoice',
  templateUrl: './copy-invoice.component.html',
  styleUrls: ['./copy-invoice.component.scss']
})
export class CopyInvoiceComponent implements OnInit {

  invoices = [];
  poData: any = {};
  startDate: any;
  endDate = new Date().toISOString().slice(0, 10);
  selectedOrder: any;
  loading = true;
  copying = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private storeService: StoreService,
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<CopyInvoiceComponent>
  ) { }

  ngOnInit(): void {
    this.startDateCalc();
    this.getInvoices();
  }

  getInvoices() {
    this.loading = true;
    this.storeService.getPurchaseOrders(this.data.hotelId, this.startDate, this.endDate, 'Invoiced')
    .pipe(finalize(() => this.loading = false))
    .subscribe(data => {
      this.invoices = data.purchase_orders;
      this.poData = data;
    });
  }
  
  startDateCalc() {
    this.startDate = new Date(this.endDate);
    this.startDate.setDate(this.startDate.getDate() - 7);
    this.startDate = this.datePipe.transform((new Date(this.startDate)), 'y-MM-dd');
  }

  filterOrders(key: string) {
    key = key.toLowerCase();
    this.invoices = this.poData?.purchase_orders?.filter(data => {
      return data.num?.toLowerCase().indexOf(key) > -1 || data.invoice_num?.toLowerCase().indexOf(key) > -1
      || data.vendor?.name?.toLowerCase().indexOf(key) > -1;
    });
  }

  selectRow(order) {
    this.selectedOrder = order;
  }

  openIndent(orderId) {
    this.copying = true;
    const mode = 'new';
    const heading = `Create Indent`;
    this.storeService.getOrderItems(orderId)
    .pipe(finalize(() => this.copying = false))
    .subscribe(res => {
      const indentItems = res?.order_items;
      const data = {
        masterItems: this.data.masterItems, heading, hotelId: this.data.hotelId, mode, indentItems, department: [],
      };
      this.openIndentDialog(data);
      this.dialogRef.close();
    });
  }

  openIndentDialog(data) {
    this.dialog.open(IndentItemsComponent, {
      width: '900px',
      maxWidth: '95%',
      minHeight: '600px',
      data
    });
  }

  submit() {
    if (this.copying) {
      return;
    }
    this.openIndent(this.selectedOrder?.id);
  }
}
