import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AccountsService } from 'src/app/core/accounts.service';
import { CompaniesService } from 'src/app/core/companies.service';
import { Globals } from 'src/app/core/globals.service';

@Component({
  selector: 'app-add-company',
  templateUrl: './add-company.component.html',
  styleUrls: ['./add-company.component.scss']
})
export class AddCompanyComponent implements OnInit {

  companyForm: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private accountsService: AccountsService,
    private globals: Globals,
    private companiesService: CompaniesService,
    private dialogRef: MatDialogRef<AddCompanyComponent>
  ) { }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.companyForm = this.fb.group({
      name: ['', Validators.required],
      gstNum: [''],
      contactPerson: [''],
      email: [''],
      phone: [''],
      address: ['']
    });
  }

  submit() {
    if (this.data?.configLevel === 'chain') {
      this.addGroupCompany();
    }
    else {
      this.addHotelCompany();
    }
  }

  addHotelCompany() {
    const formVal = this.companyForm.value;
    const body = {
      company_name: formVal['name'],
      tax_num: formVal['gstNum'],
      contact_person: formVal['contactPerson'],
      email: formVal['email'],
      phone_num: formVal['phone'],
      address: formVal['address'],
      applicable_to_chain: '',
      contracted_rate: '',
      user: localStorage.getItem('user')
    }

    this.companiesService.addCompany(body, this.data.hotelId).subscribe(res => {
      this.onSuccess();
    })
  }

  addGroupCompany() {
    this.accountsService.addGroupCompanies(this.data.hotelId, this.companyForm.value)
    .subscribe(res => {
      if (res?.success) {
        this.onSuccess();
      }
    });
  }

  onSuccess() {
    this.dialogRef.close();
    this.globals.snackBarFunc('Company Added');
  }
}
