<!-- <button mat-icon-button aria-label="close dialog" mat-dialog-close class="closeButton">
  <mat-icon>close</mat-icon>
</button> -->
<div id="container">
  <div class="form-row" id="header"><h3>{{hotelName}} - {{data.roomNum}}</h3>
    <button mat-icon-button aria-label="close dialog" mat-dialog-close class="form-inline ml-auto" id="close">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <br>
  <div class="form-row">
    <div class="form-group col-md-3 col-sm-3">
      <label for="startdate">Start Date:</label>
      <input type="date" class="form-control form-control-sm" placeholder="startdate" id="startdate" [(ngModel)]="startDate"  name="startdate" (change)="checkDateDiff(startDate, endDate)">
    </div>
    <div class="form-group col-md-2 col-sm-2">
      <label for="starttime">Start Time:</label>
      <input type="time" class="form-control form-control-sm" placeholder="starttime" id="starttime" [(ngModel)]="startTime"  name="starttime" >
    </div>
    <div class="form-group col-md-3 col-sm-3">
      <label for="enddate">End Date:</label>
      <input type="date" class="form-control form-control-sm" placeholder="enddate" id="enddate" [(ngModel)]="endDate"  name="enddate" (change)="checkDateDiff(startDate, endDate)">
    </div>
    <div class="form-group col-md-2 col-sm-2">
      <label for="endtime">End Time:</label>
      <input type="time" class="form-control form-control-sm" placeholder="endtime" id="endtime" [(ngModel)]="endTime"  name="endtime" >
    </div>
    <div class="form-group col-md-2 col-sm-2">
      <br>
      <button class="btn mr-1" type="submit" (click) = submit(startDate,startTime,endDate,endTime) [disabled] = "InvalidInput" >Submit</button>
    </div>
  </div>
  <span>Kindly select the date range with difference not more than 2 days.</span>
  <div style="display: block">
    <canvas baseChart
            [datasets]="lineChartData"
            [labels]="lineChartLabels"
            [legend]="lineChartLegend"
            [options]="lineChartOptions"
            [chartType]="lineChartType"
            width="700" height="200">
    </canvas>
  </div>
</div>

<div>
  <div style="display: block">
    <canvas baseChart
            [datasets]="lineChartData1"
            [labels]="lineChartLabels1"
            [legend]="lineChartLegend1"
            [options]="lineChartOptions1"
            [chartType]="lineChartType1"
            width="700" height="200">
    </canvas>
  </div>
</div>