<div class="primary_modal_wrapper">
    <div class="modal_heading">
        <h1>Get Payment File</h1>
    </div>

    <mat-divider></mat-divider>

    <div class="modal_body">
        
        <div class="scroll-table-container">
            <table class="table">
                <thead class="thead-dark">
                    <tr>
                        <th class="text-left small-width-col">Date</th>
                        <th class="text-left small-width-col">POS</th>
                        <th class="text-left">Vendor</th>
                        <th class="text-left">Invoice No.</th>
                        <th class="text-right">Amount</th>
                    </tr>
                </thead>
    
                <tbody>
                    <tr *ngFor="let payment of data.payments">
                        <td>{{payment.date | date: 'dd MMM'}}</td>
                        <td class="text-left">{{payment.pos_name}}</td>
                        <td class="text-left" nowrap="">{{payment.vendor_name}}</td>
                        <td class="text-left">{{payment.expense_invoice_num}}</td>
                        <td class="text-right">
                            <input type="text" class="form-control form-control-sm"
                                (keypress)="globals.numberOnly($event)"
                                [(ngModel)]="payment.inputVal">
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        
    </div>

    <div class="modal_footer">
        <mat-dialog-actions align="end">
            <button mat-raised-button 
                mat-dialog-close>
                Cancel
            </button>
            <button mat-raised-button
                class="primary_btn"
                (click)="downloadClick()">
                Download
            </button>
        </mat-dialog-actions>
    </div>

</div>