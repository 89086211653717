<div>
    <div class="heading flex-space-bw">
        <h2>{{expenseType === 'hotel' ? 'Expenses' : 'Group Expenses'}}</h2>
        <button mat-raised-button class="active small-btn"
            (click)="goBack()">
            Back
        </button>
    </div>
    <mat-divider></mat-divider>

    <div class="container-fluid">
        <div class="row pt-1 pb-2 bg-white">
            <div class="col-sm-2">
                <label for="From Date">From Date:</label>
                <input type="date" class="form-control" id="from-date" max="{{endDate}}" [(ngModel)]="startDate"
                (change)="initView()">
            </div>
            <div class="col-sm-2">
                <label for="To Date">To Date:</label>
                <input type="date" class="form-control" id="to-date" min="{{startDate}}" [(ngModel)]="endDate"
                (change)="initView()">
            </div>
            <div class="col-sm-2">
                <label for="To Date">Type:</label>
                <select class="form-control"  id="payment_type" name="type" 
                    [(ngModel)]="paymentType" (change)="initView()">
                    <option *ngFor="let option of paymentModes" [value]="option">{{option}}</option>
                </select>
            </div>
            <div *ngIf="expenseType === 'chain'"
                class="col-sm-2">
                <label for="To Date">Hotel:</label>
                <select class="form-control"
                    [(ngModel)]="selectedHotel" (change)="changeHotel()">
                    <option value="">All</option>
                    <option *ngFor="let hotel of payments?.hotels" [value]="hotel">{{hotel}}</option>
                </select>
            </div>

            <div class="ml-auto">
                <label [ngStyle]="{'visibility': 'hidden'}">Add Expense</label>
                <div>
                    <button 
                        *ngIf="accountsConfig?.conf?.accountsConfigLevel === 'chain' && expenseType === 'hotel'"
                        mat-raised-button class="active"
                        (click)="getGroupExpense()">
                        Group Expense
                    </button>

                    <button *ngIf="payments?.total_count > 0"  mat-raised-button
                        class="active ml-2"
                        (click)="downloadExcel()">
                        <span>Download</span>
                    </button>

                    <button *ngIf="expenseType === 'hotel'"
                        mat-raised-button class="active ml-2"
                        (click)="goToAddPayment(false, 'Add Expense')">
                        Add Expense
                    </button>


                    <button *ngIf="expenseType === 'hotel'"
                        mat-raised-button class="active ml-2" 
                        (click)="goToAddPayment(true, 'Deposit')">
                        Deposit
                    </button>

                    <button *ngIf="payments?.total_count > 0 && storeConfig.isEnabled" 
                        mat-raised-button
                        class="active ml-2"
                        (click)="getBankPaymentFile()">
                        <span>Get Bank Payment File</span>
                    </button>

                </div>
            </div>
        </div>
        
        <div class="content">

            <table class="table table-bordered mt-2" 
                *ngIf="payments?.total_count > 0">
                <thead class="thead-dark">
                    <tr>
                        <th></th>
                        <th class="text-left small-width-col">Date</th>
                        <th class="text-left small-width-col">POS</th>
                        <th class="text-left small-width-col" *ngIf="expenseType === 'chain'">Hotel</th>
                        <th class="text-left" width="10">Guest</th>
                        <th class="text-center" nowrap="">Room No.</th>
                        <th *ngIf="paymentType !== 'Bill to Company'" class="text-left small-width-col">Type</th>
                        <th *ngIf="paymentType !== 'Bill to Company'" class="text-left small-width-col">Category</th>
                        <th *ngIf="paymentType !== 'Bill to Company'" class="text-left small-width-col">Expense Date</th>
                        <th class="text-left">Invoice No.</th>
                        <th class="text-center">Details</th>
                        <th class="text-left">Comments</th>
                        <th *ngIf="paymentType === 'Bill to Company'" class="text-left small-width-col">Company</th>
                        <th *ngIf="paymentType === 'Bill to Company'" class="text-left small-width-col">BTC Payment Date</th>
                        <th *ngIf="paymentType === 'Bill to Company'" class="text-left small-width-col">Reference</th>
                        <th *ngIf="paymentType === 'Bill to Company'" class="text-left small-width-col">File</th>
                        <th class="text-right small-width-col">Paid in</th>
                        <th class="text-right small-width-col">Paid out</th>
                        <th class="text-right">Balance</th>
                        <th></th>
                    </tr>
                </thead>

                <tbody>
                    <ng-container *ngFor="let item of showPayments; let i=index">
                        <tr
                            [ngStyle]="{'background-color': '#d2d6d7'}">
                            <td [attr.colspan]="expenseType === 'hotel' ? 14 : 15" class="heading">
                                {{item.date | date: 'dd MMM'}}
                            </td>
                        </tr>
                        <tr *ngFor="let payment of item.data">
                            <td>
                                <ng-container *ngIf="!payment.paid_in && payment.vendor_name">
                                    <input type="checkbox" (change)="selectPayment($event.target.checked, payment)">
                                </ng-container>
                            </td>
                            <td class="text-left">{{payment.date | date: 'hh:mm a'}}</td>
                            <td class="text-left">{{payment.pos_name}}</td>
                            <td class="text-left" *ngIf="expenseType === 'chain'">{{payment.hotel}}</td>
                            <td class="text-left" nowrap="">{{payment.vendor_name ? payment.vendor_name : payment.guest_name}}</td>
                            <td class="text-center">{{payment.room_num}}</td>
                            <td class="text-left" *ngIf="paymentType !== 'Bill to Company'">{{payment.type_of_payment}}</td>
                            <td class="text-left" *ngIf="paymentType !== 'Bill to Company'">{{payment.expense_category}}</td>
                            <td class="text-left" *ngIf="payment.expense_date && paymentType !== 'Bill to Company'">{{payment.expense_date | date: 'dd MMM YYYY'}}</td>
                            <td class="text-left" *ngIf="!payment.expense_date && paymentType !== 'Bill to Company'"></td>
                            <td class="text-left">{{payment.expense_invoice_num}}</td>
                            <td class="text-center">
                                <a *ngIf="payment.details?.length > 0" [href]="payment.details" target="_blank">View</a>
                            </td>
                            <td class="text-left">{{payment.comments}}</td>
                            
                            <td *ngIf="paymentType === 'Bill to Company'" class="text-left">{{payment.company_name}}</td>
                            <td *ngIf="paymentType === 'Bill to Company'" class="text-left">{{payment.btc_payment_date}}</td>
                            <td *ngIf="paymentType === 'Bill to Company'" class="text-left">{{payment.btc_payment_ref}}</td>
                            <td *ngIf="paymentType === 'Bill to Company'" class="text-left">
                                <a *ngIf="payment.btc_payment_file?.length > 0" [href]="payment.btc_payment_file" target="_blank">View</a>
                            </td>


                            <td class="text-right">
                                <ng-container *ngIf="payment.paid_in">
                                    {{payment.amount | number: '1.2-2'}}
                                </ng-container>
                            </td>
                            <td class="text-right">
                                <ng-container *ngIf="!payment.paid_in">
                                    {{payment.amount | number: '1.2-2'}}
                                </ng-container>
                            </td>
                            <td></td>
                            <td>
                                <mat-icon class="text-danger" *ngIf="payment.deleteable"
                                    (click)="deletePayment(payment.id, payment.amount, payment.paid_in)">
                                    delete
                                </mat-icon>
                            </td>
                        </tr>
                        <tr *ngIf="paymentType === 'Cash' && expenseType === 'hotel'">
                            <td colspan="13" class="text-right font-weight-bold">
                                Carry Forward
                            </td>
                            <td class="text-right font-weight-bold">
                                {{item.carryForward | number: '1.2-2'}}
                            </td>
                        </tr>
                        <tr *ngIf="paymentType === 'Cash' && expenseType === 'hotel'"
                            [ngStyle]="{'background-color': 'rgb(236 236 236)'}">
                            <td colspan="14"
                                class="text-right font-weight-bold">
                                {{item.balance | number: '1.2-2'}}
                            </td>
                        </tr>
                    </ng-container >
                </tbody>
            </table>
            <div *ngIf="payments?.total_count === 0">
                <span class="text-danger" [ngStyle]="{'font-size': '1.3rem'}">
                    No {{paymentType}} payments were made between {{startDate | date: 'dd MMM'}} & {{endDate | date: 'dd MMM'}}
                </span>
            </div>
        </div>
    </div>
</div>