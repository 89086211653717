<div>
    <div class="heading d-flex justify-content-between align-items-center">
        <div class="d-flex align-items-center">
            <h1>{{ "banquets.title" | translate }}</h1>
        </div>

    </div>

    <mat-divider></mat-divider>

    <div>
        
        <ul class="list_table">

            <li>
                <div class="main_header">
                    <div class="status_colors">
                        <div>
                            <div class="color-code" [ngStyle]="{'background-color': '#1ebc38cb'}"></div>{{ "banquets.confirmed" | translate }}
                        </div>
                        <div>
                            <div class="color-code" [ngStyle]="{'background-color': '#023009'}"></div>{{ "banquets.completed" | translate }}
                        </div>
                    </div>

                    <div class="d-flex justify-content-between align-items-center">
                        <button mat-raised-button
                            class="primary_btn"
                            (click)="createReservation()">
                            {{ "banquets.new_reservation" | translate }}
                        </button>

                        <div class="d-flex justify-content-between align-items-center date-picker">
                            <svg (click)="changeDay('prev')" role="button" matTooltip="Previous"
                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                              <path
                                d="M224 480c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25l192-192c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l169.4 169.4c12.5 12.5 12.5 32.75 0 45.25C240.4 476.9 232.2 480 224 480z" />
                            </svg>

                            <input 
                                type="date" 
                                name="staydate" 
                                class="form-control form-control-sm ml-2 mr-2"
                                [ngModel]="startDate"
                                (change)="datePicked($event.target.value)">

                            <svg (click)="changeDay('next')" role="button" matTooltip="Next"
                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                              <path
                                d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z" />
                            </svg>
                        </div>

                    </div>
        
                </div>
            </li>

            <li class="header_date_row">
                <span class="first_cell">{{'stayview.rooms' | translate}}</span>
                <div class="main_cells_wrapper">
                    <span class="cell" *ngFor="let date of headerDates">
                        {{date | date: ' dd MMM'}} <br>
                        {{date | date: ' EEE '}}
                    </span>
                </div>
            </li>

            <li>
                <span class="first_cell"></span>
                <div class="main_cells_wrapper">
                    <span class="cell" *ngFor="let date of headerDates">
                        <div class="day_divider">
                            <div *ngFor="let type of sessions">{{type.shortName}}</div>
                        </div>
                    </span>
                </div>
            </li>

            <li class="data_row_container">
                <div class="room_type_container" *ngFor="let category of hallsData">
                    <div class="room_type_row room_row">
                        <div class="first_cell" (click)="category.hidden = !category.hidden">
                            <span [matTooltip]="category.category">{{category.categoryName}}</span>

                            <svg [@rotate]="category.hidden ? 'rotated': 'initial'"
                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                              <path
                                d="M352 352c-8.188 0-16.38-3.125-22.62-9.375L192 205.3l-137.4 137.4c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25C368.4 348.9 360.2 352 352 352z" />
                            </svg>
                        </div>

                        <div class="main_cells_wrapper">

                            <div class="day_divider cell" *ngFor="let d of headerDates">
                                <div *ngFor="let type of sessions; let si=index">
                                    <ng-container *ngIf="category?.tentatives?.hasOwnProperty(d)">

                                            <span 
                                                role="button" 
                                                class="cell" >
                                                <span class="cell_info"
                                                    [matMenuTriggerFor]="unassignedGuestsDrop" 
                                                    [ngClass]="{'bg_primary': category?.tentatives[d][si]?.length > 0}">
                                                    {{category?.tentatives[d][si]?.length || ''}}
                                                </span>
                
                                                <!-- UNASSIGNED GUESTS MENU START -->

                                                <mat-menu #unassignedGuestsDrop="matMenu"> 

                                                    <div class="menu-container" 
                                                        *ngFor="let unGuest of category?.tentatives[d][si]; 
                                                        let ug = index;" 
                                                        [ngStyle]="{'background-color': ug%2 == 0 ? '#f2f2f2': '#fff'}">  

                                                        <div class="mb-2">
                                                            <span>{{unGuest.guestName}}</span>

                                                            <div>
                                                                <button class="btn btn-info btn-sm" (click)="editReservation(unGuest.reservationId)">{{ "banquets.edit" | translate }}</button>
                                                                <button class="btn btn-info btn-sm" (click)="changeStatus('confirmed', unGuest)">{{ "banquets.confir" | translate }}</button>
                                                            </div>
                                                        </div>

                                                        <div>
                                                            <small class="text-secondary">{{unGuest.hallNames}}</small>

                                                            <div class="d-flex justify-content-between">
                                                                <small class="text-secondary" *ngFor="let uns of unGuest.sessions">
                                                                    {{sessionMap[uns]}},
                                                                </small>
                                                            </div>

                                                            <small class="text-secondary">{{unGuest.price}}</small>
                                                        </div>
                                                    </div> 

                                                </mat-menu>

                                                <!-- UNASSIGNED GUESTS MENU START -->
                                            </span>

                                    </ng-container>
                
                                </div>
                    
                            </div>
                             
                        </div>

                    </div>
    
                    <div class="rooms_expandable_wrapper" 
                        [@expandCollapse]="category.hidden ? 'collapsed': 'expanded'" [@staggerEffect]>

                        <div class="rooms_row room_row" *ngFor="let hall of category?.halls">
                            <div class="first_cell">
                                <span>{{hall.hallName}}</span>

                            </div>

                            <div class="main_cells_wrapper" >
                                <div class="day_divider cell" *ngFor="let d of headerDates">
                                    <div *ngFor="let type of sessions; let si = index">
                                        <ng-container  *ngIf="hall?.sessions?.hasOwnProperty(d)">
                                            <ng-container *ngFor="let booking of hall.sessions[d]">

                                                <div *ngIf="booking.sessions.includes(si.toString())"
                                                    class="booking_block"
                                                    draggable="true"
                                                    [ngStyle]="booking | banquetBookingPos"
                                                    (click)="editReservation(booking.reservationId)">
                                                    {{booking.guestName}}
                                                </div>

                                            </ng-container>
                                        </ng-container>

                                    </div>
                        
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </li>

        </ul>
    </div>
</div>
