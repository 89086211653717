import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { OnboardData } from '../views/onboard/onboarddata';


@Injectable({
  providedIn: 'root'
})
export class OnboardService {

  constructor(private http: HttpClient) { }


  getConfig(productId): Observable<any> {
    return this.http.get(`${environment.apiUrl}/products/${productId}`);
  };

  getHotels(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/hotels/`);
  };

  getRoles(roleType): Observable<any> {
    let params = new HttpParams();
    params = params.append('roleType', roleType);
    return this.http.get(`${environment.apiUrl}/users/get-roles`, {params: params});
  };

  getEditOnboardData(hotelId): Observable<any> {
    return this.http.get(`${environment.apiUrl}/onboard_edit/${hotelId}`);
  };

  verifyOverRideHotelId(hotelName): Observable<any> {
    return this.http.get(`${environment.apiUrl}/onboard_new/over-ride/${hotelName}`);
  };

  onboardRms(onboardPayload: OnboardData, overRide): Observable<any> {
    let params = new HttpParams();
    params = params.append('typo', overRide);
    return this.http.post(`${environment.apiUrl}/onboard_new/rms/update`, onboardPayload, {params: params});
  };

  onboardPms(onboardPmsPayload): Observable<any> {
    return this.http.post(`${environment.apiUrl}/onboard_new/pms/update`, onboardPmsPayload);
  };


}
