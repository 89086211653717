<div class="container">
    <div class="row">
        <div class="col-sm-2">
            <label for="Department">{{'stores.department' | translate}}</label>
            <input list="departmentList" class="form-control form-control-sm" (click)="department=''"
                (change)="filterBy()" [(ngModel)]="department">
            <datalist id="departmentList">
                <option value="All">
                <option *ngFor="let dept of userDepartments" [value]="dept.name">
            </datalist>

        </div>
        <div class="col-sm-2">
            <label for="Category">{{'stores.category' | translate}}</label>
            <select class="form-control form-control-sm" (change)="filterSubCategories(category); filterBy();" [(ngModel)]="category">
                <option value="">All</option>
                <option *ngFor="let cat of categories">
                    {{cat.name}}
                </option>
            </select>
        </div>
        <div class="col-sm-2">
            <label for="subCategory">{{'stores.sub_category' | translate}}</label>
            <select class="form-control form-control-sm" (change)="filterBy();" [(ngModel)]="subCategory">
                <option value="">All</option>
                <option *ngFor="let cat of subCategories">
                    {{cat.name}}
                </option>
            </select>
        </div>
        <div class="col-sm-3 ml-auto">
            <button mat-stroked-button class="border-info return-btn"
                (click)="returnStock()">
                {{'stores.return_btn' | translate}}
            </button>

            <input type="search" class="form-control form-control-sm" placeholder="{{'stores.search' | translate}} . . ."
            (input)="searchFun($event.target.value)">
        </div>
    </div>

    <div class="d-flex justify-content-end total-value-text">
        Total Value: <span>{{totalValue | number: '1.2-2'}}</span>
    </div>

    <table class="mt-4 table table-striped">
        <thead class="thead-dark">
            <tr>
                <th>{{'stores.department' | translate}}</th>
                <th>{{'stores.item' | translate}}</th>
                <th>{{'stores.category' | translate}}</th>
                <th>{{'stores.sub_category' | translate}}</th>
                <th class="text-right">{{'stores.stock' | translate}}</th>
                <th>{{'stores.unit' | translate}}</th>
                <th class="text-right">{{'stores.value' | translate}}</th>
                <th class="text-right">{{'stores.last_updated' | translate}}</th>
            </tr>
        </thead>

        <tbody>
            <tr *ngFor="let dept of $deptStock">
                <td>{{dept.dept_name}}</td>
                <td>{{dept.item_name}}</td>
                <td>{{dept.item_category}}</td>
                <td>{{dept.item_sub_category}}</td>
                <td class="text-right font-weight-bold">{{dept.stock}}</td>
                <td>{{dept.unit}}</td>
                <td class="text-right">{{dept.value | number: '1.2-2'}}</td>
                <td class="text-right">{{dept.last_updated | date : 'dd-MM-YYYY hh:mm a'}}</td>
            </tr>
        </tbody>
    </table>
</div>