<div class="primary_modal_wrapper"
  [ngStyle]="submitting && {'pointer-events': 'none'}">
  <div class="modal_heading">
    <h1>{{'stores.return_to_master_store' | translate}}</h1>
  </div>

  <mat-divider></mat-divider>

  <div class="modal_body">
    <div class="row">
      <div class="col-sm-6">
        <label for="Department">{{'stores.department' | translate}}</label>
        <ng-select class="form-control form-control-sm" (change)="deptChange($event)">
          <ng-option *ngFor="let item of data?.departments" [value]="item.id">
            {{item.name}}
          </ng-option>
        </ng-select>
      </div>

      <div class="col-sm-6">
        <label for="Item">{{'stores.item' | translate}}</label>
        <ng-select class="form-control form-control-sm" (change)="selectItem($event)">
          <ng-option *ngFor="let item of filteredItems" [value]="item">
            {{item.item_name}}
          </ng-option>
        </ng-select>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-sm-6" style="height: 90px;">
        <label>{{'stores.quantity_to_return' | translate}}
          <span *ngIf="selectedItem?.item_id">
            | Available :&nbsp;<span class="text-success">{{selectedItem.stock}}</span>
            ({{selectedItem.unit}}) 
          </span>
        </label>
        <input 
          type="number" 
          class="form-control form-control-sm" 
          [(ngModel)]="selectedItem.quantity"
          (input)="quantityChange()">

        <div class="error_msg" *ngIf="quantityError">{{quantityError}}</div>
      </div>

      <div class="col-sm-6 d-flex align-items-center">
        <button mat-stroked-button (click)="add()" class="border-info">
          {{'stores.add' | translate}}
        </button>
      </div>
    </div>

    <div class="table_wrapper">
      <table class="table">
        <thead>
          <tr>
            <th>{{'stores.dept' | translate}}</th>
            <th>{{'stores.item' | translate}}</th>
            <th>{{'stores.qty' | translate}}</th>
            <th></th>
          </tr>
        </thead>
  
        <tbody>
          <tr *ngFor="let item of returnItems">
            <td>{{item.dept_name}}</td>
            <td>{{item.item_name}}</td>
            <td>{{item.quantity}} {{item.unit}}</td>
            <td>
              <mat-icon class="text-danger" role="button"
                (click)="removeItem(item.item_id)"
                matTooltip="Remove item">
                delete
              </mat-icon>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

  </div>

  <div class="modal_footer">
    <mat-dialog-actions align="end">
      <button mat-raised-button mat-dialog-close>{{'stores.cancel' | translate}}</button>
      <button mat-raised-button
        class="primary_btn" 
        (click)="submitConfirmation()">
        {{submitting ? 'Returning...' : 'Return'}}
      </button>
    </mat-dialog-actions>
  </div>

</div>
