<div class="dialog-box-popup">
    <div class="row mb-1">
        <h1>{{'stayview.swap_room.exchange_room' | translate}}</h1>
        <hr>
    </div>

    <div class="popup-form">
        <div class="form-row">
            <div class="form-group col-md-4 mb-3">
                <label for="currentRoom">{{'stayview.swap_room.current_room' | translate}}</label>
                <input type="text" id="currentRoom" class="form-control form-control-sm" [(ngModel)]="currentroomNum" name='currentroomNum'>
            </div>
            <div class="form-group col-md-4 mb-3">
                <label for="nextRoom">{{'stayview.swap_room.exchange_room' | translate}}</label>
                <input type="text" class="form-control form-control-sm" list="rooms" [(ngModel)]="selecteddata" name="rooms" (click)="clearField()">
                <datalist id="rooms" name="rooms">
                    <option> </option>
                    <option *ngFor="let option of roomAllocsDetails" [value]="option.roomNum">{{option.roomNum}}</option>
                </datalist>
            </div>
        </div>
        <div class="row action-buttons mt-4">
            <button type="button" mat-raised-button (click)="closeDialog()">
                {{'stayview.swap_room.cancel' | translate}}
            </button>
            <button *ngIf="(submitting == false)" type="submit" mat-raised-button (click)="submit(selecteddata)" [disabled]="!selecteddata"
                class="confirm-button">
                {{'stayview.swap_room.submit' | translate}}
            </button>
            <button *ngIf="(submitting == true)" type="submit" mat-raised-button disabled
                class="confirm-button">
                {{'stayview.swap_room.submitting' | translate}}
            </button>
        </div>
    </div>
</div>

