import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormArray,
  FormControl,
} from '@angular/forms';
import { GuestsService } from 'src/app/core/guests.service';
import { Globals } from 'src/app/core/globals.service';
import { MatDialog } from '@angular/material/dialog';
import { BookingDetailsComponent } from '../booking-details/booking-details.component';
import { UserService } from 'src/app/core/user.service';
import { forkJoin } from 'rxjs';
import { saveAs } from 'file-saver-es';
import { DialogComponent } from '../../_shared/dialog/dialog.component';

@Component({
  selector: 'app-guests',
  templateUrl: './guests.component.html',
  styleUrls: ['./guests.component.scss'],
})
export class GuestsComponent implements OnInit {
  hotelId: string;
  guestData: any[];
  currencyCode: string;
  currentPage = 0;
  totalPages = 0;
  entries = 0;
  totalEntries = 0;
  search: FormGroup;
  editGuestForm: FormGroup;
  searchMode = false;
  overlay = false;
  showEditForm = false;
  genders = ['Male', 'Female', 'Other'];
  identityTypes = [];
  localFilesSrc = [];
  idEditable = false;
  user = '';

  constructor(
    private guestsService: GuestsService,
    private route: ActivatedRoute,
    public fb: FormBuilder,
    public global: Globals,
    public dialog: MatDialog,
    public userService: UserService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.hotelId = params.hotelId;
      this.global.changeHotelID(this.hotelId);
    });
    this.route.queryParams.subscribe((queryParams) => {
      this.user = queryParams.user;
    });
    this.getGuests();
    this.global.getCurrencyCode(this.hotelId).subscribe((data) => {
      this.currencyCode = data;
    });

    this.search = this.fb.group({
      searchInput: ['', Validators.required],
    });

    this.getProductConfig();
  }

  getProductConfig() {
    this.userService.getProductConfig(this.hotelId).subscribe((data) => {
      this.identityTypes = data.conf.identityTypes;
    });
  }

  initEditGuestForm(data) {
    this.editGuestForm = this.fb.group({
      id: [data.id],
      name: [data.name, [Validators.required, Validators.maxLength(64)]],
      email: [data.email, Validators.maxLength(64)],
      phone: [data.phone, Validators.maxLength(15)],
      photo_id: new FormArray([]),
      date_of_birth: [data.date_of_birth],
      gender: [data.gender],
      address: this.fb.group({
        address: [data.address?.address, Validators.maxLength(64)],
        city: [data.address?.city, Validators.maxLength(30)],
        country: [data.address?.country, Validators.maxLength(30)],
        state: [data.address?.state, Validators.maxLength(30)],
        zip: [data.address?.zip, Validators.maxLength(30)],
      }),
      id_proof: [data.id_proof],
      id_detail: [data.id_detail],
    });

    if (data.photo_id?.length) {
      for (const url of data.photo_id) {
        this.addPhotoId(url);
      }
    }
  }

  addPhotoId(url) {
    (this.editGuestForm.get('photo_id') as FormArray).push(
      new FormControl(url)
    );
  }

  getGuests(page = 0) {
    this.guestsService.getGuests(this.hotelId, page).subscribe((data) => {
      this.guestData = data.data;
      this.entries = this.guestData.length;
      this.currentPage = data.page;
      this.totalPages = data.total_pages;
      this.totalEntries = data.total_entries;
    });
  }

  onSearch(key) {
    if (this.search.valid) {
      this.guestsService
        .guestSearch(this.hotelId, key.value.searchInput)
        .subscribe((data) => {
          this.guestData = data.data;
          this.entries = this.guestData.length;
          this.currentPage = data.page;
          this.totalPages = data.total_pages;
          this.totalEntries = data.total_entries;
          this.searchMode = true;
        });
    }
  }

  openPhotoId(ids) {
    ids.forEach((id) => {
      window.open(id.url, '_blank');
    });
  }

  openBookingDetails(bookingId) {
    this.dialog.open(BookingDetailsComponent, {
      data: {
        bookingId,
        hotelId: this.hotelId,
        currencyCode: this.currencyCode,
      },
    });
  }

  getPrevBookings(guest) {
    if (guest.previous_stays) {
      return;
    }
    this.guestsService
      .getPrevBookings(guest.id, this.hotelId)
      .subscribe((data) => {
        guest.previous_stays = data?.prev_bookings;
      });
  }

  paginator(currentPage: number, totalPages: number) {
    let showPages = [];
    let start = 0;
    if (currentPage - 2 < 0) {
      start = currentPage;
    } else {
      start = currentPage - 2;
    }
    let end = start + 5;
    if (end > totalPages) {
      end = totalPages;
    }
    for (let i = start; i <= end; i++) {
      showPages.push(i);
    }
    return showPages;
  }

  editFormToggle(data?) {
    this.localFilesSrc = [];

    if (data) {
      if (!(data.id_detail?.length && data.id_proof?.length)) {
        this.idEditable = true;
      } else {
        this.idEditable = false;
      }
      this.initEditGuestForm(data);
    }

    this.showEditForm = !this.showEditForm;
    this.overlay = this.showEditForm ? true : false;
  }

  onSubmit() {
    if (!this.localFilesSrc.length) {
      this.editGuestData();
      return;
    }

    this.uploadFilesToS3(this.localFilesSrc).subscribe((newSrc) => {
      for (const url of newSrc) {
        this.addPhotoId(url);
      }
      this.editGuestData();
    });
  }

  uploadFilesToS3(files) {
    const requests = [];
    for (const file of files) {
      const key = `${this.hotelId}/${file.file.name}`;
      requests.push(this.userService.uploadFile(file.file, key));
    }
    return forkJoin(requests);
  }

  fileUpload(input) {
    const reader = new FileReader();
    reader.onload = (e) => {
      this.localFilesSrc.push({ src: e.target.result, file: input.files[0] });
    };
    reader.readAsDataURL(input.files[0]);
  }

  editGuestData() {
    this.guestsService
      .editGuest(
        this.hotelId,
        this.editGuestForm.value.id,
        this.editGuestForm.value
      )
      .subscribe((data) => {
        this.getGuests();
      });
  }

  downloadFile() {
    this.guestsService.downloadGuests(this.hotelId).subscribe((data) => {
      let blob: any = new Blob([data], { type: 'text/xlsx; charset=utf-8' });
      let fileName = this.hotelId + '_guests.xlsx';
      saveAs(blob, fileName);
    });
  }

  uploadFile(event) {
    const dialogBody = {
      title: 'Upload Guest Details',
      body: 'Are you sure you want to Submit ?',
      confirmBtn: 'Submit',
      cancelBtn: 'Cancel',
    };
    const dialogRef = this.dialog.open(DialogComponent, { data: dialogBody });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'true') {
        var filelength = Object.keys(event.files).length;
        for (var i = 0; i < filelength; i++) {
          this.guestsService
            .uploadGuests(this.hotelId, event.files[i], this.user)
            .subscribe(
              (success) => {
                const WarningDialog = {
                  title: 'Success !',
                  body: `You Have successfully added Guests list`,
                  cancelBtn: 'Dismiss',
                };
                this.dialog.open(DialogComponent, { data: WarningDialog });
              },
              (err) => {
                const WarningDialog = {
                  title: 'ERROR !',
                  body: 'Something went wrong',
                  cancelBtn: 'Dismiss',
                };
                this.dialog.open(DialogComponent, { data: WarningDialog });
              }
            );
        }
      }
    });
  }

  removeDuplicateEntries() {
    const dialogBody = {
      title: 'Remove Duplicate Guest Entries',
      body: 'Maximum 150 entries will be removed per request, Are you sure you want to Remove ?',
      confirmBtn: 'YES',
      cancelBtn: 'NO',
    };
    const dialogRef = this.dialog.open(DialogComponent, { data: dialogBody });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'true') {
        this.guestsService
          .removeDuplicateEntries(this.hotelId, this.user)
          .subscribe(
            (success) => {
              if (success['success']['count'] == 0) {
                var WarningDialog = {
                  title: 'Success !',
                  body: `You Have No Duplicates Left To Remove`,
                  cancelBtn: 'Dismiss',
                };
              } else {
                var WarningDialog = {
                  title: 'Success !',
                  body:
                    `You Have successfully Removed ` +
                    success['success']['count'] +
                    ` Duplicates`,
                  cancelBtn: 'Dismiss',
                };
              }

              this.dialog.open(DialogComponent, { data: WarningDialog });
            },
            (err) => {
              const WarningDialog = {
                title: 'ERROR !',
                body: 'Something went wrong',
                cancelBtn: 'Dismiss',
              };
              this.dialog.open(DialogComponent, { data: WarningDialog });
            }
          );
      }
    });
  }
}
