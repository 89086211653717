<div>
    <div class="heading d-flex justify-content-between align-items-center">
        <h2>{{ "accounts.ledger" | translate }} - {{headerName}}</h2>

        <button mat-raised-button class="active" (click)="goBack()">
            {{ "accounts.back_btn" | translate }}
        </button>
    </div>
    <mat-divider></mat-divider>
    
    <div class="container">

        <div class="row pt-1 pb-2 bg-white">
            <div class="col-sm-2">
                <label for="From Date">{{ "accounts.from_date" | translate }}:</label>
                <input type="date" class="form-control" id="from-date" max="{{endDate}}" [(ngModel)]="startDate" (change)="dateChange()">
            </div>
            <div class="col-sm-2">
                <label for="To Date">{{ "accounts.to_date" | translate }}:</label>
                <input type="date" class="form-control" id="to-date" min="{{startDate}}" [(ngModel)]="endDate" (change)="dateChange()">
            </div>
            <div *ngIf="qParams?.company && payments?.configLevel === 'chain'"
                class="col-sm-2">
                <label for="To Date">{{ "accounts.hotel" | translate }}:</label>
                <select class="form-control"
                    [(ngModel)]="selectedHotel" (change)="changeHotel()">
                    <option value="">{{ "accounts.all" | translate }}</option>
                    <option *ngFor="let hotel of hotels" [value]="hotel.id">
                        {{hotel.name}}
                    </option>
                </select>
            </div>
        </div>

        <table class="table mt-3">
            <thead class="thead-dark">
                <tr>
                    <th>{{ "accounts.date" | translate }}</th>
                    <th *ngIf="payments?.configLevel === 'chain' && qParams?.company">{{ "accounts.hotel" | translate }}</th>
                    <th *ngIf="qParams?.company">{{ "accounts.room_no" | translate }}</th>
                    <th *ngIf="qParams?.company">{{ "accounts.guest_name" | translate }}</th>
                    <th>{{ "accounts.order_invoice_no" | translate }}.</th>
                    <th [width]="40">{{qParams?.company ? 'Particular' : 'Details'}}</th>
                    <th *ngIf="qParams?.vendor">{{ "accounts.image" | translate }}</th>
                    <th class="text-right">{{ "accounts.outstanding" | translate }}</th>
                    <th class="text-right">{{qParams?.company ? 'Received' : 'Paid'}}</th>
                </tr>
            </thead>
    
            <tbody>
                <tr class="dark-row">
                    <td>{{ "accounts.total" | translate }}</td>
                    <td [attr.colspan]="totalColSpan" class="text-right">
                        {{payments?.totalOutstanding | number: '1.2-2'}}
                    </td>
                    <td class="text-right">
                        {{payments?.totalReceived | number: '1.2-2'}}
                    </td>
                </tr>
                <tr *ngFor="let item of payments?.payments">
                    <td>{{item.date | date: 'dd MMM yyyy'}}</td>
                    <td *ngIf="payments?.configLevel === 'chain' && qParams?.company">{{item.hotelName}}</td>
                    <td *ngIf="qParams?.company">{{item.roomNum}}</td>
                    <td *ngIf="qParams?.company">{{item.guestName}}</td>
                    <td *ngIf="qParams?.company">{{item.invoiceNum ? item.invoiceNum : item.folioNum}}</td>

                    <td *ngIf="qParams?.vendor">{{item.orderNum}}/ {{item.invoiceNum}}</td>
                    <td>{{item.comments}}</td>
                    <td *ngIf="qParams?.vendor">
                        <a [href]="item.imgUrl" target="_blank">
                            <img *ngIf="item.imgUrl" [src]="item.imgUrl" width="30" height="30">
                        </a>
                    </td>

                    <td class="text-right">{{item.outstanding}}</td>
                    <td class="text-right">{{item.received}}</td>
                </tr>
            </tbody>
        </table>

    </div>

    <div class="d-flex justify-content-end">
        <nav>
            <ul class="pagination">
                <li class="page-item">
                    <a class="page-link" (click)="previousPage()">Previous</a>
                </li>
                <li class="page-item">
                    <a class="page-link active-page">
                        {{currentPage}}
                    </a>
                </li>
                <li class="page-item">
                    <a class="page-link" (click)="nextPage()">Next</a>
                </li>
            </ul>
          </nav>
    </div>

</div>
