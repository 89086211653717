import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { Globals } from 'src/app/core/globals.service';
import { LeadsService } from 'src/app/core/leads.service';
import { ReservationdataService } from 'src/app/core/reservationdata.service';
import { Statuses } from 'src/app/models/leads';
import { DialogComponent } from 'src/app/_shared/dialog/dialog.component';

@Component({
  selector: 'app-leads-list',
  templateUrl: './leads-list.component.html',
  styleUrls: ['./leads-list.component.scss']
})
export class LeadsListComponent implements OnInit {

  statuses = Statuses;
  types = [
    'Rooms',
    'Banquet',
    'Restaurant'
  ];

  hotelId: string;
  filterForm: FormGroup;
  leadsList: any[] = [];
  filteredLeads: any[] = [];
  payload: any;
  loaded = false;
  leadConfig: any = {};

  constructor(
    private leadsService: LeadsService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private reservData: ReservationdataService,
    private dialog: MatDialog,
    private globals: Globals
  ) { }

  ngOnInit(): void {
    const routeSnap = this.route.snapshot;
    this.hotelId = routeSnap.params?.hotelId;
    this.initForm();
    this.getLeads();
    this.valueChangesSub();
    this.getLeadConfig();
  }

  initForm() {
    const offsetDays = 7;
    const today = this.datePipe.transform((new Date()), 'y-MM-dd');
    let minusOffset: any = new Date();
    minusOffset.setDate(minusOffset.getDate() - offsetDays);
    minusOffset = this.datePipe.transform((new Date(minusOffset)), 'y-MM-dd');
    
    this.filterForm = this.fb.group({
      startDate: [minusOffset],
      endDate: [today],
      status: [''],
      type: [''],
    });
  }

  getLeadConfig() {
    this.leadsService.getLeadConfig(this.hotelId)
    .pipe( (finalize(() => this.loaded = true)))
    .subscribe(res => {
      this.leadConfig = res;
      this.types = this.leadConfig?.conf.types?.length ? this.leadConfig?.conf?.types : this.types;
    });
  }

  getLeads() {
    const filters = this.filterForm.value;

    this.leadsService.getLeads(this.hotelId, this.filterForm.controls.startDate.value, this.filterForm.controls.endDate.value)
    .subscribe(res => {
      if (res?.success) {
        this.leadsList = res.data;
        this.filteredLeads = JSON.parse(JSON.stringify(res.data));
      }
    });
  }

  createLeads() {
    this.router.navigate([`leads/${this.hotelId}/create`]);
  }

  valueChangesSub() {
    this.filterForm.controls.startDate.valueChanges.subscribe(() => {
      this.getLeads();
    });
    this.filterForm.controls.endDate.valueChanges.subscribe(() => {
      this.getLeads();
    });
    this.filterForm.controls.status.valueChanges.subscribe(() => {
      this.filterLeads();
    });
    this.filterForm.controls.type.valueChanges.subscribe(() => {
      this.filterLeads();
    });
  }

  filterLeads() {
    const status = this.filterForm.controls.status.value;
    const type = this.filterForm.controls.type.value;

    this.filteredLeads = this.leadsList.filter(lead => {
      return lead.status?.indexOf(status) > -1 &&
            lead.type?.indexOf(type) > -1;
    });
  }

  editLead(lead: any) {
    this.router.navigate([`leads/${this.hotelId}/${lead.id}`]);
  }

  changeStatus(lead: any, status: string) {
    const dRef = this.dialog.open(DialogComponent, {
      data: {
        title: 'CONFIRM',
        body: `Are you sure you want to change status to ${status}`,
        confirmBtn: 'Yes', cancelBtn: 'No'
      }
    });

    dRef.afterClosed().subscribe(res => {
      if (res === 'true') {
        this.leadsService.changeStatus(this.hotelId, lead.id, status)
        .subscribe(data => {
          if (data?.success) {
            this.globals.snackBarFunc('Status changed');
            lead.status = status;
          }
        });
      }
    });
  }

  getBookingPayload(bookingId: string, checkIn: string, checkOut: string) {
    const bookingInfo = {
      bookingid: bookingId, 
      checkin: checkIn, 
      checkout: checkOut
    };

    this.reservData.fetchpayload(bookingInfo, this.hotelId).subscribe(data => {
      this.payload = data?.payload;
    });
  }

}
