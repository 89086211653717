<div class="primary_modal_wrapper">
    <div class="modal_heading">
        <h1>Copy Invoice</h1>
    </div>

    <mat-divider></mat-divider>

    <div class="modal_body">

        <div class="d-flex justify-content-between align-items-end">
            <div>
                <label for="">{{'stores.start_date' | translate}} :</label>
                <input type="date" class="form-control form-control-sm" [(ngModel)]="startDate"
                (change)="getInvoices()" max="{{endDate}}">
            </div>
    
            <div>
                <label for="">{{'stores.end_date' | translate}} :</label>
                <input type="date" class="form-control form-control-sm" [(ngModel)]="endDate"
                (change)="getInvoices()" min="{{startDate}}">
            </div>

            <div>
                <input type="text" class="form-control form-control-sm" placeholder="Search" (input)="filterOrders($event.target.value)">
            </div>
        </div>
     
        <div class="table-container">
            <table class="mt-4 table table-striped">
                <thead class="thead-dark">
                    <tr>
                        <th>{{'stores.order' | translate}} #</th>
                        <th>{{'stores.invoice_date' | translate}}</th>
                        <th class="text-right">Value</th>
                        <th>{{'stores.invoice' | translate}}#</th>
                        <th>{{'stores.vendor' | translate}}</th>
                    </tr>
                </thead>
        
                <tbody>
                    <ng-container *ngIf="invoices?.length && !loading">
                        <tr *ngFor="let order of invoices" (click)="selectRow(order)"
                            [ngClass]="{'selected-row': selectedOrder?.id === order.id}">
                            <td>{{order.num}}</td>
                            <td>{{order.invoice_date | date: 'dd MMM'}}</td>
                            <td class="text-right">{{order.pre_tax_amount | number: '1.2-2'}}</td>
                            <td>{{order.invoice_num}}</td>
                            <td>{{order.vendor?.name}}</td>
                        </tr>
                    </ng-container>

                    <ng-container *ngIf="!loading && !invoices?.length">
                        <tr>
                            <td colspan="6"> No Invoice in this date range </td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>

    </div>

    <div class="modal_footer">
        <mat-dialog-actions align="end">
            <button mat-raised-button mat-dialog-close>Cancel</button>
            <button mat-raised-button
                [disabled]="!selectedOrder?.id"
                class="primary_btn"
                (click)="submit()">
                Copy
            </button>
        </mat-dialog-actions>
    </div>

</div>