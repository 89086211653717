import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { DatePipe, Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Globals } from 'src/app/core/globals.service';
import { UserService } from 'src/app/core/user.service';
import { CommunicationService } from 'src/app/core/communication.service';
import { FileService } from 'src/app/core/file.service';
import { StoreService } from 'src/app/core/store.service';
import { PosService } from 'src/app/core/pos.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-pos-reports',
  templateUrl: './pos-reports.component.html',
  styleUrls: ['./pos-reports.component.scss']
})

export class PosReportsComponent implements OnInit {
    hotelId: string;
    currentUser: string;
    currencyCode: string;
    fileName= 'ExcelSheet.xlsx';
    today =  new Date().toISOString().slice(0, 10);
    toDate = this.today;
    fromDate = this.today;
    monthFirstDay = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    reportsList = [];
    selectPosReport:any;
    posName: string;
    posDashData: any;
    config: any;
    posNames = [];
    reportData: any;
    serviceTotal = {amount: 0, tax: 0, total: 0};
    paymentsTotal = {amount: 0};
    
    
    

    constructor(public dialog: MatDialog, private globals: Globals, private route: ActivatedRoute,  public location: Location, private posService: PosService,
      public commS: CommunicationService, private userService: UserService, public storeService: StoreService, private datePipe: DatePipe) {}
    
    /** Default Intilization of Reports Page. */
    ngOnInit(): void {
      this.reportsList = ['Item Consumption', 'Payments', 'Complimentary orders', 'Cancelled payments', ];      
      this.route.params.subscribe(params => {
        this.hotelId = params.hotelId;
        this.globals.changeHotelID(this.hotelId);
      });
      this.globals.getCurrencyCode(this.hotelId).subscribe(
        data => {
          this.currencyCode = data;
      });
      this.selectPosReport = this.reportsList[0]; 
      this.getPosConfig();
      this.getCurrentDate();
    };

    /** Page On change Actions */
    getCurrentDate() {
      this.userService.getCurrentDate(this.hotelId).subscribe(res => {
        this.toDate = res?.currentDate;
        this.fromDate = this.datePipe.transform(this.monthFirstDay, 'yyyy-MM-dd');
      })
    };

    exportexcel(){
      let element = document.getElementById('excel-table');
      const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      this.fileName = this.selectPosReport +'.xlsx'
      XLSX.writeFile(wb, this.fileName);
    };


    getPaymentsReport() {
      const data: any = {};
      const invoiceTypes = ['billed', 'unbilled'];
      for (const type of invoiceTypes) {
        for (const item of this.posDashData[type]) {
          for (const payment of item.payments) {
            if (payment.type_of_payment in data) {
              data[payment.type_of_payment] += payment.amount;
            } else {
              data[payment.type_of_payment] = payment.amount;
            }
          }
        }
      }
      const dataArr = [];
      for (const payment in data) {
        if (data.hasOwnProperty(payment)) {
          dataArr.push({ type_of_payment: payment, amount: data[payment] });
        }
      }
      return dataArr;
    };

    pushService(service) {
      const serviceAmt = service.discounted_amount ? service.discounted_amount: service.service_amount;
      const data = {
        service_name: service.service_name,
        quantity: service.quantity,
        service_amount: serviceAmt,
        tax: service.tax.total,
        total_amount: service.total_amount,
      };
      return data;
    }

    getItemConsumptionReport() {
      const data = [];
      const invoiceTypes = ['billed', 'unbilled'];
      for (const type of invoiceTypes) {
        for (const item of this.posDashData[type]) {
          if (item.services_used) {
            for (const service of item.services_used) {
              if (data.length === 0) {
                data.push(this.pushService(service));
                continue;
              }
              let dup = false;
              for (const d of data) {
                if (d.service_name === service.service_name) {
                  const serviceAmt = service.discounted_amount
                    ? service.discounted_amount
                    : service.service_amount;
                  dup = true;
                  d.quantity += service.quantity;
                  d.service_amount += serviceAmt;
                  d.tax += service.tax.total;
                  d.total_amount += service.total_amount;
                  break;
                }
              }
              if (!dup) {
                data.push(this.pushService(service));
              }
            }
          }
        }
      }
      return data;
    };

    getNewPosReports(){
      console.log(this.selectPosReport);
      this.posService.getPmsPosReports(this.hotelId, this.selectPosReport, this.posName, this.fromDate, this.toDate).subscribe(res => {
        console.log(res)
        this.reportData = res;
      });
    };
  

  
    onSelect(reportName) {
      if (reportName === 'Item Consumption') {
        this.reportData = this.getItemConsumptionReport();
        this.serviceTotal = this.getServiceTotal(this.reportData);
      } else if (reportName === 'Payments') {
        this.reportData = this.getPaymentsReport();
        this.paymentsTotal = this.getPaymentsTotal(this.reportData);
      }else{
        this.getNewPosReports()
      } 
    };

    getOrders() {
      this.posService.getPosOrders(this.hotelId, this.posName, this.fromDate, this.toDate, this.today)
        .subscribe((data) => {
          this.posDashData = data;
          this.onSelect(this.selectPosReport);
        });
    };

    getPosConfig() {
      this.posService.getPosConfig(this.hotelId).subscribe(res => {
        this.getConfig();
      });
    };

    getConfig() {
      this.userService.getProductConfig(this.hotelId).subscribe((data) => {
        this.globals.setConfig(data.conf);
        this.config = data;
        if (!this.config.conf.hasOwnProperty('posData') ||this.config.conf.posData.length === 0) {
          return;
        }
        this.config.conf.posData.forEach((ele) => {
          this.posNames.push(ele.posName);
        });
        console.log(this.posNames)
        if (localStorage.getItem('posName') && this.posNames.includes(localStorage.getItem('posName'))) {
          this.posName = localStorage.getItem('posName');
        } else if (this.posNames?.length > 0) {
          this.posName = this.posNames[0];
        } else {
          return;
        }
        this.getOrders();
      });
    };

    getServiceTotal(data) {
      const totalObj = {amount: 0, tax: 0, total: 0};
      data.forEach(ele => {
        totalObj.amount += ele.service_amount;
        totalObj.tax += ele.tax;
        totalObj.total += ele.total_amount;
      });
      return totalObj;
    };

    getPaymentsTotal(data) {
      const totalObj = {amount: 0};
      data.forEach(ele => {
        totalObj.amount += ele.amount;
      });
      return totalObj;
    };
    
    goBack() { 
      this.location.back();
    };
  }


