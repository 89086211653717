import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StoreService } from 'src/app/core/store.service';
import { finalize } from 'rxjs/operators'

@Component({
  selector: 'app-vendor',
  templateUrl: './vendor.component.html',
  styleUrls: ['./vendor.component.scss']
})
export class VendorComponent implements OnInit {

  vendorForm: FormGroup;
  title: string = 'Add Vendor';
  loading: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<VendorComponent>,
    private fb: FormBuilder,
    private storeService: StoreService
  ) { }

  ngOnInit(): void {
    this.title = this.data?.title;
    this.initForm();
  }

  initForm() {
    const data = this.data?.vendor;
    this.vendorForm = this.fb.group({
      id: [data?.id],
      name: [data?.name ? data.name : '', Validators.required],
      contact_person: [data?.contact_person],
      gst_num: [data?.gst_num],
      phone: [data?.phone],
      address: [data?.address],
      email_id: [data?.email_id],
      state: [data?.state]
    });
  }

  submit() {
    if (this.loading) {
      return;
    }
  
    this.loading = true;
    if (this.data.mode === 'add') {
      this.addVendor();
    } else {
      this.editVendor();
    }
  }

  addVendor() {
    this.storeService.addVendor(this.vendorForm.value, this.data.hotelId)
    .pipe(finalize(() => this.loading = false))
    .subscribe(data => {
      this.dialogRef.close({success: true});
    })
  }
  
  editVendor() {
    this.storeService.editVendor(this.vendorForm.value, this.data.hotelId)
    .pipe(finalize(() => this.loading = false))
    .subscribe(data => {
      this.dialogRef.close({success: true});
    })
  }
}
