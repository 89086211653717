import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AllocationMenuComponent } from './allocation-menu.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
  declarations: [AllocationMenuComponent],
  imports: [
    CommonModule, MatIconModule, MatButtonModule, TranslateModule
  ],
  exports: [AllocationMenuComponent]
})
export class AllocationMenuModule { }
