import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Globals } from 'src/app/core/globals.service';

@Component({
  selector: 'app-select-printer',
  templateUrl: './select-printer.component.html',
  styleUrls: ['./select-printer.component.scss']
})
export class SelectPrinterComponent implements OnInit {
  
  selectedPrinter: any;
  printers = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<SelectPrinterComponent>,
    private globals: Globals
  ) { }

  ngOnInit(): void {
    if (this.data?.printers) {
      this.printers = this.data.printers;
    }
  }

  save() {
    if (!this.selectedPrinter) {
      this.globals.snackBarFunc('Please select a printer')
      return;
    }
    localStorage.setItem(this.data.printerType, this.selectedPrinter);
    this.dialogRef.close({success: true})
  }

}
