import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CommunicationService } from 'src/app/core/communication.service';
import { Globals } from 'src/app/core/globals.service';
import { StoreService } from 'src/app/core/store.service';

@Component({
  selector: 'app-consume',
  templateUrl: './consume.component.html',
  styleUrls: ['./consume.component.scss']
})
export class ConsumeComponent implements OnInit {

  department: string;
  category = '';
  subCategory = '';
  subCategories: any[];
  consumeItemForm: FormGroup;
  masterItems: any[];
  selectedItem: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public fb: FormBuilder,
              public dialogRef: MatDialogRef<any>,
              public storeService: StoreService,
              public commS: CommunicationService,
              public globals: Globals) { }

  ngOnInit(): void {
    this.initializeForm();
    if (this.data.departments?.length > 0) {
      this.consumeItemForm.controls.dept_id.setValue(this.data.departments[0].id);
    }
    this.masterItems = JSON.parse(JSON.stringify(this.data.masterItems));
  }

  initializeForm(){
    this.consumeItemForm = this.fb.group({
      item_id: ['', [Validators.required]],
      quantity: ['', [Validators.required]],
      comments: [''],
      dept_id: ['', [Validators.required]]
    });
  }

  selectItem(item) {
    this.consumeItemForm.controls.item_id.setValue(item.id);
    this.selectedItem = item;
  }

  filterSubCategories(category) {
    this.subCategory = '';
    if (category === '') {
      this.subCategories = [];
      return;
    }
    this.subCategories = this.data.categories.filter(data => {
      return data?.name === category;
    });
    if (this.subCategories.length > 0) {
      this.subCategories = this.subCategories[0].sub_categories;
    }
  }

  filterMasterItems() {
    const category = this.category.toLowerCase();
    const subCategory = this.subCategory.toLowerCase();

    this.masterItems = this.data.masterItems.filter(data => {
      return data.category?.toLowerCase().indexOf(category) > -1 && data.sub_category?.toLowerCase().indexOf(subCategory) > -1;
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onSubmit(form) {
    this.closeDialog();
    this.storeService.consumeItem(this.data.hotelId, form.value).subscribe(data => {
        this.commS.emitconsumptionReload();
        this.globals.snackBarFunc(`Item consumed successfully !`);
      }
    );
  }

}
