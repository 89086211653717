import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { StoreService } from 'src/app/core/store.service';
import { OrderComponent } from 'src/app/_shared/common_modals/order/order.component';
import { DialogComponent } from 'src/app/_shared/dialog/dialog.component';
import { AccReceivePaymentComponent } from '../modals/acc-receive-payment/acc-receive-payment.component';
import { VendorComponent } from '../modals/vendor/vendor.component';
import { AccountsService } from 'src/app/core/accounts.service';
import { Globals } from 'src/app/core/globals.service';
@Component({
  selector: 'app-payables',
  templateUrl: './payables.component.html',
  styleUrls: ['./payables.component.scss']
})
export class PayablesComponent implements OnInit {
  @Input() accountsConfig: any = {};

  hotelId: string;
  vendors: any = {};
  currentPage = 0;
  loading = true;
  paginateArray = [];
  $vendors = [];
  masterItems = [];

  constructor(
    private route: ActivatedRoute,
    private storeService: StoreService,
    private dialog: MatDialog,
    private router: Router,
    private accountsService: AccountsService,
    private globals: Globals
  ) { }

  ngOnInit(): void {
    this.hotelId = this.route.snapshot.params.hotelId;
    this.getVendors();
  }

  getVendors() {
    this.storeService.getVendors(this.hotelId)
    .pipe(finalize(() => this.loading = false))
    .subscribe(res => {
      this.vendors = res?.vendors;
      this.$vendors = res?.vendors;
    });
  }

  editvendor(vendor) {
    const ref = this.dialog.open(VendorComponent, {
      width: '600px',
      data: {hotelId: this.hotelId, title: 'Edit Vendor', mode: 'edit', vendor}
    });
    
    ref.afterClosed().subscribe(res => {
      if (res?.success) {
        this.getVendors();
      }
    })
  }

  addVendor() {
    const ref = this.dialog.open(VendorComponent, {
      width: '600px',
      data: {hotelId: this.hotelId, title: 'Add Vendor', mode: 'add'}
    });
    
    ref.afterClosed().subscribe(res => {
      if (res?.success) {
        this.getVendors();
      }
    })
  }

  makePayment(vendor) {
    const ref = this.dialog.open(AccReceivePaymentComponent, {
      width: '500px',
      data: {hotelId: this.hotelId, title: 'Make Payment', vendor}
    });

    ref.afterClosed().subscribe(res => {
      if (res?.success) {
      }
    })
  }

  vendorsFilter(key: string) {
    key = key.toLowerCase();
    this.$vendors = this.vendors.filter(data => {
      return data.name?.toLowerCase().indexOf(key) > -1;
    });
  }


  deleteVendor(vendor) {
    const dialogBody = {title: 'Delete vendor', body: `Are you sure you want to delete ${vendor.name} ?`, confirmBtn: 'Yes', cancelBtn: 'No'};
    const dialogRef = this.dialog.open(DialogComponent, {data: dialogBody});
  
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'true') {
        this.storeService.deleteVendor(vendor.id, this.hotelId).subscribe(res => {
          this.getVendors();
        })
      }
    });
  }

  viewLedger(vendor) {
    this.router.navigate([`accounts/ledger/${this.hotelId}/${vendor.id}`], {queryParams: {vendor: JSON.stringify(vendor)}})
  }

  createPo() {
    if (this.masterItems?.length) {
      this.openOrderComp();
    } else {
      this.storeService.getMasterItems(this.hotelId).subscribe(data => {
        this.masterItems = data?.master_items;
        this.openOrderComp();
      });
    }
  }

  openOrderComp() {
    const heading = 'Create PO';
    const data = {hotelId: this.hotelId, mode: 'new', heading, tab: 'purchaseOrder', masterItems: this.masterItems};
    this.dialog.open(OrderComponent, {
      width: '900px',
      maxWidth: '95%',
      minHeight: '600px',
      data
    });
  }

  refreshOutstanding(vendor) {
    const body: any = {
      updateType: 'vendor',
      vendorId: vendor.id
    }
    this.accountsService.updateOutstanding(this.hotelId, body).subscribe(res => {
      if (res?.success) {
        this.globals.snackBarFunc(`Outstanding updated !`);
        vendor.total_outstanding = res.data?.newOutstanding;
      }
    })
  }
}
