import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { MultiSelectDropdownService } from './multi-select-dropdown.service';

@Component({
  selector: 'app-multi-select-dropdown',
  templateUrl: './multi-select-dropdown.component.html',
  styleUrls: ['./multi-select-dropdown.component.scss']
})
export class MultiSelectDropdownComponent implements OnChanges {
  uniqueId: string;

  @Input() data: any[];
  @Input() name: string;
  @Input() placeholder: string;
  @Input() selectedKeys: string[];

  @Output() onRowClick = new EventEmitter();

  isMultiSelectVisible: boolean = false;

  constructor(
    private multiSelectDropdownService: MultiSelectDropdownService
  ) { }

  ngOnChanges() {
    this.data = JSON.parse(JSON.stringify(this.data));
    this.uniqueId = Math.round(Math.random() * 1000).toString();
    this.multiSelectDropdownService.openedNow$.subscribe(res => {
      if (res.current !== this.uniqueId) {
        this.showMultiSelect(false);
      }
    });

    if (this.selectedKeys && Object.keys(this.selectedKeys)?.length) {
      this.setSelectedRow();
    }
  }

  setSelectedRow() {
    this.data?.forEach(e => {
      if (this.selectedKeys[e.id]) {
        e.checked = true;
      }
    });
  }

  selectRow(checked, item) {
    this.onRowClick.emit({checked, item});
  }

  showMultiSelect(value) {
    if (value) {
      this.multiSelectDropdownService.openedNow = this.uniqueId;
    }
    this.isMultiSelectVisible = value;
  }

}
