import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { NgSelectModule } from '@ng-select/ng-select';

import { HomeComponent } from './home/home.component';
import { MatDividerModule } from '@angular/material/divider';
import { SettingsComponent } from './settings/settings.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { PurchaseOrderComponent } from './purchase-order/purchase-order.component';
import { IndentComponent } from './indent/indent.component';
import { IndentItemsComponent } from './indent/indent-items/indent-items.component';
import { DeptStockComponent } from './dept-stock/dept-stock.component';
import { ConsumptionComponent } from './consumption/consumption.component';
import { ConsumeComponent } from './consumption/consume/consume.component';
import { StoreReportsComponent } from './store-reports/store-reports.component';
import { SendPoEmailComponent } from './modals/send-po-email/send-po-email.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ReturnStockItemComponent } from './modals/return-stock-item/return-stock-item.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { CopyInvoiceComponent } from './modals/copy-invoice/copy-invoice.component';

@NgModule({
  declarations: [
    HomeComponent,
    SettingsComponent,
    PurchaseOrderComponent,
    IndentComponent,
    IndentItemsComponent,
    DeptStockComponent,
    ConsumptionComponent,
    ConsumeComponent,
    StoreReportsComponent,
    SendPoEmailComponent,
    ReturnStockItemComponent,
    CopyInvoiceComponent,
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatDividerModule,
    FormsModule,
    ReactiveFormsModule,
    MatExpansionModule,
    NgSelectModule,
    MatDialogModule,
    MatTooltipModule,
    TranslateModule
  ]
})
export class StoreModule { }
