import { Component, Inject, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReservationService } from 'src/app/core/reservation.service';

@Component({
  selector: 'app-additional-guest',
  templateUrl: './additional-guest.component.html',
  styleUrls: ['./additional-guest.component.css'],
})

export class AdditionalGuestComponent implements OnInit {
  addressLine1: any;
  city: any;
  state: any;
  zip: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private guestService: ReservationService
  ) {}

  fileselected = [];
  hotelId: any;
  guestName = null;
  country = null;
  idproof = null;
  idDetails = null;
  identityList = [];
  guestDetails = [];
  gender = null;
  email = null;
  phone = null;

  ngOnInit(): void {
    this.identityList = this.data.idList;
    this.hotelId = this.data.hotelId;
    this.clearForm();
    this.getGuestDetails();
  }

  deleteguest(index) {
    var guestIdToBeremoved = null;
    if (this.guestDetails[index]['guestId']){
      guestIdToBeremoved = this.guestDetails[index]['guestId']
    }
    this.guestDetails.splice(index, 1);
    this.updateGuestDetails(this.guestDetails, guestIdToBeremoved);
  }

  handleInput(event) {
    var filelength = Object.keys(event.files).length;

    for (var i = 0; i < filelength; i++) {
      let randomnumber = crypto.getRandomValues(new Uint32Array(1));
      var filename = randomnumber +'-'+ event.files[i].name;
      this.guestService
        .uploadAdditionalGuestFiles(
          this.data.hotelId,
          event.files[i],
          this.data.guestId,
          filename
        )
        .subscribe(
          (success) => {
            this.fileselected.push(success);
          },
          (err) => {
            console.log(err);
          }
        );
    }
  }

  removeImage(url) {
    this.fileselected.splice(
      this.fileselected.findIndex(function (i) {
        return i === url;
      }),
      1
    );
  }

  getGuestDetails() {
    this.guestService
      .fetchAdditionalGuest(this.data.guestId, this.hotelId, this.data.allocId)
      .subscribe((details) => {
        this.guestDetails = details;
      });
  }

  updateGuestDetails(details, guestIdToBeRemoved) {
    this.guestService
      .updateAdditionalGuest(details, this.hotelId, this.data.guestId, guestIdToBeRemoved, this.data.allocId)
      .subscribe((success) => {
        this.ngOnInit();
      });
  }

  submit(form: NgForm): void {
    const details = {
      guestName: this.guestName,
      email: this.email,
      phone: this.phone,
      country: this.country,
      idDetails: this.idDetails,
      idproof: this.idproof,
      photoIds: this.fileselected,
      guestId: this.data.guestId,
      gender: this.gender,
      addressLine1: this.addressLine1,
      city: this.city,
      state: this.state,
      zip: this.zip,
      allocId: this.data.allocId
    };
    
    this.guestService
      .addAdditionalGuest(details, this.hotelId)
      .subscribe((success) => {
        this.ngOnInit();
      });
  }

  clearForm(){
    this.guestName = null;
    this.country = null;
    this.idproof = null;
    this.idDetails = null;
    this.fileselected = [];
    this.gender = null;
    this.email = null;
    this.phone = null;
    this.addressLine1 = null;
    this.city = null;
    this.state = null;
    this.zip = null;
  }
}
