import { animate, animateChild, animation, query, stagger, state, style, transition, trigger, useAnimation } from '@angular/animations';

/* ==========================
# Animations
============================*/

const fadeInAnimation = animation([
    style({opacity: 0}),
    animate('{{duration}} {{easing}}')
], {
    params: {
        duration: '1s',
        easing: 'ease-in'
    }
});


/* ==========================
# Triggers
============================*/

export const expandCollapse = trigger('expandCollapse', [
    state('collapsed', style({
        height: 0,
        overflow: 'hidden',
        opacity: 0
    })),

    transition('collapsed <=> expanded', [
        animate('300ms ease-out')
    ])
]);

export const slide = trigger('slide', [
    transition(':leave', [
        animate('150ms', style({
            backgroundColor: 'green',
            color: '#fff',
        })),
        animate('300ms ease-in', style({
            transform: 'translateX(-100%)',
            opacity: 0
        }))
    ])
]);

export const rotate = trigger('rotate', [
    state('rotated', style ({
        transform: 'rotate(180deg)'
    })),

    transition('rotated <=> initial', [
        animate('200ms ease-out')
    ])
]);

export const fadeIn = trigger('fadeIn', [
    transition(':enter', 
        useAnimation(fadeInAnimation, {
            params: {
                duration: '500ms'
            }
        })
    )
]);


export const staggerEffect = trigger('staggerEffect', [
    transition('* => *', [ 
      query(':enter', [
          style({ opacity: 0 }),
          stagger(15, [
              useAnimation(fadeInAnimation, {params: {duration: '300ms'}})
            ])
        ],
        { optional: true }
      )
    ])
]);
