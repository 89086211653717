import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StoreCommonService {

  constructor() { }

  roundExp(n) {
    return Math.round((n + Number.EPSILON) * 100) / 100;
  }

  calculateTotal(operand, item, total) {
    if (operand === '+') {
      total.quantity += item.quantity;
      total.pre_tax_amount = this.roundExp(total.pre_tax_amount += item.pre_tax_amount);
      total.tax.total = this.roundExp(total.tax.total += item.tax.total);
      total.total_amount = this.roundExp(total.total_amount += item.total_amount);
    }
    else {
      total.quantity -= item.quantity;
      total.pre_tax_amount = this.roundExp(total.pre_tax_amount -= item.pre_tax_amount);
      total.tax.total = this.roundExp(total.tax.total -= item.tax.total);
      total.total_amount = this.roundExp(total.total_amount -= item.total_amount);
    }

    for (const newTax of item.tax.break_up) {
      if (total.tax.break_up.length === 0) {
        total.tax.break_up.push(newTax);
        continue;
      }
      let existingTax = false;
      for (const tax of total.tax.break_up) {
        if (newTax.name === tax.name) {
          existingTax = true;
          if (operand === '+') {
            tax.amount = this.roundExp(tax.amount += newTax.amount);
          } else {
            tax.amount = this.roundExp(tax.amount -= newTax.amount);
          }
          break;
        }
      }
      if (!existingTax) {
        total.tax.break_up.push(newTax);
      }
    }
    return total;
  }

  onQuantityChange(selectedItem, lastTaxDetails) {
    const qty = selectedItem.quantity;
    selectedItem.pre_tax_amount = this.roundExp(lastTaxDetails.pre_tax_amount * qty);
    selectedItem.tax.total = this.roundExp(lastTaxDetails.tax_details.total * qty);
    selectedItem.total_amount = this.roundExp(lastTaxDetails.total_amount * qty);

    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < selectedItem.tax.break_up.length; i++) {
      selectedItem.tax.break_up[i].amount = this.roundExp(lastTaxDetails.tax_details.break_up[i]?.amount * qty);
    }
    return selectedItem;
  }

  onItemQuantityChange(item, newQty) {
    item.pre_tax_amount = this.roundExp((item.pre_tax_amount * newQty) / item.quantity);
    item.tax_total = this.roundExp((item.tax.total * newQty) / item.quantity);
    item.total_amount = this.roundExp((item.total_amount * newQty) / item.quantity);
    const newBreakUp = [];
    for (const tax of item.tax.break_up) {
      const newAmt = this.roundExp((tax.amount * newQty) / item.quantity);
      newBreakUp.push({name: tax.name, amount: newAmt});
    }
    item.tax.break_up = newBreakUp;
    item.quantity = parseFloat(newQty);
    return item;
  }

  changeTaxFeilds(selectedItem, lastTaxDetails, rateChange = false) {
    const body: any = {};
    body.tax_details = {total: 0, break_up: []};
    body.pre_tax_amount = lastTaxDetails.pre_tax_amount * selectedItem.quantity;
    body.total_amount = lastTaxDetails.total_amount * selectedItem.quantity;
    body.tax_details.total = lastTaxDetails.tax_details.total * selectedItem.quantity;
    for (const tax of lastTaxDetails.tax_details.break_up) {
      const brAmount = rateChange ? tax.amount * selectedItem.quantity : tax.amount / selectedItem.quantity;
      body.tax_details.break_up.push({name: tax.name, amount: brAmount});
    }
    return body;
  }

  updateSelectedItem(selectedItem, data) {
    selectedItem.tax = data.tax_details;
    selectedItem.pre_tax_amount = data.pre_tax_amount;
    selectedItem.total_amount = data.total_amount;
    return selectedItem;
  }

  isQuantityValid(qty: number): boolean {
    if (!(qty && qty >= 0.01 && qty <= 99999999.99)) {
      return false;
    }
    const qtyArr = qty.toString().split('.');
    if (qtyArr.length > 1 && qtyArr[1].length > 2) {
      return false;
    }
    return true;
  }

}
