<div class="container">
    <div class="row">
        <div class="col-sm-2">
            <label for="">{{'stores.start_date' | translate}} :</label>
            <input type="date" class="form-control form-control-sm" [(ngModel)]="startDate"
            (change)="getItemsConsumed()" max="{{endDate}}">
        </div>
        <div class="col-sm-2">
            <label for="">{{'stores.end_date' | translate}} :</label>
            <input type="date" class="form-control form-control-sm" [(ngModel)]="endDate"
            (change)="getItemsConsumed()" min="{{startDate}}">
        </div>
        <div class="col-sm-2">
            <label for="Department">{{'stores.department' | translate}}</label>
            <input list="departmentList" class="form-control form-control-sm" (click)="department=''"
                (change)="filterBy()" [(ngModel)]="department">
            <datalist id="departmentList">
                <option value="All">
                <option *ngFor="let dept of userDepartments" [value]="dept.name">
            </datalist>

        </div>

        <div class="offset-sm-2 col-sm-4">
            <label for="" class="invisible">{{'stores.create' | translate}}</label>
            <div class="d-flex justify-content-end">
                <button mat-stroked-button 
                    class="btn-sm pr-border mr-2" (click)="consumeItem()">{{'stores.consume' | translate}}
                </button>
                <input type="search" class="form-control form-control-sm" placeholder="{{'stores.search' | translate}} . . ."
                (input)="filterItemsConsumed($event.target.value)">
            </div>
        </div>
    </div>

    <table class="mt-4 table table-striped">
        <thead class="thead-dark">
            <tr>
                <th>{{'stores.department' | translate}}</th>
                <th>{{'stores.date' | translate}}</th>
                <th>{{'stores.item_name' | translate}}</th>
                <th>{{'stores.quantity' | translate}}</th>
                <th class="text-right">{{'stores.value' | translate}}</th>
                <th class="text-right">{{'stores.comments' | translate}}</th>
            </tr>
        </thead>

        <tbody>
            <tr *ngFor="let item of $itemsConsumed">
                <td>{{item.department}}</td>
                <td>{{item.date | date: 'dd MMM hh:mm a'}}</td>
                <td>{{item.name}}</td>
                <td>{{item.quantity}}  {{item.unit}}</td>
                <td class="text-right">{{item.pre_tax | number: '1.2-2'}}</td>
                <td class="text-right">{{item.comments}}</td>
            </tr>
        </tbody>
    </table>
</div>