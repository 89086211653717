import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaintenanceIndexComponent } from './maintenance-index/maintenance-index.component';
import { MatDividerModule } from '@angular/material/divider';
import { CreateIssueComponent } from './create-issue/create-issue.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { MaintenanceHomeComponent } from './maintenance-home/maintenance-home.component';
import { LostAndFoundComponent } from './lost-and-found/lost-and-found.component';
import { LaundryComponent } from './laundry/laundry.component';
import { HousekeepingComponent } from './housekeeping/housekeeping.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { RouterModule } from '@angular/router';
import { MatSortModule } from '@angular/material/sort';
import { CreateLaundryComponent } from './laundry/create-laundry/create-laundry.component';
import { LostAndFoundEntryComponent } from './lost-and-found/lost-and-found-entry/lost-and-found-entry.component';

@NgModule({
  declarations: [
    MaintenanceIndexComponent,
    CreateIssueComponent,
    MaintenanceHomeComponent,
    LostAndFoundComponent,
    LaundryComponent,
    HousekeepingComponent,
    CreateLaundryComponent,
    LostAndFoundEntryComponent,
  ],
  imports: [
    CommonModule,
    MatDividerModule,
    ReactiveFormsModule,
    FormsModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    NgSelectModule,
    TranslateModule,
    MatTableModule,
    MatPaginatorModule,
    RouterModule,
    MatSortModule
  ],
})
export class MaintenanceModule {}
