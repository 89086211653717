import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AccountsService } from 'src/app/core/accounts.service';
import { Globals } from 'src/app/core/globals.service';

@Component({
  selector: 'app-accounts-index',
  templateUrl: './accounts-index.component.html',
  styleUrls: ['./accounts-index.component.scss']
})
export class AccountsIndexComponent implements OnInit {

  tabItems = [
    {key: 'receivables', name: 'Receivables'},
    {key: 'payables', name: 'Payables'},
    {key: 'taxes', name: 'Taxes'},
    {key: 'reconciliation', name: 'Reconciliation'},
  ];

  accountsConfig: any = {loading: true};
  currentTab = this.tabItems[0].key;
  hotelId: string;
  
  constructor(
    private accountsService: AccountsService,
    private route: ActivatedRoute,
    private globals: Globals
  ) { }
  
  ngOnInit(): void {
    localStorage.setItem('accountsCurrentTab', '0');
    if (this.route.snapshot.queryParams?.tabName === 'gstr1') {
      this.currentTab = 'gstr1';
    }
    else if (this.route.snapshot.queryParams?.tabIndex) {
      this.changeCurrentTab(parseInt(this.route.snapshot.queryParams?.tabIndex))
    }
    this.route.params.subscribe(params => {
      this.hotelId = params.hotelId;
      this.globals.changeHotelID(this.hotelId);
    });

    this.getAccountsConfig();

    this.accountsService._currentTab.subscribe(e => this.currentTab = e)
  }
  
  getAccountsConfig() {
    this.accountsService.getAccountsConfig(this.hotelId).subscribe(res => {
      this.accountsConfig = res;
    });
  }

  changeCurrentTab(index: number) {
    this.currentTab = this.tabItems[index].key;
    localStorage.setItem('accountsCurrentTab', index.toString());
  }

}
