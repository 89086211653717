<div class="dialog-box-popup">
  <div class="row mb-1">
    <h1>
      {{ "stayview.reservation.edit_booking.edit_booking_id" | translate }}
    </h1>
    <hr />
  </div>

  <div class="popup-form">
    <div class="form-row">
      <div class="form-group col-md-4 mb-3">
        <label for="oldId">{{
          "stayview.reservation.edit_booking.current_booking_id" | translate
        }}</label>
        <input
          type="text"
          id="oldId"
          class="form-control form-control-sm"
          [(ngModel)]="currentBookingId"
          name="oldId"
          [disabled]="true"
        />
      </div>
      <div class="form-group col-md-4 mb-3">
        <label for="newId">{{
          "stayview.reservation.edit_booking.new_booking_id" | translate
        }}</label>
        <input
          type="text"
          id="newId"
          class="form-control form-control-sm"
          [(ngModel)]="newBookingId"
          maxlength="20"
          name="newId"
        />
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-4 mb-3">
        <label for="oldChannel">{{
          "stayview.reservation.edit_booking.current_channel" | translate
        }}</label>
        <input
          type="text"
          id="oldChannel"
          class="form-control form-control-sm"
          [(ngModel)]="currentChannel"
          name="oldChannel"
          [disabled]="true"
        />
      </div>
      <div class="form-group col-md-4 mb-3">
        <label for="newChannel">{{
          "stayview.reservation.edit_booking.new_channel" | translate
        }}</label>
        <input
          type="text"
          id="newChannel"
          class="form-control form-control-sm"
          [(ngModel)]="newChannel"
          maxlength="20"
          name="newChannel"
        />
      </div>
    </div>
    <span>{{ "stayview.reservation.edit_booking.message" | translate }}</span>
    <div class="row action-buttons mt-4">
      <button type="button" mat-raised-button (click)="closeDialog()">
        {{ "stayview.reservation.edit_booking.cancel" | translate }}
      </button>
      <button
        *ngIf="submitting == false"
        type="submit"
        mat-raised-button
        (click)="submit(newBookingId)"
        class="confirm-button"
      >
        {{ "stayview.reservation.edit_booking.submit" | translate }}
      </button>
      <button
        *ngIf="submitting == true"
        type="submit"
        mat-raised-button
        disabled
        class="confirm-button"
      >
        {{ "stayview.reservation.edit_booking.submitting" | translate }}
      </button>
    </div>
  </div>
</div>
