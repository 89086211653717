import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReservationService } from 'src/app/core/reservation.service';
import { DialogComponent } from 'src/app/_shared/dialog/dialog.component';

@Component({
  selector: 'app-send-voucher-email',
  templateUrl: './send-voucher-email.component.html',
  styleUrls: ['./send-voucher-email.component.css']
})
export class SendVoucherEmailComponent implements OnInit {
  voucherUrl: any;
  bookingId: any;
  roomVoucher = true;
  bookingVoucher = false;
  groups = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private guestService: ReservationService,public dialog: MatDialog, private dialogRef: MatDialogRef<SendVoucherEmailComponent>) { }
  emailIds = '';
  ngOnInit(): void {
    this.emailIds = this.data.emailId;
    this.bookingId = this.data.bookingId;
    this.groups = this.data.groups;
  }

  
  submitting = false;
  viewVoucher(value){
    if(value == 'booking'){
      this.guestService.createAndFetchVoucher(this.data.hotelId, this.data.bookingId, this.data.source).subscribe((voucherdata) => {
        if (Object.keys(voucherdata).length == 0) {
          const WarningDialog = {
            title: 'ERROR !',
            body: `Voucher does not exist`,
            cancelBtn: 'Dismiss',
          };
          this.dialog.open(DialogComponent, { data: WarningDialog });
        } else if ('error' in voucherdata) {
          const WarningDialog = {
            title: 'ERROR !',
            body: voucherdata.error,
            cancelBtn: 'Dismiss',
          };
          this.dialog.open(DialogComponent, { data: WarningDialog });
        } else {
          window.open(voucherdata.url);
        }
      });
    }else{
      this.guestService.fetchallocVoucher(this.data.roomAllocationId, this.data.hotelId, this.data.user, true).subscribe(data=>{
        this.voucherUrl = data.URL;
        if (this.voucherUrl.url){
          window.open(this.voucherUrl.url);
        }
      });
    }
    
  };

  closeDialog(){
    this.dialogRef.close();
  }

  submit(emailIds){
    const dialogBody = {title: 'Confirm', body: `Are you sure you want to send Email?`,
    confirmBtn: 'Yes', cancelBtn: 'No', width: '400px', height: '150px'};
    const dialogRef = this.dialog.open(DialogComponent, {data: dialogBody});
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'true'){
        this.submitting = true;
        if(<HTMLInputElement>document.getElementById('bookingVoucher') == null || (<HTMLInputElement>document.getElementById('bookingVoucher'))
          .checked){
          this.guestService.createAndFetchVoucher(this.data.hotelId, this.data.bookingId, this.data.source, false, emailIds).subscribe((voucherdata) => {
            this.submitting = false;
            var WarningDialog = {}
            if (Object.keys(voucherdata).length == 0) {
              WarningDialog = {
                title: 'ERROR !',
                body: `Voucher does not exist`,
                cancelBtn: 'Dismiss',
              };
            } else if ('error' in voucherdata) {
              WarningDialog = {
                title: 'ERROR !',
                body: voucherdata.error,
                cancelBtn: 'Dismiss',
              };
            } else {
              WarningDialog = {title: 'Success !', body:`Mail has been sent.`, cancelBtn: 'Dismiss'};
            }
            const dialogRef = this.dialog.open(DialogComponent, {data: WarningDialog});
              dialogRef.disableClose = true;
              dialogRef.afterClosed().subscribe((result) => {
                if (result === 'false') {
                  this.dialog.closeAll();
                }
              });
          });
        }else{
            this.guestService.sendallocVoucherToEmail(this.data.roomAllocationId, this.data.hotelId, emailIds, this.data.user).subscribe(success=>{
              this.submitting = false;
              const WarningDialog = {title: 'Success !', body:`Mail has been sent.`, cancelBtn: 'Dismiss'};
              const dialogRef = this.dialog.open(DialogComponent, {data: WarningDialog});
              dialogRef.disableClose = true;
              dialogRef.afterClosed().subscribe((result) => {
                if (result === 'false') {
                  this.dialog.closeAll();
                }
              });
            });
          }
      }
    });
  }
}
