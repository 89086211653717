import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LeadsService {

  private baseUrl = `${environment.apiUrl}/maintenance-issue`;

  constructor(private http: HttpClient) { }

  getLeadConfig(hotelId): Observable <any> {
    return this.http.get(`${environment.apiUrl}/hotels/${hotelId}/products/leads`);
  }

  createLeads(hotelId, data): Observable<any> {
    return this.http.post(`${environment.apiUrl}/pms/${hotelId}/leads`, data);
  }

  updateLead(hotelId, data, leadId): Observable<any> {
    return this.http.put(`${environment.apiUrl}/pms/${hotelId}/leads/${leadId}`, data);
  }

  getLeads(hotelId, startDate, endDate): Observable<any> {
    const httpParams = new HttpParams({fromObject: {startDate, endDate}});
    return this.http.get(`${environment.apiUrl}/pms/${hotelId}/leads`, {params: httpParams});
  }

  getLeadById(hotelId: string, leadId: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}/pms/${hotelId}/leads/${leadId}`);
  }

  changeStatus(hotelId: string, leadId: string, status: string): Observable<any> {
    return this.http.put(`${environment.apiUrl}/pms/${hotelId}/leads/${leadId}/change-status`, {status});
  }
}
