<nav class="navbar" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
      Select Company
    </div>
    <div class="navbar-end">
      <div class="navbar-item">
        <div class="search">
          <form [formGroup]="search">
            <span>Search Name</span>
            <input
              id="search-bar"
              type="text"
              placeholder="{{ 'guest.search' | translate }}. . ."
              formControlName="searchInput"
              (keyup)="showResultBasedOnSearch($event)"
            />
          </form>
          <button id="newbutton" (click)="clear()">Clear</button>
          <button id="newbutton" (click)="back()">Back</button>
        </div>
      </div>
    </div>
</nav>
<hr>
<div class="mat-elevation-z8">

    <table mat-table [dataSource]="dataSource">

        <!-- Select Column -->
        <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
        </th>
        <td mat-cell *matCellDef="let row">
            <button id="newbutton" (click)="selectedRow(row)">
            Select
            </button>
        </td>
        </ng-container>
    
        <!-- Name Column -->
        <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Name </th>
        <td mat-cell *matCellDef="let element"> {{element.name}}  <mat-icon (click)="editCompany(element)">info</mat-icon></td>
        </ng-container>
    
        <!-- Email Column -->
        <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef> Email </th>
        <td mat-cell *matCellDef="let element"> {{element.email}} </td>
        </ng-container>
    
        <!-- GST Column -->
        <ng-container matColumnDef="tax_num">
        <th mat-header-cell *matHeaderCellDef> GST </th>
        <td mat-cell *matCellDef="let element"> {{element.tax_num}} </td>
        </ng-container>
    
        <!-- Contract Rates Column -->
        <ng-container matColumnDef="contracted_rate">
        <th mat-header-cell *matHeaderCellDef> Contracted rates </th>
        <td mat-cell *matCellDef="let element">
          <ng-container *ngFor="let rate of element.contracted_rate">
            <p *ngIf="rate.mealplan && rate.rate">{{rate.room_id}} - {{rate.mealplan}} - {{rate.occupancy}} - {{rate.rate}}</p>
            <p *ngIf="!rate.mealplan && rate.rate">{{rate.room_id}} - {{rate.rate}}</p>
          </ng-container>
        </td>
        </ng-container>
    
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"
            (click)="selection.toggle(row)">
        </tr>
    </table>
    
</div>