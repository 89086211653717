import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogComponent } from 'src/app/_shared/dialog/dialog.component';
import { UserService } from 'src/app/core/user.service';

@Component({
  selector: 'app-transfer-folio',
  templateUrl: './transfer-folio.component.html',
  styleUrls: ['./transfer-folio.component.scss']
})
export class TransferFolioComponent implements OnInit {

  transferToGuest = '';
  submitting = false;
  guestList: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  private userService: UserService,
  public dialog: MatDialog,
  private dialogRef: MatDialogRef<TransferFolioComponent>) { }

  ngOnInit(): void {
    this.guestList = this.data.checkedInAllocDetails;
  }

  closeDialog() {
    this.dialogRef.close();
  }

  submit(transfertoGuest){
    const dialogBody =
      {title: 'Transfer Bill to Room No!', body: 'Are you sure you want to transfer Bill to selected Room No?',
        confirmBtn: 'Yes', cancelBtn: 'Cancel'
      };
    const dialog = this.dialog.open(DialogComponent, {data: dialogBody, height:'170px'});
    var dataList = [];
    dialog.afterClosed().subscribe(async result => {
      if (result === 'true'){
        const transferPromises = [];
        for (var allocId in this.data.currentAllocId){
          if(this.data.currentAllocId[allocId]['room_allocation_id'] !== transfertoGuest){
            dataList.push(this.data.currentAllocId[allocId]['room_allocation_id'])
          }
        }
        this.userService.transferGroupInvoiceBulkToRoom(dataList, transfertoGuest, this.data.hotelId).toPromise()
        // Wait for all transfer requests to complete
        await Promise.all(transferPromises);
        window.location.reload();
      }
    })
  }
    
}
