import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor(private http: HttpClient) { }

  getReports(hotelId, start, end , chainId, type, paymentType, mealPlan, user, status, option): Observable<any> {
    let params = new HttpParams();
    params = params.append('start', start);
    params = params.append('type', type);
    params = params.append('end', end);
    params = params.append('chainId', chainId);
    params = params.append('paymentType', paymentType);
    params = params.append('mealPlan', mealPlan);
    params = params.append('user', user);
    params = params.append('status', status);
    params = params.append('option', option);

    return this.http.get(`${environment.apiUrl}/reports/${hotelId}`, {params: params} );
  };

  getChains(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/chains`);
  };

  getPaymentTypes(hotelId, chainId, reportName, start, end): Observable<any> {
    let params = new HttpParams();
    params = params.append('start', start);
    params = params.append('end', end);
    params = params.append('reportName', reportName);
    return this.http.get(`${environment.apiUrl}/pms/${hotelId}/payment-types/${chainId}`,  {params: params}  );
  };

  feedbackCall(hotelId, mobile, bookingId): Observable<any> {
    let params = new HttpParams();
    params = params.append('CallFrom', mobile);
    params = params.append('bookingId', bookingId);
    return this.http.get(`${environment.apiUrl}/whatsapp/checkout/${hotelId}`, {params: params} );
  };

  refreshNightAuditData(hotelId, start): Observable<any> {
    let params = new HttpParams();
    params = params.append('date', start);
    return this.http.get(`${environment.apiUrl}/crons/pms/aggregate/${hotelId}`, {params: params} );
  };


};
