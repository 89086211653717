import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogComponent } from '../../dialog/dialog.component';
import { PaymentsService } from 'src/app/core/payments.service';

@Component({
  selector: 'app-send-payment-link',
  templateUrl: './send-payment-link.component.html',
  styleUrls: ['./send-payment-link.component.scss']
})
export class SendPaymentLinkComponent implements OnInit {

  submitting = false;
  emails = '';
  error = {index: 0, error: false};
  phoneNos = '';
  amountToPay = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private paymentService: PaymentsService,
    private dialog: MatDialog,
    private matDialogRef: MatDialogRef<SendPaymentLinkComponent>
  ) { }

  ngOnInit(): void {
    this.emails = this.data.email;
    this.phoneNos = this.data.phone?.replace(/[^0-9,+]/g, '');
    this.amountToPay = this.data.balance;
  }

  submit() {
    if (this.error.error) {
      return;
    }
    const emails = this.emails?.split(',').map(e => e.trim());
    const phoneNums = this.phoneNos?.split(',').map(e => e.trim());
    const body = {
      emails, phoneNums, 
      bookingId: this.data.bookingId, 
      amount: this.amountToPay,
      roomAllocationId: this.data.roomAllocationId
    };
  
    this.paymentService.sendPaymentLink(body, this.data.hotelId).subscribe(res => {
      if (res?.success) {
        const dbody = {title: 'Success !', body: `Payment Link Sent ${res.link}`, cancelBtn: 'Dismiss', width: '500px', height: '158px'};
        this.dialog.open(DialogComponent, 
          {data: dbody});
        this.matDialogRef.close();
      }
    })
  }

  validateEmail() {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const emails = this.emails.split(',');
    let i=-1;
    for (const email of emails) {
      i++;
      if (!regex.test(email.trim())) {
        this.error.index = i;
        this.error.error = true;
        return;
      }
    }
    this.error = {index: 0, error: false};
  }

  numberOnly(event: any) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (
      // Only allow one decimal point
      (charCode === 46 && (event.target as HTMLInputElement).value.includes('.')) || 
      // Disallow non-numeric characters
      (charCode !== 46 && charCode > 31 && (charCode < 48 || charCode > 57))
    ) {
      event.preventDefault();
    }
  }

  phoneNumValidation(event: any) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (
      charCode === 44 ||
      charCode === 43 ||
      (charCode >= 48 && charCode <= 57)
    ) {
      return;
    } else {
      event.preventDefault();
    }
  }

}
