import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HRModuleSevice } from 'src/app/core/hr-module.service';
import { DialogComponent } from 'src/app/_shared/dialog/dialog.component';
import { saveAs } from 'file-saver-es';

@Component({
  selector: 'app-attendance',
  templateUrl: './attendance.component.html',
  styleUrls: ['./attendance.component.scss']
})
export class AttendanceComponent implements OnInit {
  @Input() hotelId: string;
  @Input() user: string;
  @Input() showAttendance: boolean;
  @Input() allowSalaryProcessing: boolean;
  currentMonth: string;
  employeeStatus = 'Active';
  allDates: any[];
  attendance = 'P'
  attendanceTypes = [];
  attendanceData = [];
  numDays: number;
  selectedDepartment = '';
  employeesAttendance: any;
  allEmployeeAttendance: any;
  level = '';
  month = this.datePipe.transform(new Date(), 'yyyy-MM');
  groupId = '';

  constructor(private datePipe: DatePipe, private hrService: HRModuleSevice, public matdialog:MatDialog) { }

  ngOnInit(): void {
    // (<HTMLInputElement>document.getElementById('month')).value = this.datePipe.transform(new Date(), 'yyyy-MM');
    this.allDates = this.getAllDaysInMonth(new Date().getFullYear(), new Date().getMonth())
    this.fetchAttendance(new Date().getMonth()+1, new Date().getFullYear());
    this.hrService.getProductConfig(this.hotelId).subscribe(success=>{
      this.level = success.conf.HRConfigLevel;
      this.groupId = success.conf.chainId;
    });
  }

  selectedMonth(){
    var date = new Date((<HTMLInputElement>document.getElementById('month')).value)
    this.allDates = this.getAllDaysInMonth(date.getFullYear(), date.getMonth());
    this.fetchAttendance(date.getMonth()+1, date.getFullYear(), this.employeeStatus);
  }

  getAllDaysInMonth(year, month) {
    const date = new Date(year, month, 1);
  
    const dates = [];
  
    while (date.getMonth() === month) {
      dates.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }
    this.numDays = dates.length;
    
    return dates;
  }

  selectedDept(){
    for (var item in this.attendanceData){
      if(this.attendanceData[item]['department'] == this.selectedDepartment){
        this.employeesAttendance = this.attendanceData[item]['employees']
      }else if(this.selectedDepartment == "All"){
        this.employeesAttendance = this.allEmployeeAttendance;
      }
    }
  }

  fetchAttendance(month, year, employeeStatus = 'Active'){
    this.hrService.fetchAttendance(this.hotelId, month, year, employeeStatus).subscribe(success=>{
      this.attendanceTypes = success.attendanceOptions;
      this.attendanceData = success.attendanceDetails;
      this.selectedDepartment = "All";
      this.employeesAttendance = success.allEmployeeAttendance;
      this.allEmployeeAttendance = success.allEmployeeAttendance;
    })
  }

  downloadSalary(group = null){
    var date = new Date(this.month);
    const dialogBody = {title: 'Process Salary', body: 'Are you sure you want to Process '+this.month+' Salary ?',
        confirmBtn: 'Submit', cancelBtn: 'Cancel'
        };
    const dialogRef = this.matdialog.open(DialogComponent, {data: dialogBody});
    dialogRef.afterClosed().subscribe(result => {
      if(result === 'true'){
        this.hrService.downloadSalary(this.hotelId, date.getMonth()+1, date.getFullYear(), this.user ,group, 'true').subscribe(
          data => {
            var WarningDialog = {title: 'Done !', body: `You Have successfully processed Salary for `+ this.month, cancelBtn: 'Dismiss'};
            const dialogRef = this.matdialog.open(DialogComponent, {data: WarningDialog});
            dialogRef.disableClose = true;
            let blob:any = new Blob([data], { type: 'text/xlsx; charset=utf-8' });
            let fileName = this.month+'_Employees_Salary.xlsx'
            saveAs(blob, fileName);
          });
        }
    });
    
  }

  downloadAttendance(group = null){
    var date = new Date(this.month);
    this.hrService.downloadAttendance(this.hotelId, date.getMonth()+1, date.getFullYear(), this.selectedDepartment, group).subscribe(
      data => {
        let blob:any = new Blob([data], { type: 'text/xlsx; charset=utf-8' });
        let fileName = this.hotelId+'_attendance.xlsx'
        saveAs(blob, fileName);
      });
  }

  updateAttendance(attendance){
    const dialogBody = {title: 'Update Attendance', body: 'Are you sure you want to Update ?',
        confirmBtn: 'Submit', cancelBtn: 'Cancel'
        };
    const dialogRef = this.matdialog.open(DialogComponent, {data: dialogBody});
    dialogRef.afterClosed().subscribe(result => {
      if(result === 'true'){
        this.hrService.updateAttendance(this.hotelId, attendance, this.user).subscribe(success=>{
          var WarningDialog = {title: 'Done !', body: `You Have successfully modified the data`, cancelBtn: 'Dismiss'};
          const dialogRef = this.matdialog.open(DialogComponent, {data: WarningDialog});
          dialogRef.disableClose = true;
          dialogRef.afterClosed().subscribe(result => {
            if (result === 'false'){
              this.matdialog.closeAll();
              window.location.reload()
            }
          });
        },err=>{
          const WarningDialog = {title: 'ERROR !', body: 'Something went wrong!!' , cancelBtn: 'Dismiss'};
          const dialogRef = this.matdialog.open(DialogComponent, {data: WarningDialog});
          dialogRef.disableClose = true;
          dialogRef.afterClosed().subscribe(result => {
            if (result === 'false'){
              this.matdialog.closeAll();
              window.location.reload()
            }
          });
        })
      }
    })
  }
}
