import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommunicationService } from 'src/app/core/communication.service';
import { CompaniesService } from 'src/app/core/companies.service';
import { Globals } from 'src/app/core/globals.service';

@Component({
  selector: 'app-add-edit-form',
  templateUrl: './add-edit-form.component.html',
  styleUrls: ['./add-edit-form.component.scss']
})
export class AddEditFormComponent implements OnInit {
  companyForm: FormGroup;
  currentUser: string;

  get contracted_rate() { return this.companyForm.get('contracted_rate'); }

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public fb: FormBuilder, private companiesServices: CompaniesService,
              private dialogRef: MatDialogRef<AddEditFormComponent>, private commS: CommunicationService,
              private globals: Globals) { }

  ngOnInit(): void {
    this.globals.currentUser.subscribe(user => this.currentUser = user);
    if (this.data.mode === 'add') {
      this.initializeForm();
      this.getHotelRooms();
    } else if (this.data.mode === 'edit') {
      this.editCompany();
    }
  }

  getHotelRooms(rate = null) {
    this.companiesServices.getHotelRateplans(this.data.hotelId).subscribe(
      data => {
        data.forEach(ele => {
          if (rate!=null && rate.rate!=null){
            rate.forEach(item => {
              if(item.room_id == ele.room_id){
              this.addContractedRate(ele.room_id, ele.occupancy, ele.mealplan, item.rate.rate);
            }})
          }else{
            this.addContractedRate(ele.room_id, ele.occupancy, ele.mealplan);
          }
          
        });
      }
    );
  }

  initializeForm(){
    this.companyForm = this.fb.group({
      company_name: ['', [Validators.required]],
      tax_num: [''],
      contact_person: [''],
      email: [''],
      phone_num: [''],
      address: [''],
      applicable_to_chain: [],
      contracted_rate: this.fb.array([]),
      user: [this.currentUser]
    });
  }

  addContractedRate(room_id, occupancy, mealplan, rate = null){
    (this.contracted_rate as FormArray).push(this.fb.group({
      room_id, occupancy, mealplan, rate
    }));
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onSubmit(form) {
    if (this.data.mode === 'add') {
      this.companiesServices.addCompany(form.value, this.data.hotelId).subscribe(
        data => {
          this.commS.emitCompanyChange('change');
        }
      );
    } else if (this.data.mode === 'edit') {
      this.companiesServices.updateCompany(form.value, this.data.hotelId, this.data.data.id).subscribe(
        data => {
          this.commS.emitCompanyChange('change');
        }
      );
    }
  }

  editCompany() {
    const data = this.data.data;
    this.companyForm = this.fb.group({
      company_name: [data.name, [Validators.required]],
      tax_num: [data.tax_num],
      contact_person: [data.contact_person],
      email: [data.email],
      phone_num: [data.phone],
      address: [data.address],
      applicable_to_chain: [],
      contracted_rate: this.fb.array([]),
      user: [this.currentUser]
    });

    if (data.contracted_rate.length > 0) {
      var occupancyPresent
      data.contracted_rate.forEach(ele => {
        if (ele.occupancy && ele.mealplan){
          this.addContractedRate(ele.room_id, ele.occupancy, ele.mealplan, ele.rate);
        }else{
          occupancyPresent = false
        }
      });
      if (occupancyPresent == false){
        this.getHotelRooms(data.contracted_rate);
      }
    } else {
      this.getHotelRooms();
    }
  }

}
