<div class="primary_modal_wrapper">
    <div class="modal_heading">
        <h1>{{'stayview.previous_checkins' | translate}}</h1>

        <svg [mat-dialog-close]="false" role="button"
            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
          <path
            d="M315.3 411.3c-6.253 6.253-16.37 6.253-22.63 0L160 278.6l-132.7 132.7c-6.253 6.253-16.37 6.253-22.63 0c-6.253-6.253-6.253-16.37 0-22.63L137.4 256L4.69 123.3c-6.253-6.253-6.253-16.37 0-22.63c6.253-6.253 16.37-6.253 22.63 0L160 233.4l132.7-132.7c6.253-6.253 16.37-6.253 22.63 0c6.253 6.253 6.253 16.37 0 22.63L182.6 256l132.7 132.7C321.6 394.9 321.6 405.1 315.3 411.3z" />
        </svg>
    </div>

    <mat-divider></mat-divider>

    <div class="modal_body">

        <table class="table" *ngIf="loaded && allocations?.length">
            <thead class="thead-dark">
                <tr>
                    <th>{{'stayview.guest_name' | translate}}</th>
                    <th>{{'stayview.source' | translate}}</th>
                    <th>{{'stayview.checkin' | translate}}</th>
                    <th>{{'stayview.checkout' | translate}}</th>
                    <th>{{'stayview.amount' | translate}}</th>
                    <th>{{'stayview.room_no' | translate}}</th>
                    <th>{{'stayview.status' | translate}}</th>
                    <th></th>
                    <th></th>
                </tr>
            </thead>

            <tbody>
                <tr *ngFor="let item of allocations">
                    <td class="ellipsis">{{item.guestName}}</td>
                    <td>{{item.source}}</td>
                    <td>{{item.checkIn | date: 'dd/MM/YYYY'}}</td>
                    <td>{{item.checkOut | date: 'dd/MM/YYYY'}}</td>
                    <td class="text-right">{{item.totalAmount | number: '1.2-2'}}</td>
                    <td>{{item.roomNum}}</td>
                    <td>{{item.status}}</td>
                    <td>
                        <button *ngIf="item.status === 'Assigned'; else default"
                            class="checkin-btn"
                            (click)="checkIn(item.roomAllocationId, item.bookingId, item.guestName)"
                            >{{'stayview.check_in' | translate}}
                        </button>

                        <ng-template #default>
                            <button class="checkin-btn d-flex align-items-center" 
                                (click)="getAvailableRooms(item.checkIn, item.checkOut, item.roomId)"
                                [matMenuTriggerFor]="menu">
                                {{'stayview.check_in' | translate}}

                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="16">
                                  <path
                                    d="M4.251 181.1C7.392 177.7 11.69 175.1 16 175.1c3.891 0 7.781 1.406 10.86 4.25l197.1 181.1l197.1-181.1c6.5-6 16.64-5.625 22.61 .9062c6 6.5 5.594 16.59-.8906 22.59l-208 192c-6.156 5.688-15.56 5.688-21.72 0l-208-192C-1.343 197.7-1.749 187.6 4.251 181.1z" />
                                </svg>
                            </button>
                        </ng-template>

                        <mat-menu #menu="matMenu">
                            <ng-container *ngIf="!roomsLoading && !availableRooms?.length">
                                {{'stayview.no_rooms_available' | translate}}
                            </ng-container>
                            <ng-container *ngIf="roomsLoading">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin:auto;background:#fff;display:block;" width="100px" height="100px" viewBox="0 0 100 50" preserveAspectRatio="xMidYMid">
                                    <rect x="14.5" y="10" width="8" height="20" fill="#b2b2b2">
                                      <animate attributeName="opacity" dur="0.8620689655172413s" repeatCount="indefinite" calcMode="spline" keyTimes="0;0.5;1" keySplines="0.5 0 0.5 1;0.5 0 0.5 1" values="1;0.2;1" begin="-0.5172413793103448"></animate>
                                    </rect><rect x="34.5" y="10" width="8" height="20" fill="#b2b2b2">
                                      <animate attributeName="opacity" dur="0.8620689655172413s" repeatCount="indefinite" calcMode="spline" keyTimes="0;0.5;1" keySplines="0.5 0 0.5 1;0.5 0 0.5 1" values="1;0.2;1" begin="-0.3448275862068965"></animate>
                                    </rect><rect x="54.5" y="10" width="8" height="20" fill="#b2b2b2">
                                      <animate attributeName="opacity" dur="0.8620689655172413s" repeatCount="indefinite" calcMode="spline" keyTimes="0;0.5;1" keySplines="0.5 0 0.5 1;0.5 0 0.5 1" values="1;0.2;1" begin="-0.17241379310344826"></animate>
                                    </rect><rect x="74.5" y="10" width="8" height="20" fill="#b2b2b2">
                                      <animate attributeName="opacity" dur="0.8620689655172413s" repeatCount="indefinite" calcMode="spline" keyTimes="0;0.5;1" keySplines="0.5 0 0.5 1;0.5 0 0.5 1" values="1;0.2;1" begin="-0.8620689655172413"></animate>
                                    </rect>
                                </svg>
                            </ng-container>
                            <button *ngFor="let room of availableRooms"
                                (click)="assignGuest(room, item)"
                                mat-menu-item>
                                {{room.roomNum}}
                            </button>

                        </mat-menu>
                          
                    </td>
                    <td><small><mat-icon style="cursor: pointer; font-size:medium;" (click)="openEditStay(item.roomAllocationId, item.status, item.bookingId, item.source)">edit</mat-icon></small></td>
                </tr>
            </tbody>
        </table>

        <div *ngIf="!loaded">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin:auto;background:#fff;display:block;" width="100px" height="100px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                <g transform="translate(50 50)">
                  <g transform="scale(0.8)">
                    <g transform="translate(-50 -50)">
                      <g>
                        <animateTransform attributeName="transform" type="translate" repeatCount="indefinite" dur="1s" values="-20 -20;20 -20;0 20;-20 -20" keyTimes="0;0.33;0.66;1"></animateTransform>
                        <path fill="#46dff0" d="M44.19 26.158c-4.817 0-9.345 1.876-12.751 5.282c-3.406 3.406-5.282 7.934-5.282 12.751 c0 4.817 1.876 9.345 5.282 12.751c3.406 3.406 7.934 5.282 12.751 5.282s9.345-1.876 12.751-5.282 c3.406-3.406 5.282-7.934 5.282-12.751c0-4.817-1.876-9.345-5.282-12.751C53.536 28.033 49.007 26.158 44.19 26.158z"></path>
                        <path fill="#e90c59" d="M78.712 72.492L67.593 61.373l-3.475-3.475c1.621-2.352 2.779-4.926 3.475-7.596c1.044-4.008 1.044-8.23 0-12.238 c-1.048-4.022-3.146-7.827-6.297-10.979C56.572 22.362 50.381 20 44.19 20C38 20 31.809 22.362 27.085 27.085 c-9.447 9.447-9.447 24.763 0 34.21C31.809 66.019 38 68.381 44.19 68.381c4.798 0 9.593-1.425 13.708-4.262l9.695 9.695 l4.899 4.899C73.351 79.571 74.476 80 75.602 80s2.251-0.429 3.11-1.288C80.429 76.994 80.429 74.209 78.712 72.492z M56.942 56.942 c-3.406 3.406-7.934 5.282-12.751 5.282s-9.345-1.876-12.751-5.282c-3.406-3.406-5.282-7.934-5.282-12.751 c0-4.817 1.876-9.345 5.282-12.751c3.406-3.406 7.934-5.282 12.751-5.282c4.817 0 9.345 1.876 12.751 5.282 c3.406 3.406 5.282 7.934 5.282 12.751C62.223 49.007 60.347 53.536 56.942 56.942z"></path>
                      </g>
                    </g>
                  </g>
                </g>
            </svg>
        </div>

        <div *ngIf="loaded && !allocations?.length">
            {{'stayview.no_previous_checkins_found' | translate}}
        </div>
   
    </div>

</div>