<div>
    <div class="aio_heading">
        <h2>Details of B2B Invoices - 4A, 4B, 4C, 6B, 6C</h2>

        <div class="d-flex justify-space-between align-items-center">
            <button mat-raised-button class="primary_btn mr-1" (click)="downloadExcel()">
                Download Excel
            </button>
        
            <button mat-raised-button class="primary_btn" (click)="back()">
              Back
            </button>
        </div>
    </div>

    <mat-divider></mat-divider>

    <div class="container-fluid common_content">
        
        <table class="table">
            <thead class="thead-dark">
                <tr>
                    <th>Particular</th>
                    <th>GSTIN/NUM</th>
                    <th class="text-right">Voucher Count</th>
                    <th class="text-right">E Invoice Count</th>
                    <th class="text-right">Taxable Amount</th>
                    <th class="text-right">Integrated Tax Amount</th>
                    <th class="text-right">Central Tax Amount</th>
                    <th class="text-right">State Tax Amount</th>
                    <th class="text-right">Cess Amount</th>
                    <th class="text-right">Tax Amount</th>
                    <th class="text-right">Invoice Amount</th>
                </tr>
            </thead>

            <tbody>
                <tr *ngFor="let item of tableData">
                    <td>{{item.billTo}}</td>
                    <td>{{item.ctin}}</td>
                    <td class="text-center links" (click)="openInvoiceDetails(item)">
                        {{item.voucherCount}}
                    </td>
                    <td class="text-right">{{item.eInvoiceCount}}</td>
                    <td class="text-right">{{item.preTaxAmt | number: '1.2-2'}}</td>
                    <td class="text-right">{{item.igst | number: '1.2-2'}}</td>
                    <td class="text-right">{{item.cgst | number: '1.2-2'}}</td>
                    <td class="text-right">{{item.sgst | number: '1.2-2'}}</td>
                    <td></td>
                    <td class="text-right">{{item.totalTax | number: '1.2-2'}}</td>
                    <td class="text-right">{{item.totalInvoiceAmt | number: '1.2-2'}}</td>
                </tr>

                <tr class="total-row dark-row">
                    <td>Total</td>
                    <td></td>
                    <td class="text-center">{{totalData?.voucherCount}}</td>
                    <td class="text-center">{{totalData?.eInvoiceCount}}</td>
                    <td class="text-right">{{totalData?.preTaxAmt | number: '1.2-2'}}</td>
                    <td class="text-right">{{totalData?.igst | number: '1.2-2'}}</td>
                    <td class="text-right">{{totalData?.cgst | number: '1.2-2'}}</td>
                    <td class="text-right">{{totalData?.sgst | number: '1.2-2'}}</td>
                    <td></td>
                    <td class="text-right">{{totalData?.totalTax | number: '1.2-2'}}</td>
                    <td class="text-right">{{totalData?.totalInvoiceAmt | number: '1.2-2'}}</td>
                </tr>
                <tr>
                    <td></td>
                </tr>
            </tbody>

        </table>

    </div>

</div>
