import { AbstractControl, ValidationErrors } from '@angular/forms';

export class CustomValidator {
    static cannotBeLessThanOne(control: AbstractControl): ValidationErrors | null {
        if ((control.value) <= 0){
            return {cannotBeLessThanOne: true };
        }
        return null;
    }
}
