<div class="tab-container">
    
    <div class="d-flex justify-content-end">
        <button *ngIf="accountsConfig?.conf?.accountsConfigLevel === 'chain'"  mat-stroked-button 
            class="btn-sm pr-border mr-2"
            (click)="updateGroupCompanies()">
            {{ "accounts.update_group_companies" | translate }}
        </button>

        <button class="btn-sm pr-border mr-2" (click)="addCompany()">
            {{ "accounts.add_company" | translate }}
        </button>

        <input type="search" id="search" 
            class="form-control form-control-sm" placeholder="Search..." 
            [formControl]="searchInput" >
    </div>

    <table class="table m-3" *ngIf="companies?.data?.length">
        <thead class="thead-dark">
            <tr>
                <th>{{ "accounts.name" | translate }}</th>
                <th>{{ "accounts.gst/vat" | translate }}</th>
                <th>{{ "accounts.phone" | translate }}</th>
                <th>{{ "accounts.email" | translate }}</th>
                <th class="text-right">{{ "accounts.total_outstanding" | translate }}</th>
                <th class="text-center">{{ "accounts.action" | translate }}</th>
            </tr>
        </thead>

        <tbody>
            <tr *ngFor="let item of companies?.data">
                <td>{{item.name}}</td>
                <td>{{item.taxNum}}</td>
                <td>{{item.phone}}</td>
                <td>{{item.email}}</td>
                <td class="text-right">
                    <div class="d-flex justify-content-between">
                        <span>{{item.totalOutstanding | number: '1.2-2'}}</span>
                        <mat-icon matTooltip="Refresh Total Outstanding" (click)="refreshOutstanding(item)">refresh</mat-icon>
                    </div>
                </td>
                <td class="d-flex justify-content-end">
                    <button class="btn-sm pr-border mr-2" (click)="receivePayment(item)">
                        {{ "accounts.receive_payment" | translate }}
                    </button>
                    <button class="btn-sm pr-border mr-2"
                        (click)="viewLedger(item)">
                        {{ "accounts.view_ledger" | translate }}
                    </button>
                    <mat-icon class="text-primary mr-1" (click)="editCompany(item)">edit</mat-icon>
                    <mat-icon class="text-danger" (click)="deleteCompany(item)">delete</mat-icon>
                </td>
            </tr>
        </tbody>
    </table>

    <h1 *ngIf="!loading && !companies?.data?.length"> {{ "accounts.no_data_found" | translate }} </h1>

    <div *ngIf="companies?.data?.length && companies?.total_pages" class="d-flex justify-content-end">
        <nav>
            <ul class="pagination">
                <li class="page-item">
                    <a class="page-link" (click)="previousPage()">{{ "accounts.previous" | translate }}</a>
                </li>
                <li class="page-item" *ngFor="let page of paginateArray;">
                    <a class="page-link" [ngClass]="{'active-page': page === currentPage}" (click)="getReceivables(page)">
                        {{page}}
                    </a>
                </li>
                <li class="page-item">
                    <a class="page-link" (click)="nextPage()">{{ "accounts.next" | translate }}</a>
                </li>
            </ul>
          </nav>
    </div>

</div>
