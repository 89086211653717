import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { HRModuleSevice } from 'src/app/core/hr-module.service';
import { DialogComponent } from 'src/app/_shared/dialog/dialog.component';
import { CreateEmployeeComponent } from './create-employee/create-employee.component';
import { saveAs } from 'file-saver-es';

@Component({
  selector: 'app-employees',
  templateUrl: './employees.component.html',
  styleUrls: ['./employees.component.scss'],
})
export class EmployeesComponent implements AfterViewInit {
  @Input() hotelId: string;
  @Input() user: string;
  @Input() groupId: string;
  @Input() showSalary: boolean;
  @Input() showEmployees: boolean;
  ELEMENT_DATA: EmployeeInfoElement[];
  level = '';
  employeeStatus = 'Active';

  constructor(public dialog: MatDialog, private hrService: HRModuleSevice) {}

  displayedColumns: string[] = [
    'name',
    'department',
    'position',
    'joinDate',
    'phone',
    'email',
    'id',
    'comments',
    'take_action',
  ];
  dataSource = new MatTableDataSource<EmployeeInfoElement>();

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit(): void {
    this.fetchAllEmployeesInfo();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.hrService.getProductConfig(this.hotelId).subscribe((success) => {
      this.level = success.conf.HRConfigLevel;
      this.groupId = success.conf.chainId;
    });
  }

  createNewEmployee() {
    const data = { hotelId: this.hotelId, mode: 'new', user: this.user };
    this.dialog.open(CreateEmployeeComponent, {
      width: '80%',
      maxWidth: '95%',
      minHeight: '90%',
      height: '100%',
      data,
    });
  }

  fetchAllEmployeesInfo(filter = 'Active') {
    this.hrService
      .fetchAllInfoOfEmployees(this.hotelId, null, filter)
      .subscribe((success) => {
        this.dataSource.data = success as EmployeeInfoElement[];
      });
  }

  openIds(photoIds) {
    for (var link in photoIds) {
      window.open(photoIds[link]['url']);
    }
  }

  editEmployee(employeeId) {
    const data = {
      hotelId: this.hotelId,
      mode: 'modify',
      user: this.user,
      employeeId: employeeId,
      showSalary: this.showSalary,
    };
    this.dialog.open(CreateEmployeeComponent, {
      width: '80%',
      maxWidth: '95%',
      minHeight: '90%',
      height: '100%',
      data,
    });
  }

  deleteEmployee(employeeId) {
    const dialogBody = {
      title: 'Deletion',
      body: 'Are you sure you want to Delete ?',
      confirmBtn: 'Submit',
      cancelBtn: 'Cancel',
    };
    const dialogRef = this.dialog.open(DialogComponent, { data: dialogBody });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'true') {
        this.hrService.removeEmployee(employeeId, this.hotelId).subscribe(
          (success) => {
            var WarningDialog = {
              title: 'Done !',
              body: `You Have successfully removed the data`,
              cancelBtn: 'Dismiss',
            };
            const dialogRef = this.dialog.open(DialogComponent, {
              data: WarningDialog,
            });
            dialogRef.disableClose = true;
            dialogRef.afterClosed().subscribe((result) => {
              if (result === 'false') {
                this.dialog.closeAll();
                window.location.reload();
              }
            });
          },
          (err) => {
            const WarningDialog = {
              title: 'ERROR !',
              body: 'Something went wrong!!',
              cancelBtn: 'Dismiss',
            };
            const dialogRef = this.dialog.open(DialogComponent, {
              data: WarningDialog,
            });
            dialogRef.disableClose = true;
            dialogRef.afterClosed().subscribe((result) => {
              if (result === 'false') {
                this.dialog.closeAll();
                window.location.reload();
              }
            });
          }
        );
      }
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  downloadData(group = null) {
    var showSalary = String(this.showSalary);

    this.hrService
      .downloadEmployeesData(this.hotelId, group, showSalary)
      .subscribe((data) => {
        let blob: any = new Blob([data], { type: 'text/xlsx; charset=utf-8' });
        let fileName = 'EmployeesDetails.xlsx';
        saveAs(blob, fileName);
      });
  }
}
export interface EmployeeInfoElement {
  name: string;
  department: string;
  reportingManager: string;
  position: string;
  joinDate: string;
  exitDate: string;
  salary: string;
  accountNum: string;
  ifscCode: string;
  processedSalary: string;
  paidLeave: number;
  casualLeave: number;
  advanceBalance: number;
  advanceInstallment: number;
  pfNum: string;
  pfUANNum: string;
  esiNum: string;
  comments: string;
}
