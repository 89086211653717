import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MultiSelectDropdownService {

  private _openedNow = new BehaviorSubject<{prev, current}>({prev: '', current: ''});
  openedNow$ = this._openedNow.asObservable();

  constructor() { }

  set openedNow(id: string) {
    this._openedNow.next({
      prev: this._openedNow.value.current,
      current: id
    });

  }

}
