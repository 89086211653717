<div>
  <nav class="navbar" role="navigation" aria-label="main navigation">
      <div class="navbar-brand">
          <h2 *ngIf="mode == 'new'">{{ "maintenance.create_laundry" | translate }}</h2>
          <h2 *ngIf="mode == 'edit'">{{ "maintenance.edit_laundry" | translate }}</h2>
          <h2 *ngIf="mode == 'summary'">{{ "maintenance.laundry_summary" | translate }}</h2>
          <h2 *ngIf="mode == 'stock'">{{ "maintenance.laundry_item_stock" | translate }}</h2>
      </div>
      <div class="navbar-end">
          <button mat-stroked-button class="btn-sm pr-border backButton" (click)="close()">
          {{ "maintenance.back_btn" | translate }}
          </button>
          <button *ngIf="submitting == false" [hidden]="mode == 'summary' || mode == 'stock'" [disabled]="mode == 'new' && listOfItems.length == 0" type="submit" mat-stroked-button class="btn-sm pr-border" (click)="submit()">{{ "maintenance.submit" | translate }}</button>
          <button *ngIf="submitting == true" type="submit" mat-stroked-button class="btn-sm pr-border" disabled>{{ "maintenance.submitting" | translate }}...</button>
      </div>
  </nav>
  
  <mat-divider></mat-divider>
  <div class="form-row mt-4" *ngIf="mode == 'summary'">
    <div class="form-group col-md-8 col-sm-8 col-xs-8">
        <label for="selectedDate">{{ "maintenance.select_date" | translate }}:</label>
        <input type="date" class="form-control form-control-sm" onkeydown="return false" id="selectedDate" [(ngModel)]="selectedDate" 
            name="selectedDate"/>
    </div>
    <div class="form-group col-md-2 col-sm-2 col-xs-2">
      <button [disabled]="!selectedDate || downloadDisabled" type="submit" mat-stroked-button class="btn-sm pr-border addButton" (click)="downloadSummary()">{{ "maintenance.download" | translate }}</button>
    </div>
    
  </div>
  <div class="form-row mt-4" *ngIf="mode !== 'summary' && mode !=='stock'">
    <div class="form-group col-md-2 col-sm-2 col-xs-2" *ngIf = "status == 'Sent'">
        <label for="sentDate">{{ "maintenance.sent_date" | translate }}:</label>
        <input type="date" class="form-control form-control-sm" onkeydown="return false" id="sentDate" [(ngModel)]="sentDate" 
            name="sentDate"/>
    </div>
    <div class="form-group col-md-2 col-sm-2 col-xs-2" *ngIf = "status == 'Received'">
        <label for="receivedDate">{{ "maintenance.received_date" | translate }}:</label>
        <input type="date" class="form-control form-control-sm" onkeydown="return false" id="receivedDate" [(ngModel)]="receivedDate"
            name="receivedDate"/>
    </div>
    <div class="form-group col-md-2 col-sm-2 col-xs-2">
        <label for="item">{{ "maintenance.item" | translate }}:</label>
        <select class="form-control form-control-sm" [(ngModel)]="item" required>
            <option *ngFor="let item of laundryItems" [value]="item">
                {{ item }}
            </option>
        </select>
    </div>
    <div class="form-group col-md-1 col-sm-1 col-xs-1">
        <label for="quantity">{{ "maintenance.quantity" | translate }}:</label>
        <input type="number" class="form-control form-control-sm" id="quantity" [(ngModel)]="quantity" min="1" name="quantity" value="" required/>
    </div>
    <div class="form-group col-md-1 col-sm-1 col-xs-1">
        <label for="status">{{ "maintenance.status" | translate }}:</label>
        <select class="form-control form-control-sm" [(ngModel)]="status" id="status">
            <option value="Sent" selected>{{ "maintenance.sent" | translate }}</option>
            <option value="Received">{{ "maintenance.received" | translate }}</option>
        </select>
    </div>
    <div class="form-group col-md-3 col-sm-3 col-xs-3">
        <label for="comments">{{ "maintenance.comments" | translate }}:</label>
        <input type="text" class="form-control form-control-sm" id="comments" [(ngModel)]="comments" name="comments" value="" />
    </div>
    <div class="form-group col-md-1 col-sm-1 col-xs-1">
      <button (click)="addListOfItems()" [hidden]="mode != 'new'" [disabled]="!item" mat-stroked-button class="addButton">{{ "maintenance.add_item" | translate }}</button>
    </div>
  </div>

  <span *ngIf="mode !== 'summary' && mode !=='stock'">* {{ "maintenance.status_change_message" | translate }} *</span>
  
  <div *ngIf="listOfItems && listOfItems.length > 0">
    <table class="table table-striped">
      <thead class="thead-dark">
        <tr class="text-center">
          <th>{{ "maintenance.sr_no" | translate }}</th>
          <th>{{ "maintenance.item" | translate }}</th>
          <th>{{ "maintenance.quantity" | translate }}</th>
          <th>{{ "maintenance.status" | translate }}</th>
          <th>{{ "maintenance.sent_date" | translate }}</th>
          <th>{{ "maintenance.received_date" | translate }}</th>
          <th>{{ "maintenance.comments" | translate }}</th>
          <th>{{ "maintenance.action" | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let data of listOfItems; let indexOfelement = index"
          class="text-center"
        >
          <td>{{ indexOfelement + 1 }}</td>
          <td>{{ data.item }}</td>
          <td>{{ data.quantity }}</td>
          <td>{{ data.status }}</td>
          <td>{{ data.sentDate }}</td>
          <td>{{ data.receivedDate }}</td>
          <td>{{ data.comments }}</td>
          <td>
            <mat-icon class="actions" (click)="removeItemFromList(indexOfelement)">
              delete
            </mat-icon>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div *ngIf="laundryStockInfo && laundryStockInfo.length > 0">
    <table class="table table-striped">
      <thead class="thead-dark">
        <tr class="text-center">
          <th>{{ "maintenance.sr_no" | translate }}</th>
          <th>{{ "maintenance.item_name" | translate }}</th>
          <th>{{ "maintenance.total_items" | translate }}</th>
          <th>{{ "maintenance.sent_item" | translate }}</th>
          <th>{{ "maintenance.current_balance" | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let data of laundryStockInfo; let indexOfelement = index"
          class="text-center"
        >
          <td>{{ indexOfelement + 1 }}</td>
          <td>{{ data.itemName }}</td>
          <td>{{ data.totalItems }}</td>
          <td>{{ data.totalItems - data.currentBalance}}</td>
          <td>{{ data.currentBalance }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>    


