<div class="primary_modal_wrapper">
    <div class="modal_heading">
        <h1>Add GST</h1>
    </div>

    <mat-divider></mat-divider>

    <div class="modal_body">

        
        <form [formGroup]="addGstForm">

            <div>
                <label>Pre Tax Amount</label>
                <input 
                    class="form-control form-control-sm" 
                    type="text" 
                    formControlName="preTaxAmount" 
                    (keypress)="numberOnly($event)">
            </div>
            
            <table class="table">
                <thead>
                    <tr>
                        <th width="40%">Tax</th>
                        <th width="40%">Amount</th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody formArrayName="taxes">
                    <tr *ngFor="let item of itemsFormArray.controls; let i = index" [formGroupName]="i">
                        <td>
                            <select class="form-control form-control-sm" formControlName="taxName" (change)="taxNameChange(i)">
                                <option *ngFor="let item of data.taxNames"
                                    [value]="item.name">
                                    {{item.name}}
                                </option>
                            </select>
                          </td>

                          <td>
                            <input 
                                class="form-control form-control-sm" 
                                formControlName="amount" type="text" 
                                placeholder="Amount" 
                                (keypress)="numberOnly($event)">
                          </td>

                          <td>
                            <mat-icon *ngIf="itemsFormArray.length > 1" 
                                type="button" 
                                mat-raised-button 
                                (click)="removeItem(i)">
                                delete_outline
                            </mat-icon>
                          </td>

                          <td>
                            <mat-icon *ngIf="i === itemsFormArray.length - 1" 
                                type="button" 
                                mat-raised-button 
                                (click)="addItem()">
                                add_circle_outline
                            </mat-icon>
                          </td>
                    </tr>
                </tbody>
            </table>
          </form>

          <div class="total_block">
            <label>Total</label>
            <span>{{totalAmount | number: '1.2-2'}}</span>
          </div>
    </div>

    <div class="modal_footer">
        <mat-dialog-actions align="end">
            <button mat-raised-button 
                mat-dialog-close>
                Cancel
            </button>
            <button mat-raised-button
                class="primary_btn"
                (click)="submit()">
                Done
            </button>
        </mat-dialog-actions>
    </div>

</div>