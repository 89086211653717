import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GuestsService {
  constructor(private http: HttpClient) {}

  getGuests(hotelId, page): Observable<any> {
    const httpParams = new HttpParams({
      fromObject: { page },
    });
    return this.http.get(`${environment.apiUrl}/guests/${hotelId}`, {
      params: httpParams,
    });
  }

  getGuestsData(hotelId, limit, keyword): Observable<any> {
    const httpParams = new HttpParams({
      fromObject: { limit, keyword },
    });
    return this.http.get(`${environment.apiUrl}/fetchGuestsData/${hotelId}`, {params: httpParams});
  }

  guestSearch(hotelId, query): Observable<any> {
    const httpParams = new HttpParams({
      fromObject: { query },
    });
    return this.http.get(`${environment.apiUrl}/guests/${hotelId}`, {
      params: httpParams,
    });
  }

  editGuest(hotelId, guestId, data): Observable<any> {
    return this.http.put(
      `${environment.apiUrl}/guests/${hotelId}/${guestId}`,
      data
    );
  }

  getPrevBookings(guestId: string, hotelId: string): Observable<any> {
    return this.http.get(
      `${environment.apiUrl}/guests/${hotelId}/${guestId}/prev-bookings`
    );
  }

  downloadGuests(hotelId): Observable<any> {
    return this.http.get(`${environment.apiUrl}/download/guests/${hotelId}`, {
      responseType: 'blob',
    });
  }

  uploadGuests(hotelId, filedata, user): Observable<any> {
    const data = new FormData();
    data.append('file', filedata);
    const httpParams = new HttpParams({
      fromObject: { user },
    });
    return this.http.put(
      `${environment.apiUrl}/uploadGuestsList/${hotelId}`,
      data,
      { params: httpParams }
    );
  }

  removeDuplicateEntries(hotelId, user): Observable<any> {
    return this.http.put(`${environment.apiUrl}/dedupeGuestData/${hotelId}`, {
      user: user,
    });
  }
}
