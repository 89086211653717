<nav class="navbar" role="navigation" aria-label="main navigation">
  <div class="navbar-brand">
    Select Guest
  </div>
  <div class="navbar-end">
    <div class="navbar-item">
      <div class="search">
        <form [formGroup]="search" (ngSubmit)="onSearch(search)">
          <span>Search Name, Email or Phone number</span>
          <input
            id="search-bar"
            type="text"
            placeholder="{{ 'guest.search' | translate }}. . ."
            formControlName="searchInput"
          />
          <button type="submit" id="newbutton">
            Search
          </button>
          <button type="button" id="newbutton" (click)="back()">
            Back
          </button>
        </form>
      </div>
    </div>
  </div>
</nav>
<hr>
<div class="mat-elevation-z8">

    <table mat-table [dataSource]="dataSource">

        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
          </th>
          <td mat-cell *matCellDef="let row">
            <button id="newbutton" (click)="selectedRow(row)">
             Select
            </button>
          </td>
        </ng-container>

        <!-- Position Column -->
        <ng-container matColumnDef="position">
            <th mat-header-cell *matHeaderCellDef> Sr. No </th>
            <td mat-cell *matCellDef="let element"> {{element.position}} </td>
          </ng-container>
      
        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef> Name </th>
          <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>
      
        <!-- Email Column -->
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef> Email </th>
          <td mat-cell *matCellDef="let element"> {{element.email}} </td>
        </ng-container>
      
        <!-- Phone Column -->
        <ng-container matColumnDef="phone">
          <th mat-header-cell *matHeaderCellDef> Phone </th>
          <td mat-cell *matCellDef="let element"> {{element.phone}} </td>
        </ng-container>
      
        <!-- DOB Column -->
        <ng-container matColumnDef="dob">
          <th mat-header-cell *matHeaderCellDef> DOB </th>
          <td mat-cell *matCellDef="let element"> {{element.dob}} </td>
        </ng-container>

        <!-- Country Column -->
        <ng-container matColumnDef="country">
            <th mat-header-cell *matHeaderCellDef> Country </th>
            <td mat-cell *matCellDef="let element"> {{element.address.country}} </td>
        </ng-container>

        <!-- IdDetail Column -->
        <ng-container matColumnDef="idDetail">
            <th mat-header-cell *matHeaderCellDef> ID Detail </th>
            <td mat-cell *matCellDef="let element"> {{element.idDetail}} </td>
        </ng-container>

        <!-- PhotoId Column -->
        <ng-container matColumnDef="photoId">
          <th mat-header-cell *matHeaderCellDef class="column-photo-id"> Photo ID </th>
          <td mat-cell *matCellDef="let element" class="column-photo-id">
            <div class="inline-container">
              <ng-container *ngFor="let photo of element.photoId | slice:0:2">
                <a *ngIf="photo.url" href="{{ photo.url }}" target="_blank" class="inline">
                  <img *ngIf="photo.url" [src]="photo.url" alt="Photo Thumbnail" width="40" height="40">
                </a>
                <a *ngIf="photo.signature" href="{{ photo.signature }}" target="_blank" class="inline">
                  <img *ngIf="photo.signature" [src]="photo.signature" alt="Photo Thumbnail" width="40" height="40">
                </a>
              </ng-container>
            </div>
          </td>
        </ng-container>
      
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"
            (click)="selection.toggle(row)">
        </tr>
      </table>
      
</div>