import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Globals } from 'src/app/core/globals.service';
import { HRModuleSevice } from 'src/app/core/hr-module.service';
import { UserService } from 'src/app/core/user.service';


@Component({
  selector: 'app-hr-home',
  templateUrl: './hr-home.component.html',
  styleUrls: ['./hr-home.component.scss']
})
export class HrHomeComponent implements OnInit {

  tabItems = [
    {key: 'employees', name: 'Employees'},
    {key: 'attendance', name: 'Attendance'},
    {key: 'salary', name: 'Salary'}
  ];

  currentTab: string;
  hotelId: any;
  currentUser = '';
  enabled = false;
  groupId: any;
  userFeatures: any;
  showSalary = true;
  allowSalaryProcessing = true;
  showAttendance = true;
  showEmployees = true;

  constructor(private route: ActivatedRoute,
              public globals: Globals,
              private router: Router,
              private hrService: HRModuleSevice,
              private userService: UserService) { }

  ngOnInit(): void {
    Object.freeze(this.tabItems);
    this.route.params.subscribe(params => {
      this.hotelId = params.hotelId;
      this.globals.changeHotelID(this.hotelId);
    });
    this.route.queryParams.subscribe(queryParams => {
      this.currentUser = queryParams.user;
    });
    this.getUserFeatures();
    const lastIndex = sessionStorage.getItem('hrModuleCurrentTab') ? sessionStorage.getItem('hrModuleCurrentTab') : 0;
    this.changeCurrentTab(lastIndex);
    this.hrService.getProductConfig(this.hotelId).subscribe(success=>{
      this.enabled = success.isEnabled;
      this.groupId = success.conf.chainId;
    });
    
  }

  changeCurrentTab(index) {
    this.currentTab = this.tabItems[index].key;
    sessionStorage.setItem('hrModuleCurrentTab', index);
  }

  getUserFeatures() {
    // const body = {username: this.currentUser};
    // this.userService.getUserData(body).subscribe(data => {
    //   this.userFeatures = data?.features.live;
      // if (this.userFeatures.hasOwnProperty('salary')) {
      //   this.showSalary = this.userFeatures.salary;
      // }if(this.userFeatures.hasOwnProperty('salaryProcessing')){
      //   this.allowSalaryProcessing = this.userFeatures.salaryProcessing;
      // }if(this.userFeatures.hasOwnProperty('attendance')){
      //   this.showAttendance = this.userFeatures.attendance;
      // }if(this.userFeatures.hasOwnProperty('employees')){
      //   this.showEmployees = this.userFeatures.employees;
      // }
    // });
    this.userService.userFeatures$.subscribe(res => {
      this.userFeatures = res?.live;
      if (this.userFeatures?.hasOwnProperty('salary')) {
        this.showSalary = this.userFeatures.salary;
      }if(this.userFeatures?.hasOwnProperty('salaryProcessing')){
        this.allowSalaryProcessing = this.userFeatures.salaryProcessing;
      }if(this.userFeatures?.hasOwnProperty('attendance')){
        this.showAttendance = this.userFeatures.attendance;
      }if(this.userFeatures?.hasOwnProperty('employees')){
        this.showEmployees = this.userFeatures.employees;
      }
    })
  }
}
