<div class="primary_modal_wrapper">
    <div class="modal_heading">
        <h1>{{'pos.transfer_services' | translate}}</h1>
    </div>

    <mat-divider></mat-divider>

    <div class="modal_body">
        
        <div class="d-flex justify-content-between">
            <span>{{data?.services?.length}} items selected</span>
            <span>{{'pos.total_amount' | translate}}: {{totalAmount | number: '1.2-2'}}</span>
        </div>

        <div id="search_bar">
            <input type="text" class="form-control form-control-sm" placeholder="Search"
                (input)="filterOrder($event.target.value)">
        </div>

        <div class="table_wrapper mt-2">
            <table class="table">
                <thead>
                    <tr class="text-secondary">
                        <td></td>
                        <th>{{'pos.order_id' | translate}}</th>
                        <th>{{'pos.details' | translate}}</th>
                        <th>{{'pos.num_of_items' | translate}}</th>
                        <th>{{'pos.total' | translate}}</th>
                    </tr>
                </thead>

                <tbody>
                    <tr *ngFor="let item of filteredOrders">
                        <td>
                            <input 
                                type="radio"
                                name="toTransfer"
                                id="{{item.invoice_id}}"
                                [value]="item.invoice_id"
                                [(ngModel)]="selectedInvoiceId"
                            />
                        </td>
                        <td>{{item.order_id}}</td>
                        <td>{{item.details}}</td>
                        <td>{{item.services_used?.length}}</td>
                        <td class="text-right">{{item.total_amount | number: '1.2-2'}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="modal_footer">
        <mat-dialog-actions align="end">
            <button mat-raised-button mat-dialog-close>
                {{'pos.cancel' | translate}}
            </button>
            <button mat-raised-button
                class="primary_btn"
                (click)="submit()">
                {{'pos.submit' | translate}}
            </button>
        </mat-dialog-actions>
    </div>

</div>