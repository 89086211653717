import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PosService } from 'src/app/core/pos.service';
import { DialogComponent } from 'src/app/_shared/dialog/dialog.component';

@Component({
  selector: 'app-transfer-order',
  templateUrl: './transfer-order.component.html',
  styleUrls: ['./transfer-order.component.scss']
})
export class TransferOrderComponent implements OnInit {
  totalAmount = 0;
  currentOrders: any[];
  filteredOrders: any[];
  selectedInvoiceId: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private posService: PosService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<TransferOrderComponent>
  ) { }

  ngOnInit(): void {
    this.data?.services?.forEach(e => {
      this.totalAmount += e.total_amount;
    });
  
    this.currentOrders = this.posService.currentOrders?.filter(e => {
      return !e.is_complimentary && e.invoice_id !== this.data.currentInvoiceId;
    });
    
    this.filteredOrders = this.currentOrders;
  }

  filterOrder(key: string | undefined) {
    key = key?.toLowerCase();
    this.filteredOrders = this.currentOrders.filter(e => {
      return e.order_id?.toLowerCase().indexOf(key) > -1 ||
            e.details?.toLowerCase().indexOf(key) > -1;
    });
  }

  submit() {
    if (!this.selectedInvoiceId) {
      this.dialog.open(DialogComponent, {
        width: '400px',
        data: {title: 'Oops !', body: `Please select an order to transfer`, confirmBtn: 'Ok'}
      });
      return;
    }
    const serviceUsedIds: string[] = this.data.services.map(e => e.service_used_id);
    const body = {
      serviceUsedIds,
      transferToInvoice: this.selectedInvoiceId,
      transferFromInvoice: this.data.currentInvoiceId
    };

    this.dialogRef.close({success: true, body});
  }
}
