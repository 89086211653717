<div class="primary_modal_wrapper">
    <div class="heading">
        <div class="modal_heading">
            <h1>{{data.heading}}</h1>
            <button mat-stroked-button class="border-primary" (click)="copyInvoice()">Copy Invoice</button>
        </div>
        <mat-divider></mat-divider>
    </div>

    <div class="content_wrap mt-3">
        <div class="row mb-3">
            <div class="col-sm-3">
                <label for="Vendor" class="font-weight-bold">{{'stores.requester_department' | translate}}</label>
                <select class="form-control form-control-sm" [(ngModel)]="requesterDept" [disabled]="data.mode === 'view'">
                    <option value="" hidden>{{'stores.select_department' | translate}}</option>
                    <option *ngFor="let dept of userDepartments" 
                        [value]="dept.id"> {{dept.name}}
                    </option>
                </select>
            </div>
            <div class="col-sm-3" *ngIf="data.mode !== 'view'">
                <label for="category">{{'stores.category' | translate}}</label>
                <select class="form-control form-control-sm" [(ngModel)]="category" (change)="filterSubCategories(category); filterMasterItems();">
                    <option value="">All</option>
                    <option *ngFor="let cat of categories">
                        {{cat.name}}
                    </option>
                </select>
            </div>
            <div class="col-sm-3" *ngIf="data.mode !== 'view'">
                <label for="subcategory">{{'stores.sub_category' | translate}}</label>
                <select class="form-control form-control-sm" [(ngModel)]="subCategory" (change)="filterMasterItems();">
                    <option value="">All</option>
                    <option *ngFor="let cat of subCategories">
                        {{cat.name}}
                    </option>
                </select>
            </div>
            <div class="col-sm-3" *ngIf="data.mode !== 'view'">
                <label for="Date">Date</label>
                <input class="form-control form-control-sm" type="date" [(ngModel)]="indentDate" >
            </div>
        </div>
        <div *ngIf="data.mode !== 'view'" class="row form-group mb-3">
            <div class="col-sm-3">
                <label for="Item Name">{{'stores.select_item' | translate}}</label>
                <ng-select (change)="selectItem($event)" class="form-control form-control-sm">
                    <ng-option *ngFor="let item of masterItems" [value]="item.id">{{item.name}}</ng-option>
                 </ng-select>
            </div>
            <div class="col-sm-1" [ngStyle]="{ 'flex': '0 0 10%', 'max-width': '10%'}">
                <label for="Quantity">{{'stores.quantity' | translate}}</label>
                <input type="number" class="form-control form-control-sm" 
                [ngClass]="{'invalid': selectedItem.quantity < 0.01 || selectedItem.quantity > 99999999.99}"
                [(ngModel)]="selectedItem.quantity" (input)="onQuantityChange()" min="1" max="99999999.99">
            </div>
            <div class="col-sm-1">
                <label for="unit">{{'stores.unit' | translate}}</label>
                <span>{{selectedItem.unit}}</span>
            </div>
            <div class="col-sm-2">
                <label for="PreTax Amount">{{'stores.pre_tax' | translate}}</label>
                <input type="number" class="form-control form-control-sm" [ngModel]="selectedItem.pre_tax_amount" readonly>
            </div>
            <div class="col-sm-3">
                <label for="Comments">{{'stores.comments' | translate}}</label>
                <input type="text" class="form-control form-control-sm" [(ngModel)]="selectedItem.comments">
            </div>
            <div class="col-sm-1">
                <label for="Add button" class="invisible">Add</label>
                <button mat-stroked-button class="btn-sm border-primary" 
                    (click)="pushItem(selectedItem)">{{'stores.add_item_btn' | translate}}
                </button>
            </div>
        </div>

        <!-- List of Indent items -->
        <div class="table-container">
            <table class="table">
                <thead class="thead-dark">
                    <tr>
                        <th>{{'stores.name' | translate}}</th>
                        <th class="text-right" [ngStyle]="{'width': '70px'}">
                            {{'stores.qty' | translate}}
                        </th>
                        <th></th>
                        <th class="text-right" [ngStyle]="{'width': '160px'}">
                            {{'stores.pre_tax' | translate}}
                        </th>
                        <th [ngStyle]="{'width': '220px'}">
                            {{'stores.comments' | translate}}
                        </th>
                        <th *ngIf="data.mode !== 'view'" [ngStyle]="{'width': '70px'}"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of indentItems">
                        <td>{{item.name}}</td>

                        <td class="text-right" *ngIf="data.mode !== 'view'">
                            <input type="number" [ngClass]="{'invalid': item.quantity < 0.01}"
                                class="form-control form-control-sm" [value]="item.quantity"
                                (input)="onItemQuantityChange(item, $event.target.value)" min="1" placeholder="{{item.quantity}}">
                        </td>
                        <td class="text-right" *ngIf="data.mode === 'view'">
                            {{item.quantity}}
                        </td>
                        <td>{{item?.unit}}</td>
                        <td class="text-right">{{item.pre_tax_amount | number: '1.2-2'}}</td>

                        <td *ngIf="data.mode !== 'view'">
                            <input type="text" class="form-control form-control-sm" [(ngModel)]="item.comments">
                        </td>
                        <td *ngIf="data.mode === 'view'">
                            {{item.comments}}
                        </td>

                        <td *ngIf="data.mode !== 'view'" class="text-right">
                            <div class="d-flex justify-content-end">
                                <mat-icon class="text-danger" (click)="removeItem(item)">delete</mat-icon>
                            </div>
                        </td>
                    </tr>
                    <tr class="bg-light font-weight-bold">
                        <td>{{'stores.total' | translate}}</td>
                        <td class="text-right">{{indentTotal.quantity}}</td>
                        <td></td>
                        <td class="text-right">{{indentTotal.pre_tax_amount | number: '1.2-2'}}</td>
                        <td *ngIf="data.mode !== 'view'"></td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="bottom-row">
            <div *ngIf="data.mode !== 'new'" class="d-flex align-items-end mr-2">
                <button mat-stroked-button class="border-primary" (click)="getIndentPdf()"
                    [disabled]="printDisabled">
                    {{'stores.print' | translate}}
                </button>
            </div>
            <div class="d-flex align-items-end mr-2">
                <button *ngIf="data.mode !== 'view'" mat-stroked-button type="submit" class="pr-border"
                    (click)="submitIndent()" [disabled]="!indentItems || indentItems?.length === 0 || !requesterDept">
                    {{'stores.submit' | translate}}
                </button>
            </div>
            <div class="d-flex align-items-end">
                <button mat-stroked-button class="border-danger" (click)="closeDialog()">
                    {{data.mode === 'view' ? 'Close' : 'Cancel'}}
                </button>
            </div>
        </div>
    </div>
</div>