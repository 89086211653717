import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogComponent } from 'src/app/_shared/dialog/dialog.component';
import { Globals } from 'src/app/core/globals.service';
import { UserService } from 'src/app/core/user.service';

@Component({
  selector: 'app-discount-log',
  templateUrl: './discount-log.component.html',
  styleUrls: ['./discount-log.component.scss']
})
export class DiscountLogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private dialog: MatDialog,
    private userService: UserService,
    private globals: Globals,
    private dialogRef: MatDialogRef<DiscountLogComponent>
  ) { }

  ngOnInit(): void {
  }

  undoDiscount(id) {
    const dref = this.dialog.open(DialogComponent, {
      data: {
        title: 'Undo Discount', 
        body: 'Are you sure you want to undo this discount ?', 
        confirmBtn: 'Yes',
        cancelBtn: 'Cancel'
      }
    });

    dref.afterClosed().subscribe(res => {
      if (res === 'true') {
        const {roomAllocationId, hotelId} = this.data;
        this.userService.undoDiscount(hotelId, roomAllocationId, id).subscribe(res => {
          this.globals.snackBarFunc('Discount undone successfully !');
          this.dialogRef.close({refresh: true});
        })
      }
    })
  }

}
