import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportsComponent } from './reports.component';
import { MatDividerModule } from '@angular/material/divider';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { MatSelectModule } from '@angular/material/select';




@NgModule({
  declarations: [
    ReportsComponent
  ],
  imports: [
    CommonModule, 
    MatDividerModule, 
    ReactiveFormsModule, 
    MatButtonModule, 
    MatIconModule, 
    FormsModule,
    TranslateModule,
    MatSelectModule
  ]
})
export class ReportsModule { }
