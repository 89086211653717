import { GuestsComponent } from './guests.component';
import { GuestsRoutingModule } from './guests-routing.module';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { ReactiveFormsModule } from '@angular/forms';
import {MatBadgeModule} from '@angular/material/badge';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [GuestsComponent],
  imports: [
    CommonModule, GuestsRoutingModule, MatDividerModule, MatFormFieldModule, MatIconModule,
    MatInputModule, MatButtonModule, MatListModule, MatMenuModule, ReactiveFormsModule, TranslateModule
  ]
})
export class GuestsModule { }
