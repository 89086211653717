import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PosReportsComponent } from './pos-reports.component';
import { MatDividerModule } from '@angular/material/divider';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDatepickerModule } from '@angular/material/datepicker';




@NgModule({
  declarations: [
    PosReportsComponent
  ],
  imports: [
    CommonModule, 
    MatDividerModule, 
    ReactiveFormsModule, 
    MatButtonModule, 
    MatIconModule, 
    FormsModule,
    TranslateModule,
    MatSelectModule,
    MatMenuModule,
    MatTooltipModule,
    MatDatepickerModule,
  ]
})
export class PosReportsModule { }
