import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { StoreService } from 'src/app/core/store.service';
import { saveAs } from 'file-saver-es';
@Component({
  selector: 'app-store-reports',
  templateUrl: './store-reports.component.html',
  styleUrls: ['./store-reports.component.scss']
})
export class StoreReportsComponent implements OnInit, OnDestroy {

  @Input() hotelId: string;
  @Input() features: any;

  $report: any;
  endDate = new Date().toISOString().slice(0, 10);
  startDate: any;
  reportName: string;
  reportNames = [
    {name: 'Indent', types: ['Item Wise', 'Department Wise', 'Category Wise', 'Sub-category Wise']},
    {name: 'Purchase Order', types: ['Item Wise', 'Vendor Wise', 'Category Wise', 'Sub-category Wise']},
    {name: 'Consumption', types: ['Item Wise', 'Department Wise', 'Category Wise', 'Sub-category Wise']}];
  type: string;
  types: string[];

  componentDestroyed$ = new Subject();

  constructor(public storeService: StoreService,
              public datePipe: DatePipe) { }

  ngOnInit(): void {
    this.startDateCalc();
    this.reportChange(this.reportNames[0].name);
  }

  getReports() {
    this.storeService.getReport(this.hotelId, this.reportName, this.type, this.startDate, this.endDate)
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe(data => {
      this.$report = data;
      this.addHiddenState();
    });
  }

  addHiddenState() {
    this.$report.data.forEach(ele => {
      ele.hidden = true;
    });
  }

  toggleState(i: number) {
    this.$report.data[i].hidden = !this.$report.data[i].hidden;
  }

  startDateCalc() {
    this.startDate = new Date(this.endDate);
    this.startDate.setDate(this.startDate.getDate() - 7);
    this.startDate = this.datePipe.transform((new Date(this.startDate)), 'y-MM-dd');
  }

  reportChange(name: string) {
    this.reportName = name;
    for (const report of this.reportNames) {
      if (name === report.name) {
        this.types = report.types;
        this.type = this.types[0];
        break;
      }
    }
    this.getReports();
  }

  typeChange(type: string) {
    this.type = type;
    this.getReports();
  }

  downloadReport() {
    this.storeService.downloadReport(this.hotelId, this.reportName, this.startDate, this.endDate)
    .subscribe(res => {
      const blob: any = new Blob([res], {type: 'text/pdf; charset=utf-8'});
      saveAs(blob, `${this.reportName.replace(/\s/g, "")}.xlsx`);
    })
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

}
