import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StoreService {

  url = `${environment.apiUrl}/stores`;
  constructor(private http: HttpClient) { }

  /* Category */
  addCategory(body, hotelId: string): Observable <any> {
    return this.http.post(`${this.url}/category/${hotelId}`, body);
  }

  addSubCategory(body, hotelId: string): Observable <any> {
    return this.http.post(`${this.url}/sub-category/${hotelId}`, body);
  }

  editCategory(body, hotelId: string): Observable <any> {
    return this.http.put(`${this.url}/category/${hotelId}`, body);
  }

  getCategories(hotelId): Observable <any> {
    return this.http.get(`${this.url}/category/${hotelId}`);
  }

  deleteCategory(categoryId: string, hotelId: string): Observable <any> {
    return this.http.delete(`${this.url}/category/${hotelId}/${categoryId}`);
  }

  deleteSubCategory(name: string, categoryId: string, hotelId: string): Observable <any> {
    return this.http.delete(`${this.url}/sub-category/${hotelId}/${name}/${categoryId}`);
  }

  /* Master Item */
  addMasterItem(body, hotelId: string): Observable <any> {
    return this.http.post(`${this.url}/master-item/${hotelId}`, body);
  }

  getMasterItems(hotelId): Observable <any> {
    return this.http.get(`${this.url}/master-item/${hotelId}`);
  }

  editMasterItem(body, hotelId: string): Observable <any> {
    return this.http.put(`${this.url}/master-item/${hotelId}`, body);
  }

  deleteItem(itemId: string, hotelId: string): Observable <any> {
    return this.http.delete(`${this.url}/master-item/${hotelId}/${itemId}`);
  }

  /* Departments */
  addDeparment(body, hotelId: string): Observable <any> {
    return this.http.post(`${this.url}/department/${hotelId}`, body);
  }

  getDepartments(hotelId): Observable <any> {
    return this.http.get(`${this.url}/department/${hotelId}`);
  }

  editDept(body, hotelId: string): Observable <any> {
    return this.http.put(`${this.url}/department/${hotelId}`, body);
  }

  deleteDept(deptId: string, hotelId: string): Observable <any> {
    return this.http.delete(`${this.url}/department/${hotelId}/${deptId}`);
  }

  returnStock(body, hotelId): Observable <any> {
    return this.http.post(`${this.url}/return-stock/${hotelId}`, body);
  }

  /* Vendors */
  addVendor(body, hotelId: string): Observable <any> {
    return this.http.post(`${this.url}/vendor/${hotelId}`, body);
  }

  getVendors(hotelId: string, sort=''): Observable <any> {
    const httpParams = new HttpParams({fromObject: {sort}});
    return this.http.get(`${this.url}/vendor/${hotelId}`, {params: httpParams});
  }

  editVendor(body, hotelId: string): Observable <any> {
    return this.http.put(`${this.url}/vendor/${hotelId}`, body);
  }

  deleteVendor(vendorId: string, hotelId: string): Observable <any> {
    return this.http.delete(`${this.url}/vendor/${hotelId}/${vendorId}`);
  }

  getTaxGroups(hotelId: string): Observable <any> {
    return this.http.get(`${environment.apiUrl}/tax-group/${hotelId}`);
  }

  /* Purchase Orders */
  addPurchaseOrder(body, hotelId: string): Observable <any> {
    return this.http.post(`${this.url}/purchase-orders/${hotelId}`, body);
  }

  editPurchaseOrder(body, hotelId: string): Observable <any> {
    return this.http.put(`${this.url}/purchase-orders/${hotelId}`, body);
  }

  getPurchaseOrders(hotelId: string, startDate, endDate, status = null): Observable <any> {
    const obj: any = {startDate, endDate};
    if (status) {
      obj.status = status;
    }
    const httpParams = new HttpParams({fromObject: obj});
    return this.http.get(`${this.url}/purchase-orders/${hotelId}`, { params: httpParams });
  }

  getOrderItems(orderId: string): Observable <any> {
    return this.http.get(`${this.url}/order-items/${orderId}`);
  }

  changeOrderStatus(body, hotelId: string): Observable <any> {
    return this.http.put(`${this.url}/purchase-orders/${hotelId}/change-status`, body);
  }

  goodsReceived(hotelId: string, body): Observable <any> {
    return this.http.put(`${this.url}/purchase-orders/${hotelId}/goods_received`, body);
  }

  deletePurchaseOrder(hotelId: string, orderId: string): Observable <any> {
    return this.http.delete(`${this.url}/purchase-orders/${hotelId}/${orderId}`);
  }

  /* Indent */
  createIndent(hotelId: string, body): Observable <any> {
    return this.http.post(`${this.url}/indents/${hotelId}`, body);
  }

  editIndent(hotelId: string, body): Observable <any> {
    return this.http.put(`${this.url}/indents/${hotelId}`, body);
  }

  getIndents(hotelId: string, startDate, endDate, status): Observable <any> {
    const httpParams = new HttpParams({fromObject: {startDate, endDate, status}});
    return this.http.get(`${this.url}/indents/${hotelId}`, { params: httpParams });
  }

  getIndentItems(hotelId: string, indentId): Observable <any> {
    return this.http.get(`${this.url}/indent-items/${hotelId}/${indentId}`);
  }

  deleteIndent(hotelId: string, indentId): Observable <any> {
    return this.http.delete(`${this.url}/indents/${hotelId}/${indentId}`);
  }

  changeIndentStatus(hotelId: string, body): Observable <any> {
    return this.http.put(`${this.url}/indents/${hotelId}/change-status`, body);
  }

  /* Items consumed */
  consumeItem(hotelId: string, body): Observable <any> {
    return this.http.post(`${this.url}/items-consumed/${hotelId}`, body);
  }

  getItemsConsumed(hotelId: string, startDate, endDate): Observable <any> {
    const httpParams = new HttpParams({fromObject: {startDate, endDate}});
    return this.http.get(`${this.url}/items-consumed/${hotelId}`, { params: httpParams });
  }

  /* Reports */
  getReport(hotelId: string, name, type, startDate, endDate): Observable <any> {
    const httpParams = new HttpParams({fromObject: {startDate, endDate, type, name}});
    return this.http.get(`${this.url}/reports/${hotelId}`, { params: httpParams });
  }

  downloadReport(hotelId: string, name, startDate, endDate): Observable <any> {
    const httpParams = new HttpParams({fromObject: {startDate, endDate, name}});
    return this.http.get(`${this.url}/reports/download/${hotelId}`, { params: httpParams, responseType: 'blob'});
  }

  /*********/

  getUserData(body): Observable <any> {
    return this.http.post(`${environment.apiUrl}/user/get_details`, body);
  }

  getStoreConfig(hotelId: string): Observable <any> {
    return this.http.get(`${environment.apiUrl}/hotels/${hotelId}/products/stores`);
  }

  addUserDept(hotelId, body): Observable <any> {
    return this.http.post(`${this.url}/user-departments/${hotelId}`, body);
  }

  getUserDept(hotelId: string): Observable <any> {
    return this.http.get(`${this.url}/user-departments/${hotelId}`);
  }

  getDeptByUser(hotelId: string): Observable <any> {
    return this.http.get(`${this.url}/user-departments/${hotelId}/user`);
  }

  getDeptStock(hotelId: string): Observable <any> {
    return this.http.get(`${this.url}/department-stock/${hotelId}`);
  }

  deleteUserDept(hotelId: string, username, deptId): Observable <any> {
    const httpParams = new HttpParams({fromObject: {username, dept_id: deptId}});
    return this.http.delete(`${this.url}/user-departments/${hotelId}`, { params: httpParams });
  }

  calculateTax(hotelId: string, body): Observable <any> {
    return this.http.post(`${this.url}/calculate-tax/${hotelId}`, body);
  }

  getAverageRate(hotelId: string, itemId: string): Observable <any> {
    return this.http.get(`${this.url}/average-rate/${hotelId}/${itemId}`);
  }

  getPdf(hotelId: string, pdfType: string, id: string): Observable<any> {
    const httpParams = new HttpParams({fromObject: {pdfType, id}});
    return this.http.get(`${environment.apiUrl}/stores/pdf/${hotelId}`, {params: httpParams, responseType: 'blob'});
  }

  sendEmail(hotelId: string, body): Observable <any> {
    return this.http.post(`${this.url}/send-email/${hotelId}`, body);
  }

  getHotel(hotelId: string): Observable <any> {
    return this.http.get(`${environment.apiUrl}/hotels/${hotelId}`);
  }

  getBankPaymentFile(hotelId: string, data): Observable <any> {
    return this.http.post(`${this.url}/bank-payment-file/${hotelId}`, data, {responseType: 'blob'});
  }

  getBankPaymentFileExpenses(hotelId: string, data): Observable <any> {
    return this.http.post(`${this.url}/bank-payment-file/${hotelId}/expense`, data, {responseType: 'blob'});
  }
}
