<div>
    <nav class="navbar" role="navigation" aria-label="main navigation">
        <div class="navbar-brand">
            <h2 *ngIf="mode == 'new'">{{ "maintenance.create_entry" | translate }}</h2>
            <h2 *ngIf="mode == 'edit'">{{ "maintenance.edit_entry" | translate }}</h2>
        </div>
        <div class="navbar-end">
            <button mat-stroked-button class="btn-sm pr-border backButton" (click)="close()">
            {{ "maintenance.back_btn" | translate }}
            </button>
        </div>
    </nav>
    
    <mat-divider></mat-divider>

    <div class="form-row mt-4">
        <div class="form-group col-md-2 col-sm-2 col-xs-2">
            <label for="date">{{ "maintenance.date" | translate }}:</label>
            <input type="date" class="form-control form-control-sm" onkeydown="return false" id="date" [(ngModel)]="date" name="date"/>
        </div>
        <div class="form-group col-md-3 col-sm-3 col-xs-3">
            <label for="itemDetails">{{ "maintenance.item_details" | translate }}:</label>
            <input type="text" class="form-control form-control-sm" id="itemDetails" [(ngModel)]="itemDetails" name="itemDetails" value="" required/>
        </div>
        
        <div class="form-group col-md-1 col-sm-1 col-xs-1">
            <label for="status">{{ "maintenance.status" | translate }}:</label>
            <select class="form-control form-control-sm" [(ngModel)]="status" id="status">
                <option value="Lost" selected>{{ "maintenance.lost" | translate }}</option>
                <option value="Found">{{ "maintenance.found" | translate }}</option>
            </select>
        </div>
        <div class="form-group col-md-1 col-sm-1 col-xs-1">
            <label for="itemStatus">{{ "maintenance.item_status" | translate }}:</label>
            <select class="form-control form-control-sm" [(ngModel)]="itemStatus" id="itemStatus">
                <option value="Open" selected>{{ "maintenance.open" | translate }}</option>
                <option value="Close">{{ "maintenance.close" | translate }}</option>
            </select>
        </div>
        
        <div class="form-group col-md-5 col-sm-5 col-xs-5">
            <label for="comments">{{ "maintenance.comments" | translate }}:</label>
            <input type="text" class="form-control form-control-sm" id="comments" [(ngModel)]="comments" name="comments" value="" />
        </div>
    </div>
    
    <div class="ml-auto">
        <button (click)="submit()" [disabled]="!itemDetails" mat-stroked-button class="btn-sm">{{ "maintenance.submit" | translate }}</button>
      </div>
</div>
