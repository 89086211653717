import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountsService } from 'src/app/core/accounts.service';
import { B2binvoiceDetailsComponent } from '../b2binvoice-summary/b2binvoice-details/b2binvoice-details.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-b2c-summary',
  templateUrl: './b2c-summary.component.html',
  styleUrls: ['./b2c-summary.component.scss']
})
export class B2cSummaryComponent implements OnInit {

  hotelId = '';
  b2cData: any = {};
  tableData = []

  constructor(
    private accountsService: AccountsService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.hotelId = params.hotelId;
      try {
        this.b2cData = JSON.parse(params?.data);
        this.createTableData();
      }
      catch { }
    });
  }

  createTableData() {
    const arr = [];

    for (const rt in this.b2cData) {
      arr.push(this.b2cData[rt])
    }
    arr.sort((a,b) => b.totalAmount - a.totalAmount);
    this.tableData = arr;
  }

  openInvoiceDetails(invoiceDic) {
    const invoiceIds = []
    for (const invId in invoiceDic) {
      invoiceIds.push(invId);
    }
    this.dialog.open(B2binvoiceDetailsComponent, {
      width: '900px',
      data: {
        summary: {invoiceIds}, 
        hotelId: this.hotelId,
      }
    })
  }

  back() {
    this.accountsService.currentTab = 'gstr1';
    this.router.navigate([`accounts/${this.hotelId}`], {queryParams: {tabName: 'gstr1', fromBack: true}});
  }
}
