<div class="container card-0 justify-content-center">
    <div class="card-body px-sm-4 px-0">
        <div class="row justify-content-center round">
            <div class="col-lg-10 col-md-12 ">
                <div class="card shadow-lg card-1">
                    <div class="card-header">
                        <h3> Thanks for your booking. Please fill the details below to enable quick and Contactless Check-In on arrival.</h3>
                    </div>
                    <div class="card-body inner-card">
                        <form #form="ngForm" name="ngForm" (ngSubmit)="onSubmit(form)" autocomplete="off" enctype="multipart/form-data">
                            <div class="row justify-content-center">
                                <div class="col-lg-5 col-md-6 col-sm-12">
                                    <div class="form-group"><label for="first-name">Name</label><span style="color: red;"> * </span><input type="text" class="form-control" id="first-name" [(ngModel)]="guestDetails.name" name="name" minlength="3" placeholder="Type your Name" required> </div>
                                    <div class="form-group"> <label for="Mobile-Number">Mobile No</label><span style="color: red;"> * </span> <input type="text" class="form-control" id="Mobile-Number" [(ngModel)]="guestDetails.phone" name="phone" placeholder="" required > </div>
                                </div>
                                <div class="col-lg-5 col-md-6 col-sm-12">
                                    <div class="form-group"> <label for="phone">Email</label><span style="color: red;"> * </span> <input type="email" class="form-control" id="email" [(ngModel)]="guestDetails.email" name="email" placeholder="" required> </div>
                                    <div class="form-group"> <label for="countries" >Country</label><span style="color: red;"> * </span>
                                        <input class="form-control" type="text" id="countries" list="country" [(ngModel)]="guestDetails.country" (click)="clearform()" name="country" required/>
                                        <datalist id="country" name = "country">
                                            <option *ngFor="let option of countriesList" [value]="option">{{option}}</option>
                                        </datalist>
                                    </div>
                                </div>
                                <div class="col-md-12 col-lg-10 col-12">
                                    <div class="form-group"> <label for="exampleFormControlTextarea2">Address</label><span style="color: red;"> * </span> <textarea class="form-control rounded-0" id="exampleFormControlTextarea2" [(ngModel)]="guestDetails.inputAddress" name="address" rows="5" required></textarea></div>
                                </div>
                                <div class="col-md-12 col-lg-10 col-12 form-outline">
                                    <div class="form-group"> <label for="additionalRequest">Additional Request</label> <textarea class="form-control rounded-0" id="additionalRequest" [(ngModel)]="guestDetails.special_request" name="request" rows="2" style="height:100%;"></textarea></div>
                                </div>
                                <div *ngIf="showTravelData" class="col-lg-5 col-md-6 col-sm-12">
                                    <div class="form-group"><label for="purposeOfVisit">Purpose Of Visit</label><input type="text" class="form-control" [(ngModel)]="guestDetails.purposeOfVisit" name="purposeOfVisit"> </div>
                                    <div class="form-group"> <label for="goingTo">Going To</label><input type="text" class="form-control" [(ngModel)]="guestDetails.goingTo" name="goingTo"> </div>
                                </div>
                                <div *ngIf="showTravelData" class="col-lg-5 col-md-6 col-sm-12">
                                    <div class="form-group"> <label for="comingFrom">Coming From</label><input type="text" class="form-control" [(ngModel)]="guestDetails.comingFrom" name="comingFrom"> </div>
                                </div>
                            </div>
                            <div class="row justify-content-center">
                                <div class="col-lg-5 col-md-6 col-sm-12">
                                    <div class="col-lg-5 col-md-6 col-sm-12" style="padding-left: 0%;">
                                        <div class="form-group"><label for="fileup">Photo ID</label><span style="color: red;"> * </span><br>
                                            <div class="upload-btn-wrapper">
                                                <button  id="fileup" type="button" class="btn1" [disabled]="disablefield">Upload</button>
                                                <input type="file" class="form-control" name="documents" accept=".pdf, image/jpg, image/jpeg, image/png" multiple capture="camera" (change)="handleInput($event.target)" [disabled]="disablefield"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-5 col-md-6 col-sm-6" *ngIf="fileSelected?.length>0">
                                        <div class="Id-image form-group" id="files" style='display:inline-table; padding-right: 5px; padding-top: 5px; font-size: 12px;' 
                                            *ngFor='let i of fileSelected'>
                                            <div class="cancel" (click)="removeImage(i)">+</div>
                                            <a *ngIf="i['url']" href="{{i['url']}}" target="_blank">
                                                <img src="{{i['url']}}" width="70px" height="50px" alt="">
                                            </a>
                                            <a *ngIf="i['signature']" href="{{i['signature']}}" target="_blank">
                                                <img src="{{i['signature']}}" width="70px" height="50px" alt="">
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-lg-5 col-sm-12">
                                    <label for="signature-pad">Sign Here</label><span style="color: red;"> * </span><span style="float:right; cursor: pointer; font-size: 12px;" (click)="clearSignature()">Clear</span>
                                    <div class="signature-container">
                                        <signature-pad [options]="signaturePadOptions" (onBeginEvent)="drawStart()" id="signature-pad" (onEndEvent)="drawComplete()" required></signature-pad>
                                    </div>
                                    <div class="row" *ngIf="termsAndConditions">
                                        <div class="form-group form-check">
                                            <input id="accept" name="accept" (input)="termsRead()" type="checkbox" />
                                            <label for="accept" id="checkboxlabel">I have read the TermsAndConditions</label>
                                        </div>
                                    </div>
                                    <div class="row justify-content-end mb-5">
                                        <div class="col-lg-5 col-md-5 col-sm-6">
                                            <button type="submit" class="btn btn-block" [disabled]="form.invalid || fileSelected?.length==0 || !signatureImg || disablefield || notreadTerms">
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row justify-content-center">
                                <div class="col-md-12 col-lg-10 col-12 form-outline">
                                    <div class="form-group"> <label for="termsCondns" *ngIf="termsAndConditions">Terms And Conditions</label><p style="white-space: pre-line">{{termsAndConditions}}</p></div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>