import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { CommunicationService } from 'src/app/core/communication.service';
import { Globals } from 'src/app/core/globals.service';
import { ReservationdataService } from 'src/app/core/reservationdata.service';
import { UserService } from 'src/app/core/user.service';
import { DialogComponent } from 'src/app/_shared/dialog/dialog.component';
import { LogsComponent } from 'src/app/views/logs/logs.component';
import { EditInvoiceComponent } from 'src/app/views/stayview/edit-invoice/edit-invoice.component'; 
import { EditStayComponent } from 'src/app/views/stayview/edit/edit-stay/edit-stay.component';
import { PaymentsDetailsComponent } from 'src/app/views/stayview/payment-details/payment-details.component'; 
import { ServiceDetailsComponent } from 'src/app/views/stayview/service-details/service-details.component'; 
import { SwapRoomComponent } from 'src/app/views/stayview/swap-room/swap-room.component'; 

@Component({
  selector: 'app-allocation-menu2',
  templateUrl: './allocation-menu2.component.html',
  styleUrls: ['./allocation-menu2.component.scss']
})
export class AllocationMenu2Component implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];

  @Input() guestInfo: any = {};
  @Input() currentData: any;
  @Output() refreshView = new EventEmitter();

  currentUser: string;
  hotelId: string;
  balance: number;
  totalAmount: number;
  rateplanId = '';
  features: any = {};
  invoiceDetails: any;
  hasEInvoice = false;

  constructor(
    private userService: UserService, 
    public dialog: MatDialog, 
    public globals: Globals,
    private resdataS: ReservationdataService, 
    public datePipe: DatePipe, 
    public commS: CommunicationService,
    ) { }

  ngOnInit(): void {
    this.subscriptions.push(
      this.globals.currentUser.subscribe(user => this.currentUser = user),
      this.globals.currentHotelID.subscribe(hotelId => this.hotelId = hotelId),
      this.commS.balanceChange.subscribe(ev => {
        if (ev === this.guestInfo.roomAllocationId) {
          this.balanceUpdate();
        }
      }),
      this.userService.userFeatures$.subscribe(data => {
        this.features = data;
      })
    );

    if (this.guestInfo?.room?.rateplanId) {
      const rateArr = this.guestInfo?.room?.rateplanId.split('-');
      this.rateplanId = rateArr[rateArr.length - 1].toUpperCase();
    }

    this.menuOpened();
    if (this.guestInfo.status === 'Assigned') {
      this.totalAmount = this.guestInfo.roomPrice?.total_after_tax;
    }
  }

  menuOpened() {
    this.subscriptions.push(
      this.commS.menuOpened$.subscribe(data => {
        
        if (data?.roomAllocationId === this.guestInfo.roomAllocationId ){
          this.balance = this.guestInfo.balance;
          if((!this.totalAmount || !this.balance) &&
            ['Checked in', 'Checked out'].indexOf(data?.status) > -1) {
            
            this.getInvoiceDetails();
          
           }

      }    
      
      })
    );
  }

  getInvoiceDetails() {
    this.subscriptions.push(
      this.userService.getInvoiceDetails(this.guestInfo.roomAllocationId, this.hotelId)
      .subscribe(data => {
        if (data) {
          this.invoiceDetails = data;
          this.afterInvoiceDetails();
          this.calculateRoomTotal(data);
        }
      })
    );
  }

  afterInvoiceDetails() {
    this.invoiceDetails?.forEach(e => {
      if (e.e_invoice_link) {
        this.hasEInvoice = true;
      }
    })
  }
  
  openEInvoice() {
    this.invoiceDetails?.forEach(e => {
      if (e.e_invoice_link) {
        window.open(e.e_invoice_link, '_blank');
      }
    })
  }

  calculateRoomTotal(data) {
    this.totalAmount = 0; this.balance = 0;
    data?.forEach(e => {
      this.totalAmount += e.total_amount;
      this.balance += e.balance_amount;
    });

    this.totalAmount = Number(this.totalAmount.toFixed(2));
    this.balance = Number(this.balance.toFixed(2));
  }


  undoCheckIn(roomAllocationId, checkInDate) {
    const dialogBody =
      {title: 'Undo Checkin', body: 'Are you sure you want to Undo Checkin? This will void the invoice and delete services used.',
       confirmBtn: 'Undo', cancelBtn: 'Cancel', width: '400px', height: '170px'
      };
    const dialogRef = this.dialog.open(DialogComponent, {data: dialogBody});
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'true') {
        const body = {
          checkInDate, 
          bookingId: this.guestInfo.bookingId,
          user: this.currentUser
        };
        this.userService.undoCheckIn(roomAllocationId, this.hotelId, body).subscribe({
          next: () => {
            this.refreshView.emit();
          },
          error: null
        });
      }
    });
  }


  // CHECK IN
  checkIn(allocId, bookingId){
    
    if (this.currentData.room.housekeeping === 'dirty'){
      const WarningDialog =
      {title: 'WARNING !', body: 'Room needs to be clean for CHECK IN', cancelBtn: 'Dismiss'};
      this.dialog.open(DialogComponent, {data: WarningDialog});
      return;
    }
    const dialogBody =
      {title: 'Check in ?', body: 'Are you sure you want to check in ?',
        confirmBtn: 'Check in', cancelBtn: 'Cancel'
      };
    const dialogRef = this.dialog.open(DialogComponent, {data: dialogBody});
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'true'){

        const body = {
          roomAllocationId: allocId, 
          hotelId: this.hotelId, 
          bookingId, 
          user: this.currentUser,
          allocData: {
            guest_name: this.guestInfo.guestName,
            ...this.guestInfo.guestContact
          }
        };
        this.userService.checkIn(body, this.hotelId)
        .subscribe(success => {
          this.refreshView.emit();
        },
          err => {
        });
      }
    });
  }

  checkOut(roomAllocationId, bookingId) {
    const dialogBody =
      {title: 'Check out ?', body: 'Are you sure you want to check out ?',
       confirmBtn: 'Check out', cancelBtn: 'Cancel'
      };
    const dialogRef = this.dialog.open(DialogComponent, {data: dialogBody});
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'true'){
        const body = {
          roomAllocationId, 
          bookingId, 
          user: this.currentUser,
          allocData: {
            guest_name: this.guestInfo.guestName,
            ...this.guestInfo.guestContact
          }
        };
        this.userService.checkOut(body, this.hotelId)
          .subscribe(
          res => {
            if (res && 'error' in res) {
              const WarningDialog =
              {title: 'ERROR !', body: `${res.error}`, cancelBtn: 'Dismiss'};
              this.dialog.open(DialogComponent, {data: WarningDialog});
              return;
            }
            this.refreshView.emit();
          },
          err => {
            const WarningDialog =
              {title: 'ERROR !', body: `${err.error}`, cancelBtn: 'Dismiss'};
            this.dialog.open(DialogComponent, {data: WarningDialog});
          });
      }
    });
  }

  unassignRoom(checkin, checkout, roomAllocationId){
    const roomNumId = this.currentData.room.roomNumId;
    const dialogBody = {title: 'Unassign', body: 'Are you sure you want to unassign ?',
       confirmBtn: 'Unassign', cancelBtn: 'Cancel'
    };
    const dialogRef = this.dialog.open(DialogComponent, {data: dialogBody});
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'true') {
        const body = {
          checkin, checkout, roomAllocationId, roomNumId,
          roomNum: this.currentData.room.roomNum, user: this.currentUser};
        this.userService.unassignRoom(body, this.hotelId).subscribe(
          success => {
            this.refreshView.emit();
          }, err => {
            const WarningDialog = {title: 'ERROR !', body: `Something went wrong`, cancelBtn: 'Dismiss'};
            this.dialog.open(DialogComponent, {data: WarningDialog});
        });
      }
    });
  }

  goToEdit(allocId, status, invoiceIds, bookingId, source){
    const invoiceId = invoiceIds?.length ? invoiceIds[0] : null;
    this.dialog.open(EditStayComponent, {
      data : {allocId, hotelId: this.hotelId, status, invoiceId, bookingId, source, queryParams:{ user: this.currentUser }, 
        roomNum: this.currentData?.room?.roomNum, folioNo: this.guestInfo?.folioNo
      }
    });
  }

  goToAddService() {
    this.dialog.open(ServiceDetailsComponent, {data : {
      allocId: this.guestInfo.roomAllocationId, 
      invoiceId: this.guestInfo.invoiceIds?.length ? this.guestInfo.invoiceIds[0] : null, 
      mode: 'newEntry'
    }});
  }

  goToAddPayment(allocId, invoiceIds){
    const invoiceId = invoiceIds?.length ? invoiceIds[0] : null;
    this.dialog.open(PaymentsDetailsComponent, {
      disableClose: true,
      data : {allocId, invoiceId, mode: 'newEntry', guestInfo: this.guestInfo}
    });
  }

  getInvoice(roomAllocationId){
    this.userService.getInvoice(this.hotelId, roomAllocationId).subscribe(
      data => {
        data.invoices.forEach(ele => {
          if ('error' in ele){
            const WarningDialog = {title: 'ERROR !', body: `${ele.error}`, cancelBtn: 'Dismiss'};
            this.dialog.open(DialogComponent, {data: WarningDialog});
            return;
          }
          window.open(ele.url, '_blank');
        });
    }, err => {
      const WarningDialog = {title: 'ERROR !', body: `Something went wrong`, cancelBtn: 'Dismiss'};
      this.dialog.open(DialogComponent, {data: WarningDialog});
    });
  }

  deleteAllocation(bookingId, checkin, checkout, amount){
    this.userService.getNumRooms(this.hotelId, bookingId).subscribe(
      num => {
        if (num.num_rooms === 1) {
          const dialogBody = {title: 'DELETE', body: 'Do you want to delete the entry and cancel the reservation ?',
          confirmBtn: 'Yes', cancelBtn: 'Cancel', width: '400px', height: '170px'};
          const dialogRef = this.dialog.open(DialogComponent, {data: dialogBody});
          dialogRef.afterClosed().subscribe(result => {
            if (result === 'true'){
              const body = {bookingid: bookingId, checkin:checkin, checkout:checkout, amount:amount, user: this.currentUser};
              this.resdataS.fetchpayload(body,this.hotelId).subscribe(response=> {
                response['payload']['user'] = this.currentUser;
                var payloadlist = [response['payload']];
                this.resdataS.cancelReservation(payloadlist).subscribe(
                  success => {
                    this.refreshView.emit();
                  }, err => {
                    const WarningDialog = {title: 'ERROR !', body: err['error']['error'] , cancelBtn: 'Dismiss'};
                    this.dialog.open(DialogComponent, {data: WarningDialog});
                });
              });
            }
          });
        } else if (num.num_rooms > 1){
          const dialogBody = {title: 'DELETE', body: `This reservation involves multiple rooms.
          If you wish to cancel the entire reservation, please go to Reservations section and cancel the entire 
          reservation. Are you sure you want to delete the current room only ?`,
          confirmBtn: 'Yes', cancelBtn: 'Cancel', width: '500px', height: '215px'};
          const dialogRef = this.dialog.open(DialogComponent, {data: dialogBody});
          dialogRef.afterClosed().subscribe(result => {
            if (result === 'true'){
              const body = {checkin, checkout, roomNumId: this.currentData.room.roomNumId, user: this.currentUser, bookingId: bookingId};
              this.userService.deleteRoomAllocation(this.guestInfo.roomAllocationId, this.hotelId, body).subscribe(
                success => {
                  this.refreshView.emit();
                }, err => {
                  const WarningDialog = {title: 'ERROR !', body: err['error']['error'] , cancelBtn: 'Dismiss'};
                  this.dialog.open(DialogComponent, {data: WarningDialog});
              });
            }
          });
        }
      },err=>{
        const WarningDialog = {title: 'ERROR !', body: err['error']['error'] , cancelBtn: 'Dismiss'};
        this.dialog.open(DialogComponent, {data: WarningDialog});
      });
  }
  deleteOutOfOrder(checkin, checkout){
    const dialogBody = {title: 'DELETE', body: `Are you sure you want to Delete ?`,
    confirmBtn: 'Yes', cancelBtn: 'Cancel', width: '350px', height: '150px'};
    const dialogRef = this.dialog.open(DialogComponent, {data: dialogBody});
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'true'){
        const body = {checkin, checkout, roomNumId: this.currentData.room.roomNumId, user: this.currentUser, status:'Out of Order'};
        this.userService.deleteRoomAllocation(this.guestInfo.roomAllocationId, this.hotelId, body).subscribe(
          success => {
            this.refreshView.emit();
          }, err => {
            const WarningDialog = {title: 'ERROR !', body: err['error']['error'] , cancelBtn: 'Dismiss'};
            this.dialog.open(DialogComponent, {data: WarningDialog});
          }
        );
      }
    });
  }

  balanceUpdate() {
    this.userService.getBalance(this.guestInfo.roomAllocationId).subscribe(
      data => {
        this.totalAmount = data.total_amount;
        this.balance = data.balance;
      }
    );
  }

  editInvoice(roomAllocationId) {
    this.userService.getInvoiceDetails(roomAllocationId, this.hotelId).subscribe(
      data => {
        this.dialog.open(EditInvoiceComponent, {data :
          {data, roomAllocationId: this.guestInfo.roomAllocationId,
            user: this.currentUser, hotelId: this.hotelId}});
      }
    );
  }

  swapRoom(roomAllocationId){
    this.dialog.open(SwapRoomComponent, {data :
      {roomAllocationId: this.guestInfo.roomAllocationId, roomNumId: this.currentData.room.roomNumId, roomNum: this.currentData.room.roomNum,
        user: this.currentUser, hotelId: this.hotelId}});
  }

  fetchLogs(roomAllocationId){
    this.resdataS.fetchLogsOfAllocId(roomAllocationId, this.hotelId).subscribe(result=>{
      this.dialog.open(LogsComponent, {
        width: '80%',
        maxWidth: '95%',
        minHeight: '60%',
        height: '60%',
        data :{logs: result.logs, user: this.currentUser, hotelId: this.hotelId}});
      
    })
    
  }

  undoCheckOut(roomAllocationId, bookingId) {
    const dialogBody =
    {title: 'Undo Checkout', body: 'Are you sure you want to Undo Checkout? ',
     confirmBtn: 'Undo', cancelBtn: 'Cancel', width: '400px', height: '170px'
    };
  const dialogRef = this.dialog.open(DialogComponent, {data: dialogBody});
  dialogRef.afterClosed().subscribe(result => {
    if (result === 'true') {
      const body = {
        bookingId: this.guestInfo.bookingId,
      };
      this.userService.undoCheckOut(roomAllocationId, this.hotelId, body).subscribe({
        next: () => {
          this.refreshView.emit();
        },
        error: null
      });
    }
  });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(e => e.unsubscribe());
  }

}
